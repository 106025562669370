import React, { Component } from 'react';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';
import { errorMsgCheck, mailCheck, buildSelectItems, resultToIbSheet } from '../../common/ComFunction';
import { getMemberList, saveMember } from '../../service/members/MembersService';
import { getUserCnt2, getCisCommCode } from '../../service/CommonService';
import Header from '../include/Header';
import MemberExcel from './MemberExcel';
import { CUST_NO_AJ } from '../../common/ComValues';
import { Button } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore } = CommonStore;

var result = {
	//sheet데이터
	data: [],
};
class MemberManager extends Component {
	constructor(props) {
		super(props);

		this.sheetData = {
			id: 'sheetMemberManager', // sheet id
			initData: 'members/Init-MemberManager', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '600px', // sheet height
		};
	}

	state = {
		//검색조건
		custNo: sessionStorage.authCode === '0' || sessionStorage.authCode === '1' ? '' : sessionStorage.custNo, //고객번호
		custNm: sessionStorage.authCode === '0' || sessionStorage.authCode === '1' ? '' : sessionStorage.custNm, //고객사명
		userNameSearch: sessionStorage.authCode === '3' || sessionStorage.authCode === '6' ? sessionStorage.userName : '', //사용자명
		userIdSearch: sessionStorage.authCode === '3' || sessionStorage.authCode === '6' ? sessionStorage.userId : '', //아이디
		userStatusSearch: 'E', //유저상태(E:재직 / D:퇴사 / U:미승인)
		mntnYn: 'N', //유지보수위탁업체여부
		searchReadonly: false, //userNameSearch Readonly 여부

		//검색팝업
		excelUploadModal: false, //엑셀업로드모달

		//조회결과
		memberList: [], //사용자리스트

		//입력항목
		userId: '', //사용자아이디
		userName: '', //사용자명
		otelNo: '', //대표(사무실)번호
		otelNo1: '', //대표(사무실)번호1
		otelNo2: '', //대표(사무실)번호2
		otelNo3: '', //대표(사무실)번호3
		celNo: '', //휴대폰번호
		celNo1: '', //휴대폰번호1
		celNo2: '', //휴대폰번호2
		celNo3: '', //휴대폰번호3
		emailAddr: '', //메일주소
		passWord: '', //비밀번호
		userTitle: '', //사용자직급
		addrSeq: '', //지점시퀀스
		spotNm: '', //지점명
		spotId: '', //지점아이디
		postAddr: '', //지점주소1
		postSddr: '', //지점주소2
		postCode: '', //우편번호
		deptName: '', //부서명
		appAuthYn: 'N', //결재권한여부
		authCode: '', //권한코드
		userStatus: 'E', //유저상태(E:재직 / D:퇴사 / U:미승인)
		custSeq: '', //청구지순번(관리자,현업만(0,1) 관리)
		empno: '', //사번
		aosYn: 'N', //AOS사용여부
		systCode: '', // 가입경로

		chgYn: false, //입력변경여부
		passChgYn: false, // 비밀번호변경여부
		tranType: 'I', //transaction타입(U:수정 / I:추가 / D:삭제)
		userIdRead: true, //userId Readonly 여부
		userIdChk: false, //사용자아이디중복체크
		ajValid: false, // aj네트웍스 전용 필수값 체크시 사용

		disabled: true,

		userStatusList: [],
		userStatusSearchList: [],
		authCodeList: [], // 공통코드 선언
	};

	setSearchReadonly = () => {
		if (sessionStorage.authCode === '3' || sessionStorage.authCode === '6') {
			this.setState({
				searchReadonly: true, //사용자는 검색조건 읽기전용
			});
		} else {
			this.setState({
				searchReadonly: false,
			});
		}
	};

	resetState = (type) => {
		if (type === 'A') {
			// 전체 초기화
			this.setState({
				memberList: [], //사용자리스트
				tranType: 'U', //transaction타입(U:수정 / I:추가 / D:삭제)
				userIdRead: true, //userId Readonly 여부
			});
			this.resetResult(); //ibsheet 데이터 리셋
		} else if (type === 'I') {
			// 신규입력상태
			this.setState({
				tranType: 'I', //transaction타입(U:수정 / I:추가 / D:삭제)
				userIdRead: false, //userId Readonly 여부
			});
		} else if (type === 'U') {
			// 수정가능상태
			this.setState({
				tranType: 'U', //transaction타입(U:수정 / I:추가 / D:삭제)
				userIdRead: true, //userId Readonly 여부
			});
		}
		this.setState({
			//입력항목
			userId: '', //사용자아이디
			userName: '', //사용자명
			otelNo: '', //대표(사무실)번호
			otelNo1: '', //대표(사무실)번호1
			otelNo2: '', //대표(사무실)번호2
			otelNo3: '', //대표(사무실)번호3
			celNo: '', //휴대폰번호
			celNo1: '', //휴대폰번호1
			celNo2: '', //휴대폰번호2
			celNo3: '', //휴대폰번호3
			emailAddr: '', //메일주소
			passWord: '', //비밀번호
			userTitle: '', //사용자직급
			addrSeq: '', //지점시퀀스
			spotNm: '', //지점명
			spotId: '', //지점아이디
			postAddr: '', //지점주소1
			postSddr: '', //지점주소2
			postCode: '', //우편번호
			deptName: '', //부서명
			appAuthYn: 'N', //결재권한여부
			authCode: '', //권한코드
			userStatus: 'E', //유저상태(E:재직 / D:퇴사 / U:미승인)
			userIdChk: false, //사용자아이디중복체크
			custSeq: '', //청구지순번(관리자,현업만(0,1) 관리)
			empno: '', //사번
			aosYn: 'N', //AOS사용여부
			systCode: '', //가입경로

			chgYn: false, //입력변경여부
			passChgYn: false, // 비밀번호변경여부
			// tranType    : 'U',  //transaction타입(U:수정 / I:추가 / D:삭제)
			// userIdRead  : true, //userId Readonly 여부
		});
	};

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
			chgYn: true, // 변경일어남
		});
		if (e.target.name === 'passWord') {
			this.setState({
				[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
				chgYn: true, // 변경일어남
				passChgYn: true, // 변경일어남
			});
		}
	};
	handleChangeNum = (e) => {
		// 숫자만 입력받도록
		const value = e.target.value.replace(/\D/, '');
		this.setState({
			[e.target.name]: value,
			chgYn: true, // 변경일어남
		});
	};
	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
	};
	onSaveBtnClick = () => {
		window.doAction();
	};
	componentDidMount() {
		ProgressStore.activeProgress();
		// this.findCustList();

		this.setSearchReadonly();

		//공통코드 조회
		Promise.all([getCisCommCode('10'), getCisCommCode('10'), getCisCommCode('13')])
			.then((r) => {
				let [code10Data, code10Data1, code13Data] = r;

				// 퇴사상태는 제외.삭제버튼으로 처리
				// 미승인인 경우에만 옵션 나오지 않도록 처리 20221004
				code10Data.map((item, i) => {
					if (item.value === 'X') {
						code10Data.splice(i, 1);
					}
				});
				code10Data1.map((item, i) => {
					if (item.value === 'X') {
						code10Data1.splice(i, 1);
					}
				});

				// 사용자 authCode에 따라 보여지는 권한 리스트 변경
				let tmpList = [];
				if (sessionStorage.authCode === '1') {
					// 현업은 관리자 제외
					code13Data.map((item, i) => {
						if (item.value !== '0') {
							tmpList.push(item);
						}
					});
				} else if (sessionStorage.authCode === '2') {
					//고객관리자는 관리자, 현업,유지보수처, 발주미사용 권한 제외
					code13Data.map((item, i) => {
						if (item.value === '2' || item.value === '3') {
							tmpList.push(item);
						}
					});
				} else if (sessionStorage.authCode === '5') {
					// 발주미사용 전용 리스트
					code13Data.map((item, i) => {
						if (item.value === '5' || item.value === '6') {
							tmpList.push(item);
						}
					});
				} else if (sessionStorage.authCode === '3' || sessionStorage.authCode === '6') {
					//고객사용자는 고객사용자만 본다
					code13Data.map((item, i) => {
						if (item.value == sessionStorage.authCode) {
							tmpList.push(item);
						}
					});
					this.findCustList(); // 사용자는 검색조건 세팅되어있으므로 바로 검색 20200605 kkj
				} else if (sessionStorage.authCode === '0') {
					//관리자는 전체권한 볼 수 있다
					tmpList = code13Data;
				}

				code13Data = tmpList;

				this.setState({
					userStatusList: code10Data, // 사용자상태 공통코드
					userStatusSearchList: code10Data1, // 사용자상태 공통코드
					authCodeList: code13Data, // 권한 공통코드
				});

				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err);
			});
	}
	// 고객사팝업
	handleClickOpen = (popupType) => {
		if (popupType === '1') {
			//고객사 팝업
			CustSearchPopupStore.openPopup(popupType, null, null, this.handleClose);
		} else if (popupType === '2') {
			//지점 팝업
			//지점은 고객사 팝업 검색 후 가능
			if (this.state.custNo === '') {
				ModalStore.openModal('지점검색은 회사명 검색 이후 가능합니다.');
				return;
			}
			CustSearchPopupStore.openPopup(popupType, sessionStorage.spotTitle + '명', this.state.custNo, this.handleClose);
		}
	};
	handleClose = (item) => {
		if (item !== undefined) {
			if (CustSearchPopupStore.popupType === '1') {
				let customData = JSON.parse(sessionStorage.getItem('customData'));

				// 고객사 검색 결과
				if (item.custNo !== undefined) {
					this.setState({
						custNo: item.custNo,
						custNm: item.custNm,
					});
					this.resetState('A');
				}
				this.setState({
					ajValid: customData != null && customData.hasOwnProperty('CT012') ? true : false, //aj네트웍스 전용 필수값 체크시 사용
				});
			} else if (CustSearchPopupStore.popupType === '2') {
				// 지점 검색 결과
				if (item.addrSeq !== undefined) {
					this.setState({
						addrSeq: item.addrSeq, //지점시퀀스
						spotNm: item.spotNm, //지점명
						spotId: item.spotId, //지점아이디
						postAddr: item.postAddr, //지점주소1
						postSddr: item.postSddr, //지점주소2
						postCode: item.postCode, //지점우편번호
						chgYn: true, // 변경일어남
					});
				}
			}
		}
	};
	// 고객사팝업 end

	//모달
	closeModal = () => {
		if (this.state.excelUploadModal) {
			this.setState({
				excelUploadModal: false,
			});
		}
	};
	//모달end

	//사용자 리스트 조회
	findCustList = () => {
		if (this.state.custNo === '') {
			// alert('검색어를 입력하셔야합니다.');
			ModalStore.openModal('검색어를 입력하셔야합니다.');
			this.custNm.focus();
			return;
		}

		ProgressStore.activeProgress();
		let param = {
			custNo: this.state.custNo,
			userNm: this.state.userNameSearch,
			userId: this.state.userIdSearch,
			userStatus: this.state.userStatusSearch, //유저상태(E:재직 / D:퇴사 / U:미승인)
		};

		getMemberList(param)
			.then((r) => {
				this.setState({ memberList: r }, () => {
					// JSON객체형태로 서버에서 받으므로 IBsheet에서 출력 가능한 배열형태로 변환
					window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				});

				// this.resetState('I');
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};

	// 조회된 결과 중 선택한 user input에 셋팅
	setInput = () => {
		let userId = window.getSelectedUserId();
		if (userId !== undefined) {
			this.state.memberList.map((item, idx) => {
				if (item.userId === userId) {
					this.setState({
						userId: item.userId,
						userName: item.userName, //사용자명
						otelNo1: item.otelNo1, //대표(사무실)번호1
						otelNo2: item.otelNo2, //대표(사무실)번호2
						otelNo3: item.otelNo3, //대표(사무실)번호3
						celNo1: item.celNo1, //휴대폰번호1
						celNo2: item.celNo2, //휴대폰번호2
						celNo3: item.celNo3, //휴대폰번호3
						emailAddr: item.emailAddr, //메일주소
						passWord: item.passWord, //비밀번호
						userTitle: item.userTitle, //사용자직급
						addrSeq: item.addrSeq, //지점시퀀스
						spotNm: item.spotNm, //지점명
						spotId: item.spotId, //지점아이디
						postAddr: item.postAddr, //지점주소1
						postSddr: item.postSddr, //지점주소2
						postCode: item.postCode, //우편번호
						deptName: item.deptName, //부서명
						appAuthYn: item.appAuthYn, //결재권한여부
						authCode: item.authCode, //권한코드
						userStatus: item.userStatus, //유저상태(E:재직 / D:퇴사 / U:미승인)
						custSeq: item.custSeq, //청구지순번(관리자,현업만(0,1) 관리)
						empno: item.empno, //사번
						aosYn: item.aosYn, //AOS사용여부
						chgYn: false, //입력변경여부
						passChgYn: false, //비밀번호변경여부
						tranType: 'U', //transaction타입 수정
						userIdRead: true, //userid입력
						systCode: item.systCode, //가입경로
						privacyChkYn: item.privacyChkYn, //개인정보수집밍이용동의
						rentalChkYn: item.rentalChkYn, //렌탈몰약관동의
						emailChkYn: item.emailChkYn, //이메일수신동의
						smsChkYn: item.smsChkYn, //SMS,MMS 수신동의
					});
				}
			});
		}
	};
	//사용자아이디 중복검사
	chkUserId = () => {
		if (this.state.userId === '') {
			ModalStore.openModal('사용자 아이디를 먼저 입력하세요.');
			this.userId.focus();
			return;
		}

		let param = {
			userId: this.state.userId, //사용자아이디
		};

		ProgressStore.activeProgress();
		getUserCnt2(param)
			.then((r) => {
				let cnt = r[0].cnt;
				// alert(r[0].cnt);
				let msg = '';
				if (cnt > 0) {
					msg = '이미 존재하는 아이디입니다.';
					this.setState({
						userIdChk: false,
					});
				} else {
					msg = '사용 가능한 아이디입니다.';
					this.setState({
						userIdChk: true,
					});
				}
				ModalStore.openModal(msg);
				// comModal(true, msg);
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};
	// 사용자 추가할 수 있도록 readonly 풀고 state 리셋 후 추가상태로 셋팅
	setForAdd = () => {
		this.resetState('I'); //입력가능상태
		this.userId.focus();
	};

	// 저장
	saveUser = () => {
		const {
			userId,
			userName,
			otelNo1,
			otelNo2,
			otelNo3,
			celNo1,
			celNo2,
			celNo3,
			emailAddr,
			passWord,
			userTitle,
			addrSeq,
			deptName,
			appAuthYn,
			custNo,
			authCode,
			chgYn,
			passChgYn,
			tranType,
			userIdChk,
			userStatus,
			custSeq,
			ajValid,
			empno,
			aosYn,
			systCode,
		} = this.state;

		//validation chk
		if (tranType === 'U' && !chgYn) {
			// 수정상태에서 변경 없을 시
			ModalStore.openModal('변경사항이 없습니다.');
			return;
		}
		if (userId === '') {
			ModalStore.openModal('아이디를 입력하세요.');
			this.userId.focus();
			return;
		}
		if (passWord === '') {
			ModalStore.openModal('비밀번호를 입력하세요.');
			this.passWord.focus();
			return;
		}
		if (userName === '') {
			ModalStore.openModal('이름을 입력하세요.');
			this.userName.focus();
			return;
		}
		if (addrSeq === '') {
			ModalStore.openModal(sessionStorage.spotTitle + '명을 검색 또는 입력하세요.');
			this.spotNm.focus();
			return;
		}
		if (emailAddr === '') {
			ModalStore.openModal('이메일주소를 입력하세요.');
			this.emailAddr.focus();
			return;
		}
		// if (otelNo1 === '') {
		// 	ModalStore.openModal('대표전화를 입력하세요.');
		// 	this.otelNo1.focus();
		// 	return;
		// }
		// if (otelNo2 === '') {
		// 	ModalStore.openModal('대표전화를 입력하세요.');
		// 	this.otelNo2.focus();
		// 	return;
		// }
		// if (otelNo3 === '') {
		// 	ModalStore.openModal('대표전화를 입력하세요.');
		// 	this.otelNo3.focus();
		// 	return;
		// }
		// if (celNo1 === '') {
		// 	ModalStore.openModal('휴대전화를 입력하세요.');
		// 	this.celNo1.focus();
		// 	return;
		// }
		// if (celNo2 === '') {
		// 	ModalStore.openModal('휴대전화를 입력하세요.');
		// 	this.celNo2.focus();
		// 	return;
		// }
		// if (celNo3 === '') {
		// 	ModalStore.openModal('휴대전화를 입력하세요.');
		// 	this.celNo3.focus();
		// 	return;
		// }
		if (authCode === '') {
			ModalStore.openModal('권한을 입력하세요.');
			this.authCode.focus();
			return;
		}
		if (ajValid && empno === '') {
			ModalStore.openModal('사번을 입력하세요.');
			this.empno.focus();
			return;
		}

		if (passWord.length < 4) {
			ModalStore.openModal('비밀번호는 최소 4자 이상입니다.');
			this.passWord.focus();
			return;
		}
		if (tranType === 'I' && !userIdChk) {
			// 입력상태에서 중복검사여부
			ModalStore.openModal('아이디 중복검사는 필수입니다.');
			return;
		}

		if (!mailCheck(emailAddr)) {
			ModalStore.openModal('메일주소 형식이 올바르지 않습니다.');
			this.emailAddr.focus();
			return;
		}
		// validation chk end
		ProgressStore.activeProgress();

		let param = {
			userId: userId, //사용자아이디
			userName: userName, //사용자명
			custNo: custNo, //고객번호
			otelNo: otelNo1 + otelNo2 + otelNo3, //대표(사무실)번호
			celNo: celNo1 + celNo2 + celNo3, //휴대폰번호
			emailAddr: emailAddr, //메일주소
			passWord: passWord, //비밀번호
			userTitle: userTitle, //사용자직급
			addrSeq: addrSeq, //지점시퀀스
			deptName: deptName, //부서명
			appAuthYn: appAuthYn, //결재권한여부
			authCode: authCode, //권한코드
			userStatus: userStatus, //사용자상태
			custSeq: custSeq === '' ? null : custSeq, //청구지순번(관리자,현업만(0,1) 관리)
			empno: empno, //사번
			aosYn: aosYn, //AOS사용여부
			tranType: tranType, //transaction 타입
			passChgYn: passChgYn ? 'Y' : 'N', //비밀번호변경여부
			systCode: systCode, //가입경로
		};

		saveMember(param)
			.then((r) => {
				// console.log(r);console.log(r.length);console.log(r[0]);
				ModalStore.openModal('저장되었습니다.');

				// this.resetState();
				this.resetResult(); //ibsheet 데이터 리셋
				this.findCustList(); //저장 성공 후 재조회
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};
	// sheet데이터 리셋
	resetResult = () => {
		result = {
			data: [],
		};
		window[this.sheetData.id].LoadSearchData(result, {
			Sync: 1,
		});
	};
	// 삭제
	callDelUser = () => {
		if (this.state.userId === '') {
			ModalStore.openModal('사용자를 먼저 선택하세요.');
			return;
		}
		ConfirmModalStore.openModal('사용자를 삭제하시겠습니까?', this.deleteUser);
	};
	deleteUser = () => {
		const { userId } = this.state;

		ProgressStore.activeProgress();

		let param = {
			userId: userId, //사용자아이디
			userStatus: 'D', //퇴사상태
			tranType: 'D', //transaction 타입
		};

		saveMember(param)
			.then((r) => {
				// console.log(r);console.log(r.length);console.log(r[0]);
				ModalStore.openModal('삭제되었습니다.');

				// this.resetState();
				this.resetResult(); //ibsheet 데이터 리셋
				this.findCustList(); //저장 성공 후 재조회
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mb30">
								<h2>사용자 관리</h2>
								{this.state.searchReadonly ? (
									<div className="btnRight">
										<a className="btn01 search" onClick={this.findCustList}>
											<span>조회</span>
										</a>
									</div>
								) : (
									<div className="btnRight">
										<a className="btn01 search" onClick={this.findCustList}>
											<span>조회</span>
										</a>
										<a className="btn03 add" onClick={this.setForAdd}>
											<span>추가</span>
										</a>
										{sessionStorage.authCode === '0' && (
											<a className="btn03 add" style={{ width: 100 }} onClick={() => this.setState({ excelUploadModal: true })}>
												<span>대량사용자추가</span>
											</a>
										)}
									</div>
								)}
								{/* <a onClick={this.onSaveBtnClick} className="btn01 save"><span>저장</span></a> */}
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '9%' }} />
										<col style={{ width: '24%' }} />
										<col style={{ width: '9%' }} />
										<col style={{ width: '12%' }} />
										<col style={{ width: '9%' }} />
										<col style={{ width: '16%' }} />
										<col style={{ width: '9%' }} />
										<col style={{ width: '12%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>고객사명</th>
											<td>
												<input
													type="text"
													ref={(ref) => (this.custNm = ref)}
													name="custNm"
													value={this.state.custNm || ''}
													onChange={this.handleChange}
													readOnly
													style={{ width: '80%', marginRight: '5px' }}
												/>
												{/* 고객사검색버튼 */}
												<IconButton
													aria-label="Search"
													className="iconButton"
													onClick={() => this.handleClickOpen('1')}
													style={{ display: sessionStorage.btnControl }}
												>
													<Search fontSize="small" />
												</IconButton>
											</td>
											<th>사용자명</th>
											<td>
												<input
													type="text"
													ref={(ref) => (this.userNameSearch = ref)}
													name="userNameSearch"
													value={this.state.userNameSearch || ''}
													onChange={this.handleChange}
													readOnly={this.state.searchReadonly}
													onKeyDown={(e) => {
														if (e.key === 'Enter') this.findCustList();
													}}
												/>
											</td>
											<th>아이디</th>
											<td>
												<input
													type="text"
													ref={(ref) => (this.userIdSearch = ref)}
													name="userIdSearch"
													value={this.state.userIdSearch || ''}
													onChange={this.handleChange}
													readOnly={this.state.searchReadonly}
													onKeyDown={(e) => {
														if (e.key === 'Enter') this.findCustList();
													}}
												/>
											</td>
											<th>사용자상태 </th>
											<td>
												<select name="userStatusSearch" value={this.state.userStatusSearch} onChange={this.handleChange}>
													{this.state.userStatusSearchList ? buildSelectItems(this.state.userStatusSearchList) : ''}
												</select>
											</td>
											{/* <th>유지보수위탁업체</th>
                                        <td>
                                        <select name="mntnYn" value={this.state.mntnYn} onChange={this.handleChange}>
                                            <option value="전체">전체</option>
                                            <option value="Y">Y</option>
                                            <option value="N">N</option>
                                        </select>
                                        </td> */}
										</tr>
									</tbody>
								</table>
							</div>

							<div className="writeType05 long">
								<div className="left" onClick={this.setInput}>
									<div
										className="scroll"
										onMouseOver={() => {
											document.body.style.overflow = 'hidden';
										}}
										onMouseOut={() => {
											document.body.style.overflow = 'auto';
										}}
									>
										<p className="headline">사용자 리스트</p>
										<IBSheet {...this.sheetData} />
									</div>
								</div>
								<div className="right">
									{/* <h2>-사용자 정보</h2> */}
									<p className="headline">사용자 정보</p>
									<table>
										<colgroup>
											<col style={{ width: '20%' }} />
											<col style={{ width: '30%' }} />
											<col style={{ width: '20%' }} />
											<col style={{ width: '30%' }} />
										</colgroup>
										<tbody>
											<tr>
												<th>
													아이디 <span className="star">*</span>
												</th>
												<td colSpan="3">
													<input
														type="text"
														className="size02"
														name="userId"
														maxLength="50"
														value={this.state.userId || ''}
														readOnly={this.state.userIdRead}
														onChange={(e) => {
															this.handleChange(e);
															this.setState({ userIdChk: false });
														}}
														ref={(ref) => (this.userId = ref)}
													/>
													{/* <a className="btn01 blue" onClick={this.chkUserId} style={(this.state.userIdRead) ? {visibility:"hidden"}:{visibility:"visible"}}><span>중복검사</span></a> */}
													<Button
														variant="outlined"
														size="small"
														color="primary"
														className="btnInTable"
														onClick={this.chkUserId}
														style={this.state.userIdRead ? { visibility: 'hidden' } : { visibility: 'visible' }}
													>
														중복검사
													</Button>
												</td>
											</tr>
											<tr>
												<th>
													비밀번호 <span className="star">*</span>
												</th>
												<td>
													<input
														type="password"
														name="passWord"
														maxLength="20"
														value={this.state.passWord || ''}
														onChange={this.handleChange}
														ref={(ref) => (this.passWord = ref)}
													/>
												</td>
												<th>사번 {this.state.ajValid ? <span className="star">*</span> : ''}</th>
												<td>
													<input
														type="text"
														name="empno"
														maxLength="20"
														value={this.state.empno || ''}
														onChange={this.handleChange}
														ref={(ref) => (this.empno = ref)}
													/>
												</td>
											</tr>
											<tr>
												<th>
													이름 <span className="star">*</span>
												</th>
												<td>
													<input
														type="text"
														name="userName"
														maxLength="25"
														value={this.state.userName || ''}
														onChange={this.handleChange}
														ref={(ref) => (this.userName = ref)}
													/>
												</td>
												<th>사용자상태 </th>
												<td>
													<select name="userStatus" value={this.state.userStatus} onChange={this.handleChange}>
														{/* <option value="E">재직</option>
                                                        <option value="D">퇴사</option>
                                                        <option value="U">미승인</option> */}
														{/* {userStatusList ? buildSelectItems([{title:'전체', value:0}].concat(userStatusList)) : '' }  */}
														{this.state.userStatusList ? buildSelectItems(this.state.userStatusList) : ''}
													</select>
												</td>
											</tr>
											<tr>
												<th>
													{sessionStorage.spotTitle}명 <span className="star">*</span>
												</th>
												<td colSpan="3">
													<input
														type="text"
														className="size01"
														name="spotNm"
														value={this.state.spotNm || ''}
														onChange={(e) => {
															this.handleChange(e);
															this.setState({ addrSeq: '' });
														}}
														ref={(ref) => (this.spotNm = ref)}
														readOnly
													/>
													{/* <a className="btn01 search" onClick={() => this.handleClickOpen('2')}><span>검색</span></a> */}
													<IconButton aria-label="Search" className="iconButton" onClick={() => this.handleClickOpen('2')}>
														<Search fontSize="small" />
													</IconButton>
												</td>
											</tr>
											<tr>
												<th>{sessionStorage.deptTitle}명 </th>
												<td colSpan="3">
													<input
														type="text"
														className="size01"
														name="deptName"
														maxLength="25"
														value={this.state.deptName || ''}
														onChange={this.handleChange}
														ref={(ref) => (this.deptName = ref)}
													/>
												</td>
											</tr>
											<tr>
												<th>{sessionStorage.spotTitle}코드 </th>
												<td>
													<input
														type="text"
														name="spotId"
														maxLength="15"
														value={this.state.spotId || ''}
														onChange={(e) => {
															this.handleChange(e);
															this.setState({ addrSeq: '' });
														}}
														ref={(ref) => (this.spotId = ref)}
														readOnly
													/>
												</td>
												{/* 관리자/현업만 청구지순번 수정, 조회가능 */}
												<th style={sessionStorage.authCode === '0' || sessionStorage.authCode === '1' ? { visibility: 'visible' } : { visibility: 'hidden' }}>
													청구지순번{' '}
												</th>
												<td style={sessionStorage.authCode === '0' || sessionStorage.authCode === '1' ? { visibility: 'visible' } : { visibility: 'hidden' }}>
													<input
														type="text"
														name="custSeq"
														maxLength="10"
														value={this.state.custSeq || ''}
														onChange={(e) => {
															this.handleChangeNum(e);
														}}
														ref={(ref) => (this.custSeq = ref)}
													/>
												</td>
											</tr>
											<tr>
												<th>{sessionStorage.spotTitle}주소 </th>
												<td colSpan="3">
													<input
														type="text"
														name="postCode"
														className="size04"
														maxLength="6"
														value={this.state.postCode || ''}
														onChange={(e) => {
															this.handleChangeNum(e);
															this.setState({ addrSeq: '' });
														}}
														ref={(ref) => (this.postCode = ref)}
														readOnly
													/>
													<input
														type="text"
														name="postAddr"
														className="size03"
														maxLength="50"
														value={this.state.postAddr || ''}
														onChange={(e) => {
															this.handleChange(e);
															this.setState({ addrSeq: '' });
														}}
														ref={(ref) => (this.postAddr = ref)}
														readOnly
													/>
													{/* <a className="btn01 black" onClick={() => this.setState({openPost:true})}><span>주소검색</span></a> */}
													<input
														type="text"
														name="postSddr"
														className="size03"
														maxLength="50"
														value={this.state.postSddr || ''}
														onChange={(e) => {
															this.handleChange(e);
															this.setState({ addrSeq: '' });
														}}
														ref={(ref) => (this.postSddr = ref)}
														readOnly
													/>
												</td>
											</tr>
											<tr>
												<th>
													이메일 <span className="star">*</span>
												</th>
												<td colSpan="3">
													<input
														type="text"
														className="size01"
														name="emailAddr"
														maxLength="100"
														value={this.state.emailAddr || ''}
														onChange={this.handleChange}
														ref={(ref) => (this.emailAddr = ref)}
													/>
												</td>
											</tr>
											<tr>
												<th>직급 </th>
												<td colSpan="3">
													<input
														type="text"
														className="size02"
														name="userTitle"
														maxLength="10"
														value={this.state.userTitle || ''}
														onChange={this.handleChange}
														ref={(ref) => (this.userTitle = ref)}
													/>
												</td>
											</tr>
											<tr>
												<th>대표전화</th>
												<td colSpan="3">
													<input
														type="text"
														name="otelNo1"
														className="size05"
														maxLength="3"
														value={this.state.otelNo1 || ''}
														onChange={this.handleChangeNum}
														ref={(ref) => (this.otelNo1 = ref)}
													/>
													<span className="dash">-</span>
													<input
														type="text"
														name="otelNo2"
														className="size05"
														maxLength="4"
														value={this.state.otelNo2 || ''}
														onChange={this.handleChangeNum}
														ref={(ref) => (this.otelNo2 = ref)}
													/>
													<span className="dash">-</span>
													<input
														type="text"
														name="otelNo3"
														className="size05"
														maxLength="4"
														value={this.state.otelNo3 || ''}
														onChange={this.handleChangeNum}
														ref={(ref) => (this.otelNo3 = ref)}
													/>
												</td>
											</tr>
											<tr>
												<th>휴대전화</th>
												<td colSpan="3">
													<input
														type="text"
														name="celNo1"
														className="size05"
														maxLength="3"
														value={this.state.celNo1 || ''}
														onChange={this.handleChangeNum}
														ref={(ref) => (this.celNo1 = ref)}
													/>
													<span className="dash">-</span>
													<input
														type="text"
														name="celNo2"
														className="size05"
														maxLength="4"
														value={this.state.celNo2 || ''}
														onChange={this.handleChangeNum}
														ref={(ref) => (this.celNo2 = ref)}
													/>
													<span className="dash">-</span>
													<input
														type="text"
														name="celNo3"
														className="size05"
														maxLength="4"
														value={this.state.celNo3 || ''}
														onChange={this.handleChangeNum}
														ref={(ref) => (this.celNo3 = ref)}
													/>
												</td>
											</tr>
											<tr>
												{/* 20190115 kkj 결재권한여부가 고정결재선관리에 자동등록이라는 혼란을 주어 삭제 */}
												{/* <th>결재권한여부 <span className="star">*</span></th>
                                                <td>                                            
                                                    <select name="appAuthYn" value={this.state.appAuthYn} onChange={this.handleChange}>                                                
                                                        <option value="Y">Y</option>
                                                        <option value="N">N</option>
                                                    </select>
                                                </td>    */}
												<th>
													권한 <span className="star">*</span>
												</th>
												<td>
													<select name="authCode" value={this.state.authCode} onChange={this.handleChange} ref={(ref) => (this.authCode = ref)}>
														{/* <option value="">전체</option> */}
														{this.state.authCodeList ? buildSelectItems([{ title: '선택', value: '' }].concat(this.state.authCodeList)) : null}
													</select>
												</td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<th>가입경로 </th>
												<td>
													<select name="systCode" value={this.state.systCode} onChange={this.handleChange}>
														<option value="CIS">CIS</option>
														<option value="ARM">ARM</option>
													</select>
												</td>
												<td></td>
												<td></td>
											</tr>
											{this.state.ajValid ? (
												<tr>
													<th>AOS사용여부</th>
													<td>
														<select name="aosYn" value={this.state.aosYn} onChange={this.handleChange}>
															<option value="N">N</option>
															<option value="Y">Y</option>
														</select>
													</td>
													<td></td>
													<td></td>
												</tr>
											) : null}
										</tbody>
									</table>
									<p className="headline">정보 수집 동의</p>
									<table>
										<colgroup>
											<col style={{ width: '20%' }} />
											<col style={{ width: '30%' }} />
											<col style={{ width: '20%' }} />
											<col style={{ width: '30%' }} />
										</colgroup>
										<tbody>
											<tr>
												<th>
													개인정보 수집 및
													<br />
													이용동의{' '}
												</th>
												<td>
													<select name="privacyChkYn" value={this.state.privacyChkYn} disabled={this.state.disabled}>
														<option value="N">N</option>
														<option value="Y">Y</option>
													</select>
												</td>
												<th>
													AJ ITmall
													<br />
													이용 약관동의{' '}
												</th>
												<td>
													<select name="rentalChkYn" value={this.state.rentalChkYn} disabled={this.state.disabled}>
														<option value="N">N</option>
														<option value="Y">Y</option>
													</select>
												</td>
											</tr>
											<tr>
												<th>
													SMS,MMS
													<br />
													수신동의{' '}
												</th>
												<td>
													<select name="smsChkYn" value={this.state.smsChkYn} disabled={this.state.disabled}>
														<option value="N">N</option>
														<option value="Y">Y</option>
													</select>
												</td>
												<th>이메일수신동의 </th>
												<td>
													<select name="emailChkYn" value={this.state.emailChkYn} disabled={this.state.disabled}>
														<option value="N">N</option>
														<option value="Y">Y</option>
													</select>
												</td>
											</tr>
										</tbody>
									</table>
									<div className="btnRight2">
										{/* <a className="btn01 save" onClick={this.saveUser}><span>저장</span></a>
                                        <a className="btn01 min" onClick={this.callDelUser}><span>삭제</span></a> */}
										<Button
											className="button"
											onClick={this.callDelUser}
											color="secondary"
											variant="contained"
											size="small"
											style={this.state.searchReadonly ? { visibility: 'hidden' } : { visibility: 'visible' }}
										>
											<DeleteIcon className="saveIcon" />
											<span className="text">삭제</span>
										</Button>
										<Button className="button" onClick={this.saveUser} color="primary" variant="contained" size="small">
											<SaveIcon className="saveIcon" />
											<span className="text">저장</span>
										</Button>
									</div>
								</div>
							</div>
						</div>
						<MemberExcel
							open={this.state.excelUploadModal}
							closeFnc={this.closeModal}
							userStatusList={this.state.userStatusSearchList}
							authcodeList={this.state.authCodeList}
						/>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(MemberManager);
