import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ReturnIcon from '@material-ui/icons/LocalShipping';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
//날짜
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import Tooltip from '@material-ui/core/Tooltip';

import { getAssetReturnAskManualList, updateAssetReturnAskManual } from '../../service/rental/RentalAssetService';
import { getOrderAppStatus } from '../../service/rental/RentalOrderService';
import { getCisCommCode, getCustomData } from '../../service/CommonService';
import { dateToFormat, errorMsgCheck, resultToIbSheet, InsertComma, getHostType } from '../../common/ComFunction';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
import { CUST_NO_KY_DEV, CUST_NO_KY } from '../../common/ComValues';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore, UserPopupStore, KYContInfoCheckPopupStore } = CommonStore;

class ReturnAskManual extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sCustNo: sessionStorage.authCode > 1 ? sessionStorage.custNo : '',
			sCustNm: sessionStorage.authCode > 1 ? sessionStorage.custNm : '',
			sProcFlag: '',
			sAskUserId: '',
			sAskUserNm: '',
			sUserChgId: sessionStorage.authCode > 1 ? '' : sessionStorage.userId,
			sUserChgNm: sessionStorage.authCode > 1 ? '' : sessionStorage.userName,
			sAsstNo: '',

			delMethodList: [],
			returnStatusList: [{title:'신청완료', value:'isnull'},{title:'반납처리중', value:'0'},{title:'반납완료', value:'1'}
			,{title:'반납취소', value:'2'}],
			appList: [],

			/* 필드데이터 */
			apiInputSeq: '',
			returnStatusCd: 1,
			procFlagNm: '',
			custNo: '',
			custNm: '',
			email: '',
			asstNo: '',
			itemId: '',
			contNo: '',
			askUser: '',
			returnHopeDate: '',
			returnRmk: '',
			postCode: '',
			postAddr: '',
			postSddr: '',
			addrUser: '',
			addrTelNo: '',
			addrHpNo: '',
			procMsg: '',
			delMethod: '',
			delRealDate: '',
			askRmk: '',
			cancelRmk: '',
			custReqNo: '', // api v2 고객요청번호

			/* 회수정보 */
			delMethod: '',
			delDate: moment(),
			delTime: '',

			/* 시스템정보 */
			disabled: true,

			contInfoBtnShow: 'none', // 계약정보확인 버튼 활성화 여부
		};

		this.sheetData = {
			id: 'returnAskManualSheet', // sheet id
			initData: 'rental/Init-ReturnAskManual', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '587px', // sheet height
		};
	}

	componentDidMount = () => {
	};

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
	};

	getReturnAskList = () => {

		let param = {
			procFlag: this.state.sProcFlag,
			custNo: this.state.sCustNo,
			asstNo: this.state.sAsstNo,
			askUser: this.state.sAskUserId,
			userChg: this.state.sUserChgId,
		};

		ProgressStore.activeProgress();
		getAssetReturnAskManualList(param)
			.then((r) => {
				window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				this.setEmptyField(); //초기화
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	getReturnAskDetail = () => {
		if (!this.state.apiInputSeq) return;

		let param = {
			apiInputSeq: this.state.apiInputSeq,
			custNo: this.state.sCustNo,
			asstNo: this.state.sAsstNo,
			askUser: this.state.sAskUserId,
			userChg: this.state.sUserChgId,
		};

		ProgressStore.activeProgress();
		getAssetReturnAskManualList(param)
			.then((r) => {
				this.setDetailData(r[0]);
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	updateReturnAskManual = (state) => {
		let param = {
			apiInputSeq: this.state.apiInputSeq,
			procFlag: state,
			procMsg: this.state.procMsg,
		};

		ProgressStore.activeProgress();
		updateAssetReturnAskManual(param)
			.then(() => {
				ModalStore.openModal('저장 되었습니다.');
				ProgressStore.deactiveProgress();
			})
			.then(() => {
				//재조회
				this.getReturnAskList();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	clickUpdateReturnAskManual = (state) => {
		if (!this.state.apiInputSeq) return;
		ConfirmModalStore.openModal((state == 0 ? '반납처리중' : state == 1 ? '반납완료' : '반납취소') + '로 변경하시겠습니까?', () => {
			this.updateReturnAskManual(state);
		});
	};

	getReturnAskData = () => {
		if (window[this.sheetData.id].LastRow() === 0) return;

		let data = window.getReturnAskSelectData();
		//if (data.apiInputSeq === undefined) return;

		this.setDetailData(data);

	};

	setDetailData = (data) => {
		console.log(data);
		this.setState(
			{
				apiInputSeq: data.apiInputSeq,
				returnStatusCd: data.returnStatusCd,
				procFlagNm: data.procFlagNm,
				custNo: data.custNo,
				custNm: data.custNm,
				email: data.email,
				asstNo: data.asstNo,
				itemId: data.itemId,
				contNo: data.contNo,
				askUser: data.askUser,
				returnHopeDate: data.returnHopeDate,
				returnRmk: data.returnRmk,
				postCode: data.postCode,
				postAddr: data.postAddr,
				postSddr: data.postSddr,
				addrUser: data.addrUser,
				addrTelNo: data.addrTelNo,
				addrHpNo: data.addrHpNo,
				procMsg: data.procMsg,
				delMethod: data.delMethod,
				delRealDate: data.delRealDate,
				delTime: data.delTime,
				askRmk: !data.askRmk ? data.procMsg : data.askRmk,
				cancelRmk: data.cancelRmk,
				disabled: data.returnStatusCd !== '1' ? true : false,
				custReqNo: data.custReqNo, // api v2 고객요청번호
			},
		);
	};

	setEmptyField = () => {
		this.setState({
			apiInputSeq: '',
			returnStatusCd: 1,
			procFlagNm: '',
			custNo: '',
			custNm: '',
			email: '',
			asstNo: '',
			itemId: '',
			contNo: '',
			askUser: '',
			returnHopeDate: '',
			returnRmk: '',
			postCode: '',
			postAddr: '',
			postSddr: '',
			addrUser: '',
			addrTelNo: '',
			addrHpNo: '',
			procMsg: '',
			delMethod: '',
			delRealDate: '',
			delTime: '',
			askRmk: '',
			cancelRmk: '',
			custReqNo: '',
			disabled: true,
		});
	};

	dateHandler = (date) => {
		this.setState({ delDate: date });
	};

	customerSearch = () => {
		CustSearchPopupStore.openPopup('1', null, null, this.handleCustSearch);
	};

	handleCustSearch = (item) => {
		if (item !== undefined) {
			this.setState({
				sCustNo: item.custNo,
				sCustNm: item.custNm,
			});
		}
	};

	handleChange = (e) => {
		this.setState({ procMsg: e.target.value });
	};

	userSearch = (type) => {
		UserPopupStore.openPopup(type, this.state.sCustNo, this.handleUserSearch);
	};

	handleUserSearch = (item) => {
		if (item !== undefined) {
			if (UserPopupStore.type === 'user') {
				this.setState({
					sUserChgId: item.userCode,
					sUserChgNm: item.userName,
				});
			} else {
				this.setState({
					sAskUserId: item.userId,
					sAskUserNm: item.userName,
				});
			}
		}
	};

	makeUserChgInput = () => {
		if (sessionStorage.authCode > '1') {
			return (
				<tr>
					<th>신청자</th>
					<td>
						<input
							type="text"
							style={{ width: 100, marginRight: 10 }}
							value={this.state.sAskUserId}
							placeholder="ID"
							onChange={(e) => this.setState({ sAskUserId: e.target.value })}
						/>
						<IconButton onClick={() => this.userSearch('')} color="secondary" className="iconButton">
							<SearchIcon fontSize="small" />
						</IconButton>
						<input
							type="text"
							style={{ width: 80, marginLeft: 10 }}
							value={this.state.sAskUserNm}
							placeholder="이름"
							onChange={(e) => this.setState({ sAskUserNm: e.target.value })}
						/>
					</td>
					<td />
					<td />
				</tr>
			);
		} else {
			return (
				<tr>
					<th>지원담당자</th>
					<td colSpan="3">
						<input
							type="text"
							style={{ width: 100, marginRight: 10 }}
							value={this.state.sUserChgId}
							placeholder="ID"
							onChange={(e) => this.setState({ sUserChgId: e.target.value })}
						/>
						<IconButton onClick={() => this.userSearch('user')} color="secondary" className="iconButton">
							<SearchIcon fontSize="small" />
						</IconButton>
						<input
							type="text"
							style={{ width: 80, marginLeft: 10 }}
							value={this.state.sUserChgNm}
							placeholder="이름"
							onChange={(e) => this.setState({ sUserChgNm: e.target.value })}
						/>
					</td>
				</tr>
			);
		}
	};

	contInfoCheck = () => {
		KYContInfoCheckPopupStore.openPopup(this.state.custNo, this.state.custReqNo);
	};

	render() {
		let appInfoStyle = {
			textAlign: 'center',
			border: 0,
		};

		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mb30">
								<h2>반납신청조회(수기)</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.getReturnAskList}>
										<span>조회</span>
									</a>
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '90px' }} />
										<col style={{ width: '280px' }} />
										<col style={{ width: '90px' }} />
										<col style={{ width: '150px' }} />
										<col style={{ width: '90px' }} />
										<col />
									</colgroup>
									<tbody>
										<tr>
											<th>고객명</th>
											<td>
												<input type="text" readOnly={true} style={{ marginRight: 10, width: 220 }} value={this.state.sCustNm} />
												<IconButton onClick={this.customerSearch} color="secondary" className="iconButton" style={{ display: sessionStorage.btnControl }}>
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
											<th>반납상태</th>
											<td>
												<select style={{ width: 120 }} onChange={(e) => this.setState({ sProcFlag: e.target.value })}>
													<option value="">전체</option>
													{this.state.returnStatusList.map((item) => (
														<option value={item.value}>{item.title}</option>
													))}
												</select>
											</td>
											<th>자산번호</th>
											<td>
												<input
													type="text"
													style={{ marginRight: 10, width: 120 }}
													value={this.state.sAsstNo}
													onChange={(e) => this.setState({ sAsstNo: e.target.value })}
												/>
											</td>
										</tr>
										{this.makeUserChgInput()}
									</tbody>
								</table>
							</div>
							<div className="writeType03" style={{ float: 'left', width: '37%' }} onClick={this.getReturnAskData}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">반납요청리스트</p>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
							<div style={{ float: 'right', width: '58%', height: '600px' }}>
								<p className="headline">요청정보</p>
								<div className="writeType03_noborder">
									<table>
										<colgroup>
											<col style={{ width: 100 }} />
											<col style={{ width: 200 }} />
											<col style={{ width: 90 }} />
											<col style={{ width: 160 }} />
										</colgroup>
										<tbody>
											<tr>
												<th>고객명</th>
												<td>
													<input type="text" readOnly={true} value={this.state.custNm} />
												</td>
												<th>이메일</th>
												<td><input type="text" readOnly={true} style={{fontSize:12}} value={this.state.email} /></td>
											</tr>
											<tr>
												<th>자산번호</th>
												<td>
													<input type="text" readOnly={true} value={this.state.asstNo} />
												</td>
												<th>계약번호</th>
												<td>
													<input type="text" readOnly={true} value={this.state.contNo} />
												</td>
											</tr>
											<tr>
												<th>반납상태</th>
												<td>
													<input type="text" readOnly={true} value={this.state.procFlagNm} />
												</td>
												<th>회수희망일자</th>
												<td>
													<input type="text" readOnly={true} value={this.state.returnHopeDate} />
												</td>
											</tr>
											<tr>
												<th>반납사유</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.returnRmk} />
												</td>
											</tr>
											<tr>
												<th>회수지주소</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.postAddr + ' ' + this.state.postSddr} />
												</td>
											</tr>
											<tr>
												<th>담당자</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.addrUser + ' ( ' + this.state.addrTelNo + ' / ' + this.state.addrHpNo + ' )'} />
												</td>
											</tr>
											<tr>
												<th>처리내용</th>
												<td colSpan="3">
													<input type="text" value={this.state.procMsg} onChange={this.handleChange}/>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div style={{ float: 'right' }}>
									<Button
										color="primary"
										variant="outlined"
										size="small"
										style={{ alignItems: 'initial', marginRight: 5 }}
										onClick={() => this.clickUpdateReturnAskManual(0)}
									>
										<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
										반납처리중
									</Button>
									<Button
										color="primary"
										variant="outlined"
										size="small"
										style={{ alignItems: 'initial', marginRight: 5 }}
										onClick={() => this.clickUpdateReturnAskManual(1)}
									>
										<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
										반납완료
									</Button>
									<Button
										color="primary"
										variant="outlined"
										size="small"
										style={{ alignItems: 'initial' }}
										onClick={() => this.clickUpdateReturnAskManual(2)}
									>
										<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
										반납취소
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(ReturnAskManual);
