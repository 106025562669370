import React, { Component } from 'react';
import { ComIBSheetComboNull, errorMsgCheck, resultToIbSheet, SheetParamData3 } from '../../common/ComFunction';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';
import Header from '../include/Header';
import { IconButton } from '@material-ui/core';
import { Search, ThumbUpSharp } from '@material-ui/icons';
import { getCustApiAllocList, saveCustApiAlloc, getAllocList, getNoAllocList } from '../../service/admin/CustApiAllocManagerService';
import { getCisCommCode } from '../../service/CommonService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ProgressStore, CustSearchPopupStore } = CommonStore;

var result = {
	data: [],
};
class ApiAllocManager extends Component {
	constructor(props) {
		super(props);
		this.sheetData = {
			id: 'custApiAllocSheet', //sheet id
			initData: 'admin/Init-CustApiAllocManager', // sheet data javascript filename
			width: '100%', // sheet width
			height: '250px', // sheet height
		};

		this.sheetData2 = {
			id: 'apiAllocSheet',
			initData: 'admin/Init-ApiYesAllocManager',
			width: '100%',
			height: '250px',
		};

		this.sheetData3 = {
			id: 'apiNoAllocSheet',
			initData: 'admin/Init-ApiNotAllocManager',
			width: '100%',
			height: '250px',
		};
	}

	state = {
		// 검색조건
		custNoSearch: '', //고객사코드 검색
		custNmSearch: '', // 고객사명
		cnSrchInpt: 'S', //고객사검색검색조건/입력항목 여부('S':검색조건/'I':입력항목)

		// 조회결과
		custVerifyList: [], // 검증키 할당리스트
		apiAllocList: [], // api 할당 정보 리스트
		apiNoAllocList: [], // api 미할당 정보 리스트

		// api할당 변수 정보
		custNo: '', // 고객번호
		verifySeq: '', // 검증순번
	};

	componentDidMount = () => {
		ProgressStore.activeProgress();
		//공통코드 조회
		Promise.all([getCisCommCode('APICT')]).then((r) => {
			let [codeApiCateData] = r;
			const timer = setInterval(() => {
				if (window[this.sheetData2.id] && window[this.sheetData3.id]) {
					ComIBSheetComboNull('0', window[this.sheetData2.id], 'apiCate', codeApiCateData, 'title', 'value', '', '');
					ComIBSheetComboNull('0', window[this.sheetData3.id], 'apiCate', codeApiCateData, 'title', 'value', '', '');
					ProgressStore.deactiveProgress();
					clearInterval(timer);
				}
			}, 100);
		});
	};

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
		window[this.sheetData2.id].DisposeSheet();
		window[this.sheetData3.id].DisposeSheet();
	};

	// api 할당 리스트 조회
	getCustApiAllocList = () => {
		let param = {
			custNo: this.state.custNoSearch,
		};

		ProgressStore.activeProgress();

		getCustApiAllocList(param)
			.then((r) => {
				this.setState({ custApiAllocList: r }, () => {
					window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				});
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				ProgressStore.deactiveProgress();
				errorMsgCheck(err);
			});
	};

	// api 할당 내역 저장
	saveCustApiAlloc = () => {
		const sheet2 = window[this.sheetData2.id];
		const sheet3 = window[this.sheetData3.id];

		const sheetParam2 = sheet2.GetSaveJson(0);
		const sheetParam3 = sheet3.GetSaveJson(0);

		var sRow2 = null;
		var sRow3 = null;
		var sheetCheck = true;

		// 저장할 내용이 있는지 체크
		if (sheetCheck) {
			sRow2 = sheet2.FindStatusRow('I|U|D');
			sRow3 = sheet3.FindStatusRow('I|U|D');

			if (sRow2 == '' && sRow3 == '') {
				ModalStore.openModal('저장할 내역이 없습니다.');
				return;
			}
		}
		ProgressStore.activeProgress();

		if (
			(sheetParam2 && sheetParam2.data.length > 0 && sheetParam2.Message != 'KeyFieldError') ||
			(sheetParam3 && sheetParam3.data.length > 0 && sheetParam3.Message != 'KeyFieldError')
		) {
			Promise.all([saveCustApiAlloc(sheetParam2), saveCustApiAlloc(sheetParam3)])
				.then((r) => {
					ModalStore.openModal('저장되었습니다.');

					this.getCustApiAllocList();
					this.getAllocList(); // 저장 성공 후 재조회
					this.getNoAllocList();

					ProgressStore.deactiveProgress();
				})
				.catch((err) => {
					errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
				});
		} else {
			ProgressStore.deactiveProgress();
		}
	};

	// api 할당 리스트
	getAllocList = () => {
		ProgressStore.activeProgress();
		let param = {
			custNo: this.state.custNo,
			verifySeq: this.state.verifySeq,
		};
		getAllocList(param)
			.then((r) => {
				this.setState({ apiAllocList: r }, () => {
					window[this.sheetData2.id].LoadSearchData(resultToIbSheet(r));
				});
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};

	// api 미할당 리스트
	getNoAllocList = () => {
		ProgressStore.activeProgress();
		let param = {
			custNo: this.state.custNo,
			verifySeq: this.state.verifySeq,
		};
		getNoAllocList(param)
			.then((r) => {
				this.setState({ apiNoAllocList: r }, () => {
					window[this.sheetData3.id].LoadSearchData(resultToIbSheet(r));
				});
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};

	// api 할당 관리 컬럼 추가
	addCustApiAllocManager = () => {
		const sheet = window[this.sheetData.id];
		sheet.DataInsert(-1);
	};

	// 고객사팝업
	handleClickOpen = (popupType) => {
		CustSearchPopupStore.openPopup('1', null, null, this.handleClose);
	};

	handleClose = (item) => {
		if (item !== undefined) {
			this.setState({
				custNoSearch: item.custNo,
				custNmSearch: item.custNm,
			});
		}
	};

	setApiList = () => {
		let data = window.getCustApiAllocData();

		if (window[this.sheetData.id].RowCount() > 0) {
			if (data.custNo != undefined) {
				this.setState(
					{
						custNo: data.custNo,
						verifySeq: data.verifySeq,
					},
					() => {
						this.getAllocList();
						this.getNoAllocList();
					},
				);
			}
		}
	};
	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mb30">
								<h2>API 할당 관리</h2>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '10%' }} />
										<col style={{ width: '30%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>고객</th>
											<td>
												<input
													type="text"
													className="size01"
													ref={(ref) => (this.custNmSearch = ref)}
													name="custNmSearch"
													value={this.state.custNmSearch || ''}
													onChange={() => {
														this.setState({ custNoSearch: '', custNmSearch: '' });
													}}
													style={{ width: 170 }}
												/>
												{/* 고객사검색버튼 */}
												<IconButton
													aria-label="Search"
													className="iconButton"
													onClick={() => {
														this.handleClickOpen('1');
														this.setState({ cnSrchInpt: 'S' });
													}}
												>
													<Search fontSize="small"></Search>
												</IconButton>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="btnRight3">
								<a className="btn01 search" onClick={this.getCustApiAllocList}>
									<span>조회</span>
								</a>
								{/* <a className="btn01 add" onClick={this.addCustApiAllocManager}>
										<span>추가</span>
									</a>
									<a className="btn01 save" onClick={this.saveCustApiAlloc}>
										<span>저장</span>
									</a> */}
							</div>

							<div className="writeType05 short">
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
									onClick={() => {
										this.setApiList();
									}}
								>
									<IBSheet {...this.sheetData} />
								</div>
							</div>

							<div className="btnRight3">
								<a className="btn01 save" onClick={this.saveCustApiAlloc}>
									<span>저장</span>
								</a>
							</div>

							<div className="writeType03" style={{ float: 'left', width: '49%', marginTop: 20 }}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">할당</p>
									<IBSheet {...this.sheetData2} />
								</div>
							</div>
							<div className="writeType03_acc" style={{ float: 'right', width: '49%', marginTop: 20 }}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">미할당</p>
									<IBSheet {...this.sheetData3} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(ApiAllocManager);
