import { httpGet, httpPost, httpPut, toQueryString } from '../Service';
//FMS IOS 앱 다운로드 url 조회
export function getAppBuildInfo(data) {
	return httpGet('/api/v1.0/cis/app/appBuild' + toQueryString(data));
}

// 조회
export function getAppVerList(data) {
	return httpGet('/api/v1.0/cis/app/ver' + toQueryString(data));
}

// 저장(C/U/D)
export function saveAppVer(data) {
	data = data || {};
	return httpPut('/api/v1.0/cis/app/ver/', { body: data });
}

// 조회
export function getAppBuildList(data) {
	return httpGet('/api/v1.0/cis/app/build' + toQueryString(data));
}

// 저장(C/U/D)
export function saveAppBuild(data) {
	data = data || {};
	return httpPut('/api/v1.0/cis/app/build/', { body: data });
}
