import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/CheckBox';
import BillIcon from '@material-ui/icons/Receipt';
import Button from '@material-ui/core/Button';

//날짜
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import { getMonthEndList, getMonthEndAssetList, postMonthEnd } from '../../service/rental/RentalMonthEndService';
import { dateToFormat, errorMsgCheck, resultToIbSheet, ozWinOpen } from '../../common/ComFunction';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore } = CommonStore;

class MonthEnd extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sCustNo: sessionStorage.custNo,
			sCustNm: sessionStorage.custNm,
			sEndDate: moment(),

			endId: '',
			bilId: '',
			custSeq: '',
			reqNm: '',
			asstStatus: '',
			model: '',
			serialNo: '',
			maker: '',
			startDate: '',
			endDate: '',
			contNo: '',
			userchg: '',

			/* 시스템정보 */
			confirmBtn: true,
			billBtn: true,
		};

		this.sheetData = {
			id: 'endListSheet', // sheet id
			initData: 'rental/Init-MonthEnd', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '200px', // sheet height
		};

		this.sheetData2 = {
			id: 'endAssetListSheet', // sheet id
			initData: 'rental/Init-MonthEndAsset', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '417px', // sheet height
		};
	}

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
		window[this.sheetData2.id].DisposeSheet();
	};

	searchEnd = () => {
		if (!this.state.sEndDate) {
			ModalStore.openModal('마감월을 선택해 주세요.');
			return;
		}

		let param = {
			custNo: this.state.sCustNo,
			endDate: dateToFormat(this.state.sEndDate).substring(0, 7),
			saleDept: '04',
		};

		ProgressStore.activeProgress();
		getMonthEndList(param)
			.then((r) => {
				window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	endConfirm = () => {
		ConfirmModalStore.openModal(this.state.reqNm + '의 마감을 확정처리 하시겠습니까?', this.endConfirmProc);
	};

	endConfirmProc = () => {
		if (!this.state.endId) {
			ModalStore.openModal('마감을 선택해 주세요.');
			return;
		}

		let param = {
			endId: this.state.endId,
		};

		postMonthEnd(param)
			.then((r) => {
				ModalStore.openModal('확정처리 되었습니다.');

				this.setState({
					confirmBtn: true,
				});
				this.searchEnd();

				//초기화
				window[this.sheetData2.id].RemoveAll();
				this.setAssetInfo(null);
			})
			.catch((err) => {
				errorMsgCheck(err);
			});
	};

	customerSearch = () => {
		CustSearchPopupStore.openPopup('1', null, null, this.handleCustSearch);
	};

	handleCustSearch = (item) => {
		if (item !== undefined) {
			this.setState({
				sCustNo: item.custNo,
				sCustNm: item.custNm,
			});
		}
	};

	getMonthEndAssetList = () => {
		if (window[this.sheetData.id].LastRow() === 0) return;

		let data = window.getMonthEndData();
		if (!data.endId) return;

		//초기화
		this.setAssetInfo(null);
		this.setState({
			endId: data.endId,
			bilId: data.bilId,
			custSeq: data.custSeq,
			reqNm: data.reqNm,
			confirmBtn: data.confirmUser ? true : false,
			billBtn: data.bilId ? false : true,
		});

		let param = {
			endId: data.endId,
			bilId: data.bilId,
		};

		ProgressStore.activeProgress();
		getMonthEndAssetList(param)
			.then((r) => {
				window[this.sheetData2.id].LoadSearchData(resultToIbSheet(r));
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	getMonthEndAssetInfo = () => {
		if (window[this.sheetData2.id].LastRow() === 0) return;

		let data = window.getMonthEndAssetInfo();
		if (!data) return;

		this.setAssetInfo(data);
	};

	setAssetInfo = (data) => {
		if (data) {
			this.setState({
				contNo: data.contNo,
				model: data.model,
				serialNo: data.serialNo,
				maker: data.makeNm,
				startDate: dateToFormat(data.fromDate),
				endDate: dateToFormat(data.expiryDate),
				asstStatus: data.asstStatus,
				billSeq: data.billSeq,
				monthUseDay: data.monthUseDay,
				userChg: data.userChg,
			});
		} else {
			this.setState({
				contNo: '',
				model: '',
				serialNo: '',
				maker: '',
				startDate: '',
				endDate: '',
				asstStatus: '',
				billSeq: '',
				monthUseDay: '',
				endDate: '',
				userChg: '',
			});
		}
	};

	showBill = () => {
		if (!this.state.bilId) {
			ModalStore.openModal('명세서가 발행되지 않았습니다.');
			return;
		}

		ozWinOpen('efrmDiv', '/RD_BIL/BILL_DOC', 'BILL_DOC', 'ozBilId=' + this.state.bilId + '&ozCustSeq=' + this.state.custSeq, '2');
	};

	dateHandler = (date) => {
		this.setState({ sEndDate: date });
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont" style={{ height: 900 }}>
							<div className="h2Wrap mb30">
								<h2>월마감</h2>
								<div className="btnRight">
									<a onClick={this.searchEnd} className="btn01 search">
										<span>조회</span>
									</a>
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '90px' }} />
										<col style={{ width: '280px' }} />
										<col style={{ width: '90px' }} />
										<col />
									</colgroup>
									<tbody>
										<tr>
											<th>고객명</th>
											<td>
												<input type="text" readOnly={true} style={{ marginRight: 10, width: 220 }} value={this.state.sCustNm} />
												<IconButton onClick={this.customerSearch} color="secondary" className="iconButton" style={{ display: sessionStorage.btnControl }}>
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
											<th>마감월</th>
											<td>
												<DatePicker
													customInput={<input type="text" style={{ width: 80 }} />}
													dateFormat="YYYY-MM"
													onChange={(date) => this.dateHandler(date, 's')}
													selected={this.state.sEndDate}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div style={{ marginTop: 15, textAlign: 'right' }}>
								<Button color="secondary" variant="contained" size="small" onClick={this.endConfirm} disabled={this.state.confirmBtn}>
									<CheckIcon style={{ fontSize: 20, marginRight: 5 }} />
									마감확정
								</Button>
								<Button color="primary" variant="contained" size="small" onClick={this.showBill} disabled={this.state.billBtn} style={{ marginLeft: 7 }}>
									<BillIcon style={{ fontSize: 20, marginRight: 5 }} />
									명세서
								</Button>
							</div>
							<div className="writeType03" onClick={this.getMonthEndAssetList}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">청구지별 당월 마감 리스트</p>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
							<div className="writeType03" onClick={this.getMonthEndAssetInfo} style={{ float: 'left', width: '65%' }}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">청구 자산 리스트</p>
									<IBSheet {...this.sheetData2} />
								</div>
							</div>
							<div className="writeType03" style={{ float: 'right', width: '30%' }}>
								<p className="headline">자산상세</p>
								<table>
									<colgroup>
										<col style={{ width: 80 }} />
									</colgroup>
									<tbody>
										<tr>
											<th>자산상태</th>
											<td>
												<input type="text" value={this.state.asstStatus} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										<tr>
											<th>청구회차</th>
											<td>
												<input type="text" value={this.state.billSeq} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										<tr>
											<th>모델</th>
											<td>
												<input type="text" value={this.state.model} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										<tr>
											<th>시리얼</th>
											<td>
												<input type="text" value={this.state.serialNo} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										<tr>
											<th>제조사</th>
											<td>
												<input type="text" value={this.state.maker} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										<tr>
											<th>개시일</th>
											<td>
												<input type="text" value={this.state.startDate} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										<tr>
											<th>만기일자</th>
											<td>
												<input type="text" value={this.state.endDate} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										<tr>
											<th>계약번호</th>
											<td>
												<input type="text" value={this.state.contNo} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										<tr>
											<th>마감담당</th>
											<td>
												<input type="text" value={this.state.userChg} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(MonthEnd);
