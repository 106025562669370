import {httpGet,httpPost,httpPut,toQueryString} from '../Service';

// 메뉴조회
export function getMenuList(data){
    return httpGet('/api/v1.0/cis/menu'+toQueryString(data));
}

// 저장(C/U/D)
export function saveMenu(data){
    data = data || {};
    if(data.tranType === 'I')
        return httpPost('/api/v1.0/cis/menu/',{body:data});
    else if(data.tranType === 'U')
        return httpPut('/api/v1.0/cis/menu/',{body:data});
    else
        return httpPut('/api/v1.0/cis/menu/',{body:data});
}

// 권한별메뉴조회
export function getMenuByAuthList(data){
    return httpGet('/api/v1.0/cis/auth'+toQueryString(data));
}
// 저장(C/U/D)
export function saveAuth(data){
    data = data || {};
    return httpPut('/api/v1.0/cis/auth/',{body:data});
}