import React, { Component } from 'react';
import { errorMsgCheck, dateToFormat, textNewLine, Transition } from '../common/ComFunction';
import { getBoardDetail, insertBoard, updateBoard, getBoardReplyList, updateBoardReply, insertBoardReply } from '../service/HomeService';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import { observer } from 'mobx-react';
import CommonStore from '../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore, FilePopupStore } = CommonStore;

class NoticePopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			boardId: this.props.boardId,
			sCustNo: '', //고객번호
			sCustNm: '', //고객명
			bTitle: '', //제목
			bFile: '', //첨부파일명
			bFileUrl: '', //첨부파일 url
			bContents: '', //내용
			bReadCnt: 0, //조회수
			bWriteId: '', //작성자ID
			bWriteUser: '', //작성자명
			bWriteDate: '', //작성일자
			bReplyContents: '', //댓글쓰기내용

			replyList: [], //댓글리스트

			btnDisplay: 'none', //삭제,수정 버튼 노출
			replBtnDisplay: 'none', //댓글 삭제 버튼
			readOnly: true, //제목,내용 변경 모드
			updateBtnTitle: '수정', //수정버튼 타이틀 수정 <-> 저장
			inputBorder: 0,
		};
	}

	componentDidMount = () => {
		if (this.props.mode === 'read') {
			ProgressStore.activeProgress();

			this.getBoardDetail();
			this.getBoardReplyList();
		}
	};

	getBoardDetail = () => {
		getBoardDetail(this.props.boardId)
			.then((r) => {
				this.setState({
					bTitle: r[0].subject,
					bFile: r[0].fileName,
					bFileUrl: r[0].filePath,
					bCustNm: r[0].custNm,
					bContents: r[0].contents,
					bReadCnt: r[0].readno,
					bWriteId: r[0].userId,
					bWriteUser: r[0].userName,
					bWriteDate: dateToFormat(r[0].writeDate),
				});
			})
			.then(() => {
				//접속자와 글쓴이가 같으면 수정,삭제버튼을 보여 준다.
				if (sessionStorage.userId === this.state.bWriteId) {
					this.setState({
						btnDisplay: 'inline-block',
					});
				}
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err);
			});
	};

	getBoardReplyList = () => {
		getBoardReplyList(this.props.boardId)
			.then((r) => {
				this.setState({
					replyList: r,
					bReplyContents: '',
				});
			})
			.catch((err) => {
				errorMsgCheck(err);
			});
	};

	regBoardCheck = () => {
		if (this.state.bTitle === '') {
			ModalStore.openModal('제목을 입력해 주세요.', this.modalClose);
			return;
		}
		//현업관리자 이상이고 고객번호가 없으면 알려준다.
		var addMsg = '';
		if (sessionStorage.authCode < 2 && !this.state.sCustNo) {
			addMsg = '고객을 선택하지 않으면 전체 공지사항으로 등록 됩니다. ';
		}

		ConfirmModalStore.openModal(addMsg + '등록 하시겠습니까?', this.regBoard);
	};

	regBoard = () => {
		let param = {
			custNo: this.state.sCustNo ? this.state.sCustNo : sessionStorage.custNo,
			boardType: this.props.type,
			subject: this.state.bTitle,
			fileName: this.state.bFile,
			fileUrl: this.state.bFileUrl,
			contents: this.state.bContents,
			instUser: sessionStorage.userId,
		};
		ProgressStore.activeProgress();

		insertBoard(param).then((r) => {
			ModalStore.openModal('등록 되었습니다.', this.modalClose);
			ProgressStore.deactiveProgress();
		});
	};

	regBoardReply = () => {
		if (this.state.bReplyContents === '') {
			ModalStore.openModal('댓글 내용을 입력해 주세요.');
			return;
		}

		let param = {
			boardId: this.state.boardId,
			replyContents: this.state.bReplyContents,
			instUser: sessionStorage.userId,
		};
		ProgressStore.activeProgress();
		insertBoardReply(param).then((r) => {
			ModalStore.openModal('등록 되었습니다.', this.getBoardReplyList);
			ProgressStore.deactiveProgress();
		});
	};

	modalClose = () => {
		if (
			(ConfirmModalStore.modalText.indexOf('삭제') > -1 && ConfirmModalStore.modalText.indexOf('댓글') < 0) ||
			ConfirmModalStore.modalText.indexOf('등록') > -1
		) {
			this.props.hidePopup();
		}
	};

	boardDeleteCheck = () => {
		ConfirmModalStore.openModal('삭제 하시겠습니까?', this.deleteBoard);
	};

	deleteBoard = () => {
		let param = {
			boardId: this.state.boardId,
			delYn: 'Y',
			userId: sessionStorage.userId,
		};
		updateBoard(param).then(() => {
			ModalStore.openModal('삭제 되었습니다.', this.modalClose);
		});
	};

	boardUpdateCheck = () => {
		if (this.state.readOnly) {
			this.setState({
				readOnly: false,
				inputBorder: '1px solid rgba(233, 30, 99, 0.29)',
				updateBtnTitle: '저장',
			});
			return;
		}

		ConfirmModalStore.openModal('수정 하시겠습니까?', this.updateBoard);
	};

	updateBoard = () => {
		let param = {
			boardId: this.state.boardId,
			subject: this.state.bTitle,
			contents: this.state.bContents,
			userId: sessionStorage.userId,
		};
		updateBoard(param)
			.then(() => {
				ModalStore.openModal('수정 되었습니다.', this.modalClose);
			})
			.then(() => {
				this.setState({
					updateBtnTitle: '수정',
					readOnly: true,
				});
				this.getBoardDetail();
			});
	};

	boardReplyDeleteCheck = (cSeq) => {
		ConfirmModalStore.openModal('댓글을 삭제 하시겠습니까?', () => this.deleteBoardReply(cSeq));
	};

	deleteBoardReply = (cSeq) => {
		let param = {
			boardId: this.state.boardId,
			cSeq: cSeq,
			delUser: sessionStorage.userId,
		};
		updateBoardReply(param).then(() => {
			ModalStore.openModal('삭제 되었습니다.', this.modalClose);
			this.getBoardReplyList();
		});
	};

	searchCustomer = () => {
		//관리자만 조회할 수 있다.
		//if(sessionStorage.authCode <= 1){
		//}
		CustSearchPopupStore.openPopup('1', null, null, this.handleCustSearch);
	};

	handleCustSearch = (item) => {
		if (item !== undefined) {
			this.setState({
				sCustNo: item.custNo,
				sCustNm: item.custNm,
			});
		}
	};

	fileUpload = () => {
		FilePopupStore.openPopup('파일', this.handleFileClose);
	};

	handleFileClose = (result) => {
		if (result !== undefined) {
			this.setState({
				bFile: result.fileSaveName,
				bFileUrl: result.fileSavePath,
			});
		}
	};

	activePopup = () => {
		let props = this.props;
		let title = props.type === '1' ? '공지사항' : 'Q&A';

		if (props.mode === 'write') {
			return (
				<div className="popArea qnaUp">
					<div className="popCont">
						<div className="h2Wrap">
							<h2>
								{title} 등록 <span>*는 필수항목입니다.</span>
							</h2>
						</div>
						<div className="writeType02 fileFake">
							<table>
								<colgroup>
									<col style={{ width: '160px' }} />
									<col />
								</colgroup>
								<tbody>
									<tr>
										<th>
											제목<span className="star">*</span>
										</th>
										<td>
											<input type="text" value={this.state.bTitle} onChange={(e) => this.setState({ bTitle: e.target.value })} />
										</td>
									</tr>
									<tr style={{ display: sessionStorage.authCode < 2 ? 'contents' : 'none' }}>
										<th>
											고객<span className="star">*</span>
										</th>
										<td>
											<input type="text" value={this.state.sCustNm} readOnly={true} style={{ width: 300 }} />
											<Button color="primary" variant="contained" size="small" style={{ marginLeft: 10 }} onClick={this.searchCustomer}>
												검색
											</Button>
										</td>
									</tr>
									<tr>
										<th>첨부파일</th>
										<td>
											<input type="text" value={this.state.bFile} style={{ width: 300 }} readOnly={true} />
											<Button color="primary" variant="contained" size="small" style={{ marginLeft: 10 }} onClick={this.fileUpload}>
												업로드
											</Button>
										</td>
									</tr>
									<tr>
										<th>내&nbsp;&nbsp;&nbsp;&nbsp;용</th>
										<td>
											<textarea
												style={{ minHeight: 395 }}
												value={this.state.bContents}
												onChange={(e) => this.setState({ bContents: e.target.value })}
											></textarea>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="btnCenter">
							<a onClick={this.regBoardCheck} className="btn02 blue">
								등록
							</a>
							<a className="btn02 white" onClick={props.hidePopup}>
								취소
							</a>
						</div>
						<div className="btnClose">
							<a onClick={props.hidePopup}>팝업닫기</a>
						</div>
					</div>
				</div>
			);
		} else if (props.mode === 'read') {
			return (
				<div className="popArea qnaList">
					<div className="popCont">
						<div className="h2Wrap">
							<h2>{title}</h2>
						</div>
						<div className="pop scrollWrap">
							<div className="writeType02 mb0">
								<table>
									<colgroup>
										<col style={{ width: '100px' }} />
										<col style={{ width: '180px' }} />
										<col style={{ width: '100px' }} />
										<col style={{ width: '100px' }} />
										<col style={{ width: '100px' }} />
										<col style={{ width: '70px' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>제목</th>
											<td colSpan="3">
												<input
													type="text"
													style={{ border: this.state.inputBorder }}
													value={this.state.bTitle}
													onChange={(e) => this.setState({ bTitle: e.target.value })}
													readOnly={this.state.readOnly}
												/>
											</td>
											<th>작성자</th>
											<td>{this.state.bWriteUser}</td>
										</tr>
										<tr>
											<th>고객명</th>
											<td>{this.state.bCustNm}</td>
											<th>작성일</th>
											<td>{this.state.bWriteDate}</td>
											<th>조회수</th>
											<td>{this.state.bReadCnt}</td>
										</tr>
										<tr>
											<th>첨부파일</th>
											<td colSpan="5">
												{this.state.bFileUrl ? (
													<a href={this.state.bFileUrl} target="_blank" className="file">
														{this.state.bFile}
													</a>
												) : (
													<a className="file">첨부파일없음</a>
												)}
											</td>
										</tr>
										<tr>
											<td colSpan="6" className="contTd">
												<div className="content">
													<textarea
														value={this.state.bContents}
														style={{ border: this.state.inputBorder }}
														readOnly={this.state.readOnly}
														onChange={(e) => this.setState({ bContents: e.target.value })}
													></textarea>
													{/* textNewLine(this.state.bContents) */}
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="repleWrap">
								<div className="white">
									<div className="tit">댓글쓰기</div>
									<div className="text">
										<textarea value={this.state.bReplyContents} onChange={(e) => this.setState({ bReplyContents: e.target.value })}></textarea>
									</div>
									<div className="btnUp">
										<a className="btn01" onClick={this.regBoardReply}>
											<span>올리기</span>
										</a>
									</div>
								</div>
								<div className="repleList">
									<ul>
										{this.state.replyList.map((item, i) => (
											<li key={i}>
												<div className="name">
													{item.cUserNm}
													<span
														style={{
															marginLeft: 10,
															color: '#FA5858',
															cursor: 'pointer',
															display: sessionStorage.userName === item.cUserNm ? 'inline-block' : 'none',
														}}
														onClick={() => this.boardReplyDeleteCheck(item.cSeq)}
													>
														[삭제]
													</span>
												</div>
												<div className="text">{textNewLine(item.cMemo)}</div>
												<div className="date">{dateToFormat(item.cDate)}</div>
											</li>
										))}
									</ul>
								</div>
							</div>
							<div className="btnCenter">
								<a className="btn02 white" style={{ display: this.state.btnDisplay }} onClick={this.boardDeleteCheck}>
									삭제
								</a>
								<Tooltip title="수정버튼을 클릭하면 제목과 내용을 수정하실 수 있습니다." placement="top">
									<a className="btn02 white" style={{ display: this.state.btnDisplay }} onClick={this.boardUpdateCheck}>
										{this.state.updateBtnTitle}
									</a>
								</Tooltip>
								<a className="btn02 white" onClick={props.hidePopup}>
									닫기
								</a>
							</div>
						</div>

						<div className="btnClose">
							<a onClick={props.hidePopup}>팝업닫기</a>
						</div>
					</div>
				</div>
			);
		}
	};

	render() {
		return <div>{this.activePopup()}</div>;
	}
}
export default observer(NoticePopup);
