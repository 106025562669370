import axios from 'axios';
class FileConnectionService {

  constructor() {
    console.log("Service is constructed");
  }

  getRestClient() {
      //console.log(window.location.protocol+'//'+window.location.hostname+(window.location.port ? ':'+window.location.port: ''));
    let url = window.location.protocol+'//'+window.location.hostname+(window.location.port ? ':'+window.location.port: '');
    if (!this.serviceInstance) {
      this.serviceInstance = axios.create({
        // baseURL: 'http://localhost:3000/',
        baseURL: url,
        // timeout: 10000,
        headers: {
            'Content-Type': 'application/json'
          },
      });
    }
    return this.serviceInstance;
  }
}

export default (new FileConnectionService());