import React, { Component } from 'react';
import { Line, Bar } from 'react-chartjs-2';

import { errorMsgCheck } from '../common/ComFunction';
import { getMonthOrderCnt, getMonthTotRentAssetCnt, getMonthEndAssetSearch } from '../service/HomeService';

import { observer } from 'mobx-react';
import CommonStore from '../common/CommonStore';
const { ProgressStore } = CommonStore;

class Chart extends Component {
	constructor(props) {
		super(props);

		this.state = {
			monthOrdeChartLabel: [],
			monthOrdeChartData: [],
			monthRentChartData: [],
			monthEndAssetChartLabel: [],
			monthEndAssetChartData: [],
		};
	}

	componentDidMount = () => {
		ProgressStore.activeProgress();
		let custNo = sessionStorage.custNo;

		if (sessionStorage.authCode > 1) {
			Promise.all([getMonthOrderCnt(custNo), getMonthTotRentAssetCnt(custNo), getMonthEndAssetSearch(custNo)])
				.then((r) => {
					let [orderCnt, totRentAssetCnt, endAssetCnt] = r;
					let chart1Label = [];
					let chart1Data = [];
					let chart1Data2 = [];
					let chart2Label = [];
					let chart2Data = [];

					for (var j in orderCnt) {
						chart1Label[j] = orderCnt[j].yyyymm;
						chart1Data[j] = orderCnt[j].orderCnt;
						chart1Data2[j] = totRentAssetCnt[j] === undefined ? '' : totRentAssetCnt[j].totRentCnt;
					}

					for (var k in endAssetCnt) {
						chart2Label[k] = endAssetCnt[k].item;
						chart2Data[k] = endAssetCnt[k].cnt;
					}

					this.setState({
						monthOrdeChartLabel: chart1Label,
						monthOrdeChartData: chart1Data,
						monthRentChartData: chart1Data2,
						monthEndAssetChartLabel: chart2Label,
						monthEndAssetChartData: chart2Data,
					});

					ProgressStore.deactiveProgress();
				})
				.catch((err) => {
					errorMsgCheck(err);
				});
		}
	};

	render() {
		return (
			<div>
				<div className="box">
					<div className="h2Wrap border">
						<h2>발주 수량</h2>
					</div>
					<div className="graph">
						<Line
							data={{
								labels: this.state.monthOrdeChartLabel,
								datasets: [
									{
										label: '월별발주수량',
										data: this.state.monthOrdeChartData,
										backgroundColor: 'rgba(61, 173, 204, 0.5)',
										borderColor: 'rgba(74, 169, 186, 1)',
										//hoverBackgroundColor: 'rgba(255,99,132,0.4)',
										//hoverBorderColor: 'rgba(255,99,132,1)',
										borderWidth: 2,
									} /*,{
                                    label: '월별총렌트수량추이',
                                    data: this.state.monthRentChartData,
                                    backgroundColor: 'rgba(138, 242, 96, 0.5)',//'rgba(255, 99, 132, 0.5)',
                                    borderColor: 'rgba(92, 232, 109, 1)',//'rgba(255,99,132,1)',
                                    type: 'line',
                                    borderWidth: 2
                                }*/,
								],
							}}
							options={{
								maintainAspectRatio: false,
							}}
						/>
					</div>
				</div>
				<div className="box">
					<div className="h2Wrap border">
						<h2>당월 만기자산</h2>
					</div>
					<div className="graph">
						<Bar
							data={{
								labels: this.state.monthEndAssetChartLabel,
								datasets: [
									{
										label: '항목별 만기자산 수량',
										data: this.state.monthEndAssetChartData,
										backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
										hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
										borderWidth: 2,
									},
								],
							}}
							options={{
								maintainAspectRatio: false,
								scales: {
									yAxes: [
										{
											ticks: {
												beginAtZero: true,
											},
										},
									],
								},
							}}
						/>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(Chart);
