import { Button, Dialog } from '@material-ui/core';
import React, { Component } from 'react';
import { errorMsgCheck } from '../../common/ComFunction';
import { getContInfoKY } from '../../service/admin/ContractService';
import { getApiKywonKey } from '../../service/CommonService';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ProgressStore, KYContInfoCheckPopupStore } = CommonStore;

class KYContInfoCheckPopup extends Component {
	constructor(props) {
		super(props);
	}

	componentDidUpdate = () => {
		if (KYContInfoCheckPopupStore.open && !KYContInfoCheckPopupStore.searched) {
			this.getKYContInfo();
		}
	};

	// 교원 전용 계약정보 확인
	getKYContInfo = () => {
		let param = {
			custNo: KYContInfoCheckPopupStore.custNo,
			custReqNo: KYContInfoCheckPopupStore.custReqNo,
		};

		ProgressStore.activeProgress();
		console.log(param);
		// 교원에서 부여하는 인증키 가져오기
		getApiKywonKey(param)
			.then((r) => {
				KYContInfoCheckPopupStore.setSearched(true);

				let apiKywonKey = r[0].key;

				let param2 = {
					key: apiKywonKey,
					orderNo: param.custReqNo,
					custNo: param.custNo,
				};
				console.log(param2);

				// 인증키 + 고객요청번호를 통해 계약정보 가져오기
				getContInfoKY(param2)
					.then((r) => {
						KYContInfoCheckPopupStore.setContInfo(r[0]);
					})
					.catch((err) => {
						errorMsgCheck(err);
					})
					.finally(() => {
						ProgressStore.deactiveProgress();
					});
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	render() {
		let popContHeight = {
			height: 600,
		};

		let orderZip =
			KYContInfoCheckPopupStore.contInfo.orderZip1 === undefined && KYContInfoCheckPopupStore.contInfo.orderZip2 === undefined
				? ''
				: KYContInfoCheckPopupStore.contInfo.orderZip1 + KYContInfoCheckPopupStore.contInfo.orderZip2;

		return (
			<div>
				<Dialog open={KYContInfoCheckPopupStore.open} onClose={KYContInfoCheckPopupStore.closePopup}>
					<div className="popSmArea searchPop">
						<div className="popCont" style={popContHeight}>
							<div className="h2Wrap">
								<h2>계약정보 확인</h2>
							</div>

							<div className="writeType02 type2">
								<table>
									<colgroup>
										<col style={{ width: '20%' }} />
										<col style={{ width: '80%' }} />

										<col />
									</colgroup>
									<tbody>
										<tr>
											<th>주문번호</th>
											<td>
												<input type="text" name="orderNo" className="size01" value={KYContInfoCheckPopupStore.contInfo.orderNo} readOnly={true} />
											</td>
										</tr>
										<tr>
											<th>전화번호</th>
											<td>
												<input type="text" name="telNo" className="size01" value={KYContInfoCheckPopupStore.contInfo.orderTelNo} readOnly={true} />
											</td>
										</tr>
										<tr>
											<th>핸드폰번호</th>
											<td>
												<input type="text" name="cellPhone" className="size01" value={KYContInfoCheckPopupStore.contInfo.orderCellPhonNo} readOnly={true} />
											</td>
										</tr>
										<tr>
											<th>주소</th>
											<td>
												<input type="text" name="postCode" className="size01" value={orderZip} readOnly={true} style={{ marginRight: '5%', width: '20%' }} />

												<input
													type="text"
													name="addr"
													className="size01"
													value={KYContInfoCheckPopupStore.contInfo.orderAddress1}
													readOnly={true}
													style={{ width: '75%' }}
												/>
											</td>
										</tr>
										<tr>
											<th>상세주소</th>
											<td>
												<input
													type="text"
													name="addrDtl"
													className="size01"
													value={KYContInfoCheckPopupStore.contInfo.orderAddress2}
													readOnly={true}
													style={{ width: '100%' }}
												/>
											</td>
										</tr>
										<tr>
											<th>배송요청일</th>
											<td>
												<input
													type="text"
													name="delDate"
													className="size01"
													value={KYContInfoCheckPopupStore.contInfo.orderSubscriptionStartDate}
													readOnly={true}
													style={{ width: '100%' }}
												/>
											</td>
										</tr>
										<tr>
											<th>요청상태</th>
											<td>
												<input
													type="text"
													name="orderStatus"
													className="size01"
													value={KYContInfoCheckPopupStore.contInfo.orderStatus}
													readOnly={true}
													style={{ width: '100%' }}
												/>
											</td>
										</tr>
										<tr>
											<th>매입여부</th>
											<td>
												<input
													type="text"
													name="takeOverYn"
													className="size01"
													value={KYContInfoCheckPopupStore.contInfo.takeOverYn}
													readOnly={true}
													style={{ width: '100%' }}
												/>
											</td>
										</tr>
										<tr>
											<th>누적사용일수(학습일수)</th>
											<td>
												<input
													type="text"
													name="takeOverYn"
													className="size01"
													value={KYContInfoCheckPopupStore.contInfo.studyDays}
													readOnly={true}
													style={{ width: '100%' }}
												/>
											</td>
										</tr>
									</tbody>
								</table>

								<div style={{ marginTop: 15, textAlign: 'right', display: KYContInfoCheckPopupStore.handleClose ? 'block' : 'none' }}>
									<Button
										color="primary"
										variant="outlined"
										size="small"
										onClick={() => {
											KYContInfoCheckPopupStore.closePopup(KYContInfoCheckPopupStore.contInfo);
										}}
									>
										<ChangeCircleIcon />
										변경
									</Button>
								</div>
							</div>

							<div
								className="btnClose"
								onClick={() => {
									KYContInfoCheckPopupStore.closePopup(undefined);
								}}
							>
								<a>팝업닫기</a>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}
}

export default observer(KYContInfoCheckPopup);
