import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { getUserId, getUserPw } from '../service/CommonService';
import { errorMsgCheck, Transition } from '../common/ComFunction';

import { observer } from 'mobx-react';
import CommonStore from '../common/CommonStore';
const { ModalStore, ProgressStore } = CommonStore;

class FindIdPw extends Component {
	state = {
		findIdName: '', //이름_id찾기
		findIdMail: '', //이메일_id찾기
		findPwId: '', //아이디_pw찾기
		findPwMail: '', //이메일_pw찾기

		idInfo: [], //id찾기_result
	};
	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
		});
	};
	resetState = (now, idx) => {
		this.setState({
			findIdName: '', //이름_id찾기
			findIdMail: '', //이메일_id찾기
			findPwId: '', //아이디_pw찾기
			findPwMail: '', //이메일_pw찾기
		});
		// 20181022 kkj state가 변경되도 input 값이 남아있어 ref로 강제 초기화
		if (now == 0 && idx == 0) {
			this.findIdName.value = '';
			this.findIdMail.value = '';
		} else if (now == 1 && idx == 1) {
			this.findPwId.value = '';
			this.findPwMail.value = '';
		}
	};

	findUserId = () => {
		if (this.state.findIdName === '' || this.state.findIdMail === '') {
			ModalStore.openModal('이름과 이메일을 모두 입력하세요.');
			return;
		}
		ProgressStore.activeProgress();
		let param = {
			userName: this.state.findIdName,
			emailAddr: this.state.findIdMail,
		};

		getUserId(param)
			.then((r) => {
				// this.dataLoading(false);
				let msg = '';
				if (r[0].result === 'Y') {
					this.setState({
						idInfo: r[0],
					});
					msg = '입력한 메일주소로 ID정보를 전송했습니다.';
				} else {
					msg = '일치하는 사용자가 없습니다.';
				}
				ModalStore.openModal(msg);
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, this.props);
			});
	};

	findUserPw = () => {
		if (this.state.findPwId === '' || this.state.findPwMail === '') {
			ModalStore.openModal('아이디와 이메일을 모두 입력하세요.');
			return;
		}
		ProgressStore.activeProgress();
		let param = {
			userId: this.state.findPwId,
			emailAddr: this.state.findPwMail,
		};

		getUserPw(param)
			.then((r) => {
				// this.dataLoading(false);
				let msg = '';
				if (r[0].result === 'Y') {
					this.setState({
						idInfo: r[0],
					});
					msg = '입력한 메일주소로 패스워드 정보를 전송했습니다.';
				} else {
					msg = '일치하는 사용자가 없습니다.';
				}
				ModalStore.openModal(msg);
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, this.props);
			});
	};

	// 분기에 따라 render하므로 함수로 뺐다
	activePopup = () => {
		const { open, openIdx, handleClickOpen, handleClose } = this.props;
		if (openIdx == 0) {
			// id찾기
			return (
				<div>
					<Dialog open={open} onClose={handleClose}>
						<div className="popArea idpwSearch">
							<div className="bg"></div>
							<div className="popCont">
								<div className="tab">
									<a
										href="javascript:;"
										onClick={() => {
											handleClickOpen(0);
											this.resetState(`${openIdx}`, 1);
										}}
										className="active"
									>
										아이디 찾기
									</a>
									{/* <a href="javascript:;" onClick={() => {handleClickOpen(1); this.resetState(`${openIdx}`,0)}}>비밀번호 찾기</a> */}
								</div>
								<div className="tabBox">
									<div className="cont">
										<div className="inputLine">
											<div className="tit">이&nbsp;&nbsp;&nbsp;름</div>
											<div className="input">
												<input
													type="text"
													ref={(ref) => (this.findIdName = ref)}
													name="findIdName"
													placeholder="이름을 입력해 주세요."
													value={this.state.findIdName}
													onChange={this.handleChange}
												/>
											</div>
										</div>
										<div className="inputLine">
											<div className="tit">이메일</div>
											<div className="input">
												<input
													type="text"
													ref={(ref) => (this.findIdMail = ref)}
													name="findIdMail"
													placeholder="가입한 이메일 주소를 입력해 주세요."
													value={this.state.findIdMail}
													onChange={this.handleChange}
												/>
											</div>
										</div>
										<div className="btn">
											<a onClick={this.findUserId}>
												<span>아이디 찾기</span>
											</a>
										</div>
									</div>
								</div>
								<div className="btnClose">
									<a href="javascript:;" onClick={handleClose}>
										팝업닫기
									</a>
								</div>
							</div>
						</div>
					</Dialog>
				</div>
			);
		} else if (openIdx == 1) {
			// pw찾기
			return (
				<div>
					<Dialog open={open} onClose={handleClose}>
						<div className="popArea idpwSearch">
							<div className="bg"></div>
							<div className="popCont">
								<div className="tab">
									<a
										href="javascript:;"
										onClick={() => {
											handleClickOpen(0);
											this.resetState(`${openIdx}`, 1);
										}}
									>
										아이디 찾기
									</a>
									<a
										href="javascript:;"
										onClick={() => {
											handleClickOpen(1);
											this.resetState(`${openIdx}`, 0);
										}}
										className="active"
									>
										비밀번호 찾기
									</a>
								</div>
								<div className="tabBox">
									<div className="cont">
										<div className="inputLine">
											<div className="tit">아이디</div>
											<div className="input">
												<input
													type="text"
													ref={(ref) => (this.findPwId = ref)}
													name="findPwId"
													placeholder="아이디를 입력해 주세요."
													value={this.state.findPwId}
													onChange={this.handleChange}
												/>
											</div>
										</div>
										<div className="inputLine">
											<div className="tit">이메일</div>
											<div className="input">
												<input
													type="text"
													ref={(ref) => (this.findPwMail = ref)}
													name="findPwMail"
													placeholder="가입한 이메일 주소를 입력해 주세요."
													value={this.state.findPwMail}
													onChange={this.handleChange}
												/>
											</div>
										</div>

										<div className="btn">
											<a onClick={this.findUserPw}>
												<span>비밀번호 찾기</span>
											</a>
										</div>
									</div>
								</div>
								<div className="btnClose">
									<a href="javascript:;" onClick={handleClose}>
										팝업닫기
									</a>
								</div>
							</div>
						</div>
					</Dialog>
				</div>
			);
		}
	};
	render() {
		return <div>{this.activePopup()}</div>;
	}
}

export default observer(FindIdPw);
