import React, { Component } from 'react';
import IBSheet from '../IBSheet';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';

import { customCustList, saveCustomCust, customMngList, saveCustomMng } from '../../service/admin/CustomizeManagerSerivce';
import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
import { errorMsgCheck, resultToIbSheet, SheetParamData3 } from '../../common/ComFunction';
const { ModalStore, ProgressStore } = CommonStore;

class CustomizeManager extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectCustomCode: '', // 선택한 커스텀코드
		};
		this.sheetData = {
			id: 'customSheetManager',
			initData: 'admin/Init-CustomizeManager',
			width: '100%',
			height: '250px',
		};
		this.sheetData2 = {
			id: 'customDtlSheetManager',
			initData: 'admin/Init-CustomizeDtlManager',
			width: '100%',
			height: '250px',
		};
	}
	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
		window[this.sheetData2.id].DisposeSheet();
	};

	addCustom = () => {
		const sheet = window[this.sheetData.id];
		sheet.DataInsert(-1);
		this.setState({ selectCustomCode: '' });
	};

	addCustomCust = () => {
		if (!this.state.selectCustomCode) return;
		const sheet = window[this.sheetData2.id];
		let newRow = sheet.DataInsert(-1);
		sheet.SetCellValue(newRow, 'customCode', this.state.selectCustomCode);
	};

	setCustomCode = () => {
		let data = window.getCustomData();

		this.setState(
			{
				selectCustomCode: data.customCode,
			},
			() => {
				this.getCustomCustList();
			},
		);
	};
	getCustomList = () => {
		ProgressStore.activeProgress();

		customMngList()
			.then((r) => {
				window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};

	saveCustom = () => {
		ProgressStore.activeProgress();
		const sheet = window[this.sheetData.id];

		let sheetParam = SheetParamData3(sheet, null, true);

		if (sheetParam && sheetParam.data.length > 0 && sheetParam.Message != 'KeyFieldError') {
			saveCustomMng(sheetParam)
				.then((r) => {
					ModalStore.openModal('저장되었습니다.');

					this.getCustomList();
					ProgressStore.deactiveProgress();
				})
				.catch((err) => {
					errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
				});
		} else {
			ProgressStore.deactiveProgress();
		}
	};

	getCustomCustList = () => {
		ProgressStore.activeProgress();

		let param = {
			customCode: this.state.selectCustomCode,
		};

		customCustList(param)
			.then((r) => {
				window[this.sheetData2.id].LoadSearchData(resultToIbSheet(r));
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};

	saveCustomCust = () => {
		ProgressStore.activeProgress();

		const sheet = window[this.sheetData2.id];
		let sheetParam = SheetParamData3(sheet, null, true);
		if (sheetParam && sheetParam.data.length > 0 && sheetParam.Message != 'KeyFieldError') {
			saveCustomCust(sheetParam)
				.then((r) => {
					ModalStore.openModal('저장되었습니다.');

					this.getCustomCustList();
					ProgressStore.deactiveProgress();
				})
				.catch((err) => {
					errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
				});
		} else {
			ProgressStore.deactiveProgress();
		}
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mb30">
								<h2>고객 커스터마이징 관리</h2>
							</div>
							<div className="btnRight2">
								<a className="btn05 search" onClick={this.getCustomList}>
									<span>조회</span>
								</a>
								<a className="btn04 add" onClick={this.addCustom}>
									<span>추가</span>
								</a>
								<a className="btn05 save" onClick={this.saveCustom}>
									<span>저장</span>
								</a>
							</div>
							<div className="writeType05 short">
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
									onClick={this.setCustomCode}
								>
									<p className="headline">커스텀 내용</p>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
							<div className="btnRight3">
								<a className="btn04 add" onClick={this.addCustomCust}>
									<span>추가</span>
								</a>
								<a className="btn05 save" onClick={this.saveCustomCust}>
									<span>저장</span>
								</a>
							</div>
							<div className="writeTyoe05 short">
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">적용할 고객리스트</p>
									<IBSheet {...this.sheetData2} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default observer(CustomizeManager);
