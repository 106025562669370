import React, { Component } from 'react';
import IBSheet from '../IBSheet';
import LeftMenu from '../include/LeftMenu';
import Header from '../include/Header';
import { errorMsgCheck, resultToIbSheet, dateToFormat, buildSelectItems, ComIBSheetComboNull, SheetParamData5 } from '../../common/ComFunction';
import { getApiProcList, apiInputReset } from '../../service/admin/ApiInputManagerSerivce';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

//날짜
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { getCisCommCode } from '../../service/CommonService';
import SendIcon from '@material-ui/icons/Input';
import { Button } from '@material-ui/core';
import ExcelDown from '../include/ExcelDown';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore } = CommonStore;

class ApiInputManager extends Component {
	constructor(props) {
		super(props);
		this.sheetData = {
			id: 'apiProcManagerSheet', // sheet id
			initData: 'admin/Init-ApiProcManager', // sheet data javascript filename
			width: '100%', // sheet width
			height: '300px', // sheet height
		};

		this.sheetData2 = {
			id: 'apiCustOrdeItemManagerSheet', // sheet id
			initData: 'admin/Init-CustOrdeItemManager', // sheet data javascript filename
			width: '100%', // sheet width
			height: '280px', // sheet height
		};
	}

	state = {
		// 조회결과
		apiProcList: [], // api 인터페이스 처리 리스트

		/* 인터페이스 재전송 */
		selSendIfType: '',

		reqApiInputSeq: '', // 요청 apiInputSeq
		apiInputSeq: '', // 조회된 apiInputSeq

		/* api 처리 데이터 */
		apiInputData: '',

		apictCodeList: [], // 공통코드 선언
		apipfCodeList: [], // 공통코드 선언

		/* 렌탈신청 정보 */
		custReqNo: '',
		contTerm: '',
		spotNm: '',
		delUser: '',
		delDept: '',
		delPostCode: '',
		delAddr: '',
		delAddrDtl: '',
		delTelno: '',
		delHpno: '',
		delAskDate: '',
		ordeRmk: '',

		/* 반납신청 정보 */
		asstNo: '',
		addrUser: '',
		postCode: '',
		postAddr: '',
		postSddr: '',
		addrTelno: '',
		addrHpno: '',
		returnHopeDate: '',
		returnRmk: '',

		/* api 구분 */
		apiCate: '',

		/* 검색 */
		custNm: '',
		custNo: '',
		custReqNoSearch: '',
		apiCateSearch: '',
		procFlag: '',

		sDate: moment(),
		eDate: moment(),

		excelList: [],
	};

	componentDidMount = () => {
		ProgressStore.activeProgress();
		Promise.all([getCisCommCode('APICT'), getCisCommCode('APIPF')])
			.then((r) => {
				let [codeApictData, codeApipfData] = r;
				/* 카테고리 제외코드 AH/OCI/OKC/OSS/SC/SR */
				for (let i = 0; i < codeApictData.length; i++) {
					if (
						codeApictData[i].value === 'AH' ||
						codeApictData[i].value === 'OCI' ||
						codeApictData[i].value === 'OKC' ||
						codeApictData[i].value === 'OSS' ||
						codeApictData[i].value === 'SC' ||
						codeApictData[i].value === 'SR'
					) {
						codeApictData.splice(i, 1);
						i--;
					}
				}

				this.setState({
					apictCodeList: codeApictData, // 카테고리 공통코드
					apipfCodeList: codeApipfData, // 처리상태 공통코드
				});
				const timer = setInterval(() => {
					if (window[this.sheetData.id]) {
						ComIBSheetComboNull('0', window[this.sheetData.id], 'apiCate', codeApictData, 'title', 'value', '', '');
						ComIBSheetComboNull('0', window[this.sheetData.id], 'procFlag', codeApipfData, 'title', 'value', '', '');
						clearInterval(timer);
					}
				}, 100);
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
		window[this.sheetData2.id].DisposeSheet();
	};

	customerSearch = () => {
		CustSearchPopupStore.openPopup('1', null, null, this.handleCustClose);
	};

	handleCustClose = (item) => {
		if (item === undefined) return;

		this.setState({
			custNo: item.custNo,
			custNm: item.custNm,
		});
	};

	apiProcList = () => {
		ProgressStore.activeProgress();

		let param = {
			apiInputSeq: this.state.reqApiInputSeq,
			custNo: this.state.custNo,
			custReqNo: this.state.custReqNoSearch,
			apiCate: this.state.apiCateSearch,
			procFlag: this.state.procFlag,
			sDate: dateToFormat(this.state.sDate),
			eDate: dateToFormat(this.state.eDate),
		};

		getApiProcList(param)
			.then((r) => {
				this.setState({ apiProcList: r }, () => {
					window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				});
				//엑셀용
				this.makeExcelData(r);
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	apiOrdeItemList = () => {
		ProgressStore.activeProgress();

		let custOrdeItemList = [];
		let data = window.getApiProcData();
		const obj = JSON.parse(data.apiInputData);
		const ordeDtl = obj.data.ordeDtl;
		let item = {};

		if (ordeDtl) {
			for (var i = 0; i < ordeDtl.length; i++) {
				item.seq = ordeDtl[i].seq;
				item.userName = ordeDtl[i].userName;
				item.userDeptName = ordeDtl[i].userDeptName;
				item.qty = ordeDtl[i].qty;
				item.model = ordeDtl[i].model;

				custOrdeItemList.push(item);
				item = {};
			}
		}
		window[this.sheetData2.id].LoadSearchData(resultToIbSheet(custOrdeItemList));
		ProgressStore.deactiveProgress();
	};

	setOrdeList = () => {
		let data = window.getApiProcData();
		let obj = JSON.parse(data.apiInputData);
		let ordeMstr = obj.data.ordeMstr;
		/* api구분값 저장*/
		this.setState({
			apiCate: data.apiCate,
		});

		if (ordeMstr) {
			/* 고객발주정보 저장 */
			this.setState({
				custReqNo: ordeMstr.custReqNo,
				contTerm: ordeMstr.contTerm,
				spotNm: ordeMstr.spotNm,
				delUser: ordeMstr.delUser,
				delDept: ordeMstr.delDept,
				delPostCode: ordeMstr.delPostCode,
				delAddr: ordeMstr.delAddr,
				delAddrDtl: ordeMstr.delAddrDtl,
				delTelno: ordeMstr.delTelno,
				delHpno: ordeMstr.delHpno,
				delAskDate: ordeMstr.delAskDate,
				ordeRmk: ordeMstr.ordeRmk,
			});
		}

		if (data.apiCate == 'RC') {
			// 렌탈신청 취소일 경우
			this.setCancelOrdeList();
		} else if (data.apiCate == 'RT') {
			// 반납신청일 경우
			this.setReturnList();
		}
	};

	setCancelOrdeList = () => {
		let data = window.getApiProcData();
		console.log(data);
		// let obj = JSON.parse(data.apiInputData);
		let custOrdeItemList = [];
		let item = {};

		let param = {
			// 렌탈신청 취소한 고객요청번호
			custReqNo: data.custReqNo,
			apiCate: 'R',
		};

		getApiProcList(param)
			.then((r) => {
				console.log(r);
				let obj = JSON.parse(r[0].apiInputData);
				let ordeMstr = obj.data.ordeMstr;
				let ordeDtl = obj.data.ordeDtl;
				/* 렌탈신청 취소 정보 */
				this.setState({
					custReqNo: ordeMstr.custReqNo,
					contTerm: ordeMstr.contTerm,
					spotNm: ordeMstr.spotNm,
					delUser: ordeMstr.delUser,
					delDept: ordeMstr.delDept,
					delPostCode: ordeMstr.delPostCode,
					delAddr: ordeMstr.delAddr,
					delAddrDtl: ordeMstr.delAddrDtl,
					delTelno: ordeMstr.delTelno,
					delHpno: ordeMstr.delHpno,
					delAskDate: ordeMstr.delAskDate,
					ordeRmk: ordeMstr.ordeRmk,
				});
				/* 렌탈신청 취소 제품 */
				for (var i = 0; i < ordeDtl.length; i++) {
					item.seq = ordeDtl[i].seq;
					item.userName = ordeDtl[i].userName;
					item.userDeptName = ordeDtl[i].userDeptName;
					item.qty = ordeDtl[i].qty;
					item.model = ordeDtl[i].model;
					custOrdeItemList.push(item);
					item = {};
				}
				window[this.sheetData2.id].LoadSearchData(resultToIbSheet(custOrdeItemList));
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	setReturnList = () => {
		let data = window.getApiProcData();
		let obj = JSON.parse(data.apiInputData);
		let rtnMstr = obj.data.rtnMstr;

		this.setState({
			custReqNo: rtnMstr.custReqNo,
			addrUser: rtnMstr.addrUser,
			postCode: rtnMstr.postCode,
			postAddr: rtnMstr.postAddr,
			postSddr: rtnMstr.postSddr,
			addrTelno: rtnMstr.addrTelno,
			addrHpno: rtnMstr.addrHpno,
			returnHopeDate: rtnMstr.returnHopeDate,
			returnRmk: rtnMstr.returnRmk,
		});
	};

	dateHandler = (date, type) => {
		if (type === 's') {
			this.setState({ sDate: date });
		} else if (type === 'e') {
			this.setState({ eDate: date });
		}
	};

	// 저장
	callResetFunc = () => {
		const sheet = window[this.sheetData.id];
		const FindCheckedRow = sheet.FindCheckedRow('chk');
		const checkRows = FindCheckedRow.split('|');

		if (!FindCheckedRow) {
			ModalStore.openModal('선택된 건이 없습니다.');
			return;
		}

		ConfirmModalStore.openModal('해당건의 처리상태를 초기화 하시겠습니까?', () => {
			// validation chk end
			ProgressStore.activeProgress();
			const sheetParam = SheetParamData5(sheet, null);

			apiInputReset(sheetParam.data)
				.then((r) => {
					ModalStore.openModal('처리상태가 리셋되었습니다.');

					this.apiProcList(); // 재조회
				})
				.catch((err) => {
					errorMsgCheck(err);
				})
				.finally(() => {
					ProgressStore.deactiveProgress();
				});
		});
	};

	makeExcelData = (data) => {
		let tmpList = [];
		let tmp = {};
		data.map((item, idx) => {
			tmp = {};
			tmp.Seq = item.apiInputSeq;
			tmp.고객번호 = item.custNo;
			tmp.요청번호 = item.ordeNo;
			tmp.고객요청번호 = item.custReqNo;
			tmp.계약번호 = item.contNo;
			tmp.API입력값 = item.apiInputData;
			tmp.API구분 = item.apiCateNm;
			tmp.처리상태 = item.procFlag;
			tmp.처리메세지 = item.procMsg;
			tmp.처리일자 = dateToFormat(item.procDate);
			tmpList.push(tmp);
		});
		this.setState({ excelList: tmpList });
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mb30">
								<h2>API 이력조회</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.apiProcList}>
										<span>조회</span>
									</a>
									<ExcelDown data={this.state.excelList} text="엑셀" filename={'API이력조회리스트.csv'} />
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '10%' }} />
										<col style={{ width: '23%' }} />
										<col style={{ width: '10%' }} />
										<col style={{ width: '23%' }} />
										<col style={{ width: '10%' }} />
										<col style={{ width: '23%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th style={{ width: '15%' }}>SEQ</th>
											<td>
												<input
													type="text"
													style={{ width: 150, marginRight: 10 }}
													value={this.state.reqApiInputSeq}
													onChange={(e) => this.setState({ reqApiInputSeq: e.target.value })}
													maxLength={6}
												/>
											</td>
											<th style={{ width: '7%' }}>고객명</th>
											<td>
												<input type="text" readOnly={true} style={{ marginRight: 10, width: 130 }} value={this.state.custNm} />
												<IconButton onClick={this.customerSearch} color="secondary" className="iconButton" style={{ display: sessionStorage.btnControl }}>
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
											<th style={{ width: '12%' }}>고객요청번호</th>
											<td>
												<input type="text" value={this.state.custReqNoSearch} onChange={(e) => this.setState({ custReqNoSearch: e.target.value })} />
											</td>
										</tr>
										<tr>
											<th>요청일자</th>
											<td colSpan={3}>
												<DatePicker
													customInput={<input type="text" style={{ width: 115 }} />}
													dateFormat="YYYY-MM-DD"
													selectsStart
													selected={this.state.sDate}
													onChange={(date) => this.dateHandler(date, 's')}
													startDate={this.state.sDate}
													endDate={this.state.eDate}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
												<span style={{ marginLeft: 5, marginRight: 5 }}>~</span>
												<DatePicker
													customInput={<input type="text" style={{ width: 115 }} />}
													dateFormat="YYYY-MM-DD"
													selectsEnd
													selected={this.state.eDate}
													onChange={(date) => this.dateHandler(date, 'e')}
													startDate={this.state.sDate}
													endDate={this.state.eDate}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
											</td>
											<th>
												처리불가건
												<br />
												리셋
											</th>
											<td>
												<Button className="button" onClick={this.callResetFunc} color="primary" variant="contained" size="small" style={{ marginLeft: 10 }}>
													<SendIcon className="saveIcon" />
													<span className="text">리셋</span>
												</Button>
											</td>
										</tr>
										<tr>
											<th>API구분</th>
											<td>
												<select value={this.state.apiCateSearch} onChange={(e) => this.setState({ apiCateSearch: e.target.value })}>
													{this.state.apictCodeList ? buildSelectItems([{ title: '선택', value: '' }].concat(this.state.apictCodeList)) : null}
												</select>
											</td>
											<th>처리상태</th>
											<td>
												<select value={this.state.procFlag} onChange={(e) => this.setState({ procFlag: e.target.value })}>
													{this.state.apipfCodeList ? buildSelectItems([{ title: '선택', value: '' }].concat(this.state.apipfCodeList)) : null}
												</select>
											</td>
											<td></td>
											<td></td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="writeType03">
								<p className="headline">API 처리내역</p>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
									onClick={() => {
										this.setOrdeList(), this.apiOrdeItemList();
									}}
								>
									<IBSheet {...this.sheetData} />
								</div>
							</div>

							<div style={this.state.apiCate === 'R' || this.state.apiCate === 'RC' ? { display: 'block', height: '400px' } : { display: 'none' }}>
								<p className="headline" style={this.state.apiCate === 'R' ? { display: 'block' } : { display: 'none' }}>
									고객 렌탈신청 정보
								</p>
								<p className="headline" style={this.state.apiCate === 'RC' ? { display: 'block' } : { display: 'none' }}>
									고객 렌탈신청 취소 정보
								</p>
								<div className="writeType03_noborder">
									<table>
										<colgroup>
											<col style={{ width: 90 }} />
											<col style={{ width: 120 }} />
											<col style={{ width: 100 }} />
											<col style={{ width: 180 }} />
										</colgroup>
										<tbody>
											<tr>
												<th>고객요청번호</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.custReqNo} />
												</td>
											</tr>
											<tr>
												<th>계약기간</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.contTerm} />
												</td>
											</tr>
											<tr>
												<th>지점명</th>
												<td>
													<input type="text" readOnly={true} value={this.state.spotNm} />
												</td>
											</tr>
											<tr>
												<th>수령자명</th>
												<td>
													<input type="text" readOnly={true} value={this.state.delUser} />
												</td>
												<th>신청자부서명</th>
												<td>
													<input type="text" readOnly={true} value={this.state.delDept} />
												</td>
											</tr>
											<tr>
												<th>주소</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.delPostCode} style={{ width: '10%' }} />
													<input type="text" readOnly={true} value={this.state.delAddr} style={{ width: '30%' }} />
													<input type="text" readOnly={true} value={this.state.delAddrDtl} style={{ width: '30%' }} />
												</td>
											</tr>
											<tr>
												<th>전화번호</th>
												<td>
													<input type="text" readOnly={true} value={this.state.delTelno} />
												</td>
												<th>휴대전화</th>
												<td>
													<input type="text" readOnly={true} value={this.state.delHpno} />
												</td>
											</tr>
											<tr>
												<th>배송요청일</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.delAskDate} />
												</td>
											</tr>

											<tr>
												<th>발주요청비고</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.ordeRmk} />
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div style={this.state.apiCate === 'RT' ? { display: 'block', height: '400px' } : { display: 'none' }}>
								<p className="headline">고객 반납신청 정보</p>
								<div className="writeType03_noborder">
									<table>
										<colgroup>
											<col style={{ width: 90 }} />
											<col style={{ width: 120 }} />
											<col style={{ width: 100 }} />
											<col style={{ width: 180 }} />
										</colgroup>
										<tbody>
											<tr>
												<th>자산번호</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.asstNo} />
												</td>
											</tr>
											<tr>
												<th>반납신청자명</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.addrUser} />
												</td>
											</tr>
											<tr>
												<th>수령자명</th>
												<td>
													<input type="text" readOnly={true} value={this.state.delUser} />
												</td>
												<th>신청자부서명</th>
												<td>
													<input type="text" readOnly={true} value={this.state.delDept} />
												</td>
											</tr>
											<tr>
												<th>주소</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.delPostCode} style={{ width: '10%' }} />
													<input type="text" readOnly={true} value={this.state.delAddr} style={{ width: '30%' }} />
													<input type="text" readOnly={true} value={this.state.delAddrDtl} style={{ width: '30%' }} />
												</td>
											</tr>
											<tr>
												<th>반납신청 전화번호</th>
												<td>
													<input type="text" readOnly={true} value={this.state.addrTelno} />
												</td>
												<th>반납신청 휴대전화</th>
												<td>
													<input type="text" readOnly={true} value={this.state.addrHpno} />
												</td>
											</tr>
											<tr>
												<th>반납요청일</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.returnHopeDate} />
												</td>
											</tr>

											<tr>
												<th>반납요청비고</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.returnRmk} />
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div className="writeType03" style={this.state.apiCate === 'R' || this.state.apiCate === 'RC' ? { display: 'block' } : { display: 'none' }}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline" style={this.state.apiCate === 'R' ? { display: 'block' } : { display: 'none' }}>
										고객 렌탈신청 제품 리스트
									</p>
									<p className="headline" style={this.state.apiCate === 'RC' ? { display: 'block' } : { display: 'none' }}>
										고객 렌탈신청 취소 제품 리스트
									</p>
									<IBSheet {...this.sheetData2} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(ApiInputManager);
