import React, { Component } from 'react';
import LeftMenu from '../include/LeftMenu';
import Header from '../include/Header';
import IBSheet from '../IBSheet';
import { getMemberApprList, saveMemberAppr, saveMemberNewCustMapping, saveMemberNewAlncCustMapping } from '../../service/members/MembersService';
import { errorMsgCheck, resultToIbSheet, managerCheck, dateToFormat } from '../../common/ComFunction';
import { Search, Clear } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import ExcelDown from '../include/ExcelDown';
import { CUST_NO_KT } from '../../common/ComValues';
import CancelIcon from '@material-ui/icons/Cancel';
import findIcon from '@material-ui/icons/FindReplace';
import { getCustomData } from '../../service/CommonService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore } = CommonStore;

var result = {
	//sheet데이터
	data: [],
};

class MemberAppr extends Component {
	constructor(props) {
		super(props);

		this.sheetData = {
			//메뉴리스트(대메뉴)
			id: 'sheetMemberAppr', // sheet id
			initData: 'admin/Init-MemberAppr', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '300px', // sheet height
		};
	}
	state = {
		//검색조건
		custNo: '', //고객번호
		custNm: '', //고객명
		addrSeq: '', //지점시퀀스
		spotNm: '', //지점명
		userName: '', //사용자명
		alncCustNo: '', // 제휴사 고객번호
		alncCustNm: '', // 제휴사명

		//조회결과
		userList: [], //사용자리스트

		//모달
		postModalOpen: false,
		//모달 end

		custMappingArea: false, //맵핑area
		personalArea: false, // 개인고객 정보
		custTempData: {}, //임시고객 데이터
		personalData: {}, // 개인고객 데이터

		excelList: [], //엑셀다운로드용 리스트

		svCustCode: '',

		customChk: false,
	};
	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
		});
	};
	handleChangeNum = (e) => {
		// 숫자만 입력받도록
		const value = e.target.value.replace(/\D/, '');
		this.setState({
			[e.target.name]: value,
		});
	};
	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
	};
	componentDidMount = () => {
		managerCheck(); // 관리자체크
	};

	// sheet데이터 리셋
	resetResult = () => {
		result = {
			data: [],
		};
		window[this.sheetData.id].LoadSearchData(result, {
			Sync: 1,
		});
	};
	// 고객사팝업
	handleClickOpen = (popupType) => {
		if (popupType === '1' || popupType === '3') {
			//1:고객사 팝업 , 3:고객사 검색해서 바로적용 팝업
			CustSearchPopupStore.openPopup(popupType, null, null, this.handleClose);
		} else if (popupType === '5' || popupType === '6') {
			if (popupType === '6' && this.state.svCustCode === '') {
				ModalStore.openModal('제휴사 변경은 고객번호가 있어야 가능 합니다.');
				return;
			}
			//5:제휴사 팝업
			CustSearchPopupStore.openPopup(popupType, null, null, this.handleClose);
		} else if (popupType === '2') {
			//지점 팝업
			//지점은 고객사 팝업 검색 후 가능
			if (this.state.custNo === '') {
				ModalStore.openModal('지점검색은 회사명 검색 이후 가능합니다.');
				return;
			}
			CustSearchPopupStore.openPopup(popupType, sessionStorage.spotTitle + '명', this.state.custNo, this.handleClose);
		}
	};
	handleClose = (item) => {
		if (item !== undefined) {
			if (CustSearchPopupStore.popupType === '1') {
				// 고객사 검색 결과
				if (item.custNo !== undefined) {
					this.setState({
						custNo: item.custNo,
						custNm: item.custNm,
					});
					this.resetState();
				}
			} else if (CustSearchPopupStore.popupType === '2') {
				// 지점 검색 결과
				if (item.addrSeq !== undefined) {
					this.setState({
						addrSeq: item.addrSeq, //지점시퀀스
						spotNm: item.spotNm, //지점명
						spotId: item.spotId, //지점아이디
						postAddr: item.postAddr, //지점주소1
						postSddr: item.postSddr, //지점주소2
						postCode: item.postCode, //지점우편번호
						chgYn: true, // 변경일어남
					});
				}
			} else if (CustSearchPopupStore.popupType === '3') {
				// 고객사 검색 결과후 바로 적용
				if (item.custNo !== undefined) {
					this.state.custTempData.newCustNo = item.custNo;
					this.state.custTempData.newCustNm = item.custNm;

					ConfirmModalStore.openModal(
						'임시고객 "' + this.state.custTempData.nowCustNm + '"을(를) "' + this.state.custTempData.newCustNm + '"로 변경 하시겠습니까?',
						() => this.newCustMapping(),
					);
				}
			} else if (CustSearchPopupStore.popupType === '5') {
				// 제휴사 검색 결과
				if (item.custNo !== undefined) {
					this.setState({
						alncCustNo: item.custNo,
						alncCustNm: item.custNm,
					});
					this.resetState();
				}
			} else if (CustSearchPopupStore.popupType === '6') {
				// 제휴사 검색 결과후 바로 적용
				if (item.custNo !== undefined) {
					this.state.custTempData.newAlncCustNo = item.custNo;
					this.state.custTempData.newAlncCustNm = item.custNm;

					ConfirmModalStore.openModal('제휴사를 ' + this.state.custTempData.newAlncCustNm + '"로 변경 하시겠습니까?', () => this.newAlncCustMapping());
				}
			}
		}
	};
	// 고객사팝업 end
	resetState = (type) => {
		this.setState({
			userList: [], //사용자리스트
		});
		this.resetResult(); //ibsheet 데이터 리셋
	};

	//조회
	getMemberApprList = () => {
		ProgressStore.activeProgress();
		let param = {
			custNo: this.state.custNo,
			alncCustNo: this.state.alncCustNo,
			userName: this.state.userName,
			spotNm: this.state.spotNm,
			addrSeq: this.state.addrSeq,
		};

		getMemberApprList(param)
			.then((r) => {
				this.resetResult(); //ibsheet 데이터 리셋
				this.setState({ userList: r }, () => {
					// JSON객체형태로 서버에서 받으므로 IBsheet에서 출력 가능한 배열형태로 변환
					window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				});

				this.makeExcelData(r);
				// this.resetState('I');
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});

		//고객맴핑 area가 열려있으면 닫는다
		if (this.state.custMappingArea) this.setState({ custMappingArea: false });

		// 개인고객 정보 area가 열려있으면 닫는다
		if (this.state.personalArea) this.setState({ personalArea: false });
	};

	// 저장
	saveMemberAppr = async () => {
		if (this.state.userList.length === 0) {
			ModalStore.openModal('조회 후 저장할 수 있습니다.');
			return;
		}

		let paramList = [];
		let length = window[this.sheetData.id].GetTotalRows();
		let item = {};

		for (var i = 0; i < length; i++) {
			// 상태가 수정인 행만 fetch
			//console.log(window[this.sheetData.id].GetCellValue(i + 1, 'status'));
			if (window[this.sheetData.id].GetCellValue(i + 1, 'status') == 'U') {
				//console.log(window[this.sheetData.id].GetCellValue(i + 1, 'custNo'));
				item.custNo = window[this.sheetData.id].GetCellValue(i + 1, 'custNo');
				item.userId = window[this.sheetData.id].GetCellValue(i + 1, 'userId');
				item.chk = window[this.sheetData.id].GetCellValue(i + 1, 'chk');
				item.chkReject = window[this.sheetData.id].GetCellValue(i + 1, 'chkReject');
				item.userStatus = item.chk === '0' ? (item.chkReject === '0' ? 'U' : 'X') : 'E';
				//수정/삭제자 추가
				item.apprUserId = sessionStorage.userId;
				item.apprUserNm = sessionStorage.userName;
				item.apprTelNo = sessionStorage.otelNo ? sessionStorage.otelNo : sessionStorage.celNo;
				paramList.push(item);
				item = {};

				await getCustomData({ custNo: window[this.sheetData.id].GetCellValue(i + 1, 'custNo'), customCode: 'CT013' }).then((r) => {
					if (r.length > 0) {
						this.setState({
							customChk: true,
						});
					} else {
						this.setState({
							customChk: false,
						});
					}
				});

				if (this.state.customChk && item.chk === '1') {
					ProgressStore.deactiveProgress();
					ModalStore.openModal('KT로 가입한 유통판매 고객은 반드시 개인 고객으로 변경하여 주시기 바랍니다.');
					return;
				}
			}
		}

		if (paramList.length === 0) {
			ModalStore.openModal('변경사항이 없습니다.');
			ProgressStore.deactiveProgress();
			return;
		}

		if (confirm('처리 하시겠습니까?')) {
			ProgressStore.activeProgress();

			saveMemberAppr(paramList)
				.then((r) => {
					ModalStore.openModal('처리되었습니다.');

					// this.resetState();
					this.resetResult(); //ibsheet 데이터 리셋
					this.getMemberApprList(); //저장 성공 후 재조회
					ProgressStore.deactiveProgress();
				})
				.catch((err) => {
					errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
				});
		}
	};

	clickUserRow = async () => {
		let data = window.getUserCustData();
		this.setState({
			svCustCode: data.nowCustNo,
		});

		await getCustomData({ custNo: data.nowCustNo, customCode: 'CT013' }).then((r) => {
			if (r.length > 0) {
				this.setState({
					customChk: true,
				});
			} else {
				this.setState({
					customChk: false,
				});
			}
		});

		//임시고객 (일반적인 임시 고객은 회원 가입시 입력한 사업자번호가 현재 ERP에 등록되어 있지 않은 고객) 이거나 KT (유통몰 B2B2C 고객) 이면 고객번호를 KT가 아닌 개인고객번호로 맵핑해 준다.
		if (data.registerType != '2') {
			this.setState({
				custMappingArea: true,
				custTempData: data,
			});

			if (this.state.customChk) {
				ModalStore.openModal('KT로 가입한 유통판매 고객은 반드시 개인 고객으로 변경하여 주시기 바랍니다.');
			}
		} else {
			this.setState({
				custMappingArea: false,
			});
		}
		if (data.registerType == '2') {
			this.setState({
				personalArea: true,
				personalData: data,
			});
		} else {
			this.setState({
				personalArea: false,
			});
		}
	};

	newCustMapping = () => {
		let param = {
			nowCustNo: this.state.custTempData.nowCustNo,
			newCustNo: this.state.custTempData.newCustNo,
			newCustNm: this.state.custTempData.newCustNm,
			userId: this.state.custTempData.userId,
		};

		saveMemberNewCustMapping(param)
			.then((r) => {
				ModalStore.openModal('처리 되었습니다.');

				// this.resetState();
				//this.resetResult();//ibsheet 데이터 리셋
				this.getMemberApprList(); //저장 성공 후 재조회
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};

	newAlncCustMapping = () => {
		let param = {
			nowCustNo: this.state.svCustCode,
			newAlncCustNo: this.state.custTempData.newAlncCustNo,
			newAlncCustNm: this.state.custTempData.newAlncCustNm,
			userId: this.state.custTempData.userId,
		};

		saveMemberNewAlncCustMapping(param)
			.then((r) => {
				ModalStore.openModal('처리 되었습니다.');

				// this.resetState();
				//this.resetResult();//ibsheet 데이터 리셋
				this.getMemberApprList(); //저장 성공 후 재조회
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};

	makeExcelData = (data) => {
		let tmpList = [];
		let tmp = {};
		data.map((item, idx) => {
			tmp = {};
			tmp.No = idx + 1;
			tmp.유입시스템 = item.fromSys;
			tmp.고객번호 = item.custNo;
			tmp.고객명 = item.custNm;
			tmp.제휴사고객번호 = item.alncCustNo;
			tmp.제휴사고객명 = item.alncCustNm;
			tmp.지점명 = item.spotNm;
			tmp.아이디 = item.userId;
			tmp.사용자명 = item.userName;
			tmp.고객식별번호 = item.fcCode;
			tmp.이메일 = item.emailAddr;
			tmp.임시고객여부 = item.tempCustYn;
			tmp.요청일 = dateToFormat(item.instDate);

			tmpList.push(tmp);
		});
		this.setState({ excelList: tmpList });
	};
	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mb30">
								<h2>가입요청승인</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.getMemberApprList}>
										<span>조회</span>
									</a>
									<ExcelDown modalOpen={ModalStore.openModal} data={this.state.excelList} text="엑셀" filename={'가입승인요청.csv'} />
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '15%' }} />
										<col style={{ width: '35%' }} />
										<col style={{ width: '15%' }} />
										<col style={{ width: '35%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>고객명</th>
											<td>
												<input
													type="text"
													className="size01"
													ref={(ref) => (this.custNm = ref)}
													name="custNm"
													value={this.state.custNm || ''}
													onChange={this.handleChange}
													readOnly
												/>
												{/* 고객사검색버튼 */}
												<IconButton
													aria-label="Search"
													className="iconButton"
													onClick={() => this.handleClickOpen('1')}
													style={{ display: sessionStorage.btnControl }}
												>
													<Search fontSize="small" />
												</IconButton>
												<IconButton
													aria-label="Clear"
													className="iconButton"
													onClick={() => this.setState({ custNo: '', custNm: '' })}
													style={{ display: sessionStorage.btnControl }}
												>
													<Clear fontSize="small" />
												</IconButton>
											</td>
											<th>{sessionStorage.spotTitle}명</th>
											<td>
												<input
													type="text"
													className="size01"
													ref={(ref) => (this.spotNm = ref)}
													name="spotNm"
													value={this.state.spotNm || ''}
													onKeyDown={(e) => {
														if (e.key === 'Enter') this.getMemberApprList();
													}}
													onChange={(e) => {
														this.handleChange(e);
														this.setState({ addrSeq: '' });
													}}
												/>
												{/* <a className="btn01 search" onClick={() => this.handleClickOpen('2')}><span>검색</span></a> */}
												<IconButton aria-label="Search" className="iconButton" onClick={() => this.handleClickOpen('2')}>
													<Search fontSize="small" />
												</IconButton>
											</td>
										</tr>
										<tr>
											<th>제휴사</th>
											<td>
												<input
													type="text"
													className="size01"
													ref={(ref) => (this.alncCustNm = ref)}
													name="alncCustNm"
													value={this.state.alncCustNm || ''}
													onChange={this.handleChange}
													readOnly
												/>
												{/* 제휴사검색버튼 */}
												<IconButton
													aria-label="Search"
													className="iconButton"
													onClick={() => this.handleClickOpen('5')}
													style={{ display: sessionStorage.btnControl }}
												>
													<Search fontSize="small" />
												</IconButton>
												<IconButton
													aria-label="Clear"
													className="iconButton"
													onClick={() => this.setState({ alncCustNo: '', alncCustNm: '' })}
													style={{ display: sessionStorage.btnControl }}
												>
													<Clear fontSize="small" />
												</IconButton>
											</td>
											<th>사용자명</th>
											<td>
												<input
													type="text"
													ref={(ref) => (this.userName = ref)}
													name="userName"
													value={this.state.userName || ''}
													onKeyDown={(e) => {
														if (e.key === 'Enter') this.getMemberApprList();
													}}
													onChange={this.handleChange}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="writeType05 long">
								<div onClick={this.clickUserRow}>
									<div
										className="scroll"
										onMouseOver={() => {
											document.body.style.overflow = 'hidden';
										}}
										onMouseOut={() => {
											document.body.style.overflow = 'auto';
										}}
									>
										<p className="headline">
											사용자 리스트{' '}
											<span style={{ color: '#424242', marginLeft: 10, fontWeight: 400 }}>
												*임시고객(렌탈몰 유입고객)의 경우 고객을 선택한 뒤 새로운 고객번호로 맵핑 작업이 필요합니다.
											</span>
										</p>
										<IBSheet {...this.sheetData} />
									</div>
								</div>
								{this.state.custMappingArea && (
									<div className="writeType03" style={{ marginTop: 20 }}>
										<table>
											<colgroup>
												<col style={{ width: '10%' }} />
												<col style={{ width: '90%' }} />
											</colgroup>
											<tbody>
												<tr>
													<th>사업자번호</th>
													<td>
														<span style={{ marginLeft: 15 }}>{this.state.custTempData.idNo}</span>
													</td>
												</tr>
												<tr>
													<th>연락처</th>
													<td>
														<span style={{ marginLeft: 15 }}>
															{this.state.custTempData.otelNo && '사무실 : ' + this.state.custTempData.otelNo}
															{this.state.custTempData.otelNo && this.state.custTempData.celNo ? ' / ' : ''}
															{this.state.custTempData.celNo && ' 휴대폰 : ' + this.state.custTempData.celNo}
														</span>
													</td>
												</tr>
												<tr>
													<th>제휴사</th>
													<td>
														<span style={{ marginLeft: 15 }}>{this.state.custTempData.alncCustNm}</span>
														<Button
															className="button"
															onClick={() => this.handleClickOpen('6')}
															color="secondary"
															variant="contained"
															size="small"
															style={{ marginLeft: 15 }}
														>
															<Search className="saveIcon" />
															<span className="text">찾아서 적용</span>
														</Button>
													</td>
												</tr>
												<tr>
													<th>이메일</th>
													<td>
														<span style={{ marginLeft: 15 }}>{this.state.custTempData.emailAddr}</span>
													</td>
												</tr>
												<tr>
													<th>유입경로</th>
													<td>
														<span style={{ marginLeft: 15 }}>{this.state.custTempData.inflowPath}</span>
													</td>
												</tr>
												<tr>
													<th>고객 맵핑</th>
													<td>
														<text></text>
														<Button
															className="button"
															onClick={() => this.handleClickOpen('3')}
															color="secondary"
															variant="contained"
															size="small"
															style={{ marginLeft: 15 }}
														>
															<Search className="saveIcon" />
															<span className="text">찾아서 적용</span>
														</Button>
														<text style={{ marginLeft: 15 }}>*실제 고객번호를 맵핑해 줍니다. (KT는 개인고객으로 맵핑)</text>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								)}
								{this.state.personalArea && (
									<div className="writeType03" style={{ marginTop: 20 }}>
										<table>
											<colgroup>
												<col style={{ width: '20%' }} />
												<col style={{ width: '80%' }} />
											</colgroup>
											<tbody>
												<tr>
													<th>입력한 사업자번호</th>
													<td>
														<span style={{ marginLeft: 15 }}>{this.state.personalData.recommendIdno}</span>
													</td>
												</tr>
												<tr>
													<th>입력된 제휴사</th>
													<td>
														<span style={{ marginLeft: 15 }}>{this.state.personalData.alncCustNm}</span>
														<Button
															className="button"
															onClick={() => this.handleClickOpen('6')}
															color="secondary"
															variant="contained"
															size="small"
															style={{ marginLeft: 15 }}
														>
															<Search className="saveIcon" />
															<span className="text">찾아서 적용</span>
														</Button>
													</td>
												</tr>
												<tr>
													<th>이메일</th>
													<td>
														<span style={{ marginLeft: 15 }}>{this.state.personalData.emailAddr}</span>
													</td>
												</tr>
												<tr>
													<th>연락처</th>
													<td>
														<span style={{ marginLeft: 15 }}>
															{this.state.personalData.otelNo && '사무실 : ' + this.state.personalData.otelNo}
															{this.state.personalData.otelNo && this.state.personalData.celNo ? ' / ' : ''}
															{this.state.personalData.celNo && ' 휴대폰 : ' + this.state.personalData.celNo}
														</span>
													</td>
												</tr>
												<tr>
													<th>고객 식별번호</th>
													<td>
														<span style={{ marginLeft: 15 }}>{this.state.personalData.fcCode}</span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								)}
								<div className="btnRight2">
									{/* <Button className="button" onClick={() => this.saveMemberAppr('X')} color="secondary" variant="contained" size="small">
										<CancelIcon className="saveIcon" />
										<span className="text">승인거절</span>
									</Button> */}
									<Button className="button" onClick={() => this.saveMemberAppr()} color="primary" variant="contained" size="small">
										<SaveIcon className="saveIcon" />
										<span className="text">처리</span>
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(MemberAppr);
