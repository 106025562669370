import React, { Component } from 'react';
import IBSheet from '../IBSheet';
import LeftMenu from '../include/LeftMenu';
import Header from '../include/Header';
import { getCustIFCompList, saveCustIFComp } from '../../service/admin/CustIFCompManagerService';
import { errorMsgCheck, resultToIbSheet, SheetParamData3 } from '../../common/ComFunction';
import { IconButton } from '@material-ui/core';
import { Search } from '@material-ui/icons';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ProgressStore, CustSearchPopupStore } = CommonStore;

class CustIFCompManager extends Component {
	constructor(props) {
		super(props);
		this.sheetData = {
			id: 'custIFCompManagerSheet', // sheet id
			initData: 'admin/Init-CustCompIFList', // sheet data javascript filename
			width: '100%', // sheet width
			height: '600px', // sheet height
		};
		this.state = {
			// 검색조건
			custNoSearch: '', //고객사코드 검색
			custNmSearch: '', // 고객사명
			cnSrchInpt: 'S', //고객사검색검색조건/입력항목 여부('S':검색조건/'I':입력항목)
			delYn: '',

			// 조회결과
			custIFCompList: [], // 인터페이스 업체 리스트
		};
	}

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
	};

	// 인터페이스 업체 조회
	getCustIFCompList = () => {
		ProgressStore.activeProgress();

		let param = {
			custNo: this.state.custNoSearch,
			delYn: this.state.delYn,
		};
		getCustIFCompList(param)
			.then((r) => {
				this.setState({ custIFCompList: r }, () => {
					window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				});
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				ProgressStore.deactiveProgress();
				errorMsgCheck(err);
			});
	};

	// 인터페이스 업체 저장
	saveCustIFComp = () => {
		ProgressStore.activeProgress();
		const sheet = window[this.sheetData.id];

		let sheetParam = SheetParamData3(sheet, null, true);

		if (sheetParam && sheetParam.data.length > 0 && sheetParam.Message != 'KeyFieldError') {
			saveCustIFComp(sheetParam)
				.then((r) => {
					ModalStore.openModal('저장되었습니다.');

					this.getCustIFCompList(); // 저장 성공 후 재조회
					ProgressStore.deactiveProgress();
				})
				.catch((err) => {
					errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
				});
		} else {
			ProgressStore.deactiveProgress();
		}
	};

	// 인터페이스 업체관리 컬럼 추가
	addCustIFCompManager = () => {
		const sheet = window[this.sheetData.id];
		sheet.DataInsert(-1);
	};

	// 고객사팝업
	handleClickOpen = (row, col) => {
		CustSearchPopupStore.openPopup('1', null, null, this.handleClose);
	};

	handleClose = (item) => {
		if (item) {
			this.setState({
				custNoSearch: item.custNo,
				custNmSearch: item.custNm,
			});
		}
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mb30">
								<h2>인터페이스 업체관리</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.getCustIFCompList}>
										<span>조회</span>
									</a>
									<a className="btn01 add" onClick={this.addCustIFCompManager}>
										<span>추가</span>
									</a>
									<a className="btn01 save" onClick={this.saveCustIFComp}>
										<span>저장</span>
									</a>
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '10%' }} />
										<col style={{ width: '30%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>고객</th>
											<td>
												<input
													type="text"
													className="size01"
													ref={(ref) => {
														this.custNmSearch = ref;
													}}
													name="custNmSearch"
													value={this.state.custNmSearch || ''}
													onChange={() => {
														this.setState({ custNoSearch: '', custNmSearch: '' });
													}}
													style={{ width: 170 }}
												/>
												{/* 고객사검색버튼 */}
												<IconButton
													aria-label="Search"
													className="iconButton"
													onClick={() => {
														this.handleClickOpen('1');
														this.setState({ cnSrchInpt: 'S' });
													}}
												>
													<Search fontSize="small"></Search>
												</IconButton>
											</td>

											<th>삭제여부</th>
											<td>
												<select value={this.state.delYn} onChange={(e) => this.setState({ delYn: e.target.value })}>
													<option value="">전체</option>
													<option value="N">N</option>
													<option value="Y">Y</option>
												</select>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="writeType05 short">
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(CustIFCompManager);
