import React from 'react'

const Privacy =(props)=> {
    return(
    //     <div className="agreeBox">
    //         <div className="scrollWrap vertical">
    //             <div className="in">
    //                 <ul>
    //                     <li>
    //                         <h3>□ 개인정보처리방침</h3>
    //                         <p>1. 개인정보의 수집·이용</p>
    //                         <p style={{marginLeft:'20px'}}>가. 회사가 개인정보를 수집하는 목적은 이용자의 신분과 서비스 이용의사를 확인하여 최적화되고 맞춤화된 서비스를 제공하기 위함입니다. 회사는 최초 회원가입 시 서비스의 본질적 기능을 수행하기 위해 반드시 필요한 최소한의 정보만을 수집하고 있으며 회사가 제공하는 서비스 이용에 따른 대금결제, 물품배송 및 환불 등에 필요한 정보를 추가로 수집할 수 있습니다.</p>
    //                         <br/>
    //                         <p>2. 회사는 개인정보를 수집·이용목적 이외에 다른 용도로 이를 이용하거나 이용자의 동의 없이 제3자에게 이를 제공하지 않습니다.</p>
    //                         <br/>
    //                         <p>3. 회사는 다음과 같은 목적으로 개인정보를 수집하여 이용할 수 있습니다. 다만, 전자상거래 등에서의 소비자보호에 관한 법률, 국세기본법, 전자금융거래법 등 관계법령에 따라 주민등록번호 및 은행계좌번호의 수집·보관이 불가피한 경우나, 휴대폰 소액결제 등 요금정산을 위하여 이동전화번호정보의 수집·보관이 불가피한 경우에는 이용자에게 고지하여 해당 정보를 수집할 수 있습니다.</p>
    //                         <p style={{marginLeft:'20px'}}>가.	구매회원/판매회원</p>
    //                         <p style={{marginLeft:'35px'}}>(1)	본인여부 확인, 연령제한서비스 제공, SSO연동, 계약이행 및 약관변경 등의 고지를 위한 연락, 본인의사확인 및 민원 등의 고객불만처리 : 이름, 법인명, 법인등록번호, 사업자등록번호, 법인 주소, ID, 비밀번호, 휴대폰번호, 이메일 주소, 외국인등록번호(외국인인 경우)</p>
    //                         <p style={{marginLeft:'35px'}}>(2)	주문, 결제 및 배송서비스 : 이름, 전화번호, 법인명, 법인주소, 은행계좌정보, 휴대폰결제 시 휴대폰번호, 현금영수증 신청 시 현금영수증 정보</p>
    //                         <p style={{marginLeft:'35px'}}>(3)	신규 서비스 개발, 맞춤 서비스 제공 및 마케팅,서비스 이용 통계 및 설문 : 성별, 생년월일, 휴대폰번호, 법인명, 법인주소, 이메일 주소</p>
    //                         <p style={{marginLeft:'35px'}}>(4)	본인인증 식별, 본인여부, 연령 확인, 부정이용 방지, 맞춤 서비스 등 통합서비스운영( * ID/PW찾기와 성인상품의 열람 등 ) : 이름, 성별, 생년월일, 휴대폰번호, 법인명, 법인등록번호, 법인주소, 사업자등록번호, CI/DI, 아이핀 인증결과, 통신사, 내/외국인정보, 서비스이용기록, 기기정보</p>
    //                         <p style={{marginLeft:'20px'}}>나.	판매회원</p>
    //                         <p style={{marginLeft:'35px'}}>(1)	부가가치세법 제32조에 따른 세금계산서 등의 발행 : 주민등록번호</p>
    //                         <p style={{marginLeft:'35px'}}>(2)	물품대금 송금 및 대금결제서비스의 제공 등 : 신용카드정보, 은행계좌정보</p>
    //                         <p style={{marginLeft:'35px'}}>(3)	판매회원 서비스 제공 등 : 담당자명, 담당자연락처, 주소</p>
    //                         <p style={{marginLeft:'20px'}}>다.	제휴서비스</p>
    //                         <p style={{marginLeft:'35px'}}>회사는 제휴서비스 제공의 목적으로 제휴사를 통해 개인정보를 추가 수집하게 되는 경우 별도 이용자의 동의를 얻어 수집합니다.</p>
    //                         <p style={{marginLeft:'20px'}}>라.	기타</p>
    //                         <p style={{marginLeft:'35px'}}>(1) 서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집·저장·조합·분석될 수 있습니다.</p>
    //                         <p style={{marginLeft:'60px'}}>(가) 서비스 이용기록(IP Address, 쿠키, 방문 일시 등) 및 기기 정보(단말기식별번호, OS정보 등) 등 이용내역정보: 부정 이용 방지, 비인가 사용 방지, 신규 서비스 개발 및 맞춤서비스 제공 등</p>
    //                         <br/>
    //                         <p>4. 회사는 이용자의 개인정보를 수집할 경우 법령상 근거가 없는 한 반드시 이용자의 동의를 얻어 수집하며, 이용자의 기본적 인권을 침해할 우려가 있는 인종, 출신지, 본적지, 사상, 정치적 성향, 범죄기록, 건강상태 등의 정보는 이용자의 동의 또는 법령의 규정에 의한 경우 이외에는 수집하지 않습니다.</p>
    //                         <br/>
    //                         <p>5. 회사는 회원 가입을 만 14세 이상인 경우에 가능하도록 하며 개인정보의 수집·이용에 법정대리인의 동의가 필요한 만 14세 미만 아동의 개인정보는 원칙적으로 수집하지 않습니다. 단, 법정대리인의 동의를 얻은 경우에는 만 14세 미만 이용자의 개인정보를 수집·이용할 수 있습니다.</p>
    //                         <br/>
    //                         <p>6. 회사는 다음과 같은 방법으로 개인정보를 수집할 수 있습니다.</p>
    //                         <p style={{marginLeft:'20px'}}>가) 홈페이지, 서면, 팩스, 전화, 고객센터 문의하기, 이벤트 응모</p>
    //                         <p style={{marginLeft:'20px'}}>나) 생성정보 수집 툴을 통한 자동 수집</p>
    //                         <br/>
    //                         <p>7. 회사는 개인정보를 수집함에 있어, 서비스 제공에 필요한 최소한의 개인정보를 ‘필수동의 항목’으로, 그 외 개인정보는 ‘선택동의 항목’으로 구분하여 이에 대해 개별적으로 동의할 수 있는 절차를 마련합니다. 회사는 이용자가 필요한 최소한의 개인정보 이외의 개인정보를 제공하지 아니한다는 이유로 그 서비스의 제공을 거부하지 않습니다.</p>
    //                     </li>
    //                     <br/>
    //                     <li>
    //                         <h3>□ 개인정보 제3자 제공</h3>
    //                         <p>1. 회사는 원칙적으로 이용자의 사전 동의를 받아 이용자들의 개인정보를 외부에 제공합니다. 다만, 아래의 경우에는 예외로 합니다.</p>
    //                         <p style={{marginLeft:'20px'}}>가) 법령의 규정에 의거하거나, 수사, 조사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 및 감독당국의 요구가 있는 경우</p>
    //                         <p style={{marginLeft:'20px'}}>나) 요금 정산을 위하여 필요한 경우</p>
    //                         <p style={{marginLeft:'20px'}}>다) 이용자 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요함에도 불구하고 동의를 받을 수 없는 경우</p>
    //                         <br/>
    //                         <p>2. 회사는 원칙적으로 「개인정보의 수집·이용」에서 고지한 범위 내에서 개인정보를 이용하거나 제3자에게 제공하며, 동 범위를 초과하여 이용하거나 제3자에게 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.</p>
    //                         <p style={{marginLeft:'20px'}}>가) 이용자들이 사전에 공개 또는 제3자 제공에 동의한 경우</p>
    //                         <p style={{marginLeft:'20px'}}>나) 법령의 규정에 의거하거나, 수사, 조사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 및 감독당국의 요구가 있는 경우</p>
    //                         <br/>
    //                         <p>3. 그 밖에 개인정보 제3자 제공이 필요한 경우에는 이용자의 동의를 얻는 등 적법한 절차를 통하여 제3자에게 개인정보를 제공할 수 있습니다. 회사는 이용자들의 거래 이행을 위하여 필요한 경우 이용자의 동의를 얻는 등 적법한 절차를 통하여 아래와 같이 개인정보를 제공할 수 있습니다.</p>
    //                         <table style={{marginLeft:'20px'}}>
    //                             <colgroup>
    //                                 <col width="20%" />
    //                                 <col width="25%" />
    //                                 <col width="30%" />
    //                                 <col width="25%" />
    //                             </colgroup>
    //                             <thead>
    //                             <tr>
    //                                 <th>공유받는 자</th>
    //                                 <th>공유하는 항목</th>
    //                                 <th>공유받는 자의 이용목적</th>
    //                                 <th>보유 및 이용기간</th>
    //                             </tr>
    //                             </thead>
    //                             <tr>
    //                                 <td>판매자[판매자리스트]</td>
    //                                 <td>ID, 성명, 법인번호,사업자등록번호, 전화번호, 휴대폰번호, 배송지 주소, 이메일주소(선택시), 통관고유부호(선택시), 생년월일(선택시)</td>
    //                                 <td>상품 및 경품(서비스) 배송(전송), 제품 설치, 반품, 환불, 고객상담 등 정보통신 서비스제공 계약 및 전자상거래(통신판매)계약의 이행을 위해 필요한 업무의 처리</td>
    //                                 <td>구매 서비스 종료 후 1개월</td>
    //                             </tr>
    //                         </table>               
    //                         <br/>
    //                         <p>4. 이용자는 개인정보의 제3자 제공에 대하여 동의를 하지 않을 수 있고, 언제든지 제3자 제공 동의를 철회할 수 있습니다. 동의를 거부하시는 경우에도 회원가입서비스는 이용하실 수 있으나, 제 3자 제공에 기반한 관련 서비스의 이용/제공이 제한될 수 있습니다. 기타 개인정보 제3자 제공에 대한 변동사항은 공지 및 별도 통지를 할 예정입니다.</p>
    //                     </li>
    //                     <br/>
    //                     <li>
    //                         <h3>□ 개인정보의 처리위탁</h3>
    //                         <p>1. 회사는 원활하고 향상된 서비스를 위하여 개인정보 처리를 타인에게 위탁할 수 있습니다.이 경우 회사는 사전에 다음 각 호의 사항 모두를 이용자에게 미리 알리고 동의를 받습니다. 다음 각 호의 어느 하나의 사항이 변경되는 경우에도 같습니다.</p>
    //                         <p style={{marginLeft:'20px'}}>가. 개인정보 처리위탁을 받는 자</p>
    //                         <p style={{marginLeft:'20px'}}>나. 개인정보 처리위탁을 하는 업무의 내용</p>
    //                         <br/>
    //                         <p>2. 회사는 정보통신서비스의 제공에 관한 계약을 이행하고 이용자 편의 증진 등을 위하여 필요한 경우 개인정보처리방침에 따라 가항 각 호의 사항을 공개함으로써 고지절차와 동의절차를 거치지 아니하고 개인정보 처리를 타인에게 위탁할 수 있습니다</p>
    //                         <br/>
    //                         <p>3. 회사는 개인정보의 처리와 관련하여 아래와 같이 업무를 위탁하고 있으며, 관계법령에 따라 위탁 계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 조치를 하고 있습니다. 회사는 위탁 계약 시 수탁자의 개인정보 보호조치 능력을 고려하고, 개인정보의 안전한 관리 및 파기 등 수탁자의 의무 이행 여부를 주기적으로 확인합니다. 또한 위탁처리하는 정보는 원활한 서비스를 제공하기 위하여 필요한 최소한의 정보에 국한됩니다.</p>
    //                         <br/>
    //                         <p style={{marginLeft:'20px'}}>가.	개인정보 국내처리 위탁현황</p>
    //                         <table style={{marginLeft:'20px'}}>
    //                             <colgroup>
    //                                 <col width="30%" />
    //                                 <col width="70%" />
    //                             </colgroup>
    //                             <thead>
    //                             <tr>
    //                                 <th>수탁업체</th>
    //                                 <th>위탁업무 내용</th>
    //                             </tr>
    //                             </thead>
    //                             <tr>
    //                                 <td>일양로지스</td>
    //                                 <td>제품 배송 및 반품 교환 수거 서비스</td>
    //                             </tr>
    //                             <tr>
    //                                 <td>CJ올리브네트웍스</td>
    //                                 <td>회원가입 및 상품 구매 관련 카카오톡 안내 서비스</td>
    //                             </tr>                
    //                         </table> 
    //                         <p>4. 구매자가 실명 인증시 API 방식을 통한 실명 인증 여부 확인	수취인의 성명, 연락처	세관의 통관 업무 수행을 위한 개인실명인증 확인 후 즉시파기</p>
    //                         <br/>
    //                         <p>5. 이용자 개인정보의 보유 ： 이용기간 및 파기</p>
    //                         <p>회사는 이용자의 개인정보를 원칙적으로 고지 및 약정한 기간 동안 보유 및 이용하며 개인정보의 수집 및 이용목적이 달성되거나 이용자의 파기 요청이 있는 경우 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다</p>
    //                         <br/>
    //                         <p style={{marginLeft:'20px'}}>가. 관계법령 및 회사 방침에 의한 정보보유 사유</p>
    //                         <p style={{marginLeft:'35px'}}>상법 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 법령에서 규정한 바에 따라 이용자의 개인정보를 보관하며 마케팅 등 다른 목적으로 이용하지 않습니다.</p>
    //                         <table style={{marginLeft:'35px'}}>
    //                             <colgroup>
    //                                 <col width="25%"/>
    //                                 <col width="25%"/>
    //                                 <col width="25%"/>
    //                                 <col width="25%"/>
    //                             </colgroup>
    //                             <thead>
    //                             <tr>
    //                                 <th>관계 법률</th>
    //                                 <th>목적</th>
    //                                 <th>수집항목</th>
    //                                 <th>보유기간</th>
    //                             </tr>
    //                             </thead>
    //                             <tr>
    //                                 <td>통신비밀보호법</td>
    //                                 <td>법원의 영장을 받아 수사기관이 요청시 제공</td>
    //                                 <td>로그기록, IP 등</td>
    //                                 <td>3개월</td>
    //                             </tr>
    //                             <tr>
    //                                 <td rowSpan="3">전자상거래등에서의 소비자보호에 관한 법률</td>
    //                                 <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
    //                                 <td>소비자 식별정보,분쟁처리 기록 등</td>
    //                                 <td>3년</td>
    //                             </tr> 
    //                             <tr>
    //                                 <td>대금결제 및 재화 등의 공급에 관한 기록</td>
    //                                 <td rowSpan="2">소비자 식별정보,계약/청약철회 기록 등</td>
    //                                 <td rowSpan="2">5년</td>
    //                             </tr>   
    //                             <tr>
    //                                 <td>계약 또는 청약철회 등에 관한 기록</td>
    //                             </tr>       
    //                             <tr>
    //                                 <td rowSpan="2">국세기본법</td>
    //                                 <td>계약 또는 청약철회 등에 관한 기록</td>
    //                                 <td>국세 증빙자료 등</td>
    //                                 <td>10년</td>
    //                             </tr>  
    //                             <tr>
    //                                 <td>국세징수권 등 소멸시효 계산</td>
    //                                 <td>과세표준과 세액의 신고자료 등</td>
    //                                 <td>5년</td>
    //                             </tr>
    //                             <tr>
    //                                 <td>부가가치세법</td>
    //                                 <td>장부, 세금계산서, 수입세금계산서, 영수증 등</td>
    //                                 <td>부가가치세의 과세표준과 세액의 신고자료 등</td>
    //                                 <td>5년</td>
    //                             </tr>   
    //                             <tr>
    //                                 <td>전자금융거래법</td>
    //                                 <td>전자금융거래기록 확인</td>
    //                                 <td>전자금융거래에 관한 기록, 상대방에 관한 정보 등</td>
    //                                 <td>5년</td>
    //                             </tr>                                          
    //                         </table>   
    //                         <br/>             
    //                         <p style={{marginLeft:'20px'}}>나. 회사방침에 의한 정보보유 사유</p>
    //                         <p style={{marginLeft:'35px'}}>1) 부정거래기록</p>
    //                         <p style={{marginLeft:'55px'}}>보존 이유 : 부정거래의 배제</p>
    //                         <p style={{marginLeft:'55px'}}>보존 기간 : 1년</p>
    //                         <p style={{marginLeft:'55px'}}>보존 항목 : 이름, ID, CI/DI, 휴대폰번호, 법인명, 법인주소, 사업자등록번호, 법인등록번호, 이메일주소, 생년월일</p>
    //                         <p style={{marginLeft:'55px'}}>* 부정거래 : 법령, 회사와 이용자 간의 서비스 이용 약관 또는 공서양속을 위반하거나 기타 회사, 회원 또는 타인의 권리나 이익을 침해하는 방법이나 내용의 거래를 말함</p>
    //                         <br/>
    //                         <p style={{marginLeft:'20px'}}>다. 수집된 개인정보의 보유·이용기간은 서비스이용계약체결(회원가입)시부터 서비스이용계약해지(탈퇴신청, 직권탈퇴 포함)입니다. 또한 동의 해지 시 회사는 이용자의 개인정보를 상기 명시한 정보보유 사유에 따라 일정 기간 저장하는 자료를 제외하고는 지체 없이 파기하며 개인정보처리가 제3자에게 위탁된 경우에는 수탁자에게도 파기하도록 지시합니다.</p>
    //                         <br/>
    //                         <p style={{marginLeft:'20px'}}>라. 2015년 8월 18일부터, 회사는 1년 동안 회사의 서비스를 이용하지 않은 이용자의 개인정보는 ‘정보통신망 이용촉진 및 정보보호등에 관한 법률 제29조’ 에 근거하여 이용자에게 사전통지하고 개인정보를 파기하거나 별도로 분리하여 저장 관리합니다. 이용자의 요청이 있을 경우에는 위 기간을 달리 정할 수 있습니다. 단, 통신비밀보호법, 전자상거래 등에서의 소비자보호에 관한 법률 등의 관계법령의 규정에 의하여 보존할 필요가 있는 경우 관계법령에서 규정한 일정한 기간 동안 이용자 개인정보를 보관합니다</p>
    //                         <br/>
    //                         <p style={{marginLeft:'20px'}}>마. 회사는 다항 기간 만료 30일 전까지 개인정보가 파기되거나 분리되어 저장·관리되는 사실과 기간 만료일 및 해당 개인정보의 항목을 공지사항, 전자우편 등의 방법으로 이용자에게 알립니다. 이를 위해 이용자는 회사에 정확한 연락처 정보를 제공/수정하여야 합니다.</p>
    //                         <br/>
    //                         <p style={{marginLeft:'20px'}}>바. 파기방법</p>
    //                         <p style={{marginLeft:'35px'}}>이용자의 개인정보는 수집 및 이용목적이 달성된 후에는 지체 없이 파기됩니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각 등을 통하여 파기하고, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법 또는 물리적 방법을 사용하여 파기합니다</p>
    //                         <br/>
    //                         <p>6. 스마트폰 앱 관리</p>
    //                         <p>스마트폰 앱을 통해 서비스 이용 시, 이용자에게 개인정보 수집·이용 동의를 받은 범위 내에서 단말기정보 등에 접근한다는 것을 고지하고 승인을 획득한 후 정보가 수집되거나 전송됩니다. 앱 상에서 이용자가 단말기 접근 권한을 허용하였다고 하여 허용권한과 관련된 모든 정보가 즉시 수집되거나 전송되는 것은 아닙니다. 서비스 제공을 위하여 스마트폰 앱의 접근 권한을 필수 또는 선택적으로 이용자에게 요청하고 있으며, 단말기 내 "설정" 메뉴를 통하여 이용자가 직접 권한을 변경할 수 있습니다. 앱 권한에 대한 자세한 사항은 애플리케이션 스토어를 통해 확인하실 수 있습니다.
    //                             스마트폰 상에서 앱을 삭제하더라도 이용자의 회원계정은 유지되므로, 회원탈퇴를 원하실 경우 AJ고객발주시스템 웹페이지 상에서 “회원탈퇴” 기능을 이용하시거나 고객센터(1588-0053)로 연락하여 주시기 바랍니다.</p>
    //                         <br/>
    //                         <p>7. 쿠키(Cookie)의 운용 및 거부</p>
    //                         <p style={{marginLeft:'20px'}}>가. 쿠키의 사용 목적</p>
    //                         <p style={{marginLeft:'35px'}}>1) 회사는 회사가 운영하는 인터넷 사이트에서 개인 맞춤 서비스를 제공하기 위해 이용 정보를 저장하는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 웹사이트 서버가 이용자의 브라우저에게 전송하는 소량의 정보로서 이용자 컴퓨터의 하드디스크에 저장됩니다.</p>
    //                         <p style={{marginLeft:'35px'}}>2) 회사는 쿠키의 사용을 통해서만 가능한 특정된 맞춤형 서비스를 제공할 수 있습니다.</p>
    //                         <p style={{marginLeft:'35px'}}>3)회사는 회원을 식별하고 회원의 로그인 상태를 유지하기 위해 쿠키를 사용할 수 있습니다.</p>
    //                         <br/>
    //                         <p style={{marginLeft:'20px'}}>나. 쿠키의 설치/운용 및 거부</p>
    //                         <p style={{marginLeft:'35px'}}>1) 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을 조정함으로써 모든 쿠키를 허용/거부하거나, 쿠키가 저장될 때마다 확인을 거치도록 할 수 있습니다.</p>
    //                         <p style={{marginLeft:'55px'}}>- 쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과 같습니다.</p>
    //                         <br/>
    //                         <p style={{marginLeft:'55px'}}>[도구] 메뉴에서 [인터넷 옵션]을 선택합니다.</p>
    //                         <p style={{marginLeft:'55px'}}>[개인정보 탭]을 클릭합니다.</p>
    //                         <p style={{marginLeft:'55px'}}>[개인정보처리 수준]을 설정하시면 됩니다.</p>
    //                         <br/>
    //                         <p style={{marginLeft:'35px'}}>2) 쿠키의 저장을 거부할 경우에는 개인 맞춤서비스 등 회사가 제공하는 일부 서비스는 이용이 어려울 수 있습니다.</p>
    //                         <p style={{marginLeft:'35px'}}>3) 맞춤형 광고에 대한 상세내용은 링크를 통해 확인하시기 바랍니다.</p>
    //                         <br/>
    //                         <p>8. 이용자의 권리</p>
    //                         <p style={{marginLeft:'20px'}}>가. 이용자는 언제든지 AJ고객발주시스템 사이트의 “정보수정”을 통하여 회원님의 개인정보를 열람, 정정 처리하실 수 있으며, 전자우편 또는 서면으로 요청하신 경우 열람, 정정, 삭제처리 해드리겠습니다. 이용자의 개인정보가 제3자에게 제공되거나 처리위탁된 경우 이용자는 회사 또는 ‘제3자’/’수탁자’에게 파기를 요청할 수 있습니다. 단, 회원 아이디(ID), 성명, 주민등록번호, 법인명, 법인등록번호, 사업자등록번호, 외국인등록번호는 정정이 불가능하며, 개명 및 법인명 변경으로 인한 성명 변경 및 행정상의 문제로 인한 주민(사업자)등록번호 변경은 예외적으로 허용 될 수 있습니다. 기타 법률에 따라 정정 또는 삭제가 금지되거나 제한되어 있는 경우에는 해당 처리가 제한될 수 있습니다. 또한 개인정보 오류에 대한 정정 요청한 경우에는 다른 법률에 따라 개인정보의 제공을 요청받는 경우가 아닌 한 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다, 만약 잘못된 개인정보를 이미 제공한 경우에는 정정 처리 결과를 제 3자에게 통지하여 정정이 이루어지도록 하겠습니다.</p>
    //                         <br/>
    //                         <p style={{marginLeft:'20px'}}>나. 이용자는 언제든지 AJ고객발주시스템 사이트의 개인정보에 대하여 처리의 정지를 요구 할 수 있습니다. 다만 아래의 경우에 해당하는 경우 처리정지 요구를 거절할 수 있습니다.</p>
    //                         <p style={{marginLeft:'35px'}}>1) 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우</p>
    //                         <p style={{marginLeft:'35px'}}>2) 다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우</p>
    //                         <p style={{marginLeft:'35px'}}>3) 개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한 경우로서 정보주체가 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우</p>
    //                         <br/>
    //                         <p style={{marginLeft:'20px'}}>다. 회원가입 등을 통해 개인정보의 수집·이용·제공에 대해 회원님께서 동의하신 내용을 언제든지 철회할 수 있습니다. 동의철회는 회사 사이트 내 “회원 탈퇴 신청”을 클릭하거나 서면, 이메일 등으로 연락하시면 지체 없이 개인정보의 삭제 등 필요한 조치를 하겠습니다. 다만 법률 또는 약관의 규정에 따라 회사가 회원님의 개인정보를 보존하여야 하는 경우에는 해당 처리가 제한될 수 있습니다. 이 경우 회원님은 본인 식별을 위하여 반드시 회원아이디(ID)와 법인 확인 식별정보를 밝히셔야 하며, 철회로 인해 서비스에 다소 제약이 있거나 일부 서비스를 이용하지 못하실 수 있습니다</p>
    //                         <br/>
    //                         <p>9. 이용자의 의무</p>
    //                         <p>이용자는 자신의 개인정보를 보호할 의무가 있으며, 회사의 귀책사유가 없이 ID, 비밀번호, 접근매체 등의 양도·대여·분실이나 로그인 상태에서 이석 등 이용자 본인의 부주의나 관계법령에 의한 보안조치로 차단할 수 없는 방법이나 기술을 사용한 해킹 등 회사가 상당한 주의에도 불구하고 통제할 수 없는 인터넷 상의 문제 등으로 개인정보가 유출되어 발생한 문제에 대해 회사는 책임을 지지 않습니다.</p>
    //                         <br/>
    //                         <p style={{marginLeft:'20px'}}>가. 이용자는 자신의 개인정보를 최신의 상태로 유지해야 하며, 이용자의 부정확한 정보 입력으로 발생하는 문제의 책임은 이용자 자신에게 있습니다.</p>
    //                         <p style={{marginLeft:'20px'}}>나. 타인의 개인정보를 도용한 회원가입 또는 ID 등을 도용하여 결제처리 시 이용자자격 상실과 함께 관계법령에 의거하여 처벌될 수 있습니다.</p>  
    //                         <p style={{marginLeft:'20px'}}>다. 이용자는 ID, 비밀번호 등에 대해 보안을 유지할 책임이 있으며 제3자에게 이를 양도하거나 대여할 수 없습니다. 이용자는 회사의 개인정보보호정책에 따라 보안을 위해 비밀번호의 주기적 변경에 협조할 의무가 있습니다.</p>
    //                         <p style={{marginLeft:'20px'}}>라. 이용자는 회사의 서비스를 이용한 후에는 반드시 로그인 계정을 종료하고 웹 브라우저 프로그램을 종료해야 합니다.</p>
    //                         <p style={{marginLeft:'20px'}}>마. 이용자는 "정보 통신망 이용촉진 및 정보보호 등에 관한 법률", “개인정보보호법”, "주민등록법" 등 기타 개인정보에 관한 법률을 준수하여야 합니다.</p>
    //                         <br/>
    //                         <p>10. 링크 사이트에 대한 책임</p>
    //                         <p>회사는 이용자에게 다른 웹사이트에 대한 링크를 제공할 수 있습니다. 다만, 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 "개인정보처리방침"이 적용되지 않습니다.</p>
    //                         <br/>
    //                         <p>11. 개인정보의 기술적／관리적 보호 대책</p>
    //                         <p>회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 보호대책을 강구하고 있습니다.</p>
    //                         <br/>
    //                         <p style={{marginLeft:'20px'}}>가. 개인정보의 암호화</p>
    //                         <p style={{marginLeft:'35px'}}>이용자의 비밀번호는 일방향 암호화하여 저장 및 관리되고 있으며, 개인정보의 확인 및 변경은 비밀번호를 알고 있는 본인에 의해서만 가능합니다. 비밀번호는 이용자의 생일, 전화번호 등 타인이 추측하기 쉬운 숫자 등을 이용하지 않도록 비밀번호 생성규칙을 수립하여 적용하고 있습니다. 주민등록번호, 외국인 등록번호, 은행계좌번호 및 신용카드번호 등의 개인정보는 안전한 암호 알고리즘으로 암호화되어 저장 및 관리되고 있습니다.</p>
    //                         <p style={{marginLeft:'20px'}}>나. 해킹 등에 대비한 대책</p>
    //                         <p style={{marginLeft:'35px'}}>회사는 해킹 등 회사 정보통신망 침입에 의해 이용자의 개인정보가 유출되는 것을 방지하기 위해 침입탐지 및 침입차단 시스템을 24시간 가동하고 있습니다. 만일의 사태에 대비하여 모든 침입탐지 시스템과 침입차단 시스템은 이중화로 구성하여 운영하고 있으며, 민감한 개인정보는 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.</p>
    //                         <p style={{marginLeft:'20px'}}>다. 개인정보 취급자의 최소화 및 교육</p>
    //                         <p style={{marginLeft:'35px'}}>회사는 회사의 개인정보 취급자를 최소한으로 제한하며, 개인정보 취급자에 대한 교육 등 관리적 조치를 통해 개인정보보호의 중요성을 인식시키고 있습니다.</p>
    //                         <p style={{marginLeft:'20px'}}>라. 개인정보보호 전담부서의 운영</p>
    //                         <p style={{marginLeft:'35px'}}>회사는 개인정보의 효율적 보호를 위해 개인정보보호전담부서를 운영하고 있으며, 개인정보처리방침의 이행사항 및 개인정보 취급자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정할 수 있도록 노력하고 있습니다.</p>
    //                         <br/>
    //                         <p>12. 개인정보 보호책임자</p>
    //                         <p>회사는 이용자가 회사의 서비스를 안전하게 이용할 수 있도록 최선을 다하고 있습니다. 이용자는 회사의 서비스 이용과 관련한 모든 개인정보보호 민원을 전담부서로 신고하실 수 있으며, 회사는 이용자의 신고사항에 대해 신속하고 성실하게 답변해드리고 있습니다.</p>
    //                         <br/>
    //                         <p>개인정보 보호책임자성명 : 오석만</p>
    //                         <p>소속부서 : 경영관리본부</p>
    //                         <p>전화번호 : 02-6240-0830</p>
    //                         <p>※ 상기 연락처 등은 “개인정보보호 전담 고객센터”로 연결됩니다.</p>
    //                         <br/>
    //                         <p>기타 개인정보침해에 대한 신고 또는 상담이 필요하신 경우에는 아래 기관으로 문의하시기 바랍니다.</p>
    //                         <p>개인정보분쟁조정위원회전화 : 1833-6972</p>
    //                         <p>URL : http://www.kopico.go.kr</p>
    //                         <br/>
    //                         <p>개인정보침해신고센터전화 : (국번없이)118</p>
    //                         <p>URL : http://privacy.kisa.or.kr</p>
    //                         <br/>
    //                         <p>대검찰청 첨단범죄수사센터전화 : (국번없이)1301</p>
    //                         <p>URL : http://www.spo.go.kr</p>
    //                         <br/>
    //                         <p>경찰청 사이버안전국전화 : (국번없이)182</p>
    //                         <p>URL : http://cyberbureau.police.go.kr</p>
    //                         <br/>
    //                         <p>13. 고지의 의무</p>
    //                         <p>본 개인정보처리방침은 관계법령 및 지침의 변경 또는 회사의 필요 등에 의하여 내용의 추가, 삭제 및 수정이 생길 수 있습니다. 이 경우 최소 7일 전에 홈페이지 또는 이메일을 통해 사전 공지하고 사전 공지가 곤란한 경우 지체 없이 공지하며, 별도 공지가 없는 한 7일이 경과된 후에 시행됩니다. 다만, 중대한 내용이 변경되는 경우에는 최소 30일 전에 공지하고, 별도 공지가 없는 한 30일이 경과된 후에 시행됩니다. 또한 당사는 관계법령에 따라 필요한 경우 고객의 별도 동의를 받을 수 있습니다.</p>
    //                         <br/>
    //                         <p>공고일자 : 2020년 11월 02일</p>
    //                         <p>시행일자 : 2020년 12월 01일</p>
    //                     </li>
    //                 </ul>
    //             </div>
    //         </div>
    //     </div>
        <div className="agreeBox" style={{padding: '20px 20px 40px 20px', backgroundColor: 'white'}}>
            <div className={props.from === 'join' ? 'scrollWrap vertical' : 'vertical'}>
                <div className="in">           
                    <div>
                        {props.from !== 'join' &&
                            <div>
                                <h1 style={{fontSize: 25}}>개인정보 처리방침</h1>
                                <br/>
                            </div>
                        }
                        <p>AJ네트웍스㈜(이하 '회사'라 한다)가 취급하는 모든 개인정보는 개인정보보호법 등 관련 법령상의 개인정보보호 규정을 준수하여 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.</p>
                    </div>
                    <br/>
                    <div>
                        <h3>1. 개인정보의 처리목적</h3>
                        <p style={{marginLeft:20}}>① 회사는 고객발주시스템 발주서비스 확인 및 안내를 위한 목적으로 필요한 최소한으로 개인정보를 처리하고 있습니다.</p>
                        <br/>
                        <h3>2. 처리하는 개인정보의 항목 및 보유기간</h3>
                        <p style={{marginLeft:20}}>① 회사에서 처리하는 개인정보는 수집·이용 목적으로 명시한 범위 내에서 처리하며, 개인정보보호법 및 관련 법령에서 정하는 보유기간을 준용하여 이행하고 있습니다.</p>
                        <table style={{marginLeft:20, borderWidth: 1, borderColor: '#BDBDBD'}}>
                            <colgroup>
                                <col width="23%" />
                                <col width="17%" />
                                <col width="60%" />
                            </colgroup>
                            <thead>
                            <tr>
                                <th colSpan="2">구분</th>
                                <th>상세내용</th>
                            </tr>
                            </thead>
                            <tr>
                                <td rowSpan="3">회원가입 본인인증 및 결재요청 메일 전송</td>
                                <td>수집항목</td>
                                <td>(필수)아이디, 패스워드, 이름, 회사, 이메일, 휴대폰번호</td>
                            </tr>
                            <tr>
                                <td>수집목적</td>
                                <td>본인인증 및 결재요청 메일 전송</td>
                            </tr>
                            <tr>
                                <td>보유·이용 기간</td>
                                <td>계약 철회일로부터 5년</td>
                            </tr>
                            <tr>
                                <td rowSpan="3">배송정보 안내</td>
                                <td>수집항목</td>
                                <td>(필수)전화번호 (사무실 및 개인 휴대폰 번호)</td>
                            </tr>
                            <tr>
                                <td>수집목적</td>
                                <td>배송정보 안내 카카오 알림톡 전송 및 제품 배송,교환,수거에 따른 개인간 연락용도로 활용</td>
                            </tr>
                            <tr>
                                <td>보유·이용 기간</td>
                                <td>계약 철회일로부터 5년</td>
                            </tr>	
                            <tr>
                                <td rowSpan="2">카메라</td>
                                <td>수집항목</td>
                                <td>(필수)android.permission.CAMERA</td>
                            </tr>
                            <tr>
                                <td>수집목적</td>
                                <td>당사 자산번호 바코드 촬영</td>
                            </tr>	
                        </table>		            
                        <p style={{marginLeft:20}}>② 회사에서 처리하는 개인정보는 원칙적으로 개인정보의 수집·이용 목적이 달성되면 지체없이 파기합니다. 단, 관계 법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계 법령에서 정한 일정한 기간 내에서 개인정보를 보유·이용 합니다.</p>
                        <p style={{marginLeft:35}}>가.「전자상거래 등에서의 소비자보호에 관한 법률」에 의한 보관</p>
                        <p style={{marginLeft:35}}>1) 계약 또는 청약철회 등에 관한 기록 : 5년</p>
                        <p style={{marginLeft:35}}>2) 대금결제 및 재화 등의 공급에 관한 기록 : 5년</p>
                        <p style={{marginLeft:35}}>3) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</p>
                        <p style={{marginLeft:35}}>4) 표시/광고에 관한 기록 : 6개월</p>
                        <p style={{marginLeft:35}}>나.「통신비밀보호법」 시행령 제41조에 의한 통신사실확인자료 보관 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월</p> 
                        <p style={{marginLeft:35}}>다.「전자금융거래법」에 의한 보관</p>
                        <p style={{marginLeft:35}}>1) 건당 1만원 초과 전자금융거래에 관한 기록 : 5년</p> 
                        <p style={{marginLeft:35}}>2) 건당 1만원 이하 전자금융거래에 관한 기록 : 1년</p>
                        <p style={{marginLeft:35}}>라.「정보통신 이용촉진 및 정보보호 등에 관한 법률」에 의한 보관</p> 
                        <p style={{marginLeft:35}}>1) 본인확인에 관한 기록 : 6개월</p>
                        <br/>
                        <h3>3. 서비스 이용과정에서 이용자로부터 수집하는 개인정보</h3>
                        <p style={{marginLeft:20}}>PC웹, 모바일 웹/앱 이용과정에서 단말기 정보(OS, 화면사이즈, 디바이스 아이디), IP주소, 쿠키, 방문일시의 정보가 자동으로 생성되어 수집될수 있습니다.</p>
                        <br/>
                        <h3>4. 개인정보 제3자 제공에 관한 사항</h3>
                        <p style={{marginLeft:20}}>① 회사는 원칙적으로 정보주체의 개인정보를 수집·이용 목적으로 명시한 범위 내에서 처리하며, 정보주체의 사전 동의 없이는 본래의 목적 범위를 초과하여 처리하거나 제3자에게 제공하지 않습니다.</p> 
                        <br/>
                        <h3>5. 개인정보 파기 절차 및 방법</h3>
                        <p style={{marginLeft:20}}>① 회사는 원칙적으로 개인정보 처리목적이 달성된 개인정보는 지체 없이 파기합니다. 다만, 개인정보를 파기하지 않고 보존하는 경우에는, 다른 개인정보 및 개인정보파일과 분리하여 보관하고 해당 목적을 달성하기 위한 목적으로만 이용합니다.</p>
                        <p style={{marginLeft:20}}>② 파기의 절차, 기한 및 방법은 다음과 같습니다.</p>
                        <p style={{marginLeft:35}}>가. 파기절차</p>
                        <p style={{marginLeft:50}}>불필요한 개인정보 및 보유기간이 경과한 개인정보는 처리 목적 달성 완료 후 지체없이 파기합니다.</p>
                        <p style={{marginLeft:35}}>나. 파기방법</p>
                        <p style={{marginLeft:35}}>1) 전자적 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.</p>
                        <p style={{marginLeft:35}}>2) 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</p>
                        <br/>
                        <h3>6. 개인정보처리 위탁에 관한 사항</h3>
                        <p style={{marginLeft:20}}>① 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.</p>
                        <table style={{marginLeft:20, borderWidth: 1, borderColor: '#BDBDBD'}}>
                            <colgroup>
                                <col width="25%" />
                                <col width="55%" />
                                <col width="20%" />
                            </colgroup>
                            <thead>
                            <tr>
                                <th>위탁받는 자(수탁자)</th>
                                <th>위탁업무 내용</th>
                                <th>연락처</th>
                            </tr>
                            </thead>
                            <tr>
                                <td>AJ ICT</td>
                                <td>발주시스템 유지보수</td>
                                <td>02-6240-0831</td>
                            </tr>
                            <tr>
                                <td>일양로지스</td>
                                <td>제품 배송 및 반품,교환,수거 서비스</td>
                                <td>1588-0002</td>
                            </tr>
                            <tr>
                                <td>CJ올리브네트웍스</td>
                                <td>회원가입 및 상품 구매 곤련 카카오 알림톡 안내 서비스</td>
                                <td>02-6252-0000</td>
                            </tr>                
                        </table>	
                        <br/>
                        <h3>7. 개인정보 안전성 확보 조치</h3>
                        <p style={{marginLeft:20}}>개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</p>
                        <p style={{marginLeft:20}}>1. 개인정보 취급직원의 최소화 및 교육</p>
                        <p style={{marginLeft:20}}>개인정보를 취급하는 직원은 반드시 필요한 인원에 한하여 지정·관리하고 있으며 취급직원을 대상으로 안전한 관리를 위한 교육을 실시하고 있습니다.</p>
                        <p style={{marginLeft:20}}>2. 개인정보에 대한 접근 제한</p>
                        <p style={{marginLeft:20}}>개인정보를 처리하는 개인정보처리시스템에 대한 접근권한의 부여·변경·말소를 통하여 개인정보에 대한 접근통제를 위한 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.</p>
                        <p style={{marginLeft:20}}>3. 접속기록의 보관</p>
                        <p style={{marginLeft:20}}>개인정보처리시스템에 접속한 기록을 1년 이상 보관·관리하고 있습니다. 다만, 5만명 이상의 정보주체에 관하여 개인정보를 처리하거나, 고유식별정보 또는 민감정보를 처리하는 개인정보처리시스템의 경우에는 2년 이상 보관·관리하고 있습니다.</p>
                        <p style={{marginLeft:20}}>4. 개인정보의 암호화</p>
                        <p style={{marginLeft:20}}>개인정보는 암호화 등을 통해 안전하게 저장 및 관리되고 있습니다. 또한, 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의 별도 보안기능을 사용하고 있습니다.</p>
                        <p style={{marginLeft:20}}>5. 보안프로그램 설치 및 주기적 점검·갱신</p>
                        <p style={{marginLeft:20}}>해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적으로 갱신·점검하고 있습니다.</p>
                        <p style={{marginLeft:20}}>6. 비인가자에 대한 출입 통제</p>
                        <p style={{marginLeft:20}}>개인정보를 보관하고 있는 개인정보처리시스템의 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립·운영하고 있습니다.</p>
                        <br/>
                        <h3>8. 정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항</h3>
                        <p style={{marginLeft:20}}>① 정보주체는 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.</p>
                        <p style={{marginLeft:40}}>1. 개인정보 열람요구</p>
                        <p style={{marginLeft:40}}>2. 오류 등이 있을 경우 정정 요구</p>
                        <p style={{marginLeft:40}}>3. 삭제요구</p>
                        <p style={{marginLeft:40}}>4. 처리정지 요구</p>
                        <p style={{marginLeft:20}}>② 제1항에 따른 권리 행사는 회사에 대해 개인정보보호법 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체 없이 조치하겠습니다.</p>		
                        <p style={{marginLeft:20}}>③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 “개인정보 처리방법에 관한고시” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</p>
                        <p style={{marginLeft:20}}>④ 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조제4항, 제37조제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.</p>
                        <p style={{marginLeft:20}}>⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</p>
                        <p style={{marginLeft:20}}>⑥ 회사는 정보주체의 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</p>
                        <br/>
                        <h3>9. 개인정보보호 보호책임자 및 담당자 연락처</h3>		
                        <table style={{marginLeft:20, borderWidth: 1, borderColor: '#BDBDBD'}}>
                            <colgroup>
                                <col width="25%" />
                                <col width="25%" />
                                <col width="25%" />
                                <col width="25%" />
                            </colgroup>
                            <thead>
                            <tr>
                                <th>구분</th>
                                <th>부서명</th>
                                <th>성명/직책</th>
                                <th>연락처</th>
                            </tr>
                            </thead>
                            <tr>
                                <td>개인정보 보호책임자</td>
                                <td>경영지원본부</td>
                                <td>김영일 상무</td>
                                <td>02-6363-9875</td>
                            </tr>               
                        </table>
                        <br/>
                        <h3>10. 개인정보 열람청구를 접수·처리하는 부서</h3>
                        <p style={{marginLeft:20}}>① 정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.</p>
                        <table style={{marginLeft:20, borderWidth: 1, borderColor: '#BDBDBD'}}>
                            <colgroup>
                                <col width="25%" />
                                <col width="25%" />
                                <col width="25%" />
                                <col width="25%" />
                            </colgroup>
                            <thead>
                            <tr>
                                <th>구분</th>
                                <th>부서명</th>
                                <th>성명/직책</th>
                                <th>연락처</th>
                            </tr>
                            </thead>
                            <tr>
                                <td>개인정보 보호책임자</td>
                                <td>총무팀</td>
                                <td>이영규 팀장</td>
                                <td>02-6363-9933</td>
                            </tr>               
                        </table>
                        <br/>
                        <h3>11. 권익침해 구제방법</h3>
                        <p style={{marginLeft:20}}>① 정보주체는 개인정보침해로 인한 피해를 구제 받기 위하여 개인정보 분쟁조정위원회, 한국인터넷진흥원 개인정보 침해-신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다.</p>
                        <p style={{marginLeft:40}}>☞ 개인정보 분쟁조정위원회 : 1833-6972 (www.kopico.go.kr)</p>
                        <p style={{marginLeft:40}}>☞ 개인정보 침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</p>
                        <p style={{marginLeft:40}}>☞ 대검찰청 사이버수사과 : (국번없이) 1301 (www.spo.go.kr)</p>
                        <p style={{marginLeft:40}}>☞ 경찰청 사이버안전국 : (국번없이) 182 (cyberbureau.police.go.kr)</p>
                        <br/>
                        <h3>12. 개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항</h3>
                        <p style={{marginLeft:20}}>① 회사는 홈페이지에서 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC컴퓨터내의 하드디스크에 저장됩니다.</p>
                        <p style={{marginLeft:40}}>가. 쿠키 등 사용목적</p>
                        <p style={{marginLeft:40}}>홈페이지의 접속 빈도나 방문 시간 등을 분석, 정보주체의 관심분야를 파악 및 자취 추적 등을 위하여 사용합니다.</p>
                        <p style={{marginLeft:40}}>나. 쿠키 설정 거부 방법</p>
                        <p style={{marginLeft:40}}>1) 정보주체는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수 있습니다.</p>
                        <p style={{marginLeft:40}}>2) 설정방법 예시</p>
                        <p style={{marginLeft:40}}>- (인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구 {">"} 인터넷 옵션 {">"} 개인정보 {">"} 고급 {">"} 자동으로 쿠키 처리 안 함</p>
                        <p style={{marginLeft:40}}>- (Chrome의 경우) : 웹 브라우저 오른쪽 상단 더보기 {">"}  설정 {">"} 고급 {">"}  개인정보 및 보안 {">"} 사이트 설정 {">"} 쿠키 {">"} 사이트에서 쿠키 데이터를 저장하고 읽도록 허용(권장)</p>
                        <p style={{marginLeft:40}}>- (Android 앱) : 설정 {">"} 고급 {">"} 개인정보 보호 {">"} 권한 관리자 {">"} 유형 선택 {">"} 허용여부 선택</p>
                        <p style={{marginLeft:40}}>- (IOS 앱) : 설정 {">"} 개인정보 보호 {">"} 유형 선택 {">"} 허용여부 선택</p>
                        <br/>
                        <h3>13. 개인정보 처리방침 변경</h3>
                        <p style={{marginLeft:20}}>이 개인정보처리방침은 2021. 09. 01.부터 적용됩니다.</p> 
                    </div>
                </div>
            </div>
        </div>    
    )
}

export default Privacy;