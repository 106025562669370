import { httpGet, httpPost, httpPut, toQueryString } from '../Service';

// api 수기관리 리스트
export function apiMisreturnList(data) {
	return httpGet('/api/v1.0/cis/api/apiMisreturnList' + toQueryString(data));
}

// api 수기관리 리스트
export function apiMisreturnExchangeList(data) {
	return httpGet('/api/v1.0/cis/api/apiMisreturnExchangeList' + toQueryString(data));
}

// 처리완료
export function misreturnExchangeProc(data) {
	data = data || {};
	return httpPost('/api/v1.0/cis/api/misreturnExchangeProc', { body: data });
}
