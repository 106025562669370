import React, { Component, Fragment } from 'react';
import { getCustList, getSpotList, getCustomerList } from '../../service/CommonService';
import { errorMsgCheck } from '../../common/ComFunction';
import { ArrowRight } from '@material-ui/icons';
import { Dialog } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ProgressStore, CustSearchPopupStore } = CommonStore;

class CustSearchPopup extends Component {
	componentDidUpdate = () => {
		// didMount 동작 안하여 didUpdate로 변경
		if (CustSearchPopupStore.popupType === '2' && CustSearchPopupStore.open && !CustSearchPopupStore.spotSearched) {
			this.findSpotList();
		}
	};

	//고객사 조회
	findCustList = () => {
		if (CustSearchPopupStore.custNm === '') {
			//alert('검색어를 입력하셔야합니다.');
			ModalStore.openModal('검색어를 입력하셔야합니다.');
			this.custNm.focus();
			return;
		}
		let param = {
			custNm: CustSearchPopupStore.custNm,
			custNo: sessionStorage.custNo,
			repCustNo: sessionStorage.repCustNo,
			authCode: sessionStorage.authCode,
			mntnYn: CustSearchPopupStore.popupType === '4' ? 'Y' : '',
		};
		ProgressStore.activeProgress();
		getCustList(param)
			.then((r) => {
				CustSearchPopupStore.setCustList(r);
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};
	//고객사(customer기준) 조회
	findCustomerList = () => {
		if (CustSearchPopupStore.custNm === '') {
			//alert('검색어를 입력하셔야합니다.');
			ModalStore.openModal('검색어를 입력하셔야합니다.');
			this.custNm.focus();
			return;
		}
		let param = {
			custNm: CustSearchPopupStore.custNm,
			custNo: sessionStorage.custNo,
			repCustNo: sessionStorage.repCustNo,
			authCode: sessionStorage.authCode,
		};
		ProgressStore.activeProgress();
		getCustomerList(param)
			.then((r) => {
				CustSearchPopupStore.setCustList(r);
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	//지점 조회
	findSpotList = () => {
		let param = {
			custNo: CustSearchPopupStore.custNo,
			spotNm: CustSearchPopupStore.spotNm,
		};
		ProgressStore.activeProgress();
		getSpotList(param)
			.then((r) => {
				CustSearchPopupStore.setSpotList(r);
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	primaryTitle = (item) => {
		let custNm = item.custNm;
		let custNo = item.custNo;

		// return '[' + custNo + ']' + custNm;
		return custNm;
	};

	secondaryTitle = (item) => {
		let custNo = item.custNo;
		let ceoNm = item.ceoNm.replace(' ', '') === '' ? '' : '대표 : ' + item.ceoNm.replace(' ', '');
		let idNo = item.idNo.replace(' ', '') === '' ? '' : '사업자번호 : ' + item.idNo.replace(' ', '');

		return '고객번호 : ' + custNo + (ceoNm === '' ? '' : '\n' + ceoNm) + (idNo === '' ? '' : '\n' + idNo);
	};

	render() {
		const { open, popupTitle, popupType } = CustSearchPopupStore;

		let popContHeight = {
			height: 600,
		};

		if (popupType === '1' || popupType === '4' || popupType === '5' || popupType === '6') {
			// 고객사, 유지보수사, 제휴사
			return (
				<div>
					<Dialog open={open} onClose={CustSearchPopupStore.closePopup}>
						<div className="popSmArea searchPop">
							<div className="popCont" style={popContHeight}>
								<div className="h2Wrap">
									<h2>
										{popupTitle} 검색 <span>*는 필수항목입니다.</span>
									</h2>
								</div>
								<div className="writeType02 type2">
									<table>
										<colgroup>
											<col style={{ width: '160px' }} />
											<col />
										</colgroup>
										<tbody>
											<tr>
												<th>
													{popupTitle}
													<span className="star">*</span>
												</th>
												<td>
													<input
														type="text"
														ref={(ref) => (this.custNm = ref)}
														name="custNm"
														className="size01"
														value={CustSearchPopupStore.custNm}
														onChange={(e) => CustSearchPopupStore.setCustNm(e.target.value)}
														onKeyDown={(e) => {
															if (e.key == 'Enter') this.findCustList();
														}}
													/>
													<a href="#;" className="btn01 search" onClick={this.findCustList}>
														<span>검색</span>
													</a>
												</td>
											</tr>
											<tr>
												<td colSpan="2">
													{/* list출력부분 */}
													<div style={{ height: '380px', overflowX: 'hidden', overflowY: 'auto' }}>
														<div>
															<List component="nav">
																{CustSearchPopupStore.custList.map((item, idx) => (
																	<ListItem button key={idx}>
																		<ListItemIcon>
																			<ArrowRight />
																		</ListItemIcon>
																		<ListItemText
																			inset
																			primary={this.primaryTitle(item)}
																			secondary={
																				<Fragment>
																					{this.secondaryTitle(item)
																						.split('\n')
																						.map((line, i) => {
																							return (
																								<Fragment key={i}>
																									{line}
																									<br />
																								</Fragment>
																							);
																						})}
																				</Fragment>
																			}
																			onClick={() => {
																				CustSearchPopupStore.closePopup(item);
																			}}
																		/>
																	</ListItem>
																))}
															</List>
														</div>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								{/* <div className="btnCenter">
                                    <a className="btn02 blue">등록</a>
                                    <a className="btn02 white" onClick={CustSearchPopupStore.closePopup}>취소</a>
                                </div> */}
								<div
									className="btnClose"
									onClick={() => {
										CustSearchPopupStore.closePopup(undefined);
									}}
								>
									<a>팝업닫기</a>
								</div>
							</div>
						</div>
					</Dialog>
				</div>
			);
		} else if (popupType === '2') {
			//지점
			return (
				<div>
					<Dialog open={open} onClose={CustSearchPopupStore.closePopup}>
						<div className="popSmArea searchPop">
							<div className="popCont" style={popContHeight}>
								<div className="h2Wrap">
									<h2>{popupTitle} 검색 </h2>
								</div>
								<div className="writeType02 type2">
									<table>
										<colgroup>
											<col style={{ width: '160px' }} />
											<col />
										</colgroup>
										<tbody>
											<tr>
												<th>{popupTitle}</th>
												<td>
													<input
														type="text"
														ref={(ref) => (this.spotNm = ref)}
														name="spotNm"
														className="size01"
														onChange={(e) => CustSearchPopupStore.setSpotNm(e.target.value)}
														onKeyDown={(e) => {
															if (e.key == 'Enter') this.findSpotList();
														}}
													/>
													<a href="#;" className="btn01 search" onClick={this.findSpotList}>
														<span>검색</span>
													</a>
												</td>
											</tr>
											<tr>
												<td colSpan="2">
													{/* list출력부분 */}
													<div style={{ height: '380px', overflowX: 'hidden', overflowY: 'auto' }}>
														<div>
															<List component="nav">
																{CustSearchPopupStore.spotList.map((item, idx) => (
																	<ListItem button key={idx}>
																		<ListItemIcon>
																			<ArrowRight />
																		</ListItemIcon>
																		<ListItemText
																			inset
																			primary={
																				(item.spotId === '' ? '' : '[' + item.spotId + '] ') + item.spotNm + (item.addrUser === '' ? '' : ' - ' + item.addrUser)
																			}
																			secondary={item.postAddr}
																			onClick={() => {
																				CustSearchPopupStore.closePopup(item);
																			}}
																		/>
																	</ListItem>
																))}
															</List>
														</div>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								{/* <div className="btnCenter">
                                    <a className="btn02 blue">등록</a>
                                    <a className="btn02 white" onClick={CustSearchPopupStore.closePopup}>취소</a>
                                </div> */}
								<div
									className="btnClose"
									onClick={() => {
										CustSearchPopupStore.closePopup(undefined);
									}}
								>
									<a>팝업닫기</a>
								</div>
							</div>
						</div>
					</Dialog>
				</div>
			);
		} else if (popupType === '3') {
			//고객사(rental.dbo.customer 기준)
			return (
				<div>
					<Dialog open={open} onClose={CustSearchPopupStore.closePopup}>
						<div className="popSmArea searchPop">
							<div className="popCont" style={popContHeight}>
								<div className="h2Wrap">
									<h2>
										{popupTitle} 검색 <span>*는 필수항목입니다.</span>
									</h2>
								</div>
								<div className="writeType02 type2">
									<table>
										<colgroup>
											<col style={{ width: '160px' }} />
											<col />
										</colgroup>
										<tbody>
											<tr>
												<th>
													{popupTitle}
													<span className="star">*</span>
												</th>
												<td>
													<input
														type="text"
														ref={(ref) => (this.custNm = ref)}
														name="custNm"
														className="size01"
														value={CustSearchPopupStore.custNm}
														onChange={(e) => CustSearchPopupStore.setCustNm(e.target.value)}
														onKeyDown={(e) => {
															if (e.key == 'Enter') this.findCustomerList();
														}}
													/>
													<a href="#;" className="btn01 search" onClick={this.findCustomerList}>
														<span>검색</span>
													</a>
												</td>
											</tr>
											<tr>
												<td colSpan="2">
													{/* list출력부분 */}
													<div style={{ height: '380px', overflowX: 'hidden', overflowY: 'auto' }}>
														<div>
															<List component="nav">
																{CustSearchPopupStore.custList.map((item, idx) => (
																	<ListItem button key={idx}>
																		<ListItemIcon>
																			<ArrowRight />
																		</ListItemIcon>
																		<ListItemText
																			inset
																			primary={this.primaryTitle(item)}
																			secondary={
																				<Fragment>
																					{this.secondaryTitle(item)
																						.split('\n')
																						.map((line, i) => {
																							return (
																								<Fragment key={i}>
																									{line}
																									<br />
																								</Fragment>
																							);
																						})}
																				</Fragment>
																			}
																			onClick={() => {
																				CustSearchPopupStore.closePopup(item);
																			}}
																		/>
																	</ListItem>
																))}
															</List>
														</div>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								{/* <div className="btnCenter">
                                    <a className="btn02 blue">등록</a>
                                    <a className="btn02 white" onClick={CustSearchPopupStore.closePopup}>취소</a>
                                </div> */}
								<div
									className="btnClose"
									onClick={() => {
										CustSearchPopupStore.closePopup(undefined);
									}}
								>
									<a>팝업닫기</a>
								</div>
							</div>
						</div>
					</Dialog>
				</div>
			);
		}
	}
}

export default observer(CustSearchPopup);
