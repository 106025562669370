import React, { Component } from 'react';
import LeftMenu from '../include/LeftMenu';
import Header from '../include/Header';
import IBSheet from '../IBSheet';
import { Search } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { resultToIbSheet, errorMsgCheck, dateToFormat, managerCheck, buildSelectItems, SheetParamData3 } from '../../common/ComFunction';
import { getCisCommCode } from '../../service/CommonService';
import { getErpDataSyncList, getItemListForReverse, saveErpDataSync } from '../../service/admin/ErpDataSyncService';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/NoteAdd';
import SaveIcon from '@material-ui/icons/Save';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore, UserPopupStore, LargeInputNoPopupStore } = CommonStore;

var result = {
	//sheet데이터
	data: [],
};

let firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
let today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

class ErpDataSync extends Component {
	constructor(props) {
		super(props);
		this.sheetData = {
			id: 'sheetErpDataSync', // sheet id
			initData: 'admin/Init-ErpDataSync', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '250px', // sheet height
		};
		this.sheetData2 = {
			id: 'sheetErpDataSync2', // sheet id
			initData: 'admin/Init-ErpDataSync2', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '180px', // sheet height
		};
	}

	state = {
		//검색조건
		custNoSearch: '', //고객사코드_검색
		custNmSearch: '', //고객사명_검색
		asstNoSearch: '', //자산번호_검색
		saleChgSearch: '', //영업담당자_검색
		modelSearch: '', //모델명_검색
		contNoSearch: '', //계약번호_검색
		frDate: moment(firstDay), //계약일자_from
		toDate: moment(today), //계약일자_to
		contNoSearch: '', //계약번호_검색
		contNoSearch2: '', //계약번호_검색
		rentTypeSearch: '1', //렌탈유형_검색
		outYn: 'N', //OUT(구발주시스템)여부

		//조회결과
		contList: [], //계약리스트
		contItemList: [], //계약제품리스트

		//입력항목 - 신청자정보
		custNo: '', //고객번호
		contNo: '', //계약번호
		userId: '', //사용자id
		userName: '', //사용자명
		deptName: '', //부서명
		addrSeq: '', //지점시퀀스
		spotId: '', //지점id
		spotNm: '', //지점명
		appUse: '', //고객결재사용여부

		// 입력항목 - 사용자정보
		name: '', // 실사용자명
		deptNm: '', // 실사용자 부서
		telno: '', // 실사용자 전화번호
		hpno: '', // 실사용자 휴대전화
		chgYn: false, //입력변경여부

		//모달,
		postModalOpen: false,
		//모달 end
		//검색팝업
		openItemSearch: false, //제품검색팝업
		openFile: false, //파일팝업

		rentTypeSearchList: [], // 공통코드 선언
	};
	resetState = (type) => {
		if (type === 'A') {
			// 전체 초기화
			this.setState({
				contList: [], //리스트
				contItemList: [], //리스트
			});
			this.resetResult(); //ibsheet 데이터 리셋
		}
		this.setState({
			//입력항목
			custNo: '', //고객번호
			contNo: '', //계약번호
			userId: '', //사용자id
			userName: '', //사용자명
			deptName: '', //부서명
			addrSeq: '', //지점시퀀스
			spotId: '', //지점id
			spotNm: '', //지점명

			name: '', // 실사용자 이름
			deptNm: '', // 실사용자 부서
			telno: '', // 실사용자 전화번호
			hpno: '', // 실사용자 핸드폰번호

			chgYn: false, //입력변경여부
		});
	};
	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
			chgYn: true, // 변경일어남
		});
	};
	handleChangeNum = (e) => {
		// 숫자만 입력받도록
		const value = e.target.value.replace(/\D/, '');
		this.setState({
			[e.target.name]: value,
			chgYn: true, // 변경일어남
		});
	};
	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
		window[this.sheetData2.id].DisposeSheet();
	};

	componentDidMount = () => {
		managerCheck(); // 관리자체크
		ProgressStore.activeProgress();
		//공통코드 조회
		Promise.all([getCisCommCode('18')])
			.then((r) => {
				let [code18Data] = r;
				this.setState({
					rentTypeSearchList: code18Data, // 렌트유형 공통코드
				});

				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err);
			});
	};

	// 고객사팝업
	handleClickOpen = (popupType) => {
		if (popupType === '1' || popupType === '3') {
			CustSearchPopupStore.openPopup(popupType, null, null, this.handleClose);
		}
	};
	handleClose = (item) => {
		if (item !== undefined) {
			console.log(item.appUse);
			if (CustSearchPopupStore.popupType === '1') {
				// 고객사 검색 결과
				this.setState({
					custNoSearch: item.custNo,
					custNmSearch: item.custNm,
					appUse: item.appUse,
				});
			}
		}
	};
	// 고객사팝업 end
	// 조회된 결과 중 선택한 row input에 셋팅
	setInput = () => {
		let key = window.getSelectedKey();
		if (key !== undefined) {
			this.state.contList.map((item, idx) => {
				if (item.contNo === key) {
					this.resetState();
					this.setState(
						{
							contNoSearch2: key,
							contNo: item.contNo,
							custNo: item.custNo,
						},
						() => {
							this.getContItemList();
						},
					);
					// console.log(key);
				}
			});
		}
		//console.log(window[this.sheetData.id].ColSaveName(window.getClickedCol()));
	};
	// sheet데이터 리셋
	resetResult = () => {
		result = {
			data: [],
		};
		window[this.sheetData.id].LoadSearchData(result, {
			Sync: 1,
		});
		window[this.sheetData2.id].LoadSearchData(result, {
			Sync: 1,
		});
	};
	dateHandler = (date, type) => {
		if (type === 's') {
			this.setState({ frDate: date });
		} else {
			this.setState({ toDate: date });
		}
	};

	//조회
	getList = () => {
		if (this.state.custNoSearch === '' && this.state.contNoSearch === '') {
			ModalStore.openModal('고객명 또는 계약번호 입력 후 조회 가능합니다.');
			return;
		}
		if (this.state.custNoSearch !== '') {
			if ((this.state.frDate === null && this.state.toDate !== null) || (this.state.frDate !== null && this.state.toDate === null)) {
				ModalStore.openModal('계약일자는 시작일과 종료일을 모두 입력해야합니다.');
				return;
			}
		}
		ProgressStore.activeProgress();
		let param = {
			custNoSearch: this.state.custNoSearch, //고객사코드_검색
			custNmSearch: this.state.custNmSearch, //고객사명_검색
			// asstNoSearch  :   this.state.asstNoSearch,  //자산번호_검색
			// saleChgSearch :   this.state.saleChgSearch,  //영업담당자_검색
			// modelSearch   :   this.state.modelSearch,  //모델명_검색
			contNoSearch: this.state.contNoSearch, //계약번호_검색
			frDate: this.state.frDate === null ? '' : dateToFormat(this.state.frDate), //계약일자_from
			toDate: this.state.toDate === null ? '' : dateToFormat(this.state.toDate), //계약일자_to
			rentTypeSearch: this.state.rentTypeSearch, //렌탈유형
			outYn: this.state.outYn, //구발주시스템여부
		};
		getErpDataSyncList(param)
			.then((r) => {
				this.setState({ contList: r }, () => {
					// JSON객체형태로 서버에서 받으므로 IBsheet에서 출력 가능한 배열형태로 변환
					window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				});

				this.resetState();

				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	//계약제품조회
	getContItemList = () => {
		ProgressStore.activeProgress();
		let param = {
			contNo: this.state.contNoSearch2, //계약번호_검색
		};

		getItemListForReverse(param)
			.then((r) => {
				this.setState({ contItemList: r }, () => {
					// JSON객체형태로 서버에서 받으므로 IBsheet에서 출력 가능한 배열형태로 변환
					window[this.sheetData2.id].LoadSearchData(resultToIbSheet(r));
				});

				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	// 사용자팝업
	searchUser = () => {
		if (this.state.custNoSearch === '') {
			ModalStore.openModal('고객명 검색 후 가능합니다.');
			return;
		}
		UserPopupStore.openPopup(null, this.state.custNoSearch, this.handleUserSearch);
	};
	handleUserSearch = (item) => {
		if (item !== undefined) {
			this.setState({
				userId: item.userId,
				userName: item.userName,
				deptName: item.deptName,
				addrSeq: item.addrSeq,
				spotId: item.spotId,
				spotNm: item.spotNm,
			});
		}
	};
	// 사용자팝업 end
	// 저장
	save = () => {
		var sRow = window[this.sheetData.id].FindStatusRow('I|U');
		if (sRow == '') {
			ModalStore.openModal('선택된 내역이 없습니다.');
			return;
		}
		if (this.state.userId === '') {
			ModalStore.openModal('사용자 정보를 입력하세요.');
			return;
		}

		ConfirmModalStore.openModal('입력된 사용자정보로 발주등록을 하시겠습니까?', this.saveErpDataSync);
	};
	saveErpDataSync = () => {
		ProgressStore.activeProgress();

		/*
            orde_status =  0 : 접수대기중 , 1 : 결재중 , 2 : 반려, 3 : 결재완료, 4: 계약완료 9 : 신청취소
        */

		let param = {
			addrSeq: this.state.addrSeq, //신청자의 지점시퀀스
			userId: this.state.userId, //신청자ID
			ordeStatus: '4',
			spotId: this.state.spotId,
			spotNm: this.state.spotNm,
			deptName: this.state.deptName,
			userName: this.state.userName,
			name: this.state.name,
			deptNm: this.state.deptNm,
			telno: this.state.telno,
			hpno: this.state.hpno,
		};
		let sheet = window[this.sheetData.id];

		saveErpDataSync(SheetParamData3(sheet, param, true))
			.then((r) => {
				ModalStore.openModal('저장되었습니다.');
				// this.resetState();
				this.resetResult(); //ibsheet 데이터 리셋
				this.getList(); //저장 성공 후 재조회
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	handleTextArray = (text) => {
		if (text === '') return;
		this.setState({
			contNoSearch: text,
		});
	};

	render() {
		const dateInput = <input type="text" style={{ width: 120 }} />;
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mb30">
								<h2>ERP렌탈신청동기화</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.getList}>
										<span>조회</span>
									</a>
									{/* <a className="btn01 add"><span>추가</span></a> */}
									{/* <a className="btn01 save" onClick={this.save}><span>발주등록</span></a> */}
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '8%' }} />
										<col style={{ width: '26%' }} />
										<col style={{ width: '8%' }} />
										<col style={{ width: '32%' }} />
										<col style={{ width: '8%' }} />
										<col style={{ width: '18%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>고객명</th>
											<td>
												<input
													type="text"
													className="size01"
													ref={(ref) => (this.custNmSearch = ref)}
													name="custNmSearch"
													value={this.state.custNmSearch || ''}
													onChange={this.handleChange}
													readOnly
												/>
												{/* 고객사검색버튼 */}
												<IconButton
													aria-label="Search"
													className="iconButton"
													onClick={() => {
														this.handleClickOpen('1');
													}}
												>
													<Search fontSize="small" />
												</IconButton>
											</td>
											<th>계약일자</th>
											<td>
												<DatePicker
													customInput={dateInput}
													dateFormat="YYYY-MM-DD"
													selected={this.state.frDate}
													onChange={(date) => this.dateHandler(date, 's')}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
												<span style={{ marginLeft: 10, marginRight: 10 }}>~</span>
												<DatePicker
													customInput={dateInput}
													dateFormat="YYYY-MM-DD"
													selected={this.state.toDate}
													onChange={(date) => this.dateHandler(date, 'e')}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
											</td>
											<th>렌탈유형</th>
											<td>
												<select name="rentTypeSearch" value={this.state.rentTypeSearch} onChange={this.handleChange} style={{ width: 150 }}>
													{this.state.rentTypeSearchList ? buildSelectItems(this.state.rentTypeSearchList) : ''}
												</select>
											</td>
										</tr>
										<tr>
											<th>계약번호</th>
											<td colSpan="5">
												<input
													type="text"
													ref={(ref) => (this.contNoSearch = ref)}
													name="contNoSearch"
													value={this.state.contNoSearch || ''}
													onKeyDown={(e) => {
														if (e.key === 'Enter') this.getList();
													}}
													onChange={this.handleChange}
													style={{ marginRight: 10, width: 160 }}
												/>
												<Button
													color="primary"
													variant="outlined"
													size="small"
													style={{ marginLeft: 5, alignItems: 'initial' }}
													onClick={() => LargeInputNoPopupStore.openPopup(this.handleTextArray)}
												>
													<AddIcon style={{ fontSize: 20, marginRight: 5 }} />
													대량입력
												</Button>
											</td>
										</tr>
										{sessionStorage.authCode === '0' ? (
											/* 슈퍼관리자는 구발주여부 관계없이 조회가능하다 */
											<tr>
												<th>
													구발주
													<br />
													여부
												</th>
												<td colSpan="5">
													<select name="outYn" value={this.state.outYn} onChange={this.handleChange} style={{ width: 150 }}>
														<option value="Y">Y</option>
														<option value="N" selected>
															N
														</option>
													</select>
												</td>
											</tr>
										) : null}
									</tbody>
								</table>
							</div>

							<div className="writeType05 short">
								<div onClick={this.setInput}>
									<div
										className="scroll"
										onMouseOver={() => {
											document.body.style.overflow = 'hidden';
										}}
										onMouseOut={() => {
											document.body.style.overflow = 'auto';
										}}
									>
										<p className="headline">계약목록</p>
										<div onClick={this.setInput}>
											<IBSheet {...this.sheetData} />
										</div>
									</div>
								</div>
							</div>
							<div className="writeType05 short">
								<div style={{ float: 'left', width: '45%' }}>
									<p className="headline">사용자 정보</p>
									<table>
										<colgroup>
											<col style={{ width: '30%' }} />
											<col style={{ width: '70%' }} />
										</colgroup>
										<tbody>
											<tr>
												<th>사용자명</th>
												<td>
													<input
														type="text"
														className="size01"
														name="name"
														value={this.state.name || ''}
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.name = ref)}
													/>
												</td>
											</tr>
											<tr>
												<th>부서명</th>
												<td>
													<input
														type="text"
														className="size01"
														name="deptNm"
														value={this.state.deptNm || ''}
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.deptNm = ref)}
													/>
												</td>
											</tr>
											<tr>
												<th>전화번호</th>
												<td>
													<input
														type="text"
														className="size01"
														name="telno"
														value={this.state.telno || ''}
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.telno = ref)}
													/>
												</td>
											</tr>
											<tr>
												<th>휴대전화</th>
												<td>
													<input
														type="text"
														className="size01"
														name="hpno"
														value={this.state.hpno || ''}
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.hpno = ref)}
													/>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div style={{ float: 'right', width: '50%' }}>
									<p className="headline">신청자 정보</p>
									<table>
										<colgroup>
											<col style={{ width: '30%' }} />
											<col style={{ width: '70%' }} />
										</colgroup>
										<tbody>
											<tr>
												<th>신청자명 </th>
												<td>
													<input
														type="text"
														className="size01"
														name="userName"
														value={this.state.userName || ''}
														readOnly
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.userName = ref)}
													/>
													<IconButton
														aria-label="Search"
														className="iconButton"
														onClick={() => {
															this.searchUser();
														}}
													>
														<Search fontSize="small" />
													</IconButton>
												</td>
											</tr>
											<tr>
												<th>신청자ID </th>
												<td>
													<input
														type="text"
														name="userId"
														value={this.state.userId || ''}
														readOnly
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.userId = ref)}
													/>
												</td>
											</tr>
											<tr>
												<th>부서(대리점)명 </th>
												<td>
													<input
														type="text"
														name="deptName"
														value={this.state.deptName || ''}
														readOnly
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.deptName = ref)}
													/>
												</td>
											</tr>
											<tr>
												<th>{sessionStorage.spotTitle}명 </th>
												<td>
													<input
														type="text"
														name="spotNm"
														value={this.state.spotNm || ''}
														readOnly
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.spotNm = ref)}
													/>
												</td>
											</tr>
										</tbody>
									</table>
									<div className="btnRight2">
										<Button className="button" onClick={this.save} color="primary" variant="contained" size="small">
											<SaveIcon className="saveIcon" />
											<span className="text">발주등록</span>
										</Button>
									</div>
								</div>
							</div>
							<div className="writeType05 short">
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">계약제품</p>
									<div>
										<IBSheet {...this.sheetData2} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(ErpDataSync);
