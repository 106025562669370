import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CheckIcon from '@material-ui/icons/HowToReg';
import { Transition } from '../../common/ComFunction';

import { observer } from 'mobx-react';
import ConfirmModalStore from '../../common/ConfirmModalStore';
class ConfirmModal extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div>
				<Dialog open={ConfirmModalStore.modalOpen} TransitionComponent={Transition}>
					<div style={{ padding: '20px 20px 20px 20px' }}>
						<CheckIcon />
						<DialogContent>{ConfirmModalStore.modalText}</DialogContent>
						<DialogActions>
							<Button onClick={() => ConfirmModalStore.closeModal('Y')} color="primary" autoFocus>
								{ConfirmModalStore.modalBtnTextY}
							</Button>
							<Button onClick={() => ConfirmModalStore.closeModal('X')} color="secondary">
								{ConfirmModalStore.modalBtnTextX}
							</Button>
						</DialogActions>
					</div>
				</Dialog>
			</div>
		);
	}
}
export default observer(ConfirmModal);
