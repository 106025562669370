import { httpGet, httpPost, httpPut, toQueryString } from '../Service';

// 유지보수고객리스트조회
export function getMntncCustList(data) {
	return httpGet('/api/v1.0/cis/mntnc/custList' + toQueryString(data));
}

// 고객사유지보수대상 자산리스트조회
export function getMntncCustAsstList(data) {
	return httpGet('/api/v1.0/cis/mntnc/custAsstList' + toQueryString(data));
}

// 유지보수사 엔지니어리스트 조회
export function getMntncProcUserList(data) {
	return httpGet('/api/v1.0/cis/mntnc/procUserList' + toQueryString(data));
}

// 할당 엔지니어 자산 조회
export function getMntncProcAsstList(data) {
	return httpGet('/api/v1.0/cis/mntnc/procAsstList' + toQueryString(data));
}

// 엔지니어 할당
export function allocProcUser(data) {
	return httpPut('/api/v1.0/cis/mntnc/alloc/procUser/' + data);
}

// 엔지니어 할당 해제
export function deallocProcUser(data) {
	return httpPut('/api/v1.0/cis/mntnc/dealloc/procUser/' + data);
}

// 유지보수 자산 전체 리스트 조회
export function getMntncAsstAllList(data) {
	return httpGet('/api/v1.0/cis/mntnc/mntncAsstAllList' + toQueryString(data));
}
