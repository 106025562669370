import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { IconButton } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import UpIcon from '@material-ui/icons/ArrowUpward';
import DownIcon from '@material-ui/icons/ArrowDownward';
import ApprIcon from '@material-ui/icons/Face';

import { errorMsgCheck, resultToIbSheet, SheetParamData } from '../../common/ComFunction';
import { getFixApprList, insertFixAppr, updateFixAppr } from '../../service/members/FixApprService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore, UserPopupStore } = CommonStore;

class FixApprManager extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selRow: 0,
			custFixSeq: 0,
			selAppSeq: 0,
			sCustNo: sessionStorage.authCode === '0' || sessionStorage.authCode === '1' ? '' : sessionStorage.custNo,
			sCustNm: sessionStorage.authCode === '0' || sessionStorage.authCode === '1' ? '' : sessionStorage.custNm,
			userId: '',
			addrSeq: '',

			disabledBtn: true,
		};

		this.sheetData = {
			id: 'fixApprSheet', // sheet id
			initData: 'members/Init-FixAppr', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '400px', // sheet height
		};
	}

	componentDidMount = () => {
		const interval = setInterval(() => {
			if (window[this.sheetData.id]) {
				window[this.sheetData.id].ShowFilterRow();
				clearInterval(interval);
			}
		}, 100);
	};

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
	};

	// 고객사팝업
	handleClickOpen = (popupType) => {
		if (popupType === '1') {
			//고객사 팝업
			CustSearchPopupStore.openPopup(popupType, null, null, this.handleClose);
		} else if (popupType === '2') {
			//지점 팝업
			//지점은 고객사 팝업 검색 후 가능
			if (this.state.sCustNo === '') {
				ModalStore.openModal(sessionStorage.spotTitle + '검색은 회사명 검색 이후 가능합니다.');
				return;
			}
			CustSearchPopupStore.openPopup(popupType, sessionStorage.spotTitle + '명', this.state.sCustNo, this.handleClose);
		}
	};

	handleClose = (item) => {
		if (item !== undefined) {
			if (CustSearchPopupStore.popupType === '1') {
				// 고객사 검색 결과
				if (item.custNo !== undefined) {
					this.setState({
						sCustNo: item.custNo,
						sCustNm: item.custNm,
					});
				}
			} else if (CustSearchPopupStore.popupType === '2') {
				// 지점 검색 결과
				if (item.addrSeq !== undefined) {
					this.setState({
						addrSeq: item.addrSeq, //지점시퀀스
						spotNm: item.spotNm, //지점명
					});
				}
			}
		}
	};

	handleUserSearch = (item) => {
		if (item !== undefined) {
			this.setState({
				userId: item.userId,
				userNm: item.userName,
				addrSeq: item.addrSeq,
			});
			this.insertCheck(item.userName);
		}
	};

	searchUser = () => {
		UserPopupStore.openPopup('rep', sessionStorage.authCode > 1 ? this.state.sCustNo : '', this.handleUserSearch);
	};

	getFixApprList = () => {
		let param = {
			custNo: this.state.sCustNo,
		};

		ProgressStore.activeProgress();
		getFixApprList(param)
			.then((r) => {
				window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	updateCheck = () => {
		ConfirmModalStore.openModal('저장 하시겠습니까?', this.saveFixAppr);
	};

	saveFixAppr = () => {
		var sheet = window[this.sheetData.id];

		let param = {
			userId: sessionStorage.userId,
		};

		ProgressStore.activeProgress();
		updateFixAppr(SheetParamData(sheet, param, false))
			.then(() => {
				ModalStore.openModal('저장 되었습니다.');
				this.getFixApprList();
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	insertCheck = (userName) => {
		ConfirmModalStore.openModal(userName + '을 등록 하시겠습니까?', this.insertFixAppr);
	};

	insertFixAppr = () => {
		let param = {
			custNo: this.state.sCustNo,
			userId: this.state.userId,
			addrSeq: this.state.addrSeq,
		};

		ProgressStore.activeProgress();
		insertFixAppr(param)
			.then(() => {
				ModalStore.openModal('저장 되었습니다.');
				this.getFixApprList();
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	selApprData = () => {
		let data = window.getAppUserData();

		if (!data) return;

		this.setState({
			selRow: data.row,
			selCustFixSeq: data.custFixSeq,
			selAppSeq: data.appSeq,
		});
	};
	/*
    appSeqHandler =(act)=> {
        if(this.state.selRow === 0){
            ModalStore.openModal('순서를 변경할 결재자를 선택해 주세요.');
            return;
        }
       
        var sheet = window[this.sheetData.id];
        let selRow = this.state.selRow;
        let selAppSeq = this.state.appSeq;
        let totRow = sheet.LastRow();
        console.log('selRow = '+selRow);
        let toRow;
        let fromRow;
        let chgAppSeq;
        let orgAppSeq;
        if(act === 'UP'){
            toRow = selRow > 1 ? selRow - 1 : 1;
            chgAppSeq = selAppSeq > 1 ? selAppSeq - 1 : 1;

        }else if(act === 'DOWN'){
            console.log('selRow < totRow =' + selRow + ' , '+ totRow )
            toRow = selRow < totRow ? selRow + 1 : totRow;
            chgAppSeq = selAppSeq < totRow ? selAppSeq + 1 : totRow;

        }

        console.log('toRow = '+toRow);
        console.log('chgAppSeq = '+chgAppSeq);

        sheet.DataMove(toRow, -1);
        sheet.SetCellValue(toRow, "appSeq", chgAppSeq);
        sheet.SetCellValue(selRow, "appSeq", selAppSeq);
    }
*/
	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mb30">
								<h2>고정 결재선 관리</h2>
							</div>
							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: 100 }} />
										<col />
									</colgroup>
									<tbody>
										<tr>
											<th>고객명</th>
											<td>
												<input type="text" value={this.state.sCustNm} style={{ width: 200, marginRight: 10 }} />
												{/* 고객사검색버튼 */}
												<IconButton
													onClick={() => this.handleClickOpen('1')}
													color="secondary"
													className="iconButton"
													style={{ display: sessionStorage.btnControl }}
												>
													<Search fontSize="small" />
												</IconButton>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div style={{ marginTop: 15, textAlign: 'right' }}>
								<Button color="primary" variant="contained" size="small" style={{ alignItems: 'initial', marginRight: 5 }} onClick={this.getFixApprList}>
									<Search style={{ fontSize: 20, marginRight: 5 }} />
									조회
								</Button>
								<Button
									color="secondary"
									variant="contained"
									size="small"
									style={{ alignItems: 'initial', marginRight: 5, backgroundColor: '#0062cc' }}
									onClick={this.searchUser}
								>
									<ApprIcon style={{ fontSize: 20, marginRight: 5 }} />
									결재자 등록
								</Button>
								<Button color="secondary" variant="contained" size="small" style={{ alignItems: 'initial' }} onClick={this.updateCheck}>
									<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
									저장
								</Button>
							</div>
							<div className="writeType03" onClick={this.selApprData}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">고정 결재선</p>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
							<div>
								<p>*최초 결재자 : 모든 결재에 첫번째로 처리하는 자</p>
								<p>*최종 결재자 : 모든 결재의 마지막 결재자로 처리하는 자</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(FixApprManager);
