import React, { Component } from 'react';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';
import { errorMsgCheck, buildSelectItems, resultToIbSheet, managerCheck } from '../../common/ComFunction';
import { getCisCommCode } from '../../service/CommonService';
import { getMenuByAuthList, saveAuth } from '../../service/admin/MenuService';
import Header from '../include/Header';
import { Radio } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import { RadioGroup } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ProgressStore } = CommonStore;

var result = {
	//sheet데이터
	data: [],
};
class AuthManager extends Component {
	constructor(props) {
		super(props);

		this.sheetData = {
			//메뉴리스트(대메뉴)
			id: 'sheetAuthManager', // sheet id
			initData: 'admin/Init-AuthManager', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '500px', // sheet height
		};
	}
	state = {
		//검색조건
		authCode: '0', //권한코드
		treeControl: '2', //트리제어

		//조회결과
		menuList: [], //메뉴리스트

		//모달
		postModalOpen: false,
		openSpotPop: false, //지점검색
		//모달 end

		authCodeList: [], // 공통코드 선언
	};
	componentDidMount() {
		managerCheck(); // 관리자체크
		ProgressStore.activeProgress();
		// this.findCustList();
		//공통코드 조회
		Promise.all([getCisCommCode('13')])
			.then((r) => {
				let [code13Data] = r;
				this.setState({
					authCodeList: code13Data, // 권한 공통코드
				});

				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err);
			});
	}
	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
		});
	};
	handleChangeNum = (e) => {
		// 숫자만 입력받도록
		const value = e.target.value.replace(/\D/, '');
		this.setState({
			[e.target.name]: value,
		});
	};
	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
	};
	// sheet데이터 리셋
	resetResult = () => {
		result = {
			data: [],
		};
		window[this.sheetData.id].LoadSearchData(result, {
			Sync: 1,
		});
	};

	//권한리스트 조회
	getMenuByAuthList = () => {
		ProgressStore.activeProgress();
		let param = {
			authCode: this.state.authCode,
		};

		getMenuByAuthList(param)
			.then((r) => {
				this.resetResult(); //ibsheet 데이터 리셋

				this.setState({ menuList: r }, () => {
					// JSON객체형태로 서버에서 받으므로 IBsheet에서 출력 가능한 배열형태로 변환
					window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				});

				// this.resetState('I');
				window[this.sheetData.id].ShowTreeLevel(this.state.treeControl - 1);
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};

	// 저장
	saveAuthByMenu = () => {
		// 조회후 저장가능
		if (this.state.menuList.length === 0) {
			ModalStore.openModal('조회 후 저장할 수 있습니다.');
			return;
		}

		ProgressStore.activeProgress();
		let paramList = [];
		let length = window[this.sheetData.id].GetTotalRows();
		let item = {};
		for (var i = 0; i < length; i++) {
			// 자식행에 동일한 체크값 설정
			item.authCode = this.state.authCode;
			item.menuCode = window[this.sheetData.id].GetCellValue(i + 1, 'menuCode');
			item.chk = window[this.sheetData.id].GetCellValue(i + 1, 'chk');
			item.delYn = item.chk === '0' ? 'Y' : 'N';
			paramList.push(item);
			item = {};
		}

		saveAuth(paramList)
			.then((r) => {
				ModalStore.openModal('저장되었습니다.');

				// this.resetState();
				this.resetResult(); //ibsheet 데이터 리셋
				this.getMenuByAuthList(); //저장 성공 후 재조회
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mb30">
								<h2>권한관리</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.getMenuByAuthList}>
										<span>조회</span>
									</a>
									{/* <a className="btn01 add"><span>추가</span></a> */}
									{/* <a className="btn01 save"><span>저장</span></a> */}
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '15%' }} />
										<col style={{ width: '35%' }} />
										<col style={{ width: '15%' }} />
										<col style={{ width: '35%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>권한</th>
											<td>
												<select
													name="authCode"
													value={this.state.authCode}
													onChange={(e) => {
														this.handleChange(e);
														this.resetResult();
														this.setState({ menuList: [] });
													}}
												>
													{this.state.authCodeList ? buildSelectItems(this.state.authCodeList) : null}
												</select>
											</td>
											<th>트리제어</th>
											<td>
												<FormControl component="fieldset" style={{ margin: '-11px 0px' }}>
													{/* <FormLabel component="legend">Gender</FormLabel> */}
													<RadioGroup
														aria-label="treeControl"
														name="treeControl"
														value={this.state.treeControl}
														onChange={(e) => {
															this.handleChange(e);
															window[this.sheetData.id].ShowTreeLevel(e.target.value - 1);
														}}
														row
													>
														<FormControlLabel value="1" control={<Radio />} label="1" />
														<FormControlLabel value="2" control={<Radio />} label="2" />
														{/* <FormControlLabel value="3" control={<Radio />} label="3" /> */}
													</RadioGroup>
												</FormControl>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="writeType05 long">
								<div>
									<div
										className="scroll"
										onMouseOver={() => {
											document.body.style.overflow = 'hidden';
										}}
										onMouseOut={() => {
											document.body.style.overflow = 'auto';
										}}
									>
										<p className="headline">메뉴 리스트</p>
										<IBSheet {...this.sheetData} />
									</div>
								</div>
								<div className="btnRight2">
									<Button className="button" onClick={this.saveAuthByMenu} color="primary" variant="contained" size="small">
										<SaveIcon className="saveIcon" />
										<span className="text">저장</span>
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(AuthManager);
