import React, { Component } from 'react';
import { makeObservable, observable, action } from 'mobx';
import { observer } from 'mobx-react';

const IfrsPopupStore = observable({
	open: false,
	handleClose: null,

	arrItemCate: [], //제품카테고리 리스트

	deptCode: '04',
	itemType: '',

	openPopup(handleClose) {
		this.open = true;
		if (handleClose) this.handleClose = handleClose;
	},
	closePopup(item) {
		this.open = false;
		if (this.handleClose) {
			this.handleClose(item);
		}
		this.itemType = '';
	},
	setItemType(itemType) {
		this.itemType = itemType;
	},
	setArrItemCate(list) {
		this.arrItemCate = list;
	},
});

export default IfrsPopupStore;
