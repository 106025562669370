import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';

import {
	getApiReprRequestSendList,
	getApiReprRequestSendInfo,
	getApiReprRequestSendHistory,
	putApiReprRequestSend,
} from '../../service/admin/ApiReprRequestSendManagerService';
import { errorMsgCheck, resultToIbSheet, dateToFormat, ComIBSheetComboNull, SheetParamData4 } from '../../common/ComFunction';
import { getCisCommCode } from '../../service/CommonService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ProgressStore, CustSearchPopupStore, LargeInputNoPopupStore } = CommonStore;

class ApiReprRequestSendManager extends Component {
	constructor(props) {
		super(props);

		this.sheetData = {
			id: 'apiReprRequestSendManagerSheet', // sheet id
			initData: 'admin/Init-ApiReprRequestSendManager', // sheet data javascript filename
			width: '100%', // sheet width
			height: '300px', // sheet height
		};

		this.state = {
			// 검색조건
			custNm: '', // 고객명
			custNo: '', //고객번호
			contNo: '', // 계약번호
			custReqNo: '', // 고객요청번호
			statusCd: '', // 상태코드
			requestYn: '', // 청구전송여부

			// 조회 form - 수리비 청구 내역
			custNoForm: '',
			custNmForm: '',
			contNoForm: '',
			custReqNoForm: '',
			ordeNoForm: '',
			asstNoForm: '',
			requestYnForm: '',
			dmdAmt: '',
			dmdVat: '',

			// 조회 form - 수리비 전송 내역
			sendCustNo: '',
			sendCustNm: '',
			sendCustReqNo: '',
			sendContNo: '',
			sendOrdeNo: '',
			sendAsstNo: '',
			sendDmdAmt: '',
			sendDmdVat: '',
			sendDate: '',

			// FROM 보이기 여부
			formDisplay: '',

			openCustPop: false, // 고객검색팝업
			disabledBtn: false, // 청구상태전송 버튼 여부 체크
		};
	}

	componentDidMount = () => {
		ProgressStore.activeProgress();
		//공통코드 조회
		Promise.all([getCisCommCode('APIST')])
			.then((r) => {
				let [codeStatusCdData] = r;
				const timer = setInterval(() => {
					if (window[this.sheetData.id]) {
						ComIBSheetComboNull('0', window[this.sheetData.id], 'statusCd', codeStatusCdData, 'title', 'value', '', '');
						ProgressStore.deactiveProgress();
						clearInterval(timer);
					}
				}, 100);
			})
			.catch((err) => {
				errorMsgCheck(err);
			});
	};

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
	};

	customerSearch = () => {
		CustSearchPopupStore.openPopup('1', null, null, this.handleCustClose);
	};

	handleCustClose = (item) => {
		if (item === undefined) return;

		this.setState({
			custNo: item.custNo,
			custNm: item.custNm,
		});
	};

	handleTextArray = (text) => {
		if (text === '') return;
		this.setState({
			contNo: text,
		});
	};

	// sheet데이터 리셋
	resetResult = () => {
		// 전체 초기화
		window[this.sheetData.id].LoadSearchData(
			{
				data: [],
			},
			{
				Sync: 1,
			},
		);

		this.setState({
			requestYnForm: 'N',
			formDisplay: 'N',
		});
	};

	getApiRequestSendList = () => {
		if (this.state.statusCd == '' || this.state.statusCd == null) {
			ModalStore.openModal('상태를 입력해주세요');
			return;
		}

		// 반납입고완료(35)일 경우 청구전송 버튼 보이게 하기
		if (this.state.statusCd === '35') {
			// 계약번호 필수값은 제거
			// if (this.state.contNo == '' || this.state.contNo == null) {
			// 	ModalStore.openModal('계약번호를 입력해주세요');
			// 	return;
			// }

			this.setState({
				requestYnForm: 'N',
				formDisplay: 'N',
			});
		}

		let param = {
			custNo: this.state.custNo,
			contNo: this.state.contNo,
			custReqNo: this.state.custReqNo,
			statusCd: this.state.statusCd,
			requestYn: this.state.requestYn,
		};

		getApiReprRequestSendList(param)
			.then((r) => {
				window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	showInfo = () => {
		let data = window.getReprRequestData();
		if (this.state.statusCd === '37') {
			if (data.requestYn == 'N') {
				this.apiRequestSendInfo();
			} else {
				this.apiRequestSendHistory();
			}
		}
	};
	apiRequestSendInfo = () => {
		let data = window.getReprRequestData();

		if (window[this.sheetData.id].RowCount() > 0) {
			let param = {
				custNo: data.custNo,
				contNo: data.contNo,
			};

			if (data.custNo != undefined) {
				ProgressStore.activeProgress();

				getApiReprRequestSendInfo(param)
					.then((r) => {
						let obj = r[0];
						if (obj == null || obj == undefined) {
							ModalStore.openModal('해당건은 미청구 수리내역입니다.');

							this.setState({
								formDisplay: 'N',
							});

							ProgressStore.deactiveProgress();
							return;
						}
						this.setState({
							custNoForm: obj.custNo,
							custNmForm: obj.custNm,
							contNoForm: obj.contNo,
							dmdAmt: obj.dmdAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
							dmdVat: obj.dmdVat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
							ordeNoForm: data.ordeNo,
							custReqNoForm: data.custReqNo,
							asstNoForm: data.asstNo,
							requestYnForm: data.requestYn,
							formDisplay: 'Y',
						});

						ProgressStore.deactiveProgress();
					})
					.catch((err) => {
						errorMsgCheck(err, ProgressStore.deactiveProgress());
					});
			}
		}
	};

	apiRequestSendHistory = () => {
		let data = window.getReprRequestData();

		if (window[this.sheetData.id].RowCount() > 0) {
			let param = {
				custNo: data.custNo,
				contNo: data.contNo,
				custReqNo: data.custReqNo,
				asstNo: data.asstNo,
			};

			if (param.custNo !== undefined) {
				getApiReprRequestSendHistory(param)
					.then((r) => {
						let obj = r[0];

						this.setState({
							sendCustNm: data.custNm,
							sendCustReqNo: obj.custReqNo,
							sendContNo: obj.contNo,
							sendOrdeNo: obj.ordeNo,
							sendAsstNo: obj.asstNo,
							sendDmdAmt: obj.dmdAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
							sendDmdVat: obj.dmdVat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
							sendDate: dateToFormat(obj.sendDate),
							requestYnForm: data.requestYn,
						});
					})
					.catch((err) => {
						errorMsgCheck(err, ProgressStore.deactiveProgress());
					});
			}
		}
	};

	// 상태가 검사중(37)인 경우 청구전송
	apiRequestSend = () => {
		let param = {
			custNo: this.state.custNoForm,
			custReqNo: this.state.custReqNoForm,
			asstNo: this.state.asstNoForm,
			dmdType: '10',
			dmdAmt: this.state.dmdAmt,
			dmdVat: this.state.dmdVat,
			ordeNo: this.state.ordeNoForm,
			originStatusCd: '37',
			statusCd: '38',
			reqType: 'S',
			instUser: sessionStorage.userId,
		};

		ProgressStore.activeProgress();

		putApiReprRequestSend(param)
			.then((r) => {
				alert('청구완료되었습니다.');
				this.getApiRequestSendList();
				this.setState({
					formDisplay: 'N',
				});
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	// 상태가 입고완료(35)인 경우 청구전송
	apiMultiRequestSend = () => {
		let sheet = window[this.sheetData.id];
		let FindCheckedRow = sheet.FindCheckedRow('chk');
		let checkRows = FindCheckedRow.split('|');

		// 청구전송 누르자마자 안보이게 처리
		this.setState({
			disabledBtn: true,
		});

		if (FindCheckedRow) {
			// 전송여부 체크
			for (var idx = 0; idx < checkRows.length; idx++) {
				if (sheet.GetCellValue(checkRows[idx], 'requestYn') === 'Y') {
					ModalStore.openModal('이미 전송된 내역이 있습니다.');
					this.setState({
						disabledBtn: false,
					});
					return;
				}
			}

			let param = { statusCd: this.state.statusCd };

			let sheetParam = SheetParamData4(sheet, param);
			console.log(this.state.statusCd);
			console.log(sheetParam);

			if (sheetParam && sheetParam.data.length > 0) {
				putApiReprRequestSend(sheetParam)
					.then((r) => {
						console.log(r);
						ModalStore.openModal('청구완료 전송 등록했습니다.');
						this.setState({
							disabledBtn: false,
						});
					})
					.catch((err) => {
						errorMsgCheck(err, ProgressStore.deactiveProgress());
					})
					.finally(() => {
						ProgressStore.deactiveProgress();
						this.getApiRequestSendList();
					});
			}
		} else {
			ModalStore.openModal('선택된 건이 없습니다.');
			return;
		}
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mb30">
								<h2>API 수리비 청구 전송</h2>

								<div className="btnRight">
									<a className="btn01 search" onClick={this.getApiRequestSendList}>
										<span>조회</span>
									</a>
									<Button
										color="secondary"
										variant="contained"
										style={{ lineHeight: '18px', marginLeft: '10px' }}
										onClick={this.apiMultiRequestSend}
										disabled={this.state.disabledBtn}
									>
										<label style={{ fontSize: '13px' }}>청구상태전송</label>
									</Button>
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '13%' }} />
										<col style={{ width: '20%' }} />
										<col style={{ width: '13%' }} />
										<col style={{ width: '20%' }} />
										<col style={{ width: '13%' }} />
										<col style={{ width: '20%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>고객명</th>
											<td>
												<input type="text" readOnly={true} style={{ marginRight: 10, width: '80%' }} value={this.state.custNm} />
												<IconButton onClick={this.customerSearch} color="secondary" className="iconButton" style={{ display: sessionStorage.btnControl }}>
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
											<th>고객요청번호</th>
											<td>
												<input type="text" value={this.state.custReqNo} onChange={(e) => this.setState({ custReqNo: e.target.value })} />
											</td>
											<th>상태</th>
											<td>
												<select
													value={this.state.statusCd}
													onChange={(e) => {
														this.setState({ statusCd: e.target.value });
														this.resetResult();
													}}
												>
													<option value="">선택</option>
													<option value="35">반납입고 완료</option>
													<option value="36">자산수리관리 미청구건</option>
													<option value="37">자산수리관리 등록건</option>
												</select>
											</td>
										</tr>
										<tr>
											<th>계약번호</th>
											<td>
												<input
													type="text"
													value={this.state.contNo}
													style={{ marginRight: 10, width: '80%' }}
													onChange={(e) => this.setState({ contNo: e.target.value })}
												/>
												<IconButton onClick={() => LargeInputNoPopupStore.openPopup(this.handleTextArray)} color="secondary" className="iconButton">
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
											<th>전송여부</th>
											<td>
												<select value={this.state.requestYn} onChange={(e) => this.setState({ requestYn: e.target.value })}>
													<option value="">선택</option>
													<option value="Y">Y</option>
													<option value="N">N</option>
												</select>
											</td>
											<td></td>
											<td></td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="writeType03">
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
									onClick={() => {
										this.showInfo();
									}}
								>
									<IBSheet {...this.sheetData} />
								</div>
							</div>

							<div style={this.state.statusCd === '35' ? { display: 'block' } : { display: 'none' }}>
								<div>
									<span style={{ color: 'red' }}>
										* 반납입고완료(35) 이후 ERP 자산수리관리 등록대상이 아닌 경우 바로 청구완료(38) 상태 전송하여 고객사에서 해당 렌탈건의 종료를 알리는
										기능입니다.
									</span>
								</div>
								<div>
									<span style={{ color: 'red' }}>* 위약금이 발생하는 반납의 경우(14이내 반납) 청구완료(38) 상태가 전송된 이후 일배치로 청구가 생성됩니다.</span>
								</div>
								<div>
									<span style={{ color: 'red' }}>
										* 청구완료(38) 전송은 한번만 보낼 수 있습니다. 전송 전에 반드시 자산수리관리 등록 대상이 아닌지 확인 후 전송하시기 바랍니다.
									</span>
								</div>
							</div>

							<div style={this.state.statusCd === '37' ? { display: 'block' } : { display: 'none' }}>
								<div>
									<span style={{ color: 'red' }}>
										* 수리비 청구가 이미 존재하는 케이스입니다. 상태전송시 청구완료(38) 상태를 보내게 되며 이때 수리비 청구액을 청구완료(38) 상태 전송시
										포함하여 전송합니다.
									</span>
								</div>
								<div>
									<span style={{ color: 'red' }}>
										* 만일 자산수리관리에 한 자산에 대하여 여러건의 자산수리가 등록된 경우 각 건당 생성된 모든 수리비 청구를 합산하여 전송하며 이때 청구완료
										단계까지 도달하지 못한 자산수리관리 건이 존재하는 경우 해당 건을 삭제 또는 청구완료 단계로 만들어 청구를 생성한 후 전송 시도할 수 있습니다.
									</span>
								</div>
								<div>
									<span style={{ color: 'red' }}>* 한번만 보낼 수 있습니다. 전송 전에 반드시 확인 후 전송하시기 바랍니다.</span>
								</div>
							</div>

							<div
								style={
									this.state.formDisplay === 'Y'
										? this.state.requestYnForm === 'N'
											? { display: 'block', height: '400px' }
											: { display: 'none' }
										: { display: 'none' }
								}
							>
								<p className="headline">수리비 청구 내역</p>
								{/* <Button
									color="secondary"
									variant="contained"
									style={{ lineHeight: '18px', marginBottom: '10px', float: 'right' }}
									onClick={this.apiRequestSend}
								>
									<label style={{ fontSize: '13px' }}>상태전송</label>
								</Button> */}
								<div className="writeType03_noborder">
									<table>
										<colgroup>
											<col style={{ width: 90 }} />
											<col style={{ width: 120 }} />
											<col style={{ width: 100 }} />
											<col style={{ width: 180 }} />
										</colgroup>
										<tbody>
											<tr>
												<th>고객명</th>
												<td>
													<input type="text" readOnly={true} value={this.state.custNmForm} />
												</td>
												<th>고객요청번호</th>
												<td>
													<input type="text" readOnly={true} value={this.state.custReqNoForm} />
												</td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<th>계약번호</th>
												<td>
													<input type="text" readOnly={true} value={this.state.contNoForm} />
												</td>
												<th>반납요청번호</th>
												<td>
													<input type="text" readOnly={true} value={this.state.ordeNoForm} />
												</td>
												<th>자산번호</th>
												<td>
													<input type="text" readOnly={true} value={this.state.asstNoForm} />
												</td>
											</tr>
											<tr>
												<th>청구액</th>
												<td>
													<input type="text" readOnly={true} value={this.state.dmdAmt} />
												</td>
												<th>부가세</th>
												<td>
													<input type="text" readOnly={true} value={this.state.dmdVat} />
												</td>
												<td></td>
												<td></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>

							<div style={this.state.requestYnForm === 'Y' ? { display: 'block', height: '400px' } : { display: 'none' }}>
								<p className="headline">수리비 청구 전송내역</p>
								<div className="writeType03_noborder">
									<table>
										<colgroup>
											<col style={{ width: 90 }} />
											<col style={{ width: 120 }} />
											<col style={{ width: 100 }} />
											<col style={{ width: 180 }} />
										</colgroup>
										<tbody>
											<tr>
												<th>고객명</th>
												<td>
													<input type="text" readOnly={true} value={this.state.sendCustNm} />
												</td>
												<th>고객요청번호</th>
												<td>
													<input type="text" readOnly={true} value={this.state.sendCustReqNo} />
												</td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<th>계약번호</th>
												<td>
													<input type="text" readOnly={true} value={this.state.sendContNo} />
												</td>
												<th>반납요청번호</th>
												<td>
													<input type="text" readOnly={true} value={this.state.sendOrdeNo} />
												</td>
												<th>자산번호</th>
												<td>
													<input type="text" readOnly={true} value={this.state.sendAsstNo} />
												</td>
											</tr>
											<tr>
												<th>보낸 청구액</th>
												<td>
													<input type="text" readOnly={true} value={this.state.sendDmdAmt} />
												</td>
												<th>보낸 부가세</th>
												<td>
													<input type="text" readOnly={true} value={this.state.sendDmdVat} />
												</td>
												<td></td>
												<td></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(ApiReprRequestSendManager);
