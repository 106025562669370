import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';
import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';

//날짜
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import { getCisCommCode, getRentalCustContType } from '../../service/CommonService';
import { isEmpty, resultToIbSheet, errorMsgCheck, managerCheck } from '../../common/ComFunction';
import { getInfoList, saveCategoryInfo } from '../../service/admin/MobileCouponService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore } = CommonStore;

class MallMoCpCategory extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sUserChgId: sessionStorage.userId,
			sUserChgNm: sessionStorage.userName,

			excelList: [], //엑셀다운로드용 리스트

			firstLoad: true,
		};

		this.sheetData = {
			id: 'compSheet',
			initData: 'admin/Init-MoCpCompany',
			width: '100%',
			height: '250px',
			// ibsheet row click 이벤트 추가함
			onClick: (row, col) => {
				this.getCategoryList('cateSheet1', compSheet.GetRowStatus(row), compSheet.GetCellValue(row, 'code'));
			},
		};

		this.sheetData1 = {
			id: 'cateSheet1',
			initData: 'admin/Init-MoCpCate1',
			width: '100%',
			height: '250px',
			// ibsheet row click 이벤트 추가함
			onClick: (row, col) => {
				this.getCategoryList('cateSheet2', cateSheet1.GetRowStatus(row), cateSheet1.GetCellValue(row, 'code'));
			},
		};

		this.sheetData2 = {
			id: 'cateSheet2',
			initData: 'admin/Init-MoCpCate2',
			width: '100%',
			height: '250px',
			// ibsheet row click 이벤트 추가함
			onClick: (row, col) => {
				this.getCategoryList('cateSheet3', cateSheet2.GetRowStatus(row), cateSheet2.GetCellValue(row, 'code'));
			},
		};

		this.sheetData3 = {
			id: 'cateSheet3',
			initData: 'admin/Init-MoCpCate3',
			width: '100%',
			height: '250px',
		};
	}

	getCategoryList = (sheetid, rowStatus, upperCode) => {
		var nextSheetid = null;
		if (sheetid == 'compSheet') {
			upperCode = null;
			this.state.firstLoad = false;
			compSheet.RemoveAll();
			cateSheet1.RemoveAll();
			cateSheet2.RemoveAll();
			cateSheet3.RemoveAll();
			nextSheetid = 'cateSheet1';
		} else if (sheetid == 'cateSheet1') {
			cateSheet1.RemoveAll();
			cateSheet2.RemoveAll();
			cateSheet3.RemoveAll();
			nextSheetid = 'cateSheet2';
		} else if (sheetid == 'cateSheet2') {
			cateSheet2.RemoveAll();
			cateSheet3.RemoveAll();
			nextSheetid = 'cateSheet3';
		}

		// 수정중이 아닌 row가 선택됐을때만 작동
		if (rowStatus != 'R') {
			return;
		}

		let param = {
			upper_code: upperCode,
			admin: 'Y',
		};

		ProgressStore.activeProgress();
		getInfoList(param)
			.then((r) => {
				window[sheetid].LoadSearchData(resultToIbSheet(r));
				// 로드 후 row 선택 안돼있도록
				setTimeout(function () {
					window[sheetid].SetSelectRow(-1);
				}, 200);
			})
			.catch((err) => {
				errorMsgCheck(err, () => ProgressStore.deactiveProgress());
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	addCategoryRow = (sheetid) => {
		if (this.state.firstLoad) {
			ModalStore.openModal('조회를 먼저 해주세요.');
			return;
		}

		var selectRow = 0;
		if (sheetid == 'cateSheet1') {
			selectRow = compSheet.GetSelectRow();
		} else if (sheetid == 'cateSheet2') {
			selectRow = cateSheet1.GetSelectRow();
		} else if (sheetid == 'cateSheet3') {
			selectRow = cateSheet2.GetSelectRow();
		}

		if (selectRow == -1) {
			ModalStore.openModal('상위 분류를 선택해주세요.');
			return;
		}

		if (sheetid == 'compSheet') {
			cateSheet1.RemoveAll();
			cateSheet2.RemoveAll();
			cateSheet3.RemoveAll();
		} else if (sheetid == 'cateSheet1') {
			cateSheet2.RemoveAll();
			cateSheet3.RemoveAll();
		} else if (sheetid == 'cateSheet2') {
			cateSheet3.RemoveAll();
		}
		window[sheetid].DataInsert(-1);
	};

	saveCategory = (sheetid) => {
		if (this.state.firstLoad) {
			ModalStore.openModal('조회를 먼저 해주세요.');
			return;
		}

		var selectRow = 0;
		var upperCode = null;
		if (sheetid == 'cateSheet1') {
			selectRow = compSheet.GetSelectRow();
			upperCode = compSheet.GetCellValue(compSheet.GetSelectRow(), 'code');
		} else if (sheetid == 'cateSheet2') {
			selectRow = cateSheet1.GetSelectRow();
			upperCode = cateSheet1.GetCellValue(cateSheet1.GetSelectRow(), 'code');
		} else if (sheetid == 'cateSheet3') {
			selectRow = cateSheet2.GetSelectRow();
			upperCode = cateSheet2.GetCellValue(cateSheet2.GetSelectRow(), 'code');
		}

		if (selectRow == -1) {
			ModalStore.openModal('상위 분류를 선택해주세요.');
			return;
		}

		// 추가 및 변경된 row가 있는지 확인
		var editRows = [];
		var isEmptyRow = -1;
		if (window[sheetid].RowCount() > 0) {
			for (var i = 1; i <= window[sheetid].RowCount() + 1; i++) {
				var status = window[sheetid].GetRowStatus(i);
				if (status == 'I' || status == 'U' || status == 'D') {
					var json = window[sheetid].GetRowData(i);
					json['upperCode'] = upperCode;
					var newCode = json['newCode'];
					var codeName = json['codeName'];
					var usexYorn = json['usexYorn'];
					if (isEmpty(newCode)) {
						isEmptyRow = i;
					} else if (isEmpty(codeName)) {
						isEmptyRow = i;
					} else if (isEmpty(usexYorn)) {
						isEmptyRow = i;
					} else if (sheetid == 'cateSheet3' && isEmpty(json['goodsId'])) {
						isEmptyRow = i;
					}
					editRows.push(json);
				}
			}
		}

		if (editRows.length == 0) {
			ModalStore.openModal('추가나 수정된 데이터가 없습니다.');
			return;
		} else if (isEmptyRow != -1) {
			ModalStore.openModal(isEmptyRow + '행에 입력되지 않은 값이 있습니다.');
			return;
		}

		ConfirmModalStore.openModal('저장하시겠습니까?', () => {
			saveCategoryInfo({ jsonData: JSON.stringify(editRows) })
				.then((r) => {
					ProgressStore.activeProgress();
					if (r[0].failCnt != 0) {
						ModalStore.openModal('중복된 코드 ' + r[0].failCnt + '건을 제외하고 저장되었습니다.');
					} else {
						ModalStore.openModal('저장되었습니다.');
					}
					this.getCategoryList(sheetid, 'R', upperCode);
				})
				.catch((err) => {
					errorMsgCheck(err, () => ProgressStore.deactiveProgress());
				});
		});
	};

	componentDidMount = () => {
		managerCheck(); // 관리자체크
		Promise.all([getCisCommCode('04'), getCisCommCode('11')]).then((r) => {
			let [code04, code11] = r;
			this.setState({
				arrDelMothd: code04,
				arrOrderStatus: code11,
			});
		});
	};

	componentWillUnmount = () => {
		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}
		if (window[this.sheetData1.id]) {
			window[this.sheetData1.id].DisposeSheet();
		}
		if (window[this.sheetData2.id]) {
			window[this.sheetData2.id].DisposeSheet();
		}
		if (window[this.sheetData3.id]) {
			window[this.sheetData3.id].DisposeSheet();
		}
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont" style={{ height: 1350 }}>
							<div className="h2Wrap mb30">
								<h2>모바일 쿠폰 상품 정보 조회</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.getCategoryList.bind(this, 'compSheet', 'R')}>
										<span>조회</span>
									</a>
								</div>
							</div>
							<div className="writeType03" style={{ float: 'left', width: '49%' }}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<span className="headline">쿠폰발급업체</span>
									<select id="selectCateCode1" style={{ display: 'none' }}></select>
									<div style={{ float: 'right' }}>
										<Button className="button" onClick={this.addCategoryRow.bind(this, 'compSheet')} color="primary" variant="contained" size="small">
											<AddIcon className="addIcon" />
											<span className="text">추가</span>
										</Button>
										&nbsp;
										<Button className="button" onClick={this.saveCategory.bind(this, 'compSheet')} color="primary" variant="contained" size="small">
											<SaveIcon className="saveIcon" />
											<span className="text">저장</span>
										</Button>
									</div>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
							<div className="writeType03" style={{ float: 'right', width: '50%' }}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<span className="headline">대분류</span>
									<div style={{ float: 'right' }}>
										<Button className="button" onClick={this.addCategoryRow.bind(this, 'cateSheet1')} color="primary" variant="contained" size="small">
											<AddIcon className="addIcon" />
											<span className="text">추가</span>
										</Button>
										&nbsp;
										<Button className="button" onClick={this.saveCategory.bind(this, 'cateSheet1')} color="primary" variant="contained" size="small">
											<SaveIcon className="saveIcon" />
											<span className="text">저장</span>
										</Button>
									</div>
									<IBSheet {...this.sheetData1} />
								</div>
							</div>
							<div className="writeType03" style={{ float: 'left', width: '49%' }}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<span className="headline">중분류</span>
									<div style={{ float: 'right' }}>
										<Button className="button" onClick={this.addCategoryRow.bind(this, 'cateSheet2')} color="primary" variant="contained" size="small">
											<AddIcon className="addIcon" />
											<span className="text">추가</span>
										</Button>
										&nbsp;
										<Button className="button" onClick={this.saveCategory.bind(this, 'cateSheet2')} color="primary" variant="contained" size="small">
											<SaveIcon className="saveIcon" />
											<span className="text">저장</span>
										</Button>
									</div>
									<IBSheet {...this.sheetData2} />
								</div>
							</div>
							<div className="writeType03" style={{ float: 'right', width: '50%' }}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<span className="headline">소분류</span>
									<div style={{ float: 'right' }}>
										<Button className="button" onClick={this.addCategoryRow.bind(this, 'cateSheet3')} color="primary" variant="contained" size="small">
											<AddIcon className="addIcon" />
											<span className="text">추가</span>
										</Button>
										&nbsp;
										<Button className="button" onClick={this.saveCategory.bind(this, 'cateSheet3')} color="primary" variant="contained" size="small">
											<SaveIcon className="saveIcon" />
											<span className="text">저장</span>
										</Button>
									</div>
									<IBSheet {...this.sheetData3} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(MallMoCpCategory);
