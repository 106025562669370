import { httpGet, httpPost, httpPut, toQueryString } from '../Service';

// api 수기관리 리스트
export function getApiHandList(data) {
	return httpGet('/api/v1.0/cis/api/apiHandList' + toQueryString(data));
}
// 출고취소여부
export function getApiDelCheck(data) {
	return httpGet('/api/v1.0/cis/api/apiDelCheck' + toQueryString(data));
}
// 계약정리여부
export function getApiContCheck(data) {
	return httpGet('/api/v1.0/cis/api/apiContCheck' + toQueryString(data));
}
// 처리완료
export function putApiProc(data) {
	data = data || {};
	return httpPut('/api/v1.0/cis/api/apiProcUpdate/', { body: data });
}
// 반납신청번복 체크
export function getReturnBackCheck(data) {
	return httpGet('/api/v1.0/cis/api/returnBackCheck' + toQueryString(data));
}
