import React, { Component } from 'react';
import IBSheet from '../IBSheet';

import Dialog from '@material-ui/core/Dialog';
import { Transition, errorMsgCheck } from '../../common/ComFunction';

import { getAssetHistory } from '../../service/rental/RentalAssetService';
import { resultToIbSheet } from '../../common/ComFunction';
import { CUST_NO_HC, CUST_NO_HCL, CUST_NO_BONIF, CUST_NO_BONIF_YPC } from '../../common/ComValues';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ProgressStore, AssetHistoryPopStore } = CommonStore;

class AssetHistoryPop extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sheetChg: false,
		};

		this.sheetData = {
			id: 'assetHistorySheet', // sheet id
			initData: 'rental/Init-AssetHistory', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '400px', // sheet height
		};

		this.sheetData2 = {
			id: 'assetHistoryMpcSheet', // sheet id
			initData: 'rental/Init-AssetHistoryMpc', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '400px', // sheet height
		};
	}

	componentWillUnmount = () => {
		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}
		if (window[this.sheetData2.id]) {
			window[this.sheetData2.id].DisposeSheet();
		}
	};

	componentDidUpdate = () => {
		if (!AssetHistoryPopStore.open) return;

		let param = {
			asstNo: AssetHistoryPopStore.asstNo,
			custNo: AssetHistoryPopStore.custNo,
		};
		let customData = JSON.parse(sessionStorage.getItem('customData'));

		ProgressStore.activeProgress();
		getAssetHistory(param)
			.then((r) => {
				//랜더링 속도보다 느려서 타임아웃시전
				if (
					// AssetHistoryPopStore.custNo === CUST_NO_HC ||
					// AssetHistoryPopStore.custNo === CUST_NO_HCL ||
					// AssetHistoryPopStore.custNo === CUST_NO_BONIF ||
					// AssetHistoryPopStore.custNo === CUST_NO_BONIF_YPC
					customData != null &&
					customData.hasOwnProperty('CT031')
				) {
					var sheetIntvl = setInterval(() => {
						if (window[this.sheetData2.id] !== undefined) {
							window[this.sheetData2.id].LoadSearchData(resultToIbSheet(r));
							this.setState({
								sheetChg: true,
							});
							clearInterval(sheetIntvl);
						}
					}, 100);
				} else {
					var sheetIntvl2 = setInterval(() => {
						if (window[this.sheetData.id] !== undefined) {
							window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
							clearInterval(sheetIntvl2);
						}
					}, 100);
				}
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	popClose = () => {
		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}
		if (window[this.sheetData2.id]) {
			window[this.sheetData2.id].DisposeSheet();
		}
		AssetHistoryPopStore.closePopup();
	};

	render() {
		const { open, custNo } = AssetHistoryPopStore;
		let sheet;

		if (this.state.sheetChg) {
			sheet = <IBSheet {...this.sheetData2} />;
		} else {
			sheet = <IBSheet {...this.sheetData} />;
		}

		return (
			<div>
				<Dialog open={open} TransitionComponent={Transition} onClose={this.popClose}>
					<div className="popSmArea searchPop" style={{ transform: 'translate(-18%, 0)' }}>
						<div className="popCont" style={{ height: 520, width: 1300, overflow: 'hidden' }}>
							<div className="h2Wrap">
								<h2>자산변경내역</h2>
							</div>
							<div className="writeType04">{sheet}</div>
							<div className="btnClose" onClick={this.popClose}>
								<a>팝업닫기</a>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}
}
export default observer(AssetHistoryPop);
