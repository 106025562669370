import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';

import { errorMsgCheck, resultToIbSheet, managerCheck, buildSelectItems, SheetParamData3, ComIBSheetComboNull } from '../../common/ComFunction';
import { getAppBuildList, saveAppBuild } from '../../service/admin/AppManageService';
import { getCisCommCode } from '../../service/CommonService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore } = CommonStore;

class AppBuildManager extends Component {
	constructor(props) {
		super(props);

		this.state = {
			disabledBtn: true,
			appKind: '', //앱종류
			appPlatform: '', //앱플랫폼
			appVer: '', //앱버전
			useYn: '', //사용여부
			arrAppKind: [],
			arrAppPlatform: [],
		};

		this.sheetData = {
			id: 'appBuildSheet', // sheet id
			initData: 'admin/Init-AppBuildManager', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '300px', // sheet height
		};
	}

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
	};

	componentDidMount = () => {
		managerCheck(); // 관리자체크
		this.getList();

		Promise.all([getCisCommCode('APK'), getCisCommCode('APP')]).then((r) => {
			let [codeAPK, codeAPP] = r;
			this.setState(
				{
					arrAppKind: codeAPK,
					arrAppPlatform: codeAPP,
				},
				() => {
					const timer = setInterval(() => {
						if (window[this.sheetData.id]) {
							ComIBSheetComboNull('0', window[this.sheetData.id], 'appName', codeAPK, 'title', 'value', '', '');
							ComIBSheetComboNull('0', window[this.sheetData.id], 'platform', codeAPP, 'title', 'value', '', '');
							clearInterval(timer);
						}
					}, 100);
				},
			);
		});
	};

	getList = () => {
		ProgressStore.activeProgress();
		let param = {
			appKind: this.state.appKind,
			appPlatform: this.state.appPlatform,
			appVer: this.state.appVer,
			useYn: this.state.useYn,
		};
		getAppBuildList(param)
			.then((r) => {
				window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	addSheet = () => {
		let row = window.addAppBuildSheet();

		this.setState({
			row: row,
		});
	};

	saveCheck = () => {
		ConfirmModalStore.openModal('저장 하시겠습니까?', this.save);
	};

	save = () => {
		var sheet = window[this.sheetData.id];

		ProgressStore.activeProgress();
		let sheetParam = SheetParamData3(sheet, null, true);
		if (sheetParam && sheetParam.data.length > 0 && sheetParam.Message != 'KeyFieldError') {
			saveAppBuild(sheetParam)
				.then(() => {
					ModalStore.openModal('저장 되었습니다.');
					this.getList();
					ProgressStore.deactiveProgress();
				})
				.catch((err) => {
					errorMsgCheck(err, ProgressStore.deactiveProgress());
				});
		} else {
			ProgressStore.deactiveProgress();
		}
	};

	resetState = () => {
		// 전체 초기화
		window[this.sheetData.id].LoadSearchData(
			{
				data: [],
			},
			{
				Sync: 1,
			},
		);
		this.setState(
			{
				loadingData: false,
				disabledBtn: true,
			},
			() => {
				this.getList();
			},
		);
	};

	handleChangeSearch = (e) => {
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
		});
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mb30">
								<h2>앱빌드관리</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.getList}>
										<span>조회</span>
									</a>
									<a className="btn03 add" onClick={this.addSheet}>
										<span>추가</span>
									</a>
									<a className="btn03 save" onClick={this.saveCheck}>
										<span>저장</span>
									</a>
								</div>
							</div>
							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '10%' }} />
										<col style={{ width: '15%' }} />
										<col style={{ width: '10%' }} />
										<col style={{ width: '15%' }} />
										<col style={{ width: '10%' }} />
										<col style={{ width: '15%' }} />
										<col style={{ width: '10%' }} />
										<col style={{ width: '15%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>앱</th>
											<td>
												<select
													name="appKind"
													value={this.state.appKind || ''}
													onChange={(e) => {
														this.handleChangeSearch(e);
														this.resetState();
													}}
													ref={(ref) => (this.appKind = ref)}
												>
													{this.state.arrAppKind ? buildSelectItems([{ title: '전체', value: '' }].concat(this.state.arrAppKind)) : null}
												</select>
											</td>
											<th>플랫폼</th>
											<td>
												<select
													name="appPlatform"
													value={this.state.appPlatform || ''}
													onChange={(e) => {
														this.handleChangeSearch(e);
														this.resetState();
													}}
													ref={(ref) => (this.appPlatform = ref)}
												>
													{this.state.arrAppPlatform ? buildSelectItems([{ title: '전체', value: '' }].concat(this.state.arrAppPlatform)) : null}
												</select>
											</td>
											<th>앱버전</th>
											<td>
												<input
													type="text"
													ref={(ref) => (this.appVer = ref)}
													name="appVer"
													value={this.state.appVer}
													onKeyDown={(e) => {
														if (e.key == 'Enter') this.getList();
													}}
													onChange={this.handleChangeSearch}
												/>
											</td>
											<th>사용여부</th>
											<td>
												<select
													name="useYn"
													value={this.state.useYn || ''}
													onChange={(e) => {
														this.handleChangeSearch(e);
														this.resetState();
													}}
													ref={(ref) => (this.useYn = ref)}
												>
													<option value="">전체</option>
													<option value="N">N</option>
													<option value="Y">Y</option>
												</select>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="writeType03">
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">리스트</p>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(AppBuildManager);
