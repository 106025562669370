import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import SwapHoriz from '@material-ui/icons/SwapHoriz';
import SaveIcon from '@material-ui/icons/Save';
import DatePicker from 'react-datepicker';
import { apiMisreturnList, misreturnExchangeProc } from '../../service/admin/ApiMisreturnManagerService';
import { errorMsgCheck, resultToIbSheet, dateToFormat } from '../../common/ComFunction';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
import { red } from '@material-ui/core/colors';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore, ApiMisreturnSearchPopupStore,  } = CommonStore;

class ApiMisreturnManager extends Component {
	constructor(props) {
		super(props);

		this.sheet = null;
		this.sheetData = {
			id: 'apiMisreturnManagerSheet', //sheet id
			initData: 'admin/Init-ApiMisreturnManager',
			width: '100%',
			height: '300px',
			sheetRendered: (sheet) => {
				this.sheet = sheet;
			},
		};
	}

	state = {
		misreturnList: [],
		searchReqSDate: null,
		searchReqEDate: null,
		searchStoSDate: null,
		searchStoEDate: null,
		searchContNo: '',
		searchAsstNo: '',
		isChangedCont: false,
		custNo: '',

		misrtnTarget: {
			custReqNo: null,
			contNo: null,
			ordeNo: null,
			asstNo: null,
			statusCd: null,
			chgCustReqNo: null,
			chgContNo: null,
			chgAsstNo: null,
			chgStatusCd: null,
			
		},
		chgTarget: {
			custReqNo: null,
			contNo: null,
			ordeNo: null,
			asstNo: null,
			statusCd: null,
			chgCustReqNo: null,
			chgContNo: null,
			chgCsstNo: null,
			chgCtate: null,
		}
	};

	componentDidMount = () => {
		// sheet 이벤트 등록
		window[`${this.sheetData.id}_OnDblClick`] = (row, col) => {
			if (row > 0) {
				this.setState({
					custNo: this.sheet.GetCellValue(row, 'custNo'),
					misrtnTarget: {
						custReqNo: this.sheet.GetCellValue(row, 'custReqNo'),
						contNo: this.sheet.GetCellValue(row, 'contNo'),
						ordeNo: this.sheet.GetCellValue(row, 'ordeNo'),
						asstNo: this.sheet.GetCellValue(row, 'asstNo'),
						statusCd: this.sheet.GetCellValue(row, 'statusCd'),
					}
				});
			}
			this.setState({
				isChangedCont: false,
				chgTarget: {
					custReqNo: null,
					contNo: null,
					ordeNo: null,
					asstNo: null,
					statusCd: null,
					chgCustReqNo: null,
					chgContNo: null,
					chgCsstNo: null,
					chgCtate: null,
				}
			});
		};
	};

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
	};

	dateHandler = (date, type) => {
		if (type === 'rs') {
			this.setState({ searchReqSDate: date });
		} else if (type === 're') {
			this.setState({ searchReqEDate: date });
		} else if (type === 'ss') {
			this.setState({ searchStoSDate: date });
		} else if (type === 'se') {
			this.setState({ searchStoEDate: date });
		}
	};

	customerSearch = () => {
		CustSearchPopupStore.openPopup('1', null, null, this.handleCustClose);
	};

	changeTargetSearch = () => {
		if(this.state.misrtnTarget.custReqNo == null) {
			ModalStore.openModal('오반납대상을 먼저 선택해 주세요.');
			return;
		}
		ApiMisreturnSearchPopupStore.openPopup(this.handlechangeTargetClose);
	};

	handleCustClose = (item) => {
		if (item === undefined) return;

		this.setState({
			custNo: item.custNo,
			custNm: item.custNm,
		});
	};

	handlechangeTargetClose = (item) => {
		if (item === undefined) return;

		this.setState({
			isChangedCont: false,
			chgTarget: {
				custReqNo: item.custReqNo,
				contNo: item.contNo,
				asstNo: item.asstNo,
				statusCd: item.statusCd,
				ordeNo: item.ordeNo,
				chgCustReqNo: null,
				chgContNo: null,
				chgAsstNo: null,
				chgStatusCd: null,
			},
			misrtnTarget: {
				...this.state.misrtnTarget,
				chgCustReqNo: null,
				chgContNo: null,
				chgAsstNo: null,
				chgStatusCd: null,
			},
		});
	};

	changeAsst = () => {
		if(this.state.misrtnTarget.custReqNo == null || this.state.chgTarget.custReqNo == null) {
			ModalStore.openModal('오반납대상과 교체할대상을 먼저 선택해 주세요.');
			return;
		}
		if(this.state.misrtnTarget.contNo == this.state.chgTarget.contNo) {
			ModalStore.openModal('서로 다른 계약을 선택해 주세요.');
			return;
		}

		this.setState({
			isChangedCont: true,
			misrtnTarget: {
				...this.state.misrtnTarget,
				chgCustReqNo: this.state.misrtnTarget.custReqNo,
				chgContNo: this.state.chgTarget.contNo,
				chgAsstNo: this.state.chgTarget.asstNo,
				chgStatusCd: this.state.misrtnTarget.statusCd,
			},
			chgTarget: {
				...this.state.chgTarget,
				chgCustReqNo: this.state.chgTarget.custReqNo,
				chgContNo: this.state.misrtnTarget.contNo,
				chgAsstNo: this.state.misrtnTarget.asstNo,
				chgStatusCd: this.state.chgTarget.statusCd,
			}
		});

	};

	

	misreturnList = () => {
		ProgressStore.activeProgress();

		let param = {
			contNo: this.state.searchContNo,
			asstNo: this.state.searchAsstNo,
			reqSDate: dateToFormat(this.state.searchReqSDate).substring(0, 10),
			reqEDate: dateToFormat(this.state.searchReqEDate).substring(0, 10),
			stoSDate: dateToFormat(this.state.searchStoSDate).substring(0, 10),
			stoEDate: dateToFormat(this.state.searchStoEDate).substring(0, 10),
		};

		apiMisreturnList(param)
			.then((r) => {
				this.setState({ misreturnList: r }, () => {
					window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				});
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};
	reset = () => {
		this.setState({
			isChangedCont: false,
			custNo: '',
			misrtnTarget: {
				custReqNo: null,
				contNo: null,
				ordeNo: null,
				asstNo: null,
				statusCd: null,
				chgCustReqNo: null,
				chgContNo: null,
				chgAsstNo: null,
				chgStatusCd: null,
				
			},
			chgTarget: {
				custReqNo: null,
				contNo: null,
				ordeNo: null,
				asstNo: null,
				statusCd: null,
				chgCustReqNo: null,
				chgContNo: null,
				chgCsstNo: null,
				chgCtate: null,
			}
		});
		this.misreturnList();
	}

	save = () => {
		if(this.state.misrtnTarget.chgCustReqNo == null || this.state.chgTarget.chgCustReqNo == null || !this.state.isChangedCont) {
			ModalStore.openModal('먼저 교체 버튼을 눌러 확인해 주세요.');
			return;
		}
		if(this.state.misrtnTarget.contNo == this.state.chgTarget.contNo) {
			ModalStore.openModal('서로 다른 계약을 선택해 주세요.');
			return;
		}
		ConfirmModalStore.openModal('두 계약을 교체 하시겠습니까?', () => {
			ProgressStore.activeProgress();
			let params = {
				"misrtnContNo": this.state.misrtnTarget.contNo
				,"misrtnCustReqNo": this.state.misrtnTarget.custReqNo
				,"misrtnAsstNo": this.state.misrtnTarget.asstNo
				,"misrtnOrdeNo": this.state.misrtnTarget.ordeNo
				,"chgTrgtContNo": this.state.chgTarget.contNo
				,"chgTrgtCustReqNo": this.state.chgTarget.custReqNo
				,"chgTrgtAsstNo": this.state.chgTarget.asstNo
				,"chgTrgtOrdeNo": this.state.chgTarget.ordeNo
				,"custNo": this.state.custNo
			}
			misreturnExchangeProc(params)
				.then((r) => {
					console.log(r);
					ModalStore.openModal('저장되었습니다.', this.reset);
				})
				.catch((err) => {
					errorMsgCheck(err);
				})
				.finally(() => {
					ProgressStore.deactiveProgress();
				});
		});
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mm30">
								<h2>오반납 관리</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.misreturnList}>
										<span>조회</span>
									</a>
								</div>
							</div>
							<div style={{fontSize: '12px', textAlign: 'left', color: 'red'}}>
							※오반납 의심목록에서 더블클릭으로 오반납 대상을 선택하고 팝업에서 교체대상을 선택하신 뒤 중앙의 교체 아이콘을 클릭하면 저장이 가능합니다.
							</div>

							<div className="writeType03">
								<table>
									<tbody>
										<tr>
											<th>요청일자</th>
											<td colSpan={3}>
												<DatePicker
													customInput={<input type="text" style={{ width: 115 }} />}
													dateFormat="YYYY-MM-DD"
													selectsStart
													selected={this.state.searchReqSDate}
													onChange={(date) => this.dateHandler(date, 'rs')}
													startDate={this.state.searchReqSDate}
													endDate={this.state.searchReqEDate}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
												<span style={{ marginLeft: 5, marginRight: 5 }}>~</span>
												<DatePicker
													customInput={<input type="text" style={{ width: 115 }} />}
													dateFormat="YYYY-MM-DD"
													selectsEnd
													selected={this.state.searchReqEDate}
													onChange={(date) => this.dateHandler(date, 're')}
													startDate={this.state.searchReqSDate}
													endDate={this.state.searchReqEDate}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
											</td>
											<th>입고일자</th>
											<td colSpan={3}>
												<DatePicker
													customInput={<input type="text" style={{ width: 115 }} />}
													dateFormat="YYYY-MM-DD"
													selectsStart
													selected={this.state.searchStoSDate}
													onChange={(date) => this.dateHandler(date, 'ss')}
													startDate={this.state.searchStoSDate}
													endDate={this.state.searchStoEDate}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
												<span style={{ marginLeft: 5, marginRight: 5 }}>~</span>
												<DatePicker
													customInput={<input type="text" style={{ width: 115 }} />}
													dateFormat="YYYY-MM-DD"
													selectsEnd
													selected={this.state.searchStoEDate}
													onChange={(date) => this.dateHandler(date, 'se')}
													startDate={this.state.searchStoSDate}
													endDate={this.state.searchStoEDate}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
											</td>
										</tr>
										<tr>
											<th style={{ width: '10%' }}>계약번호</th>
											<td colSpan={3}>
												<input type="text" style={{width:'100px'}} value={this.state.searchContNo} onChange={(e) => this.setState({ searchContNo: e.target.value })} />
											</td>
											<th style={{ width: '10%' }}>자산번호</th>
											<td colSpan={3}>
												<input type="text" style={{width:'100px'}} value={this.state.searchAsstNo} onChange={(e) => this.setState({ searchAsstNo: e.target.value })} />
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="writeType03">
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline" style={{display: 'inline'}}>오반납 의심목록</p>
									<span style={{fontSize: '13px'}}>(반납요청이 없는데 반납이 된 자산기준 목록)</span>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
							<p className="headline" style={{display: 'inline'}}>오반납 계약교체</p>
							<span style={{fontSize: '13px'}}>(잘못 반납된 자산을 실제 반납 요청한 계약으로 교체)</span>
							<p></p>
							<div className="writeType03" style={{ float: 'left', width: '45%' }}>
								<table>
									<colgroup>
										<col style={{ width: '18%' }} />
										<col style={{ width: '32%' }} />
										<col style={{ width: '18%' }} />
										<col style={{ width: '32%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>오반납 대상</th>
											<td colSpan={3}>
												{this.state.misrtnTarget.contNo}
											</td>
										</tr>
										<tr>
											<th>요청번호</th>
											<td>{this.state.misrtnTarget.custReqNo}</td>
											<th>계약번호</th>
											<td>{this.state.misrtnTarget.contNo}</td>
										</tr>
										<tr>
											<th>자산번호</th>
											<td>{this.state.misrtnTarget.asstNo}</td>
											<th>상태</th>
											<td>{this.state.misrtnTarget.statusCd}</td>
										</tr>
									</tbody>
								</table>
								수정
								<table>
									<colgroup>
										<col style={{ width: '18%' }} />
										<col style={{ width: '32%' }} />
										<col style={{ width: '18%' }} />
										<col style={{ width: '32%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>요청번호</th>
											<td>{this.state.misrtnTarget.chgCustReqNo}</td>
											<th>계약번호</th>
											<td><span style={{color: '#ff5454'}}>{this.state.misrtnTarget.chgContNo}</span></td>
										</tr>
										<tr>
											<th>자산번호</th>
											<td><span style={{color: '#ff5454'}}>{this.state.misrtnTarget.chgAsstNo}</span></td>
											<th>상태</th>
											<td>{this.state.misrtnTarget.chgStatusCd}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="writeType03" style={{  paddingTop: '100px', float: 'left', width: '10%', textAlign: 'center' }}>
								<IconButton onClick={this.changeAsst} className="iconButton" style={{backgroundColor: this.state.isChangedCont ? '#ff5454' : ''}}>
									<SwapHoriz fontSize="large"></SwapHoriz>
								</IconButton>
							</div>
							<div className="writeType03" style={{ float: 'right', width: '45%' }}>
								<table>
									<colgroup>
										<col style={{ width: '18%' }} />
										<col style={{ width: '32%' }} />
										<col style={{ width: '18%' }} />
										<col style={{ width: '32%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>교체 대상</th>
											<td colSpan={3}>
												<input type="text" readOnly={true} style={{ marginRight: 10, width: 130 }} value={this.state.custNm} />
												<IconButton onClick={this.changeTargetSearch} color="secondary" className="iconButton" style={{ display: sessionStorage.btnControl }}>
													<SearchIcon fontSize="small"/>
												</IconButton>
											</td>
										</tr>
										<tr>
											<th>요청번호</th>
											<td>{this.state.chgTarget.custReqNo}</td>
											<th>계약번호</th>
											<td>{this.state.chgTarget.contNo}</td>
										</tr>
										<tr>
											<th>자산번호</th>
											<td>{this.state.chgTarget.asstNo}</td>
											<th>상태</th>
											<td>{this.state.chgTarget.statusCd}</td>
										</tr>
									</tbody>
								</table>
								수정
								<table>
									<colgroup>
										<col style={{ width: '18%' }} />
										<col style={{ width: '32%' }} />
										<col style={{ width: '18%' }} />
										<col style={{ width: '32%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>요청번호</th>
											<td>{this.state.chgTarget.chgCustReqNo}</td>
											<th>계약번호</th>
											<td><span style={{color: '#ff5454'}}>{this.state.chgTarget.chgContNo}</span></td>
										</tr>
										<tr>
											<th>자산번호</th>
											<td><span style={{color: '#ff5454'}}>{this.state.chgTarget.chgAsstNo}</span></td>
											<th>상태</th>
											<td>{this.state.chgTarget.chgStatusCd}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div style={{ clear: 'both', marginTop: 15, marginBottom: -15, textAlign: 'right' }}>
								<Button
									color="primary"
									variant="outlined"
									size="small"
									style={{ marginLeft: 10, alignItems: 'initial' }}
									onClick={this.save}
								>
									<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
									저장
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(ApiMisreturnManager);
