import React, { Component } from 'react';
import IBSheet from '../IBSheet';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ContIcon from '@material-ui/icons/AssignmentTurnedIn';
import { Search, Clear, TrainRounded } from '@material-ui/icons';
import { httpPut } from '../../service/Service';

import { errorMsgCheck, resultToIbSheet, dateToFormat } from '../../common/ComFunction';
import * as ContractService from '../../service/admin/ContractService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore } = CommonStore;

class MallOrderSaleInfo extends Component {
	constructor(props) {
		super(props);
		this.sheetItemData = {
			//제품리스트
			id: 'sheetOrderItemList', // sheet id
			initData: 'admin/Init-MallOrderItemList', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '130px', // sheet height
		};
	}

	state = {
		disabledBtn: false,
	};

	componentWillUnmount = () => {
		window[this.sheetItemData.id].DisposeSheet();
	};

	resetItemResulList = () => {
		//reset
		window[this.sheetItemData.id].LoadSearchData(
			{
				data: [],
			},
			{
				Sync: 1,
			},
		);
	};

	saveItemStoNo = () => {
		let paramList = [];
		let object = window[this.sheetItemData.id];
		let item = {};

		for (var i = 1; i <= object.GetTotalRows(); i++) {
			if (object.GetCellValue(i, 'stoNo') === '') {
				alert('입고번호가 입력되지 않았습니다.');
				return;
			}

			if (object.GetCellValue(i, 'stoSeq') === '') {
				alert('입고번호 순번이 입력되지 않았습니다.');
				return;
			}

			if (object.GetCellValue(i, 'stoNo') != '' && object.GetCellValue(i, 'stoSeq') != '') {
				item.ordeNo = object.GetCellValue(i, 'ordeNo');
				item.seq = object.GetCellValue(i, 'seq');
				item.stoNo = object.GetCellValue(i, 'stoNo');
				item.stoSeq = object.GetCellValue(i, 'stoSeq');
				paramList.push(item);
				item = {};
			}
		}

		ConfirmModalStore.openModal('입고번호를 저장 하시겠습니까?', () => this.updateOrderItemInfo(paramList));
	};

	updateOrderItemInfo = (paramList) => {
		ProgressStore.activeProgress();

		let data = {};

		for (var i in paramList) {
			data = paramList[i];

			httpPut('/api/v1.0/cis/admin/contract-item/' + data.ordeNo, { body: data });
		}

		ProgressStore.deactiveProgress();
		ModalStore.openModal('저장 되었습니다.');

		this.getOrderList(false); //저장 성공 후 재조회

		// ContractService.updateContOrderItemInfo(paramList)
		// .then(r => {
		//     ModalStore.openModal('저장 되었습니다.');
		//     ProgressStore.deactiveProgress();
		// }).catch(err => {
		//     errorMsgCheck(err, undefined,() => ProgressStore.deactiveProgress());
		// });
	};

	render() {
		const data = this.props.data;

		return (
			<div>
				<div className="writeType03">
					<div style={{ marginTop: 15, marginBottom: -15, textAlign: 'right' }}>
						<Button
							className="button"
							onClick={() => this.saveItemStoNo()}
							color="primary"
							variant="contained"
							size="small"
							disabled={data.contYn === 'Y' ? true : false}
						>
							<SaveIcon className="saveIcon" />
							<span className="text">입고번호저장</span>
						</Button>
					</div>
					<div
						className="scroll"
						onMouseOver={() => {
							document.body.style.overflow = 'hidden';
						}}
						onMouseOut={() => {
							document.body.style.overflow = 'auto';
						}}
					>
						<p className="headline">제품 리스트</p>
						<IBSheet {...this.sheetItemData} />
					</div>
				</div>

				<div className="writeType03_acc" style={{ float: 'left', width: '48%', height: 300, marginBottom: '25px' }}>
					<p className="headline">배송정보</p>
					<table>
						<colgroup>
							<col style={{ width: 80 }} />
							<col style={{ width: 140 }} />
							<col style={{ width: 80 }} />
							<col />
						</colgroup>
						<tbody>
							<tr>
								<th>받으시는분</th>
								<td colSpan="3">
									<input type="text" value={data.delUser} readOnly={true} style={{ border: 0 }} />
								</td>
							</tr>
							<tr>
								<th>전화번호</th>
								<td>
									<input type="text" value={data.delTelno} onChange={(e) => this.setState({ delTelno: e.target.value })} readOnly={true} />
								</td>
								<th>휴대폰번호</th>
								<td>
									<input type="text" value={data.delHpno} onChange={(e) => this.setState({ delHpno: e.target.value })} readOnly={true} />
								</td>
							</tr>
							<tr>
								<th>주소</th>
								<td colSpan="3">
									<input
										type="text"
										value={data.delPostCode}
										onChange={(e) => this.setState({ delPostCode: e.target.value })}
										readOnly={true}
										style={{ width: '20%', marginRight: 5 }}
									/>
									<input
										type="text"
										value={data.delAddr}
										onChange={(e) => this.setState({ delAddr: e.target.value })}
										readOnly={true}
										style={{ width: '70%', marginRight: 5 }}
									/>
									<IconButton
										onClick={() => this.setState({ postModalOpen: true })}
										color="secondary"
										className="iconButton"
										disabled={data.contYn === 'Y' ? true : false}
									></IconButton>
								</td>
							</tr>
							<tr>
								<th>상세주소</th>
								<td colSpan="3">
									<input
										type="text"
										name="delAddrDtl"
										ref={(ref) => (this.delAddrDtl = ref)}
										value={data.delAddrDtl}
										onChange={(e) => this.setState({ delAddrDtl: e.target.value })}
										readOnly={true}
									/>
								</td>
							</tr>
							<tr>
								<th>요청비고</th>
								<td colSpan="3">
									<textarea value={data.ordeRmk} rows="2" style={{ border: 0, height: 60 }} readOnly={true}></textarea>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className="writeType03_acc" style={{ float: 'right', width: '48%', height: 300, marginBottom: '25px' }}>
					<p className="headline">카드정보</p>
					<table>
						<colgroup>
							<col style={{ width: 80 }} />
							<col style={{ width: 100 }} />
							<col style={{ width: 80 }} />
							<col />
						</colgroup>
						<tbody>
							<tr>
								<th>할부개월</th>
								<td>
									<input type="text" value={data.payMonth ? data.payMonth : '일시불'} readOnly={true} />
								</td>
								<th>빌링키</th>
								<td>
									<input type="text" value={data.userCardId} readOnly={TrainRounded} />
								</td>
							</tr>
						</tbody>
					</table>
					<p className="headline">가상계좌정보</p>
					<table>
						<colgroup>
							<col style={{ width: 80 }} />
							<col style={{ width: 100 }} />
							<col style={{ width: 80 }} />
							<col />
						</colgroup>
						<tbody>
							<tr>
								<th>은행명</th>
								<td>
									<input type="text" value={data.userVaccBank} readOnly={true} />
								</td>
								<th>계좌번호</th>
								<td>
									<input type="text" value={data.userVaccNo} readOnly={TrainRounded} />
								</td>
							</tr>
							<tr>
								<th>
									현금영수증
									<br />
									발행여부
								</th>
								<td>
									<input type="text" value={data.cashRecieptHp ? 'Y' : 'N'} readOnly={true} style={{ border: 0 }} />
								</td>
								<th>
									현금영수증
									<br />
									발행번호
								</th>
								<td>
									<input type="text" value={data.cashRecieptHp} readOnly={true} style={{ border: 0 }} />
								</td>
							</tr>
						</tbody>
					</table>
					<p className="headline">카드 및 현금영수증발행 결과</p>
					<table>
						<colgroup>
							<col />
						</colgroup>
						<tbody>
							<tr>
								<td colSpan="3">
									<textarea value={data.resultMsg} rows="2" style={{ border: 0, height: 60 }} readOnly={true}></textarea>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className="writeType03">
					<div className="btnCenter">
						{/*<a className="btn02 white" style={{opacity:1, display: !this.state.disabledBtn ? 'none' : 'inline-block'}} onClick={this.checkOrderItem}>계약</a>*/}
						<Button
							color="secondary"
							variant="contained"
							style={{ marginLeft: 10, alignItems: 'center', width: 200, height: 50 }}
							onClick={this.checkOrderItem}
							disabled={this.state.disabledBtn}
						>
							<ContIcon style={{ fontSize: 20, marginRight: 5 }} />
							<label style={{ fontSize: 18 }}>계약 출고의뢰</label>
						</Button>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(MallOrderSaleInfo);
