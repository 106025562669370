import { httpGet, httpPost, httpPut, toQueryString } from '../Service';

// 조회
export function getCompList(data) {
	return httpGet('/api/v1.0/cis/comp' + toQueryString(data));
}

// 저장(C/U/D)
export function saveComp(data) {
	data = data || {};
	if (data.tranType === 'I') return httpPost('/api/v1.0/cis/comp/', { body: data });
	else if (data.tranType === 'U') return httpPut('/api/v1.0/cis/comp/', { body: data });
	else return httpPut('/api/v1.0/cis/comp/', { body: data });
}

// 고객사 제품 조회
export function getCustItemList(data) {
	return httpGet('/api/v1.0/cis/custItem' + toQueryString(data));
}

// 제품 조회
export function getItemList(data) {
	return httpGet('/api/v1.0/cis/item' + toQueryString(data));
}

// 제품 저장(C/U/D)
export function saveCustItem(data) {
	data = data || {};
	if (data.tranType === 'I') return httpPost('/api/v1.0/cis/custItem/', { body: data });
	else if (data.tranType === 'U') return httpPut('/api/v1.0/cis/custItem/', { body: data });
	else return httpPut('/api/v1.0/cis/custItem/', { body: data });
}

export function saveCustItemOption(data) {
	return httpGet('/api/v1.0/cis/custItem/option/save' + data);
}

// 옵션 조회
export function getCustItemOptionList(data) {
	return httpGet('/api/v1.0/cis/custItem/option' + toQueryString(data));
}

// 제품 조합 리스트 조회
export function getCustItemCombList(data) {
	return httpGet('/api/v1.0/cis/custItem/comb' + toQueryString(data));
}

// 제품 조합 미할당제품 리스트 조회
export function getCustItemCombUnbindList(data) {
	return httpGet('/api/v1.0/cis/custItem/comb/unbind' + toQueryString(data));
}

// 제품 조합 할당제품 리스트 조회
export function getCustItemCombBindList(data) {
	return httpGet('/api/v1.0/cis/custItem/comb/bind' + toQueryString(data));
}

// 제품 조합 저장(C/U/D)
export function saveCustItemComb(data) {
	return httpPut('/api/v1.0/cis/custItem/comb/', { body: data });
}

// 제품 조합 할당 제품 저장(C/U/D)
export function saveCustItemCombBind(data) {
	return httpPut('/api/v1.0/cis/custItem/comb/bind/', { body: data });
}
