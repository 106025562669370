import React, { Component, useEffect, useState, useRef } from 'react'
import IBSheet from '../IBSheet';
import SaveIcon from '@material-ui/icons/Save';
import OtherIcon from '@material-ui/icons/SwapVerticalCircle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import BuildIcon from '@material-ui/icons/Build';
import ContIcon from '@material-ui/icons/AssignmentTurnedIn';
import { buildSelectItems, InsertComma } from '../../common/ComFunction';
import DatePicker from 'react-datepicker';

import { errorMsgCheck, resultToIbSheet, dateToFormat } from '../../common/ComFunction';
import * as ContractService from '../../service/admin/ContractService';

const sheetData2 = {
    id: 'orderItemsSheet', // sheet id
    initData: 'admin/Init-OrderItemsList', // sheet initData javascript filename
    //data: "data", // sheet data javascript filename
    width: '100%', // sheet width
    height: '267px', // sheet height
};   





function MallOrderRentalInfo2(props) {
    /* props를 사용하여 렌더링 */
    const [proceMonth, setProceMonth] = useState('');
    const [contType, setContType] = useState('');
    const [delMethod, setDelMethod] = useState('');
    const [delAskDate, setDelAskDate] = useState('');
    const [delRemark, setDelRemark] = useState('');
    const [delOtherRemark, setDelOtherRemark] = useState('');
    const [updtUser, setUpdtUser] = useState('');
    const [updtDate, setUpdtDate] = useState('');
 
    const [seq, setSeq] = useState('');
    const [itemQty, setItemQty] = useState('');
    const [itemCode, setItemCode] = useState('');
    const [changeQty, setChangeQty] = useState('');
    const [itemNm, setItemNm] = useState('');
    const [model, setModel] = useState('');
    const [spec, setSpec] = useState('');
    const [makeNm, setMakeNm] = useState('');
    const [userDeptName, setUserDeptName] = useState('');
    const [userName, setUserName] = useState('');
    const [userRemark, setUserRemark] = useState('');
    const [monthPrc, setMonthPrc] = useState('');
    const [stoNo, setStoNo] = useState('');
    const [stoSeq, setStoSeq] = useState('');
    const [asstNo, setAsstNo] = useState('');
    const [ifrsCode, setIfrsCode] = useState('');
    const [inputReadOnly, setInputReadOnly] = useState(false);
    const [disabledBtn, setDisabledBtn] = useState(false)

    useEffect(() => {
        //console.log('props.data => '+props.data);
        // 첫번째 렌더링과 이후의 모든 업데이트에서 수행
        if(window[sheetData2.id]){
            window[sheetData2.id].DisposeSheet();
        }else{

        }
    },[]);

    let getOrderItemInfo = function(){
        if (window[sheetData2.id].LastRow() === 0) return;
        let data = window.getOrderItemSelectData();
    
        setSeq(data.seq);
        setItemQty(data.itemQty);
        setItemCode(data.itemCode);
        setChangeQty(data.changeQty);
        setItemNm(data.itemNm);
        setModel(data.model);
        setSpec(data.spec);
        setMakeNm(data.makeNm);
        setUserDeptName(data.userDeptName);
        setUserName(data.userName);
        setUserRemark(data.userRemark);
        setMonthPrc(data.monthPrc);
        setStoNo(data.stoNo);
        setStoSeq(data.stoSeq);
        setAsstNo(data.asstNo);
        setIfrsCode(data.ifrsCode);

        if(props.data.contYn === 'Y'){
            setInputReadOnly(true);
            setDisabledBtn(true);
        }
    }

    const data = props.data ? props.data : {};

    if(window[sheetData2.id]){
        window[sheetData2.id].LoadSearchData(data.ordeItemList);
    }
    
    return (
        <div>
            <div className="writeType03_acc" style={{ float: 'left', width: '48%', height: 300, marginBottom: '25px' }}>
                <p className="headline">배송정보</p>
                <table>
                    <colgroup>
                        <col style={{ width: 80 }} />
                        <col style={{ width: 140 }} />
                        <col style={{ width: 80 }} />
                        <col />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>받으시는분</th>
                            <td colSpan="3">
                                <input
                                    type="text"
                                    value={data.delUser}
                                    readOnly={true}
                                    style={{ border: 0 }} />
                            </td>
                        </tr>                            
                        <tr>
                            <th>전화번호</th>
                            <td>
                                <input
                                    type="text"
                                    value={data.delTelno}
                                    readOnly={true}
                                />
                            </td>
                            <th>휴대폰번호</th>
                            <td>
                                <input
                                    type="text"
                                    value={data.delHpno}
                                    readOnly={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>주소</th>
                            <td colSpan="3">
                                <input
                                    type="text"
                                    value={data.delPostCode}
                                    readOnly={true}
                                    style={{ width: '20%', marginRight: 5 }}
                                />
                                <input
                                    type="text"
                                    value={data.delAddr}
                                    readOnly={true}
                                    style={{ width: '78%'}}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>상세주소</th>
                            <td colSpan="3">
                                <input
                                    type="text"
                                    name="delAddrDtl"
                                    //ref={(ref) => (this.delAddrDtl = ref)}
                                    value={data.delAddrDtl}
                                    readOnly={data.inputReadOnly}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>요청비고</th>
                            <td colSpan="3">
                                <textarea value={data.ordeRmk} rows="2" style={{ border: 0, height: 60 }} readOnly={true}></textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="writeType03_acc" style={{ float: 'right', width: '48%', height: 300, marginBottom: '25px' }}>
                <p className="headline">출고정보</p>
                <table>
                    <colgroup>
                        <col style={{ width: 80 }} />
                        <col style={{ width: 130 }} />
                        <col style={{ width: 80 }} />
                        <col />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>계약처리월</th>
                            <td>
                                <select
                                    value={proceMonth}
                                    onChange={(e) => setProceMonth(e.target.value)}
                                    disabled={data.inputReadOnly}
                                >
                                    <option value="">선택</option>
                                    <option value="1">당월</option>
                                    <option value="2">익월</option>
                                </select>
                            </td>
                            <th>계약유형</th>
                            <td>
                                <select value={contType} onChange={(e) => setContType(e.target.value)} disabled={inputReadOnly}>
                                    <option value="">선택</option>
                                    {data.arrContType
                                        ? data.arrContType.map((item, i) => (
                                                <option key={i} value={item.contType} selected={item.contType === data.contType ? true : false}>
                                                    {item.contTypeNm}
                                                </option>
                                            ))
                                        : null}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>배송방법</th>
                            <td>
                                <select value={delMethod} onChange={(e) => setDelMethod(e.target.value)} disabled={data.inputReadOnly}>
                                    {data.arrDelMothd ? buildSelectItems([{ title: '선택', value: '' }].concat(data.arrDelMothd)) : null}
                                </select>
                            </td>
                            <th>배송요청일</th>
                            <td>
                                {/* {data.contNo ? (
                                    <input type="text" value={delDate} readOnly={true} style={{ width: 100, border: 0 }} />
                                ) : ( */}
                                    <DatePicker
                                        customInput={<input type="text" style={{ width: 100 }} />}
                                        dateFormat="YYYY-MM-DD"
                                        selected={delAskDate}
                                        onChange={(date) => setDelAskDate(date)}
                                        isClearable={false}
                                    />
                                {/* )} */}
                            </td>
                        </tr>
                        <tr>
                            <th>출고비고</th>
                            <td colSpan="3">
                                <textarea
                                    value={delRemark}
                                    readOnly={data.inputReadOnly}
                                    onChange={(e) => setDelRemark(e.target.value)}
                                    style={{ height: 60 }}
                                ></textarea>
                            </td>
                        </tr>
                        <tr>
                            <th>배송비고</th>
                            <td colSpan="3">
                                <input
                                    type="text"
                                    value={delOtherRemark}
                                    readOnly={data.inputReadOnly}
                                    onChange={(e) => setDelOtherRemark(e.target.value)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>변경자</th>
                            <td>
                                <input type="text" value={updtUser} readOnly={true} style={{ border: 0 }} />
                            </td>
                            <th>변경일자</th>
                            <td>
                                <input type="text" value={updtDate} readOnly={true} style={{ border: 0 }} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ marginTop: 15, textAlign: 'right' }}>
                    <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        style={{ marginLeft: 10, alignItems: 'initial' }}
                        //onClick={this.saveDelInfo2}
                        disabled={data.disabledBtn}
                    >
                        <SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
                        출고정보저장
                    </Button>
                </div>
            </div>
            <div className="writeType03" style={{ float: 'left', width: '40%', marginTop: 20 }} onClick={()=> getOrderItemInfo()}>
                <div
                    className="scroll"
                    onMouseOver={() => {
                        document.body.style.overflow = 'hidden';
                    }}
                    onMouseOut={() => {
                        document.body.style.overflow = 'auto';
                    }}
                >
                    <p className="headline">주문제품 리스트</p>
                    <IBSheet {...sheetData2} />
                </div>
            </div>
            <div className="writeType03_acc" style={{ float: 'right', width: '55%', marginTop: 20 }}>
                <p className="headline">제품정보</p>
                <table>
                    <colgroup>
                        <col style={{ width: 80 }} />
                        <col style={{ width: 220 }} />
                        <col style={{ width: 70 }} />
                        <col />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>모델명</th>
                            <td>
                                <input type="text" value={model} readOnly={true} style={{ border: 0 }} />
                            </td>
                            <th>제조사</th>
                            <td>
                                <input type="text" value={makeNm} readOnly={true} style={{ border: 0 }} />
                            </td>
                        </tr>
                        <tr>
                            <th>스펙</th>
                            <td colSpan="3">
                                <input type="text" value={spec} readOnly={true} style={{ border: 0 }} />
                            </td>
                        </tr>
                        <tr>
                            <th>부서(대리점)</th>
                            <td>
                                <input type="text" value={userDeptName} readOnly={true} style={{ border: 0 }} />
                            </td>
                            <th>사용자명</th>
                            <td>
                                <input type="text" value={userName} readOnly={true} style={{ border: 0 }} />
                            </td>
                        </tr>
                        <tr>
                            <th>사용자비고</th>
                            <td colSpan="3">
                                <input type="text" value={userRemark} readOnly={true} style={{ border: 0 }} />
                            </td>
                        </tr>
                        <tr>
                            <th>신품</th>
                            <td>
                                <input
                                    type="text"
                                    value={stoNo}
                                    onChange={(e) => setStoNo(e.target.value)}
                                    placeholder="입고번호"
                                    readOnly
                                    // readOnly={this.state.inputReadOnly}
                                    style={{ width: 100, marginLeft: 5, marginRight: 5 }}
                                />
                                <input
                                    type="text"
                                    value={stoSeq}
                                    onChange={(e) => setStoSeq(e.target.value)}
                                    placeholder="순번"
                                    readOnly
                                    // readOnly={this.state.inputReadOnly}
                                    style={{ width: 50, marginRight: 5 }}
                                />
                                <IconButton
                                    onClick={() => {
                                        this.stockPopOpen('A');
                                        setStoNo('');
                                        setStoSeq('');
                                    }}
                                    color="secondary"
                                    className="iconButton"
                                    disabled={data.disabledBtn}
                                >
                                    <SearchIcon fontSize="small" />
                                </IconButton>
                            </td>
                            <th>IFRS코드</th>
                            <td colSpan="3">
                                <input
                                    type="text"
                                    value={ifrsCode}
                                    style={{ width: 90, marginRight: 5 }}
                                    onChange={(e) => setIfrsCode(e.target.value)}
                                    readOnly={inputReadOnly}
                                />
                                <IconButton
                                    //onClick={() => this.setState({ ifrsPopOpen: true })}
                                    color="secondary"
                                    className="iconButton"
                                    disabled={data.disabledBtn}
                                >
                                    <SearchIcon fontSize="small" />
                                </IconButton>
                            </td>
                        </tr>
                        <tr>
                            <th>재고</th>
                            <td>
                                <input
                                    type="text"
                                    value={asstNo}
                                    onChange={(e) => setAsstNo(e.target.value)}
                                    placeholder="자산번호"
                                    readOnly
                                    // readOnly={this.state.inputReadOnly}
                                    style={{ width: 90, marginRight: 5 }}
                                />
                                <IconButton
                                    onClick={() => {
                                        this.stockPopOpen('B');
                                        setAsstNo('');
                                    }}
                                    color="secondary"
                                    className="iconButton"
                                    disabled={data.disabledBtn}
                                >
                                    <SearchIcon fontSize="small" />
                                </IconButton>
                            </td>
                            <th>월렌탈료</th>
                            <td>
                                <input
                                    type="text"
                                    value={monthPrc}
                                    readOnly={inputReadOnly}
                                    onChange={(e) => setMonthPrc(e.target.value)}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ marginTop: 15, textAlign: 'right' }}>
                    <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        style={{ marginLeft: 10, alignItems: 'initial' }}
                        //onClick={this.saveItemInfo}
                        disabled={data.disabledBtn}
                    >
                        <SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
                        제품정보저장
                    </Button>
                </div>
            </div>
            <div className="writeType03" >
                <div className="btnCenter" style={{ paddingTop: 700 }}>
                    {/*<a className="btn02 white" style={{opacity:1, display: !this.state.disabledBtn ? 'none' : 'inline-block'}} onClick={this.checkOrderItem}>계약</a> */}
                    <Button
                        color="secondary"
                        variant="contained"
                        style={{ marginLeft: 10, alignItems: 'center', width: 200, height: 50 }}
                        //onClick={this.checkOrderItem}
                        disabled={data.disabledBtn}
                    >
                        <ContIcon style={{ fontSize: 20, marginRight: 5 }} />
                        <label style={{ fontSize: 18 }}>계약 출고의뢰</label>
                    </Button>
                </div>
            </div>

        </div>
    );
}

function areEqual(prevProps, nextProps){
    //console.log(prevProps.data.ordeNo + ' , ' + nextProps.data.ordeNo);
}


class MallOrderRentalInfo extends Component{
    constructor(props) {
        super(props);

        this.state = {
            proceMonth: '',
            contType: '',
            delMethod: '',
            delAskDate: null,
            delRemark: '',
            delOtherRemark: '',
            updtUser: '',
            updtDate: '',

            seq: '',
            itemQty: '',
            itemCode: '',
            changeQty: '',
            itemNm: '',
            model: '',
            spec: '',
            makeNm: '',
            userDeptName: '',
            userName: '',
            userReamrk: '',
            monthPrc: '',
            stoNo: '',
            stoSeq: '',
            asstNo: '',
            ifrsCode: '',

            inputReadOnly: false,
            disabledBtn: false,
        }

		this.sheetData2 = {
			id: 'orderItemsSheet', // sheet id
			initData: 'admin/Init-OrderItemsList', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '267px', // sheet height
		};   
    }

    componentWillUnmount =()=> {
        //window[this.sheetData2.id].DisposeSheet();
    }

	getOrderItemInfo = () => {
		if (window[this.sheetData2.id].LastRow() === 0) return;

		this.setOrderItemInfo(window.getOrderItemSelectData());
	};

	setOrderItemInfo = (data) => {
        alert(data.seq);
		if (data) {
			this.setState({
				seq: data.seq,
				itemQty: data.itemQty,
				itemCode: data.itemCode,
				itemNm: data.itemNm,
				model: data.model,
				spec: data.spec,
				makeNm: data.makeNm,
				userDeptName: data.userDeptName,
				userName: data.userName,
				userRemark: data.userRemark,
				monthPrc: data.monthPrc ? InsertComma(data.monthPrc) : data.monthPrc,
				stoNo: data.stoNo,
				stoSeq: data.stoSeq,
				asstNo: data.asstNo,
				ifrsCode: data.ifrsCode,
			});
		} else {
			this.setState({
				seq: '',
				itemQty: '',
				itemCode: '',
				changeQty: '',
				itemNm: '',
				model: '',
				spec: '',
				makeNm: '',
				userDeptName: '',
				userName: '',
				userReamrk: '',
				monthPrc: '',
				stoNo: '',
				stoSeq: '',
				asstNo: '',
				ifrsCode: '',
			});
		}
	};

    shouldComponentUpdate =(nextProps, nextState)=> {
        let props = this.props !== nextProps;
        let state = this.state !== nextState
        alert('props = '+props + ' , state = '+state);
        return props || !state; 
    } 

    render(){

        const data = this.props.data ? this.props.data : {};

        if(window[this.sheetData2.id]){
            window[this.sheetData2.id].LoadSearchData(data.ordeItemList);
        }
        
        return (
            <div>
                <div className="writeType03_acc" style={{ float: 'left', width: '48%', height: 300, marginBottom: '25px' }}>
                    <p className="headline">배송정보</p>
                    <table>
                        <colgroup>
                            <col style={{ width: 80 }} />
                            <col style={{ width: 140 }} />
                            <col style={{ width: 80 }} />
                            <col />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>받으시는분</th>
                                <td colSpan="3">
                                    <input
                                        type="text"
                                        value={data.delUser}
                                        readOnly={true}
                                        style={{ border: 0 }} />
                                </td>
                            </tr>                            
                            <tr>
                                <th>전화번호</th>
                                <td>
                                    <input
                                        type="text"
                                        value={data.delTelno}
                                        readOnly={true}
                                    />
                                </td>
                                <th>휴대폰번호</th>
                                <td>
                                    <input
                                        type="text"
                                        value={data.delHpno}
                                        readOnly={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>주소</th>
                                <td colSpan="3">
                                    <input
                                        type="text"
                                        value={data.delPostCode}
                                        readOnly={true}
                                        style={{ width: '20%', marginRight: 5 }}
                                    />
                                    <input
                                        type="text"
                                        value={data.delAddr}
                                        readOnly={true}
                                        style={{ width: '78%'}}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>상세주소</th>
                                <td colSpan="3">
                                    <input
                                        type="text"
                                        name="delAddrDtl"
                                        //ref={(ref) => (this.delAddrDtl = ref)}
                                        value={data.delAddrDtl}
                                        readOnly={data.inputReadOnly}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>요청비고</th>
                                <td colSpan="3">
                                    <textarea value={data.ordeRmk} rows="2" style={{ border: 0, height: 60 }} readOnly={true}></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="writeType03_acc" style={{ float: 'right', width: '48%', height: 300, marginBottom: '25px' }}>
                    <p className="headline">출고정보</p>
                    <table>
                        <colgroup>
                            <col style={{ width: 80 }} />
                            <col style={{ width: 130 }} />
                            <col style={{ width: 80 }} />
                            <col />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>계약처리월</th>
                                <td>
                                    <select
                                        value={this.state.proceMonth}
                                        onChange={(e) => this.setState({proceMonth: e.target.value})}
                                        disabled={this.state.inputReadOnly}
                                    >
                                        <option value="">선택</option>
                                        <option value="1">당월</option>
                                        <option value="2">익월</option>
                                    </select>
                                </td>
                                <th>계약유형</th>
                                <td>
                                    <select value={this.state.contType} onChange={(e) => this.setState({contType: e.target.value})} disabled={this.state.inputReadOnly}>
                                        <option value="">선택</option>
                                        {data.arrContType
                                            ? data.arrContType.map((item, i) => (
                                                    <option key={i} value={item.contType} selected={item.contType === data.contType ? true : false}>
                                                        {item.contTypeNm}
                                                    </option>
                                                ))
                                            : null}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>배송방법</th>
                                <td>
                                    <select value={this.state.delMethod} onChange={(e) => this.setState({delMethod: e.target.value})} disabled={this.state.inputReadOnly}>
                                        {data.arrDelMothd ? buildSelectItems([{ title: '선택', value: '' }].concat(data.arrDelMothd)) : null}
                                    </select>
                                </td>
                                <th>배송요청일</th>
                                <td>
                                    {/* {data.contNo ? (
                                        <input type="text" value={delDate} readOnly={true} style={{ width: 100, border: 0 }} />
                                    ) : ( */}
                                        <DatePicker
                                            customInput={<input type="text" style={{ width: 100 }} />}
                                            dateFormat="YYYY-MM-DD"
                                            selected={this.state.delAskDate}
                                            onChange={(date) => this.setState({delAskDate: date})}
                                            isClearable={false}
                                        />
                                    {/* )} */}
                                </td>
                            </tr>
                            <tr>
                                <th>출고비고</th>
                                <td colSpan="3">
                                    <textarea
                                        value={this.state.delRemark}
                                        readOnly={this.state.inputReadOnly}
                                        onChange={(e) => this.setState({delRemark: e.target.value})}
                                        style={{ height: 60 }}
                                    ></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th>배송비고</th>
                                <td colSpan="3">
                                    <input
                                        type="text"
                                        value={this.state.delOtherRemark}
                                        readOnly={this.state.inputReadOnly}
                                        onChange={(e) => this.setState({delOtherRemark: e.target.value})}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>변경자</th>
                                <td>
                                    <input type="text" value={this.state.updtUser} readOnly={true} style={{ border: 0 }} />
                                </td>
                                <th>변경일자</th>
                                <td>
                                    <input type="text" value={this.state.updtDate} readOnly={true} style={{ border: 0 }} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ marginTop: 15, textAlign: 'right' }}>
                        <Button
                            color="primary"
                            variant="outlined"
                            size="small"
                            style={{ marginLeft: 10, alignItems: 'initial' }}
                            //onClick={this.saveDelInfo2}
                            disabled={this.state.disabledBtn}
                        >
                            <SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
                            출고정보저장
                        </Button>
                    </div>
                </div>
                <div className="writeType03" style={{ float: 'left', width: '40%', marginTop: 20 }} onClick={()=> this.getOrderItemInfo()}>
                    <div
                        className="scroll"
                        onMouseOver={() => {
                            document.body.style.overflow = 'hidden';
                        }}
                        onMouseOut={() => {
                            document.body.style.overflow = 'auto';
                        }}
                    >
                        <p className="headline">주문제품 리스트</p>
                        <IBSheet {...this.sheetData2} />
                    </div>
                </div>
                <div className="writeType03_acc" style={{ float: 'right', width: '55%', marginTop: 20 }}>
                    <p className="headline">제품정보</p>
                    <table>
                        <colgroup>
                            <col style={{ width: 80 }} />
                            <col style={{ width: 220 }} />
                            <col style={{ width: 70 }} />
                            <col />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>모델명</th>
                                <td>
                                    <input type="text" value={this.state.model} readOnly={true} style={{ border: 0 }} />
                                </td>
                                <th>제조사</th>
                                <td>
                                    <input type="text" value={this.state.makeNm} readOnly={true} style={{ border: 0 }} />
                                </td>
                            </tr>
                            <tr>
                                <th>스펙</th>
                                <td colSpan="3">
                                    <input type="text" value={this.state.spec} readOnly={true} style={{ border: 0 }} />
                                </td>
                            </tr>
                            <tr>
                                <th>부서(대리점)</th>
                                <td>
                                    <input type="text" value={this.state.userDeptName} readOnly={true} style={{ border: 0 }} />
                                </td>
                                <th>사용자명</th>
                                <td>
                                    <input type="text" value={this.state.userName} readOnly={true} style={{ border: 0 }} />
                                </td>
                            </tr>
                            <tr>
                                <th>사용자비고</th>
                                <td colSpan="3">
                                    <input type="text" value={this.state.userRemark} readOnly={true} style={{ border: 0 }} />
                                </td>
                            </tr>
                            <tr>
                                <th>신품</th>
                                <td>
                                    <input
                                        type="text"
                                        value={this.state.stoNo}
                                        onChange={(e) => this.setState({stoNo: e.target.value})}
                                        placeholder="입고번호"
                                        readOnly
                                        // readOnly={this.state.inputReadOnly}
                                        style={{ width: 100, marginLeft: 5, marginRight: 5 }}
                                    />
                                    <input
                                        type="text"
                                        value={this.state.stoSeq}
                                        onChange={(e) => this.setState({StoSeq: e.target.value})}
                                        placeholder="순번"
                                        readOnly
                                        // readOnly={this.state.inputReadOnly}
                                        style={{ width: 50, marginRight: 5 }}
                                    />
                                    <IconButton
                                        onClick={() => {
                                            this.stockPopOpen('A');
                                            this.setState({
                                                stoNo: '',
                                                stoSeq: ''
                                            })
                                        }}
                                        color="secondary"
                                        className="iconButton"
                                        disabled={data.disabledBtn}
                                    >
                                        <SearchIcon fontSize="small" />
                                    </IconButton>
                                </td>
                                <th>IFRS코드</th>
                                <td colSpan="3">
                                    <input
                                        type="text"
                                        value={this.state.ifrsCode}
                                        style={{ width: 90, marginRight: 5 }}
                                        onChange={(e) => this.setState({frsCode: e.target.value})}
                                        readOnly={this.state.inputReadOnly}
                                    />
                                    <IconButton
                                        //onClick={() => this.setState({ ifrsPopOpen: true })}
                                        color="secondary"
                                        className="iconButton"
                                        disabled={data.disabledBtn}
                                    >
                                        <SearchIcon fontSize="small" />
                                    </IconButton>
                                </td>
                            </tr>
                            <tr>
                                <th>재고</th>
                                <td>
                                    <input
                                        type="text"
                                        value={this.state.asstNo}
                                        onChange={(e) => this.setState({asstNo: e.target.value})}
                                        placeholder="자산번호"
                                        readOnly
                                        // readOnly={this.state.inputReadOnly}
                                        style={{ width: 90, marginRight: 5 }}
                                    />
                                    <IconButton
                                        onClick={() => {
                                            this.stockPopOpen('B');
                                            this.setState({
                                                asstNo: ''
                                            })
                                        }}
                                        color="secondary"
                                        className="iconButton"
                                        disabled={data.disabledBtn}
                                    >
                                        <SearchIcon fontSize="small" />
                                    </IconButton>
                                </td>
                                <th>월렌탈료</th>
                                <td>
                                    <input
                                        type="text"
                                        value={this.state.monthPrc}
                                        readOnly={this.state.inputReadOnly}
                                        onChange={(e) => this.setState({monthPrc: e.target.value})}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ marginTop: 15, textAlign: 'right' }}>
                        <Button
                            color="primary"
                            variant="outlined"
                            size="small"
                            style={{ marginLeft: 10, alignItems: 'initial' }}
                            //onClick={this.saveItemInfo}
                            disabled={data.disabledBtn}
                        >
                            <SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
                            제품정보저장
                        </Button>
                    </div>
                </div>
                <div className="writeType03" >
                    <div className="btnCenter" style={{ paddingTop: 700 }}>
                        {/*<a className="btn02 white" style={{opacity:1, display: !this.state.disabledBtn ? 'none' : 'inline-block'}} onClick={this.checkOrderItem}>계약</a> */}
                        <Button
                            color="secondary"
                            variant="contained"
                            style={{ marginLeft: 10, alignItems: 'center', width: 200, height: 50 }}
                            //onClick={this.checkOrderItem}
                            disabled={data.disabledBtn}
                        >
                            <ContIcon style={{ fontSize: 20, marginRight: 5 }} />
                            <label style={{ fontSize: 18 }}>계약 출고의뢰</label>
                        </Button>
                    </div>
                </div>

            </div>
        );

    }
}


export default MallOrderRentalInfo;
//export default React.memo(MallOrderRentalInfo2, areEqual);