import { httpGet, httpPost, httpPut, toQueryString } from '../Service';

// 조회
export function getErpDataSyncList(data) {
	return httpGet('/api/v1.0/cis/erpDataSync' + toQueryString(data));
}

// 계약제품 조회
export function getItemListForReverse(data) {
	return httpGet('/api/v1.0/cis/get-contItem' + toQueryString(data));
}

// 저장(C/U/D)
export function saveErpDataSync(data) {
	data = data || {};
	return httpPost('/api/v1.0/cis/erpDataSync/', { body: data });
	// return httpPost('/api/v1.0/cis/erpDataSync/' + data);
}
