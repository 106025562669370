import React, { Component } from 'react';
import { makeObservable, observable, action } from 'mobx';
import { observer } from 'mobx-react';

const RentalOrderDividePopupStore = observable({
	open: false,
	searched: false,
	ordeNo: '',
	handleClose: null,

	openPopup(ordeNo, handleClose) {
		this.open = true;
		this.ordeNo = ordeNo;
		if (handleClose) this.handleClose = handleClose;
	},
	closePopup(item) {
		this.open = false;
		this.ordeNo = '';
		this.searched = false;
		if (this.handleClose) {
			this.handleClose(item);
		}
	},
	setSearched(searched) {
		this.searched = searched;
	},
});

export default RentalOrderDividePopupStore;
