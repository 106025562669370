import React, { Component } from 'react';
import { makeObservable, observable, action } from 'mobx';
import { observer } from 'mobx-react';

const AssetInfoChangePopStore = observable({
	open: false,
	asstInfo: {},
	handleClose: null,

	openPopup(asstInfo, handleClose) {
		this.open = true;
		this.asstInfo = asstInfo;
		if (handleClose) this.handleClose = handleClose;
	},
	closePopup(item) {
		this.open = false;
		this.asstInfo = {};
		if (this.handleClose) {
			this.handleClose(item);
		}
	},
});

export default AssetInfoChangePopStore;
