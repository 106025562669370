import {httpGet,httpPost,httpPut,toQueryString} from '../Service'

//마감 청구지 리스트
export function getMonthEndList(data){
    return httpGet('/api/v1.0/cis/rental/monthEnd'+toQueryString(data));
}

//마감 자산 리스트
export function getMonthEndAssetList(data){
    return httpGet('/api/v1.0/cis/rental/monthEnd/asset'+toQueryString(data));
}

//마감확정
export function postMonthEnd(data){
    data = data || {};
    return httpPost('/api/v1.0/cis/rental/monthEnd/'+data.endId,{body:data});
}