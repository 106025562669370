import { httpGet, httpPost, httpPut, toQueryString } from '../Service';

// 조회
export function getMobileCouponProcList(data) {
	return httpGet('/api/v1.0/cis/mc/eventgoodslist' + toQueryString(data));
}

// 쿠폰발급업체 / 분류 조회
export function getInfoList(data) {
	return httpGet('/api/v1.0/cis/mc/category' + toQueryString(data));
}

// 저장
export function saveCategoryInfo(data) {
	return httpPost('/api/v1.0/cis/mc/category/', {body: data});
}

/**
 * MallMoCpManager
 * */
//쿠폰발급내역
export function getCpList(data) {
	return httpGet('/api/v1.0/cis/mc/couponissuedlist' + toQueryString(data));
}

//현금결제정보 발행취소
export function setCouponCancel(data) {
	return httpGet('/api/v1.0/cis/mc/couponCancel' + toQueryString(data));
}

//현금결제정보 재발행
export function setCouponResend(data) {
	return httpGet('/api/v1.0/cis/mc/couponResend' + toQueryString(data));
}

//현금결제정보 조회
export function getCouponInfoSearch(data) {
	return httpGet('/api/v1.0/cis/mc/couponInfo' + toQueryString(data));
}

//쿠폰교환내역 조회
export function getCouponState(data) {
	return httpGet('/api/v1.0/cis/mc/couponState' + toQueryString(data));
} 

//쿠폰발급이력 조회
export function getCouponissuedHistoryList(data) {
	return httpGet('/api/v1.0/cis/mc/couponissuedHistoryList' + toQueryString(data));
} 

/**
 * MallMoCpManagerPopup
 * */
//쿠폰생성
export function getCouponSend(data) {
	return httpPost('/api/v1.0/cis/mc/couponSend',{body: data});
}