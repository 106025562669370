import { httpGet, httpPut, toQueryString } from '../Service';

// 커스터마이징 내용 불러오기
export function customMngList() {
	return httpGet('/api/v1.0/cis/customize/list');
}

// 커스터마이징 내용 저장하기
export function saveCustomMng(data) {
	data = data || {};
	return httpPut('/api/v1.0/cis/customize/save', { body: data });
}

// 커스터마이징 고객 조회
export function customCustList(data) {
	return httpGet('/api/v1.0/cis/customize/custList' + toQueryString(data));
}

// 커스터마이징 고객 저장
export function saveCustomCust(data) {
	data = data || {};
	return httpPut('/api/v1.0/cis/customize/custSave', { body: data });
}
