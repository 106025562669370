import React, { Component } from 'react';

import IBSheet from '../IBSheet';
import SaveIcon from '@material-ui/icons/Save';
import ReturnIcon from '@material-ui/icons/LocalShipping';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { Transition, errorMsgCheck, resultToIbSheet, isEmptyObject } from '../../common/ComFunction';

import { getSpotList } from '../../service/members/MembersService';
import { updateAssetListInfo } from '../../service/rental/RentalAssetService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, RentalOrderItemCombPopStore } = CommonStore;

class RentalOrderItemCombPop extends Component {
	constructor(props) {
		super(props);

		this.sheetData = {
			id: 'rentalOrderItemCombSheet', // sheet id
			initData: 'rental/Init-RentalOrderItemCombPop', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '400px', // sheet height
		};
	}

	componentDidUpdate = () => {
		const interval = setInterval(() => {
			if (window[this.sheetData.id]) {
				window[this.sheetData.id].LoadSearchData(resultToIbSheet(RentalOrderItemCombPopStore.custItemCombListData));
				clearInterval(interval);
			}
		}, 100);
	};

	componentWillUnmount = () => {
		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}
	};

	selectItem = () => {
		ConfirmModalStore.openModal('선택한 제품 조합을 추가하시겠습니까?', () => {
			if (isEmptyObject(window.getrentalOrderItemCombSelectedRow())) {
				this.popClose(window[this.sheetData.id].GetRowData(1)); // 클릭 없이 선택 시 첫번째 row로
			} else {
				this.popClose(window.getrentalOrderItemCombSelectedRow());
			}
		});
	};

	updateAssetInfo = () => {
		ProgressStore.activeProgress();

		let arrData = window.getAssetInfoChangeList();

		if (arrData.length === 0) {
			ModalStore.openModal('변경된 데이터가 없습니다.');
			return;
		}

		console.log(arrData);
		updateAssetListInfo(arrData)
			.then(() => {
				ModalStore.openModal('저장 되었습니다.', () => this.popClose(true));
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	popClose = (selectedRow) => {
		console.log(selectedRow);
		RentalOrderItemCombPopStore.closePopup(selectedRow);

		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}
	};

	render() {
		// getSpotList();
		return (
			<div>
				<Dialog open={RentalOrderItemCombPopStore.open} TransitionComponent={Transition} onClose={() => this.popClose()}>
					<div className="popSmArea searchPop" style={{ transform: 'translate(-15%, 0)' }}>
						<div className="popCont" style={{ height: 570, width: 1170, overflow: 'hidden' }}>
							<div className="h2Wrap">
								<h2>조합 가능한 제품</h2>
							</div>
							<div className="writeType04">
								<IBSheet {...this.sheetData} />
								<div style={{ marginTop: 20, float: 'right' }}>
									<Button color="primary" variant="contained" size="small" style={{ alignItems: 'initial' }} onClick={this.selectItem}>
										<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
										선택
									</Button>
								</div>
							</div>
							<div className="btnClose" onClick={() => this.popClose()}>
								<a>팝업닫기</a>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}
}
export default observer(RentalOrderItemCombPop);
