import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';
import DatePicker from 'react-datepicker';
import { apiBillingStatemenList } from '../../service/admin/ApiBillingStatementManagerService';
import { errorMsgCheck, resultToIbSheet, dateToFormat, ComIBSheetComboNull } from '../../common/ComFunction';
import { getRentalCommCode } from '../../service/CommonService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
import ExcelDown from '../include/ExcelDown';
import moment from 'moment';

const { ProgressStore } = CommonStore;

class ApiBillingStatementManager extends Component {
	constructor(props) {
		super(props);

		this.sheet = null;
		this.sheetData = {
			id: 'apiBillingStatementManagerSheet', //sheet id
			initData: 'admin/Init-ApiBillingStatementManager',
			width: '100%',
			height: '600px',
			sheetRendered: (sheet) => {
				this.sheet = sheet;
			},
		};
	}

	state = {
		statemenList: [],
		excelList: [], //엑셀다운로드용 리스트
		searchRegSDate: null,
		searchRegEDate: null,
		searchDmdSDate: null,
		searchDmdEDate: null,
		searchContNo: '',
		searchAsstNo: '',
		searchOrdeNo: '',
		searchCustReqNo: '',
		isChangedCont: false,
		custNo: '',

	};

	componentDidMount = () => {
		// sheet 이벤트 등록
		window[`${this.sheetData.id}_OnDblClick`] = (row, col) => {
		};

		Promise.all([getRentalCommCode('45')]).then((r) => {
			this.dmdTypeCodes = r[0];
			const timer = setInterval(() => {
				if (window[this.sheetData.id]) {
					ComIBSheetComboNull('0', window[this.sheetData.id], 'dmdType', this.dmdTypeCodes, 'title', 'value', '', '');
					ProgressStore.deactiveProgress();
					clearInterval(timer);
				}
			}, 100);
		});
	};

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
	};

	dateHandler = (date, type) => {
		if (type === 'rs') {
			this.setState({ searchRegSDate: date });
		} else if (type === 're') {
			this.setState({ searchRegEDate: date });
		} else if (type === 'ss') {
			this.setState({ searchDmdSDate: date });
		} else if (type === 'se') {
			this.setState({ searchDmdEDate: date });
		}
	};

	statemenList = () => {
		ProgressStore.activeProgress();

		let param = {
			contNo: this.state.searchContNo,
			asstNo: this.state.searchAsstNo,
			ordeNo: this.state.searchOrdeNo,
			custReqNo: this.state.searchCustReqNo,
			regSDate: dateToFormat(this.state.searchRegSDate).substring(0, 10),
			regEDate: dateToFormat(this.state.searchRegEDate).substring(0, 10),
			dmdSDate: dateToFormat(this.state.searchDmdSDate).substring(0, 10),
			dmdEDate: dateToFormat(this.state.searchDmdEDate).substring(0, 10),
		};

		apiBillingStatemenList(param)
			.then((r) => {
				this.setState({ statemenList: r }, () => {
					window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				});
				this.makeExcelData(r);
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	makeExcelData = (data) => {
		let tmpList = [];
		let tmp = {};
		data.map((item, idx) => {
			tmp = {};
			tmp.No = idx + 1;
			tmp.청구번호 = item.dmdNo;
			tmp.주문번호 = item.ordeNo;
			tmp.요청번호 = item.custReqNo;
			tmp.청구월 = item.dmdDate;
			tmp.자산번호 = item.asstNo;
			tmp.청구구분 = (this.dmdTypeCodes.find(o => o.value == item.dmdType).title);
			tmp.계약번호 = item.contNo;
			tmp.청구금액 = item.dmdAmt;
			tmp.변경청구금액 = '';
			tmp.변경청구금액사유 = '';
			tmp.화면 = item.fromTable;
			tmp.등록일자 = item.instDate;
			tmpList.push(tmp);
		});
		this.setState({ excelList: tmpList });
	};
	reset = () => {
		this.statemenList();
	}

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mm30">
								<h2>청구명세서 관리</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.statemenList}>
										<span>조회</span>
									</a>
									<ExcelDown data={this.state.excelList} text="엑셀" filename={`청구명세서관리_${moment().format('YYYYMMDDHHmmss')}`} />
								</div>
							</div>

							<div className="writeType03">
								<table>
									<tbody>
										<tr>
											<th>등록일자</th>
											<td colSpan={4}>
												<DatePicker
													customInput={<input type="text" style={{ width: 115 }} />}
													dateFormat="YYYY-MM-DD"
													selectsStart
													selected={this.state.searchRegSDate}
													onChange={(date) => this.dateHandler(date, 'rs')}
													startDate={this.state.searchRegSDate}
													endDate={this.state.searchRegEDate}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
												<span style={{ marginLeft: 5, marginRight: 5 }}>~</span>
												<DatePicker
													customInput={<input type="text" style={{ width: 115 }} />}
													dateFormat="YYYY-MM-DD"
													selectsEnd
													selected={this.state.searchRegEDate}
													onChange={(date) => this.dateHandler(date, 're')}
													startDate={this.state.searchRegSDate}
													endDate={this.state.searchRegEDate}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
											</td>
											<th>청구일자</th>
											<td colSpan={4}>
												<DatePicker
													customInput={<input type="text" style={{ width: 115 }} />}
													dateFormat="YYYY-MM-DD"
													selectsStart
													selected={this.state.searchDmdSDate}
													onChange={(date) => this.dateHandler(date, 'ss')}
													startDate={this.state.searchDmdSDate}
													endDate={this.state.searchDmdEDate}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
												<span style={{ marginLeft: 5, marginRight: 5 }}>~</span>
												<DatePicker
													customInput={<input type="text" style={{ width: 115 }} />}
													dateFormat="YYYY-MM-DD"
													selectsEnd
													selected={this.state.searchDmdEDate}
													onChange={(date) => this.dateHandler(date, 'se')}
													startDate={this.state.searchDmdSDate}
													endDate={this.state.searchDmdEDate}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
											</td>
										</tr>
										<tr>
											<th style={{ width: '10%' }}>주문번호</th>
											<td>
												<input type="text" style={{width:'100px'}} value={this.state.searchOrdeNo} onChange={(e) => this.setState({ searchOrdeNo: e.target.value })} />
											</td>
											<th style={{ width: '10%' }}>계약번호</th>
											<td>
												<input type="text" style={{width:'100px'}} value={this.state.searchContNo} onChange={(e) => this.setState({ searchContNo: e.target.value })} />
											</td>
											<th style={{ width: '10%' }}>요청번호</th>
											<td>
												<input type="text" style={{width:'100px'}} value={this.state.searchCustReqNo} onChange={(e) => this.setState({ searchCustReqNo: e.target.value })} />
											</td>
											<th style={{ width: '10%' }}>자산번호</th>
											<td>
												<input type="text" style={{width:'100px'}} value={this.state.searchAsstNo} onChange={(e) => this.setState({ searchAsstNo: e.target.value })} />
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="writeType03">
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline" style={{display: 'inline'}}>청구 목록</p>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(ApiBillingStatementManager);
