import React, { Component } from 'react';
import { makeObservable, observable, action } from 'mobx';
import { observer } from 'mobx-react';

const KYContRenewalPopupStore = observable({
	open: false,
	newCustReqNo: '',
	oldCustReqNo: '',

	openPopup(newCustReqNo) {
		this.open = true;
		this.newCustReqNo = newCustReqNo;
	},
	closePopup(oldCustReqNo) {
		this.open = false;
		this.oldCustReqNo = '';
	},
	setCustReqNo(oldCustReqNo) {
		this.oldCustReqNo = oldCustReqNo;
	},
});

export default KYContRenewalPopupStore;
