import React, { Component } from 'react';
import ajLogo from '../images/h1Login.png';
import { userLogin, requestUserPassword, getCustImgTxt } from '../service/CommonService';
import { createCookie, readCookie, handleSessionStorage } from '../common/ComFunction';
import FindIdPw from './FindIdPw';
import RequestJoin from './RequestJoin';
import Banner from './include/Banner';

import { observer } from 'mobx-react';
import CommonStore from '../common/CommonStore';
const { ModalStore, ProgressStore } = CommonStore;

// 한국코퍼레이션 전용 로그인페이지
import { CUST_NO_HC } from '../common/ComValues';
class HC extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// login input
			loginId: '', //아이디
			loginPw: '', //패스워드
			saveIdYn: false, //아이디저장여부
			buttonEnable: false, //버튼enable

			// id/pw 찾기
			open: false, //id찾기_dialog
			openIdx: 0, //팝업_인덱스

			// 회원가입
			openJoin: false, //회원가입_dialog

			custNo: CUST_NO_HC, // 한국코퍼레이션 전용 로그인 페이지
			custNm: '한국코퍼레이션',
			custImg: '', // 이미지url
			custMainTxt: '', //문구
			userChg: '', //지원담당자
			userChgNm: '', //지원담당자명
			userChgTel: '', //지원담당자전화번호
		};
	}

	componentDidMount = () => {
		if (sessionStorage.accessToken) {
			// sessionStorage.clear();
			this.props.history.push('/Home', null);
		}
		let userId = readCookie('ajCisSaveUserID');
		this.setState({ loginId: userId });

		this.getCustImgTxt();
	};

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
		});
	};

	handleSubmit = () => {
		if (this.state.loginId === '') {
			ModalStore.openModal('아이디를 입력해 주세요.');
			return;
		}

		ProgressStore.activeProgress();

		let param = {
			username: this.state.loginId,
			password: this.state.loginPw,
		};

		this.setState({ buttonEnable: true });

		userLogin(param)
			.then((r) => {
				if (r.length > 0) {
					let userData = r[0];

					handleSessionStorage(userData, this.state.saveIdYn);

					ProgressStore.deactiveProgress();

					this.props.history.push('/Home', null);
				} else {
					ModalStore.openModal('등록된 정보가 없습니다.');
					this.setState({ buttonEnable: false });
					ProgressStore.deactiveProgress();
				}
			})
			.catch((err) => {
				alert(err);
				console.log(err);
				this.setState({ buttonEnable: false });
				ProgressStore.deactiveProgress();
			});
	};

	// 팝업
	handleClickOpen = (idx) => {
		this.setState({
			open: true,
			openIdx: idx,
		});
	};

	handleClickOpenJoin = () => {
		this.setState({
			openJoin: true,
		});
	};

	handleClose = () => {
		this.setState({
			open: false,
			openJoin: false,
		});
	};

	// 로그인페이지 이미지 주소와 문구 조회
	getCustImgTxt = () => {
		let param = {
			custNo: this.state.custNo,
		};

		getCustImgTxt(param).then((r) => {
			// console.log(r[0]);
			this.setState({
				custImg: r[0].custImg,
				custMainTxt: r[0].custMainTxt,
				userChg: r[0].userChg, //지원담당자
				userChgNm: r[0].userChgNm, //지원담당자명
				userChgTel: r[0].userChgTel, //지원담당자전화번호
			});
			sessionStorage.custImg = r[0].custImg;
		});
	};

	makeLogo = () => {
		if (this.state.custImg === '') {
			return (
				<div className="h1Logoin">
					<h1>
						<img src={ajLogo} alt="AJ렌탈" />
					</h1>
				</div>
			);
		} else {
			return (
				<div className="h1Logoin">
					<h1>
						<img src={this.state.custImg} alt={this.state.custNm} style={{ width: '250px', marginBottom: '-20px' }} />
					</h1>
				</div>
			);
		}
	};
	makeMainTxt = () => {
		if (this.state.userChg === '') {
			return <div className="tit">MEMBERS LOGIN</div>;
		} else {
			return <div className="tit">{this.state.custMainTxt}</div>;
		}
	};

	makeTelInfo = () => {
		if (this.state.custMainTxt === '') {
			return (
				<div className="info">
					<a href="tel:15880053">컨텍센터 1588 - 0053</a>
					<a href="http://www.ajnetworks.co.kr/" target="_blank">
						AJ 네트웍스 홈페이지 바로가기
					</a>
				</div>
			);
		} else {
			return (
				<div className="info">
					<a href={'tel:' + (this.state.userChgTel === '' || this.state.userChgTel === undefined ? '15880053' : this.state.userChgTel)}>
						[AJ문의전화] {this.state.userChgNm} : {this.state.userChgTel === '' || this.state.userChgTel === undefined ? '15880053' : this.state.userChgTel}
					</a>
					<a href="http://www.ajnetworks.co.kr/" target="_blank">
						AJ 네트웍스 홈페이지 바로가기
					</a>
				</div>
			);
		}
	};

	render() {
		return (
			<div>
				<div id="skipnavigation">
					<a href="#container">본문내용 바로가기</a>
				</div>

				<div className="wrap">
					<div className="loginWrap">
						{/* <div className="h1Logoin">
                            <h1><img src={ajLogo} alt="AJ렌탈"/></h1>
						</div> */}
						{this.makeLogo()}
						<div className="member">
							{/* <div className="tit">
                                MEMBERS LOGIN
							</div> */}
							{this.makeMainTxt()}
							<div className="inputWrap">
								<div className="box">
									<input
										type="text"
										id="loginId"
										name="loginId"
										placeholder="아이디를 입력해 주세요."
										value={this.state.loginId || ''}
										onChange={this.handleChange}
									/>
								</div>
								<div className="box">
									<input
										type="password"
										id="loginPw"
										name="loginPw"
										placeholder="비밀번호를 입력해 주세요."
										value={this.state.loginPw || ''}
										onChange={this.handleChange}
										onKeyDown={(e) => {
											if (e.key === 'Enter') this.handleSubmit();
										}}
									/>
								</div>
								<div className="baseCehck">
									<input type="checkbox" id="saveIdYn" name="saveIdYn" checked={this.state.saveIdYn} onChange={this.handleChange} />{' '}
									<label htmlFor="saveIdYn">아이디 저장</label>
								</div>
								<div className="btnLogin" onClick={this.handleSubmit}>
									<a href="javascript:;">로그인</a>
								</div>
								<div className="link">
									<a href="javascript:;" onClick={() => this.handleClickOpen(0)}>
										아이디 찾기
									</a>
									{/* /
                                    <a href="javascript:;" onClick={() => this.handleClickOpen(1)}>패스워드 찾기</a> */}
									<a href="javascript:;" onClick={this.handleClickOpenJoin}>
										회원가입
									</a>
								</div>
							</div>
						</div>
						{/* <div className="info">
                            <a href="tel:15880053">컨텍센터 1588 - 0053</a>
                            <a href="http://www.ajnetworks.co.kr/" target="_blank">AJ 네트웍스 홈페이지 바로가기</a>
						</div> */}
						{this.makeTelInfo()}

						{/* <div className="bannerList four">
                            <ul>
                                <li><a><img src="/images/thumb01.png" alt="베너"/></a></li>
                                <li><a><img src="/images/thumb01.png" alt="베너"/></a></li>
                                <li><a><img src="/images/thumb01.png" alt="베너"/></a></li>
                                <li><a><img src="/images/thumb01.png" alt="베너"/></a></li>
                            </ul>
                        </div> */}
						{/* 배너 */}
						<Banner />
					</div>
				</div>
				<RequestJoin open={this.state.openJoin} handleClose={this.handleClose} />
				<FindIdPw open={this.state.open} openIdx={this.state.openIdx} handleClickOpen={this.handleClickOpen} handleClose={this.handleClose} />
			</div>
		);
	}
}

export default observer(HC);
