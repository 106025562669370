import {httpGet,httpPost,httpPut,toQueryString} from '../Service';

// 주문조회
export function getMallOrderList(data){
    return httpGet('/api/v1.0/arm/admin/order/kt'+toQueryString(data));
}

// 결제처리
export function orderCardPayment(data){
    data = data || {};
    return httpPut('/api/v1.0/arm/admin/billing/card',{body:data});
}

// 현금영수증발행
export function cashReceiptPub(data){
    data = data || {};
    return httpPut('/api/v1.0/arm/admin/billing/cash',{body:data});
}

//유통계약생성
export function orderContDelivery(data){
    data = data || {};
    return httpPost('/api/v1.0/arm/admin/order/saleContract/',{body:data});
}

//유통계약생성 New (단건처리)
export function orderSaleContProc(data){
    data = data || {};
    return httpPost('/api/v1.0/arm/admin/order/saleContract/'+ data.ordeNo, {body:data});
}

//요청제품 리스트 조회
export function getContOrderItemList(ordeNo) {
	return httpGet('/api/v1.0/cis/admin/contract/items/' + ordeNo);
}

export function orderAndPayCancel(data){
    data = data || {};
    return httpPut('/api/v1.0/arm/admin/order/'+data.ordeNo, {body:data});
}

//빌링키 정기결제 리스트 조회
export function orderMonthPayList(data){
    data = data || {}
    return httpGet('/api/v1.0/arm/admin/order/pay'+toQueryString(data));
}

//정기 결제 대상자
export function getMallPaymentTargetList(data){
    data = data || {}
    return httpGet('/api/v1.0/arm/admin/order/payment-target'+toQueryString(data));
}