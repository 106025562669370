import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CheckIcon from '@material-ui/icons/HowToReg';
import { Transition } from '../../common/ComFunction';

import { observer } from 'mobx-react';
import ModalStore from '../../common/ModalStore';

class Modal extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div>
				<Dialog open={ModalStore.modalOpen} onClose={ModalStore.closeModal} TransitionComponent={Transition}>
					<div style={{ padding: '20px 20px 20px 20px' }}>
						<CheckIcon />
						<DialogContent>{ModalStore.modalText}</DialogContent>
						<DialogActions>
							<Button onClick={() => ModalStore.closeModal()} color="secondary" autoFocus>
								확인
							</Button>
						</DialogActions>
					</div>
				</Dialog>
			</div>
		);
	}
}
export default observer(Modal);
