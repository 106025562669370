import React, { Component } from 'react';
import { makeObservable, observable, action } from 'mobx';
import { observer } from 'mobx-react';

const ConfirmModalStore = observable({
	modalOpen: false,
	modalText: '',
	modalCallbackY: null,
	modalCallbackX: null,
	modalBtnTextY: '확인',
	modalBtnTextX: '취소',
	openModal(text, callbackY, callbackX, btnTextY, btnTextX) {
		this.clearField();
		this.modalOpen = true;
		if (text) this.modalText = text;
		if (callbackY) this.modalCallbackY = callbackY;
		if (callbackX) this.modalCallbackX = callbackX;
		if (btnTextY) this.modalBtnTextY = btnTextY;
		if (btnTextX) this.modalBtnTextX = btnTextX;
	},
	closeModal(modalType) {
		this.modalOpen = false;
		if (modalType === 'Y') {
			if (this.modalCallbackY) {
				this.modalCallbackY();
			}
		}
		if (modalType === 'X') {
			if (this.modalCallbackX) {
				this.modalCallbackX();
			}
		}
	},
	clearField() {
		this.modalOpen = false;
		this.modalText = '';
		this.modalCallbackY = null;
		this.modalCallbackX = null;
		this.modalBtnTextY = '확인';
		this.modalBtnTextX = '취소';
	},
});

export default ConfirmModalStore;
