import React, { Component } from 'react';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';
import { errorMsgCheck, resultToIbSheet, managerCheck } from '../../common/ComFunction';
import { getMenuList, saveMenu } from '../../service/admin/MenuService';
import Header from '../include/Header';
import { Button } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import { RadioGroup } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { Radio } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import Checkbox from '@material-ui/core/Checkbox';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore } = CommonStore;

var result = {
	//sheet데이터
	data: [],
};
const TREE = '2';

class MenuManager extends Component {
	constructor(props) {
		super(props);

		this.sheetData = {
			id: 'sheetMenuManager', // sheet id
			initData: 'admin/Init-MenuManager', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '600px', // sheet height
		};
	}

	state = {
		treeControl: TREE, //트리제어
		systCode: sessionStorage.systCode, //시스템코드

		//조회결과
		menuList: [], //메뉴리스트

		//입력항목
		menuCode: '', //메뉴코드
		rootMenu: '', //루트메뉴
		upprMenu: '', //상위메뉴
		menuName: '', //메뉴명
		level: '', //메뉴레벨
		menuRank: '', //메뉴순번
		menuPath: '', //경로
		menuLine: '', //메뉴라인
		delYn: '', //삭제여부
		mobileUseYn: false, //모바일에서 사용여부
		mobileOnly: false, //모바일 전용 메뉴

		chgYn: false, //입력변경여부
		tranType: 'I', //transaction타입(U:수정 / I:추가 / D:삭제)
	};
	handleChangeSearch = (e) => {
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
		});
	};
	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
			chgYn: true, // 변경일어남
		});
	};
	handleChangeNum = (e) => {
		// 숫자만 입력받도록
		const value = e.target.value.replace(/\D/, '');
		this.setState({
			[e.target.name]: value,
			chgYn: true, // 변경일어남
		});
	};
	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
	};
	componentDidMount = () => {
		managerCheck(); // 관리자체크
		this.getMenuList();
	};

	resetState = (type) => {
		if (type === 'A') {
			// 전체 초기화
			this.setState({
				treeControl: TREE, //트리제어
				menuList: [], //메뉴리스트

				tranType: 'U', //transaction타입(U:수정 / I:추가 / D:삭제)
			});
			this.resetResult(); //ibsheet 데이터 리셋
		} else if (type === 'I') {
			// 신규입력상태
			this.setState({
				tranType: 'I', //transaction타입(U:수정 / I:추가 / D:삭제)
			});
		} else if (type === 'U') {
			// 수정가능상태
			this.setState({
				tranType: 'U', //transaction타입(U:수정 / I:추가 / D:삭제)
			});
		}
		this.setState({
			//입력항목
			menuCode: '', //메뉴코드
			rootMenu: '', //루트메뉴
			upprMenu: '', //상위메뉴
			menuName: '', //메뉴명
			level: '', //메뉴레벨
			menuRank: '', //메뉴순번
			menuPath: '', //경로
			menuLine: '', //메뉴라인
			delYn: '', //삭제여부
			chgYn: false, //입력변경여부
			mobileUseYn: false, //모바일사용메뉴
			mobileOnly: false, //모바일전용
		});
	};
	//메뉴리스트 조회
	getMenuList = () => {
		// if(this.state.custNo === ''){
		//     // alert('검색어를 입력하셔야합니다.');
		//     this.props.modalOpen('A','검색어를 입력하셔야합니다.');
		//     this.custNm.focus();
		//     return;
		// }

		ProgressStore.activeProgress();
		let param = {
			systCode: this.state.systCode,
		};

		getMenuList(param)
			.then((r) => {
				this.resetResult(); //ibsheet 데이터 리셋

				this.setState({ menuList: r }, () => {
					// JSON객체형태로 서버에서 받으므로 IBsheet에서 출력 가능한 배열형태로 변환
					window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				});

				// this.setState({ treeControl: TREE }, () => {
				// 	window[this.sheetData.id].ShowTreeLevel(this.state.treeControl - 1);
				// });
				this.resetState('I');
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};
	// sheet데이터 리셋
	resetResult = () => {
		result = {
			data: [],
		};
		window[this.sheetData.id].LoadSearchData(result, {
			Sync: 1,
		});
	};

	// 조회된 결과 중 선택한 input에 셋팅
	setInput = () => {
		let key = window.getSelectedKey();
		if (key !== undefined) {
			this.state.menuList.map((item, idx) => {
				if (item.menuCode === key) {
					this.setState({
						menuCode: item.menuCode, //메뉴코드
						rootMenu: item.rootMenu, //루트메뉴
						upprMenu: item.upprMenu, //상위메뉴
						menuName: item.menuName, //메뉴명
						level: item.level, //메뉴레벨
						menuRank: item.menuRank, //메뉴순번
						menuPath: item.menuPath, //경로
						menuLine: item.menuLine, //메뉴라인
						delYn: item.delYn, //삭제여부
						mobileUseYn: item.mobileUse === 'Y' ? true : false,
						mobileOnly: item.mobileOnly === 'Y' ? true : false,

						chgYn: false, //입력변경여부
						tranType: 'U', //transaction타입 수정
					});
				}
			});
		}
	};

	// 추가할 수 있도록 readonly 풀고 state 리셋 후 추가상태로 셋팅
	setForAdd = (type) => {
		if (this.state.menuCode === '') {
			ModalStore.openModal('메뉴를 먼저 선택하세요.');
			return;
		}

		let menuLine = '';
		let findRow = window.getClickedRow();

		if (type === 0) {
			this.resetState('I'); //입력가능상태
			if (findRow == 0) {
				//동일레벨
				this.setState({ level: '0' });
			} else {
				if (window[this.sheetData.id].GetCellValue(findRow, 'level') === '0') {
					this.setState({ level: '0' });
				} else {
					menuLine = window[this.sheetData.id]
						.GetCellValue(findRow, 'menuLine')
						.substring(0, window[this.sheetData.id].GetCellValue(findRow, 'menuLine').lastIndexOf(','));
					this.setState({
						level: window[this.sheetData.id].GetCellValue(findRow, 'level'),
						upprMenu: window[this.sheetData.id].GetCellValue(findRow, 'upprMenu'),
						menuLine: menuLine + ',',
						rootMenu: window[this.sheetData.id].GetCellValue(findRow, 'rootMenu'),
					});
				}
			}
		} else if (type === 1) {
			//하위레벨
			let level = window[this.sheetData.id].GetCellValue(findRow, 'level');
			let child = parseInt(window[this.sheetData.id].GetCellValue(findRow, 'level'), 10) + 1;

			// 메뉴는 2depth이므로 level이 1이면 하위생성 불가
			if (level === '2') {
				ModalStore.openModal('메뉴는 3단을 초과할 수 없습니다.');
				return;
			}
			this.resetState('I'); //입력가능상태
			this.setState({
				level: child,
				upprMenu: window[this.sheetData.id].GetCellValue(findRow, 'menuCode'),
			});
			if (level == '0') {
				this.setState({ rootMenu: window[this.sheetData.id].GetCellValue(findRow, 'menuCode') });
			} else if (level == '1') {
				this.setState({ rootMenu: window[this.sheetData.id].GetCellValue(findRow, 'rootMenu') });
			} else if (level == '2') {
				this.setState({ rootMenu: window[this.sheetData.id].GetCellValue(findRow, 'rootMenu') });
			}
			this.setState({ menuLine: window[this.sheetData.id].GetCellValue(findRow, 'menuLine') + ',' });
		} else {
			return;
		}

		this.menuName.focus();
	};

	// 저장
	saveMenu = () => {
		const { menuCode, rootMenu, upprMenu, menuName, level, menuRank, menuPath, menuLine, delYn, chgYn, tranType, systCode, mobileUseYn, mobileOnly } =
			this.state;

		//validation chk
		// if (tranType === 'U' && !chgYn) {
		// 	// 수정상태에서 변경 없을 시
		// 	ModalStore.openModal('변경사항이 없습니다.');
		// 	return;
		// }
		if (menuName === '') {
			ModalStore.openModal('메뉴명을 입력하세요.');
			this.menuName.focus();
			return;
		}
		if (menuRank === '') {
			ModalStore.openModal('순번을 입력하세요.');
			this.menuRank.focus();
			return;
		}
		// validation chk end
		ProgressStore.activeProgress();

		let param = {
			menuCode: menuCode, //메뉴코드
			rootMenu: rootMenu, //루트메뉴
			upprMenu: upprMenu, //상위메뉴
			menuName: menuName, //메뉴명
			level: level, //메뉴레벨
			menuRank: menuRank, //메뉴순번
			menuPath: menuPath, //경로
			menuLine: menuLine, //메뉴라인
			systCode: systCode, //시스템코드
			delYn: delYn, //삭제여부
			tranType: tranType, //transaction타입(U:수정 / I:추가 / D:삭제)
			mobileUse: mobileUseYn ? 'Y' : 'N', //모바일사용메뉴
			mobileOnly: mobileOnly ? 'Y' : 'N', //모바일전용메뉴
		};

		saveMenu(param)
			.then((r) => {
				// console.log(r);console.log(r.length);console.log(r[0]);
				ModalStore.openModal('저장되었습니다.');

				// this.resetState();
				this.resetResult(); //ibsheet 데이터 리셋
				this.getMenuList(); //저장 성공 후 재조회
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};

	// 삭제
	callDelMenu = () => {
		if (this.state.menuCode === '') {
			ModalStore.openModal('메뉴를 먼저 선택하세요.');
			return;
		}
		let cnt = 0;
		this.state.menuList.map((item, idx) => {
			if (item.menuLine.indexOf(this.state.menuCode) > -1) {
				cnt++;
			}
		});
		if (cnt > 1) {
			ConfirmModalStore.openModal('선택한 메뉴의 하위메뉴가 존재합니다. 하위메뉴를 포함하여 삭제하시겠습니까?', this.deleteMenu);
		} else {
			ConfirmModalStore.openModal('메뉴를 삭제하시겠습니까?', this.deleteMenu);
		}
	};
	deleteMenu = () => {
		const { menuCode } = this.state;

		ProgressStore.activeProgress();

		let param = {
			menuCode: menuCode, //메뉴코드
			delYn: 'Y', //삭제
			tranType: 'D', //transaction 타입
		};

		saveMenu(param)
			.then((r) => {
				// console.log(r);console.log(r.length);console.log(r[0]);
				ModalStore.openModal('삭제되었습니다.');

				// this.resetState();
				this.resetResult(); //ibsheet 데이터 리셋
				this.getMenuList(); //저장 성공 후 재조회
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};

	chgSystCode = (systCode) => {
		if (systCode === 'CIS') {
			this.setState({ treeControl: '2' }, () => {
				this.getMenuList();
			});
			window[this.sheetData.id].ShowTreeLevel(1);
		} else {
			this.setState({ treeControl: '3' }, () => {
				this.getMenuList();
			});
			window[this.sheetData.id].ShowTreeLevel(2);
		}
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mb30">
								<h2>메뉴관리</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.getMenuList}>
										<span>조회</span>
									</a>
									<a className="btn03 add" onClick={() => this.setForAdd(0)}>
										<span>동일레벨</span>
									</a>
									<a className="btn03 add" onClick={() => this.setForAdd(1)}>
										<span>하위레벨</span>
									</a>
									{/* <a className="btn01 save"><span>저장</span></a> */}
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '15%' }} />
										<col style={{ width: '20%' }} />
										<col style={{ width: '15%' }} />
										<col style={{ width: '50%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>시스템</th>
											<td>
												<select
													name="systCode"
													value={this.state.systCode || ''}
													onChange={(e) => {
														this.handleChangeSearch(e);
														this.resetState('A');
														this.chgSystCode(e.target.value);
													}}
													ref={(ref) => (this.systCode = ref)}
												>
													<option value="CIS">고객발주시스템</option>
													<option value="ARM">렌탈몰</option>
												</select>
											</td>
											<th>트리제어</th>
											<td>
												<FormControl component="fieldset" style={{ margin: '-11px 0px' }}>
													{/* <FormLabel component="legend">Gender</FormLabel> */}
													<RadioGroup
														aria-label="treeControl"
														name="treeControl"
														value={this.state.treeControl}
														onChange={(e) => {
															this.handleChange(e);
															window[this.sheetData.id].ShowTreeLevel(e.target.value - 1);
														}}
														row
													>
														<FormControlLabel value="1" control={<Radio />} label="1" />
														<FormControlLabel value="2" control={<Radio />} label="2" />
														<FormControlLabel value="3" control={<Radio />} label="3" />
													</RadioGroup>
												</FormControl>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="writeType05 long">
								<div className="left" onClick={this.setInput}>
									<div
										className="scroll"
										onMouseOver={() => {
											document.body.style.overflow = 'hidden';
										}}
										onMouseOut={() => {
											document.body.style.overflow = 'auto';
										}}
									>
										<p className="headline">메뉴 리스트</p>
										<IBSheet {...this.sheetData} />
									</div>
								</div>
								<div className="right">
									<p className="headline">메뉴 정보</p>
									<table>
										<colgroup>
											<col style={{ width: '20%' }} />
											<col style={{ width: '30%' }} />
											<col style={{ width: '20%' }} />
											<col style={{ width: '30%' }} />
										</colgroup>
										<tbody>
											<tr>
												<th>
													메뉴명 <span className="star">*</span>
												</th>
												<td colSpan="3">
													<input
														type="text"
														className="size01"
														name="menuName"
														maxLength="25"
														value={this.state.menuName || ''}
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.menuName = ref)}
													/>
												</td>
											</tr>
											<tr>
												<th>
													순번 <span className="star">*</span>
												</th>
												<td colSpan="3">
													<input
														type="text"
														className="size04"
														name="menuRank"
														maxLength="2"
														value={this.state.menuRank || ''}
														onChange={(e) => {
															this.handleChangeNum(e);
														}}
														ref={(ref) => (this.menuRank = ref)}
													/>
												</td>
											</tr>
											<tr>
												<th>경로 </th>
												<td colSpan="3">
													<input
														type="text"
														name="menuPath"
														maxLength="100"
														value={this.state.menuPath || ''}
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.menuPath = ref)}
													/>
												</td>
											</tr>
											<tr>
												<th>메뉴코드 </th>
												<td>
													<input
														type="text"
														name="menuCode"
														maxLength="7"
														value={this.state.menuCode || ''}
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.menuCode = ref)}
														readOnly
													/>
												</td>
												<th>상위코드 </th>
												<td>
													<input
														type="text"
														name="upprMenu"
														maxLength="7"
														value={this.state.upprMenu || ''}
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.upprMenu = ref)}
														readOnly
													/>
												</td>
											</tr>
											<tr>
												<th>루트메뉴 </th>
												<td>
													<input
														type="text"
														name="rootMenu"
														maxLength="7"
														value={this.state.rootMenu || ''}
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.rootMenu = ref)}
														readOnly
													/>
												</td>
												<th>
													메뉴레벨 <span className="star">*</span>
												</th>
												<td>
													<input
														type="text"
														name="level"
														maxLength="2"
														value={this.state.level || ''}
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.level = ref)}
														readOnly
													/>
												</td>
											</tr>
											<tr>
												<th>메뉴라인 </th>
												<td colSpan="3">
													<input
														type="text"
														className="size01"
														name="menuLine"
														maxLength="100"
														value={this.state.menuLine || ''}
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.menuLine = ref)}
														readOnly
													/>
												</td>
											</tr>
											<tr>
												<th>
													모바일
													<br />
													발주시스템
												</th>
												<td colSpan="3">
													<Checkbox
														checked={this.state.mobileUseYn}
														onClick={() => this.setState({ mobileUseYn: !this.state.mobileUseYn })}
														style={{ color: '#3f51b5' }}
													/>
													모바일사용
													<Checkbox
														checked={this.state.mobileOnly}
														onClick={() => this.setState({ mobileOnly: !this.state.mobileOnly })}
														style={{ color: '#f50057', marginLeft: 15 }}
													/>
													모바일전용
												</td>
											</tr>
										</tbody>
									</table>
									<div className="btnRight2">
										<Button className="button" onClick={this.saveMenu} color="primary" variant="contained" size="small">
											<SaveIcon className="saveIcon" />
											<span className="text">저장</span>
										</Button>
										<Button className="button" onClick={this.callDelMenu} color="secondary" variant="contained" size="small">
											<DeleteIcon className="saveIcon" />
											<span className="text">삭제</span>
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(MenuManager);
