import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';

import Tooltip from '@material-ui/core/Tooltip';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailIcon from '@material-ui/icons/Assignment';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import Menu from '@material-ui/core/Menu';

import { dateToFormat, errorMsgCheck, resultToIbSheet, excelDown, getCustomCheck } from '../../common/ComFunction';

import {
	getOrderList,
	getOrderDetail,
	getOrderItemList,
	getOrderAppStatus,
	getOrderDeliveryStatus,
	cancelRentOrder,
	getOrderListForExcel,
} from '../../service/rental/RentalOrderService';
import { getCisCommCode, getCustomData } from '../../service/CommonService';
import ExcelDown from '../include/ExcelDown';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore, UserPopupStore, RentalOrderPopStore } = CommonStore;

import { CUST_NO_AJ, CUST_NO_DDI } from '../../common/ComValues';

class RentalOrderSearch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			startDate: moment(),
			endDate: moment(),

			// sCustNo: sessionStorage.custNo !== CUST_NO_AJ ? sessionStorage.custNo : '',
			// sCustNm: sessionStorage.custNo !== CUST_NO_AJ ? sessionStorage.custNm : '',
			// sUserNm: sessionStorage.custNo !== CUST_NO_AJ ? sessionStorage.userName : '',
			// sUserId: sessionStorage.custNo !== CUST_NO_AJ ? sessionStorage.userId : '',
			sCustNo: sessionStorage.custNo,
			sCustNm: sessionStorage.custNm,
			sUserNm: sessionStorage.userName,
			sUserId: sessionStorage.userId,
			sOrdeNo: '',
			sOrdeStatus: '',

			// expanded: '1',
			custNo: '', //고객번호
			ordeNo: '', //요청번호
			ordeStatus: '', //요청상태
			delSpotId: '', //지점코드
			delSpotNm: '', //지점명
			delDeptId: '', //수령인부서코드(대리점코드)
			delDeptNm: '', //수령인부서명
			cancelRemark: '', //취소사유

			//취소정보
			cancelUser: '', //취소자
			cancelUserRemark: '', //취소사유

			updateDisabled: true,
			cancelDisabled: true,
			//결재정보
			appList: [],

			//배송정보
			delList: [],
			delStatus: '', //공통코드(07) 11:접수완료, 12:출고접수, 13:출고완료, 14:배송중, 15: 배송완료
			activeStep: '',

			//취소사유
			anchorEl: null,

			//excel
			excelList: [], //엑셀다운로드용 리스트
		};

		this.sheetData = {
			id: 'orderSearchSheet', // sheet id
			initData: 'rental/Init-RentalOrderSearch', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '587px', // sheet height
		};

		this.sheetData2 = {
			id: 'rentalOrderItemSearch', // sheet id
			initData: 'rental/Init-RentalOrderItemSearch', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '150px', // sheet height
		};
	}

	componentDidMount = () => {
		// aj인경우 공백으로 넣기
		getCustomData({ custNo: sessionStorage.custNo, customCode: 'CT012' }).then((r) => {
			if (r.length > 0) {
				this.setState({
					sCustNo: '',
					sCustNm: '',
					sUserNm: '',
					sUserId: '',
				});
			}
		});

		getCisCommCode('07').then((r) => {
			this.setState({
				delList: r,
			});
		});
	};

	componentWillUnmount = () => {
		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}
		if (window[this.sheetData2.id]) {
			window[this.sheetData2.id].DisposeSheet();
		}
	};

	dateHandler = (date, type) => {
		if (type === 's') {
			this.setState({ startDate: date });
		} else {
			this.setState({ endDate: date });
		}
	};

	searchCustomer = () => {
		//관리자만 조회할 수 있다.
		//if(sessionStorage.authCode <= 1){
		CustSearchPopupStore.openPopup('1', null, null, this.handleCustSearch);
		//}
	};

	searchUser = () => {
		UserPopupStore.openPopup(null, sessionStorage.authCode > 1 ? sessionStorage.custNo : '', this.handleUserSearch);
	};

	handleCustSearch = (item) => {
		if (item !== undefined) {
			this.setState({
				sCustNo: item.custNo,
				sCustNm: item.custNm,
			});
		}
	};

	handleUserSearch = (item) => {
		if (item !== undefined) {
			this.setState({
				sUserId: item.userId,
				sUserNm: item.userName,
			});
		}
	};

	getSearchParam = () => {
		// 대표고객이면서 고객관리자인 경우 현재 대표고객으로 조회시 하위 고객사까지 조회 가능하다
		var repYn = '';
		if (sessionStorage.custNo === sessionStorage.repCustNo && sessionStorage.custNo === this.state.sCustNo && sessionStorage.authCode === '2') {
			repYn = 'Y';
		} else {
			repYn = 'N';
		}

		let param = {
			custNo: this.state.sCustNo,
			userId: this.state.sUserId,
			ordeStatus: this.state.sOrdeStatus,
			startDate: dateToFormat(this.state.startDate),
			endDate: dateToFormat(this.state.endDate),
			ordeNo: this.state.sOrdeNo,
			repYn: repYn, // 대표고객이 하위 고객사 전체 조회 가능함 20190716 kkj
		};

		return param;
	};

	searchList = () => {
		let param = this.getSearchParam();

		ProgressStore.activeProgress();

		getOrderList(param)
			.then((r) => {
				window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));

				this.setState({
					custNo: '', //고객번호
					ordeNo: '',
					appList: [],
					delStatus: '',
					activeStep: '',
				});
				//인풋필드 초기화
				this.setEmptyInput();
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	searchExcelList = () => {
		let param = this.getSearchParam();

		ProgressStore.activeProgress();

		getOrderListForExcel(param)
			.then((r) => {
				// console.log(r);
				if (r.length === 0) {
					ModalStore.openModal('검색된 내용이 없습니다.');
				} else {
					excelDown(
						this.makeExcelData(r),
						(this.state.sCustNm
							? `${this.state.sCustNm}(${dateToFormat(this.state.startDate)}~${dateToFormat(this.state.endDate)})`
							: `(${dateToFormat(this.state.startDate)}~${dateToFormat(this.state.endDate)})`) + '_요청내역리스트.csv',
					);
				}
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	makeExcelData = (data) => {
		let tmpList = [];
		let tmp = {};
		data.map((item, idx) => {
			tmp = {};
			tmp.No = idx + 1;
			tmp.요청번호 = item.ordeNo;
			tmp.계약번호 = item.contNo;
			tmp.고객번호 = item.custNo;
			tmp.고객명 = item.custNm;
			tmp.배송요청일 = dateToFormat(item.delAskDate);
			tmp.신청일 = dateToFormat(item.instDate);
			tmp.신청자ID = item.rqstId;
			tmp.신청자명 = item.applyName;
			tmp.신청목적 = item.ordePrpsNm;
			tmp.지점코드 = item.spotId;
			tmp.지점명 = item.spotNm;
			tmp.배송방법 = item.delMethodNm;
			tmp.진행상태 = item.ordeStatusNm;
			tmp.배송상태 = item.delStatusNm;
			tmp.사용자 = item.userName;
			tmp.사용부서 = item.userDeptName;
			tmp.요청비고 = item.ordeRmk;
			tmp.신청비고 = item.delAskRmk;
			tmp.사용자사번 = item.userEmpno;
			tmp.사용자비고 = item.userRemark;
			tmp.모델 = item.model;
			tmp.수량 = item.itemQty;
			tmpList.push(tmp);
		});
		// this.setState({ excelList: tmpList });

		return tmpList;
	};

	getOrdeDetailInfo = () => {
		if (window[this.sheetData.id].LastRow() === 0) return;

		let orderData = window.getSelectData();
		if (orderData.ordeNo === '') return;

		this.setState({
			ordeNo: orderData.ordeNo,
		});
		ProgressStore.activeProgress();
		Promise.all([
			getOrderDetail(orderData.ordeNo),
			getOrderItemList(orderData.ordeNo),
			getOrderAppStatus(orderData.ordeNo),
			getOrderDeliveryStatus(orderData.ordeNo),
		])
			.then((r) => {
				let [detailData, orderItemData, appData, delData] = r;

				//상세(지점정보)
				this.setDetailInfo(detailData[0]);
				//신청제품 리스트
				window[this.sheetData2.id].LoadSearchData(resultToIbSheet(orderItemData));
				this.setState({
					appList: appData,
					delStatus: delData[0].ordeStatus,
					// activeStep: delData[0].ordeStatus === '15' ? delData[0].ordeSeq + 1 : !delData[0].ordeSeq ? '' : delData[0].ordeSeq,
					activeStep: delData[0].ordeStatus ? delData[0].ordeStatus - 9 : '',
				});
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	setDetailInfo = async (data) => {
		if (data !== null) {
			this.setState({
				custNo: data.custNo,
				ordeNo: data.ordeNo,
				ordeStatus: data.ordeStatus,
				delSpotId: data.spotId,
				delSpotNm: data.spotNm,
				delDeptId: data.deptId,
				delDeptNm: data.delDept,
				cancelUser: data.cancelUser,
				cancelUserRemark: data.ordeCancelRmk,
				updateDisabled:
					data.ordeStatus !== '2' && data.ordeStatus < 4 && (data.rqstId === sessionStorage.userId || sessionStorage.authCode === '2') ? false : true,
				cancelDisabled: data.ordeStatus !== '2' && data.ordeStatus < 4 && data.rqstId === sessionStorage.userId ? false : true,
			});

			var customData = JSON.parse(sessionStorage.getItem('customData'));

			// 두산디지털이노베이션은 결재완료건 수정불가
			if (customData != null && customData.hasOwnProperty('CT014')) {
				this.setState({
					updateDisabled:
						data.ordeStatus !== '2' && data.ordeStatus < 3 && (data.rqstId === sessionStorage.userId || sessionStorage.authCode === '2') ? false : true,
				});
			}
		}
	};

	setEmptyInput = () => {
		this.setState({
			custNo: '', //고객번호
			ordeNo: '',
			ordeStatus: '',
			delSpotId: '', //지점코드
			delSpotNm: '', //지점명
			delDeptId: '', //수령인부서코드(대리점코드)
			delDeptNm: '', //수령인부서명
			cancelUser: '',
			cancelUserRemark: '',
			updateDisabled: true,
			cancelDisabled: true,
		});

		window[this.sheetData2.id].RemoveAll();
	};

	goOrderUpdate = () => {
		ConfirmModalStore.openModal('수정 페이지로 이동 하시겠습니까?', () => {
			this.props.history.push('/RentalOrder/' + this.state.ordeNo, null);
			sessionStorage.activeSubPageId = '0000003';
		});
	};

	orderCancelPop = (e) => {
		this.setState({ anchorEl: e.currentTarget });
		var timer = setInterval(() => {
			if (this.fv !== null && this.fv !== undefined) {
				this.fv.focus();
				clearInterval(timer);
			}
		}, 100);
	};

	orderCancel = () => {
		//팝업닫기
		this.orderCancelPopClose();

		ConfirmModalStore.openModal('취소 처리 하시겠습니까?', () => {
			let param = {
				ordeNo: this.state.ordeNo,
				cancelRemark: this.state.cancelRemark,
			};
			cancelRentOrder(param).then(() => {
				ModalStore.openModal('완료 되었습니다.');

				this.setState({
					cancelRemark: '',
				});
				//재조회
				this.searchList();
			});
		});
	};

	orderCancelPopClose = () => {
		this.setState({
			anchorEl: null,
		});
	};

	goOrderDetail = () => {
		if (!this.state.ordeNo) {
			ModalStore.openModal('요청 리스트를 조회하여 요청번호를 선택해 주세요.');
			return;
		}

		RentalOrderPopStore.openPopup(this.state.ordeNo);
		sessionStorage.activeSubPageId = '0000003';
	};

	render() {
		const dateInput = <input type="text" style={{ width: 120 }} />;

		let appInfoStyle = {
			textAlign: 'center',
			border: 0,
		};

		const detailInputStyle = {
			border: 0,
		};

		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont" style={{ height: 1200 }}>
							<div className="h2Wrap mb30">
								<h2>신청조회</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.searchList}>
										<span>조회</span>
									</a>
									<a className="btn03 save" onClick={() => this.searchExcelList()}>
										<span>엑셀</span>
									</a>
									{/* <ExcelDown modalOpen={ModalStore.openModal} data={this.state.excelList} text="엑셀" filename={this.state.sCustNm + '_자산리스트.csv'} /> */}
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '90px' }} />
										<col style={{ width: '280px' }} />
										<col style={{ width: '90px' }} />
										<col style={{ width: '140px' }} />
										<col style={{ width: '90px' }} />
										<col style={{ width: '120px' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>고객명</th>
											<td>
												<input type="text" readOnly={true} style={{ marginRight: 10, width: 235 }} value={this.state.sCustNm} />
												<IconButton onClick={this.searchCustomer} color="secondary" className="iconButton" style={{ display: sessionStorage.btnControl }}>
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
											<th>신청자</th>
											<td colSpan="3">
												<input
													type="text"
													style={{ width: 100, marginRight: 10 }}
													value={this.state.sUserId}
													onChange={(e) => this.setState({ sUserId: e.target.value })}
												/>
												<IconButton onClick={this.searchUser} color="secondary" className="iconButton">
													<SearchIcon fontSize="small" />
												</IconButton>
												<input
													type="text"
													style={{ width: 80, marginLeft: 10 }}
													value={this.state.sUserNm}
													onChange={(e) => this.setState({ sUserNm: e.target.value })}
												/>
											</td>
										</tr>
										<tr>
											<th>신청일자</th>
											<td>
												<DatePicker
													customInput={dateInput}
													dateFormat="YYYY-MM-DD"
													selectsStart
													selected={this.state.startDate}
													onChange={(date) => this.dateHandler(date, 's')}
													startDate={this.state.startDate}
													endDate={this.state.endDate}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
												<span style={{ marginLeft: 10, marginRight: 10 }}>~</span>
												<DatePicker
													customInput={dateInput}
													dateFormat="YYYY-MM-DD"
													selectsEnd
													selected={this.state.endDate}
													onChange={(date) => this.dateHandler(date, 'e')}
													startDate={this.state.startDate}
													endDate={this.state.endDate}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
											</td>
											<th>요청번호</th>
											<td>
												<input type="text" style={{ width: 130 }} value={this.state.sOrdeNo} onChange={(e) => this.setState({ sOrdeNo: e.target.value })} />
											</td>
											<th>진행상태</th>
											<td>
												{/* 0 : 접수대기중 , 1 : 결재중 , 2 : 반려, 3 : 결재완료, 9 : 신청취소 */}
												<select defaultValue={''} onChange={(e) => this.setState({ sOrdeStatus: e.target.value })}>
													<option value="">전체</option>
													<option value="0">접수대기중</option>
													<option value="1">결재중</option>
													<option value="2">반려</option>
													<option value="3">결재완료(미계약)</option>
													<option value="4">계약완료</option>
													<option value="9">신청취소</option>
												</select>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="writeType03" style={{ float: 'left', width: '35%' }} onClick={this.getOrdeDetailInfo}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">신청리스트</p>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
							<div style={{ float: 'right', width: '60%', height: '600px' }}>
								<div className="writeType03">
									<p className="headline">{sessionStorage.spotTitle}정보</p>
									<table>
										<colgroup>
											<col style={{ width: 80 }} />
											<col style={{ width: 120 }} />
											<col style={{ width: 80 }} />
											<col style={{ width: 120 }} />
										</colgroup>
										<tbody>
											<tr>
												<th>{sessionStorage.spotTitle}코드</th>
												<td>
													<input type="text" readOnly={true} value={this.state.delSpotId} style={detailInputStyle} />
												</td>
												<th>{sessionStorage.spotTitle}명</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.delSpotNm} style={detailInputStyle} />
												</td>
											</tr>
											<tr>
												<th>{sessionStorage.deptTitle}코드</th>
												<td>
													<input
														type="text"
														readOnly={true}
														onChange={(e) => this.setState({ delDeptId: e.target.value })}
														value={this.state.delDeptId}
														style={detailInputStyle}
													/>
												</td>
												<th>{sessionStorage.deptTitle}명</th>
												<td colSpan="3">
													<input
														type="text"
														readOnly={true}
														onChange={(e) => this.setState({ delDeptNm: e.target.value })}
														value={this.state.delDeptNm}
														style={detailInputStyle}
													/>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div className="writeType03" style={{ display: this.state.ordeStatus === '9' ? 'block' : 'none' }}>
									<p className="headline">신청취소</p>
									<table>
										<colgroup>
											<col style={{ width: 80 }} />
											<col style={{ width: 120 }} />
											<col style={{ width: 80 }} />
											<col style={{ width: 120 }} />
										</colgroup>
										<tbody>
											<tr>
												<th>취소자</th>
												<td>
													<input type="text" readOnly={true} value={this.state.cancelUser} style={{ border: 0 }} />
												</td>
												<th>취소사유</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.cancelUserRemark} style={{ border: 0 }} />
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div className="writeType03" style={{ display: this.state.ordeStatus !== '9' ? 'block' : 'none' }}>
									<div
										className="scroll"
										onMouseOver={() => {
											document.body.style.overflow = 'hidden';
										}}
										onMouseOut={() => {
											document.body.style.overflow = 'auto';
										}}
									>
										<p className="headline">제품정보</p>
										<IBSheet {...this.sheetData2} />
									</div>
									<div style={{ marginTop: 5, textAlign: 'right' }}>
										<Button color="primary" variant="contained" size="small" disabled={this.state.updateDisabled} onClick={this.goOrderUpdate}>
											<EditIcon style={{ fontSize: 20, marginRight: 5 }} />
											제품 및 배송정보 수정
										</Button>
										<Button
											color="secondary"
											variant="contained"
											size="small"
											disabled={this.state.cancelDisabled}
											onClick={this.orderCancelPop}
											style={{ marginLeft: 10 }}
										>
											<DeleteIcon style={{ fontSize: 20, marginRight: 5 }} />
											신청취소
										</Button>
										<Button
											variant="contained"
											size="small"
											style={{ marginLeft: 10 }}
											disabled={this.state.ordeNo ? false : true}
											onClick={this.goOrderDetail}
										>
											<DetailIcon style={{ fontSize: 20, marginRight: 5 }} />
											상세보기
										</Button>
									</div>
								</div>

								{sessionStorage.appUse === 'Y' ? (
									<div className="writeType03">
										<p className="headline">결재정보</p>
										<table>
											<colgroup>
												<col style={{ width: '70px' }} />
												<col style={{ width: '90px' }} />
												<col style={{ width: '70px' }} />
												<col style={{ width: '110px' }} />
												<col style={{ width: '200px' }} />
											</colgroup>
											<tbody>
												<tr style={{ height: 40 }}>
													<th style={{ textAlign: 'center' }}>결재순서</th>
													<th style={{ textAlign: 'center' }}>결재자</th>
													<th style={{ textAlign: 'center' }}>결재여부</th>
													<th style={{ textAlign: 'center' }}>결재일자</th>
													<th style={{ textAlign: 'center' }}>결재비고</th>
												</tr>
												{this.state.appList.length > 0 ? (
													this.state.appList.map((item, i) => (
														<tr style={{ textAlign: 'center' }}>
															<td>
																<input type="text" className="txtCenter" value={i + 1} readOnly={true} style={appInfoStyle} />
															</td>
															<td>
																<input type="text" className="txtCenter" value={item.userName} readOnly={true} style={appInfoStyle} />
															</td>
															<td>
																<input type="text" className="txtCenter" value={item.appYn} readOnly={true} style={appInfoStyle} />
															</td>
															<td>
																<input
																	type="text"
																	className="txtCenter"
																	value={item.appDate ? dateToFormat(item.appDate) : ''}
																	readOnly={true}
																	style={appInfoStyle}
																/>
															</td>
															<td>
																<Tooltip title={item.appDesc} placement="top">
																	<input type="text" className="txtCenter" value={item.appDesc} readOnly={true} style={appInfoStyle} />
																</Tooltip>
															</td>
														</tr>
													))
												) : (
													<tr style={{ textAlign: 'center' }}>
														<td colSpan="5">등록된 결재 정보가 없습니다.</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>
								) : (
									''
								)}
								<div className="writeType03" style={{ display: this.state.delStatus ? 'block' : 'none' }}>
									<p className="headline">배송정보</p>
									<Stepper activeStep={this.state.activeStep} orientation="vertical">
										{this.state.delList.map((item, i) => {
											return (
												<Step key={item.title}>
													<StepLabel>
														<p style={{ fontWeight: 'bold' }}>{item.title}</p> {item.value <= this.state.delStatus ? '(' + item.subTitle + ')' : ''}
													</StepLabel>
												</Step>
											);
										})}
									</Stepper>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Menu id="simple-menu" anchorEl={this.state.anchorEl} open={Boolean(this.state.anchorEl)} onClose={this.orderCancelPopClose}>
					<div className="writeType03" style={{ padding: 10 }}>
						<TextField
							inputRef={(el) => (this.fv = el)}
							autofocus={true}
							label="결재비고"
							style={{ margin: 8, width: 300 }}
							placeholder="취소 사유를 입력해 주세요."
							fullWidth
							multiline
							rows="2"
							margin="normal"
							variant="outlined"
							InputLabelProps={{ shrink: true }}
							value={this.state.cancelRemark}
							onChange={(e) => this.setState({ cancelRemark: e.target.value })}
						/>
					</div>
					<Button
						color="secondary"
						variant="contained"
						size="small"
						style={{ margin: '0px 10px 15px 0px', alignItems: 'initial', float: 'right' }}
						onClick={this.orderCancel}
					>
						<DeleteIcon style={{ fontSize: 20, marginRight: 5 }} />
						저장
					</Button>
				</Menu>
			</div>
		);
	}
}
export default observer(RentalOrderSearch);
