import React, { Component } from 'react';
import LeftMenu from '../include/LeftMenu';
import Header from '../include/Header';
import IBSheet from '../IBSheet';
import * as MallService from '../../service/admin/MallService';
import { errorMsgCheck, resultToIbSheet, dateToFormat, buildSelectItems, InsertComma, managerCheck } from '../../common/ComFunction';
import * as ContractService from '../../service/admin/ContractService';
import { getCisCommCode, getRentalCustContType } from '../../service/CommonService';
import {
	getContOrderList,
	getContOrderItemList,
	updateContOrderDelInfo,
	updateContOrderItemInfo,
	contractProc,
	postChangeOrderQty,
	deleteContOrder,
	getContOrderItemCouponList,
} from '../../service/admin/ContractService';
import { getCustItemList } from '../../service/admin/CompService';

import { TrainRounded } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import ShipIcon from '@material-ui/icons/LocalShipping';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ContIcon from '@material-ui/icons/AssignmentTurnedIn';
import BlockIcon from '@material-ui/icons/Block';

import ExcelDown from '../include/ExcelDown';

import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { httpPut } from '../../service/Service';

import MallSaleInfo from './MallOrderSaleInfo';
import MallRentalInfo from './MallOrderRentalInfo';
import OtherIcon from '@material-ui/icons/SwapVerticalCircle';
import Menu from '@material-ui/core/Menu';
import TextField from '@material-ui/core/TextField';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore, StockPopupStore, IfrsPopupStore } = CommonStore;

var result = {
	//sheet데이터
	data: [],
};

class MallOrder extends Component {
	constructor(props) {
		super(props);

		this.sheetData = {
			//주문 리스트
			id: 'sheetMallOrderList', // sheet id
			initData: 'admin/Init-MallOrderList', // sheet initData javascript filename
			width: '100%', // sheet width
			height: '300px', // sheet height
		};
		this.orderItemsSheet = {
			id: 'orderItemsSheet', // sheet id
			initData: 'admin/Init-OrderItemsList', // sheet initData javascript filename
			width: '100%', // sheet width
			height: '267px', // sheet height
		};
		this.orderItemsCouponSheet = {
			// 할인정보
			id: 'orderItemsCouponSheet', // sheet id
			initData: 'admin/Init-OrderItemsCouponList', // sheet initData javascript filename
			width: '100%', // sheet width
			height: '107px', // sheet height
		};
	}
	state = {
		arrDelMethod: [],
		arrContType: [],
		arrOrderStatus: [],

		//검색조건
		custNo: '', //고객번호
		custNm: '', //고객명
		sDate: null, //주문일자
		eDate: null, //주문일자
		selPayYn: 'Y', //결제여부
		selPay: '', //결제방법
		contYn: 'N', //계약여부
		selOrderType: '', //주문구분 R:렌탈, S:유통

		ordeNo: '', //주문번호
		proceMonth: '1',
		contNo: '',
		spotId: '',
		spotNm: '',
		delTelno: '',
		delHpno: '',
		delPostCode: '',
		delAddr: '',
		delAddrDtl: '',
		ordeRmk: '',
		delMethod: '',
		delAskDate: moment(),
		delRemark: '', //출고비고
		delOtherRemark: '', //배송비고
		updtUser: '',
		updtDate: '',
		contType: '',
		prchsReqAsstNo: '',
		//요청제품상세
		seq: '',
		itemSeq: '',
		itemQty: '',
		itemCode: '',
		makeNm: '',
		itemNm: '',
		model: '',
		spec: '',
		userDeptName: '',
		userName: '',
		userRemark: '',
		monthPrc: '',
		salePrc: '',
		stoNo: '',
		stoSeq: '',
		asstNo: '',
		ifrsCode: '',
		duration: '',
		lastBalRate: '',
		changeQty: '',

		deleteBtn: true,
		disabledBtn: true,
		inputReadOnly: true,

		//카드 및 가상계좌 결제 정보
		rentType: '렌탈',
		payCardNm: '',
		cardQuota: '',
		userCardId: '',
		userVaccBank: '',
		userVaccNo: '',
		cashRecieptHp: '',
		cashReceiptType: '',
		cardResultMsg: '',
		cashrcptResultMsg: '',
		//엑셀
		excelList: null,
		arrCustItemList: [], //고객제품리스트
	};

	componentDidMount = () => {
		managerCheck(); // 관리자체크
		Promise.all([getCisCommCode('04'), getCisCommCode('11')]).then((r) => {
			let [code04, code11] = r;

			this.setState({
				arrDelMethod: code04,
				arrOrderStatus: code11,
			});
		});
	};

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
		window[this.orderItemsSheet.id].DisposeSheet();
		window[this.orderItemsCouponSheet.id].DisposeSheet();
	};

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
		});
	};

	// sheet데이터 리셋
	resetResult = () => {
		result = {
			data: [],
		};

		window[this.sheetData.id].LoadSearchData(result, { Sync: 1 });
		window[this.orderItemsSheet.id].LoadSearchData(result, { Sync: 1 });
		window[this.orderItemsCouponSheet.id].LoadSearchData(result, { Sync: 1 });

		this.setState({
			ordeNo: '', //주문번호
			proceMonth: '1',
			custNo: '',
			contNo: '',
			spotId: '',
			spotNm: '',
			delTelno: '',
			delHpno: '',
			delPostCode: '',
			delAddr: '',
			delAddrDtl: '',
			ordeRmk: '',
			delMethod: '',
			delAskDate: null,
			delDate: '',
			delRemark: '', //출고비고
			delOtherRemark: '', //배송비고
			updtUser: '',
			updtDate: '',
			contType: '',
			//요청제품상세
			seq: '',
			itemSeq: '',
			itemQty: '',
			itemCode: '',
			makeNm: '',
			itemNm: '',
			model: '',
			spec: '',
			userDeptName: '',
			userName: '',
			userRemark: '',
			monthPrc: '',
			salePrc: '',
			stoNo: '',
			stoSeq: '',
			asstNo: '',
			ifrsCode: '',
			duration: '',
			lastBalRate: '',
			changeQty: '',

			deleteBtn: true,
			disabledBtn: true,
			inputReadOnly: true,

			//카드 및 가상계좌 결제 정보
			rentType: '렌탈',
			payCardNm: '',
			cardQuota: '',
			userCardId: '',
			userVaccBank: '',
			userVaccNo: '',
			cashRecieptHp: '',
			cashReceiptType: '',
			cardResultMsg: '',
			cashrcptResultMsg: '',
		});
	};

	// 고객사팝업
	handleClickOpen = (popupType) => {
		if (popupType === '1' || popupType === '3') {
			//1:고객사 팝업 , 3:고객사 검색해서 바로적용 팝업
			CustSearchPopupStore.openPopup(popupType, null, null, this.handleClose);
		} else if (popupType === '2') {
			//지점 팝업
			//지점은 고객사 팝업 검색 후 가능
			if (this.state.custNo === '') {
				ModalStore.openModal('지점검색은 회사명 검색 이후 가능합니다.');
				return;
			}
			CustSearchPopupStore.openPopup(popupType, sessionStorage.spotTitle + '명', this.state.custNo, this.handleClose);
		}
	};

	handleClose = (item) => {
		if (item !== undefined) {
			if (CustSearchPopupStore.popupType === '1') {
				// 고객사 검색 결과
				if (item.custNo !== undefined) {
					this.setState({
						custNo: item.custNo,
						custNm: item.custNm,
					});
					this.resetState();
				}
			} else if (CustSearchPopupStore.popupType === '2') {
				// 지점 검색 결과
				if (item.addrSeq !== undefined) {
					this.setState({
						addrSeq: item.addrSeq, //지점시퀀스
						spotNm: item.spotNm, //지점명
						spotId: item.spotId, //지점아이디
						postAddr: item.postAddr, //지점주소1
						postSddr: item.postSddr, //지점주소2
						postCode: item.postCode, //지점우편번호
						chgYn: true, // 변경일어남
					});
				}
			}
		}
	};
	// 고객사팝업 end
	resetState = (type) => {
		this.setState({
			userList: [], //사용자리스트
		});
		this.resetResult(); //ibsheet 데이터 리셋
	};

	//조회
	getOrderList = (itemReSearch) => {
		ProgressStore.activeProgress();
		let param = {
			custNo: this.state.custNo,
			ordeUser: this.state.userName,
			startDate: dateToFormat(this.state.sDate),
			endDate: dateToFormat(this.state.eDate),
			payYn: this.state.selPayYn,
			payType: this.state.selPay,
			contYn: this.state.contYn,
			orderType: this.state.selOrderType,
		};

		MallService.getMallOrderList(param)
			.then((r) => {
				this.resetResult(); //ibsheet 데이터 리셋

				window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				this.makeExcelData(r);

				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};

	cashReceiptPulish = () => {
		let paramList = [];
		let object = window[this.sheetData.id];
		let item = {};

		for (var i = 1; i <= object.GetTotalRows(); i++) {
			if (object.GetCellValue(i, 'status') === 'U') {
				if (object.GetCellValue(i, 'payType') != '현금' && object.GetCellValue(i, 'cashRecieptHp') == '') {
					alert('현금 영수증 발행 대상이 아닌 요청이 선택 되었습니다.');
					return;
				}

				if (object.GetCellValue(i, 'cashrcptResultCode') == '7001') {
					alert('이미 현금영수증이 발행된건이 선택 되었습니다.');
					return;
				}

				let salePrc = Number(object.GetCellValue(i, 'salePrc'));
				let goodsCnt = '';
				let supplyAmt,
					vat = 0;

				goodsCnt = object.GetCellValue(i, 'goodsCnt');
				if (goodsCnt.length === 1) {
					goodsCnt = '0' + goodsCnt;
				}
				vat = salePrc * 0.1;
				supplyAmt = salePrc - vat;

				item.PayMethod = 'CASHRCPT';
				item.GoodsCnt = goodsCnt;
				item.GoodsName = object.GetCellValue(i, 'model');
				item.Amt = salePrc;
				item.Moid = object.GetCellValue(i, 'ordeNo');
				item.MallIP = '118.129.170.131';
				item.UserIP = '118.129.170.131';
				item.mallUserID = object.GetCellValue(i, 'rqstId');
				item.BuyerName = object.GetCellValue(i, 'ordeUser');
				item.BuyerTel = object.GetCellValue(i, 'delHpno') ? object.GetCellValue(i, 'delHpno') : object.GetCellValue(i, 'delTelno');
				item.BuyerEmail = object.GetCellValue(i, 'emailAddr');
				item.Moid = object.GetCellValue(i, 'ordeNo');
				item.ReceiptIdentity = object.GetCellValue(i, 'cashRecieptHp');
				item.CashReceiptType = object.GetCellValue(i, 'cashReceiptType');
				item.ReceiptSupplyAmt = supplyAmt;
				item.ReceiptVAT = vat;
				item.ReceiptServiceAmt = 0;
				item.ReturnType = 'TEXT';
				item.BillKeyMode = '1'; //비암호화
				paramList.push(item);
				item = {};
			}
		}

		if (paramList.length === 0) {
			ModalStore.openModal('선택된 건이 없습니다.');
			return;
		}

		ConfirmModalStore.openModal('현금 영수증을 발행 하시겠습니까?', () => {
			ProgressStore.activeProgress();

			MallService.cashReceiptPub(paramList)
				.then((r) => {
					ModalStore.openModal('처리되었습니다.');

					// this.resetState();
					this.resetResult(); //ibsheet 데이터 리셋
					//this.resetTableField();
					this.getOrderList(true); //저장 성공 후 재조회

					ProgressStore.deactiveProgress();
				})
				.catch((err) => {
					errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
				});
		});
	};

	//출고의뢰
	contRequest = () => {
		let paramList = [];
		let object = window[this.sheetData.id];
		let item = {};
		let rentType = '';

		for (var i = 1; i <= object.GetTotalRows(); i++) {
			// 상태가 수정인 행만 fetch
			if (object.GetCellValue(i, 'status') === 'U') {
				if (object.GetCellValue(i, 'contYn') == 'Y') {
					alert('이미 계약된 건이 선택 되었습니다.');
					return;
				}

				if (object.GetCellValue(i, 'stoSaveYn') == 'N') {
					alert('입고번호가 저장되지 않은 건이 있습니다.');
					return;
				}

				if (rentType === '') {
					rentType = object.GetCellValue(i, 'rentTypeNm');
				} else {
					if (rentType != object.GetCellValue(i, 'rentTypeNm')) {
						alert('렌탈과 유통계약은 함께 생성할 수 없습니다.');
						return;
					}
				}

				item.ordeNo = object.GetCellValue(i, 'ordeNo');
				item.userId = sessionStorage.userId;
				paramList.push(item);
				item = {};
			}
		}

		if (paramList.length === 0) {
			ModalStore.openModal('선택된 건이 없습니다.');
			ProgressStore.deactiveProgress();
			return;
		}

		ConfirmModalStore.openModal(rentType + ' 계약 출고를 진행 하시겠습니까?', () => this.contProc(paramList));
	};

	checkOrderItem = () => {
		var returnMsg = window.checkItemDelInfo(this.state.rentType);

		if (returnMsg !== '') {
			ModalStore.openModal(returnMsg);
			return;
		} else {
			if (this.state.rentType === '렌탈') {
				if (!this.state.proceMonth) {
					ModalStore.openModal('계약 처리월을 선택하지 않았습니다.');
					return;
				}
			}
			if (!this.state.delMethod) {
				ModalStore.openModal('배송 방법을 선택하지 않았습니다.');
				return;
			}
			if (this.state.delAskDate === '') {
				ModalStore.openModal('배송 요청일을 입력하지 않았습니다.');
				return;
			}

			ConfirmModalStore.openModal('계약생성을 진행 하시겠습니까?', this.regContract);
		}
	};

	regContract = () => {
		if (!this.state.ordeNo) {
			ModalStore.openModal('요청번호가 없습니다. 재조회후 실행해 주세요.');
			return;
		}

		let param = {
			ordeNo: this.state.ordeNo,
			empNo: sessionStorage.empno,
		};

		ProgressStore.activeProgress();

		const contApi = this.state.rentType === '렌탈' ? contractProc : MallService.orderSaleContProc;

		contApi(param)
			.then((r) => {
				let errMsg = r[0].errMsg;

				if (errMsg !== '') {
					alert(errMsg);
					ProgressStore.deactiveProgress();
				} else {
					ModalStore.openModal('계약 생성이 완료 되었습니다.');
					ProgressStore.deactiveProgress();

					this.resetResult(); //ibsheet 데이터 리셋
					//요청 리스트 재조회
					this.getOrderList();
				}
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	dateHandler = (date, type) => {
		if (type === 's') {
			this.setState({ sDate: date });
		} else if (type === 'e') {
			this.setState({ eDate: date });
		}
	};

	//요청 리스트 클릭하여 요청정보 가져오기
	getOrderInfo = () => {
		if (window[this.sheetData.id].LastRow() === 0) return;

		let data = window.getOrderSelectData();
		if (!data.ordeNo) return;

		this.setState({
			rentType: data.rentType,
			custNo: data.custNo,
		});

		window.showReantalAndSalePrc(data.rentType);

		//배송, 출고 정보
		this.setOrderInfo(data);
		//고객별 계약정보
		this.getContOrderInfoData(data.custNo);
		//주문별 제품 리스트
		this.getContOrderItemList(data.ordeNo, data.custNo);
		//주문별 제품 쿠폰 리스트
		this.getContOrderItemCouponList(data.ordeNo);
	};

	//요청제품 리스트 가져오기
	getContOrderItemList = (ordeNo, custNo) => {
		ProgressStore.activeProgress();
		if (custNo) {
			let param = {
				custNoSearch: custNo,
				delYnSearch: 'N',
			};

			Promise.all([getContOrderItemList(ordeNo), getCustItemList(param)])
				.then((r) => {
					let [data1, data2] = r;
					window[this.orderItemsSheet.id].LoadSearchData(resultToIbSheet(data1));
					this.setState({
						arrCustItemList: data2,
					});
					//초기화
					this.setOrderItemInfo();
					ProgressStore.deactiveProgress();
				})
				.catch((err) => {
					errorMsgCheck(err, ProgressStore.deactiveProgress());
				});
		} else {
			getContOrderItemList(ordeNo)
				.then((r) => {
					window[this.orderItemsSheet.id].LoadSearchData(resultToIbSheet(r));
					//초기화
					this.setOrderItemInfo();
					ProgressStore.deactiveProgress();
				})
				.catch((err) => {
					errorMsgCheck(err, ProgressStore.deactiveProgress());
				});
		}
	};

	//요청제품 쿠폰 리스트 가져오기
	getContOrderItemCouponList = (ordeNo) => {
		ProgressStore.activeProgress();
		Promise.all([getContOrderItemCouponList(ordeNo)])
			.then((r) => {
				let [data1] = r;
				window[this.orderItemsCouponSheet.id].LoadSearchData(resultToIbSheet(data1));

				//초기화
				this.setOrderItemCouponInfo();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	getOrderItemInfo = () => {
		if (window[this.orderItemsSheet.id].LastRow() === 0) return;

		this.setOrderItemInfo(window.getOrderItemSelectData());
	};

	setOrderInfo = (data) => {
		if (data) {
			this.setState({
				ordeNo: data.ordeNo,
				custNo: data.custNo,
				contNo: data.contNo,
				delUser: data.delUser,
				delTelno: data.delTelno,
				delHpno: data.delHpno,
				delPostCode: data.delPostCode,
				delAddr: data.delAddr,
				delAddrDtl: data.delAddrDtl,
				ordeRmk: data.ordeRmk,
				proceMonth: data.proceMonth ? data.proceMonth : '1',
				contType: data.contType,
				delMethod: data.delMethod,
				delAskDate: data.delAskDate ? moment(new Date(data.delAskDate)) : null,
				delRemark: data.delAskRecdesc,
				delOtherRemark: data.delOtherRecdesc,
				updtUser: data.updtUser,
				updtDate: data.updtDate ? dateToFormat(data.updtDate) : '',
				deleteBtn: data.ordeStatusCd === '4' ? true : false,
				disabledBtn: data.ordeStatusCd !== '3' ? true : false,
				inputReadOnly: data.ordeStatusCd !== '3' ? true : false,
				prchsReqAsstNo: data.prchsReqAsstNo,
				//결제정보
				payCardNm: data.payCardNm,
				cardQuota: data.cardQuota,
				userCardId: data.userCardId,
				userVaccBank: data.userVaccBank,
				userVaccNo: data.userVaccNo,
				cashRecieptHp: data.cashRecieptHp,
				cashReceiptType: data.cashReceiptType,
				cardResultMsg: data.cardResultMsg,
				cashrcptResultMsg: data.cashrcptResultMsg,
			});
		} else {
			this.setState({
				ordeNo: '',
				custNo: '',
				contNo: '',
				spotId: '',
				spotNm: '',
				delUser: '',
				delTelno: '',
				delHpno: '',
				delPostCode: '',
				delAddr: '',
				delAddrDtl: '',
				ordeRmk: '',
				proceMonth: '',
				contType: '',
				delMethod: '',
				delAskDate: null,
				delRemark: '',
				delOtherRemark: '',
				updtUser: '',
				updtDate: '',
				disabledBtn: true,
				inputReadOnly: true,
				prchsReqAsstNo: '',
				//결제정보
				payCardNm: '',
				cardQuota: '',
				userCardId: '',
				userVaccBank: '',
				userVaccNo: '',
				cashRecieptHp: '',
				cashReceiptType: '',
				cardResultMsg: '',
				cashrcptResultMsg: '',
			});
		}
	};

	setOrderItemInfo = (data) => {
		if (data) {
			this.setState({
				seq: data.seq,
				itemQty: data.itemQty,
				itemCode: data.itemCode,
				itemNm: data.itemNm,
				model: data.model,
				spec: data.spec,
				makeNm: data.makeNm,
				userDeptName: data.userDeptName,
				userName: data.userName,
				userRemark: data.userRemark,
				monthPrc: data.monthPrc ? InsertComma(data.monthPrc) : data.monthPrc,
				salePrc: data.salePrc ? InsertComma(data.salePrc) : data.salePrc,
				stoNo: data.stoNo,
				stoSeq: data.stoSeq,
				asstNo: data.asstNo,
				ifrsCode: data.ifrsCode,
			});
		} else {
			this.setState({
				seq: '',
				itemQty: '',
				itemCode: '',
				changeQty: '',
				itemNm: '',
				model: '',
				spec: '',
				makeNm: '',
				userDeptName: '',
				userName: '',
				userReamrk: '',
				monthPrc: '',
				salePrc: '',
				stoNo: '',
				stoSeq: '',
				asstNo: '',
				ifrsCode: '',
			});
		}
	};

	setOrderItemCouponInfo = (data) => {
		if (data) {
			this.setState({
				couponModel: data.couponModel,
				couponQty: data.couponQty,
				couponName: data.couponName,
				discountAmount: data.discountAmount,
				usageDate: data.usageDate,
			});
		} else {
			this.setState({
				couponModel: '',
				couponQty: '',
				couponName: '',
				discountAmount: '',
				usageDate: '',
			});
		}
	};

	getContOrderInfoData = (custNo) => {
		ProgressStore.activeProgress();
		getRentalCustContType(custNo)
			.then((custContTypeData) => {
				this.setState({ arrContType: custContTypeData });

				if (custContTypeData.length === 0 && this.state.rentType === '렌탈') {
					ModalStore.openModal('계약 유형이 저장되지 않았습니다.\nERP 고객별계약정보에서 계약 유형을 등록해 주시기 바랍니다.');
				}
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	stockPopOpen = (type) => {
		if (type === 'B' && this.state.itemQty > 1) {
			ModalStore.openModal('재고자산을 출고할 경우 요청 제품의 수량은 하나여야 합니다.\n수량 나누기로 수량을 조정 하세요.');
			return;
		}
		if (type === 'A' && this.state.asstNo) {
			ModalStore.openModal('이미 자산번호가 입력되어 있습니다.');
			return;
		}
		if (type === 'B' && this.state.stoNo) {
			ModalStore.openModal('이미 입고번호가 입력되어 있습니다.');
			return;
		}

		StockPopupStore.openPopup(type, this.state.model, this.stockCloseFnc);
	};

	stockCloseFnc = (item) => {
		if (!item) return;

		if (item.ableQty && this.state.itemQty > item.ableQty) {
			ModalStore.openModal('상품 수량보다 발주 수량이 큽니다.');
			this.setState({
				stoNo: '',
				stoSeq: '',
			});
			return;
		}

		this.setState({
			stoNo: item.stoNo,
			stoSeq: item.stoSeq,
			asstNo: item.asstNo,
		});
	};

	ifrsCloseFnc = (item) => {
		if (!item) return;

		this.setState({
			ifrsCode: item.ifrsCode,
			duration: item.duration,
			lastBalRate: item.lastBalRate,
		});
	};

	saveDelInfo2 = () => {
		if (this.state.rentType === '렌탈') {
			if (!this.state.proceMonth) {
				ModalStore.openModal('계약처리월을 선택해 주세요');
				return;
			}
		}

		if (!this.state.delMethod) {
			ModalStore.openModal('배송방법을 선택해 주세요');
			return;
		}
		if (!this.state.delAskDate) {
			ModalStore.openModal('배송 요청일을 선택해 주세요');
			return;
		}

		ConfirmModalStore.openModal('출고 정보를 저장 하시겠습니까?', this.updateContOrderDelInfo2);
	};

	updateContOrderDelInfo2 = () => {
		let param = {
			ordeNo: this.state.ordeNo,
			proceMonth: this.state.proceMonth ? this.state.proceMonth : '1', //유통이면 미선택으로 당일 처리이다.
			contType: this.state.contType,
			delMethod: this.state.delMethod,
			delAskDate: this.state.delAskDate.format('YYYY-MM-DD'),
			delAskRecdesc: this.state.delRemark,
			delOtherRecdesc: this.state.delOtherRemark,
			type: '1', // 출고정보
		};

		updateContOrderDelInfo(param)
			.then(() => {
				ModalStore.openModal('저장 되었습니다.');
				ProgressStore.deactiveProgress();
				//재조회
				this.getContOrderInfoData(this.state.custNo);
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	priceHandler = (v) => {
		if (this.state.rentType === '렌탈') {
			this.setState({ monthPrc: v });
		} else if (this.state.rentType === '유통') {
			this.setState({ salePrc: v });
		}
	};

	saveItemInfo = () => {
		if (!this.state.seq) {
			ModalStore.openModal('주문 제품 리스트에서 제품을 선택해 주세요.');
			return;
		}

		if (this.state.rentType === '렌탈') {
			if (!this.state.monthPrc) {
				ModalStore.openModal('렌탈료가 입력되지 않았습니다.');
				return;
			}
			if (this.state.asstNo && this.state.stoNo && this.state.stoSeq) {
				ModalStore.openModal('입고번호와 자산번호를 둘다 입력할 수 없습니다.');
				return;
			}
			if (this.state.stoNo && !this.state.stoSeq) {
				ModalStore.openModal('입고순번을 반드시 입력해 주세요.');
				return;
			}
			if (!this.state.stoNo && this.state.stoSeq) {
				ModalStore.openModal('입고번호를 반드시 입력해 주세요.');
				return;
			}
			if (this.state.stoNo && this.state.stoSeq && !this.state.ifrsCode) {
				ModalStore.openModal('신품인 경우 IFRS코드를 반드시 입력해 주세요.');
				return;
			}
		} else if (this.state.rentType === '유통') {
			if (!this.state.salePrc) {
				ModalStore.openModal('유통가가 입력되지 않았습니다.');
				return;
			}
			if (!this.state.stoNo && !this.state.stoSeq) {
				ModalStore.openModal('입고번호, 순번을 반드시 입력해 주세요.');
				return;
			}
			if (this.state.stoNo && !this.state.stoSeq) {
				ModalStore.openModal('입고순번을 반드시 입력해 주세요.');
				return;
			}
			if (!this.state.stoNo && this.state.stoSeq) {
				ModalStore.openModal('입고번호를 반드시 입력해 주세요.');
				return;
			}
		}

		ConfirmModalStore.openModal('출고 제품 정보를 저장 하시겠습니까?', this.updateContOrderItemInfo);
	};

	updateContOrderItemInfo = (itemSeq) => {
		let param = {
			ordeNo: this.state.ordeNo,
			seq: this.state.seq,
			itemSeq: itemSeq,
			monthPrc: this.state.monthPrc,
			salePrc: this.state.salePrc,
			stoNo: this.state.stoNo,
			stoSeq: this.state.stoSeq,
			asstNo: this.state.asstNo,
			ifrsCode: this.state.ifrsCode,
			duration: this.state.duration,
			lastBalRate: this.state.lastBalRate,
		};

		ProgressStore.activeProgress();
		updateContOrderItemInfo(param)
			.then(() => {
				ModalStore.openModal('저장 되었습니다.');
				//재조회
				this.getContOrderItemList(param.ordeNo);
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	checkContOrder = () => {
		if (this.state.ordeNo === '') {
			ModalStore.openModal('주문번호를 선택해 주세요.');
			return;
		}

		ConfirmModalStore.openModal(
			`주문번호[${this.state.ordeNo}]를 취소 처리 하시겠습니까?
        (결제가 카드일 경우 카드 취소가 진행되며 현금은 수동으로 처리)`,
			this.mallOrderCancel,
		);
	};

	mallOrderCancel = () => {
		let param = {
			ordeNo: this.state.ordeNo,
		};

		MallService.orderAndPayCancel(param)
			.then((r) => {
				ProgressStore.deactiveProgress();
				//재조회
				this.getOrderList();
				//버튼 디저블
				ModalStore.openModal('취소 되었습니다.');
				this.setState({
					deleteBtn: true,
				});
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	makeExcelData = (data) => {
		let tmpList = [];
		let tmp = {};
		data.map((item, idx) => {
			tmp = {};
			tmp.No = idx + 1;
			tmp.구분 = item.rentTypeNm;
			tmp.주문번호 = item.ordeNo;
			tmp.주문일자 = dateToFormat(item.ordeDate);
			tmp.주문자ID = item.rqstId;
			tmp.주문자 = item.ordeUser;
			tmp.상품 = item.model;
			tmp.결제방법 = item.payType;
			tmp.결제일 = item.payDay;
			tmp.결제금액 = item.salePrc;
			tmp.결제여부 = item.payYn;
			tmp.가상계좌입금차액 = item.diffAmt;
			tmp.렌탈기간 = item.contTerm;
			tmp.월렌탈료 = item.monthPrc;
			tmp.고객번호 = item.custNo;
			tmp.고객명 = item.custNm;
			tmp.제휴사 = item.alncCustNm;
			tmp.계약여부 = item.contYn;
			tmp.계약번호 = item.contNo;
			tmp.현금영수증발행번호 = item.cashRecieptHp;
			tmp.현금영수증승인결과 = item.cashrcptResultMsg;

			tmpList.push(tmp);
		});
		this.setState({ excelList: tmpList });
	};

	changeQtyPop = (e) => {
		if (!this.state.seq) {
			ModalStore.openModal('제품을 선택해 주세요.');
			return;
		}
		if (this.state.itemCode === '추가옵션') {
			return;
		}
		if (this.state.seq && this.state.itemQty === 1) {
			ModalStore.openModal('수량이 하나면 나눌수 없습니다.');
			return;
		}
		this.setState({ anchorEl: e.currentTarget });
	};

	changeQtyPopClose = () => {
		this.setState({
			anchorEl: null,
			changeQty: '',
		});
	};
	checkItemQty = () => {
		if (!this.state.changeQty) {
			ModalStore.openModal('수량을 입력해 주세요');
			return;
		}
		if (this.state.changeQty <= 0) {
			ModalStore.openModal('한 개 이상 입력해 주세요');
			return;
		}
		if (this.state.changeQty > this.state.itemQty || this.state.changeQty == this.state.itemQty) {
			ModalStore.openModal('원 수량보다 같거나 클 수 없습니다.');
			return;
		}

		ConfirmModalStore.openModal('수량을 나누시겠습니까?', this.postChangeOrderQty);
	};

	postChangeOrderQty = () => {
		let param = {
			ordeNo: this.state.ordeNo,
			seq: this.state.seq,
			changeQty: this.state.changeQty,
		};

		ProgressStore.activeProgress();
		postChangeOrderQty(param)
			.then(() => {
				ModalStore.openModal('수정 되었습니다.');
				//팝업닫기
				this.changeQtyPopClose();
				//재조회
				this.getContOrderItemList(param.ordeNo);
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont" style={{ height: 1800 }}>
							<div className="h2Wrap mb30">
								<h2>MALL 주문조회</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={() => this.getOrderList(true)}>
										<span>조회</span>
									</a>
									<ExcelDown modalOpen={ModalStore.openModal} data={this.state.excelList} text="엑셀" filename={'Mall주문리스트.csv'} />
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '10%' }} />
										<col style={{ width: '31%' }} />
										<col style={{ width: '10%' }} />
										<col style={{ width: '20%' }} />
										<col style={{ width: '10%' }} />
										<col style={{ width: '20%' }} />
									</colgroup>
									<tbody>
									<tr>
										<th>주문일자</th>
										<td>
											<DatePicker
												customInput={<input type="text" style={{ width: 115 }} />}
												dateFormat="YYYY-MM-DD"
												selectsStart
												selected={this.state.sDate}
												onChange={(date) => this.dateHandler(date, 's')}
												startDate={this.state.sDate}
												endDate={this.state.eDate}
												isClearable={true}
												showMonthDropdown
												showYearDropdown
												dropdownMode="select"
											/>
											<span style={{ marginLeft: 5, marginRight: 5 }}>~</span>
											<DatePicker
												customInput={<input type="text" style={{ width: 115 }} />}
												dateFormat="YYYY-MM-DD"
												selectsEnd
												selected={this.state.eDate}
												onChange={(date) => this.dateHandler(date, 'e')}
												startDate={this.state.sDate}
												endDate={this.state.eDate}
												isClearable={true}
												showMonthDropdown
												showYearDropdown
												dropdownMode="select"
											/>
										</td>
										<th>주문구분</th>
										<td>
											<select
												name="selOrderType"
												value={this.state.selOrderType || ''}
												onChange={(e) => this.setState({ selOrderType: e.target.value })}
												style={{ width: 120 }}
											>
												<option value="">전체</option>
												<option value="R">렌탈</option>
												<option value="S">유통</option>
											</select>
										</td>
										<th>결제방법</th>
										<td>
											<select
												name="selPay"
												value={this.state.selPay || ''}
												onChange={(e) => {
													this.setState({
														selPay: e.target.value,
													});
												}}
												style={{ width: 120 }}
											>
												<option value="" selected>
													전체
												</option>
												<option value="1">카드</option>
												<option value="2">현금</option>
											</select>
										</td>
									</tr>
									<tr>
										<th>주문자명</th>
										<td>
											<input
												type="text"
												ref={(ref) => (this.userName = ref)}
												name="userName"
												value={this.state.userName || ''}
												onChange={(e) => {
													this.setState({
														userName: e.target.value,
													});
												}}
												style={{ width: 120 }}
											/>
										</td>
										<th>결제여부</th>
										<td>
											<select
												name="selPayYn"
												value={this.state.selPayYn || ''}
												onChange={(e) => {
													this.setState({
														selPayYn: e.target.value,
													});
												}}
												style={{ width: 120 }}
											>
												<option value="">전체</option>
												<option value="N">미결제</option>
												<option value="Y" selected>
													결제완료
												</option>
												<option value="X">실패</option>
											</select>
										</td>
										<th>진행상태</th>
										<td>
											<select
												name="contYn"
												value={this.state.contYn}
												onChange={(e) => {
													this.setState({
														contYn: e.target.value,
													});
												}}
												style={{ width: 120 }}
											>
												<option value="N">미계약</option>
												<option value="Y">계약완료</option>
												<option value="X">주문취소</option>
												<option value="">전체</option>
											</select>
										</td>
									</tr>
									</tbody>
								</table>
							</div>

							<div className="writeType03">
								<div style={{ marginTop: 15, marginBottom: -15, textAlign: 'right' }}>
									<Button
										className="button"
										onClick={() => this.cashReceiptPulish()}
										color="primary"
										variant="contained"
										size="small"
										style={{ marginLeft: 10 }}
									>
										<ReceiptIcon style={{ fontSize: 20, marginRight: 5 }} />
										<span className="text">현금영수증발행</span>
									</Button>
									<Button
										color="secondary"
										size="small"
										variant="contained"
										style={{ marginLeft: 10, alignItems: 'initial' }}
										onClick={this.checkContOrder}
										disabled={this.state.deleteBtn}
									>
										<BlockIcon style={{ fontSize: 20, marginRight: 5 }} />
										주문취소
									</Button>
								</div>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
									onClick={this.getOrderInfo}
								>
									<p className="headline">주문 리스트</p>
									<IBSheet {...this.sheetData} />
								</div>
							</div>

							{/* 카드 및 가상계좌 결제정보 */}
							<div className="writeType03_acc">
								{/*
								[대응개발] 2024-01-26 유통 카드결제정보에서 유통제거 by choye
								*/}
								<p className="headline">카드결제정보</p>
								<table>
									<colgroup>
										<col style={{ width: 80 }} />
										<col style={{ width: 100 }} />
										<col style={{ width: 100 }} />
										<col style={{ width: 100 }} />
										<col style={{ width: 100 }} />
										<col />
									</colgroup>
									<tbody>
									<tr>
										<th>카드사</th>
										<td>
											<input type="text" value={this.state.payCardNm} style={{ border: 0 }} readOnly={TrainRounded} />
										</td>
										<th>할부개월</th>
										<td>
											<input type="text" value={this.state.cardQuota === '00' ? '일시불' : this.state.cardQuota} style={{ border: 0 }} readOnly={true} />
										</td>
										<th>카드승인결과</th>
										<td>
											<textarea value={this.state.cardResultMsg} rows="1" style={{ border: 0, height: 53 }} readOnly={true}></textarea>
										</td>
									</tr>
									<tr></tr>
									</tbody>
								</table>
							</div>
							<div className="writeType03_acc">
								<p className="headline">현금결제정보</p>
								<table>
									<colgroup>
										<col style={{ width: 80 }} />
										<col style={{ width: 80 }} />
										<col style={{ width: 80 }} />
										<col style={{ width: 100 }} />
										<col style={{ width: 80 }} />
										<col style={{ width: 110 }} />
										<col style={{ width: 80 }} />
										<col style={{ width: 200 }} />
										<col />
									</colgroup>
									<tbody>
									<tr>
										<th>입금은행</th>
										<td>
											<input type="text" value={this.state.userVaccBank} style={{ border: 0 }} readOnly={true} />
										</td>
										<th>입금계좌번호</th>
										<td colSpan="3">
											<input type="text" value={this.state.userVaccNo} style={{ border: 0 }} readOnly={TrainRounded} />
										</td>
										<th rowSpan="2">
											현금영수증
											<br />
											발행결과
										</th>
										<td rowSpan="2">
											<textarea value={this.state.cashrcptResultMsg} rows="1" style={{ border: 0, height: 73 }} readOnly={true}></textarea>
										</td>
									</tr>
									<tr>
										<th>
											현금영수증
											<br />
											발행여부
										</th>
										<td>
											<input
												type="text"
												value={!this.state.cashRecieptHp ? '' : this.state.cashRecieptHp ? '발행요청' : '미발급'}
												readOnly={true}
												style={{ border: 0 }}
											/>
										</td>
										<th>
											현금영수증
											<br />
											발행번호
										</th>
										<td>
											<input type="text" value={this.state.cashRecieptHp} readOnly={true} style={{ border: 0 }} />
										</td>
										<th>
											현금영수증
											<br />
											발행용도
										</th>
										<td>
											<input
												type="text"
												value={!this.state.cashReceiptType ? '' : this.state.cashReceiptType === '1' ? '개인 소득공제용' : '사업자 지출증빙용'}
												readOnly={true}
												style={{ border: 0 }}
											/>
										</td>
									</tr>
									</tbody>
								</table>
							</div>
							<div className="writeType03">
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">할인정보</p>
									<IBSheet {...this.orderItemsCouponSheet} />
								</div>
							</div>
							<div className="writeType03_acc" style={{ float: 'left', width: '48%', height: 300, marginBottom: '25px' }}>
								<p className="headline">배송정보</p>
								<table>
									<colgroup>
										<col style={{ width: 80 }} />
										<col style={{ width: 140 }} />
										<col style={{ width: 80 }} />
										<col />
									</colgroup>
									<tbody>
									<tr>
										<th>받으시는분</th>
										<td colSpan="3">
											<input type="text" value={this.state.delUser} readOnly={true} style={{ border: 0 }} />
										</td>
									</tr>
									<tr>
										<th>전화번호</th>
										<td>
											<input type="text" value={this.state.delTelno} readOnly={true} />
										</td>
										<th>휴대폰번호</th>
										<td>
											<input type="text" value={this.state.delHpno} readOnly={true} />
										</td>
									</tr>
									<tr>
										<th>주소</th>
										<td colSpan="3">
											<input type="text" value={this.state.delPostCode} readOnly={true} style={{ width: '20%', marginRight: 5 }} />
											<input type="text" value={this.state.delAddr} readOnly={true} style={{ width: '78%' }} />
										</td>
									</tr>
									<tr>
										<th>상세주소</th>
										<td colSpan="3">
											<input type="text" name="delAddrDtl" value={this.state.delAddrDtl} readOnly={this.state.inputReadOnly} />
										</td>
									</tr>
									<tr>
										<th>요청비고</th>
										<td colSpan="3">
											<textarea value={this.state.ordeRmk} rows="2" style={{ border: 0, height: 60 }} readOnly={true}></textarea>
										</td>
									</tr>
									<tr>
										<th>구입요청자산번호</th>
										<td colSpan="3">
											<input
												type="text"
												value={this.state.prchsReqAsstNo}
												readOnly={true}
												style={{ width: '30%', marginRight: 5 }}
											/>
										</td>
									</tr>
									</tbody>
								</table>
							</div>
							<div className="writeType03_acc" style={{ float: 'right', width: '48%', height: 300, marginBottom: '25px' }}>
								<p className="headline">출고정보</p>
								<table>
									<colgroup>
										<col style={{ width: 80 }} />
										<col style={{ width: 130 }} />
										<col style={{ width: 80 }} />
										<col />
									</colgroup>
									<tbody>
									{this.state.rentType === '렌탈' && (
										<tr>
											<th>계약처리월</th>
											<td>
												<select
													value={this.state.proceMonth}
													onChange={(e) => this.setState({ proceMonth: e.target.value })}
													disabled={this.state.inputReadOnly}
												>
													<option value="">선택</option>
													<option value="1">당월</option>
													<option value="2">익월</option>
												</select>
											</td>
											<th>계약유형</th>
											<td>
												<select value={this.state.contType} onChange={(e) => this.setState({ contType: e.target.value })} disabled={this.state.inputReadOnly}>
													<option value="">선택</option>
													{this.state.arrContType
														? this.state.arrContType.map((item, i) => (
															<option key={i} value={item.contType} selected={item.contType === this.state.contType ? true : false}>
																{item.contTypeNm}
															</option>
														))
														: null}
												</select>
											</td>
										</tr>
									)}
									<tr>
										<th>배송방법</th>
										<td>
											<select value={this.state.delMethod} onChange={(e) => this.setState({ delMethod: e.target.value })} disabled={this.state.inputReadOnly}>
												{this.state.arrDelMethod ? buildSelectItems([{ title: '선택', value: '' }].concat(this.state.arrDelMethod)) : null}
											</select>
										</td>
										<th>배송희망일</th>
										<td>
											{this.state.contNo ? (
												<input type="text" value={this.state.delAskDate} readOnly={true} style={{ width: 100, border: 0 }} />
											) : (
												<DatePicker
													customInput={<input type="text" style={{ width: 100 }} />}
													dateFormat="YYYY-MM-DD"
													selected={this.state.delAskDate}
													onChange={(date) => this.setState({ delAskDate: date })}
													isClearable={false}
												/>
											)}
										</td>
									</tr>
									<tr>
										<th>출고비고</th>
										<td colSpan="3">
												<textarea
													value={this.state.delRemark}
													readOnly={this.state.inputReadOnly}
													onChange={(e) => this.setState({ delRemark: e.target.value })}
													style={{ height: 60 }}
												></textarea>
										</td>
									</tr>
									<tr>
										<th>배송비고</th>
										<td colSpan="3">
											<input
												type="text"
												value={this.state.delOtherRemark}
												readOnly={this.state.inputReadOnly}
												onChange={(e) => this.setState({ delOtherRemark: e.target.value })}
											/>
										</td>
									</tr>
									<tr>
										<th>변경자</th>
										<td>
											<input type="text" value={this.state.updtUser} readOnly={true} style={{ border: 0 }} />
										</td>
										<th>변경일자</th>
										<td>
											<input type="text" value={this.state.updtDate} readOnly={true} style={{ border: 0 }} />
										</td>
									</tr>
									</tbody>
								</table>
								<div style={{ marginTop: 15, textAlign: 'right' }}>
									<Button
										color="primary"
										variant="outlined"
										size="small"
										style={{ marginLeft: 10, alignItems: 'initial' }}
										onClick={this.saveDelInfo2}
										disabled={this.state.disabledBtn}
									>
										<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
										저장
									</Button>
								</div>
							</div>
							<div className="writeType03" style={{ float: 'left', width: '40%', marginTop: 20 }} onClick={() => this.getOrderItemInfo()}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">주문제품 리스트</p>
									<IBSheet {...this.orderItemsSheet} />
								</div>
								<div style={{ marginTop: 15, textAlign: 'right' }}>
									<Button
										color="primary"
										variant="outlined"
										size="small"
										style={{ marginLeft: 10, alignItems: 'initial', display: 'none' }}
										onClick={this.changeQtyPop}
										disabled={this.state.disabledBtn}
									>
										<OtherIcon style={{ fontSize: 20, marginRight: 5 }} />
										수량나누기
									</Button>
								</div>
							</div>
							<div className="writeType03_acc" style={{ float: 'right', width: '55%', marginTop: 20 }}>
								<p className="headline">제품정보</p>
								<table>
									<colgroup>
										<col style={{ width: 80 }} />
										<col style={{ width: 220 }} />
										<col style={{ width: 70 }} />
										<col />
									</colgroup>
									<tbody>
									<tr>
										<th>모델명</th>
										<td>
											<input type="text" value={this.state.model} readOnly={true} style={{ border: 0 }} />
										</td>
										<th>제조사</th>
										<td>
											<input type="text" value={this.state.makeNm} readOnly={true} style={{ border: 0 }} />
										</td>
									</tr>
									<tr>
										<th>스펙</th>
										<td colSpan="3">
											<input type="text" value={this.state.spec} readOnly={true} style={{ border: 0 }} />
										</td>
									</tr>
									<tr>
										<th>부서(대리점)</th>
										<td>
											<input type="text" value={this.state.userDeptName} readOnly={true} style={{ border: 0 }} />
										</td>
										<th>사용자명</th>
										<td>
											<input type="text" value={this.state.userName} readOnly={true} style={{ border: 0 }} />
										</td>
									</tr>
									<tr>
										<th>사용자비고</th>
										<td colSpan="3">
											<input type="text" value={this.state.userRemark} readOnly={true} style={{ border: 0 }} />
										</td>
									</tr>
									<tr>
										<th>신품</th>
										<td>
											<input
												type="text"
												value={this.state.stoNo}
												onChange={(e) => this.setState({ stoNo: e.target.value })}
												placeholder="입고번호"
												style={{ width: 100, marginRight: 5 }}
											/>
											<input
												type="text"
												value={this.state.stoSeq}
												onChange={(e) => this.setState({ stoSeq: e.target.value })}
												placeholder="순번"
												style={{ width: 50, marginRight: 5 }}
											/>
											<IconButton
												onClick={() => {
													if (!this.state.model) {
														alert('주문 제품을 선택해 주세요.');
														return;
													}
													this.stockPopOpen('A');
													this.setState({
														stoNo: '',
														stoSeq: '',
													});
												}}
												color="secondary"
												className="iconButton"
												disabled={this.state.disabledBtn}
											>
												<SearchIcon fontSize="small" />
											</IconButton>
										</td>
										<th>IFRS코드</th>
										<td colSpan="3">
											<input
												type="text"
												value={this.state.ifrsCode}
												style={{ width: 90, marginRight: 5 }}
												onChange={(e) => this.setState({ frsCode: e.target.value })}
												readOnly={this.state.inputReadOnly}
											/>
											<IconButton
												onClick={() => IfrsPopupStore.openPopup(this.ifrsCloseFnc)}
												color="secondary"
												className="iconButton"
												disabled={this.state.rentType === '유통' ? true : this.state.disabledBtn}
											>
												<SearchIcon fontSize="small" />
											</IconButton>
										</td>
									</tr>
									<tr>
										<th>재고</th>
										<td>
											<input
												type="text"
												value={this.state.asstNo}
												onChange={(e) => this.setState({ asstNo: e.target.value })}
												placeholder="자산번호"
												readOnly
												style={{ width: 90, marginRight: 5 }}
											/>
											<IconButton
												onClick={() => {
													this.stockPopOpen('B');
													this.setState({
														asstNo: '',
													});
												}}
												color="secondary"
												className="iconButton"
												disabled={this.state.rentType === '유통' ? true : this.state.disabledBtn}
											>
												<SearchIcon fontSize="small" />
											</IconButton>
										</td>
										<th>{this.state.rentType === '렌탈' ? '월렌탈료' : '유통가'}</th>
										<td>
											<input
												type="text"
												value={this.state.monthPrc ? this.state.monthPrc : this.state.salePrc}
												readOnly={this.state.inputReadOnly}
												onChange={(e) => this.priceHandler(e.target.value)}
											/>
										</td>
									</tr>
									</tbody>
								</table>
								<div style={{ marginTop: 15, textAlign: 'right' }}>
									<Button
										color="primary"
										variant="outlined"
										size="small"
										style={{ marginLeft: 10, alignItems: 'initial' }}
										onClick={this.saveItemInfo}
										disabled={this.state.disabledBtn}
									>
										<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
										제품정보저장
									</Button>
								</div>
							</div>
							<div className="writeType03">
								<div className="btnCenter" style={{ paddingTop: 750 }}>
									<Button
										color="secondary"
										variant="contained"
										style={{ marginLeft: 10, alignItems: 'center', width: 200, height: 50 }}
										onClick={this.checkOrderItem}
										disabled={this.state.disabledBtn}
									>
										<ContIcon style={{ fontSize: 20, marginRight: 5 }} />
										{/*
										[대응개발] 2024-01-26 약생성 버튼명 계약생성요청으로 변경 by choye
										*/}
										<label style={{ fontSize: 18 }}>계약생성요청</label>
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Menu id="simple-menu" anchorEl={this.state.anchorEl} open={Boolean(this.state.anchorEl)} onClose={this.changeQtyPopClose}>
					<TextField
						label="나눌 수량 입력"
						type="number"
						variant="filled"
						margin="normal"
						autofocus={true}
						value={this.state.changeQty}
						onChange={(e) => this.setState({ changeQty: e.target.value })}
						style={{ margin: 10, width: 120 }}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<Button color="primary" variant="contained" size="small" style={{ margin: '24px 18px 10px 10px', alignItems: 'initial' }} onClick={this.checkItemQty}>
						<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
						저장
					</Button>
				</Menu>
			</div>
		);
	}
}
export default observer(MallOrder);
