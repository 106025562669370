import React, { Component } from 'react';
import IBSheet from '../IBSheet';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/NoteAdd';
import ExcelDown from '../include/ExcelDown';
import Dialog from '@material-ui/core/Dialog';
import { Transition } from '../../common/ComFunction';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import { dateToFormat, errorMsgCheck, resultToIbSheet } from '../../common/ComFunction';
import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ProgressStore, LargeInputNoPopupStore, KYUseageDaysPopStore } = CommonStore;

import { getKYUseageDays } from '../../service/admin/ContractService';
import { getApiKywonKey } from '../../service/CommonService';
class KYUseageDaysPop extends Component {
	constructor(props) {
		super(props);
		this.state = {
			/* 검색조건 */
			sDate: moment(),
			sCustReqNo: '',

			excelList: [], //엑셀다운로드용 리스트
		};

		this.sheetData = {
			id: 'kYUseageDaysPopSheet', // sheet id
			initData: 'rental/Init-KYUseageDaysPop', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '400px', // sheet height
		};
	}

	componentWillUnmount = () => {
		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}
	};

	popClose = () => {
		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}

		this.setState({
			sDate: moment(),
			sCustReqNo: '',

			excelList: [], //엑셀다운로드용 리스트
		});
		KYUseageDaysPopStore.closePopup();
	};

	getAssetList = () => {
		let param = {
			custNo: KYUseageDaysPopStore.custNo,
		};

		// 교원에서 부여하는 인증키 가져오기
		getApiKywonKey(param)
			.then((r) => {
				let apiKywonKey = r[0].key;

				let param2 = {
					key: apiKywonKey,
					custNo: param.custNo,
					custReqNo: this.state.sCustReqNo,
					yyyyMm: dateToFormat(this.state.sDate).substring(0, 7).replace('-', ''),
				};
				console.log(param2);

				// 인증키 + 년월를 통해 자산사용일수 가져오기
				ProgressStore.activeProgress();
				getKYUseageDays(param2)
					.then((r) => {
						//엑셀용
						this.makeExcelData(r);
						window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
					})
					.catch((err) => {
						errorMsgCheck(err);
					})
					.finally(() => {
						ProgressStore.deactiveProgress();
					});
			})
			.catch((err) => {
				errorMsgCheck(err);
			});
	};

	makeExcelData = (data) => {
		let tmpList = [];
		let tmp = {};
		data.map((item, idx) => {
			tmp = {};
			tmp.No = idx + 1;
			tmp.고객요청번호 = item.orderNo;
			tmp.년월 = item.yyyyMm;
			tmp.사용일 = item.studyDays;
			tmpList.push(tmp);
		});
		this.setState({ excelList: tmpList });
	};

	handleTextArray = (text) => {
		if (text === '') return;
		this.setState({
			sCustReqNo: text,
		});
	};

	dateHandler = (date) => {
		this.setState({ sDate: date });
	};

	render() {
		return (
			<div>
				<Dialog open={KYUseageDaysPopStore.open} TransitionComponent={Transition} onClose={this.popClose}>
					<div className="popSmArea searchPop" style={{ transform: 'translate(-9%, 0)' }}>
						<div className="popCont" style={{ height: 670, width: 940, overflow: 'hidden' }}>
							<div className="h2Wrap">
								<h2>교원월별자산사용일조회</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.getAssetList} style={{ marginRight: 6 }}>
										<span>조회</span>
									</a>
									{/* <a className="btn01 save" onClick={this.handleExcelBtn}><span>엑셀</span></a>*/}
									<ExcelDown
										modalOpen={ModalStore.openModal}
										data={this.state.excelList}
										text="엑셀"
										filename={this.state.sDate + '_교원월별자산사용일조회.csv'}
									/>
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '90px' }} />
										<col style={{ width: '120px' }} />
										<col style={{ width: '90px' }} />
										<col style={{ width: '280px' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>기준년월</th>
											<td>
												<DatePicker
													customInput={<input type="text" style={{ width: 80 }} />}
													dateFormat="YYYY-MM"
													onChange={(date) => this.dateHandler(date, 's')}
													selected={this.state.sDate}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
											</td>
											<th>고객요청번호</th>
											<td colSpan="3">
												<input
													type="text"
													style={{ marginRight: 10, width: 120 }}
													value={this.state.sCustReqNo}
													onChange={(e) => this.setState({ sCustReqNo: e.target.value })}
												/>
												<Button
													color="primary"
													variant="outlined"
													size="small"
													style={{ marginLeft: 5, alignItems: 'initial' }}
													onClick={() => LargeInputNoPopupStore.openPopup(this.handleTextArray)}
												>
													<AddIcon style={{ fontSize: 20, marginRight: 5 }} />
													대량입력
												</Button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="writeType03">
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">자산리스트</p>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
							<div className="btnClose" onClick={this.popClose}>
								<a>팝업닫기</a>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}
}
export default observer(KYUseageDaysPop);
