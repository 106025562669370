import React, { Component } from 'react';

import IBSheet from '../IBSheet';
import SaveIcon from '@material-ui/icons/Save';
import ReturnIcon from '@material-ui/icons/LocalShipping';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import ExcelUpIcon from '@material-ui/icons/Description';
import { Transition, errorMsgCheck, resultToIbSheet } from '../../common/ComFunction';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { insertExcelRentOrder } from '../../service/rental/RentalOrderService';
import * as ComFunction from '../../common/ComFunction';
import * as XLSX from 'xlsx';
import Papaparse from 'papaparse'

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore } = CommonStore;

class RentalOrderExcel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			uploadList: [],
			selectedItem: '',
		};

		this.sheetData = {
			id: 'rentalOrderExcel', // sheet id
			initData: 'rental/Init-RentalOrderExcel', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '400px', // sheet height
		};
	}

	componentWillUnmount = () => {
		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}
	};

	componentDidUpdate = () => {
		if (!this.props.open) return;
	};

	popClose = () => {
		this.props.closeFnc(true);

		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}
	};
	searchFile = (e) => {
		e.stopPropagation();
		e.preventDefault();
		var file = e.target.files[0];

		const reader = new FileReader();
		reader.onload = (evt) => {
			// evt = on_file_select event
			/* Parse data */
			const bstr = evt.target.result;
			const wb = XLSX.read(bstr, { type: 'binary' });
			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			/* Convert array of arrays */
			const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
			
			const parsedData = Papaparse.parse(data, {delimiter:",", header:true, skipEmptyLines:true, dynamicTyping:true,
				transformHeader: (header) => {
					return header.replace(/ /g, '');
				}});
			const result = parsedData.data.map(obj => {
				return {
					"no" : obj.순번,
					"noSeq" : obj.순서,
					"userId" : obj.발주자ID,
					"item" : obj.신청품목,
					"itemQty" : obj.수량,
					"userDeptName" : obj.사용자부서명,
					"userEmpno" : obj.사용자사번,
					"userName" : obj.사용자명,
					"userRemark" : obj.비고,
					"delPostCode" : obj.우편번호,
					"delAddr" : obj.주소1,
					"delAddrDtl" : obj.주소2,
					"delUserNm" : obj.수령인명,
					"delDeptNm" : obj.수령인부서,
					"delMobileNo" : obj.휴대폰번호,
					"delTelNo" : obj.전화번호,
					"delRemark" : obj.요청사항,
					"custPordeNo" : obj.고객사관리번호,
				}
			});
			/* Update state */

			//console.log(ComFunction.csvToJson(data)); // shows data in json format
			this.setState({
				uploadList: result
			});
		};
		reader.readAsBinaryString(file);
	};

	excelUpload = () => {
		this.resetSheet();

		//console.log(arrData);
		window[this.sheetData.id].LoadSearchData(ComFunction.resultToIbSheet(this.state.uploadList));

		var sheetIntvl = setInterval(() => {
			if (window[this.sheetData.id] !== undefined && window[this.sheetData.id].LastRow() > 0) {
				clearInterval(sheetIntvl);
				window.custItemSheetCombo(this.props.custItemList);
			}
		}, 100);
	};

	excelFormDown = () => {
		ComFunction.excelFormDownload('http://doc.ajr.co.kr/download/OUT/file1855936649081712265.xlsx', '대량발주양식.xlsx');
	};

	applyItems = () => {
		if (this.state.selectedItem === '') {
			ModalStore.openModal('적용 모델을 선택해 주세요.');
			return;
		}
		if (window[this.sheetData.id].LastRow() === 0) {
			ModalStore.openModal('엑셀을 먼저 업로드 해주세요.');
			return;
		}
		window.sheetApplyItems(this.state.selectedItem);
	};

	orderConfirm = () => {
		ConfirmModalStore.openModal('신청 하시겠습니까?', this.rentalOrder);
	};

	rentalOrder = () => {
		let orderData = window.getOrderList();

		let ordeStatus = '';
		if (sessionStorage.appUse === 'Y' && sessionStorage.authCode > 2) {
			ordeStatus = 0;
		} else if (sessionStorage.appUse === 'Y' && sessionStorage.authCode === '2' && sessionStorage.repCustNo !== '') {
			//결제사용하는데 고객관리자고 대표고객은 있는경우
			ordeStatus = 0;
		} else {
			ordeStatus = 1;
		}

		if (orderData) {
			let param = {
				ordeStatus: ordeStatus,
				custNo: sessionStorage.custNo,
			};

			ProgressStore.activeProgress();

			insertExcelRentOrder(orderData, param)
				.then(() => {
					ModalStore.openModal('신청 되었습니다.');
					ProgressStore.deactiveProgress();
					this.resetSheet();
				})
				.catch((err) => {
					errorMsgCheck(err, ProgressStore.deactiveProgress());
				});
		}
	};

	resetSheet = () => {
		window[this.sheetData.id].LoadSearchData(
			{
				data: [],
			},
			{
				Sync: 1,
			},
		);
	};

	render() {
		const props = this.props;

		return (
			<div>
				<Dialog
					open={props.open}
					TransitionComponent={Transition}
					// onClose={this.popClose}
				>
					<div className="popSmArea searchPop" style={{ transform: 'translate(-15%, 0)' }}>
						<div className="popCont" style={{ height: 665, width: 1170, overflow: 'hidden' }}>
							<div className="h2Wrap">
								<h2>엑셀발주등록</h2>
							</div>
							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '90px' }} />
										<col />
									</colgroup>
									<tbody>
										<tr>
											<th>품목적용</th>
											<td>
												<select onChange={(e) => this.setState({ selectedItem: e.target.value })} value={this.state.selectedItem} style={{ width: 250 }}>
													<option key={0} value={''}>
														선택하세요
													</option>
													{props.custItemList.map((item, i) => (
														<option key={i + 1} value={item.itemSeq}>
															{'[' + item.grpCd + ']' + item.model + ', ' + item.contTerm + '개월'}
														</option>
													))}
												</select>
												<Button
													color="primary"
													variant="contained"
													size="small"
													style={{ alignItems: 'initial', marginLeft: 15 }}
													onClick={this.applyItems}
													disabled={this.state.uploadList.length === 0 ? true : false}
												>
													<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
													적용
												</Button>
											</td>
										</tr>
									</tbody>
								</table>

								<div style={{ float: 'right', marginTop: 15 }}>
									<Button
										color="secondary"
										variant="contained"
										size="small"
										style={{ alignItems: 'initial', marginBottom: '10%' }}
										onClick={this.excelFormDown}
									>
										<ArrowDownwardIcon style={{ fontSize: 20, marginRight: 5 }} />
										엑셀양식
									</Button>
								</div>
							</div>

							<div className="writeType04">
								<IBSheet {...this.sheetData} />
								<div style={{ marginTop: 35, float: 'right' }}>
									<input type="file" id="file" ref="fileUploader" onChange={(e) => this.searchFile(e)} />
									<Button
										color="primary"
										variant="contained"
										size="small"
										style={{ alignItems: 'initial', marginRight: 15 }}
										onClick={this.excelUpload}
										disabled={this.state.uploadList.length === 0 ? true : false}
									>
										<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
										업로드
									</Button>
									<Button
										color="secondary"
										variant="contained"
										size="small"
										style={{ alignItems: 'initial' }}
										onClick={this.orderConfirm}
										disabled={this.state.uploadList.length === 0 ? true : false}
									>
										<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
										발주신청
									</Button>
								</div>
							</div>
							<div className="btnClose" onClick={this.popClose}>
								<a>팝업닫기</a>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}
}
export default observer(RentalOrderExcel);
