import React, { Component } from 'react';

import Board from './Board';
import Banner from './include/Banner';
import Chart from './Chart';
import Header from './include/Header';
import { isIE } from '../common/ComFunction';
import IBSheet from './IBSheet';
import { getMallPaymentTargetList } from '../service/admin/MallService';
import moment from 'moment';
import { CUST_NO_HDENG, CUST_NO_HAS } from '../common/ComValues';

import { observer } from 'mobx-react';
import CommonStore from '../common/CommonStore';
import { getCustomData } from '../service/CommonService';

const { ModalStore, ConfirmModalStore, ProgressStore } = CommonStore;

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isBanner: false,
			customData: [],
		};

		this.sheetData = {
			id: 'dummySheet', // sheet id
			initData: 'Init-Dummy', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '600px', // sheet height
		};
	}

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
	};
	componentDidMount() {
		// 20190107 kkj ie용 ibsheet 최초로딩 dummy sheet 체크
		if (isIE()) {
			ProgressStore.activeProgress();
			var timer = setInterval(() => {
				console.log(document.getElementById(this.sheetData.id + '-container').hasChildNodes());
				if (document.getElementById(this.sheetData.id + '-container').hasChildNodes()) {
					ProgressStore.deactiveProgress();
					clearInterval(timer);
				}
			}, 300);
		}

		//로그인한 사용자의 정기결제 대상이 있으면 안내얼럿
		getMallPaymentTargetList({ empNo: sessionStorage.empno, payDate: moment().format('YYYY-MM') }).then((datas) => {
			if (datas.length > 0) {
				const data = datas[0];
				ConfirmModalStore.openModal(`${data.custNm}(${data.custNo})의 정기결제가 필요합니다. 이동하시겠습니까?`, () => {
					this.props.history.push('/MallRegularPay', null);
				});
			}
		});

		Promise.all([getCustomData({ custNo: sessionStorage.custNo })]).then((r) => {
			let [data] = r;
			let arr = new Map();

			if (data.length > 0) {
				for (var i = 0; i < data.length; i++) {
					if (data[i].url != null && data[i].url != '') {
						arr.set(data[i].customCode, data[i].url);
					} else {
						arr.set(data[i].customCode, data[i].customWord);
					}
				}
			}

			this.setState(
				{
					customData: Object.fromEntries(arr),
				},
				() => {
					if (this.state.customData.hasOwnProperty('CT036')) window.open(this.state.customData['CT036'], '_blank');
					this.setState({
						isBanner: this.state.customData.hasOwnProperty('CT034') ? true : false,
					});
				},
			);
		});

		let customData = JSON.parse(sessionStorage.getItem('customData'));
		if (customData != null && customData.hasOwnProperty('CT036')) {
			window.open(customData['CT036'], '_blank');
		}
	}

	render() {
		const isBanner = this.state.isBanner;
		let banner;

		if (isBanner) {
			banner = <Banner />;
		} else {
			banner = null;
		}

		return (
			<div>
				<Header menuList={sessionStorage.menuList} />
				<div className="container" id="container">
					<div className="inner">
						<div className="whiteWrap">
							{/* 차트 */}
							<Chart custNo={sessionStorage.custNo} />
							{/* 공지사항 & Q&A*/}
							<Board custNo={sessionStorage.custNo} />
						</div>
						{/* 배너 */}
						{banner}
						{/* ie용 최초 로딩 dummy ibhseet */}
						<div style={{ display: 'none' }}>
							<IBSheet {...this.sheetData} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(Home);
