import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

const tabHeight = '24px'; // default: '48px'
const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
	tabsRoot: {
		borderBottom: '1px solid #e8e8e8',
		minHeight: tabHeight,
		height: tabHeight,
	},
	tabsIndicator: {
		backgroundColor: '#1890ff',
	},
	tabRoot: {
		textTransform: 'initial',
		minWidth: 72,
		minHeight: tabHeight,
		height: tabHeight,
		fontWeight: theme.typography.fontWeightMedium,
		marginRight: theme.spacing.unit * 4,
		fontSize: '15px',
		fontFamily: ['Noto Sans KR'].join(','),
		'&:hover': {
			color: '#40a9ff',
			opacity: 1,
		},
		'&$tabSelected': {
			color: '#1890ff',
			fontWeight: theme.typography.fontWeightBold,
		},
		'&:focus': {
			color: '#40a9ff',
		},
	},
	tabSelected: {},
	typography: {
		padding: theme.spacing.unit * 3,
	},
});

function TabContainer(props) {
	return <Typography component="div">{props.children}</Typography>;
}
TabContainer.propTypes = {
	children: PropTypes.node.isRequired,
};

class Tabs3 extends React.Component {
	state = {
		value: 0,
	};

	handleChange = (event, value) => {
		this.setState({ value });
		this.props.tabChange(value);
	};

	render() {
		const { classes, label1, label2, label3, tabContent1, tabContent2, tabContent3 } = this.props;
		const { value } = this.state;

		return (
			<div className={classes.root}>
				<Tabs value={value} onChange={this.handleChange} classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}>
					<Tab disableRipple classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label={label1} />
					<Tab disableRipple classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label={label2} />
					<Tab disableRipple classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label={label3} />
				</Tabs>
				{/* <Typography className={classes.typography}>Ant Design UI powered by Material-UI</Typography> */}
				{value === 0 && tabContent1 && <TabContainer>{tabContent1}</TabContainer>}
				{value === 1 && tabContent2 && <TabContainer>{tabContent2}</TabContainer>}
				{value === 2 && tabContent3 && <TabContainer>{tabContent3}</TabContainer>}
			</div>
		);
	}
}

Tabs3.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Tabs3);
