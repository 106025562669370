import React, { Component } from 'react';
import IBSheet from '../IBSheet';

import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';

import { errorMsgCheck, buildSelectItems, resultToIbSheet, Transition } from '../../common/ComFunction';
import { getRentalItemGroup } from '../../service/CommonService';
import { getRentalStock } from '../../service/include/StockService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ProgressStore, StockPopupStore } = CommonStore;

class StockPopup extends Component {
	constructor(props) {
		super(props);

		this.sheetData = {
			id: 'stockPopupSheet', // sheet id
			initData: 'include/Init-StockPopup', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '300px', // sheet height
		};

		this.sheetData2 = {
			id: 'assetPopupSheet', // sheet id
			initData: 'include/Init-AssetPopup', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '300px', // sheet height
		};
	}

	componentDidUpdate = () => {
		if (StockPopupStore.open && StockPopupStore.arrLargeCate.length === 0) {
			let param = {
				rootCd: '04',
				level: '1',
			};
			getRentalItemGroup(param)
				.then((r) => {
					StockPopupStore.setArrLargeCate(r);
				})
				.catch((err) => {
					errorMsgCheck(err);
				});
		}
	};

	popClose = (data) => {
		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}
		if (window[this.sheetData2.id]) {
			window[this.sheetData2.id].DisposeSheet();
		}
		StockPopupStore.closePopup(data);
	};

	getSelectStockItem = (type) => {
		let data;
		if (type === 'A') {
			data = window.getStockSelectData();
		} else if (type === 'B') {
			data = window.getAssetSelectData();
		}
		if (!data) return;

		this.popClose(data);
	};

	getStock = (type) => {
		let param = {
			searchType: type,
			grpCd: StockPopupStore.largeCate,
			model: StockPopupStore.model,
			itemCd: StockPopupStore.itemCode,
			rootCd: StockPopupStore.rootCd,
		};

		ProgressStore.activeProgress();
		getRentalStock(param)
			.then((r) => {
				if (type === 'A') {
					window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				} else if (type === 'B') {
					window[this.sheetData2.id].LoadSearchData(resultToIbSheet(r));
				}
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	activeSheet = (type) => {
		if (type === 'A') {
			return <IBSheet {...this.sheetData} />;
		} else if (type === 'B') {
			return <IBSheet {...this.sheetData2} />;
		}
	};

	modelHandler = (model) => {
		StockPopupStore.setModel(model);
	};

	enterKeyPress = (e) => {
		if (e.which === 13) {
			this.getStock(StockPopupStore.stockType);
		}
	};

	render() {
		const { open, stockType, model, modelTitle, itemCode, arrLargeCate } = StockPopupStore;
		//console.log(stockType);

		return (
			<div>
				<Dialog open={open} TransitionComponent={Transition} onClose={this.popClose}>
					<div className="popSmArea searchPop" style={{ transform: 'translate(-8%, 0)' }}>
						<div className="popCont" style={{ height: 520, width: 900, overflow: 'hidden' }}>
							<div className="h2Wrap">
								<h2>재고조회 - 요청모델:{modelTitle}</h2>
							</div>
							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: 80 }} />
										<col style={{ width: 100 }} />
										<col style={{ width: 80 }} />
										<col style={{ width: 210 }} />
										<col style={{ width: 80 }} />
										<col style={{ width: 110 }} />
										<col />
									</colgroup>
									<tbody>
										<tr>
											<th>대분류</th>
											<td>
												<select value={StockPopupStore.largeCate} onChange={(e) => StockPopupStore.setLargeCate(e.target.value)}>
													{arrLargeCate ? buildSelectItems([{ title: '전체', value: '' }].concat(arrLargeCate)) : null}
												</select>
											</td>
											<th>모델명</th>
											<td>
												<input
													type="text"
													value={model}
													onChange={(e) => StockPopupStore.setModel(e.target.value)}
													style={{ width: 200 }}
													onKeyPress={(e) => this.enterKeyPress(e)}
												/>
											</td>
											<th>제품코드</th>
											<td>
												<input type="text" value={itemCode} onChange={(e) => StockPopupStore.setItemCode(e.target.value)} style={{ width: 100 }} />
											</td>
											<td>
												<div className="btnRight">
													<a className="btn01 search" onClick={() => this.getStock(stockType)}>
														<span>조회</span>
													</a>
												</div>
											</td>
										</tr>
										<tr>
											<td colSpan="7" style={{ textAlign: 'center', paddingTop: 1, paddingBottom: 1 }}>
												<Checkbox checked={stockType === 'A' ? true : false} />
												상품
												<Checkbox checked={stockType === 'B' ? true : false} color="primary" />
												자산재고
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="writeType04" style={{ marginTop: -14 }} onDoubleClick={() => this.getSelectStockItem(stockType)}>
								{this.activeSheet(stockType)}
							</div>
							<div className="btnClose" onClick={this.popClose}>
								<a>팝업닫기</a>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}
}
export default observer(StockPopup);
