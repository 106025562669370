import React, { Component } from 'react';

import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import { Transition } from '../../common/ComFunction';

import { postAssetReturnAsk } from '../../service/rental/RentalAssetService';
import { errorMsgCheck, InsertComma } from '../../common/ComFunction';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore, PostPopupStore, AssetReturnPopStore } = CommonStore;

class AssetReturnPop extends Component {
	constructor(props) {
		super(props);

		this.state = {
			returnDate: moment(),
			returnRmk: '',
			selfInputChk: true,
			spotSearchChk: false,
			delUserNm: '',
			delTelNo: '',
			delMobileNo: '',
			delPostCode: '',
			delAddr: '',
			delAddrDtl: '',
			delRmk: '',

			updateComplete: false,
		};
	}

	popClose = (updateComplete) => {
		AssetReturnPopStore.closePopup(updateComplete);
	};

	returnAsk = () => {
		if (this.state.returnDate === null) {
			ModalStore.openModal('반납 요청일자를 입력해 주세요.');
			return;
		}
		if (this.state.delUserNm === '') {
			ModalStore.openModal('담당자 이름을 입력해 주세요');
			return;
		}
		if (this.state.delTelNo === '' && this.state.delMobileNo === '') {
			ModalStore.openModal('전화번호, 휴대폰번호중 하나는 반드시 입력해 주세요.');
			return;
		}
		if (this.state.delPostCode === '') {
			ModalStore.openModal('우편번호가 없습니다. 조회 버튼을 클릭하여 우편번호를 조회 하세요.');
			return;
		}
		if (this.state.delAddr === '') {
			ModalStore.openModal('우편번호 조회 버튼을 클릭하여 주소를 선택해 주세요.');
			return;
		}
		if (this.state.delAddrDtl === '') {
			ModalStore.openModal('상세 주소를 입력해 주세요.');
			return;
		}

		ConfirmModalStore.openModal('반납 신청을 진행 하시겠습니까?', this.postAssetReturn);
	};

	postAssetReturn = async () => {
		if (AssetReturnPopStore.arrReturnData.length > 0) {
			let arrData = AssetReturnPopStore.arrReturnData;

			ProgressStore.activeProgress();
			for (var i = 0; i < arrData.length; i++) {
				let param = {
					asstSeq: arrData[i].asstSeq,
					asstNo: arrData[i].asstNo,
					contNo: arrData[i].contNo,
					custNo: AssetReturnPopStore.rtnInfo.custNo,
					returnDate: this.state.returnDate.format('YYYY-MM-DD'),
					returnRmk: this.state.returnRmk,
					delPostCode: this.state.delPostCode,
					delAddr: this.state.delAddr,
					delAddrDtl: this.state.delAddrDtl,
					delUserNm: this.state.delUserNm,
					delTelNo: this.state.delTelNo,
					delMobileNo: this.state.delMobileNo,
					delRmk: this.state.delRmk,
					userId: sessionStorage.userId,
					addrSeq: sessionStorage.addrSeq,
				};

				await postAssetReturnAsk(param)
					.then(() => {})
					.catch((err) => {
						errorMsgCheck(err);
					});

				if (i + 1 == arrData.length) {
					ModalStore.openModal('반납 신청이 완료 되었습니다.');
					this.setEmptyField(); //초기화
					this.popClose(true);
					ProgressStore.deactiveProgress();
				}
			}
		} else {
			let param = {
				asstSeq: AssetReturnPopStore.rtnInfo.asstSeq,
				asstNo: AssetReturnPopStore.rtnInfo.asstNo,
				contNo: AssetReturnPopStore.rtnInfo.contNo,
				custNo: AssetReturnPopStore.rtnInfo.custNo,
				returnDate: this.state.returnDate.format('YYYY-MM-DD'),
				returnRmk: this.state.returnRmk,
				delPostCode: this.state.delPostCode,
				delAddr: this.state.delAddr,
				delAddrDtl: this.state.delAddrDtl,
				delUserNm: this.state.delUserNm,
				delTelNo: this.state.delTelNo,
				delMobileNo: this.state.delMobileNo,
				delRmk: this.state.delRmk,
				cancelFee: AssetReturnPopStore.rtnInfo.cancelFee,
				userId: sessionStorage.userId,
				addrSeq: sessionStorage.addrSeq,
			};
			ProgressStore.activeProgress();
			postAssetReturnAsk(param)
				.then(() => {
					ModalStore.openModal('반납 신청이 완료 되었습니다.');
					this.setEmptyField(); //초기화
					this.popClose(true);
				})
				.catch((err) => {
					errorMsgCheck(err);
				})
				.finally(() => {
					ProgressStore.deactiveProgress();
				});
		}
	};

	selectShipMethod = (type) => {
		if (type === 1) {
			//직접입력
			this.setEmptyField();
			this.setState({
				returnRmk: '',
				selfInputChk: true,
				spotSearchChk: false,
			});
		} else if (type === 2) {
			//지점정보 가져오기
			this.setState({
				selfInputChk: false,
				spotSearchChk: true,
			});
			CustSearchPopupStore.openPopup('2', null, AssetReturnPopStore.rtnInfo.custNo, this.handleSpotClose);
		}
	};

	setEmptyField = () => {
		this.setState({
			delPostCode: '',
			delAddr: '',
			delAddrDtl: '',
			delUserNm: '',
			delTelNo: '',
			delMobileNo: '',
			delRmk: '',
			returnRmk: '',
		});
	};

	handleSpotClose = (item) => {
		if (item === undefined) return;
		this.setState({
			delUserNm: item.addrUser,
			delTelNo: item.telNo,
			delMobileNo: item.mobileNo,
			delPostCode: item.postCode,
			delAddr: item.postAddr,
			delAddtDtl: item.postSddr,
		});
	};

	setAddress = (data) => {
		if (data.postCode !== undefined) {
			this.setState(
				{
					delPostCode: data.postCode,
					delAddr: data.address,
					delAddrDtl: '',
				},
				() => {
					this.delAddrDtl.focus();
				},
			);
		}
	};

	render() {
		const dateInput = <input type="text" style={{ width: 120 }} />;

		let redPointText = {
			color: '#d41826',
			marginLeft: 10,
			fontWeight: 'normal',
		};

		return (
			<div>
				<Dialog open={AssetReturnPopStore.open} TransitionComponent={Transition} onClose={() => this.popClose(false)}>
					<div className="popSmArea searchPop">
						<div className="popCont" style={{ height: 620, overflow: 'hidden' }}>
							<div className="h2Wrap">
								<h2>자산반납신청</h2>
							</div>
							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '100px' }} />
										<col />
									</colgroup>
									<tbody>
										<tr>
											<th>위약금</th>
											<td>
												{AssetReturnPopStore.rtnInfo.cancelFee && AssetReturnPopStore.rtnInfo.cancelFee !== '0' ? (
													<span style={redPointText}>{InsertComma(AssetReturnPopStore.rtnInfo.cancelFee)}원 (당월 또는 익월 청구)</span>
												) : (
													<span style={redPointText}>없음</span>
												)}
											</td>
										</tr>
										<tr>
											<th>반납요청일</th>
											<td>
												<DatePicker
													customInput={dateInput}
													dateFormat="YYYY-MM-DD"
													selected={this.state.returnDate}
													onChange={(date) => this.setState({ returnDate: date })}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
												<span style={redPointText}>*최소 +2일을 입력해 주세요</span>
											</td>
										</tr>
										<tr>
											<th>반납사유</th>
											<td>
												<textarea
													type="text"
													value={this.state.returnRmk}
													rows="2"
													placeholder="반납 사유를 입력해 주세요"
													onChange={(e) => this.setState({ returnRmk: e.target.value })}
													style={{ height: 60 }}
												/>
											</td>
										</tr>
										<tr>
											<th>주소입력방식</th>
											<td>
												<Checkbox checked={this.state.selfInputChk} onClick={() => this.selectShipMethod(1)} style={{ color: '#f50057' }} />
												직접입력
												<Checkbox checked={this.state.spotSearchChk} onClick={() => this.selectShipMethod(2)} style={{ color: '#01DF3A' }} />
												지점정보 가져오기
											</td>
										</tr>
										<tr>
											<th>담당자</th>
											<td>
												<input
													type="text"
													placeholder="이름"
													value={this.state.delUserNm}
													onChange={(e) => this.setState({ delUserNm: e.target.value })}
													style={{ width: 80 }}
												/>
												<span style={{ marginLeft: 7 }}>사무실</span>
												<input
													type="text"
													placeholder="전화번호 -없이"
													value={this.state.delTelNo}
													onChange={(e) => this.setState({ delTelNo: e.target.value })}
													style={{ width: 110 }}
												/>
												<span style={{ marginLeft: 10 }}>휴대폰</span>
												<input
													type="text"
													placeholder="-없이"
													value={this.state.delMobileNo}
													onChange={(e) => this.setState({ delMobileNo: e.target.value })}
													style={{ width: 120 }}
												/>
											</td>
										</tr>
										<tr>
											<th>주소</th>
											<td>
												<input type="text" placeholder="우편번호" readOnly={true} value={this.state.delPostCode} style={{ width: 80, marginRight: 5 }} />
												<Button color="primary" variant="contained" size="small" onClick={() => PostPopupStore.openPopup(this.setAddress)}>
													조회
												</Button>
												<input type="text" placeholder="주소" readOnly={true} value={this.state.delAddr} style={{ width: 262, marginLeft: 5 }} />
											</td>
										</tr>
										<tr>
											<th>상세주소</th>
											<td>
												<input
													type="text"
													name="delAddrDtl"
													ref={(ref) => (this.delAddrDtl = ref)}
													placeholder="상세주소"
													value={this.state.delAddrDtl}
													onChange={(e) => this.setState({ delAddrDtl: e.target.value })}
												/>
											</td>
										</tr>
										<tr>
											<th>요청사항</th>
											<td>
												<textarea
													type="text"
													value={this.state.delRmk}
													rows="2"
													placeholder="회수시 요청 사항을 입력해 주세요"
													onChange={(e) => this.setState({ delRmk: e.target.value })}
													style={{ height: 60 }}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div style={{ marginTop: 15, textAlign: 'right' }}>
								<Button color="secondary" variant="contained" size="small" onClick={this.returnAsk}>
									<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
									반납
								</Button>
							</div>
							<div className="btnClose" onClick={() => this.popClose(false)}>
								<a>팝업닫기</a>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}
}
export default observer(AssetReturnPop);
