import React, { Component } from 'react';
import DaumPostCode from 'react-daum-postcode';
import Dialog from '@material-ui/core/Dialog';
import { Transition } from '../../common/ComFunction';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { PostPopupStore } = CommonStore;

class PostPopup extends Component {
	constructor(props) {
		super(props);
	}

	handleAddress = (data) => {
		if (data) {
			let fullAddress = data.address;
			let extraAddress = '';

			if (data.addressType === 'R') {
				if (data.bname !== '') {
					extraAddress += data.bname;
				}
				if (data.buildingName !== '') {
					extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
				}
				fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
			}

			let returnData = {
				address: fullAddress,
				postCode: data.zonecode,
			};

			PostPopupStore.closePopup(returnData);
			//console.log(fullAddress);  // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
		} else {
			PostPopupStore.closePopup();
		}
	};

	render() {
		return (
			<Dialog open={PostPopupStore.open} TransitionComponent={Transition} onClose={this.handleAddress}>
				<div style={{ padding: '10px 10px 10px 10px' }}>
					<DaumPostCode onComplete={this.handleAddress} />
				</div>
			</Dialog>
		);
	}
}
export default observer(PostPopup);
