import React, { Component } from 'react';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';
import { errorMsgCheck, mailCheck, resultToIbSheet } from '../../common/ComFunction';
import { getSpotList, saveSpot } from '../../service/members/MembersService';
import Header from '../include/Header';
import { Visibility } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore, PostPopupStore } = CommonStore;

var result = {
	//sheet데이터
	data: [],
};

class SpotManager extends Component {
	constructor(props) {
		super(props);

		this.sheetData = {
			id: 'sheetSpotManager', // sheet id
			initData: 'members/Init-SpotManager', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '600px', // sheet height
		};
	}
	state = {
		//검색조건
		custNo: sessionStorage.authCode === '0' || sessionStorage.authCode === '1' ? '' : sessionStorage.custNo, //고객번호
		custNm: sessionStorage.authCode === '0' || sessionStorage.authCode === '1' ? '' : sessionStorage.custNm, //고객사명
		addrSeqSearch: sessionStorage.authCode === '3' || sessionStorage.authCode === '6' ? sessionStorage.addrSeq : '', //지점시퀀스
		spotNmSearch: sessionStorage.authCode === '3' || sessionStorage.authCode === '6' ? sessionStorage.spotNm : '', //지점명
		addrUserSearch: '', //지점담당자

		//조회결과
		spotList: [], //지점리스트

		//입력항목
		spotId: '', //지점아이디
		addrSeq: '', //지점시퀀스
		spotNm: '', //지점명
		postCode: '', //우편번호
		postAddr: '', //지점주소
		postSddr: '', //지점상세주소
		addrUser: '', //지점담당자
		telNo: '', //전화번호
		mobileNo: '', //휴대폰번호
		email: '', //이메일
		delYn: '', //삭제여부

		chgYn: false, //입력변경여부
		tranType: 'I', //transaction타입(U:수정 / I:추가 / D:삭제)
	};
	resetState = (type) => {
		if (type === 'A') {
			// 전체 초기화
			this.setState({
				// addrSeqSearch     : '',   //지점시퀀스
				// spotNmSearch      : '',   //지점명
				// addrUserSearch    : '',   //지점담당자
				spotList: [], //지점리스트

				tranType: 'U', //transaction타입(U:수정 / I:추가 / D:삭제)
			});
			this.resetResult(); //ibsheet 데이터 리셋
		} else if (type === 'I') {
			// 신규입력상태
			this.setState({
				tranType: 'I', //transaction타입(U:수정 / I:추가 / D:삭제)
			});
		} else if (type === 'U') {
			// 수정가능상태
			this.setState({
				tranType: 'U', //transaction타입(U:수정 / I:추가 / D:삭제)
			});
		}
		this.setState({
			//입력항목
			spotId: '', //지점아이디
			addrSeq: '', //지점시퀀스
			spotNm: '', //지점명
			postCode: '', //우편번호
			postAddr: '', //지점주소
			postSddr: '', //지점상세주소
			addrUser: '', //지점담당자
			telNo: '', //전화번호
			mobileNo: '', //휴대폰번호
			email: '', //이메일
			delYn: '', //삭제여부
			chgYn: false, //입력변경여부
		});
	};
	// 고객사팝업
	handleClickOpen = (popupType) => {
		if (popupType === '1') {
			//고객사 팝업
			CustSearchPopupStore.openPopup(popupType, null, null, this.handleClose);
		} else if (popupType === '2') {
			//지점 팝업
			//지점은 고객사 팝업 검색 후 가능
			if (this.state.custNo === '') {
				ModalStore.openModal('지점검색은 회사명 검색 이후 가능합니다.');
				return;
			}
			CustSearchPopupStore.openPopup(popupType, null, this.state.custNo, this.handleClose);
		}
	};
	handleClose = (item) => {
		if (item !== undefined) {
			if (CustSearchPopupStore.popupType === '1') {
				// 고객사 검색 결과
				if (item.custNo !== undefined) {
					this.setState({
						custNo: item.custNo,
						custNm: item.custNm,
					});
					this.resetState('A');
				}
			} else if (CustSearchPopupStore.popupType === '2') {
				// 지점 검색 결과
				if (item.addrSeq !== undefined) {
					this.setState({
						addrSeqSearch: item.addrSeq, //지점시퀀스
						spotNmSearch: item.spotNm, //지점명
					});
				}
			}
		}
	};
	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
			chgYn: true, // 변경일어남
		});
	};
	handleChangeNum = (e) => {
		// 숫자만 입력받도록
		const value = e.target.value.replace(/\D/, '');
		this.setState({
			[e.target.name]: value,
			chgYn: true, // 변경일어남
		});
	};
	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
	};
	// 다음 주소검색
	handleAddress = (data) => {
		let fullAddress = data.address;
		let postCode = data.postCode;

		this.setState(
			{
				postAddr: fullAddress === undefined ? this.state.postAddr : fullAddress,
				postSddr: fullAddress === undefined ? this.state.postSddr : '',
				postCode: postCode === undefined ? this.state.postCode : postCode,
			},
			() => {
				this.postSddr.focus();
			},
		);
	};

	// 조회된 결과 중 선택한 user input에 셋팅
	setInput = () => {
		let addrSeq = window.getSelectedAddrSeq();
		if (addrSeq !== undefined) {
			this.state.spotList.map((item, idx) => {
				if (item.addrSeq === addrSeq) {
					this.setState({
						spotId: item.spotId, //지점아이디
						addrSeq: item.addrSeq, //지점시퀀스
						spotNm: item.spotNm, //지점명
						postCode: item.postCode, //우편번호
						postAddr: item.postAddr, //지점주소
						postSddr: item.postSddr, //지점상세주소
						addrUser: item.addrUser, //지점담당자
						telNo: item.telNo, //전화번호
						mobileNo: item.mobileNo, //휴대폰번호
						email: item.email, //이메일
						delYn: item.delYn, //삭제여부

						chgYn: false, //입력변경여부
						tranType: 'U', //transaction타입 수정
						userIdRead: true, //userid입력
					});
				}
			});
		}
	};
	//지점리스트 조회
	getSpotList = () => {
		if (this.state.custNo === '') {
			// alert('검색어를 입력하셔야합니다.');
			ModalStore.openModal('검색어를 입력하셔야합니다.');
			this.custNm.focus();
			return;
		}

		ProgressStore.activeProgress();
		let param = {
			custNo: this.state.custNo, //고객번호
			spotNmSearch: this.state.spotNmSearch, //지점명
			addrUserSearch: this.state.addrUserSearch, //지점담당자
		};

		getSpotList(param)
			.then((r) => {
				this.resetResult(); //ibsheet 데이터 리셋

				this.setState({ spotList: r }, () => {
					// JSON객체형태로 서버에서 받으므로 IBsheet에서 출력 가능한 배열형태로 변환
					window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				});

				this.resetState('I');
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};
	// sheet데이터 리셋
	resetResult = () => {
		result = {
			data: [],
		};
		window[this.sheetData.id].LoadSearchData(result, {
			Sync: 1,
		});
	};
	// 삭제
	callDelSpot = () => {
		if (this.state.addrSeq === '') {
			ModalStore.openModal(sessionStorage.spotTitle + '을 먼저 선택하세요.');
			return;
		}
		ConfirmModalStore.openModal(sessionStorage.spotTitle + '을 삭제하시겠습니까?', this.deleteSpot);
	};
	deleteSpot = () => {
		const { addrSeq, custNo } = this.state;

		ProgressStore.activeProgress();

		let param = {
			addrSeq: addrSeq, //지점시퀀스
			custNo: custNo, //고객번호
			delYn: 'Y', //삭제
			tranType: 'D', //transaction 타입
		};

		saveSpot(param)
			.then((r) => {
				// console.log(r);console.log(r.length);console.log(r[0]);
				ModalStore.openModal('삭제되었습니다.');

				// this.resetState();
				this.resetResult(); //ibsheet 데이터 리셋
				this.getSpotList(); //저장 성공 후 재조회
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};
	// 저장
	saveSpot = () => {
		const { spotId, addrSeq, spotNm, postCode, postAddr, postSddr, addrUser, telNo, mobileNo, email, delYn, chgYn, tranType, custNo } = this.state;

		//validation chk
		if (tranType === 'U' && !chgYn) {
			// 수정상태에서 변경 없을 시
			ModalStore.openModal('변경사항이 없습니다.');
			return;
		}
		if (spotNm === '') {
			ModalStore.openModal(sessionStorage.spotTitle + '명을 입력하세요.');
			this.spotNm.focus();
			return;
		}
		if (postCode === '') {
			ModalStore.openModal('우편번호를 입력하세요.');
			this.postCode.focus();
			return;
		}
		if (postAddr === '') {
			ModalStore.openModal('주소를 입력하세요.');
			this.postAddr.focus();
			return;
		}
		if (postSddr === '') {
			ModalStore.openModal('상세주소를 입력하세요.');
			this.postSddr.focus();
			return;
		}
		if (addrUser === '') {
			ModalStore.openModal(sessionStorage.spotTitle + '담당자를 입력하세요.');
			this.addrUser.focus();
			return;
		}
		// if(email === '')      {ModalStore.openModal('이메일을 입력하세요.'); this.email.focus(); return;}
		if (telNo === '') {
			ModalStore.openModal('전화번호를 입력하세요.');
			this.telNo.focus();
			return;
		}
		// if(mobileNo === '')      {ModalStore.openModal('휴대전화를 입력하세요.'); this.mobileNo.focus(); return;}

		if (email !== '' && !mailCheck(email)) {
			ModalStore.openModal('메일주소 형식이 올바르지 않습니다.');
			this.email.focus();
			return;
		}
		// validation chk end
		ProgressStore.activeProgress();

		let param = {
			spotId: spotId, //지점아이디
			addrSeq: addrSeq, //지점시퀀스
			custNo: custNo, //고객번호
			spotNm: spotNm, //지점명
			postCode: postCode, //우편번호
			postAddr: postAddr, //지점주소
			postSddr: postSddr, //지점상세주소
			addrUser: addrUser, //지점담당자
			telNo: telNo, //전화번호
			mobileNo: mobileNo, //휴대폰번호
			email: email, //이메일
			delYn: delYn, //삭제여부
			tranType: tranType, //transaction타입(U:수정 / I:추가 / D:삭제)
		};

		saveSpot(param)
			.then((r) => {
				// console.log(r);console.log(r.length);console.log(r[0]);
				ModalStore.openModal('저장되었습니다.');

				// this.resetState();
				this.resetResult(); //ibsheet 데이터 리셋
				this.getSpotList(); //저장 성공 후 재조회
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};
	// 추가할 수 있도록 readonly 풀고 state 리셋 후 추가상태로 셋팅
	setForAdd = () => {
		this.resetState('I'); //입력가능상태
		this.spotId.focus();
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mb30">
								<h2>{sessionStorage.spotTitle}관리</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.getSpotList}>
										<span>조회</span>
									</a>
									<a
										className="btn03 add"
										onClick={this.setForAdd}
										style={sessionStorage.authCode === '3' || sessionStorage.authCode === '6' ? { display: 'none' } : { display: 'inline-block' }}
									>
										<span>추가</span>
									</a>
									{/* <a className="btn01 save"><span>저장</span></a> */}
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '10%' }} />
										<col style={{ width: '28%' }} />
										<col style={{ width: '10%' }} />
										<col style={{ width: '28%' }} />
										<col style={{ width: '10%' }} />
										<col style={{ width: '14%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>고객명</th>
											<td>
												<input
													type="text"
													className="size01"
													ref={(ref) => (this.custNm = ref)}
													name="custNm"
													value={this.state.custNm || ''}
													onChange={this.handleChange}
													readOnly
												/>
												{/* 고객사검색버튼 */}
												<IconButton
													aria-label="Search"
													className="iconButton"
													onClick={() => this.handleClickOpen('1')}
													style={{ display: sessionStorage.btnControl }}
												>
													<Search fontSize="small" />
												</IconButton>
											</td>
											<th>{sessionStorage.spotTitle}명</th>
											<td>
												<input
													type="text"
													className="size01"
													ref={(ref) => (this.spotNmSearch = ref)}
													name="spotNmSearch"
													value={this.state.spotNmSearch || ''}
													onKeyDown={(e) => {
														if (e.key === 'Enter') this.getSpotList();
													}}
													onChange={(e) => {
														this.handleChange(e);
														this.setState({ addrSeqSearch: '' });
													}}
													readOnly={sessionStorage.authCode === '3' || sessionStorage.authCode === '6' ? true : false}
												/>
												{/* <a className="btn01 search" onClick={() => this.handleClickOpen('2')}><span>검색</span></a> */}
												<IconButton
													aria-label="Search"
													className="iconButton"
													onClick={() => this.handleClickOpen('2')}
													style={{ visibility: sessionStorage.authCode === '3' || sessionStorage.authCode === '6' ? 'hidden' : 'visible' }}
												>
													<Search fontSize="small" />
												</IconButton>
											</td>
											<th>담당자명</th>
											<td>
												<input
													type="text"
													ref={(ref) => (this.addrUserSearch = ref)}
													name="addrUserSearch"
													value={this.state.addrUserSearch || ''}
													onKeyDown={(e) => {
														if (e.key === 'Enter') this.getSpotList();
													}}
													onChange={this.handleChange}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="writeType05 long">
								<div className="left" onClick={this.setInput}>
									<div
										className="scroll"
										onMouseOver={() => {
											document.body.style.overflow = 'hidden';
										}}
										onMouseOut={() => {
											document.body.style.overflow = 'auto';
										}}
									>
										<p className="headline">{sessionStorage.spotTitle} 리스트</p>
										<IBSheet {...this.sheetData} />
									</div>
								</div>
								<div className="right">
									<p className="headline">{sessionStorage.spotTitle} 정보</p>
									<table>
										<colgroup>
											<col style={{ width: '20%' }} />
											<col style={{ width: '30%' }} />
											<col style={{ width: '20%' }} />
											<col style={{ width: '30%' }} />
										</colgroup>
										<tbody>
											<tr>
												<th>{sessionStorage.spotTitle}코드 </th>
												<td colSpan="3">
													<input
														type="text"
														className="size02"
														name="spotId"
														maxLength="15"
														value={this.state.spotId || ''}
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.spotId = ref)}
													/>
													{/* <a className="btn01 blue" onClick={this.chkUserId} style={(this.state.userIdRead) ? {visibility:"hidden"}:{visibility:"visible"}}><span>중복검사</span></a> */}
												</td>
											</tr>
											<tr>
												<th>
													{sessionStorage.spotTitle}명 <span className="star">*</span>
												</th>
												<td>
													<input
														type="text"
														name="spotNm"
														maxLength="25"
														value={this.state.spotNm || ''}
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.spotNm = ref)}
													/>
												</td>
												<th>
													우편번호 <span className="star">*</span>
												</th>
												<td>
													<input
														type="text"
														name="postCode"
														className="size01"
														maxLength="6"
														value={this.state.postCode || ''}
														onChange={(e) => {
															this.handleChangeNum(e);
														}}
														ref={(ref) => (this.postCode = ref)}
													/>
												</td>
											</tr>
											<tr>
												<th>
													{sessionStorage.spotTitle}주소 <span className="star">*</span>
												</th>
												<td colSpan="3">
													{/* <input type="text" name="postCode" className="size04" maxLength="6" value={this.state.postCode || ''} onChange={(e) => {this.handleChangeNum(e);this.setState({addrSeq:''})}} ref={(ref)=>this.postCode=ref} readOnly/>  */}
													<input
														type="text"
														name="postAddr"
														className="size03"
														maxLength="50"
														value={this.state.postAddr || ''}
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.postAddr = ref)}
													/>
													{/* <a className="btn01 black" onClick={() => this.setState({openPost:true})} ><span>주소검색</span></a> */}
													<Button variant="outlined" size="small" className="btnInTable" onClick={() => PostPopupStore.openPopup(this.handleAddress)}>
														주소검색
													</Button>
													<input
														type="text"
														name="postSddr"
														className="size06"
														maxLength="50"
														value={this.state.postSddr || ''}
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.postSddr = ref)}
													/>
												</td>
											</tr>
											<tr>
												<th>이메일 </th>
												<td colSpan="3">
													<input
														type="text"
														className="size01"
														name="email"
														maxLength="100"
														value={this.state.email || ''}
														onChange={this.handleChange}
														ref={(ref) => (this.email = ref)}
													/>
												</td>
											</tr>
											<tr>
												<th>
													{sessionStorage.spotTitle}담당자 <span className="star">*</span>
												</th>
												<td colSpan="3">
													<input
														type="text"
														className="size02"
														name="addrUser"
														maxLength="10"
														value={this.state.addrUser || ''}
														onChange={this.handleChange}
														ref={(ref) => (this.addrUser = ref)}
													/>
												</td>
											</tr>
											<tr>
												<th>
													전화번호 <span className="star">*</span>
												</th>
												<td colSpan="3">
													{/* <input type="text" name="telNo1" className="size05" maxLength="3" value={this.state.telNo1 || ''} onChange={this.handleChangeNum} ref={(ref)=>this.telNo1=ref}/> 
                                                    <span className="dash">-</span> 
                                                    <input type="text" name="telNo2" className="size05" maxLength="4" value={this.state.telNo2 || ''} onChange={this.handleChangeNum} ref={(ref)=>this.telNo2=ref}/> 
                                                    <span className="dash">-</span> 
                                                    <input type="text" name="telNo3" className="size05" maxLength="4" value={this.state.telNo3 || ''} onChange={this.handleChangeNum} ref={(ref)=>this.telNo3=ref}/> */}
													<input
														type="text"
														name="telNo"
														className="size02"
														maxLength="11"
														value={this.state.telNo || ''}
														onChange={this.handleChangeNum}
														ref={(ref) => (this.telNo = ref)}
													/>
												</td>
											</tr>
											<tr>
												<th>휴대전화 </th>
												<td colSpan="3">
													{/* <input type="text" name="mobileNo1" className="size05" maxLength="3"  value={this.state.mobileNo1 || ''} onChange={this.handleChangeNum} ref={(ref)=>this.mobileNo1=ref}/> 
                                                    <span className="dash">-</span> 
                                                    <input type="text" name="mobileNo2" className="size05" maxLength="4" value={this.state.mobileNo2 || ''} onChange={this.handleChangeNum} ref={(ref)=>this.mobileNo2=ref}/> 
                                                    <span className="dash">-</span> 
                                                    <input type="text" name="mobileNo3" className="size05" maxLength="4" value={this.state.mobileNo3 || ''} onChange={this.handleChangeNum} ref={(ref)=>this.mobileNo3=ref}/> */}
													<input
														type="text"
														name="mobileNo"
														className="size02"
														maxLength="11"
														value={this.state.mobileNo || ''}
														onChange={this.handleChangeNum}
														ref={(ref) => (this.mobileNo = ref)}
													/>
												</td>
											</tr>
											{/* <tr>
                                                <th>삭제여부 <span className="star">*</span></th>
                                                <td colSpan="3">                                            
                                                    <select name="appAuthYn" value={this.state.appAuthYn} onChange={this.handleChange}>                                                
                                                        <option value="Y">Y</option>
                                                        <option value="N">N</option>
                                                    </select>
                                                </td>                                       
                                            </tr> */}
										</tbody>
									</table>
									<div className="btnRight2">
										{/* <a className="btn01 save" onClick={this.saveSpot}><span>저장</span></a>
                                        <a className="btn01 min" onClick={this.callDelSpot}><span>삭제</span></a> */}
										<Button className="button" onClick={this.saveSpot} color="primary" variant="contained" size="small">
											<SaveIcon className="saveIcon" />
											<span className="text">저장</span>
										</Button>
										<Button
											className="button"
											onClick={this.callDelSpot}
											color="secondary"
											variant="contained"
											size="small"
											disabled={sessionStorage.authCode === '3' || sessionStorage.authCode === '6' ? true : false}
										>
											<DeleteIcon className="saveIcon" />
											<span className="text">삭제</span>
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(SpotManager);
