import React, { Component } from 'react';
import { makeObservable, observable, action } from 'mobx';
import { observer } from 'mobx-react';

const KYContInfoCheckPopupStore = observable({
	open: false,
	searched: false,
	custNo: '',
	custReqNo: '',
	contInfo: {},
	handleClose: null,

	openPopup(custNo, custReqNo, handleClose) {
		this.open = true;
		this.custNo = custNo;
		this.custReqNo = custReqNo;
		if (handleClose) this.handleClose = handleClose;
		else this.handleClose = null;
	},
	closePopup(item) {
		this.open = false;
		this.custNo = '';
		this.custReqNo = '';
		this.searched = false;
		if (this.handleClose) {
			this.handleClose(item);
		}
		this.contInfo = {};
	},
	setContInfo(contInfo) {
		this.contInfo = contInfo;
	},
	setSearched(searched) {
		this.searched = searched;
	},
});

export default KYContInfoCheckPopupStore;
