import { httpGet, httpPost, httpPut, toQueryString } from '../Service';

// 사용자조회
export function getMemberList(data) {
	return httpGet('/api/v1.0/cis/member' + toQueryString(data));
}

// 사용자 저장(C/U/D)
export function saveMember(data) {
	data = data || {};
	if (data.tranType === 'I') return httpPost('/api/v1.0/cis/member/', { body: data });
	else if (data.tranType === 'U') return httpPut('/api/v1.0/cis/member/', { body: data });
	else return httpPut('/api/v1.0/cis/member/', { body: data });
}
// 엑셀 대량사용자 저장(C/U/D)
// export function insertExcelMember(data, param) {
export function insertExcelMember(data) {
	data = data || {};
	return httpPost('/api/v1.0/cis/member/excel-member', { body: data });
}

// 지점 조회
export function getSpotList(data) {
	return httpGet('/api/v1.0/cis/spot/' + toQueryString(data));
}

// 지점 저장(C/U/D)
export function saveSpot(data) {
	data = data || {};
	if (data.tranType === 'I') return httpPost('/api/v1.0/cis/spot/', { body: data });
	else if (data.tranType === 'U') return httpPut('/api/v1.0/cis/spot/', { body: data });
	else return httpPut('/api/v1.0/cis/spot/', { body: data });
}

// 가입승인요청조회
export function getMemberApprList(data) {
	return httpGet('/api/v1.0/cis/memberAppr' + toQueryString(data));
}

// 가입승인요청 저장(C/U/D)
export function saveMemberAppr(data) {
	data = data || {};
	if (data.tranType === 'I') return httpPost('/api/v1.0/cis/memberAppr/', { body: data });
	else if (data.tranType === 'U') return httpPut('/api/v1.0/cis/memberAppr/', { body: data });
	else return httpPut('/api/v1.0/cis/memberAppr/', { body: data });
}

// 임시고객 새로운 고객 맵핑
export function saveMemberNewCustMapping(data) {
	data = data || {};

	return httpPut('/api/v1.0/cis/memberAppr/newCustMapping/', { body: data });
}

// 제휴사 정보 수정
export function saveMemberNewAlncCustMapping(data) {
	data = data || {};

	return httpPut('/api/v1.0/cis/memberAppr/newAlncCustMapping/', { body: data });
}
