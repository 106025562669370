import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = {
	card: {
		maxWidth: 50,
	},
	media: {
		height: 50,
	},
};

function Thumbnail(props) {
	const { classes, url, width, height } = props;
	if (url) {
		return (
			<Card style={{ maxWidth: width }}>
				<CardActionArea>
					<CardMedia style={{ height: height }} image={url} />
				</CardActionArea>
			</Card>
		);
	} else {
		return null;
	}
}

Thumbnail.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Thumbnail);
