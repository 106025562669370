import React, { Component } from 'react';
import LeftMenu from '../include/LeftMenu';
import Header from '../include/Header';
import IBSheet from '../IBSheet';
import { errorMsgCheck, resultToIbSheet, dateToFormat, managerCheck } from '../../common/ComFunction';
import { getCisCommCode } from '../../service/CommonService';
import { getContOrderItemList } from '../../service/admin/ContractService';
import {
	getCpList,
	setCouponCancel, //현금결제정보발행취소
	setCouponResend, //현금결제정보 재발행
	getCouponInfoSearch, //현금결제정보 조회
	getCouponState, //쿠폰교환내역 조회
	getCouponissuedHistoryList, //쿠폰발급이력 조회
} from '../../service/admin/MobileCouponService';
import { getMallOrderList } from '../../service/admin/MallService';
import Button from '@material-ui/core/Button';
import ExcelDown from '../include/ExcelDown';
import MallMoCpManagerPopup from '../include/MallMoCpManagerPopup';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore } = CommonStore;

class MallMoCpManager extends Component {
	constructor(props) {
		super(props);

		this.sheetMallOrderList = {
			//주문 리스트
			id: 'sheetMallOrderList', // sheet id
			initData: 'admin/Init-MallOrderList2', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '300px', // sheet height
		};
		this.couponInfoListSheet = {
			//쿠폰 발급 내역
			id: 'couponInfoListSheet', // sheet id
			initData: 'admin/Init-CouponInfoList', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '300px', // sheet height
		};
		this.couponissuedHistoryListSheet = {
			//쿠폰 발급 이력
			id: 'couponissuedHistoryListSheet', // sheet id
			initData: 'admin/Init-CouponissuedHistoryList', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '300px', // sheet height
		};
	}

	state = {
		arrDelMethod: [],
		arrOrderStatus: [],

		//검색조건
		custNo: '',
		custNm: '', //고객명
		userName: '', //사용자명
		sDate: null, //주문일자
		eDate: null, //주문일자
		selPayYn: 'Y', //결제여부
		selPay: '', //결제방법
		contYn: 'N', //계약여부
		selOrderType: '', //주문구분 R:렌탈, S:유통

		ordeNo: '', //주문번호
		ordeUser: '선택하신 주문정보가 없습니다.', //주문자
		goodsCnt: '선택하신 주문정보가 없습니다.', //수량
		goodsId: '', //상품id
		trId: '', // TR_ID
		status: '',
		couponExchangeState: '아래 조회버튼을 클릭 해주세요.', //쿠폰교환내역
		couponState: '아래 조회버튼을 클릭 해주세요.',
		moneyInfo: '좌측 쿠폰 발급 내역을 선택해주세요.',
		contNo: '',
		delMethod: '',

		//요청제품상세
		model: '선택하신 주문정보가 없습니다.', //상품
		userName: '',
		monthPrc: '',
		salePrc: '',

		//카드 및 가상계좌 결제 정보
		rentType: '렌탈',
		cashRecieptHp: '',
		cashrcptResultMsg: '',

		//엑셀
		excelList: [],

		//버튼
		displayBtn: 'none',
		clickStatus: false,

		//검색팝업
		openCustSearch: false, //고객검색팝업

		MallMoCpManagerPopup: false,
	};

	componentDidMount = () => {
		managerCheck(); // 관리자체크
		Promise.all([getCisCommCode('04'), getCisCommCode('11')]).then((r) => {
			let [code04, code11] = r;

			this.setState({
				arrDelMethod: code04,
				arrOrderStatus: code11,
			});
		});
	};

	//쿠폰등록 닫기
	handleClose = (item) => {
		this.setState({
			openCustSearch: false,
		});
	};

	handleMallMoCpInst = (item) => {
		this.setState({
			MallMoCpManagerPopup: false,
		});
	};

	//쿠폰등록이벤트
	mallMoCpInst = () => {
		if (!this.state.clickStatus) {
			ModalStore.openModal('선택된 주문이 없습니다.');
			return;
		}

		let ordeNo = sheetMallOrderList.GetCellValue(sheetMallOrderList.GetSelectionRows(), 'ordeNo');
		let ordeUser = sheetMallOrderList.GetCellValue(sheetMallOrderList.GetSelectionRows(), 'ordeUser');
		let model = sheetMallOrderList.GetCellValue(sheetMallOrderList.GetSelectionRows(), 'model');
		let goodsCnt = sheetMallOrderList.GetCellValue(sheetMallOrderList.GetSelectionRows(), 'goodsCnt');
		let goodsId = sheetMallOrderList.GetCellValue(sheetMallOrderList.GetSelectionRows(), 'goodsId');

		this.setState({
			ordeNo: ordeNo,
			ordeUser: ordeUser,
			model: model,
			goodsCnt: goodsCnt,
			goodsId: goodsId,
			MallMoCpManagerPopup: true,
		});
	};

	//조회
	getOrderList = (itemReSearch) => {
		ProgressStore.activeProgress();

		let param = {
			custNo: this.state.custNo,
			ordeUser: this.state.userName,
			startDate: dateToFormat(this.state.sDate),
			endDate: dateToFormat(this.state.eDate),
			payYn: this.state.selPayYn,
			payType: this.state.selPay,
			contYn: this.state.contYn,
			orderType: this.state.selOrderType,
		};

		getMallOrderList(param)
			.then((r) => {
				window[this.sheetMallOrderList.id].LoadSearchData(resultToIbSheet(r));

				this.makeExcelData(r);
				this.setInit();
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};

	//조회낸 내용 초기화
	setInit = () => {
		couponInfoListSheet.RemoveAll(); //쿠폰 발급 내역 초기화
		couponissuedHistoryListSheet.RemoveAll(); //쿠폰 발급 이력 초기화

		this.setState({
			trId: '',
			ordeNo: '',
			moneyInfo: '좌측 쿠폰 발급 내역을 선택해주세요.',
			couponState: '아래 조회버튼을 클릭 해주세요.',
			model: '선택하신 주문정보가 없습니다.',
			ordeUser: '선택하신 주문정보가 없습니다.',
			goodsCnt: '선택하신 주문정보가 없습니다.',
			couponExchangeState: '아래 조회버튼을 클릭 해주세요.',
			displayBtn: 'none',
			clickStatus: false,
		});
	};

	dateHandler = (date, type) => {
		if (type === 's') {
			this.setState({ sDate: date });
		} else if (type === 'e') {
			this.setState({ eDate: date });
		}
	};

	//주문 리스트 클릭시 초기화 및 쿠폰 발급 내역 조회
	getOrderInfo = () => {
		this.setState({
			clickStatus: true,
		});

		if (window[this.sheetMallOrderList.id].LastRow() === 0) return;

		let ordeNo = sheetMallOrderList.GetCellValue(sheetMallOrderList.GetSelectionRows(), 'ordeNo');
		if (!ordeNo) return;

		//주문별 제품 리스트
		this.getContOrderItemList(ordeNo);
	};

	//요청제품 리스트 가져오기
	getContOrderItemList = (ordeNo) => {
		ProgressStore.activeProgress();

		let param = {
			ordeNo: ordeNo,
		};

		getCpList(param)
			.then((r) => {
				window[this.couponInfoListSheet.id].LoadSearchData(resultToIbSheet(r));
				couponissuedHistoryListSheet.RemoveAll();

				this.setState({
					ordeNo: sheetMallOrderList.GetCellValue(sheetMallOrderList.GetSelectionRows(), 'ordeNo'),
					goodsCnt: sheetMallOrderList.GetCellValue(sheetMallOrderList.GetSelectionRows(), 'goodsCnt'),
					ordeUser: sheetMallOrderList.GetCellValue(sheetMallOrderList.GetSelectionRows(), 'ordeUser'),
					model: sheetMallOrderList.GetCellValue(sheetMallOrderList.GetSelectionRows(), 'model'),
					displayBtn: 'none',
					trId: '',
					moneyInfo: '좌측 쿠폰 발급 내역을 선택해주세요.',
					couponState: '아래 조회버튼을 클릭 해주세요.',
					couponExchangeState: '아래 조회버튼을 클릭 해주세요.',
				});
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	//쿠폰 발급 내역 클릭시
	getCouponInfo = () => {
		if (window[this.couponInfoListSheet.id].LastRow() === 0) return;

		let status = couponInfoListSheet.GetCellValue(couponInfoListSheet.GetSelectionRows(), 'status');
		let instDate = couponInfoListSheet.GetCellValue(couponInfoListSheet.GetSelectionRows(), 'instDate');
		let trId = couponInfoListSheet.GetCellValue(couponInfoListSheet.GetSelectionRows(), 'trId');

		let moneyInfo = this.state.ordeUser + ' - ' + this.state.model + ' - ' + instDate;
		let displayBtn = 'none';

		//발행취소, 재발행 버튼 비활성화
		if (status == '정상발행') {
			displayBtn = 'inline-block';
		}

		this.setState({
			trId: trId,
			moneyInfo: moneyInfo,
			displayBtn: displayBtn,
			couponState: '아래 조회버튼을 클릭 해주세요.',
			couponExchangeState: '아래 조회버튼을 클릭 해주세요.',
		});
	};

	//현금 결제 정보 발행취소, 재발행 컨펌 모달
	comfirmCheck = (txt, fnc) => {
		let trId = this.state.trId;

		if (!trId) {
			ModalStore.openModal('선택된 쿠폰 발급 내역이 없습니다.');
			return;
		}

		if (fnc == 'cancle') {
			ConfirmModalStore.openModal(txt + ' 하시겠습니까?', this.couponCancel);
		} else {
			ConfirmModalStore.openModal(txt + ' 하시겠습니까?', this.couponResend);
		}
	};

	//현금결제정보 발행취소
	couponCancel = () => {
		let param = {
			trId: this.state.trId,
		};
		setCouponCancel(param);

		ModalStore.openModal('발행취소 되었습니다.');

		this.getContOrderItemList(this.state.ordeNo);
	};

	//현금결제정보 재발행
	couponResend = () => {
		let param = {
			trId: this.state.trId,
		};
		setCouponResend(param);

		ModalStore.openModal('재발행 되었습니다.');
	};

	//현금결제정보 조회
	couponInfo = () => {
		let trId = this.state.trId;

		if (!trId) {
			ModalStore.openModal('선택된 쿠폰 발급 내역이 없습니다.');
			return;
		}

		let param = {
			trId: trId,
		};

		getCouponInfoSearch(param)
			.then((r) => {
				this.setState({
					couponState: r[0].smsStatusName,
				});
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	//쿠폰교환내역 조회
	couponState = () => {
		let trId = this.state.trId;

		if (!trId) {
			ModalStore.openModal('선택된 쿠폰 발급 내역이 없습니다.');
			return;
		}

		let param = {
			trId: trId,
		};

		getCouponState(param)
			.then((r) => {
				let _exchangeStatus = r[0].exchangeStatus;
				let exchangeStatus;

				if (_exchangeStatus == 0) {
					exchangeStatus = '미사용';
				} else if (_exchangeStatus == 1) {
					exchangeStatus = '사용';
				} else {
					exchangeStatus = '사용취소';
				}

				this.setState({
					couponExchangeState: exchangeStatus,
				});
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	//쿠폰발급이력 조회
	couponissuedHistoryList = () => {
		let ordeNo = this.state.ordeNo;

		if (!ordeNo) {
			ModalStore.openModal('선택된 주문이 없습니다.');
			return;
		}
		ProgressStore.activeProgress();

		let param = {
			ordeNo: ordeNo,
		};

		getCouponissuedHistoryList(param)
			.then((r) => {
				window[this.couponissuedHistoryListSheet.id].LoadSearchData(resultToIbSheet(r));

				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	makeExcelData = (data) => {
		let tmpList = [];
		let tmp = {};
		data.map((item, idx) => {
			tmp = {};
			tmp.No = idx + 1;
			tmp.구분 = item.rentTypeNm;
			tmp.주문번호 = item.ordeNo;
			tmp.주문일자 = dateToFormat(item.ordeDate);
			tmp.주문자ID = item.rqstId;
			tmp.주문자 = item.ordeUser;
			tmp.상품 = item.model;
			tmp.결제방법 = item.payType;
			tmp.결제금액 = item.salePrc;
			tmp.결제여부 = item.payYn;
			tmp.가상계좌입금차액 = item.diffAmt;
			tmp.렌탈기간 = item.contTerm;
			tmp.월렌탈료 = item.monthPrc;
			tmp.고객번호 = item.custNo;
			tmp.고객명 = item.custNm;
			tmp.제휴사 = item.alncCustNm;
			tmp.계약여부 = item.contYn;
			tmp.계약번호 = item.contNo;
			tmp.현금영수증발행번호 = item.cashRecieptHp;
			tmp.현금영수증승인결과 = item.cashrcptResultMsg;

			tmpList.push(tmp);
		});
		this.setState({ excelList: tmpList });
	};

	componentWillUnmount = () => {
		window[this.sheetMallOrderList.id].DisposeSheet();
		window[this.couponInfoListSheet.id].DisposeSheet();
		window[this.couponissuedHistoryListSheet.id].DisposeSheet();
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont" style={{ minHeight: 1300 }}>
							<div className="h2Wrap mb30">
								<h2>MALL 모바일 쿠폰 관리</h2>
								<div className="btnRight">
									<a className="btn01" onClick={() => this.getOrderList(true)}>
										<span>조회</span>
									</a>
									<ExcelDown modalOpen={ModalStore.openModal} data={this.state.excelList} text="엑셀" filename={'Mall주문리스트.csv'} />
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '10%' }} />
										<col style={{ width: '31%' }} />
										<col style={{ width: '10%' }} />
										<col style={{ width: '20%' }} />
										<col style={{ width: '10%' }} />
										<col style={{ width: '20%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>주문일자</th>
											<td>
												<DatePicker
													customInput={<input type="text" style={{ width: 115 }} />}
													dateFormat="YYYY-MM-DD"
													selectsStart
													selected={this.state.sDate}
													onChange={(date) => this.dateHandler(date, 's')}
													startDate={this.state.sDate}
													endDate={this.state.eDate}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
												<span style={{ marginLeft: 5, marginRight: 5 }}>~</span>
												<DatePicker
													customInput={<input type="text" style={{ width: 115 }} />}
													dateFormat="YYYY-MM-DD"
													selectsEnd
													selected={this.state.eDate}
													onChange={(date) => this.dateHandler(date, 'e')}
													startDate={this.state.sDate}
													endDate={this.state.eDate}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
											</td>
											<th>주문구분</th>
											<td>
												<select
													name="selOrderType"
													defaultValue={this.state.selOrderType || ''}
													onChange={(e) => this.setState({ selOrderType: e.target.value })}
													style={{ width: 120 }}
												>
													<option value="">전체</option>
													<option value="R">렌탈</option>
													<option value="S">유통</option>
												</select>
											</td>
											<th>결제방법</th>
											<td>
												<select
													name="selPay"
													defaultValue={this.state.selPay || ''}
													onChange={(e) => {
														this.setState({
															selPay: e.target.value,
														});
													}}
													style={{ width: 120 }}
												>
													<option value="">전체</option>
													<option value="1">카드</option>
													<option value="2">현금</option>
												</select>
											</td>
										</tr>
										<tr>
											<th>주문자명</th>
											<td>
												<input
													type="text"
													ref={(ref) => (this.userName = ref)}
													name="userName"
													value={this.state.userName || ''}
													onChange={(e) => {
														this.setState({
															userName: e.target.value,
														});
													}}
													style={{ width: 120 }}
												/>
											</td>
											<th>결제여부</th>
											<td>
												<select
													name="selPayYn"
													defaultValue={this.state.selPayYn || ''}
													onChange={(e) => {
														this.setState({
															selPayYn: e.target.value,
														});
													}}
													style={{ width: 120 }}
												>
													<option value="">전체</option>
													<option value="N">미결제</option>
													<option value="Y">결제완료</option>
													<option value="X">실패</option>
												</select>
											</td>
											<th>진행상태</th>
											<td>
												<select
													name="contYn"
													defaultValue={this.state.contYn}
													onChange={(e) => {
														this.setState({
															contYn: e.target.value,
														});
													}}
													style={{ width: 120 }}
												>
													<option value="N">미계약</option>
													<option value="Y">계약완료</option>
													<option value="X">주문취소</option>
													<option value="">전체</option>
												</select>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="writeType03">
								<div style={{ marginTop: 15, marginBottom: -15, textAlign: 'right' }}>
									<Button className="button" onClick={this.mallMoCpInst} color="primary" variant="contained" size="small" style={{ marginLeft: 10 }}>
										<span className="text">쿠폰등록</span>
									</Button>
								</div>
								<div className="scroll" onClick={this.getOrderInfo}>
									<p className="headline">주문 리스트</p>
									<IBSheet {...this.sheetMallOrderList} />
								</div>
							</div>

							<div className="writeType03" style={{ float: 'left', width: '52%', marginTop: 20 }}>
								<div className="scroll" onClick={this.getCouponInfo}>
									<p className="headline">쿠폰 발급 내역</p>
									<IBSheet {...this.couponInfoListSheet} />
								</div>
							</div>

							<div className="writeType03_acc" style={{ float: 'right', width: '45%', marginTop: 20 }}>
								<p className="headline">쿠폰 발행 정보</p>
								<table>
									<colgroup>
										<col style={{ width: 80 }} />
										<col style={{ width: 200 }} />
									</colgroup>
									<tbody>
										<tr>
											<td colSpan="2" style={{ textAlign: 'center' }}>
												<input type="text" value={this.state.moneyInfo} style={{ border: 0, textAlign: 'center' }} readOnly />
											</td>
										</tr>
										<tr>
											<th>발행상태</th>
											<td>
												<input type="text" value={this.state.couponState} style={{ border: 0, textAlign: 'center' }} readOnly></input>
											</td>
										</tr>
									</tbody>
								</table>

								<div style={{ marginTop: 15, marginBottom: -15, textAlign: 'right' }}>
									<Button
										color="secondary"
										size="small"
										variant="contained"
										style={{ marginLeft: 10, alignItems: 'initial', display: this.state.displayBtn }}
										onClick={() => this.comfirmCheck('발행취소', 'cancle')}
									>
										발행취소
									</Button>
									<Button
										color="secondary"
										size="small"
										variant="contained"
										style={{ marginLeft: 10, alignItems: 'initial', display: this.state.displayBtn }}
										onClick={() => this.comfirmCheck('재발행', 'resend')}
									>
										재발행
									</Button>
									<Button className="button" color="primary" variant="contained" size="small" style={{ marginLeft: 10 }} onClick={this.couponInfo}>
										<span className="text">조회</span>
									</Button>
								</div>

								<p className="headline" style={{ marginTop: 15 }}>
									쿠폰 교환 내역
								</p>
								<table>
									<colgroup>
										<col style={{ width: '40%' }} />
										<col style={{ width: '55%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>상품명</th>
											<td>
												<input type="text" value={this.state.model} style={{ border: 0 }} readOnly></input>
											</td>
										</tr>
										<tr>
											<th>주문자</th>
											<td>
												<input type="text" value={this.state.ordeUser} style={{ border: 0 }} readOnly></input>
											</td>
										</tr>
										<tr>
											<th>수량</th>
											<td>
												<input type="text" value={this.state.goodsCnt} style={{ border: 0 }} readOnly></input>
											</td>
										</tr>
										<tr>
											<th>교환내역 (사용내역)</th>
											<td>
												<input type="text" value={this.state.couponExchangeState} style={{ border: 0 }} readOnly></input>
											</td>
										</tr>
									</tbody>
								</table>
								<div style={{ marginTop: 15, marginBottom: -15, textAlign: 'right' }}>
									<Button className="button" onClick={this.couponState} color="primary" variant="contained" size="small" style={{ marginLeft: 10 }}>
										<span className="text">조회</span>
									</Button>
								</div>
							</div>
							<div style={{ clear: 'both' }} />
							<div className="writeType03">
								<div className="scroll">
									<p className="headline">쿠폰 발급 이력</p>
									<IBSheet {...this.couponissuedHistoryListSheet} />
								</div>
								<div style={{ marginTop: 15, marginBottom: -15, textAlign: 'right' }}>
									<Button className="button" onClick={this.couponissuedHistoryList} color="primary" variant="contained" size="small" style={{ marginLeft: 10 }}>
										<span className="text">조회</span>
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<MallMoCpManagerPopup
					open={this.state.MallMoCpManagerPopup}
					ordeNo={this.state.ordeNo}
					ordeUser={this.state.ordeUser}
					goodsCnt={this.state.goodsCnt}
					goodsId={this.state.goodsId}
					model={this.state.model}
					getContOrderItemList={this.getContOrderItemList}
					handleClose={this.handleMallMoCpInst}
					type="rep"
				/>
			</div>
		);
	}
}
export default observer(MallMoCpManager);
