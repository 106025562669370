// 고객번호
export const CUST_NO_KY_DEV = '3019184'; // 교원 개발
export const CUST_NO_KY = '3148330'; // 교원
export const CUST_NO_HDENG = '2711687'; //현대엔지니어링
export const CUST_NO_AJ = '2701378'; // AJ네트웍스
export const CUST_NO_HDEC = '2701678'; //현대건설
export const CUST_NO_HKFIRE = '2706052'; // 흥국화재해상보험
export const CUST_NO_KT = '3002240'; // 주식회사케이티
export const CUST_NO_HAS = '3012518'; // 홈앤쇼핑
export const CUST_NO_KPC = '3037960'; // 한국생산성본부
export const CUST_NO_KAB = '3038849'; // 한국생산성본부인증원
export const CUST_NO_DWE = '2701221'; //도화엔지니어링
export const CUST_NO_DDI = '3007431'; //두산디지털이노베이션
export const CUST_NO_HC = '3011758'; //한국코퍼레이션
export const CUST_NO_HCL = '3140431'; //한국클라우드
export const CUST_NO_BONIF = '3067390'; // 본아이에프
export const CUST_NO_BONIF_YPC = '3156478'; // 본아이에프 와이피센터
export const CUST_NO_KMI= '7401093'; // 한국의학연구소
