import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ExtendIcon from '@material-ui/icons/ScreenShare';
import StopIcon from '@material-ui/icons/DesktopAccessDisabled';
import Button from '@material-ui/core/Button';

import { errorMsgCheck, resultToIbSheet, SheetParamData } from '../../common/ComFunction';
import { getExpAssetList, beginAssetExtendBatch } from '../../service/rental/RentalAssetService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore, AssetReturnPopStore } = CommonStore;

class ExpAssetSearch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			/* 검색 */
			sCustNo: sessionStorage.custNo,
			sCustNm: sessionStorage.custNm,

			/* 반납정보 */
			arrData: [],

			/* 사용자정보 */
			spotNm: '',
			realUseSpot: '',
			userDeptName: '',
			userName: '',
		};

		this.sheetData = {
			id: 'expAssetSheet', // sheet id
			initData: 'rental/Init-ExpAsset', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '400px', // sheet height
		};
	}

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
	};

	searchExpAssetList = () => {
		if (!this.state.sCustNo) {
			ModalStore.openModal('고객을 선택해 주세요.');
			return;
		}

		ProgressStore.activeProgress();
		getExpAssetList(this.state.sCustNo)
			.then((r) => {
				window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	customerSearch = () => {
		CustSearchPopupStore.openPopup('1', null, null, this.handleCustSearch);
	};

	handleCustSearch = (item) => {
		if (item !== undefined) {
			this.setState({
				sCustNo: item.custNo,
				sCustNm: item.custNm,
			});
		}
	};

	getUserInfo = () => {
		if (window[this.sheetData.id].LastRow() === 0) return;

		let data = window.getSelExpAssetInfo();
		if (!data) return;

		this.setUserInfo(data);
	};

	setUserInfo = (data) => {
		if (data) {
			this.setState({
				spotNm: data.spotNm,
				realUseSpot: data.realUseSpot,
				userDeptName: data.userDeptName,
				userName: data.userName,
			});
		} else {
			this.setState({
				spotNm: '',
				realUseSpot: '',
				userDeptName: '',
				userName: '',
			});
		}
	};

	endConfirm = () => {
		var sheet = window[this.sheetData.id];
		var row = sheet.LastRow();

		if (row === 0) {
			ModalStore.openModal('검색된 내용이 없습니다.');
			return;
		}

		let param = [];
		for (var i = 1; i <= row; i++) {
			let item = {};
			if (sheet.GetCellValue(i, 'chk') === '1') {
				item.contNo = sheet.GetCellValue(i, 'contNo');
				item.asstNo = sheet.GetCellValue(i, 'asstNo');
				item.asstSeq = sheet.GetCellValue(i, 'asstSeq');
				param.push(item);
			}
		}

		if (param.length === 0) {
			ModalStore.openModal('선택된 데이터가 없습니다.');
			return;
		} else {
			this.setState(
				{
					arrData: param,
				},
				() => {
					const rtnInfo = {
						custNo: this.state.sCustNo,
					};
					AssetReturnPopStore.openPopup(rtnInfo, param, this.modalClose);
				},
			);
		}
	};
	modalClose = (reSearch) => {
		//재조회
		if (reSearch) {
			this.searchExpAssetList();
		}
	};
	extendConfirm = () => {
		var sheet = window[this.sheetData.id];
		var row = sheet.LastRow();

		if (row === 0) {
			ModalStore.openModal('검색된 내용이 없습니다.');
			return;
		}

		ConfirmModalStore.openModal('선택된 자산을 12개월 연장처리 하시겠습니까?', this.extendProc);
	};

	extendProc = () => {
		var sheet = window[this.sheetData.id];

		let param = {
			extendMonth: 12, //기본 12개월로 연장
		};

		ProgressStore.activeProgress();
		beginAssetExtendBatch(SheetParamData(sheet, param, true))
			.then(() => {
				ModalStore.openModal('연장 처리가 완료 되었습니다.');
				ProgressStore.deactiveProgress();

				//재조회
				this.searchExpAssetList();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mb30">
								<h2>만기자산관리</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.searchExpAssetList}>
										<span>조회</span>
									</a>
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '90px' }} />
										<col />
									</colgroup>
									<tbody>
										<tr>
											<th>고객명</th>
											<td>
												<input type="text" readOnly={true} style={{ marginRight: 10, width: 220 }} value={this.state.sCustNm} />
												<IconButton onClick={this.customerSearch} color="secondary" className="iconButton" style={{ display: sessionStorage.btnControl }}>
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div style={{ marginTop: 15, textAlign: 'right' }}>
								<Button color="secondary" variant="contained" size="small" onClick={this.endConfirm}>
									<StopIcon style={{ fontSize: 20, marginRight: 5 }} />
									반납요청
								</Button>
								<Button color="primary" variant="contained" size="small" onClick={this.extendConfirm} style={{ marginLeft: 7 }}>
									<ExtendIcon style={{ fontSize: 20, marginRight: 5 }} />
									렌탈연장
								</Button>
							</div>
							<div className="writeType03" onClick={this.getUserInfo}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">만기자산 리스트</p>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
							<div className="writeType03_noborder">
								<p className="headline">사용자 정보</p>
								<table>
									<colgroup>
										<col style={{ width: '90px' }} />
										<col style={{ width: '350px' }} />
										<col style={{ width: '90px' }} />
										<col />
									</colgroup>
									<tbody>
										<tr>
											<th>{sessionStorage.spotTitle}명</th>
											<td>
												<input type="text" readOnly={true} value={this.state.spotNm} />
											</td>
											<th>실사용장소</th>
											<td>
												<input type="text" readOnly={true} value={this.state.realUseSpot} />
											</td>
										</tr>
										<tr>
											<th>{sessionStorage.deptTitle}명</th>
											<td>
												<input type="text" readOnly={true} value={this.state.userDeptName} />
											</td>
											<th>사용자</th>
											<td>
												<input type="text" readOnly={true} value={this.state.userName} />
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(ExpAssetSearch);
