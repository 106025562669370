import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';

//날짜
import 'react-datepicker/dist/react-datepicker.css';

import ExcelDown from '../include/ExcelDown';

import { resultToIbSheet, errorMsgCheck } from '../../common/ComFunction';
import { getMobileCouponProcList } from '../../service/admin/MobileCouponService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ProgressStore } = CommonStore;

class MallMobCouProducts extends Component {
	constructor(props) {
		super(props);

		this.state = {
			brandNm: '',
			goodsId: '',
			goodsNm: '',
			imgUrl: '',
			msg: '',
			price: '',
			discPrice: '',
			discRate: '',

			excelList: [], //엑셀다운로드용 리스트

			//요청나누기팝업
			orderDividePopOpen: false,
		};

		this.sheetData = {
			id: 'orderListSheet', // sheet id
			initData: 'admin/Init-MobCouProducts', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '500px', // sheet height
		};
	}

	getMobileCouponProcListCon = () => {
		ProgressStore.activeProgress();

		let param = {
			event_id: '1',
			api: 'Y',
		};

		getMobileCouponProcList(param)
			.then((r) => {
				window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));

				// //엑셀용
				this.makeExcelData(r);
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	getMobileCouponProcList = () => {
		ProgressStore.activeProgress();

		let param = {
			event_id: '15',
		};

		getMobileCouponProcList(param)
			.then((r) => {
				window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));

				// //엑셀용
				this.makeExcelData(r);
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	makeExcelData = (data) => {
		let tmpList = [];
		let tmp = {};
		data.map((item, idx) => {
			tmp = {};
			tmp.순번 = idx + 1;
			tmp.브랜드명 = item.brandName;
			tmp.상품ID = item.goodsId;
			tmp.상품명 = item.goodsName;
			tmp.이미지URL = item.imgUrl;
			tmp.메시지 = item.msg.replace(/\r\n/gi, '\\n');
			tmp.판매가 = item.price;
			tmp.할인가 = item.discPrice;
			tmp.할인율 = item.discRate;
			tmpList.push(tmp);
		});
		this.setState({ excelList: tmpList });
	};

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont" style={{ height: 1350 }}>
							<div className="h2Wrap mb30">
								<h2>Mall 모바일 쿠폰 상품 관리</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.getMobileCouponProcListCon}>
										<span>연동</span>
									</a>
									<a className="btn01 search" onClick={this.getMobileCouponProcList}>
										<span>조회</span>
									</a>
									<ExcelDown modalOpen={this.modalOpen} data={this.state.excelList} text="엑셀" filename={'모바일쿠폰상품관리.csv'} />
								</div>
							</div>
							<div className="writeType03">
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
									onClick={this.getOrderInfo}
								>
									<p className="headline">모바일 쿠폰 상품 정보 조회</p>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(MallMobCouProducts);
