import React, { Component } from 'react';
import { makeObservable, observable, action } from 'mobx';
import { observer } from 'mobx-react';

const ItemPopupStore = observable({
	open: false,
	title: '',
	handleClose: null,

	openPopup(title, handleClose) {
		this.open = true;
		this.title = title;
		if (handleClose) this.handleClose = handleClose;
	},
	closePopup(item) {
		this.open = false;
		this.title = '';
		if (this.handleClose) {
			this.handleClose(item);
		}
	},
});

export default ItemPopupStore;
