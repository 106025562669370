import React, { Component } from 'react';

import IBSheet from '../IBSheet';
import SaveIcon from '@material-ui/icons/Save';
import ReturnIcon from '@material-ui/icons/LocalShipping';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import ExcelUpIcon from '@material-ui/icons/Description';
import { Transition, errorMsgCheck, resultToIbSheet } from '../../common/ComFunction';

import { insertExcelMember } from '../../service/members/MembersService';
import * as ComFunction from '../../common/ComFunction';
import * as XLSX from 'xlsx';
import { Search } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore } = CommonStore;

class MemberExcel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			uploadList: [],
			selectedItem: '',

			custNo: '', //고객번호
			custNm: '', //고객사명
		};

		this.sheetData = {
			id: 'memberExcel', // sheet id
			initData: 'members/Init-MemberExcel', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '500px', // sheet height
		};
	}

	componentWillUnmount = () => {
		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}
	};

	componentDidUpdate = () => {
		if (!this.props.open) return;
	};

	popClose = () => {
		this.props.closeFnc(true);

		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}
	};

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
			chgYn: true, // 변경일어남
		});
		if (e.target.name === 'passWord') {
			this.setState({
				[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
				chgYn: true, // 변경일어남
				passChgYn: true, // 변경일어남
			});
		}
	};
	// 고객사팝업
	handleClickOpen = (popupType) => {
		if (popupType === '1') {
			//고객사 팝업
			CustSearchPopupStore.openPopup(popupType, null, null, this.handleClose);
		}
	};
	handleClose = (item) => {
		if (item !== undefined) {
			if (CustSearchPopupStore.popupType === '1') {
				// 고객사 검색 결과
				if (item.custNo !== undefined) {
					this.setState({
						custNo: item.custNo,
						custNm: item.custNm,
					});
				}
			}
		}
	};
	// 고객사팝업 end

	searchFile = (e) => {
		// console.log(e.target.files.length);
		if (e.target.files.length > 0) {
			e.stopPropagation();
			e.preventDefault();
			var file = e.target.files[0];

			const reader = new FileReader();
			reader.onload = (evt) => {
				// evt = on_file_select event
				/* Parse data */
				const bstr = evt.target.result;
				const wb = XLSX.read(bstr, { type: 'binary' });
				/* Get first worksheet */
				const wsname = wb.SheetNames[0];
				const ws = wb.Sheets[wsname];
				/* Convert array of arrays */
				const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
				/* Update state */

				console.log(data); // shows data in json format
				this.setState({
					uploadList: ComFunction.csvToJson(data),
				});
			};
			reader.readAsBinaryString(file);
		}
	};

	excelUpload = () => {
		this.resetSheet();

		//console.log(this.state.uploadList);
		window[this.sheetData.id].LoadSearchData(ComFunction.resultToIbSheet(this.state.uploadList));

		var sheetIntvl = setInterval(() => {
			if (window[this.sheetData.id] !== undefined && window[this.sheetData.id].LastRow() > 0) {
				clearInterval(sheetIntvl);
				window.memberSheetCombo(this.props.userStatusList, 'userStatus');
				window.memberSheetCombo(this.props.authcodeList, 'authCode');
			}
		}, 100);
	};

	memberConfirm = () => {
		/*
		if (this.state.custNo === '') {
			ModalStore.openModal('고객사를 먼저 검색해 주세요.');
			return;
		}
		*/
		if (window[this.sheetData.id].LastRow() === 0) {
			ModalStore.openModal('엑셀을 먼저 업로드 해주세요.');
			return;
		}

		ConfirmModalStore.openModal('등록 하시겠습니까?', this.memberInsert);
	};

	memberInsert = () => {
		let memberData = window.getMemberList();

		if (memberData) {
			// let param = {
			// 	custNo: this.state.custNo,
			// };

			ProgressStore.activeProgress();

			// insertExcelMember(memberData, param)
			insertExcelMember(memberData)
				.then(() => {
					ModalStore.openModal('등록 되었습니다.');
					ProgressStore.deactiveProgress();
					this.resetSheet();
				})
				.catch((err) => {
					errorMsgCheck(err, ProgressStore.deactiveProgress());
				});
		}
	};

	resetSheet = () => {
		window[this.sheetData.id].LoadSearchData(
			{
				data: [],
			},
			{
				Sync: 1,
			},
		);

		// this.setState({ uploadList: [] });
	};

	render() {
		const props = this.props;

		return (
			<div>
				<Dialog
					open={props.open}
					TransitionComponent={Transition}
					// onClose={this.popClose}
				>
					<div className="popSmArea searchPop" style={{ transform: 'translate(-15%, 0)' }}>
						<div className="popCont" style={{ height: 665, width: 1170, overflow: 'hidden' }}>
							<div className="h2Wrap">
								<h2>엑셀사용자등록</h2>
							</div>
							{/* <div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '90px' }} />
										<col />
									</colgroup>
									<tbody>
										<tr>
											<th>고객사명</th>
											<td>
												<input
													type="text"
													ref={(ref) => (this.custNm = ref)}
													name="custNm"
													value={this.state.custNm || ''}
													onChange={this.handleChange}
													readOnly
													style={{ width: '30%', marginRight: '5px' }}
												/>
												<IconButton aria-label="Search" className="iconButton" onClick={() => this.handleClickOpen('1')}>
													<Search fontSize="small" />
												</IconButton>
											</td>
										</tr>
									</tbody>
								</table>
							</div> */}
							<div className="writeType04">
								<div>
									<IBSheet {...this.sheetData} />
								</div>
								<div style={{ marginTop: 20, float: 'right' }}>
									<input type="file" id="file" ref="fileUploader" onClick={(e) => (e.target.value = null)} onChange={(e) => this.searchFile(e)} />
									<Button color="primary" variant="contained" size="small" style={{ alignItems: 'initial', marginRight: 15 }} onClick={this.excelUpload}>
										<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
										업로드
									</Button>
									<Button color="secondary" variant="contained" size="small" style={{ alignItems: 'initial' }} onClick={this.memberConfirm}>
										<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
										저장
									</Button>
								</div>
							</div>
							<div className="btnClose" onClick={this.popClose}>
								<a>팝업닫기</a>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}
}
export default observer(MemberExcel);
