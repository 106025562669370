import React, { Component } from 'react';
import { makeObservable, observable, action } from 'mobx';
import { observer } from 'mobx-react';

const ProgressStore = observable({
	loadingData: false,
	activeProgress() {
		this.loadingData = true;
	},
	deactiveProgress() {
		this.loadingData = false;
	},
});

export default ProgressStore;
