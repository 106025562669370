import React, { Component } from 'react';
import { makeObservable, observable, action } from 'mobx';
import { observer } from 'mobx-react';

const ModalStore = observable({
	modalOpen: false,
	modalText: '',
	modalCallback: null,
	openModal(text, callback) {
		this.clearField();
		this.modalOpen = true;
		if (text) this.modalText = text;
		if (callback) this.modalCallback = callback;
	},
	closeModal() {
		this.modalOpen = false;
		if (this.modalCallback) {
			this.modalCallback();
		}
	},
	clearField() {
		this.modalOpen = false;
		this.modalText = '';
		this.modalCallback = null;
	},
});

export default ModalStore;
