import React, { Component } from 'react';
import { Dialog } from '@material-ui/core';
import IBSheet from '../IBSheet';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { ArrowRight } from '@material-ui/icons';
import 'react-datepicker/dist/react-datepicker.css';
import { errorMsgCheck, resultToIbSheet } from '../../common/ComFunction';
import { getCisCommCode } from '../../service/CommonService';
import { getCustItemList } from '../../service/admin/CompService';
import Button from '@material-ui/core/Button';
import BuildIcon from '@material-ui/icons/Build';

import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import CommonStore from '../../common/CommonStore';
import { updateContOrderItemInfoList } from '../../service/admin/ContractService';
import { IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Remove';
const { ModalStore, ProgressStore, ConfirmModalStore, ApiOrdeItemAllocPopupStore, IfrsPopupStore, StockPopupStore } = CommonStore;

class ApiOrdeItemAllocPopup extends Component {
	constructor(props) {
		super(props);

		this.sheetData = {
			id: 'sheetApiOrdeItemAllocPopup', // sheet id
			initData: 'include/Init-ApiOrdeItemAllocPopup', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '200px', // sheet height
		};
	}
	state = {
		//검색조건
		custNoSearch: '', //고객사코드_검색
		custNmSearch: '', //고객사명_검색
		useYnSearch: 'Y', //사용여부_검색
		mangYnSearch: '', //관리자여부_검색
		delYnSearch: 'N', //삭제여부_검색
		cnSrchInpt: 'S', //고객사검색검색조건/입력항목 여부('S':검색조건/'I':입력항목)
		searchModel: '', //모델검색
		searchRentType: '', //제품 유형 검색

		cateCodeList: [], // 공통코드 선언

		//toJS(ApiOrdeItemAllocPopupStore.ordeList)
	};

	componentDidUpdate = () => {
		if (ApiOrdeItemAllocPopupStore.open && !ApiOrdeItemAllocPopupStore.searched) {
			ApiOrdeItemAllocPopupStore.setSearched(true);
			this.getCustItemList();
			this.getCateCommCode();
		}
	};

	getCateCommCode = () => {
		// window[this.sheetData.id].createSheet();
		ProgressStore.activeProgress();
		//공통코드 조회
		Promise.all([getCisCommCode('CATE')])
			.then((r) => {
				let [codeCateData] = r;
				this.setState({
					cateCodeList: codeCateData, // 카테고리 공통코드
				});
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	//조회
	getCustItemList = () => {
		ProgressStore.activeProgress();
		let param = {
			custNoSearch: ApiOrdeItemAllocPopupStore.custNo, //고객사코드_검색
			custNmSearch: this.state.custNmSearch, //고객사명_검색
			useYnSearch: this.state.useYnSearch, //사용여부_검색
			mangYnSearch: this.state.mangYnSearch, //관리자여부_검색
			delYnSearch: this.state.delYnSearch, //삭제여부_검색
			searchModel: this.state.searchModel, //모델검색
			searchRentType: this.state.searchRentType, //제품유형 검색
			stoQty: 'N', // 입고/자산 수량까지 같이 가져온다 20220124 kkj [대응개발] 2024-05-22 재고수량 제거 by choye
		};

		getCustItemList(param)
			.then((r) => {
				ApiOrdeItemAllocPopupStore.setCustItemList(r);

				// JSON객체형태로 서버에서 받으므로 IBsheet에서 출력 가능한 배열형태로 변환
				const interval = setInterval(() => {
					if (window[this.sheetData.id]) {
						// 최초 시트로딩 시간으로 인해 인터벌 줌
						window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
						window.custItemSheetCombo(this.state.cateCodeList);
						clearInterval(interval);
					}
				}, 100);
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	popClose = () => {
		window[this.sheetData.id].DisposeSheet();
		ApiOrdeItemAllocPopupStore.closePopup();
	};

	primaryTitle = (idx) => {
		let msg = `요청 제품 ${idx + 1} `;
		if (ApiOrdeItemAllocPopupStore.itemRowList[idx].model) {
			msg += ApiOrdeItemAllocPopupStore.itemRowList[idx].model;
		}
		return msg;
	};
	secondaryTitle = (idx) => {
		let msg = `미할당 상태`;

		if (ApiOrdeItemAllocPopupStore.itemRowList[idx].itemSeq) {
			msg = `제품 순번 : ${ApiOrdeItemAllocPopupStore.itemRowList[idx].itemSeq}`;
		}
		return msg;
	};

	setToSelectedOrdeItem = () => {
		const selectedval = window.getSelectedKey();
		const selectedIdx = ApiOrdeItemAllocPopupStore.selectedIdx;
		const itemRowUnit = ApiOrdeItemAllocPopupStore.itemRowList[selectedIdx];

		if (selectedIdx < 0) {
			ModalStore.openModal('요청 제품 선택 후 시도해주세요');
			return;
		}

		ConfirmModalStore.openModal(`${selectedIdx + 1}번째 요청 제품에 ${selectedval.model}을 할당하시겠습니까?`, () => {
			itemRowUnit.seq = selectedIdx + 1;
			itemRowUnit.custNo = selectedval.custNo;
			itemRowUnit.itemCode = selectedval.itemCode;
			itemRowUnit.itemSeq = selectedval.itemSeq;
			itemRowUnit.model = selectedval.model;
			itemRowUnit.monthPrc = selectedval.monthPrc;
			itemRowUnit.ifrs = '';
		});
	};

	saveOrdeItemAlloc = () => {
		const ordeList = toJS(ApiOrdeItemAllocPopupStore.ordeList);
		const itemRowList = toJS(ApiOrdeItemAllocPopupStore.itemRowList);

		for (let i = 0; i < itemRowList.length; i++) {
			if (!itemRowList[i].itemSeq) {
				ModalStore.openModal(`요청 제품 중 ${i + 1}번째 항목에 제품이 미할당 상태입니다.`);
				return;
			}
			if (!itemRowList[i].monthPrc || itemRowList[i].monthPrc == 0) {
				ModalStore.openModal(`요청 제품 중 ${i + 1}번째 항목의 월렌탈료가 미입력된 상태입니다.`);
				return;
			}
			if (itemRowList[i].stoNo && itemRowList[i].stoSeq && !itemRowList[i].ifrsCode) {
				ModalStore.openModal(`요청 제품 중 ${i + 1}번째 항목은 IFRS코드가 없습니다.`);
				return;
			}
		}

		ConfirmModalStore.openModal('제품할당 적용을 일괄 저장하시겠습니까?', () => {
			let param = {
				ordeList: ordeList,
				itemRowList: itemRowList,
			};
			ProgressStore.activeProgress();
			updateContOrderItemInfoList(param)
				.then(() => {
					ModalStore.openModal('저장 되었습니다.', this.popClose);
				})
				.catch((err) => {
					errorMsgCheck(err);
				})
				.finally(() => {
					ProgressStore.deactiveProgress();
				});
		});
	};

	stockPopOpen = (type) => {
		if (!ApiOrdeItemAllocPopupStore.itemRowList[ApiOrdeItemAllocPopupStore.selectedIdx].itemCode) {
			ModalStore.openModal('제품을 먼저 할당 후 조회 가능합니다.');
			return;
		}
		StockPopupStore.openPopup(type, ApiOrdeItemAllocPopupStore.itemRowList[ApiOrdeItemAllocPopupStore.selectedIdx].model, this.stockCloseFnc);
	};

	stockCloseFnc = (item) => {
		if (!item) return;

		const itemRowUnit = ApiOrdeItemAllocPopupStore.itemRowList[ApiOrdeItemAllocPopupStore.selectedIdx];
		//20210319 kkj 대분류 체크
		if (item.itemCd !== undefined) {
			let itemCd1 = item.itemCd;
			let itemCd2 = itemRowUnit.itemCode;
			if (itemCd1.substr(0, 3) !== itemCd2.substr(0, 3)) {
				ModalStore.openModal('제품의 대분류가 일치하지 않습니다.');
				itemRowUnit.stoNo = '';
				itemRowUnit.stoSeq = '';
				return;
			}
		}

		// if (item.ableQty && this.state.itemQty > item.ableQty) {
		// 	ModalStore.openModal('상품 수량보다 발주 수량이 큽니다.');
		// 	this.setState({
		// 		stoNo: '',
		// 		stoSeq: '',
		// 	});
		// 	return;
		// }

		itemRowUnit.stoNo = item.stoNo;
		itemRowUnit.stoSeq = item.stoSeq;
	};

	removeSelectedStoNo = () => {
		const itemRowUnit = ApiOrdeItemAllocPopupStore.itemRowList[ApiOrdeItemAllocPopupStore.selectedIdx];
		if (itemRowUnit.stoNo) {
			ConfirmModalStore.openModal('할당된 입고번호를 제거하시겠습니까?', () => {
				itemRowUnit.stoNo = '';
				itemRowUnit.stoSeq = '';
			});
		}
	};

	removeSelectedIfrsCode = () => {
		const itemRowUnit = ApiOrdeItemAllocPopupStore.itemRowList[ApiOrdeItemAllocPopupStore.selectedIdx];
		if (itemRowUnit.ifrsCode) {
			ConfirmModalStore.openModal('할당된 IFRS코드를 제거하시겠습니까?', () => {
				itemRowUnit.ifrsCode = ''; // ifrs 코드
				itemRowUnit.duration = ''; // 내용월수
				itemRowUnit.lastBalRate = ''; // 잔존가치율
			});
		}
	};

	ifrsCloseFnc = (item) => {
		if (!item) return;

		const itemRowUnit = ApiOrdeItemAllocPopupStore.itemRowList[ApiOrdeItemAllocPopupStore.selectedIdx];
		itemRowUnit.ifrsCode = item.ifrsCode;
		itemRowUnit.duration = item.duration;
		itemRowUnit.lastBalRate = item.lastBalRate;
	};

	render() {
		const { open } = ApiOrdeItemAllocPopupStore;
		const itemRowUnit = ApiOrdeItemAllocPopupStore.itemRowList[ApiOrdeItemAllocPopupStore.selectedIdx];
		let popContHeight = {
			height: 800,
			width: 900,
			overflow: 'hidden',
		};
		return (
			<div>
				<Dialog open={open} onClose={this.popClose}>
					<div className="popSmArea searchPop" style={{ transform: 'translate(-8%, -8%)' }}>
						<div className="popCont" style={popContHeight}>
							<div className="h2Wrap mb30">
								<h2>요청제품 일괄 제품할당</h2>
							</div>

							<div className="writeType05 long2">
								<div className="short" onDoubleClick={this.setToSelectedOrdeItem}>
									<div
										className="scroll"
										onMouseOver={() => {
											document.body.style.overflow = 'hidden';
										}}
										onMouseOut={() => {
											document.body.style.overflow = 'auto';
										}}
									>
										<p className="headline">제품 리스트</p>
										<IBSheet {...this.sheetData} />
									</div>
								</div>
								<div className="writeType02 type2">
									<table>
										<colgroup>
											<col style={{ width: '100px' }} />
											<col />
										</colgroup>
										<tbody>
											<tr>
												<td colSpan="2">
													<div
														style={{
															height: '200px',
															overflowX: 'hidden',
															overflowY: 'auto',
														}}
													>
														<div>
															<List component="nav">
																{ApiOrdeItemAllocPopupStore.itemRowList.map((item, idx) => (
																	<ListItem button key={idx}>
																		<ListItemIcon>
																			<ArrowRight />
																		</ListItemIcon>
																		<ListItemText
																			inset
																			primary={this.primaryTitle(idx)}
																			secondary={this.secondaryTitle(idx)}
																			onClick={() => {
																				ApiOrdeItemAllocPopupStore.setSelectedIdx(idx);
																			}}
																		/>
																	</ListItem>
																))}
															</List>
														</div>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								{itemRowUnit ? (
									<div>
										<div className="writeType03_acc" style={{ float: 'left', width: '100%', height: 45, marginBottom: '30px' }}>
											<p className="headline">할당할 요청 제품</p>
											<table>
												<colgroup>
													<col style={{ width: '10%' }} />
													<col style={{ width: '13%' }} />
													<col style={{ width: '10%' }} />
													<col style={{ width: '43%' }} />
													<col style={{ width: '10%' }} />
													<col style={{ width: '13%' }} />
												</colgroup>
												<tbody>
													<tr style={{ height: 41 }}>
														<th>No</th>
														<td>{ApiOrdeItemAllocPopupStore.selectedIdx > -1 ? ApiOrdeItemAllocPopupStore.selectedIdx + 1 : ''}</td>
														<th>모델명</th>
														<td>{itemRowUnit.model}</td>
														<th>제품순번</th>
														<td>{itemRowUnit.itemSeq}</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div className="writeType03_acc" style={{ float: 'left', width: '100%', height: 45, marginBottom: '30px' }}>
											<p className="headline">제품 정보</p>
											<table>
												<colgroup>
													<col style={{ width: '10%' }} />
													<col style={{ width: '30%' }} />
													<col style={{ width: '10%' }} />
													<col style={{ width: '23%' }} />
													<col style={{ width: '10%' }} />
													<col style={{ width: '16%' }} />
												</colgroup>
												<tbody>
													<tr style={{ height: 41 }}>
														{/* 
														[대응개발] 2024-05-20 제거 by choye 
														*/}
														{/* 
														<th>입고번호</th>
														<td>
															<input
																type="text"
																value={itemRowUnit.stoNo}
																onChange={(e) => ApiOrdeItemAllocPopupStore.setItemRowUnit(ApiOrdeItemAllocPopupStore.selectedIdx, 'stoNo', e.target.value)}
																placeholder="입고번호"
																readOnly
																style={{ width: 80, marginLeft: 5, marginRight: 5 }}
															/>
															<input
																type="text"
																value={itemRowUnit.stoSeq}
																onChange={(e) => ApiOrdeItemAllocPopupStore.setItemRowUnit(ApiOrdeItemAllocPopupStore.selectedIdx, 'stoSeq', e.target.value)}
																placeholder="순번"
																readOnly
																style={{ width: 40, marginRight: 5 }}
															/>
															<IconButton
																onClick={() => {
																	this.stockPopOpen('A');
																}}
																color="secondary"
																className="iconButton"
															>
																<SearchIcon fontSize="small" />
															</IconButton>
															<IconButton onClick={this.removeSelectedStoNo} color="secondary" className="iconButton">
																<DeleteIcon fontSize="small" />
															</IconButton>
														</td>
														<th>IFRS코드</th>
														<td>
															<input
																type="text"
																value={itemRowUnit.ifrsCode}
																style={{ width: 90, marginRight: 5 }}
																onChange={(e) => ApiOrdeItemAllocPopupStore.setItemRowUnit(ApiOrdeItemAllocPopupStore.selectedIdx, 'ifrsCode', e.target.value)}
																readOnly
															/>
															<IconButton onClick={() => IfrsPopupStore.openPopup(this.ifrsCloseFnc)} color="secondary" className="iconButton">
																<SearchIcon fontSize="small" />
															</IconButton>
															<IconButton onClick={this.removeSelectedIfrsCode} color="secondary" className="iconButton">
																<DeleteIcon fontSize="small" />
															</IconButton>
														</td>
														*/}
														<th>월렌탈료</th>
														<td>
															{/* [대응개발] 2024-05-17 disabled, style 추가 by choye */}
															<input
																type="text"
																value={itemRowUnit.monthPrc}
																readOnly
																style={{ border: 0 }}
																maxLength={6}
																onChange={(e) => ApiOrdeItemAllocPopupStore.setItemRowUnit(ApiOrdeItemAllocPopupStore.selectedIdx, 'monthPrc', e.target.value)}
															/>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								) : null}

								<div style={{ fontSize: 14, color: '#d41826' }}>
									<span>* 요청제품 목록에서 미할당 된 상태 항목 선택 선택 후 제품 리스트에서 할당할 제품을 더블 클릭하여 할당 가능합니다.</span>
								</div>
								<div style={{ marginTop: 15, textAlign: 'right' }}>
									<Button color="primary" variant="outlined" size="small" style={{ marginLeft: 10, alignItems: 'initial' }} onClick={this.saveOrdeItemAlloc}>
										<BuildIcon style={{ fontSize: 20, marginRight: 5 }} />
										제품할당 적용
									</Button>
								</div>
							</div>
							<div className="btnClose" onClick={this.popClose}>
								<a>팝업닫기</a>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}
}
export default observer(ApiOrdeItemAllocPopup);
