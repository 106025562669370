function http(...rest) {
	return fetch(...rest)
		.then((res) => {
			const contentType = (res.headers.get('Content-Type') || '').toLowerCase();
			if (!res.ok)
				if (contentType.indexOf('json') > -1)
					return res.json().then((b) => {
						console.log('err.desc = ' + b.err.desc);
						throw new Error(b && b.err && b.err.desc ? b.err.desc : '상태값:' + res.status);
					});
				// text/html or text/plain
				else
					res.text().then((t) => {
						console.log(t);
					});
			return res.json();
		})
		.then((b) => b.result);
}

export function httpGet(url, { headers: headers, body: body } = {}) {
	var header = { Accept: 'application/json' };
	if (sessionStorage.accessToken) {
		header['Content-Type'] = 'application/json;charset=utf-8';
		header['Authorization'] = sessionStorage.accessToken;
	}

	headers = Object.assign(header, headers);
	return http(url, { method: 'GET', headers: headers, body: body });
}

export function httpPost(url, { headers: headers, body: body } = {}) {
	var defaultHeader = { Accept: 'application/json' };
	/*   
    if(!headers['Content-Type']){
        defaultHeader['Content-Type'] = 'application/json;charset=utf-8';
    }
    if(defaultHeader['Content-Type']&&defaultHeader['Content-Type'].indexOf('json')>=0){
        body = JSON.stringify(body);
    }
*/
	//console.log(sessionStorage.accessToken);
	if (sessionStorage.accessToken) {
		defaultHeader['Content-Type'] = 'application/json;charset=utf-8';
		defaultHeader['Authorization'] = sessionStorage.accessToken;
		body = JSON.stringify(body);
	} else if (url.indexOf('login') == -1) {
		// 20181029 kkj 로그인은 CommonService.js에서 정의한 content type 으로 사용하기위함
		defaultHeader['Content-Type'] = 'application/json;charset=utf-8';
		// defaultHeader['Authorization'] = sessionStorage.accessToken;
		body = JSON.stringify(body);
	}
	headers = Object.assign(defaultHeader, headers);
	return http(url, { method: 'POST', headers: headers, body: body });
}

export function httpPut(url, { headers: headers, body: body } = {}) {
	var header = { Accept: 'application/json' };
	if (sessionStorage.accessToken) {
		header['Content-Type'] = 'application/json;charset=utf-8';
		header['Authorization'] = sessionStorage.accessToken;
	}
	headers = Object.assign(header, headers);
	return http(url, { method: 'PUT', headers: headers, body: JSON.stringify(body) });
}

export function httpDelete(url, { headers: headers, body: body } = {}) {
	var header = { Accept: 'application/json' };
	if (sessionStorage.accessToken) {
		header['Content-Type'] = 'application/json;charset=utf-8';
		header['Authorization'] = sessionStorage.accessToken;
	}
	headers = Object.assign(header, headers);
	return http(url, { method: 'DELETE', headers: headers, body: body });
}

export function toQueryString(data) {
	let buf = [];
	Object.keys(data).forEach((k) => buf.push(k + '=' + encodeURIComponent(data[k])));
	return (buf.length ? '?' : '') + buf.join('&');
}
