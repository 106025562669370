import React, { Component } from 'react';
import { getBannerList } from '../../service/HomeService';

export default class Banner extends Component {
	constructor(props) {
		super(props);

		this.state = {
			bannerList: [],
		};
	}

	componentDidMount = () => {
		let param = {
			useYn: 'Y',
			systCode: 'CIS',
		};
		getBannerList(param).then((r) => {
			this.setState({
				bannerList: r,
			});
		});
	};

	render() {
		return (
			<div className="bannerList">
				<ul>
					{this.state.bannerList.map((item, i) => (
						<li key={i}>
							<a onClick={() => window.open(item.url)}>
								<img src={item.path} alt={item.name} />
							</a>
						</li>
					))}
				</ul>
			</div>
		);
	}
}
