import React, { Component } from 'react';
import Header from '../include/Header';
import IBSheet from '../IBSheet';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { IconButton } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import {
	getMntncCustAsstList,
	getMntncProcUserList,
	allocProcUser,
	getMntncProcAsstList,
	deallocProcUser,
	getMntncAsstAllList,
} from '../../service/mntnc/MntncAllocService';
import { errorMsgCheck, resultToIbSheet, SheetParamData, buildSelectItems, ComIBSheetComboNull, isEmptyObject, sheetCheckAll } from '../../common/ComFunction';
import { getCisCommCode } from '../../service/CommonService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore, UserPopupStore, LargeInputNoPopupStore } = CommonStore;

class MntncAssetAlloc extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sMntCustNo: '', // 유지보수사
			sMntCustNm: '',
			sAsstNo: '', // 자산번호
			sYear: '', // 년도
			sMonth: '', //월

			sProcUser: '', // 엔지니어 id
			sProcUserNm: '', // 엔지니어명
			sItemType: '', // 제품타입

			arrYear: [],
			mapArrYear: [],
			arrItemType: [],

			sheetCheckAllValue: false, // sheet1 checkAll value
			sheet3CheckAllValue: false, // sheet3 checkAll value
		};

		this.sheetData = {
			id: 'mntncAsstSheet', // sheet id
			initData: 'mntnc/Init-MntncAssetList', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '250px', // sheet height
		};
		this.sheetData2 = {
			id: 'mntncProcUserSheet', // sheet id
			initData: 'mntnc/Init-MntncProcUserList', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '250px', // sheet height
		};
		this.sheetData3 = {
			id: 'mntncProcAsstSheet', // sheet id
			initData: 'mntnc/Init-MntncProcAsstList', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '250px', // sheet height
		};
	}

	componentDidMount = () => {
		Promise.all([getCisCommCode('19')]).then((r) => {
			let [code19] = r;
			this.setState(
				{
					arrItemType: code19,
				},
				() => {
					setInterval(() => {
						if (window[this.sheetData.id]) {
							ComIBSheetComboNull('0', window[this.sheetData.id], 'itemType', code19, 'title', 'value', '', '');
						}
					}, 100);
				},
			);
		});

		// 년도 셋팅
		var date = new Date();
		let nowYear = date.getFullYear();
		let startYear = nowYear - 10;

		for (var i = startYear; i <= nowYear; i++) {
			this.setState({
				arrYear: this.state.arrYear.push(i),
			});
		}

		this.state.mapArrYear = this.state.arrYear.map((year) => year);

		setInterval(() => {
			if (window[this.sheetData.id]) {
				let sheet = window[this.sheetData.id];
				sheet.ShowFilterRow();
			}
		}, 100);
		setInterval(() => {
			if (window[this.sheetData2.id]) {
				let sheet = window[this.sheetData2.id];
				sheet.ShowFilterRow();
				sheet.SetSumText(1, '합계');
			}
		}, 100);
		setInterval(() => {
			if (window[this.sheetData3.id]) {
				let sheet = window[this.sheetData3.id];
				sheet.ShowFilterRow();
			}
		}, 100);
	};

	componentWillUnmount = () => {
		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}
		if (window[this.sheetData2.id]) {
			window[this.sheetData2.id].DisposeSheet();
		}
		if (window[this.sheetData3.id]) {
			window[this.sheetData3.id].DisposeSheet();
		}
	};

	getMntncProcUserList = () => {
		if (!this.state.sMntCustNo) {
			ModalStore.openModal('유지보수사를 먼저 입력해주세요.');
			return;
		}

		let param = {
			mntCustNo: this.state.sMntCustNo,
			procUser: this.state.sProcUser,
			procUserNm: this.state.sProcUserNm,
		};

		ProgressStore.activeProgress();
		getMntncProcUserList(param)
			.then((r) => {
				window[this.sheetData2.id].LoadSearchData(resultToIbSheet(r));
				window[this.sheetData3.id].RemoveAll();
				this.setState({ sheet3CheckAllValue: false });
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	getMntncProcAsstList = () => {
		let data = window.getData();

		if (isEmptyObject(data)) return;

		let param = {
			procUser: data.allocProcUser,
		};

		ProgressStore.activeProgress();
		getMntncProcAsstList(param)
			.then((r) => {
				window[this.sheetData3.id].LoadSearchData(resultToIbSheet(r));
				this.setState({ sheet3CheckAllValue: false });
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	getMntncAsstList = () => {
		if (!this.state.sMntCustNo) {
			ModalStore.openModal('유지보수사를 먼저 입력해주세요.');
			return;
		}

		let param = {
			mntCustNo: this.state.sMntCustNo,
			asstNo: this.state.sAsstNo,
		};

		ProgressStore.activeProgress();
		getMntncAsstAllList(param)
			.then((r) => {
				window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				this.setState({ sheetCheckAllValue: false });
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	updateCheck = (type) => {
		if (type === 0) {
			let sheet = window[this.sheetData.id];
			if (sheet.FindCheckedRow('chk')) {
				let sheet2 = window[this.sheetData2.id];
				let selectedRow = sheet2.GetSelectRow();
				if (selectedRow < 0) {
					ModalStore.openModal('선택된 엔지니어가 없습니다.');
				} else {
					ConfirmModalStore.openModal(
						`선택된 자산을 엔지니어(${sheet2.GetCellValue(selectedRow, 'allocProcUser')}) 에게 할당하시겠습니까?`,
						this.allocProcUser,
					);
				}
			} else {
				ModalStore.openModal('선택된 자산이 없습니다.');
			}
		} else if (type === 1) {
			let sheet3 = window[this.sheetData3.id];
			if (sheet3.FindCheckedRow('chk')) {
				ConfirmModalStore.openModal(`선택된 자산을 할당해제 하시겠습니까?`, this.deallocProcUser);
			} else {
				ModalStore.openModal('선택된 자산이 없습니다.');
			}
		}
	};

	allocProcUser = () => {
		let sheet = window[this.sheetData.id];
		let sheet2 = window[this.sheetData2.id];

		let param = {
			procUser: sheet2.GetCellValue(sheet2.GetSelectRow(), 'allocProcUser'),
		};

		ProgressStore.activeProgress();
		allocProcUser(SheetParamData(sheet, param, false))
			.then(() => {
				ModalStore.openModal('저장 되었습니다.');
				this.getMntncAsstList();
				this.getMntncProcUserList();
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	deallocProcUser = () => {
		let sheet3 = window[this.sheetData3.id];

		ProgressStore.activeProgress();
		deallocProcUser(SheetParamData(sheet3, null, false))
			.then(() => {
				ModalStore.openModal('저장 되었습니다.');
				this.getMntncAsstList();
				this.getMntncProcUserList();
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	sheetCheckAll = () => {
		let sheet = window[this.sheetData.id];
		sheetCheckAll(sheet, this.state.sheetCheckAllValue);
		this.setState({ sheetCheckAllValue: !this.state.sheetCheckAllValue });
	};

	sheet3CheckAll = () => {
		let sheet3 = window[this.sheetData3.id];
		sheetCheckAll(sheet3, this.state.sheet3CheckAllValue);
		this.setState({ sheet3CheckAllValue: !this.state.sheet3CheckAllValue });
	};

	// 고객사팝업
	handleClickOpen = (popupType) => {
		if (popupType === '1' || popupType === '3') {
			//고객사 팝업
			CustSearchPopupStore.openPopup(popupType, null, null, this.handleClose);
		} else if (popupType === '2') {
			//지점 팝업
			//지점은 고객사 팝업 검색 후 가능
			if (this.state.sCustNo === '') {
				ModalStore.openModal(sessionStorage.spotTitle + '검색은 회사명 검색 이후 가능합니다.');
				return;
			}
			CustSearchPopupStore.openPopup(popupType, sessionStorage.spotTitle + '명', this.state.sCustNo, this.handleClose);
		} else if (popupType === '4') {
			//유지보수사 팝업
			CustSearchPopupStore.openPopup(popupType, null, null, this.handleClose);
		}
	};

	handleClose = (item) => {
		if (item !== undefined) {
			if (CustSearchPopupStore.popupType === '1') {
				// 고객사 검색 결과
				if (item.custNo !== undefined) {
					this.setState({
						sCustNo: item.custNo,
						sCustNm: item.custNm,
					});
				}
			} else if (CustSearchPopupStore.popupType === '2') {
				// 지점 검색 결과
				if (item.addrSeq !== undefined) {
					this.setState({
						addrSeq: item.addrSeq, //지점시퀀스
						spotNm: item.spotNm, //지점명
					});
				}
			} else if (CustSearchPopupStore.popupType === '3') {
				// 고객사 검색 결과
				if (item.custNo !== undefined) {
					this.setState({
						sCustNo: item.custNo,
						sCustNm: item.custNm,
					});
				}
			} else if (CustSearchPopupStore.popupType === '4') {
				// 유지보수사 검색 결과
				if (item.custNo !== undefined) {
					this.setState({
						sMntCustNo: item.custNo,
						sMntCustNm: item.custNm,
					});
				}
			}
		}
	};

	searchUser = () => {
		if (!this.state.sMntCustNo) {
			ModalStore.openModal('검색은 유지보수사명 검색 이후 가능합니다.');
			return;
		}
		UserPopupStore.openPopup('rep', this.state.sMntCustNo, this.handleUserSearch);
	};

	handleUserSearch = (item) => {
		if (item !== undefined) {
			this.setState({
				sProcUser: item.userId,
				sProcUserNm: item.userName,
			});
		}
	};

	handleTextArray = (text) => {
		if (text === '') return;
		this.setState({
			sAsstNo: text,
		});
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="wideRightCont" style={{ height: 800 }}>
							<div className="h2Wrap mb30">
								<h2>유지보수 엔지니어 자산 별 할당</h2>
							</div>
							<div className="writeType03">
								<Button
									color="primary"
									variant="contained"
									size="small"
									style={{ float: 'right', alignItems: 'initial', marginBottom: 5 }}
									onClick={this.getMntncAsstList}
								>
									<Search style={{ fontSize: 20, marginRight: 5 }} />
									조회
								</Button>
								<table>
									<tbody>
										<tr>
											<th>
												유지보수사<span className="star">*</span>
											</th>
											<td>
												<input type="text" value={this.state.sMntCustNm} style={{ width: 150, marginRight: 10 }} onClick={() => this.clearField(0)} />
												{/* 고객사검색버튼 */}
												<IconButton onClick={() => this.handleClickOpen('4')} color="secondary" className="iconButton">
													<Search fontSize="small" />
												</IconButton>
											</td>
											<th style={{ width: '10%' }}>자산번호</th>
											<td>
												<input
													type="text"
													style={{ width: '50%', marginRight: 10 }}
													value={this.state.sAsstNo}
													onChange={(e) => this.setState({ sAsstNo: e.target.value })}
												/>
												<IconButton onClick={() => LargeInputNoPopupStore.openPopup(this.handleTextArray)} color="secondary" className="iconButton">
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="writeType03">
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">
										자산 리스트
										<Button
											color="secondary"
											variant="contained"
											size="small"
											style={{ alignItems: 'initial', float: 'right', marginBottom: 5 }}
											onClick={() => this.updateCheck(0)}
										>
											<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
											선택건 할당
										</Button>
										<IconButton color="secondary" onClick={() => this.sheetCheckAll()} style={{ float: 'right' }}>
											{this.state.sheetCheckAllValue ? <CheckBoxIcon style={{ fontSize: 20 }} /> : <CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
										</IconButton>
									</p>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
							<div style={{ marginTop: 50, textAlign: 'right' }}>
								<Button color="primary" variant="contained" size="small" style={{ alignItems: 'initial', marginRight: 5 }} onClick={this.getMntncProcUserList}>
									<Search style={{ fontSize: 20, marginRight: 5 }} />
									조회
								</Button>
								<Button
									color="secondary"
									variant="contained"
									size="small"
									style={{ alignItems: 'initial', marginRight: 5 }}
									onClick={() => this.updateCheck(1)}
								>
									<SaveIcon style={{ fontSize: 20, marginRight: 5 }} onClick={() => this.updateCheck(1)} />
									선택건 할당해제
								</Button>
								<IconButton color="secondary" onClick={() => this.sheet3CheckAll()} style={{ float: 'right' }}>
									{this.state.sheet3CheckAllValue ? <CheckBoxIcon style={{ fontSize: 20 }} /> : <CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
								</IconButton>
							</div>
							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '80px' }} />
										<col style={{ width: '80px' }} />
										<col style={{ width: '60px' }} />
										<col style={{ width: '80px' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>엔지니어ID</th>
											<td>
												<input type="text" value={this.state.sProcUser} style={{ width: 150, marginRight: 10 }} />
												<IconButton onClick={this.searchUser} color="secondary" className="iconButton">
													<Search fontSize="small" />
												</IconButton>
											</td>
											<th>엔지니어명</th>
											<td>
												<input type="text" value={this.state.sProcUserNm} onChange={(e) => this.setState({ sProcUserNm: e.target.value })} />
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="writeType03" style={{ float: 'left', width: '38%' }} onClick={this.getMntncProcAsstList}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">엔지니어 리스트</p>
									<IBSheet {...this.sheetData2} />
								</div>
							</div>
							<div className="writeType03" style={{ float: 'right', width: '58%' }}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">엔지니어 할당 자산 리스트</p>
									<IBSheet {...this.sheetData3} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(MntncAssetAlloc);
