import React, { Component } from 'react';
import ajLogo from '../images/h1Login.png';
import { userLogin, requestUserPassword, updateUserPasswordSalt } from '../service/CommonService';
import { createCookie, readCookie, handleSessionStorage } from '../common/ComFunction';
import FindIdPw from './FindIdPw';
import RequestJoin from './RequestJoin';
import Banner from './include/Banner';

import { observer } from 'mobx-react';
import CommonStore from '../common/CommonStore';
const { ModalStore, ProgressStore } = CommonStore;

import eventImg01 from '../images/event/01/app_publish_01.png';
import eventImg02 from '../images/event/01/app_publish_02.png';
import eventImg03 from '../images/event/01/app_publish_03.png';
import eventImg04 from '../images/event/01/app_publish_04.png';
import eventImg05 from '../images/event/01/app_publish_05.png';
import eventImg06 from '../images/event/01/app_publish_06.png';
import eventImg07 from '../images/event/01/app_publish_07.png';
import eventImg08 from '../images/event/01/app_publish_08.png';

import envent2Img01 from '../images/event/02/banner_img@3x_01.gif';
import envent2Img02 from '../images/event/02/banner_img@3x_02.gif';
import envent2Img03 from '../images/event/02/banner_img@3x_03.gif';
import envent2Img04 from '../images/event/02/banner_img@3x_04.gif';
import envent2Img05 from '../images/event/02/banner_img@3x_05.gif';
import envent2Img06 from '../images/event/02/banner_img@3x_06.gif';
import envent2Img07 from '../images/event/02/banner_img@3x_07.gif';
import envent2Img08 from '../images/event/02/banner_img@3x_08.gif';

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// login input
			loginId: '', //아이디
			loginPw: '', //패스워드
			saveIdYn: false, //아이디저장여부
			buttonEnable: false, //버튼enable

			// id/pw 찾기
			open: false, //id찾기_dialog
			openIdx: 0, //팝업_인덱스

			// 회원가입
			openJoin: false, //회원가입_dialog

			//모달
			postModalOpen: false,
			openSpotPop: false, //지점검색
			//모달 end
		};
	}

	componentDidMount = () => {
		if (sessionStorage.accessToken) {
			// sessionStorage.clear();
			this.props.history.push('/Home', null);
		} else {
			sessionStorage.clear(); // hdec, mpc등에서 넘어온 경우 sessionStorage에 남은 custImg 제거용
		}
		let userId = readCookie('ajCisSaveUserID');
		this.setState({ loginId: userId });
	};

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
		});
	};

	handleSubmit = () => {
		// e.preventDefault();

		if (this.state.loginId === '') {
			ModalStore.openModal('아이디를 입력해 주세요.');
			return;
		}

		if (this.state.loginPw === '') {
			ModalStore.openModal('패스워드를 입력해 주세요.');
			return;
		}

		ProgressStore.activeProgress();

		let param = {
			username: this.state.loginId,
			password: this.state.loginPw,
		};

		this.setState({ buttonEnable: true });

		userLogin(param)
			.then((r) => {
				if (r.length > 0) {
					let userData = r[0];

					handleSessionStorage(userData, this.state.saveIdYn);

					ProgressStore.deactiveProgress();

					this.props.history.push('/Home', null);
				} else {
					ModalStore.openModal('등록된 정보가 없습니다.');
					this.setState({ buttonEnable: false });
					ProgressStore.deactiveProgress();
				}
			})
			.catch((err) => {
				alert(err);
				console.log(err);
				this.setState({ buttonEnable: false });
				ProgressStore.deactiveProgress();
			});
	};

	// 팝업
	handleClickOpen = (idx) => {
		this.setState({
			open: true,
			openIdx: idx,
		});
	};

	handleClickOpenJoin = () => {
		this.setState({
			openJoin: true,
		});
	};

	handleClose = () => {
		this.setState({
			open: false,
			openJoin: false,
		});
	};

	activeProgress = (status) => {
		this.setState({
			loadingData: status,
		});
	};

	render() {
		return (
			<div>
				<div id="skipnavigation">
					<a href="#container">본문내용 바로가기</a>
				</div>

				<div className="wrap">
					<div className="loginWrap" style={{ paddingTop: 45 }}>
						{/* <div
							style={{
								width: 1000,
								height: 100,
								boxShadow: '0 0 10px rgba(0,0,0,.1)',
								backgroundColor: '#fefefe',
								marginTop: -132,
								marginBottom: 35,
								borderRadius: 8,
							}}
						>
							<table id="__01" width="1000" height="100" border="0" cellPadding="0" cellSpacing="0">
								<tr>
									<td colSpan="5">
										<img src={eventImg01} width="1000" height="30" alt="" />
									</td>
								</tr>
								<tr>
									<td rowSpan="2">
										<img src={eventImg02} width="603" height="70" alt="" />
									</td>
									<td>
										<a href="https://play.google.com/store/apps/details?id=com.ajnetworks.mobile.cis.asset" target="_black">
											<img src={eventImg03} width="160" height="46" border="0" alt="" />
										</a>
									</td>
									<td rowSpan="2">
										<img src={eventImg04} width="34" height="70" alt="" />
									</td>
									<td>
										<a href="https://apps.apple.com/kr/app/aj%EB%A0%8C%ED%83%88-%EC%9E%90%EC%82%B0%EA%B4%80%EB%A6%AC/id1478595338 " target="_black">
											<img src={eventImg05} width="162" height="46" border="0" alt="" />
										</a>
									</td>
									<td rowSpan="2">
										<img src={eventImg06} width="41" height="70" alt="" />
									</td>
								</tr>
								<tr>
									<td>
										<img src={eventImg07} width="160" height="24" alt="" />
									</td>
									<td>
										<img src={eventImg08} width="162" height="24" alt="" />
									</td>
								</tr>
							</table>
						</div> */}
						<div className="h1Logoin">
							<h1>
								<img src={ajLogo} alt="AJ렌탈" />
							</h1>
							<div style={{ width: 715, margin: 'auto', marginTop: 30 }}>
								<table id="Table_01" width="715" height="160" border="0" cellPadding="0" cellSpacing="0">
									<tbody>
										<tr>
											<td colSpan="5">
												<img src={envent2Img01} width="715" height="101" alt="" />
											</td>
										</tr>
										<tr>
											<td rowSpan="2">
												<img src={envent2Img02} width="603" height="66" alt="" />
											</td>
											<td>
												<a href="https://apps.apple.com/kr/app/aj-%EB%A0%8C%ED%83%88go/id1584929951" target="blank">
													<img src={envent2Img03} width="44" height="44" border="0" alt="" />
												</a>
											</td>
											<td rowSpan="2">
												<img src={envent2Img04} width="9" height="66" alt="" />
											</td>
											<td>
												<a href="https://play.google.com/store/apps/details?id=com.ajnetworks.mobile.cis.rentalgo" target="blank">
													<img src={envent2Img05} width="44" height="44" border="0" alt="" />
												</a>
											</td>
											<td rowSpan="2">
												<img src={envent2Img06} width="15" height="66" alt="" />
											</td>
										</tr>
										<tr>
											<td>
												<img src={envent2Img07} width="44" height="22" alt="" />
											</td>
											<td>
												<img src={envent2Img08} width="44" height="22" alt="" />
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div className="member">
							<div className="tit">MEMBERS LOGIN</div>
							<div className="inputWrap">
								<div className="box">
									<input
										type="text"
										id="loginId"
										name="loginId"
										placeholder="아이디를 입력해 주세요."
										value={this.state.loginId || ''}
										onChange={this.handleChange}
									/>
								</div>
								<div className="box">
									<input
										type="password"
										id="loginPw"
										name="loginPw"
										placeholder="비밀번호를 입력해 주세요."
										value={this.state.loginPw || ''}
										onChange={this.handleChange}
										onKeyDown={(e) => {
											if (e.key === 'Enter') this.handleSubmit();
										}}
									/>
								</div>
								<div className="baseCehck">
									<input type="checkbox" id="saveIdYn" name="saveIdYn" checked={this.state.saveIdYn} onChange={this.handleChange} />{' '}
									<label htmlFor="saveIdYn">아이디 저장</label>
								</div>
								<div className="btnLogin" onClick={this.handleSubmit}>
									<a href="javascript:;">로그인</a>
								</div>
								<div className="link">
									<a href="javascript:;" onClick={() => this.handleClickOpen(0)}>
										아이디 찾기
									</a>
									{/* /
                                    <a href="javascript:;" onClick={() => this.handleClickOpen(1)}>패스워드 찾기</a> */}
									<a href="javascript:;" onClick={this.handleClickOpenJoin}>
										회원가입
									</a>
								</div>
							</div>
						</div>
						<div className="info">
							<a href="tel:15880053">컨텍센터 1588 - 0053</a>
							<a href="http://www.ajnetworks.co.kr/" target="_blank">
								AJ렌탈 홈페이지 바로가기
							</a>
						</div>
						{/* <div className="bannerList four">
                            <ul>
                                <li><a><img src="/images/thumb01.png" alt="베너"/></a></li>
                                <li><a><img src="/images/thumb01.png" alt="베너"/></a></li>
                                <li><a><img src="/images/thumb01.png" alt="베너"/></a></li>
                                <li><a><img src="/images/thumb01.png" alt="베너"/></a></li>
                            </ul>
                        </div> */}
						{/* 배너 */}
						<Banner />
					</div>
				</div>
				<RequestJoin open={this.state.openJoin} handleClose={this.handleClose} />
				<FindIdPw open={this.state.open} openIdx={this.state.openIdx} handleClickOpen={this.handleClickOpen} handleClose={this.handleClose} />
			</div>
		);
	}
}

export default observer(Login);
