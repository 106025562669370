import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';

import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import uniqBy from 'lodash/uniqBy';
import AutoComplete from '../include/AutoComplete';

import { errorMsgCheck, resultToIbSheet, SheetParamData3, managerCheck } from '../../common/ComFunction';
import { getMainCategoryList, saveMainCategory, getMainContCustList, saveMainContCustList, getCustMainContList } from '../../service/HomeService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore, FilePopupStore } = CommonStore;

class MainCategoryManager extends Component {
	constructor(props) {
		super(props);

		this.state = {
			//검색조건
			custNmSearch: '', //고객사명
			//조회결과
			compList: [], //고객사리스트

			path: '',

			sCustNo: '',
			sCustNm: '',
			disabledBtn: true,
			systCode: sessionStorage.systCode, //시스템코드
			searchedMainContNm: '',
		};
		this.contType = '2'; // 카테고리
		this.suggestions = []; // autoComplete props 전달용
		this.selectedRowData = { row: '', contId: null, name: null };
		this.searchedRowData = { custNmSearch: null, contId: null, name: null };
		this.custPopHandleTarget = null; // search, sheet
		this.custNoCellInfo = { row: null, col: null };
		this.sheetData = {
			id: 'mainCategorySheet', // sheet id
			initData: 'admin/Init-MainCategory', // sheet initData javascript filename
			width: '100%', // sheet width
			height: '300px', // sheet height
			sheetRendered: (sheet) => {
				this.getMainCategoryList();
			},
		};

		this.sheetData2 = {
			id: 'mainCategoryCustSheet', // sheet id
			initData: 'admin/Init-MainCategoryCust', // sheet initData javascript filename
			width: '100%', // sheet width
			height: '250px', // sheet height
			sheetRendered: (sheet) => {
				sheet.ShowFilterRow();
			},
		};
		this.sheetData3 = {
			id: 'mainCategoryCustCheckSheet', // sheet id
			initData: 'admin/Init-MainCategoryCustCheck', // sheet initData javascript filename
			width: '100%', // sheet width
			height: '250px', // sheet height
		};
	}

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet(true);
		window[this.sheetData2.id].DisposeSheet(true);
		window[this.sheetData3.id].DisposeSheet(true);
	};

	componentDidMount = () => {
		managerCheck(); // 관리자체크

		// sheet 이벤트 등록
		window[`${this.sheetData.id}_OnClick`] = (row, col) => {
			if (row > 0) {
				const sheet = this.getSheet();
				this.setState({
					path: sheet.GetCellValue(row, 'path'),
				});
				this.selectedRowData.row = row;
				this.selectedRowData.contId = sheet.GetCellValue(row, 'idx');
				this.selectedRowData.name = sheet.GetCellValue(row, 'name');
			}
		};
		//autoComplete를 위한 고객사 리스트 조회
		Promise.all([getMainContCustList()])
			.then((r) => {
				let [data] = r;
				data.map((item, idx) => {
					this.suggestions.push({ label: item.custNm });
				});

				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	getSheet = (name = 'main') => {
		if (name === 'main') {
			return window[this.sheetData.id];
		} else if (name === 'cust') {
			return window[this.sheetData2.id];
		} else if (name === 'custCheck') {
			return window[this.sheetData3.id];
		}
	};

	getMainCategoryList = () => {
		ProgressStore.activeProgress();
		let param = {
			systCode: this.state.systCode,
			custNo: this.state.sCustNo,
		};
		getMainCategoryList(param)
			.then((r) => {
				window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	//조회
	searchMainContCustList = (isReload = false) => {
		if (this.selectedRowData.row === '') {
			ModalStore.openModal('카테고리를 선택해 주세요.');
			return;
		}

		ProgressStore.activeProgress();
		let param = { contType: this.contType };

		if (isReload) {
			param.custNm = this.searchedRowData.custNmSearch;
			param.contId = this.searchedRowData.contId;
		} else {
			param.custNm = this.state.custNmSearch;
			param.contId = this.selectedRowData.contId;
		}
		getMainContCustList(param)
			.then((r) => {
				this.setState({ compList: r }, () => {
					// JSON객체형태로 서버에서 받으므로 IBsheet에서 출력 가능한 배열형태로 변환
					window[this.sheetData2.id].LoadSearchData(resultToIbSheet(r));
				});
				if (!isReload) {
					this.searchedRowData.contId = this.selectedRowData.contId;
					this.searchedRowData.name = this.selectedRowData.name;
					this.searchedRowData.custNmSearch = this.state.custNmSearch;
					this.setState({
						searchedMainContNm: this.searchedRowData.name,
					});
				}

				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	searchCustMainContList = () => {
		if (this.state.sCustNo === '') {
			ModalStore.openModal('고객을 먼저 선택해 주세요.');
			return;
		}
		ProgressStore.activeProgress();
		let param = {
			custNo: this.state.sCustNo,
			systCode: this.state.systCode,
			contType: this.contType,
		};
		getCustMainContList(param)
			.then((r) => {
				this.setState({ compList: r }, () => {
					// JSON객체형태로 서버에서 받으므로 IBsheet에서 출력 가능한 배열형태로 변환
					window[this.sheetData3.id].LoadSearchData(resultToIbSheet(r));
				});

				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	addSheet = () => {
		let sheet = this.getSheet();
		if (sheet.FindStatusRow('I').length >= 1) {
			ModalStore.openModal('한건씩 등록해 주세요.');
			return;
		}
		const row = sheet.DataInsert();
		sheet.SetCellValue(row, 'systCode', this.state.systCode);

		this.setState({
			row: row,
		});
	};

	saveMainCategory = () => {
		var sheet = this.getSheet();
		if (sheet.FindStatusRow('I|U') === '') {
			alert('저장할 내역이 없습니다.');
			return;
		}

		// 고객사별 노출순서 중복 체크
		const allRows = sheet.GetSaveJson({ AllSave: 1, ValidFullInput: 0, ValidKeyField: 0 }).data;
		if (allRows.length !== uniqBy(allRows, 'dispIdx').length) {
			alert('노출순서가 중복 됩니다.');
			return;
		}

		let sheetParam = SheetParamData3(sheet, null, false);
		if (sheetParam.Message == 'KeyFieldError') {
			return;
		}

		if (sheetParam && sheetParam.data.length > 0) {
			ConfirmModalStore.openModal('저장 하시겠습니까?', () => {
				ProgressStore.activeProgress();
				saveMainCategory(sheetParam)
					.then((r) => {
						ModalStore.openModal('저장되었습니다.');
						this.getMainCategoryList();
						ProgressStore.deactiveProgress();
					})
					.catch((err) => {
						errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
					})
					.finally(() => {
						ProgressStore.deactiveProgress();
					});
			});
		}
	};

	saveCustList = () => {
		if (this.selectedRowData.row === '') {
			ModalStore.openModal('카테고리를 선택해 주세요.');
			return;
		}
		var sheet = this.getSheet('cust');
		if (sheet.FindStatusRow('I|U') === '') {
			alert('저장할 내역이 없습니다.');
			return;
		}

		let sheetParam = SheetParamData3(sheet, null, false);
		if (sheetParam.Message == 'KeyFieldError') {
			return;
		}

		if (sheetParam && sheetParam.data.length > 0) {
			ConfirmModalStore.openModal(`"${this.searchedRowData.name}"에 저장 하시겠습니까?`, () => {
				ProgressStore.activeProgress();
				sheetParam.contId = this.searchedRowData.contId;
				sheetParam.contType = this.contType;
				saveMainContCustList(sheetParam)
					.then((r) => {
						ModalStore.openModal('저장되었습니다.');
						this.searchMainContCustList(true);
						ProgressStore.deactiveProgress();
					})
					.catch((err) => {
						errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
					})
					.finally(() => {
						ProgressStore.deactiveProgress();
					});
			});
		}
	};

	fileUpload = () => {
		if (this.selectedRowData.row === '') {
			ModalStore.openModal('카테고리를 선택해 주세요.');
			return;
		}

		FilePopupStore.openPopup('파일', this.handleFileClose);
	};
	handleFileClose = (result) => {
		if (result !== undefined) {
			this.setState({
				path: result.fileSavePath,
			});

			let sheet = window[this.sheetData.id];
			sheet.SetCellValue(this.selectedRowData.row, 'path', result.fileSavePath);
		}
	};

	resetState = (type) => {
		// 전체 초기화
		this.getSheet('main').LoadSearchData(
			{
				data: [],
			},
			{
				Sync: 1,
			},
		);
		this.getSheet('cust').LoadSearchData(
			{
				data: [],
			},
			{
				Sync: 1,
			},
		);
		this.setState(
			{
				path: '',

				loadingData: false,
				disabledBtn: true,
				searchedMainContNm: '',
			},
			() => {
				this.getMainCategoryList();
			},
		);
		this.selectedRowData.row = '';
		this.selectedRowData.contId = null;
		this.selectedRowData.name = null;
		this.searchedRowData.contId = null;
		this.searchedRowData.name = null;
		this.searchedRowData.custNmSearch = null;
	};

	handleChangeSearch = (e) => {
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
		});
	};

	openCustPop = () => {
		CustSearchPopupStore.openPopup('1', null, null, this.handleCustSearch);
	};

	handleCustSearch = (item) => {
		if (item !== undefined) {
			this.setState({
				sCustNo: item.custNo,
				sCustNm: item.custNm,
			});
			setTimeout(() => {
				this.searchCustMainContList();
			});
		}
	};

	clearField = () => {
		this.setState({
			sCustNo: '',
			sCustNm: '',
		});
	};

	custHandleSearchChange = (value) => {
		this.setState({
			custNmSearch: value,
		});
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont" style={{ height: 1350 }}>
							<div className="h2Wrap mb30">
								<h2>대표 카테고리 관리</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.getMainCategoryList}>
										<span>조회</span>
									</a>
									<a className="btn03 add" onClick={this.addSheet}>
										<span>등록</span>
									</a>
								</div>
							</div>
							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '15%' }} />
										<col style={{ width: '20%' }} />
										<col style={{ width: '15%' }} />
										<col style={{ width: '50%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>시스템</th>
											<td>
												<select
													name="systCode"
													value={this.state.systCode || ''}
													onChange={(e) => {
														this.handleChangeSearch(e);
														this.resetState();
													}}
													ref={(ref) => (this.systCode = ref)}
												>
													<option value="CIS">고객발주시스템</option>
													<option value="ARM">렌탈몰</option>
												</select>
											</td>
											<td></td>
											<td></td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="writeType03">
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">대표 카테고리 리스트</p>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
							<div className="writeType03">
								<p className="headline">대표 카테고리 이미지</p>
								<table>
									<colgroup>
										<col style={{ width: 80 }} />
										<col style={{ width: 400 }} />
										<col style={{ width: 100 }} />
									</colgroup>
									<tbody>
										<tr>
											<th>대표 카테고리 이미지</th>
											<td>
												<input type="text" value={this.state.path} readOnly={true} style={{ cursor: 'pointer', width: 350 }} />
												<Button color="primary" variant="outlined" size="small" style={{ marginLeft: 10, alignItems: 'initial' }} onClick={this.fileUpload}>
													<SearchIcon style={{ fontSize: 20, marginLeft: 5 }} />
													파일찾기
												</Button>
											</td>
											<td style={{ textAlign: 'right' }}>
												<Button
													color="secondary"
													variant="contained"
													size="small"
													style={{ marginLeft: 10, alignItems: 'initial' }}
													onClick={this.saveMainCategory}
												>
													<SaveIcon style={{ fontSize: 20 }} />
													저장
												</Button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="writeType03" style={{ float: 'left', width: '49%' }}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<div className="headline">고객 리스트</div>
									<div style={{ float: 'left', width: '130px', height: '40px', overflow: 'hidden', textOverflow: 'ellipsis', paddingTop: '6px' }}>
										{this.state.searchedMainContNm}
									</div>
									<div style={{ float: 'right' }}>
										<div
											onKeyDown={(e) => {
												if (e.key === 'Enter') this.searchMainContCustList();
											}}
											style={{ display: 'inline-block', marginBottom: '11px' }}
										>
											<AutoComplete placeholder="고객사명을 입력하세요" handleSearchChange={this.custHandleSearchChange} suggestions={this.suggestions} />
										</div>
										<Button className="button" onClick={this.searchMainContCustList.bind(null, false)} color="primary" variant="contained" size="small">
											<SearchIcon className="searchIcon" />
											<span className="text">조회</span>
										</Button>
										<Button style={{ marginLeft: '5px' }} className="button" onClick={this.saveCustList} color="primary" variant="contained" size="small">
											<SaveIcon className="saveIcon" />
											<span className="text">저장</span>
										</Button>
									</div>
									<IBSheet {...this.sheetData2} />
								</div>
							</div>
							<div className="writeType03" style={{ float: 'right', width: '50%' }}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<div className="headline">고객 체크 리스트</div>
									<div style={{ float: 'right' }}>
										<input
											type="text"
											readOnly={true}
											value={this.state.sCustNm}
											onClick={this.clearField}
											style={{ border: '1px solid #dddddd', height: '25px', padding: '0 0 0 10px', marginRight: '5px', width: '200px' }}
										/>
										<IconButton onClick={this.openCustPop} color="secondary" className="iconButton">
											<SearchIcon fontSize="small" />
										</IconButton>

										<Button className="button" onClick={this.searchCustMainContList} color="primary" variant="contained" size="small">
											<SearchIcon className="searchIcon" />
											<span className="text">조회</span>
										</Button>
									</div>
									<IBSheet {...this.sheetData3} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(MainCategoryManager);
