import { httpGet, httpPost, httpPut, toQueryString } from '../Service';

// api 수리비 청구전송 리스트
export function getApiReprRequestSendList(data) {
	return httpGet('/api/v1.0/cis/api/apiReprRequestSendList' + toQueryString(data));
}
// api 청구할 내역 조회
export function getApiReprRequestSendInfo(data) {
	return httpGet('/api/v1.0/cis/api/apiReprRequestSendInfo' + toQueryString(data));
}
// api 청구전송한 내역 조회
export function getApiReprRequestSendHistory(data) {
	return httpGet('/api/v1.0/cis/api/apiReprRequestSendHistory' + toQueryString(data));
}
// api 청구전송
export function putApiReprRequestSend(data) {
	return httpPut('/api/v1.0/cis/api/apiReprRequestSend/', { body: data });
}
