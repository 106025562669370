import {httpGet,httpPost,httpPut,toQueryString} from '../Service';

// 고정결재선조회
export function getFixApprList(data){
    return httpGet('/api/v1.0/cis/members/fix-appr'+toQueryString(data));
}

//결재자 등록
export function insertFixAppr(data){
    data = data || {};
    return httpPost('/api/v1.0/cis/members/fix-appr/',{body:data});
}

//결재자 순서 변경 및 삭제
export function updateFixAppr(data){
    return httpPut('/api/v1.0/cis/members/fix-appr/'+data);
}