import React, { Component } from 'react';
import { errorMsgCheck } from '../../common/ComFunction';
import { FileService } from '../../service/include/FileUploadService';
import { Button } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ProgressStore, FilePopupStore } = CommonStore;

class FilePopup extends Component {
	constructor() {
		super();
		this.fileService = new FileService();
	}

	state = {
		fileList: [],
	};

	handleChange = (selectorFiles) => {
		// console.log(selectorFiles);
		this.setState({ fileList: selectorFiles });
	};

	resetState = () => {
		//state초기화
		this.setState({
			fileList: [],
		});
	};

	// 저장
	saveFile = () => {
		const { fileList } = this.state;
		const data = new FormData();

		//validation chk
		if (fileList.length === 0) {
			ModalStore.openModal('업로드 할 파일을 먼저 선택하세요.');
			return;
		}
		// validation chk end
		ProgressStore.activeProgress();

		let file = fileList[0];
		console.log('Uploading file', file);
		data.append('file', file);
		data.append('instUser', sessionStorage.userId);
		// data.append('description', 'this file is uploaded by kkj');
		this.fileService
			.uploadFileToServer(data)
			.then((r) => {
				console.log('File ' + file.name + ' is uploaded');
				// console.log(r.data.result[0]);

				ModalStore.openModal('저장되었습니다.');

				this.resetState();
				FilePopupStore.closePopup(r.data.result[0]);
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	render() {
		const { open, title } = FilePopupStore;

		let popContHeight = {
			height: 300,
		};

		return (
			<div>
				<Dialog open={open} onClose={FilePopupStore.closePopup}>
					<div className="popSmArea searchPop">
						<div className="popCont" style={popContHeight}>
							<div className="h2Wrap">
								<h2>파일 업로드 </h2>
							</div>
							<div className="writeType02 type2">
								<table>
									<colgroup>
										<col style={{ width: '160px' }} />
										<col />
									</colgroup>
									<tbody>
										<tr>
											<th>{title} </th>
											<td>
												<input
													accept="image/*"
													// style={{display: 'none'}}
													id="outlined-button-file"
													// multiple
													type="file"
													onChange={(e) => this.handleChange(e.target.files)}
												/>
												{/* <label htmlFor="outlined-button-file">
                                                    <Button className="btnInTable" variant="outlined" size="small" component="span">
                                                        파일 선택
                                                    </Button>
                                                </label> */}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="btnRight2">
								<Button className="button" onClick={this.saveFile} color="primary" variant="contained" size="small">
									<SaveIcon className="saveIcon" />
									<span className="text">저장</span>
								</Button>
							</div>
							<div
								className="btnClose"
								onClick={() => {
									FilePopupStore.closePopup(undefined);
									this.resetState();
								}}
							>
								<a>팝업닫기</a>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}
}

export default observer(FilePopup);
