import React, { Component } from 'react';

import Dialog from '@material-ui/core/Dialog';

import Button from '@material-ui/core/Button';

import { errorMsgCheck, buildSelectItems, Transition } from '../../common/ComFunction';
import { getInfoList, getCouponSend } from '../../service/admin/MobileCouponService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore } = CommonStore;

class MallMoCpManagerPopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			largeCate: '',
			model: '',
			itemCode: '',
			rootCd: '04', //IT
			receivermobile: '', //연락받을 번호

			goodsId: '',
			ordeNo: '',
			arr0: '',
			arr1: '',
			arr2: '',
			arr3: '',
			upperCode2: '',
			upperCode3: '',
		};
	}

	componentDidMount = () => {
		//팝업 쿠폰 발송 업체 셋팅
		this.setPopup(null, '0');
	};

	//selectBox change Event
	handleChange = (e, i) => {
		this.setPopup(e.target.value, i);
	};

	setPopup = (data, index) => {
		var thisVal = data;

		// if(data == ''){ //전체를 선택시 이전에 선택된 상위 코드로 셋팅
		//     data = this.state.upperCode;
		// }else{          //전체가 아닐시 선택한 코드가 상위코드로 등록
		//     this.setState({
		//         upperCode: data
		//     })
		// }

		// if(data == ''){
		//     if(index == '2'){
		//         data = this.state.upperCode2;
		//     }else if(index == '3'){
		//         data = this.state.upperCode3;
		//     }
		// }else{
		//     if(index == '2'){
		//         this.setState({
		//             upperCode2: data
		//         })
		//     }else if(index == '3'){
		//         this.setState({
		//             upperCode3: data
		//         })
		//     }
		// }

		//소분류를 제외한 콤보박스 선택시 소분류코드 빈값처리
		if (index == '1' || index == '2' || index == '3') {
			this.setState({
				goodsId: '',
			});
		}

		if (data == '') {
			if (index == '1') {
				this.setState({
					arr1: [],
					arr2: [],
					arr3: [],
					arr0Val: thisVal,
					arr1Val: '',
					arr2Val: '',
					arr3Val: '',
				});
			} else if (index == '2') {
				this.setState({
					arr2: [],
					arr3: [],
					arr1Val: thisVal,
					arr2Val: '',
					arr3Val: '',
				});
			} else if (index == '3') {
				this.setState({
					arr3: [],
					arr2Val: thisVal,
					arr3Val: '',
				});
			} else if (index == '4') {
				this.setState({
					arr3Val: thisVal,
					goodsId: thisVal,
				});
			}
			return;
		}

		//서버 통신 할 상위코드 셋팅
		let param = {
			upper_code: data,
		};

		//소분류 선택시 서버통신 금지
		if (index == '4') {
			this.setState({
				arr3Val: thisVal,
				goodsId: thisVal,
			});
			return;
		}

		getInfoList(param)
			.then((r) => {
				let arr = [];

				for (var i = 0; i < r.length; i++) {
					let selectObject = {};
					if (index == '3') {
						selectObject.value = r[i].goodsId;
					} else {
						selectObject.value = r[i].code;
					}

					selectObject.title = r[i].codeName;
					arr.push(selectObject);
				}

				if (index == '0') {
					this.setState({
						arr0: arr,
						arr1: [],
						arr2: [],
						arr3: [],
						arr0Val: thisVal,
						arr1Val: '',
						arr2Val: '',
						arr3Val: '',
					});
				} else if (index == '1') {
					this.setState({
						arr1: arr,
						arr2: [],
						arr3: [],
						arr0Val: thisVal,
						arr1Val: '',
						arr2Val: '',
						arr3Val: '',
					});
				} else if (index == '2') {
					this.setState({
						arr2: arr,
						arr3: [],
						arr1Val: thisVal,
						arr2Val: '',
						arr3Val: '',
					});
				} else if (index == '3') {
					this.setState({
						arr3: arr,
						arr2Val: thisVal,
						arr3Val: '',
					});
				}
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	//confirm check
	comfirmCheck = () => {
		let regExp = /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/; //핸드폰번호 정규식
		if (!this.state.goodsId) {
			ModalStore.openModal('선택된 소분류가 없습니다.');
			return;
		}

		if (!this.state.receivermobile) {
			this.phoneInput.focus();
			ModalStore.openModal('입력된 번호가 없습니다.');
			return;
		}

		if (!regExp.test(this.state.receivermobile)) {
			this.phoneInput.focus();
			ModalStore.openModal("'-'를 제외한 올바른 핸드폰 번호를 입력해주세요.");
			return;
		}
		ConfirmModalStore.openModal('쿠폰생성 하시겠습니까?', this.checkCouponInsert);
	};

	//쿠폰 생성
	checkCouponInsert = () => {
		let param = {
			ordeNo: this.props.ordeNo,
			goodsId: this.state.goodsId,
			receivermobile: this.state.receivermobile,
		};

		getCouponSend(param)
			.then((r) => {
				if (r[0].successCnt == '1') {
					//쿠폰 발급 내역 재요청
					this.props.getContOrderItemList(this.props.ordeNo);
					ModalStore.openModal('쿠폰생성 성공하였습니다.');
					this.popClose();
				} else {
					ModalStore.openModal('쿠폰생성 실패하였습니다.');
				}
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};

	popClose = (data) => {
		this.setState({
			model: '',
			goodsId: '',
			receivermobile: '',
			arr0Val: '',
			arr1: [],
			arr2: [],
			arr3: [],
		});

		this.props.handleClose(data);
	};

	modelHandler = (model) => {
		this.setState({
			model: model,
		});
	};

	inputChange = (e) => {
		this.setState({
			receivermobile: e.target.value,
		});
	};

	selectCategory = (e) => {
		this.setState({
			goodsId: e.target.value,
		});
	};

	render() {
		const { open, stockType, model } = this.props;
		return (
			<div>
				<Dialog open={open} TransitionComponent={Transition} onClose={this.popClose}>
					<div className="popSmArea searchPop" style={{ transform: 'translate(-8%, 0)' }}>
						<div className="popCont" style={{ height: 520, width: 900, overflow: 'hidden' }}>
							<div className="h2Wrap">
								<h2>쿠폰 발송 팝업</h2>
							</div>
							<div className="writeType03">
								<table className="cpPop">
									<colgroup>
										<col style={{ width: '19%' }} />
										<col style={{ width: '27%' }} />
										<col style={{ width: '27%' }} />
										<col style={{ width: '27%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>상품명</th>
											<td>{this.props.model}</td>
											<th>주문자</th>
											<td>{this.props.ordeUser}</td>
										</tr>
										<tr>
											<th>수량</th>
											<td>{this.props.goodsCnt} 개</td>
											<th>쿠폰 발송 업체</th>
											<td>
												<select onChange={(e) => this.handleChange(e, '1')} style={{ width: '85%' }} value={this.state.arr0Val}>
													{this.state.arr0 ? buildSelectItems([{ title: '선택', value: '' }].concat(this.state.arr0)) : null}
												</select>
											</td>
										</tr>
										<tr>
											<th>쿠폰</th>
											<td colSpan="3">
												대분류{' '}
												<select onChange={(e) => this.handleChange(e, '2')} style={{ width: '25%' }} value={this.state.arr1Val}>
													{this.state.arr1 ? buildSelectItems([{ title: '선택', value: '' }].concat(this.state.arr1)) : null}
												</select>
												중분류{' '}
												<select onChange={(e) => this.handleChange(e, '3')} style={{ width: '25%' }} value={this.state.arr2Val}>
													{this.state.arr2 ? buildSelectItems([{ title: '선택', value: '' }].concat(this.state.arr2)) : null}
												</select>
												소분류{' '}
												<select onChange={(e) => this.handleChange(e, '4')} style={{ width: '25%' }} value={this.state.arr3Val}>
													{this.state.arr3 ? buildSelectItems([{ title: '선택', value: '' }].concat(this.state.arr3)) : null}
												</select>
											</td>
										</tr>
										<tr>
											<th>수신 핸드폰 번호</th>
											<td colSpan="3">
												<input
													type="text"
													placeholder="숫자만 입력해주세요."
													maxLength="11"
													onKeyUp={(e) => this.inputChange(e)}
													ref={(ref) => (this.phoneInput = ref)}
												></input>
											</td>
										</tr>
									</tbody>
								</table>
								<div style={{ marginTop: 15, marginBottom: -15, textAlign: 'right' }}>
									<Button color="secondary" size="small" variant="contained" style={{ marginLeft: 10, alignItems: 'initial' }} onClick={this.comfirmCheck}>
										쿠폰 생성
									</Button>
								</div>
							</div>
							<div className="btnClose" onClick={this.popClose}>
								<a>팝업닫기</a>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}
}
export default observer(MallMoCpManagerPopup);
