import React, { Component } from 'react';
import LeftMenu from '../include/LeftMenu';
import Header from '../include/Header';
import IBSheet from '../IBSheet';
import * as MallService from '../../service/admin/MallService';
import { errorMsgCheck, resultToIbSheet, dateToFormat } from '../../common/ComFunction';
import { Search, Clear } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore } = CommonStore;

class MallRegularPay extends Component {
	constructor(props) {
		super(props);

		this.sheetMallPayList = {
			//주문 리스트
			id: 'sheetMallPayList', // sheet id
			initData: 'admin/Init-MallOrderPayList', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '400px', // sheet height
		};
	}

	state = {
		custNo: '',
		custNm: '',
		payDate: moment(),
		selPayYn: 'N',
		selPayDay: '',
		orderUser: '',
		ordeNo: '',
		contNo: '',

		payCardNm: '',
		userCardNo: '',
		cardResultMsg: '',
	};

	componentWillUnmount = () => {
		if (window[this.sheetMallPayList.id]) {
			window[this.sheetMallPayList.id].DisposeSheet();
		}
	};

	handleClose = (item) => {
		if (item !== undefined) {
			if (item.custNo !== undefined) {
				this.setState({
					custNo: item.custNo,
					custNm: item.custNm,
				});
			}
		}
	};

	searchMonthPayList = () => {
		ProgressStore.activeProgress();

		let param = {
			custNo: this.state.custNo,
			payDate: dateToFormat(this.state.payDate).substring(0, 7),
			selPayDay: this.state.selPayDay,
			selPayYn: this.state.selPayYn,
			orderUser: this.state.orderUser,
			ordeNo: this.state.ordeNo,
			contNo: this.state.contNo,
		};

		MallService.orderMonthPayList(param)
			.then((r) => {
				window[this.sheetMallPayList.id].LoadSearchData(resultToIbSheet(r));
				//필드리셋
				this.fieldReset();

				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};

	getOrderPayData = () => {
		let data = window.getOrderSelectData();

		if (data) {
			this.setState({
				payCardNm: data.issuerCardNm,
				userCardNo: data.displayCardNo,
				cardResultMsg: data.payResultMsg,
			});
		}
	};

	fieldReset = () => {
		this.setState({
			payCardNm: '',
			userCardNo: '',
			cardResultMsg: '',
		});
	};

	//카드결제
	cardPayment = () => {
		let paramList = [];
		let object = window[this.sheetMallPayList.id];
		let item = {};
		let saleAmt = 0;

		for (var i = 1; i <= object.GetTotalRows(); i++) {
			// 상태가 수정인 행만 fetch
			if (object.GetCellValue(i, 'status') === 'U') {
				if (object.GetCellValue(i, 'payYn') == 'Y') {
					alert('이미 결제된 건이 선택 되었습니다.');
					return;
				}

				item.PayMethod = 'CARD';
				item.Amt = object.GetCellValue(i, 'sumReqAmt');
				item.BuyerName = object.GetCellValue(i, 'rqstUserNm');
				item.GoodsName = object.GetCellValue(i, 'itemDesc');
				item.MallUserID = object.GetCellValue(i, 'rqstId');
				item.MallIP = '118.129.170.131';
				item.BillTokenKey = object.GetCellValue(i, 'billTokenKey');
				item.Moid = object.GetCellValue(i, 'ordeNo');
				item.CardQuota = '00'; //object.GetCellValue(i, 'cardQuota') ? object.GetCellValue(i, 'cardQuota') : '00';
				paramList.push(item);
				item = {};
			}
		}

		if (paramList.length === 0) {
			ModalStore.openModal('선택된 건이 없습니다.');
			ProgressStore.deactiveProgress();
			return;
		}

		ConfirmModalStore.openModal('카드 결제 처리를 진행 하시겠습니까?', () => this.cardPaymentProc(paramList));
	};

	cardPaymentProc = (paramList) => {
		ProgressStore.activeProgress();

		MallService.orderCardPayment(paramList)
			.then((r) => {
				ModalStore.openModal('처리되었습니다.');

				this.fieldReset(); //필드 리셋
				this.searchMonthPayList(); //저장 성공 후 재조회

				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont" style={{ minHeight: 900 }}>
							<div className="h2Wrap mb30">
								<h2>Mall 정기결제</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.searchMonthPayList}>
										<span>조회</span>
									</a>
								</div>
							</div>
							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '10%' }} />
										<col style={{ width: '11%' }} />
										<col style={{ width: '10%' }} />
										<col style={{ width: '10%' }} />
										<col style={{ width: '10%' }} />
										<col style={{ width: '20%' }} />
										<col style={{ width: '10%' }} />
										<col style={{ width: '20%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>고객명</th>
											<td colSpan="3">
												<input type="text" className="size01" name="custNm" value={this.state.custNm || ''} readOnly />
												<IconButton
													aria-label="Search"
													className="iconButton"
													onClick={() => CustSearchPopupStore.openPopup('1', null, null, this.handleClose)}
													style={{ display: sessionStorage.btnControl }}
												>
													<Search fontSize="small" />
												</IconButton>
												<IconButton
													aria-label="Clear"
													className="iconButton"
													onClick={() => this.setState({ custNo: '', custNm: '' })}
													style={{ display: sessionStorage.btnControl }}
												>
													<Clear fontSize="small" />
												</IconButton>
											</td>
											<th>결제월</th>
											<td>
												<DatePicker
													customInput={<input type="text" style={{ width: 80 }} />}
													dateFormat="YYYY-MM"
													onChange={(date) => this.setState({ payDate: date })}
													selected={this.state.payDate}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
											</td>
											<th>결제일</th>
											<td>
												<select
													name="selPayDay"
													value={this.state.selPayDay || ''}
													onChange={(e) => {
														this.setState({ selPayDay: e.target.value });
													}}
													style={{ width: 120 }}
												>
													<option value="">전체</option>
													<option value="5" selected>
														5일
													</option>
													<option value="10">10일</option>
													<option value="15">15일</option>
													<option value="20">20일</option>
													<option value="25">25일</option>
												</select>
											</td>
										</tr>
										<tr>
											<th>결제여부</th>
											<td>
												<select
													name="selPayYn"
													value={this.state.selPayYn || ''}
													onChange={(e) => {
														this.setState({ selPayYn: e.target.value });
													}}
													style={{ width: 120 }}
												>
													<option value="">전체</option>
													<option value="N" selected>
														미결제
													</option>
													<option value="Y">완료</option>
												</select>
											</td>
											<th>주문자명</th>
											<td>
												<input
													type="text"
													name="orderUser"
													value={this.state.orderUser || ''}
													onChange={(e) => {
														this.setState({ orderUser: e.target.value });
													}}
													style={{ width: 120 }}
												/>
											</td>
											<th>주문번호</th>
											<td>
												<input
													type="text"
													name="ordeNo"
													value={this.state.ordeNo || ''}
													onChange={(e) => {
														this.setState({ ordeNo: e.target.value });
													}}
													style={{ width: 120 }}
												/>
											</td>
											<th>계약번호</th>
											<td>
												<input
													type="text"
													name="contNo"
													value={this.state.contNo || ''}
													onChange={(e) => {
														this.setState({ contNo: e.target.value });
													}}
													style={{ width: 120 }}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="writeType03">
								<div style={{ marginTop: 15, marginBottom: -15, textAlign: 'right' }}>
									<Button className="button" color="primary" variant="contained" size="small" onClick={() => this.cardPayment()}>
										<SaveIcon className="saveIcon" />
										<span className="text">카드결제</span>
									</Button>
								</div>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
									onClick={() => this.getOrderPayData()}
								>
									<p className="headline">결제 대상 리스트</p>
									<IBSheet {...this.sheetMallPayList} />
								</div>
							</div>
							<div className="writeType03_acc">
								<p className="headline">카드결제정보</p>
								<table>
									<colgroup>
										<col style={{ width: 60 }} />
										<col style={{ width: 100 }} />
										<col style={{ width: 70 }} />
										<col style={{ width: 170 }} />
										<col style={{ width: 100 }} />
										<col />
									</colgroup>
									<tbody>
										<tr>
											<th>카드사</th>
											<td>
												<input type="text" value={this.state.payCardNm} style={{ border: 0 }} readOnly={true} />
											</td>
											<th>카드번호</th>
											<td>
												<input type="text" value={this.state.userCardNo} style={{ border: 0 }} readOnly={true} />
											</td>
											<th>카드승인결과</th>
											<td>
												<textarea value={this.state.cardResultMsg} rows="1" style={{ border: 0, height: 53 }} readOnly={true}></textarea>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(MallRegularPay);
