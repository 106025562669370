import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import Menu from '@material-ui/core/Menu';
import Tooltip from '@material-ui/core/Tooltip';

import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/HighlightOff';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { errorMsgCheck, resultToIbSheet, dateToFormat } from '../../common/ComFunction';
import { getAppList, updateOrderApp } from '../../service/rental/RentalOrderAppService';
import { getOrderDetail, getOrderItemList, getOrderAppStatus } from '../../service/rental/RentalOrderService';
import { getAssetReturnAskInfo, getReturnAssetInfo } from '../../service/rental/RentalAssetService';
import queryString from 'query-string';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, UserPopupStore } = CommonStore;

class RentalOrderAppr extends Component {
	constructor(props) {
		super(props);
		const query = queryString.parse(props.location.search);
		this.state = {
			sUserNm: sessionStorage.authCode > 1 ? sessionStorage.userName : '',
			sUserId: sessionStorage.authCode > 1 ? sessionStorage.userId : '',
			sOrdeNo: query.ordeNo === undefined ? '' : query.ordeNo, // 최초결재자 링크를 통해 들어온 경우 쿼리스트링에서 요청번호 추출
			sAppStatus: 'N',

			appStatus: '',
			delSpotId: '',
			delSpotNm: '',
			delDeptId: '',
			delDeptNm: '',
			delAddrUser: '',
			delTel: '',
			delAddr: '',
			delAddrDtl: '',
			ordeRmk: '',
			appDesc: '',
			disabledApp: true,

			//결재정보
			appList: [],

			anchorEl: null,
			procData: [],
			procCnt: 0,
			procSuccessCnt: 0,
			procFailCnt: 0,
			multiCheckMode: (sessionStorage.authCode > 1 && sessionStorage.custNo == '2711687'),// || sessionStorage.custNo == '2701378';
			searchedAppStatus: '',
		};

		this.sheetData = {
			id: 'rentalOrderApprSheet', // sheet id
			initData: 'rental/Init-RentalOrderAppr', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '637px', // sheet height
		};

		this.sheetData2 = {
			id: 'rentalOrderItemSearch', // sheet id
			initData: 'rental/Init-RentalOrderItemSearch', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '170px', // sheet height
		};

		window[`${this.sheetData.id}_OnChange`] = (row, col) => {
			if(window[this.sheetData.id].CheckedRows(1) != 0) {
				this.setState({
					disabledApp: false
				});
			} else {
				this.setState({
					disabledApp: true
				});
			}
			

		};
	}

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
		window[this.sheetData2.id].DisposeSheet();
	};

	searchAppList = () => {
		if (this.state.sUserId === '') {
			ModalStore.openModal('결재자 ID는 반드시 입력해 주세요.');
			return;
		}

		//초기화
		this.setOrderInfo();
		window[this.sheetData2.id].RemoveAll();
		this.getAppList();
	};

	getAppList = () => {
		let param = {
			ordeNo: this.state.sOrdeNo,
			appStatus: this.state.sAppStatus,
			userId: this.state.sUserId,
		};

		ProgressStore.activeProgress();

		getAppList(param)
			.then((r) => {
				window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				if(this.state.sAppStatus == 'N' && this.state.multiCheckMode == true) { // 결재대상만 체크박스 보이기
					window[this.sheetData.id].SetColHidden(1, 0);
				} else {
					window[this.sheetData.id].SetColHidden(1, 1);
				}
				this.setState({
					searchedAppStatus: this.state.sAppStatus
				});
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	getOrdeDetailInfo = () => {
		if (window[this.sheetData.id].LastRow() === 0) return;

		let selData = window.getAppSelectData();
		if (selData.ordeNo === undefined) return;

		ProgressStore.activeProgress();
		this.setState({
			ordeNo: selData.ordeNo,
			appIndex: selData.appIndex,
			appStatus: selData.appStatus,
			appType: selData.appType,
		});

		//발주
		if (selData.appType === 'orde') {
			Promise.all([getOrderDetail(selData.ordeNo), getOrderAppStatus(selData.ordeNo), getOrderItemList(selData.ordeNo)])
				.then((r) => {
					let [ordeInfo, appInfo, ordeItemInfo] = r;

					this.setOrderInfo(ordeInfo[0]);
					this.setState({
						appList: appInfo,
					});
					window[this.sheetData2.id].LoadSearchData(resultToIbSheet(ordeItemInfo));

					ProgressStore.deactiveProgress();
				})
				.catch((err) => {
					errorMsgCheck(err, ProgressStore.deactiveProgress());
				});
			//반납
		} else {
			Promise.all([getAssetReturnAskInfo(selData.ordeNo), getOrderAppStatus(selData.ordeNo), getReturnAssetInfo(selData.ordeNo)])
				.then((r) => {
					let [ordeInfo, appInfo, ordeItemInfo] = r;

					this.setOrderInfo(ordeInfo[0]);
					this.setState({
						appList: appInfo,
					});
					window[this.sheetData2.id].LoadSearchData(resultToIbSheet(ordeItemInfo));

					ProgressStore.deactiveProgress();
				})
				.catch((err) => {
					errorMsgCheck(err, ProgressStore.deactiveProgress());
				});
		}
	};

	procApp = () => {

		if(this.state.multiCheckMode == true && this.state.searchedAppStatus == 'N') { // 멀티 결재 처리
			if(window[this.sheetData.id].CheckedRows(1) == 0) {
				return;
			}
			
			this.setState({
				procSuccessCnt: 0,
				procFailCnt: 0,
				procCnt: 0,
			});
			
			var saveData = window[this.sheetData.id].GetSaveJson().data;
	
			const procData = [];

			for(let i=0;i<saveData.length;i++) {
				const data = saveData[i];
				if(data.appStatus != '결재전') {
					ModalStore.openModal('"결재전" 상태만 결재처리가 가능합니다.');
					return false;
				}
				let param = {
					ordeNo: data.ordeNo,
					appIndex: data.appIndex,
					appYn: this.state.appType,
					appDesc: this.state.appDesc,
					userId: sessionStorage.userId,
					appType: data.appType,
				};
				procData.push(param);
			}
			if(procData.length > 30) {
				ModalStore.openModal(`최대 30건씩 처리가 가능합니다. (선택된 결재건: ${procData.length}건)`);
				return;
			}
			
			this.setState({
				procData: procData,
				procCnt: procData.length
			});
	
			ProgressStore.activeProgress();
			setTimeout(() => {
				this.procBatch();
			});
		} else {
			
			let selData = window.getAppSelectData();
			if (selData.ordeNo === undefined) return;
	
			let param = {
				ordeNo: this.state.ordeNo,
				appIndex: this.state.appIndex,
				appYn: this.state.appType,
				appDesc: this.state.appDesc,
				userId: sessionStorage.userId,
				appType: selData.appType,
			}; 
			// console.log(param);
			//ModalStore.openModal('처리 되었습니다.');
			ProgressStore.activeProgress();
			updateOrderApp(param)
				.then((r) => {
					ModalStore.openModal('처리 되었습니다.');
					ProgressStore.deactiveProgress();
				})
				.then(() => {
					//재조회
					this.searchAppList();
				})
				.catch((err) => {
					errorMsgCheck(err, ProgressStore.deactiveProgress());
				});
		}

	};
	procBatch = () => {
		if(this.state.procData.length == 0) {
			ModalStore.openModal(`처리 되었습니다. 총(${this.state.procCnt})/성공(${this.state.procSuccessCnt})/실패(${this.state.procFailCnt})`);
			ProgressStore.deactiveProgress();
			this.searchAppList();
			return;
		}
		const params = Object.assign(this.state.procData[0]);
		updateOrderApp(params)
			.then((r) => {
				this.state.procData.shift();
				this.setState({
					procSuccessCnt: ++this.state.procSuccessCnt
				});
				setTimeout(() => {
					this.procBatch();
				});
			})
			.catch((err) => {
				console.error(err);
				this.state.procData.shift();
				this.setState({
					procFailCnt: ++this.state.procFailCnt
				});
				setTimeout(() => {
					this.procBatch();
				});
			});
	}

	setOrderInfo = (data) => {
		if (data) {

			this.setState({
				delSpotId: data.spotId,
				delSpotNm: data.userSpotNm,
				delDeptId: data.deptId,
				delDeptNm: data.delDept,
				delAddrUser: data.delUser,
				delTel: data.delTelno + ' / ' + data.delHpno,
				delAddr: data.delPostCode + ') ' + data.delAddr,
				delAddrDtl: data.delAddrDtl,
				ordeRmk: data.ordeRmk,
			});
			if(this.state.multiCheckMode == true && this.state.searchedAppStatus == 'N') {
				this.setState({
					disabledApp: true
				});
			} else {
				this.setState({
					disabledApp: data.ordeStatus !== '9' && this.state.appStatus === '결재전' ? false : true
				});
			}
		} else {
			this.setState({
				delSpotId: '',
				delSpotNm: '',
				delDeptId: '',
				delDeptNm: '',
				delAddrUser: '',
				delTel: '',
				delAddr: '',
				delAddrDtl: '',
				ordeRmk: '',
				disabledApp: true,
				appList: [],
			});
		}
	};

	searchUser = () => {
		UserPopupStore.openPopup(null, sessionStorage.authCode > 1 ? sessionStorage.custNo : '', this.handleUserSearch);
	};

	handleUserSearch = (item) => {
		if (item !== undefined) {
			this.setState({
				sUserId: item.userId,
				sUserNm: item.userName,
			});
		}
	};

	appPop = (e, type) => {
		this.setState({
			anchorEl: e.currentTarget,
			appType: type,
		});
		var timer = setInterval(() => {
			if (this.fv !== null && this.fv !== undefined) {
				this.fv.focus();
				clearInterval(timer);
			}
		}, 100);
	};

	handleApp = () => {
		let title = '';

		if (this.state.appType === 'Y') {
			title = '결재';
		} else if (this.state.appType === 'X') {
			title = '반려';
		}

		ConfirmModalStore.openModal(title + ' 처리를 진행 하시겠습니까?', this.procApp);
		//팝업닫기
		this.appPopClose();
	};

	appPopClose = () => {
		this.setState({
			anchorEl: null,
		});
	};

	render() {
		let appInfoStyle = {
			textAlign: 'center',
			border: 0,
		};

		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont" style={{ height: 950 }}>
							<div className="h2Wrap mb30">
								<h2>결재</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.searchAppList}>
										<span>조회</span>
									</a>
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '90px' }} />
										<col style={{ width: '320px' }} />
										<col style={{ width: '90px' }} />
										<col />
									</colgroup>
									<tbody>
										<tr>
											<th>결재자</th>
											<td colSpan="3">
												<input
													type="text"
													style={{ width: 100, marginRight: 10 }}
													value={this.state.sUserId}
													onChange={(e) => this.setState({ sUserId: e.target.value })}
													readOnly={true}
												/>
												<IconButton onClick={this.searchUser} color="secondary" className="iconButton" style={{ display: sessionStorage.btnControl }}>
													<SearchIcon fontSize="small" />
												</IconButton>
												<input
													type="text"
													style={{ width: 80, marginLeft: 10 }}
													value={this.state.sUserNm}
													onChange={(e) => this.setState({ sUserNm: e.target.value })}
													readOnly={true}
												/>
											</td>
											<th>결재상태</th>
											<td>
												<select style={{ width: 130 }} onChange={(e) => this.setState({ sAppStatus: e.target.value })}>
													<option value="">전체</option>
													<option value="N" selected>
														결재대상
													</option>
													<option value="Y">결재완료</option>
													<option value="X">반려</option>
												</select>
											</td>
											<th>요청번호</th>
											<td>
												<input type="text" style={{ width: 130 }} value={this.state.sOrdeNo} onChange={(e) => this.setState({ sOrdeNo: e.target.value })} />
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="writeType03" style={{ float: 'left', width: '35%' }} onClick={this.getOrdeDetailInfo}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<div>
										<span className="headline">결재리스트</span>
										{
											this.state.multiCheckMode == true && this.state.searchedAppStatus == 'N' ?
											(<span style={{fontSize: '12px', color: 'blue'}}> ※체크박스를 선택하여 다건 결재가 가능합니다.</span>)
											:
											''
										}
									</div>
									
									<IBSheet {...this.sheetData} />
								</div>
							</div>
							<div style={{ float: 'right', width: '60%' }}>
								<p className="headline">상세정보</p>
								<div className="writeType03_acc">
									<table>
										<colgroup>
											<col style={{ width: 90 }} />
											<col style={{ width: 130 }} />
											<col style={{ width: 100 }} />
											<col />
										</colgroup>
										<tbody>
											<tr>
												<th>{sessionStorage.spotTitle}코드</th>
												<td>
													<input type="text" readOnly={true} value={this.state.delSpotId} style={{ width: 100, border: 0 }} />
												</td>
												<th>{sessionStorage.spotTitle}명</th>
												<td>
													<input type="text" readOnly={true} value={this.state.delSpotNm} style={{ width: 240, border: 0 }} />
												</td>
											</tr>
											<tr>
												<th>{sessionStorage.deptTitle}코드</th>
												<td>
													<input type="text" readOnly={true} value={this.state.delDeptId} style={{ width: 100, border: 0 }} />
												</td>
												<th>{sessionStorage.deptTitle}명</th>
												<td>
													<input type="text" readOnly={true} value={this.state.delDeptNm} style={{ width: 240, border: 0 }} />
												</td>
											</tr>
											<tr>
												<th>수령인</th>
												<td>
													<input type="text" readOnly={true} value={this.state.delAddrUser} style={{ border: 0 }} />
												</td>
												<th>연락처</th>
												<td>
													<input type="text" readOnly={true} value={this.state.delTel} style={{ border: 0 }} />
												</td>
											</tr>
											<tr>
												<th>주소</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.delAddr} style={{ border: 0 }} />
												</td>
											</tr>
											<tr>
												<th>상세주소</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.delAddrDtl} style={{ border: 0 }} />
												</td>
											</tr>
											<tr>
												<th>요청사항</th>
												<td colSpan="3">
													<textarea rows="2" readOnly={true} value={this.state.ordeRmk} style={{ height: 60, border: 0 }} />
												</td>
											</tr>
										</tbody>
									</table>
								</div>

								<div className="writeType03">
									<div
										className="scroll"
										onMouseOver={() => {
											document.body.style.overflow = 'hidden';
										}}
										onMouseOut={() => {
											document.body.style.overflow = 'auto';
										}}
									>
										<p className="headline">제품정보</p>
										<IBSheet {...this.sheetData2} />
									</div>
								</div>
								<div className="writeType03">
									<p className="headline">결재정보</p>
									<table>
										<colgroup>
											<col style={{ width: '70px' }} />
											<col style={{ width: '90px' }} />
											<col style={{ width: '70px' }} />
											<col style={{ width: '110px' }} />
											<col style={{ width: '200px' }} />
										</colgroup>
										<tbody>
											<tr style={{ height: 40 }}>
												<th style={{ textAlign: 'center' }}>결재순서</th>
												<th style={{ textAlign: 'center' }}>결재자</th>
												<th style={{ textAlign: 'center' }}>결재여부</th>
												<th style={{ textAlign: 'center' }}>결재일자</th>
												<th style={{ textAlign: 'center' }}>결재비고</th>
											</tr>
											{this.state.appList.length > 0 ? (
												this.state.appList.map((item, i) => (
													<tr style={{ textAlign: 'center' }}>
														<td>
															<input type="text" className="txtCenter" value={i + 1} readOnly={true} style={appInfoStyle} />
														</td>
														<td>
															<input type="text" className="txtCenter" value={item.userName} readOnly={true} style={appInfoStyle} />
														</td>
														<td>
															<input type="text" className="txtCenter" value={item.appYn} readOnly={true} style={appInfoStyle} />
														</td>
														<td>
															<input
																type="text"
																className="txtCenter"
																value={item.appDate ? dateToFormat(item.appDate) : ''}
																readOnly={true}
																style={appInfoStyle}
															/>
														</td>
														<td>
															<Tooltip title={item.appDesc} placement="top">
																<input type="text" className="txtCenter" value={item.appDesc} readOnly={true} style={appInfoStyle} />
															</Tooltip>
														</td>
													</tr>
												))
											) : (
												<tr style={{ textAlign: 'center' }}>
													<td colSpan="5">등록된 결재 정보가 없습니다.</td>
												</tr>
											)}
										</tbody>
									</table>
									<div style={{ marginTop: 15, textAlign: 'right' }}>
										<Button
											color="primary"
											variant="contained"
											size="small"
											disabled={this.state.disabledApp}
											style={{ alignItems: 'initial' }}
											onClick={(e) => this.appPop(e, 'Y')}
										>
											<CheckIcon style={{ fontSize: 20, marginRight: 5 }} />
											결재
										</Button>
										<Button
											color="secondary"
											variant="contained"
											size="small"
											style={{ marginLeft: 10, alignItems: 'initial' }}
											disabled={this.state.disabledApp}
											onClick={(e) => this.appPop(e, 'X')}
										>
											<CancelIcon style={{ fontSize: 20, marginRight: 5 }} />
											반려
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Menu id="simple-menu" anchorEl={this.state.anchorEl} open={Boolean(this.state.anchorEl)} onClose={this.appPopClose}>
					<div className="writeType03" style={{ padding: 10 }}>
						<TextField
							inputRef={(el) => (this.fv = el)}
							label="결재비고"
							style={{ margin: 8, width: 300 }}
							placeholder="결재자 의견을 입력해 주세요(생략가능)"
							fullWidth
							multiline
							rows="2"
							margin="normal"
							variant="outlined"
							InputLabelProps={{ shrink: true }}
							disabled={this.state.disabledApp}
							value={this.state.appDesc}
							onChange={(e) => this.setState({ appDesc: e.target.value })}
						/>
					</div>
					<div name="buttonDiv" ref={(ref) => (this.buttonDiv = ref)}>
						<Button
							color="secondary"
							variant="contained"
							size="small"
							style={{ margin: '-20px 17px 15px 0px', alignItems: 'initial', float: 'right' }}
							onClick={this.handleApp}
						>
							<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
							저장
						</Button>
					</div>
				</Menu>
			</div>
		);
	}
}
export default observer(RentalOrderAppr);
