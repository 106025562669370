import {httpGet,httpPost,httpPut,toQueryString} from './Service'

//월별 발주수량 조회
export function getMonthOrderCnt(custNo){
    return httpGet('/api/v1.0/cis/order/month-order-cnt/'+custNo);
}

//월별 총렌탈수량 조회
export function getMonthTotRentAssetCnt(custNo){
    return httpGet('/api/v1.0/cis/asset/month-tot-rent-cnt/'+custNo);
}

//당월 만기자산 조회
export function getMonthEndAssetSearch(custNo){
    return httpGet('/api/v1.0/cis/asset/month-end-search/'+custNo);
}

//공지사항 조회 
export function getNoticeBoardList(data){
    data = data || {};
    return httpGet('/api/v1.0/cis/board/notice'+toQueryString(data))
}

//Q&A 조회 
export function getQnaBoardList(data){
    data = data || {};
    return httpGet('/api/v1.0/cis/board/qna'+toQueryString(data))
}

//게시판(공지사항, Q&A) 상세 조회
export function getBoardDetail(boardId){
    return httpGet('/api/v1.0/cis/board/'+boardId)
}

//게시판(공지사항, Q&A) 등록
export function insertBoard(data){
    data = data || {};
    return httpPost('/api/v1.0/cis/board',{body:data});
}

//게시판(공지사항, Q&A) 수정
export function updateBoard(data){
    data = data || {};
    return httpPut('/api/v1.0/cis/board/'+data.boardId,{body:data});
}

//댓글 리스트
export function getBoardReplyList(boardId){
    return httpGet('/api/v1.0/cis/board/reply/'+boardId);
}

//게시판(공지사항, Q&A) 댓글 수정
export function updateBoardReply(data){
    data = data || {};
    return httpPut('/api/v1.0/cis/board/'+data.boardId+'/reply',{body:data});
}

//게시판(공지사항, Q&A) 댓글 등록
export function insertBoardReply(data){
    data = data || {};
    return httpPost('/api/v1.0/cis/board/'+data.boardId+'/reply',{body:data});
}

//배너 리스트
export function getBannerList(data){
    if(data){
        return httpGet('/api/v1.0/cis/banner'+toQueryString(data));
    }else{
        return httpGet('/api/v1.0/cis/banner');
    }
    
}

//배너 등록
export function saveBanner(data){
    return httpPost('/api/v1.0/cis/banner/save', {body:data});
}

//메인 카테고리 리스트
export function getMainCategoryList(data){
    if(data){
        return httpGet('/api/v1.0/cis/mainCategory'+toQueryString(data));
    }else{
        return httpGet('/api/v1.0/cis/mainCategory');
    }
    
}

//메인 카테고리 등록
export function saveMainCategory(data){
    return httpPost('/api/v1.0/cis/mainCategory/save', {body:data});
}

// 메인 컨텐츠 매핑 리스트
export function getMainContCustList(data = {}){
    return httpGet('/api/v1.0/cis/main-contents/custs'+toQueryString(data));
}

// 메인 컨텐츠 조회
export function getCustMainContList(data){
    return httpGet('/api/v1.0/cis/custs/main-contents'+toQueryString(data));
}

// 메인 컨텐츠 매핑 등록
export function saveMainContCustList(data){
    return httpPost('/api/v1.0/cis/main-contents/custs', {body:data});
}



