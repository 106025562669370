import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Input';
import Button from '@material-ui/core/Button';

import { geOrdeInterfaceList, getCustInterfaceCustInfo, getReqInterfaceItemList, sendCustInterfaceData } from '../../service/admin/CustIFManagerService';
import { dateToFormat, errorMsgCheck, resultToIbSheet, InsertComma } from '../../common/ComFunction';
import { CUST_NO_AJ } from '../../common/ComValues';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore } = CommonStore;

class CustIFManager extends Component {
	constructor(props) {
		super(props);
		this.state = {
			/* 검색조건 */
			sCustNo: sessionStorage.custNo !== CUST_NO_AJ ? sessionStorage.custNo : '',
			sCustNm: sessionStorage.custNo !== CUST_NO_AJ ? sessionStorage.custNm : '',
			sOrdeNo: '',

			ifKey: '',
			ifData: '',
			/* 고객 요청 정보 */
			reqNo: '',
			reqOrdNo: '',
			reqSeq: '',
			reqCd: '',
			custNm: '',
			reqCustNm: '',
			reqDeptNm: '',
			reqUserNm: '',
			/* 인터페이스 재전송 */
			selSendIfType: '',

			custSearchType: '1',
		};

		this.sheetData = {
			id: 'custIfListSheet', // sheet id
			initData: 'admin/Init-CustIFList', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '280px', // sheet height
		};

		this.sheetData2 = {
			id: 'custIfItemListSheet', // sheet id
			initData: 'admin/Init-CustIFItemList', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '200px', // sheet height
		};
	}

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
	};

	geOrdeInterfaceList = () => {
		ProgressStore.activeProgress();

		let param = {
			ordeNo: this.state.sOrdeNo,
		};

		Promise.all([geOrdeInterfaceList(param), getCustInterfaceCustInfo(param), getReqInterfaceItemList(param)])
			.then((r) => {
				let [ifList, ifInfo, ifItemList] = r;

				window[this.sheetData.id].LoadSearchData(resultToIbSheet(ifList));
				window[this.sheetData2.id].LoadSearchData(resultToIbSheet(ifItemList));
				if (ifInfo[0] !== null) {
					this.setState({
						reqNo: ifInfo[0].reqNo,
						reqOrdNo: ifInfo[0].reqOrdNo,
						reqCd: ifInfo[0].reqCd,
						custNm: ifInfo[0].custNm,
						reqCustNm: ifInfo[0].reqCustNm,
						reqDeptNm: ifInfo[0].reqDeptNm,
						reqNm: ifInfo[0].reqUserNm,
					});
				}

				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	getCustIfDetail = () => {
		if (window[this.sheetData.id].LastRow() === 0) return;

		let data = window.getSelectIfData();
		if (data.ifKey === undefined) return;

		this.setState({
			ifData: data.ifData,
		});
	};

	checkSendIf = () => {
		if (this.state.selSendIfType === '') {
			ModalStore.openModal('전송상태를 선택해 주세요.');
			return;
		}

		ConfirmModalStore.openModal('전송 하시겠습니까?', this.sendIfData);
	};

	sendIfData = () => {
		let param = {
			reqNo: this.state.reqNo,
			stateCd: this.state.selSendIfType,
		};

		ProgressStore.activeProgress();
		sendCustInterfaceData(param)
			.then((r) => {
				ModalStore.openModal('전송 되었습니다.');
				//재조회
				this.geOrdeInterfaceList();
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	handleSearchBtn = () => {
		if (this.state.sOrdeNo === '') {
			ModalStore.openModal('고객 발주 번호를 반드시 입력해 주세요.');
			return;
		}

		this.geOrdeInterfaceList();
	};

	customerSearch = () => {
		CustSearchPopupStore.openPopup('1', null, null, this.handleCustClose);
	};

	handleCustClose = (item) => {
		if (item === undefined) return;

		this.setState({
			sCustNo: item.custNo,
			sCustNm: item.custNm,
		});
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mb30">
								<h2>인터페이스 이력 관리</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.handleSearchBtn}>
										<span>조회</span>
									</a>
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '100px' }} />
										<col />
									</colgroup>
									<tbody>
										<tr>
											{/*                                                                                        
                                            <th>고객명</th>
                                            <td>
                                                <input type="text" readOnly={true} 
                                                    style={{marginRight: 10, width:220}} value={this.state.sCustNm}
                                                />
                                                <IconButton onClick={this.customerSearch} color="secondary" className="iconButton" style={{display: sessionStorage.btnControl}}>
                                                    <SearchIcon fontSize="small"/>
                                                </IconButton >
                                            </td>
*/}
											<th>고객발주번호</th>
											<td>
												<input
													type="text"
													style={{ width: 150, marginRight: 10 }}
													value={this.state.sOrdeNo}
													onChange={(e) => this.setState({ sOrdeNo: e.target.value })}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="writeType03" style={{ float: 'left', width: '40%' }} onClick={this.getCustIfDetail}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">인터페이스 리스트</p>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
							<div style={{ float: 'right', width: '55%', height: '250px' }}>
								<p className="headline">고객 발주 정보</p>
								<div className="writeType03_noborder">
									<table>
										<colgroup>
											<col style={{ width: 90 }} />
											<col style={{ width: 120 }} />
											<col style={{ width: 100 }} />
											<col style={{ width: 180 }} />
										</colgroup>
										<tbody>
											<tr>
												<th>고객사명</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.custNm} />
												</td>
											</tr>
											<tr>
												<th>고객요청번호</th>
												<td>
													<input type="text" readOnly={true} style={{ width: 80 }} value={this.state.reqOrdNo} />
												</td>
												<th>발주구분</th>
												<td>
													<input type="text" readOnly={true} value={this.state.reqCd} />
												</td>
											</tr>
											<tr>
												<th>고객요청사항</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.reqDesc} />
												</td>
											</tr>
											<tr>
												<th>신청자회사명</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.reqCustNm} />
												</td>
											</tr>
											<tr>
												<th>신청자명</th>
												<td>
													<input type="text" readOnly={true} value={this.state.reqUserNm} />
												</td>
												<th>신청자부서명</th>
												<td>
													<input type="text" readOnly={true} value={this.state.reqDeptNm} />
												</td>
											</tr>
											<tr>
												<th>재전송</th>
												<td colSpan="3">
													<select value={this.state.selSendIfType} onChange={(e) => this.setState({ selSendIfType: e.target.value })} style={{ width: 150 }}>
														<option value="">선택</option>
														<option value="11">접수</option>
														<option value="12">출고의뢰</option>
														<option value="13">출고완료</option>
														<option value="14">배송할당</option>
														<option value="15">배송완료</option>
													</select>
													<Button className="button" onClick={this.checkSendIf} color="primary" variant="contained" size="small" style={{ marginLeft: 10 }}>
														<SendIcon className="saveIcon" />
														<span className="text">전송</span>
													</Button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div className="writeType03" style={{ marginTop: 340 }}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">고객 발주 제품 리스트</p>
									<IBSheet {...this.sheetData2} />
								</div>
							</div>
							<div className="writeType03">
								<p className="headline">인터페이스 전송 데이터</p>
								<div className="writeType03_noborder">
									<table>
										<colgroup>
											<col />
										</colgroup>
										<tbody>
											<tr>
												<td>
													<textarea value={this.state.ifData} rows="5" style={{ border: 0, height: 130 }} readOnly={true} />
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(CustIFManager);
