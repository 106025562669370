import React, { Component } from 'react';
import { getCustomData, getUserCnt2, insertJoinRequest, sendAuthNum } from '../service/CommonService';
import { errorMsgCheck, comModal, mailCheck, genRandomNum } from '../common/ComFunction';
import Dialog from '@material-ui/core/Dialog';
import { Search } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { Button } from '@material-ui/core';
import Privacy from './include/Privacy';
import { CUST_NO_AJ } from '../common/ComValues';

import { observer } from 'mobx-react';
import CommonStore from '../common/CommonStore';
const { ModalStore, ProgressStore, CustSearchPopupStore, PostPopupStore } = CommonStore;

class RequestJoin extends Component {
	state = {
		//입력항목
		userId: '', //사용자아이디
		userName: '', //사용자명
		custNo: '', //고객번호
		custNm: '', //고객명
		otelNo: '', //대표(사무실)번호
		otelNo1: '', //대표(사무실)번호1
		otelNo2: '', //대표(사무실)번호2
		otelNo3: '', //대표(사무실)번호3
		celNo: '', //휴대폰번호
		celNo1: '', //휴대폰번호1
		celNo2: '', //휴대폰번호2
		celNo3: '', //휴대폰번호3
		emailAddr: '', //메일주소
		passWord: '', //비밀번호
		userTitle: '', //사용자직급
		addrSeq: '', //지점시퀀스
		spotNm: '', //지점명
		spotId: '', //지점코드
		postAddr: '', //지점주소1
		postSddr: '', //지점주소2
		postCode: '', //우편번호
		deptName: '', //부서명
		userChg: '', //지원담당자
		empno: '', //사번
		checkPrivacy: false,

		//validation
		passWordChk: '', //비밀번호(확인)
		userIdChk: false, //사용자아이디중복체크
		authNum: '', //인증번호
		authNumInst: '', //인증번호입력
		ajValid: false, //aj전용 필수값 체크용

		spotTitle: '지점', //지점호칭구분
	};

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
		});
	};

	handleChangeNum = (e) => {
		// 숫자만 입력받도록
		const value = e.target.value.replace(/\D/, '');
		this.setState({ [e.target.name]: value });
	};

	resetState = () => {
		//state초기화
		this.setState({
			//입력항목
			userId: '', //사용자아이디
			userName: '', //사용자명
			custNo: '', //고객번호
			custNm: '', //고객명
			otelNo: '', //대표(사무실)번호
			otelNo1: '', //대표(사무실)번호1
			otelNo2: '', //대표(사무실)번호2
			otelNo3: '', //대표(사무실)번호3
			celNo: '', //휴대폰번호
			celNo1: '', //휴대폰번호1
			celNo2: '', //휴대폰번호2
			celNo3: '', //휴대폰번호3
			emailAddr: '', //메일주소
			passWord: '', //비밀번호
			userTitle: '', //사용자직급
			addrSeq: '', //지점시퀀스
			spotNm: '', //지점명
			spotId: '', //지점코드
			postAddr: '', //지점주소1
			postSddr: '', //지점주소2
			postCode: '', //우편번호
			deptName: '', //부서명
			userChg: '', //지원담당자
			empno: '', //사번

			//validation
			passWordChk: '', //비밀번호(확인)
			userIdChk: false, //사용자아이디중복체크
			authNum: '', //인증번호
			authNumInst: '', //인증번호입력
			ajValid: false, //aj전용 필수값 체크용

			spotTitle: '지점', //지점호칭구분
		});
	};

	//사용자아이디 중복검사
	chkUserId = () => {
		if (this.state.userId === '') {
			ModalStore.openModal('사용자 아이디를 먼저 입력하세요.');
			this.userId.focus();
			return;
		}

		ProgressStore.activeProgress();
		let param = {
			userId: this.state.userId, //사용자아이디
		};
		getUserCnt2(param)
			.then((r) => {
				let cnt = r[0].cnt;
				// alert(r[0].cnt);
				let msg = '';
				if (cnt > 0) {
					msg = '이미 존재하는 아이디입니다.';
					this.setState({
						userIdChk: false,
					});
				} else {
					msg = '사용 가능한 아이디입니다.';
					this.setState({
						userIdChk: true,
					});
				}
				ModalStore.openModal(msg);
				// comModal(true, msg);
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, this.props);
			});
	};

	//회원가입승인 요청
	requestJoin = () => {
		const {
			userId,
			userName,
			custNo,
			custNm,
			otelNo,
			otelNo1,
			otelNo2,
			otelNo3,
			celNo,
			celNo1,
			celNo2,
			celNo3,
			emailAddr,
			passWord,
			userTitle,
			addrSeq,
			spotNm,
			spotId,
			postAddr,
			postSddr,
			passWordChk,
			userIdChk,
			postCode,
			deptName,
			userChg,
			empno,
			ajValid,
			checkPrivacy,
		} = this.state;

		//validation chk
		if (!this.validationChk('JOIN')) return;
		// validation chk end
		ProgressStore.activeProgress();
		this.setState({
			otelNo: otelNo1 + otelNo2 + otelNo3,
			celNo: celNo1 + celNo2 + celNo3,
		});

		let param = {
			userId: userId, //사용자아이디
			userName: userName, //사용자명
			custNo: custNo, //고객번호
			custNm: custNm, //고객명
			otelNo: otelNo1 + otelNo2 + otelNo3, //대표(사무실)번호
			celNo: celNo1 + celNo2 + celNo3, //휴대폰번호
			emailAddr: emailAddr, //메일주소
			passWord: passWord, //비밀번호
			userTitle: userTitle, //사용자직급
			addrSeq: addrSeq, //지점시퀀스
			spotNm: spotNm, //지점명
			spotId: spotId, //지점코드
			postAddr: postAddr, //지점주소1
			postSddr: postSddr, //지점주소2
			postCode: postCode, //지점우편번호
			deptName: deptName, //부서명
			userChg: userChg, //지원담당자
			empno: empno, //사번
			ajValid: ajValid ? 'true' : 'false', //AJ여부
			systCode: 'CIS',
			privacyYn: checkPrivacy ? 'Y' : 'N',
		};

		insertJoinRequest(param)
			.then((r) => {
				// console.log(r);console.log(r.length);console.log(r[0]);
				if (r.length > 0) {
					ModalStore.openModal('가입신청이 완료되었습니다. 승인 이후 로그인 가능합니다.');
					this.props.handleClose();
					this.resetState();
				}
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, this.props);
			});
	};

	validationChk = (type) => {
		const {
			userId,
			userName,
			custNo,
			otelNo1,
			otelNo2,
			otelNo3,
			celNo1,
			celNo2,
			celNo3,
			emailAddr,
			passWord,
			authNum,
			authNumInst,
			spotId,
			spotNm,
			postAddr,
			postSddr,
			passWordChk,
			userIdChk,
			postCode,
			spotTitle,
			ajValid,
			empno,
			checkPrivacy,
		} = this.state;

		//validation chk
		if (userId === '') {
			ModalStore.openModal('아이디를 입력하세요.');
			this.userId.focus();
			return false;
		}
		if (passWord === '') {
			ModalStore.openModal('비밀번호를 입력하세요.');
			this.passWord.focus();
			return false;
		}
		if (passWordChk === '') {
			ModalStore.openModal('비밀번호 확인을 입력하세요.');
			this.passWordChk.focus();
			return false;
		}
		if (userName === '') {
			ModalStore.openModal('이름을 입력하세요.');
			this.userName.focus();
			return false;
		}
		if (custNo === '') {
			ModalStore.openModal('회사명을 검색하세요.');
			this.custNm.focus();
			return false;
		}
		/*
		if (spotNm === '') {
			ModalStore.openModal(spotTitle + '명을 검색 또는 입력하세요.');
			this.spotNm.focus();
			return false;
		}
		if (postAddr === '') {
			ModalStore.openModal(spotTitle + '주소를 입력하세요.');
			this.postAddr.focus();
			return false;
		}
		if (postSddr === '') {
			ModalStore.openModal(spotTitle + '상세주소를 입력하세요.');
			this.postSddr.focus();
			return false;
		}		
		if (postCode === '') {
			ModalStore.openModal(spotTitle + '우편번호를 입력하세요.');
			this.postCode.focus();
			return false;
		}
		*/
		if (emailAddr === '') {
			ModalStore.openModal('이메일주소를 입력하세요.');
			this.emailAddr.focus();
			return false;
		}
		/*
		if (otelNo1 === '') {
			ModalStore.openModal('대표전화를 입력하세요.');
			this.otelNo1.focus();
			return false;
		}
		if (otelNo2 === '') {
			ModalStore.openModal('대표전화를 입력하세요.');
			this.otelNo2.focus();
			return false;
		}
		if (otelNo3 === '') {
			ModalStore.openModal('대표전화를 입력하세요.');
			this.otelNo3.focus();
			return false;
		}
		*/
		if (celNo1 === '') {
			ModalStore.openModal('휴대전화를 입력하세요.');
			this.celNo1.focus();
			return false;
		}
		if (celNo2 === '') {
			ModalStore.openModal('휴대전화를 입력하세요.');
			this.celNo2.focus();
			return false;
		}
		if (celNo3 === '') {
			ModalStore.openModal('휴대전화를 입력하세요.');
			this.celNo3.focus();
			return false;
		}
		if (ajValid && empno === '') {
			ModalStore.openModal('사번을 입력하세요.');
			this.empno.focus();
			return false;
		}

		if (passWord.length < 4) {
			ModalStore.openModal('비밀번호는 최소 4자 이상입니다.');
			this.passWord.focus();
			return false;
		}
		if (passWord !== passWordChk) {
			ModalStore.openModal('비밀번호를 확인하세요.');
			this.passWord.focus();
			return false;
		}

		if (!userIdChk) {
			ModalStore.openModal('아이디 중복검사는 필수입니다.');
			return false;
		}

		if (!mailCheck(emailAddr)) {
			ModalStore.openModal('메일주소 형식이 올바르지 않습니다.');
			this.emailAddr.focus();
			return false;
		}

		if (type === 'JOIN') {
			// 가입시에는 인증번호 validation
			if (authNum === '') {
				ModalStore.openModal('본인인증 이후 가입요청이 가능합니다.');
				this.authNumInst.focus();
				return false;
			}
			if (authNumInst === '') {
				ModalStore.openModal('인증번호를 입력하세요.');
				this.authNumInst.focus();
				return false;
			}
			if (authNum !== authNumInst) {
				ModalStore.openModal('인증번호가 일치하지 않습니다.');
				this.authNumInst.focus();
				return false;
			}
		}

		if (!checkPrivacy) {
			ModalStore.openModal('개인정보 처리 방침에 동의 하셔야 승인요청을 진행하실 수 있습니다.');
			return false;
		}

		return true;
		// validation chk end
	};

	handleClose = (item) => {
		if (item !== undefined) {
			if (CustSearchPopupStore.popupType === '1') {
				// 고객사 검색 결과
				if (item.custNo !== undefined) {
					this.setState({
						custNo: item.custNo,
						custNm: item.custNm,
						userChg: item.userChg,
						spotTitle: item.spotTitle === '0' ? '지점' : item.spotTitle === '1' ? '대리점' : '현장',
					});
					this.setState({
						// 고객사 검색시 지점관련 state reset
						addrSeq: '', //지점시퀀스
						spotNm: '', //지점명
						spotId: '', //지점코드
						postAddr: '', //지점주소1
						postSddr: '', //지점주소2
						postCode: '', //지점우편번호
					});

					getCustomData({ custNo: item.custNo, customCode: 'CT012' }).then((r) => {
						if (r.length > 0) {
							this.setState({
								ajValid: true, //aj네트웍스 전용 필수값 체크시 사용
							});
						} else {
							this.setState({
								ajValid: false, //aj네트웍스 전용 필수값 체크시 사용
							});
						}
					});
				}
			} else if (CustSearchPopupStore.popupType === '2') {
				// 지점 검색 결과
				if (item.addrSeq !== undefined) {
					this.setState({
						addrSeq: item.addrSeq, //지점시퀀스
						spotNm: item.spotNm, //지점명
						spotId: item.spotId, //지점코드
						postAddr: item.postAddr, //지점주소1
						postSddr: item.postSddr, //지점주소2
						postCode: item.postCode, //지점우편번호
					});
				}
			}
		}
	};

	// 팝업
	handleClickOpen = (popupType) => {
		if (popupType === '1') {
			//고객사 팝업
			CustSearchPopupStore.openPopup(popupType, null, null, this.handleClose);
		} else if (popupType === '2') {
			//지점 팝업
			//지점은 고객사 팝업 검색 후 가능
			if (this.state.custNo === '') {
				ModalStore.openModal('지점검색은 회사명 검색 이후 가능합니다.');
				return;
			}
			CustSearchPopupStore.openPopup(popupType, null, this.state.custNo, this.handleClose);
		}
	};

	// 다음 주소검색
	handleAddress = (data) => {
		let fullAddress = data.address;
		let postCode = data.postCode;

		this.setState(
			{
				postAddr: fullAddress === undefined ? this.state.postAddr : fullAddress,
				postSddr: fullAddress === undefined ? this.state.postSddr : '',
				postCode: postCode === undefined ? this.state.postCode : postCode,
				addrSeq: fullAddress === undefined ? this.state.addrSeq : '',
			},
			() => {
				this.postSddr.focus();
			},
		);
	};

	//인증번호요청
	requestAuthNum = () => {
		// 1. 6자리 랜덤넘버 생성 후 state에 저장
		// 2. 생성된 인증번호 SMS 전송
		// 3. 전송 완료 후 입력시간 카운트(3분)
		// 4. 3분이전 또는 이후에 다시 버튼 누르면 1~3 반복
		const { celNo1, celNo2, celNo3, authNum, userName, ajValid, empno } = this.state;

		//validation chk
		if (!this.validationChk()) return;
		// validation chk end
		let ranNum = genRandomNum(6);
		console.log(ranNum);
		this.setState(
			{
				authNum: ranNum,
			},
			() => {
				let param = {
					authNum: ranNum,
					userName: userName, //사용자명
					celNo: celNo1 + celNo2 + celNo3, //휴대폰번호
					ajValid: ajValid ? 'true' : 'false', //AJ네트웍스 여부. AJ네트웍스는 사번으로 조회하여 EMAIL 전송한다.
					empno: empno, // AJ네트웍스 시 사용할 사번
				};
				sendAuthNum(param)
					.then((r) => {
						let msg = '';
						if (r[0].result === 'Y') {
							if (ajValid) msg = '사번에 해당하는 이메일 주소로 인증번호를 발송했습니다.';
							else msg = '입력한 휴대전화번호로 인증번호를 발송했습니다.';
						} else {
							msg = '인증번호 전송에 실패했습니다.';
						}
						ModalStore.openModal(msg);
						ProgressStore.deactiveProgress();
					})
					.catch((err) => {
						errorMsgCheck(err, this.props);
					});
			},
		);
	};

	render() {
		const { open, handleClose } = this.props;
		return (
			<div>
				<Dialog
					open={open}
					onClose={() => {
						handleClose();
						this.resetState();
					}}
				>
					<div className="popArea join">
						<div className="bg"></div>
						<div className="popCont">
							<div className="h2Wrap">
								<h2>
									회원 승인요청 정보 입력 <span>*는 필수항목입니다.</span>
								</h2>
							</div>

							<div className="writeType02 type2">
								<table>
									<colgroup>
										<col style={{ width: '160px' }} />
										<col />
										<col style={{ width: '160px' }} />
										<col style={{ width: '315px' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>
												아이디 <span className="star">*</span>
											</th>
											<td colSpan="3">
												<input
													type="text"
													name="userId"
													maxLength="50"
													value={this.state.userId}
													onChange={(e) => {
														this.handleChange(e);
														this.setState({ userIdChk: false });
													}}
													ref={(ref) => (this.userId = ref)}
												/>
												{/* <a className="btn01 blue" onClick={this.chkUserId}><span>중복검사</span></a> */}
												<Button
													variant="outlined"
													size="small"
													color="primary"
													className="btnInTable"
													onClick={this.chkUserId}
													style={this.state.userIdRead ? { visibility: 'hidden' } : { visibility: 'visible' }}
												>
													중복검사
												</Button>
											</td>
										</tr>
										<tr>
											<th>
												비밀번호 <span className="star">*</span>
											</th>
											<td>
												<input
													type="password"
													name="passWord"
													maxLength="20"
													value={this.state.passWord}
													onChange={this.handleChange}
													ref={(ref) => (this.passWord = ref)}
												/>
											</td>
											<th>
												비밀번호 확인 <span className="star">*</span>
											</th>
											<td>
												<input
													type="password"
													name="passWordChk"
													maxLength="20"
													value={this.state.passWordChk}
													onChange={this.handleChange}
													ref={(ref) => (this.passWordChk = ref)}
												/>
											</td>
										</tr>
										<tr>
											<th>
												이름 <span className="star">*</span>
											</th>
											<td>
												<input
													type="text"
													name="userName"
													maxLength="25"
													value={this.state.userName}
													onChange={this.handleChange}
													ref={(ref) => (this.userName = ref)}
												/>{' '}
											</td>
											<th>사번 {this.state.ajValid ? <span className="star">*</span> : ''}</th>
											<td>
												<input
													type="text"
													name="empno"
													maxLength="20"
													value={this.state.empno}
													onChange={this.handleChangeNum}
													ref={(ref) => (this.empno = ref)}
												/>{' '}
											</td>
										</tr>
										<tr>
											<th>
												회사명 <span className="star">*</span>
											</th>
											<td colSpan="3">
												<input
													type="text"
													name="custNm"
													maxLength="30"
													value={this.state.custNm}
													onChange={this.handleChange}
													readOnly={true}
													ref={(ref) => (this.custNm = ref)}
												/>
												{/* <a className="btn01 search" onClick={() => this.handleClickOpen('1')}><span>검색</span></a> */}
												<IconButton aria-label="Search" className="iconButton" onClick={() => this.handleClickOpen('1')}>
													<Search fontSize="small" />
												</IconButton>
											</td>
										</tr>
										<tr>
											<th>{this.state.spotTitle}명</th>
											<td colSpan="3">
												<input
													type="text"
													name="spotNm"
													value={this.state.spotNm}
													onChange={(e) => {
														this.handleChange(e);
														this.setState({ addrSeq: '' });
													}}
													ref={(ref) => (this.spotNm = ref)}
												/>
												{/* <a className="btn01 search" onClick={() => this.handleClickOpen('2')}><span>검색</span></a> */}
												<IconButton aria-label="Search" className="iconButton" onClick={() => this.handleClickOpen('2')}>
													<Search fontSize="small" />
												</IconButton>
												<span className="desc">
													* 원하는 {this.state.spotTitle}이 없다면 직접 {this.state.spotTitle}을 입력할 수 있습니다.
												</span>
											</td>
										</tr>
										<tr>
											<th>{this.state.spotTitle}코드 </th>
											<td>
												<input
													type="text"
													name="spotId"
													maxLength="15"
													value={this.state.spotId}
													onChange={(e) => {
														this.handleChange(e);
														this.setState({ addrSeq: '' });
													}}
													ref={(ref) => (this.spotId = ref)}
												/>
											</td>
											<th>부서(대리점)명 </th>
											<td>
												<input
													type="text"
													name="deptName"
													maxLength="25"
													value={this.state.deptName}
													onChange={this.handleChange}
													ref={(ref) => (this.deptName = ref)}
												/>
											</td>
										</tr>
										<tr>
											<th>{this.state.spotTitle}주소</th>
											<td colSpan="3">
												<input
													type="text"
													name="postCode"
													className="size05"
													maxLength="6"
													value={this.state.postCode}
													onChange={(e) => {
														this.handleChangeNum(e);
														this.setState({ addrSeq: '' });
													}}
													ref={(ref) => (this.postCode = ref)}
												/>
												<input
													type="text"
													name="postAddr"
													className="size04"
													maxLength="50"
													value={this.state.postAddr}
													onChange={(e) => {
														this.handleChange(e);
														this.setState({ addrSeq: '' });
													}}
													ref={(ref) => (this.postAddr = ref)}
												/>
												{/* <a className="btn01 black" onClick={() => this.setState({openPost:true})}><span>주소검색</span></a> */}
												<Button variant="outlined" size="small" className="btnInTable mr" onClick={() => PostPopupStore.openPopup(this.handleAddress)}>
													주소검색
												</Button>
												<input
													type="text"
													name="postSddr"
													className="size04"
													maxLength="50"
													value={this.state.postSddr}
													onChange={(e) => {
														this.handleChange(e);
														this.setState({ addrSeq: '' });
													}}
													ref={(ref) => (this.postSddr = ref)}
												/>
											</td>
										</tr>
										<tr>
											<th>
												이메일 <span className="star">*</span>
											</th>
											<td>
												<input
													type="text"
													name="emailAddr"
													maxLength="100"
													value={this.state.emailAddr}
													onChange={this.handleChange}
													ref={(ref) => (this.emailAddr = ref)}
												/>
											</td>
											<th>직급 </th>
											<td>
												<input
													type="text"
													name="userTitle"
													maxLength="10"
													value={this.state.userTitle}
													onChange={this.handleChange}
													ref={(ref) => (this.userTitle = ref)}
												/>
											</td>
										</tr>
										<tr>
											<th>대표전화</th>
											<td colSpan="3">
												<input
													type="text"
													name="otelNo1"
													className="size03"
													maxLength="3"
													value={this.state.otelNo1}
													onChange={this.handleChangeNum}
													ref={(ref) => (this.otelNo1 = ref)}
												/>
												<span className="dash">-</span>
												<input
													type="text"
													name="otelNo2"
													className="size03"
													maxLength="4"
													value={this.state.otelNo2}
													onChange={this.handleChangeNum}
													ref={(ref) => (this.otelNo2 = ref)}
												/>
												<span className="dash">-</span>
												<input
													type="text"
													name="otelNo3"
													className="size03"
													maxLength="4"
													value={this.state.otelNo3}
													onChange={this.handleChangeNum}
													ref={(ref) => (this.otelNo3 = ref)}
												/>
											</td>
										</tr>
										<tr>
											<th>
												휴대전화 <span className="star">*</span>
											</th>
											<td colSpan="3">
												<input
													type="text"
													name="celNo1"
													className="size03"
													maxLength="3"
													value={this.state.celNo1}
													onChange={this.handleChangeNum}
													ref={(ref) => (this.celNo1 = ref)}
												/>
												<span className="dash">-</span>
												<input
													type="text"
													name="celNo2"
													className="size03"
													maxLength="4"
													value={this.state.celNo2}
													onChange={this.handleChangeNum}
													ref={(ref) => (this.celNo2 = ref)}
												/>
												<span className="dash">-</span>
												<input
													type="text"
													name="celNo3"
													className="size03"
													maxLength="4"
													value={this.state.celNo3}
													onChange={this.handleChangeNum}
													ref={(ref) => (this.celNo3 = ref)}
												/>
											</td>
										</tr>
										<tr>
											<th>
												본인인증<span className="star">*</span>
											</th>
											<td colSpan="3">
												<input
													type="text"
													name="authNumInst"
													placeholder="인증번호를 입력해 주세요"
													maxLength="6"
													value={this.state.authNumInst}
													onChange={this.handleChangeNum}
													ref={(ref) => (this.authNumInst = ref)}
												/>
												{/* <a className="btn01 blue"><span>인증요청</span></a> */}
												<Button variant="outlined" size="small" color="primary" className="btnInTable" onClick={this.requestAuthNum}>
													인증번호요청
												</Button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							{/* <div className="desc">
                                <span>* 지점 검색 결과에 원하는 지점이 없다면 직접 지점을 입력할 수 있고, 고객사에서 관리하는 지점의 아이디가 존재하면 지점코드에 입력 가능합니다.</span>
                            </div> */}
							<div style={{ marginBottom: 20 }}>
								<div className="h2Wrap">
									<h2>개인정보 수집 및 이용약관 동의(필수)</h2>
								</div>
								<Privacy from="join" />
								<input type="checkbox" value={this.state.checkPrivacy} onChange={() => this.setState({ checkPrivacy: !this.state.checkPrivacy })} />
								<text>개인정보 처리 방침에 동의 합니다.</text>
							</div>
							<div className="btnCenter">
								<a className="btn02 blue" onClick={this.requestJoin}>
									승인요청
								</a>
								<a
									onClick={() => {
										handleClose();
										this.resetState();
									}}
									className="btn02 white2"
								>
									취소
								</a>
							</div>

							<div className="btnClose">
								<a
									href="javascript:;"
									onClick={() => {
										handleClose();
										this.resetState();
									}}
								>
									팝업닫기
								</a>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}
}

export default observer(RequestJoin);
