import React, { Component } from 'react';
import { makeObservable, observable, action, toJS } from 'mobx';
import { observer } from 'mobx-react';

const ApiOrdeItemAllocPopupStore = observable({
	open: false,
	custNo: '',
	handleClose: null,
	ordeList: [], // 체크된 요청 리스트
	custItemList: [], // 조회된 고객사 제품리스트
	itemRowList: [], // 요청에 딸린 제품리스트. 동일한 요청제품 ROW 수를 가진 요청들을 선택하여 처리하므로 itemRowList의 길이는 요청제품 Row 수가 된다.
	searched: true,
	selectedIdx: -1, // 선택한 순번

	openPopup(custNo, ordeList, handleClose) {
		this.open = true;
		if (custNo) this.custNo = custNo;
		if (ordeList) {
			this.ordeList = ordeList.data;
			let itemRowCnt = ordeList.data[0].itemRowCnt;
			itemRowCnt = isNaN(itemRowCnt) ? 0 : Number(itemRowCnt);
			if (itemRowCnt > 0) {
				this.itemRowList = new Array(0); // 요청제품 row수대로 할당
				for (let i = 0; i < itemRowCnt; i++) {
					this.itemRowList.push(
						new Object({
							seq: '', // 요청제품seq
							custNo: '', //고객번호
							model: '', // 모델명
							itemCode: '', // 제품코드
							itemSeq: '', // 제품순번
							stoNo: '', // 입고번호
							stoSeq: '', // 입고순번
							ifrsCode: '', // ifrs 코드
							duration: '', // 내용월수
							lastBalRate: '', // 잔존가치율
							monthPrc: 0, // 월렌탈료
						}),
					);
				}
			}
		}
		if (handleClose) this.handleClose = handleClose;
		this.searched = false;
	},
	closePopup(item) {
		this.open = false;
		if (this.handleClose) {
			this.handleClose(item);
		}
		this.custNo = '';
		this.ordeList = [];
		this.itemRowList = [];
		this.custItemList = [];
		this.selectedIdx = -1; // 선택한 순번
	},
	setOrdeList(list) {
		this.ordeList = list;
	},
	setCustItemList(list) {
		this.custItemList = list;
		this.searched = true;
	},
	setSelectedIdx(idx) {
		this.selectedIdx = idx;
	},
	setSearched(searched) {
		this.searched = searched;
	},
	setItemRowUnit(idx, field, val) {
		if (field === 'stoNo') this.itemRowList[idx].stoNo = val;
		if (field === 'stoSeq') this.itemRowList[idx].stoSeq = val;
		if (field === 'ifrsCode') this.itemRowList[idx].ifrsCode = val;
		if (field === 'monthPrc') this.itemRowList[idx].monthPrc = val;
	},
});

export default ApiOrdeItemAllocPopupStore;
