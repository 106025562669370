import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';

import ChangeIcon from '@material-ui/icons/Autorenew';
import ReturnIcon from '@material-ui/icons/LocalShipping';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import DvrIcon from '@material-ui/icons/Dvr';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import CheckBox from '@material-ui/icons/CheckBox';
import ExcelDown from '../include/ExcelDown';

import { getAssetList, updateAssetInfo, updateAssetListInfo } from '../../service/rental/RentalAssetService';
import { dateToFormat, errorMsgCheck, resultToIbSheet, InsertComma, SheetParamData, SheetParamData3 } from '../../common/ComFunction';
import { getCustomData, getSpotList } from '../../service/CommonService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const {
	ModalStore,
	ConfirmModalStore,
	ProgressStore,
	CustSearchPopupStore,
	UserPopupStore,
	LargeInputNoPopupStore,
	AssetInfoChangePopStore,
	AssetHistoryPopStore,
	AssetReturnPopStore,
	AssetListChangePopStore,
	PreAssetSpecPopStore,
	KYUseageDaysPopStore,
} = CommonStore;

import { CUST_NO_AJ, CUST_NO_KT } from '../../common/ComValues';

class RentalStatusSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			/* 검색조건 */
			// sCustNo: sessionStorage.custNo !== CUST_NO_AJ ? sessionStorage.custNo : '',
			// sCustNm: sessionStorage.custNo !== CUST_NO_AJ ? sessionStorage.custNm : '',
			sCustNo: sessionStorage.custNo,
			sCustNm: sessionStorage.custNm,
			sOrderId: '',
			sOrderNm: '',
			sUserNm: '',
			sAddrSeq: '',
			sSpotId: '',
			sSpotNm: '',
			sAsstStatus: '1',
			sAsstNo: '',
			sModel: '',
			sOrdeNo: '',

			/* 상세정보 */
			custReqNo: '',
			custNo: '',
			asstSeq: '',
			asstNo: '',
			viewAsstNo: '', /* 추가 */
			preAsstNo: '',
			asstStatus: '',
			userEmpno: '',
			showEmpno: '',
			userNm: '',
			userTelno: '',
			userHpno: '',
			userDeptId: '',
			userDeptNm: '',
			realUseSpot: '',
			itemSeq: '',
			itemGrp: '',
			model: '',
			maker: '',
			serialNo: '',
			contDate: '',
			contMonth: '',
			fromDate: '',
			toDate: '',
			monthPrc: '',
			ordeNo: '',
			ordeDate: '',
			contNo: '',
			orderUser: '',
			totAsstCnt: '0',
			totMonthPrc: '0',
			contStatus: '',
			macCable: '',
			macWireless: '',
			cancelFee: 0,
			itemSpec: '',
			returnAskUserNm: '',
			returnAskUser: '',
			returnAskDate: '',
			returnStoDate: '',
			delAskEnddate: '',
			applyDate: '',
			completeDate: '',
			delMethodNm: '',
			ordeStatusNm: '',
			delStatusNm: '',
			ordePrps: '',
			excelList: [], //엑셀다운로드용 리스트
			selectAssetList: [],

			custSearchType: '1',
			custSearchTitle: '',

			disabledBtn: true,
			returnInfo: false, // 반납정보 보여주기
			rightCont: 'rightCont', // rightCont Classname

			chgMode: 'O', // 'O' : 단일 변경 모드, 'L' : 리스트 변경 모드
			etcAsstInfoBtnTxt: '', // 기타 자산정보 조회 버튼 문구. CT038 대응
		};

		this.sheetData = {
			id: 'rentalStatusSheet', // sheet id
			initData: 'rental/Init-RentalStatusSearch', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '618px', // sheet height
		};
		this.sheetData2 = {
			id: 'assetListChangePop', // sheet id
			initData: 'rental/Init-AssetListChangePop', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '618px', // sheet height
		};
	}

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
		window[this.sheetData2.id].DisposeSheet();
	};

	componentDidMount = () => {
		getCustomData({ custNo: sessionStorage.custNo, customCode: 'CT012' }).then((r) => {
			if (r.length > 0) {
				this.setState({
					sCustNo: '',
					sCustNm: '',
				});
			}
		});
	};

	/** 지점조회. 리스트 변경 모드 시 그리드에서 사용 */
	getSpotList = () => {
		ProgressStore.activeProgress();
		let param = {
			custNo: this.state.sCustNo,
		};

		getSpotList(param)
			.then((r) => {
				window.assetListChangePopSheetCombo(r);
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	/** 조회 */
	getAssetList = () => {
		ProgressStore.activeProgress();
		this.setState({ chgMode: 'O' }); // 재조회일 수도 있으므로 자산 단일변경모드로 미리 초기화

		// 대표고객이면서 고객관리자인 경우 현재 대표고객으로 조회시 하위 고객사까지 조회 가능하다
		var repYn = '';
		if (sessionStorage.custNo === sessionStorage.repCustNo && sessionStorage.custNo === this.state.sCustNo && sessionStorage.authCode === '2') {
			repYn = 'Y';
		} else {
			repYn = 'N';
		}

		let customData = JSON.parse(sessionStorage.getItem('customData'));

		let param = {
			custNo: this.state.sCustNo,
			asstStatus: this.state.sAsstStatus,
			addrSeq: this.state.sAddrSeq,
			spotId: this.state.sSpotId,
			rqstId: this.state.sOrderId, //userId -> rqstId로 변경 20201029 kkw
			asstNo: this.state.sAsstNo,
			userNm: this.state.sUserNm,
			ordeNo: this.state.sOrdeNo,
			repYn: repYn, // 대표고객이 하위 고객사 전체 조회 가능함 20190716 kkj
			custSeq: customData != null && customData.hasOwnProperty('CT015') ? sessionStorage.custSeq : null, //kt만 청구지순번을 보낸다.
		};

		getAssetList(param)
			.then((r) => {
				window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));

				let totmonthPrc = 0;
				for (var i in r) {
					totmonthPrc = totmonthPrc + r[i].monthPrc;
				}

				this.setState({
					totAsstCnt: r.length,
					totMonthPrc: InsertComma(Math.floor(totmonthPrc)),
					disabledBtn: true,
				});
				this.makeExcelData(r);
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	/** 상세조회 */
	getAssetDetail = () => {
		if (window[this.sheetData.id].LastRow() === 0) return;

		let data = window.getAssetStatusSelectData();
		if (data.asstNo === undefined) return;

		this.setState({
			custReqNo: data.custReqNo,
			custNo: data.custNo,
			asstSeq: data.asstSeq,
			asstNo: data.asstNo,
			viewAsstNo: data.viewAsstNo,
			preAsstNo: data.preAsstNo,
			asstStatus: data.asstStatus,
			spotId: data.spotId,
			spotNm: data.spotNm,
			userEmpno: data.userEmpno != '' ? '(' + data.userEmpno + ')' : '',
			showEmpno: data.userEmpno,
			userNm: data.userNm,
			userTelno: data.userTelno,
			userHpno: data.userHpno,
			userDeptId: data.userDeptId,
			userDeptNm: data.userDeptNm,
			realUseSpot: data.realUseSpot,
			itemSeq: data.itemSeq,
			itemGrp: data.itemGrp,
			model: data.model,
			maker: data.maker,
			serialNo: data.serialNo,
			contDate: data.contDate,
			contMonth: data.contMonth,
			fromDate: data.fromDate,
			toDate: data.toDate,
			monthPrc: data.monthPrc + '원',
			ordeNo: data.ordeNo,
			ordeDate: data.ordeDate,
			contNo: data.contNo,
			orderUser: data.orderUser,
			contStatus: data.contStatus,
			macCable: data.macCable,
			macWireless: data.macWireless,
			cancelFee: data.cancelFee,
			itemSpec: data.itemSpec,
			returnAskUserNm: data.returnAskUserNm,
			returnAskUser: data.returnAskUser,
			returnAskDate: data.returnAskDate,
			returnStoDate: data.returnStoDate,
			disabledBtn: data.asstStatus === '사용중' ? false : true,
			returnInfo: data.asstStatus === '사용중' ? false : true,
			rightCont: data.asstStatus === '사용중' ? 'rightCont' : 'rightContLong',
			delAskEnddate: data.delAskEnddate,
			applyDate: data.applyDate,
			completeDate: data.completeDate,
			delMethodNm: data.delMethodNm,
			ordeStatusNm: data.ordeStatusNm,
			delStatusNm: data.delStatusNm,
			ordePrps: data.ordePrps,
		});
	};

	/** 조회버튼 제어 */
	handleSearchBtn = () => {
		if (this.state.sCustNo === '') {
			ModalStore.openModal('고객을 반드시 입력해 주세요.');
			return;
		}
		this.setEmptyField(); //초기화
		this.getAssetList();
	};

	/** 엑셀데이터 생성 */
	makeExcelData = (data) => {
		let tmpList = [];

		let tmp = {};
		data.map((item, idx) => {
			tmp = {};
			tmp.No = idx + 1;
			//tmp.자산번호 = item.asstNo;
			tmp.자산번호 = item.viewAsstNo;
			tmp.이전자산변호 = item.preAsstNo;
			tmp.고객명 = item.custNm;
			tmp.계약번호 = item.contNo;
			tmp.자산상태 = item.asstStatus;
			tmp.사번 = item.userEmpno;
			tmp.사용자 = item.userNm;
			tmp.전화번호 = item.userTelno;
			tmp.휴대전화 = item.userHpno;
			if (sessionStorage.deptTitle === '부서') {
				tmp.부서코드 = item.userDeptId;
				tmp.부서명 = item.userDeptNm;
			} else if (sessionStorage.deptTitle === '대리점') {
				tmp.대리점코드 = item.userDeptId;
				tmp.대리점명 = item.userDeptNm;
			}
			tmp.실사용장소 = item.realUseSpot;
			tmp.관리번호 = item.itemSeq;
			tmp.지점코드 = item.spotId;
			tmp.지점명 = item.spotNm;
			tmp.제품분류 = item.itemGrp;
			tmp.제조사 = item.maker;
			tmp.모델명 = item.model;
			tmp.시리얼번호 = item.serialNo;
			tmp.최초시작일 = dateToFormat(item.contDate);
			tmp.렌탈시작일 = dateToFormat(item.fromDate);
			tmp.렌탈종료일 = dateToFormat(item.toDate);
			tmp.렌탈기간 = item.contMonth;
			tmp.월렌탈료 = item.monthPrc;
			tmp.신청일자 = dateToFormat(item.ordeDate);
			tmp.신청자 = item.orderUser;
			tmp.계약상태 = item.contStatus;
			tmp.MAC유선 = item.macCable;
			tmp.MAC무선 = item.macWireless;
			tmp.스펙 = item.itemSpec;
			tmp.반납요청자 = item.returnAskUserNm;
			tmp.반납요청자ID = item.returnAskUser;
			tmp.반납요청일 = dateToFormat(item.returnAskDate);
			tmp.반납입고일 = dateToFormat(item.returnStoDate);
			tmp.출고완료일자 = item.delAskEnddate;
			tmp.신청요청일자 = item.applyDate;
			tmp.결재완료일 = item.completeDate;
			tmp.배송방법 = item.delMethodNm;
			tmp.진행상태 = item.ordeStatusNm;
			tmp.배송상태 = item.delStatusNm;
			tmp.신청목적 = item.ordePrps;

			tmpList.push(tmp);
		});

		//console.log(tmpList);
		this.setState({ excelList: tmpList });
	};

	/** 사용자 및 지점 변경 팝업 호출 */
	changeUser = () => {
		const param = {
			custReqNo: this.state.custReqNo,
			custNo: this.state.custNo,
			asstNo: this.state.asstNo,
			asstSeq: this.state.asstSeq,
			spotId: this.state.spotId,
			spotNm: this.state.spotNm,
			userDeptId: this.state.userDeptId,
			userDeptNm: this.state.userDeptNm,
			userNm: this.state.userNm,
			realUseSpot: this.state.realUseSpot,
			userEmpno: this.state.showEmpno,
			userTelno: this.state.userTelno,
			userHpno: this.state.userHpno,
		};
		AssetInfoChangePopStore.openPopup(param, this.modalClose);
	};

	/** 자산반납 호출 */
	returnAsset = () => {
		let arrData = window.getSelectAssetData();

		if (arrData.length === 0 && this.state.asstSeq === '') {
			ModalStore.openModal('선택된 자산이 없습니다.');
			return;
		}

		let sumCancelFee = 0;

		for (var i in arrData) {
			sumCancelFee += Number(arrData[i].cancelFee);
		}

		this.setState(
			{
				custNo: this.state.sCustNo,
				cancelFee: sumCancelFee,
				selectAssetList: arrData,
			},
			() => {
				const param = {
					asstSeq: this.state.asstSeq,
					asstNo: this.state.asstNo,
					contNo: this.state.contNo,
					custNo: this.state.custNo,
					cancelFee: this.state.cancelFee,
				};
				AssetReturnPopStore.openPopup(param, arrData, this.modalClose);
			},
		);
	};

	/** 자산 리스트/단일 변경 */
	assetListChange = () => {
		if (this.state.chgMode === 'O') {
			this.getSpotList();
			let arrData = window.getSelectAssetData();
			// 단일에서 리스트로 전환시
			if (arrData.length == 0) {
				ModalStore.openModal('선택된 자산이 없습니다.');
				return;
			} else {
				this.setState(
					{
						selectAssetList: arrData,
						chgMode: 'L',
					},
					// () => AssetListChangePopStore.openPopup(this.state.sCustNo, this.state.selectAssetList, this.modalClose),
					() => window[this.sheetData2.id].LoadSearchData(resultToIbSheet(arrData)),
				);
			}
		}
		if (this.state.chgMode === 'L') {
			// 리스트에서 단일로 전환시
			this.setState({
				chgMode: 'O',
			});
		}
	};
	/** 고객사 조회 팝업 */
	customerSearch = () => {
		CustSearchPopupStore.openPopup('1', null, null, this.handleCustClose);
	};
	/** 지점 조회 팝업 */
	spotSearch = () => {
		CustSearchPopupStore.openPopup('2', sessionStorage.spotTitle, this.state.sCustNo, this.handleCustClose);
	};
	/** 고객사/지점 검색 팝업 종료 핸들링 */
	handleCustClose = (item) => {
		if (item === undefined) return;

		//고객검색
		if (CustSearchPopupStore.popupType === '1') {
			this.setState(
				{
					sCustNo: item.custNo,
					sCustNm: item.custNm,
				},
				() => {
					getCustomData({ custNo: this.state.sCustNo, customCode: 'CT038' }).then((r) => {
						if (r.length > 0) {
							console.log(r);
							this.setState({ etcAsstInfoBtnTxt: r[0].customWord });
						} else {
							this.setState({ etcAsstInfoBtnTxt: '' });
						}
					});
				},
			);
			//지점검색
		} else {
			this.setState({
				sAddrSeq: item.addrSeq,
				sSpotId: item.spotId,
				sSpotNm: item.spotNm,
			});
		}
	};
	/** 사용자조회 팝업 */
	userSearch = () => {
		UserPopupStore.openPopup(null, this.state.sCustNo, this.handleUserSearch);
	};
	/** 사용자조회 팝업 종료 핸들링 */
	handleUserSearch = (item) => {
		if (item !== undefined) {
			this.setState({
				sOrderId: item.userId,
				sOrderNm: item.userName,
			});
		}
	};
	/** 이전자산번호 조회 팝업 */
	preAssetPop = () => {
		if (this.state.preAsstNo === '') {
			ModalStore.openModal('이전 자산번호가 없습니다');
			return;
		}
		PreAssetSpecPopStore.openPopup(this.state.preAsstNo);
	};
	/** 자산번호 대량입력 */
	handleTextArray = (text) => {
		if (text === '') return;
		this.setState({
			sAsstNo: text,
		});
	};
	/** 모달 종료시 */
	modalClose = (reSearch) => {
		//재조회
		if (reSearch) {
			this.handleSearchBtn();
		}
	};
	/** 자산이력 팝업 호출 */
	assetHistoryPop = () => {
		if (!this.state.asstNo) {
			ModalStore.openModal('자산을 선택해 주세요.');
			return;
		}
		AssetHistoryPopStore.openPopup(this.state.custNo, this.state.asstNo, this.modalClose);
	};
	/** 필드 초기화 */
	setEmptyField = () => {
		this.setState({
			custNo: '',
			asstSeq: '',
			asstNo: '',
			viewAsstNo: '',
			preAsstNo: '',
			asstStatus: '',
			spotId: '',
			spotNm: '',
			userNm: '',
			userDeptId: '',
			userDeptNm: '',
			userEmpno: '',
			realUseSpot: '',
			itemSeq: '',
			itemGrp: '',
			model: '',
			maker: '',
			serialNo: '',
			contDate: '',
			contMonth: '',
			fromDate: '',
			toDate: '',
			monthPrc: 0,
			ordeNo: '',
			ordeDate: '',
			contNo: '',
			orderUser: '',
			totAsstCnt: '0',
			totMonthPrc: '0',
			contStatus: '',
			cancelFee: 0,
			itemSpec: '',
			returnAskUserNm: '',
			returnAskUser: '',
			returnAskDate: '',
			returnStoDate: '',
			selectAssetList: [],
			returnInfo: false,
			rightCont: 'rightCont',
		});
	};
	/** 지점명 변경 시 */
	onChangeSpotNm = (e) => {
		this.setState({
			sSpotNm: e.target.value,
		});

		if (e.target.value === '') {
			this.setState({
				sAddrSeq: '',
				sSpotId: '',
				sSpotNm: '',
			});
		}
	};
	/** 관리번호 변경 호출 */
	confirmItemSeqChange = () => {
		if (this.state.itemSeq === '') {
			ModalStore.openModal('관리번호가 없습니다.');
			return;
		}
		ConfirmModalStore.openModal('관리번호를 변경 하시겠습니까?', this.itemSeqChange);
	};
	/** 관리번호 변경 */
	itemSeqChange = () => {
		let param = {
			asstSeq: this.state.asstSeq,
			asstNo: this.state.asstNo,
			itemSeq: this.state.itemSeq,
			userId: sessionStorage.userId,
		};

		ProgressStore.activeProgress();
		updateAssetInfo(param)
			.then(() => {
				ModalStore.openModal('변경 되었습니다.');
				//재조회
				this.getAssetList();
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	/** 리스트 변경 시 저장함수 */
	listChangeSave = () => {
		ConfirmModalStore.openModal('변경 하시겠습니까?', () => {
			const sheetParam = SheetParamData3(window[this.sheetData2.id], null, true);
			console.log(sheetParam);

			if (sheetParam) {
				ProgressStore.activeProgress();

				updateAssetListInfo(sheetParam.data)
					.then(() => {
						ModalStore.openModal('저장 되었습니다.', () => {
							this.getAssetList(); // 재조회(여기서 단일변경모드로 전환)
						});
					})
					.catch((err) => {
						errorMsgCheck(err);
					})
					.finally(() => {
						ProgressStore.deactiveProgress();
					});
			}
		});
	};

	renderReturnInfo = () => {
		// 반납정보 display
		if (this.state.returnInfo) {
			return (
				<div>
					<p className="headline">반납정보</p>
					<div className="writeType03_noborder">
						<table>
							<colgroup>
								<col style={{ width: 100 }} />
								<col style={{ width: 170 }} />
								<col style={{ width: 100 }} />
								<col style={{ width: 150 }} />
								<col style={{ width: 90 }} />
								<col style={{ width: 180 }} />
							</colgroup>
							<tbody>
								<tr>
									<th>반납요청자</th>
									<td>
										<input type="text" readOnly={true} value={this.state.returnAskUserNm} />
									</td>
									<th>반납요청일</th>
									<td>
										<input type="text" readOnly={true} value={dateToFormat(this.state.returnAskDate)} />
									</td>
									<th>반납입고일</th>
									<td>
										<input type="text" readOnly={true} value={dateToFormat(this.state.returnStoDate)} />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			);
		} else {
			return null;
		}
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className={this.state.rightCont}>
							<div className="h2Wrap mb30">
								<h2>렌탈자산현황</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.handleSearchBtn}>
										<span>조회</span>
									</a>
									{/* <a className="btn01 save" onClick={this.handleExcelBtn}><span>엑셀</span></a>*/}
									<ExcelDown modalOpen={ModalStore.openModal} data={this.state.excelList} text="엑셀" filename={this.state.sCustNm + '_자산리스트.csv'} />
									<a
										className="btn01 search"
										onClick={() => KYUseageDaysPopStore.openPopup(this.state.sCustNo)}
										style={{ width: 150, display: this.state.etcAsstInfoBtnTxt ? 'inline' : 'none' }}
									>
										<span>{this.state.etcAsstInfoBtnTxt}</span>
									</a>
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '70px' }} />
										<col style={{ width: '230px' }} />
										<col style={{ width: '80px' }} />
										<col style={{ width: '180px' }} />
										<col style={{ width: '80px' }} />
										<col style={{ width: '140px' }} />
										<col style={{ width: '80px' }} />
										<col style={{ width: '100px' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>고객명</th>
											<td>
												<input type="text" readOnly={true} style={{ marginRight: 10, width: 170 }} value={this.state.sCustNm} />
												<IconButton onClick={this.customerSearch} color="secondary" className="iconButton" style={{ display: sessionStorage.btnControl }}>
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
											<th>자산상태</th>
											<td>
												<select style={{ width: 120 }} onChange={(e) => this.setState({ sAsstStatus: e.target.value })}>
													<option value="1" defaultChecked>
														사용
													</option>
													<option value="2">반납중</option>
													<option value="3">반납완료</option>
													<option value="">전체</option>
												</select>
											</td>
											<th>신청자</th>
											<td>
												<input
													type="text"
													style={{ width: 100, display: 'none' }}
													value={this.state.sOrderId}
													onChange={(e) => this.setState({ sOrderId: e.target.value })}
												/>
												<input
													type="text"
													style={{ width: 80, marginRight: 10 }}
													value={this.state.sOrderNm}
													onChange={(e) => this.setState({ sOrderNm: e.target.value })}
												/>
												<IconButton onClick={this.userSearch} color="secondary" className="iconButton">
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
											<th>요청번호</th>
											<td>
												<input type="text" style={{ width: 90 }} value={this.state.sOrdeNo} onChange={(e) => this.setState({ sOrdeNo: e.target.value })} />
											</td>
										</tr>
										<tr>
											<th>{sessionStorage.spotTitle}</th>
											<td>
												<input type="text" readOnly={true} style={{ marginRight: 10, width: 170 }} value={this.state.sSpotNm} />
												<IconButton onClick={this.spotSearch} color="secondary" className="iconButton">
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
											<th>자산번호</th>
											<td>
												<input
													type="text"
													style={{ marginRight: 10, width: 120 }}
													value={this.state.sAsstNo}
													onChange={(e) => this.setState({ sAsstNo: e.target.value })}
												/>
												<IconButton onClick={() => LargeInputNoPopupStore.openPopup(this.handleTextArray)} color="secondary" className="iconButton">
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
											<th>사용자명</th>
											<td colSpan="3">
												<input
													type="text"
													style={{ marginRight: 10, width: 120 }}
													value={this.state.sUserNm}
													onChange={(e) => this.setState({ sUserNm: e.target.value })}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div
								className="writeType03"
								style={{ float: 'left', width: '30%', display: this.state.chgMode === 'O' ? 'inline' : 'none' }}
								onClick={this.getAssetDetail}
							>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">자산리스트</p>
									<IBSheet {...this.sheetData} />
								</div>
								<div style={{ marginTop: 20, textAlign: 'right' }}>
									<Button
										color="primary"
										variant="contained"
										size="small"
										style={{ marginLeft: 10, alignItems: 'initial', backgroundColor: '#0064FF' }}
										onClick={this.assetListChange}
									>
										<CheckBox style={{ fontSize: 20, marginRight: 5 }} />
										자산 리스트 변경 모드
									</Button>
								</div>
							</div>
							<div className="writeType03" style={{ float: 'left', width: '100%', display: this.state.chgMode === 'L' ? 'inline' : 'none' }}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">자산리스트</p>
									<IBSheet {...this.sheetData2} />
								</div>
								<div style={{ marginTop: 20, textAlign: 'right' }}>
									<Button color="primary" variant="contained" size="small" style={{ alignItems: 'initial' }} onClick={this.listChangeSave}>
										<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
										변경저장
									</Button>
									<Button
										color="primary"
										variant="contained"
										size="small"
										style={{ marginLeft: 10, alignItems: 'initial', backgroundColor: '#0064FF' }}
										onClick={this.assetListChange}
									>
										<CheckBox style={{ fontSize: 20, marginRight: 5 }} />
										자산 단일 변경 모드
									</Button>
								</div>
							</div>
							<div style={{ float: 'right', width: '65%', height: '600px', display: this.state.chgMode === 'O' ? 'inline' : 'none' }}>
								<p className="headline">자산정보</p>
								<div className="writeType03_noborder">
									<table>
										<colgroup>
											<col style={{ width: 100 }} />
											<col style={{ width: 170 }} />
											<col style={{ width: 100 }} />
											<col style={{ width: 150 }} />
											<col style={{ width: 90 }} />
											<col style={{ width: 180 }} />
										</colgroup>
										<tbody>
											<tr>
												<th>자산번호</th>
												<td>
													<input type="text" readOnly={true} style={{width: 90}}
														   value={this.state.viewAsstNo}/>
													<IconButton onClick={this.assetHistoryPop} color="secondary"
																className="iconButton">
														<SearchIcon fontSize="small"/>
													</IconButton>
												</td>
												<th>
													이전
													<br/>
													자산번호
												</th>
												<td>
													<input type="text" readOnly={true} style={{ width: 80 }} value={this.state.preAsstNo} />
													<IconButton onClick={this.preAssetPop} color="secondary" className="iconButton">
														<DvrIcon fontSize="small" />
													</IconButton>
												</td>
												<th>자산상태</th>
												<td>
													<input type="text" readOnly={true} value={this.state.asstStatus} />
												</td>
											</tr>
											<tr>
												<th>{sessionStorage.spotTitle}코드</th>
												<td>
													<input type="text" readOnly={true} value={this.state.spotId} />
												</td>
												<th>{sessionStorage.spotTitle}명</th>
												<td colSpan="3">
													<Tooltip title={this.state.spotNm} placement="top-start">
														<input type="text" readOnly={true} value={this.state.spotNm} />
													</Tooltip>
												</td>
											</tr>
											<tr>
												<th>{sessionStorage.deptTitle}코드</th>
												<td>
													<input type="text" readOnly={true} value={this.state.userDeptId} />
												</td>
												<th>{sessionStorage.deptTitle}명</th>
												<td colSpan="3">
													<Tooltip title={this.state.userDeptNm} placement="top-start">
														<input type="text" readOnly={true} value={this.state.userDeptNm} />
													</Tooltip>
												</td>
											</tr>
											<tr>
												<th>사용자</th>
												<td>
													<Tooltip title={this.state.userNm + this.state.userEmpno} placement="top-start">
														<input type="text" readOnly={true} value={this.state.userNm + this.state.userEmpno} />
													</Tooltip>
												</td>
												<th>관리번호</th>
												<td colSpan="3">
													<input
														type="text"
														value={this.state.itemSeq}
														style={{ width: 110, marginRight: 5, border: 0 }}
														onChange={(e) => this.setState({ itemSeq: e.target.value })}
													/>
													<IconButton
														onClick={this.confirmItemSeqChange}
														color="secondary"
														className="iconButton"
														disabled={this.state.asstNo !== '' ? false : true}
													>
														<SaveIcon fontSize="small" />
													</IconButton>
												</td>
											</tr>
											<tr>
												<th>전화번호</th>
												<td>
													<input type="text" readOnly={true} value={this.state.userTelno} style={{ border: 0 }} />
												</td>
												<th>핸드폰번호</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.userHpno} style={{ border: 0 }} />
												</td>
											</tr>
											<tr>
												<th>실사용장소</th>
												<td colSpan="5">
													<Tooltip title={this.state.realUseSpot} placement="top-start">
														<input type="text" readOnly={true} value={this.state.realUseSpot} />
													</Tooltip>
												</td>
											</tr>
											<tr>
												<th>상품분류</th>
												<td>
													<input type="text" readOnly={true} value={this.state.itemGrp} />
												</td>
												<th>모델</th>
												<td colSpan="3">
													<Tooltip title={this.state.model} placement="top-start">
														<input type="text" readOnly={true} value={this.state.model} />
													</Tooltip>
												</td>
											</tr>
											<tr>
												<th>스펙</th>
												<td colSpan="5">
													{/* <input type="text" readOnly={true} value={this.state.itemSpec} /> */}
													<textarea readOnly={true} value={this.state.itemSpec || ''} style={{ height: '70px' }} />
												</td>
											</tr>
											<tr>
												<th>제조사</th>
												<td>
													<input type="text" readOnly={true} value={this.state.maker} />
												</td>
												<th>SERIAL</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.serialNo} />
												</td>
											</tr>
											<tr>
												<th>최초시작일</th>
												<td>
													<input type="text" readOnly={true} value={dateToFormat(this.state.contDate)} />
												</td>
												<th>렌탈시작일</th>
												<td>
													<input type="text" readOnly={true} value={dateToFormat(this.state.fromDate)} />
												</td>
												<th>렌탈종료일</th>
												<td>
													<input type="text" readOnly={true} value={dateToFormat(this.state.toDate)} />
												</td>
											</tr>
											<tr>
												<th>월렌탈료</th>
												<td>
													<input type="text" readOnly={true} value={InsertComma(this.state.monthPrc)} />
												</td>
												<th>렌탈기간</th>
												<td>
													<input type="text" readOnly={true} value={this.state.contMonth} />
												</td>
												<th>계약상태</th>
												<td>
													<input type="text" readOnly={true} value={this.state.contStatus} />
												</td>
											</tr>
											<tr>
												<th>MAC무선</th>
												<td>
													<input type="text" readOnly={true} value={this.state.macCable} />
												</td>
												<th>MAC유선</th>
												<td>
													<input type="text" readOnly={true} value={this.state.macWireless} />
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								{this.renderReturnInfo()}
								<p className="headline">신청정보</p>
								<div className="writeType03_noborder">
									<table>
										<colgroup>
											<col style={{ width: 80 }} />
											<col style={{ width: 150 }} />
											<col style={{ width: 80 }} />
											<col style={{ width: 200 }} />
										</colgroup>
										<tbody>
											<tr>
												<th>요청번호</th>
												<td>
													<input type="text" readOnly={true} value={this.state.ordeNo} />
												</td>
												<th>계약번호</th>
												<td>
													<input type="text" readOnly={true} value={this.state.contNo} />
												</td>
											</tr>
											<tr>
												<th>신청일자</th>
												<td>
													<input type="text" readOnly={true} value={dateToFormat(this.state.ordeDate)} />
												</td>
												<th>신청자</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.orderUser} />
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div style={{ marginTop: 15 }}>
									<div style={{ float: 'left', width: 220 }}>
										<span>
											*검색된 자산수 : <label style={{ color: '#d41826' }}>{this.state.totAsstCnt}</label>
										</span>
										<br />
										<span>
											*예상 월렌탈료 : <label style={{ color: '#d41826' }}>{this.state.totMonthPrc}</label>원
										</span>
									</div>
									<div style={{ marginTop: 5, float: 'right' }}>
										<Button
											color="primary"
											variant="contained"
											size="small"
											style={{ alignItems: 'initial' }}
											onClick={this.changeUser}
											disabled={this.state.disabledBtn}
										>
											<ChangeIcon style={{ fontSize: 20, marginRight: 5 }} />
											사용자 및 지점 변경
										</Button>
										<Button color="secondary" variant="contained" size="small" style={{ marginLeft: 10, alignItems: 'initial' }} onClick={this.returnAsset}>
											<ReturnIcon style={{ fontSize: 20, marginRight: 5 }} />
											자산반납
										</Button>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(RentalStatusSearch);
