import React, { Component } from 'react';
import { makeObservable, observable, action } from 'mobx';
import { observer } from 'mobx-react';

const PostPopupStore = observable({
	open: false,
	handleClose: null,

	openPopup(handleClose) {
		this.open = true;
		if (handleClose) this.handleClose = handleClose;
	},
	closePopup(item) {
		this.open = false;
		if (this.handleClose) {
			this.handleClose(item);
		}
	},
});

export default PostPopupStore;
