import React, { Component } from 'react';
import { makeObservable, observable, action } from 'mobx';
import { observer } from 'mobx-react';

const ApiMisreturnSearchPopupStore = observable({
	open: false,
	list: [],
	handleClose: null,
	contNo: '',

	openPopup(handleClose) {
		this.open = true;
		if (handleClose) this.handleClose = handleClose;
	},
	closePopup(item) {
		this.open = false;
		if (this.handleClose) {
			this.handleClose(item);
		}
		this.contNo = '';
		this.list = [];
	},
	setList(list) {
		this.list = list;
	},
	setContNo(contNo) {
		this.contNo = contNo;
	},
});

export default ApiMisreturnSearchPopupStore;
