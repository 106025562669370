import React, { Component } from 'react';
import BoardPopup from './BoardPopup';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CheckIcon from '@material-ui/icons/HowToReg';
import { getNoticeBoardList, getQnaBoardList } from '../service/HomeService';
import { errorMsgCheck, dateToFormat, Transition } from '../common/ComFunction';

import { observer } from 'mobx-react';
import CommonStore from '../common/CommonStore';
const { ModalStore, ProgressStore } = CommonStore;

class Board extends Component {
	constructor(props) {
		super(props);
		this.state = {
			custNo: this.props.custNo,
			noticeList: [], //공지 리스트
			qnaList: [], //qna리스트

			noticePageNo: 1, //공지사항 페이지번호
			noticeLastPageNo: 0, //공지사항 마지막 페이지 번호
			qnaPageNo: 1, //Q&A 페이지 번호
			qnaLastPageNo: 0, //Q&A 마지막 페이지 번호

			popupType: '1', //팝업 타입(1:공지, 2:Q&A)
			//popupTitle: '',		    //공지사항 및 Q&A 타이틀설정
			popupMode: '', //공지사항 및 Q&A 의 읽기(read), 스기(write) 모드 설정
			boardId: '', //공지사항 및 Q&A 글ID
			openPopup: false, //게시판 팝업 오픈
		};
	}

	componentDidMount = () => {
		let param = {
			page: 1,
			custNo: sessionStorage.authCode > 1 ? this.props.custNo : '',
		};
		//최초 게시판 로딩 (페이징 단위 5)
		this.getNoticeBoardList(param);
		this.getQnaBoardList(param);
	};

	openBoardPopup = (boardId, boardType, boardMode) => {
		this.setState({
			openPopup: true,
			popupMode: boardMode,
			popupType: boardType,
			boardId: boardId,
		});
	};

	hideBoardPopup = () => {
		this.setState({ openPopup: false });
		//보드 재조회
		let param = {
			page: 1,
			custNo: sessionStorage.authCode > 1 ? this.props.custNo : '',
		};
		this.getNoticeBoardList(param);
		this.getQnaBoardList(param);
	};

	boardGoPage = (boardType, btnType) => {
		/*
            boatdType : 1(공지) , 2(Q&A)
        */
		let page = 0;
		let lastPage = 0;

		if (boardType === '1') {
			page = this.state.noticePageNo;
			lastPage = this.state.noticeLastPageNo;
		} else if (boardType === '2') {
			page = this.state.qnaPageNo;
			lastPage = this.state.qnaLastPageNo;
		}

		//버튼 용도에 따른 페이지 번호 설정
		if (btnType === 'first') {
			page = 1;
		} else if (btnType === 'prev') {
			page = page - 1;
			if (page <= 0) {
				ModalStore.openModal('맨 처음 페이지 입니다.');
				page = 1;
				return;
			}
		} else if (btnType === 'next') {
			if (page === lastPage) {
				ModalStore.openModal('마지막 페이지 입니다.');
				return;
			}
			page = page + 1;
		} else if (btnType === 'last') {
			page = lastPage;
		}

		//보드 조회
		let param = {
			page: page,
			custNo: sessionStorage.authCode > 1 ? this.state.custNo : '',
		};

		//보드 구분에 따른 state 설정
		if (boardType === '1') {
			this.setState({ noticePageNo: page });
			this.getNoticeBoardList(param);
		} else if (boardType === '2') {
			this.setState({ qnaPageNo: page });
			this.getQnaBoardList(param);
		}
	};

	getNoticeBoardList = (param) => {
		ProgressStore.activeProgress();

		getNoticeBoardList(param)
			.then((r) => {
				this.setState({
					noticeList: r,
					noticeLastPageNo: r.length > 0 ? r[0].lastPageNo : 0,
				});
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err);
			});
	};

	getQnaBoardList = (param) => {
		ProgressStore.activeProgress();

		getQnaBoardList(param)
			.then((r) => {
				this.setState({
					qnaList: r,
					qnaLastPageNo: r.length > 0 ? r[0].lastPageNo : 0,
				});
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err);
			});
	};

	render() {
		return (
			<div>
				{/* 공지사항 */}
				<div className="box">
					<div className="h2Wrap blue">
						<h2>공지사항</h2>
						<div className="btn">
							<a onClick={() => this.openBoardPopup(null, '1', 'write')} style={{ display: sessionStorage.btnControl }} className="btn01 listUp">
								<span>등록</span>
							</a>
						</div>
					</div>

					<div className="writeType01">
						<div className="fakeThead">
							<div className="tit">제목</div>
							<div className="tit size01">작성일</div>
						</div>
						<table>
							<colgroup>
								<col />
								<col style={{ width: '112px' }} />
							</colgroup>
							<tbody>
								{this.state.noticeList.map((item, i) => (
									<tr style={{ cursor: 'pointer' }} onClick={() => this.openBoardPopup(item.boardId, '1', 'read')}>
										<td className="left">
											<a>{item.subject}</a>
										</td>
										<td>{dateToFormat(item.writeDate)}</td>
									</tr>
								))}
							</tbody>
						</table>
						<div className="paging">
							<a className="button prev" title="처음으로" onClick={() => this.boardGoPage('1', 'first')}>
								처음으로
							</a>
							<a className="on" onClick={() => this.boardGoPage('1', 'prev')}>
								이전
							</a>
							<a className="on" onClick={() => this.boardGoPage('1', 'next')}>
								다음
							</a>
							<a className="button next" title="맨끝으로" onClick={() => this.boardGoPage('1', 'last')}>
								맨끝으로
							</a>
						</div>
					</div>
				</div>
				{/* Q&A */}
				<div className="box">
					<div className="h2Wrap blue">
						<h2>Q&A</h2>
						<div className="btn">
							<a onClick={() => this.openBoardPopup(null, '2', 'write')} className="btn01 qna">
								<span>문의</span>
							</a>
						</div>
					</div>
					<div className="writeType01">
						<div className="fakeThead">
							<div className="tit size02">상태</div>
							<div className="tit">제목</div>
							<div className="tit size01">작성일</div>
						</div>
						<table>
							<colgroup>
								<col style={{ width: '100px' }} />
								<col />
								<col style={{ width: '112px' }} />
							</colgroup>
							<tbody>
								{this.state.qnaList.map((item, i) => (
									<tr style={{ cursor: 'pointer' }} onClick={() => this.openBoardPopup(item.boardId, '2', 'read')}>
										<td>{item.replyComplete ? <span className="re">{item.replyComplete}</span> : '미완료'}</td>
										<td className="left">{item.subject}</td>
										<td>{dateToFormat(item.writeDate)}</td>
									</tr>
								))}
							</tbody>
						</table>
						<div className="paging">
							<a className="button prev" title="처음으로" onClick={() => this.boardGoPage('2', 'first')}>
								처음으로
							</a>
							<a className="on" onClick={() => this.boardGoPage('2', 'prev')}>
								이전
							</a>
							<a className="on" onClick={() => this.boardGoPage('2', 'next')}>
								다음
							</a>
							<a className="button next" title="맨끝으로" onClick={() => this.boardGoPage('2', 'last')}>
								맨끝으로
							</a>
						</div>
					</div>
				</div>
				{/* 게시판 팝업 dialog */}
				<Dialog open={this.state.openPopup} onClose={this.hidePopup}>
					<BoardPopup boardId={this.state.boardId} type={this.state.popupType} mode={this.state.popupMode} hidePopup={this.hideBoardPopup} />
				</Dialog>
			</div>
		);
	}
}
export default observer(Board);
