import React, { Component } from 'react';

import Dialog from '@material-ui/core/Dialog';

import { getAssetSpec } from '../../service/rental/RentalAssetService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
import { errorMsgCheck } from '../../common/ComFunction';
const { ProgressStore, PreAssetSpecPopStore } = CommonStore;

class PreAssetSpecPop extends Component {
	constructor(props) {
		super(props);

		this.state = {
			model: '', //모델명
			maker: '', //제조사
			itemDesc: '', //제품설명
		};
	}

	componentDidUpdate = () => {
		if (PreAssetSpecPopStore.open && !PreAssetSpecPopStore.searched) {
			let param = {
				asstNo: PreAssetSpecPopStore.asstNo,
			};
			ProgressStore.activeProgress();
			getAssetSpec(param)
				.then((r) => {
					this.setState({
						model: r[0].model, //모델명
						maker: r[0].makeNm, //제조사
						itemDesc: r[0].itemSpec, //제품설명
					});
					PreAssetSpecPopStore.setSearched(true);
				})
				.catch((err) => errorMsgCheck(err))
				.finally(() => {
					ProgressStore.deactiveProgress();
				});
		}
	};

	resetState = () => {
		//state초기화
		this.setState({
			model: '', //모델명
			maker: '', //제조사
			itemDesc: '', //제품설명
		});
	};

	render() {
		const { open } = PreAssetSpecPopStore;

		let popContHeight = {
			height: 600,
		};

		return (
			<div>
				<Dialog open={open} onClose={PreAssetSpecPopStore.closePopup}>
					<div className="popSmArea searchPop">
						<div className="popCont" style={popContHeight}>
							<div className="h2Wrap">
								<h2>이전자산스펙</h2>
							</div>
							<div className="writeType02 type2">
								<table>
									<colgroup>
										<col style={{ width: '100px' }} />
										<col />
									</colgroup>
									<tbody>
										<tr>
											<th>모델명 </th>
											<td colSpan="3">
												<input type="text" name="model" maxLength="50" value={this.state.model || ''} ref={(ref) => (this.model = ref)} readOnly />
											</td>
										</tr>
										<tr>
											<th>제조사 </th>
											<td colSpan="3">
												<input type="text" name="maker" maxLength="50" value={this.state.maker || ''} ref={(ref) => (this.maker = ref)} readOnly />
											</td>
										</tr>
										<tr>
											<th>
												제품상세 <span className="star">*</span>
											</th>
											<td colSpan="3">
												<textarea
													className="size01"
													name="itemDesc"
													maxLength="500"
													value={this.state.itemDesc || ''}
													ref={(ref) => (this.itemDesc = ref)}
													readOnly
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div
								className="btnClose"
								onClick={() => {
									PreAssetSpecPopStore.closePopup(undefined);
									this.resetState();
								}}
							>
								<a>팝업닫기</a>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}
}
export default observer(PreAssetSpecPop);
