import { httpGet, httpPost, httpPut, toQueryString } from '../Service';

// api 인터페이스 처리내역
export function getApiProcList(data) {
	return httpGet('/api/v1.0/cis/apiInput/apiProc' + toQueryString(data));
}

// api 입력 처리상태 리셋
export function apiInputReset(data) {
	data = data || {};
	return httpPut('/api/v1.0/cis/apiInput/apiInputReset/', { body: data });
}
