import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Transition, ArrayTextComma, errorMsgCheck } from '../../common/ComFunction';

import { updateCustReqNo } from '../../service/rental/RentalOrderService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { KYContRenewalPopupStore, ConfirmModalStore, ModalStore, ProgressStore } = CommonStore;
class KYContRenewalPopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			oldCustReqNo: '',
			newCustReqNo: props.custRe,
		};
	}

	handleClose = () => {
		KYContRenewalPopupStore.closePopup();
		this.setState({
			oldCustReqNo: '',
		});
	};

	render() {
		return (
			<div>
				<Dialog open={KYContRenewalPopupStore.open} onClose={this.handleClose} TransitionComponent={Transition}>
					<div style={{ padding: '5px 5px 5px 5px' }}>
						<span style={{ marginLeft: 10 }}>기존 교원 요청번호</span>
						<DialogContent>
							<input
								type="text"
								style={{ minHeight: 10, width: 250, marginTop: 10 }}
								value={this.state.oldCustReqNo}
								onChange={(e) => this.setState({ oldCustReqNo: e.target.value })}
							/>
						</DialogContent>
						<span style={{ marginLeft: 10 }}> 변경 교원 요청번호</span>
						<DialogContent>
							<input
								value={KYContRenewalPopupStore.newCustReqNo}
								style={{ minHeight: 10, width: 250, marginTop: 10, border: 0 }}
								readOnly={true}
								//onChange={(e) => this.setState({ text: e.target.value })}
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => ConfirmModalStore.openModal('재약정 처리하시겠습니까?', this.updateCustReqNo)} color="primary" autoFocus>
								처리
							</Button>
							<Button onClick={() => this.handleClose()} color="secondary">
								닫기
							</Button>
						</DialogActions>
					</div>
				</Dialog>
			</div>
		);
	}

	updateCustReqNo = () => {
		ProgressStore.activeProgress();

		//let arrData = window.getAssetInfoChangeList();
		let param = {
			oldCustReqNo: this.state.oldCustReqNo,
			newCustReqNo: KYContRenewalPopupStore.newCustReqNo,
		};
		console.log(param.oldCustReqNo);
		console.log(param.newCustReqNo);

		if (param.oldCustReqNo === '') {
			ModalStore.openModal('기존 고객요청번호를 입력하세요');
			return;
		}

		updateCustReqNo(param)
			.then(() => {
				ModalStore.openModal('처리 되었습니다.', this.handleClose());
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};
}
export default observer(KYContRenewalPopup);
