import React, { Component } from 'react';
import { makeObservable, observable, action } from 'mobx';
import { observer } from 'mobx-react';

const StockPopupStore = observable({
	open: false,
	handleClose: null,
	stockType: 'A', //타입(A:상품/B:재고)
	modelTitle: '', //모델명(제목)
	model: '', //모델명
	arrLargeCate: [], //대분류리스트

	largeCate: '',
	itemCode: '',
	rootCd: '04', //IT

	openPopup(stockType, modelTitle, handleClose) {
		this.open = true;
		if (stockType) this.stockType = stockType;
		if (modelTitle) this.modelTitle = modelTitle;
		if (handleClose) this.handleClose = handleClose;
	},
	closePopup(item) {
		this.open = false;
		if (this.handleClose) {
			this.handleClose(item);
		}

		this.stockType = 'A';
		this.model = '';
		this.modelTitle = '';
		this.largeCate = '';
		this.itemCode = '';
	},
	setModel(model) {
		this.model = model;
	},
	setItemCode(itemCode) {
		this.itemCode = itemCode;
	},
	setLargeCate(largeCate) {
		this.largeCate = largeCate;
	},
	setArrLargeCate(list) {
		this.arrLargeCate = list;
	},
});

export default StockPopupStore;
