import React, { Component } from 'react';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore } = CommonStore;

class ExcelDown extends Component {
	constructor() {
		super();
	}
	// 엑셀 다운로드. json array를 csv blob으로 전환하여 다운로드
	excelDown = () => {
		var row = this.props.data;

		if (row.length === 0) {
			ModalStore.openModal('검색된 내용이 없습니다.');
			return false; // You are stopping the handling of component
		}

		var csvRows = [];
		//헤더생성
		for (var cell = 0; cell < 1; ++cell) {
			var str = '';
			for (var key in row[cell]) {
				str += key + ',';
			}
			csvRows.push(str);
		}
		//데이터생성
		for (var cell = 0; cell < row.length; ++cell) {
			var str = '';
			for (var key in row[cell]) {
				var tmp = row[cell][key] + '';
				tmp = tmp === '' || tmp === 'null' ? '' : tmp.replace(/,/g, ''); //csv파일로 변경하므로 컬럼 스트링의 콤마는 제거
				str += tmp + ',';
			}
			str = str.replace(/['"]+/g, '');
			csvRows.push(str);
		}

		var csvString = csvRows.join('\n');

		// var csvString = csvRows.join("\r\n");

		if (window.navigator.msSaveOrOpenBlob) {
			//ie는 msSaveOrOpenBlob으로 처리. \ufeff 없으면 utf-8 적용 안됨
			var blob = new Blob(['\ufeff', csvString], { type: 'text/csv;charset=utf-8;' });
			window.navigator.msSaveOrOpenBlob(blob, this.props.filename);
		} else {
			let csvFile = new Blob(['\ufeff', csvString], { type: 'text/csv;charset=utf-8' });
			let downloadLink = document.createElement('a');
			downloadLink.download = this.props.filename;
			downloadLink.href = window.URL.createObjectURL(csvFile);
			downloadLink.style.display = 'none';
			document.body.appendChild(downloadLink);
			downloadLink.click();
		}
	};

	render() {
		const { text } = this.props;

		return (
			<a className="btn03 save" onClick={this.excelDown}>
				<span>{text}</span>
			</a>
		);
	}
}

export default observer(ExcelDown);
