import React, { Component } from 'react';
import IBSheet from '../IBSheet';

import Dialog from '@material-ui/core/Dialog';

import { errorMsgCheck, buildSelectItems, resultToIbSheet, Transition } from '../../common/ComFunction';
import { getDeprItemTypeList, getIfrsBoardList } from '../../service/CommonService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ProgressStore, IfrsPopupStore } = CommonStore;

class IfrsPopup extends Component {
	constructor(props) {
		super(props);

		this.sheetData = {
			id: 'ifrsPopupSheet', // sheet id
			initData: 'include/Init-IfrsPopup', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '300px', // sheet height
		};
	}

	componentDidUpdate = () => {
		if (IfrsPopupStore.open && IfrsPopupStore.arrItemCate.length === 0) {
			getDeprItemTypeList(IfrsPopupStore.deptCode)
				.then((r) => {
					IfrsPopupStore.setArrItemCate(r);
				})
				.catch((err) => {
					errorMsgCheck(err);
				});
		}
	};

	popClose = (data) => {
		window[this.sheetData.id].DisposeSheet();
		IfrsPopupStore.closePopup(data);
	};

	getSelectIfrsItem = () => {
		let data = window.getIfrsSelectData();
		if (!data) return;

		this.popClose(data);
	};

	getIfrsList = () => {
		if (!IfrsPopupStore.itemType) {
			ModalStore.openModal('제품군을 반드시 선택해 주세요.');
			return;
		}

		let param = {
			deptCode: IfrsPopupStore.deptCode,
			itemType: IfrsPopupStore.itemType,
		};

		ProgressStore.activeProgress();
		getIfrsBoardList(param)
			.then((r) => {
				window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	render() {
		const { open } = IfrsPopupStore;

		return (
			<div>
				<Dialog open={open} TransitionComponent={Transition} onClose={this.popClose}>
					<div className="popSmArea searchPop" style={{ transform: 'translate(-8%, 0)' }}>
						<div className="popCont" style={{ height: 480, width: 900, overflow: 'hidden' }}>
							<div className="h2Wrap">
								<h2>재고조회</h2>
							</div>
							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: 80 }} />
										<col style={{ width: 250 }} />
										<col />
									</colgroup>
									<tbody>
										<tr>
											<th>제품군</th>
											<td>
												<select value={IfrsPopupStore.itemType} onChange={(e) => IfrsPopupStore.setItemType(e.target.value)}>
													{IfrsPopupStore.arrItemCate ? buildSelectItems([{ title: '선택', value: '' }].concat(IfrsPopupStore.arrItemCate)) : null}
												</select>
											</td>
											<td>
												<div className="btnRight">
													<a className="btn01 search" onClick={this.getIfrsList}>
														<span>조회</span>
													</a>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="writeType04" style={{ marginTop: -14 }} onDoubleClick={this.getSelectIfrsItem}>
								<IBSheet {...this.sheetData} />
							</div>
							<div className="btnClose" onClick={this.popClose}>
								<a>팝업닫기</a>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}
}
export default observer(IfrsPopup);
