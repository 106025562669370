import React, { Component } from 'react';
import ConfirmModal from './ConfirmModal';
import Modal from './Modal';
import Progress from './Progress';
import CustSearchPopup from './CustSearchPopup';
import UserPopup from './UserPopup';
import ApiOrdeItemAllocPopup from './ApiOrdeItemAllocPopup';
import StockPopup from './StockPopup';
import IfrsPopup from './IfrsPopup';
import PostPopup from './PostPopup';
import LargeInputNo from './LargeInputNo';
import RentalOrderDividePop from '../rental/RentalOrderDividePop';
import KYContInfoCheckPopup from './KYContInfoCheckPopup';
import ItemPopup from './ItemPopup';
import FilePopup from './FilePopup';
import AssetInfoChangePop from '../rental/AssetInfoChangePop';
import AssetHistoryPop from '../rental/AssetHistoryPop';
import AssetReturnPop from '../rental/AssetReturnPop';
import AssetListChangePop from '../rental/AssetListChangePop';
import PreAssetSpecPop from '../rental/PreAssetSpecPop';
import RentalOrderPop from '../rental/RentalOrderPop';
import RentalOrderItemCombPop from '../rental/RentalOrderItemCombPop';
import KYUseageDaysPop from '../rental/KYUseageDaysPop';
import ApiMisreturnSearchPopup from './ApiMisreturnSearchPopup';
import KYContRenewalPopup from './KYContRenewalPopup';

class Common extends Component {
	render() {
		return (
			<div>
				{/* 모달 */}
				<Modal />
				{/* 컨펌 */}
				<ConfirmModal />
				{/* 프로그래스 */}
				<Progress />
				{/* 고객사/지점 검색 */}
				<CustSearchPopup />
				{/* 사용자 검색 */}
				<UserPopup />
				{/* API계약 제품 일괄할당 */}
				<ApiOrdeItemAllocPopup />
				{/* 재고팝업 */}
				<StockPopup />
				{/* IFRS팝업 */}
				<IfrsPopup />
				{/* 주소팝업 */}
				<PostPopup />
				{/* 대량입력 */}
				<LargeInputNo />
				{/* 요청분리 */}
				<RentalOrderDividePop />
				{/* 교원계약정보확인 */}
				<KYContInfoCheckPopup />
				{/* 제품팝업 */}
				<ItemPopup />
				{/* 파일팝업 */}
				<FilePopup />
				{/* 자산정보변경 */}
				<AssetInfoChangePop />
				{/* 자산이력 */}
				<AssetHistoryPop />
				{/* 자산반납 */}
				<AssetReturnPop />
				{/* 자산리스트반납 -> 렌탈자산현황에서 팝업 호출하지 않고 직접 그리드 display하여 처리 */}
				{/* <AssetListChangePop /> */}
				{/* 이전자산스펙 */}
				<PreAssetSpecPop />
				{/* 요청상세팝업 */}
				<RentalOrderPop />
				{/* 요청제품조합선택팝업 */}
				<RentalOrderItemCombPop />
				{/* 교원월별자산사용일조회 */}
				<KYUseageDaysPop />
				{/* 교원오반납목록팝업 */}
				<ApiMisreturnSearchPopup />
				{/* 재약정 이전 고객요청번호 입력 */}
				<KYContRenewalPopup />
			</div>
		);
	}
}

export default Common;
