import React, { Component } from 'react';
import { makeObservable, observable, action } from 'mobx';
import { observer } from 'mobx-react';

const CustSearchPopupStore = observable({
	open: false,
	popupType: '1', // 1:고객사(cis.dbo.comp 기준) / 2:지점 / 3: 고객사(rental.dbo.customer 기준) / 4: 유지보수사 / 5: 제휴사 검색용 / 6: 제휴사 가입승인요청 바로 사용시
	popupTitle: '고객사',
	custNo: '',
	handleClose: null,

	custNm: '', //고객사명
	custList: [], //고객사리스트

	spotNm: '', //지점명
	spotList: [], //지점리스트
	spotSearched: false, // 지점은 자동조회 사용하므로 조회여부 체크를 위한 state

	openPopup(popupType, popupTitle, custNo, handleClose) {
		this.open = true;
		if (popupType) this.popupType = popupType;
		if (popupTitle) {
			this.popupTitle = popupTitle;
		} else {
			if (this.popupType === '1') this.popupTitle = '고객사';
			if (this.popupType === '2') this.popupTitle = '지점';
			if (this.popupType === '3') this.popupTitle = '고객사';
			if (this.popupType === '4') this.popupTitle = '유지보수사';
			if (this.popupType === '5' || this.popupType === '6') this.popupTitle = '제휴사';
		}
		if (custNo) this.custNo = custNo;
		if (handleClose) this.handleClose = handleClose;
	},
	closePopup(item) {
		this.open = false;
		if (this.handleClose) {
			this.handleClose(item);
		}

		this.custNm = '';
		this.spotNm = '';
		this.custList = [];
		this.spotList = [];
		this.spotSearched = false;
	},
	setCustNm(custNm) {
		this.custNm = custNm;
	},
	setSpotNm(spotNm) {
		this.spotNm = spotNm;
	},
	setCustList(list) {
		this.custList = list;
	},
	setSpotList(list) {
		this.spotList = list;
		this.spotSearched = true;
	},
});

export default CustSearchPopupStore;
