import React, { Component } from 'react';
import queryString from 'query-string';
import ajLogo from '../../images/h1Login.png';
import { userLogin } from '../../service/CommonService';
import { handleSessionStorage, errorMsgCheck } from '../../common/ComFunction';
import { getAppUser } from '../../service/rental/RentalOrderApprLinkService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ProgressStore } = CommonStore;

class RentalOrderApprLink extends Component {
	constructor(props) {
		super(props);
		const query = queryString.parse(props.location.search);
		// console.log(query);

		this.state = {
			userId: query.userId, //결재자id
			passWord: '', //결재자pw
			ordeNo: query.ordeNo, //결재요청번호
			time: '', //메일전송일자
			appUserId: '', //요청번호로 조회한 결재자 id
		};
	}
	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
		});
	};

	componentDidMount = () => {
		this.passWord.focus();

		if (this.chkParam()) {
			//파라미터체크
			// alert('통과!');
			// this.handleSubmit();//로그인
			this.getAppUser();
		} else {
			this.redirectToMain();
		}
	};

	redirectToMain = () => {
		if (window.location.hostname.indexOf('localhost') > -1 || window.location.hostname.indexOf('127.0.0.1') > -1) {
			//로컬테스트환경
			window.location.assign('http://localhost:3000');
		} else if (window.location.hostname.indexOf('dev') > -1) {
			//테스트서버
			window.location.assign('http://dev-cis.networks.ajway.kr');
		} else {
			//운영서버
			window.location.assign('http://cis.networks.ajway.kr');
		}
	};

	//요청번호로 결재자정보 조회
	getAppUser = () => {
		ProgressStore.activeProgress();
		getAppUser(this.state.ordeNo)
			.then((r) => {
				if (r[0] !== null) {
					// this.setState({custList : r});
					this.setState(
						{
							time: r[0].instDate,
							appUserId: r[0].userId,
						},
						() => {
							if (!(this.chkDate() && this.chkAppUser())) {
								this.redirectToMain();
							}
						},
					);
				} else {
					alert('해당 요청번호는 존재하지 않습니다.');
					this.redirectToMain();
				}
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, this.props);
			});
	};

	chkParam = () => {
		const { userId, ordeNo } = this.state;
		if (userId === '' || userId === undefined || userId === null || ordeNo === '' || ordeNo === undefined || ordeNo === null) {
			alert('파라미터가 올바르지않습니다.');
			return false;
		} else {
			return true;
		}
	};

	chkDate = () => {
		var date = new Date();
		var year = date.getFullYear();
		var month = new String(date.getMonth() + 1);
		var day = new String(date.getDate());

		// 한자리수일 경우 0을 채워준다.
		if (month.length == 1) {
			month = '0' + month;
		}
		if (day.length == 1) {
			day = '0' + day;
		}

		var today = new Date(year + '-' + month + '-' + day);
		var mailDate = new Date(this.state.time);

		var diff = today - mailDate;
		var currDay = 24 * 60 * 60 * 1000; // 시 * 분 * 초 * 밀리세컨

		var diffDay = parseInt(diff / currDay);

		if (diffDay > 7) {
			// 일주일을 넘긴 case
			alert('결재URL의 유효기간이 지났습니다.');
			return false;
		} else {
			return true;
		}
	};

	chkAppUser = () => {
		if (this.state.userId !== this.state.appUserId) {
			alert('결재요청번호의 결재자ID와 일치하지 않습니다.');
			return false;
		} else {
			return true;
		}
	};

	handleSubmit = () => {
		ProgressStore.activeProgress();

		let param = {
			username: this.state.userId,
			password: this.state.passWord,
		};

		this.setState({ buttonEnable: true });

		userLogin(param)
			.then((r) => {
				if (r.length > 0) {
					let userData = r[0];
					handleSessionStorage(userData, false, '0000002', '0000005');

					ProgressStore.deactiveProgress();

					this.props.history.push('/RentalOrderAppr?ordeNo=' + this.state.ordeNo, null);
				} else {
					alert('등록된 정보가 없습니다.');
					this.setState({ buttonEnable: false });
					ProgressStore.deactiveProgress();
				}
			})
			.catch((err) => {
				alert(err);
				console.log(err);
				this.setState({ buttonEnable: false });
				ProgressStore.deactiveProgress();
			});
	};

	render() {
		return (
			<div>
				<div id="skipnavigation">
					<a href="#container">본문내용 바로가기</a>
				</div>

				<div className="wrap">
					<div className="loginWrap">
						<div className="h1Logoin">
							<h1>
								<img src={ajLogo} alt="AJ렌탈" />
							</h1>
						</div>
						<div className="member">
							<div className="tit">결재자 확인</div>
							<div className="inputWrap">
								<div className="box">
									<input
										type="text"
										id="userId"
										name="userId"
										placeholder="아이디를 입력해 주세요."
										value={this.state.userId || ''}
										onChange={this.handleChange}
										readOnly
									/>
								</div>
								<div className="box">
									<input
										type="password"
										id="passWord"
										name="passWord"
										ref={(ref) => (this.passWord = ref)}
										autoComplete="new-password"
										placeholder="비밀번호를 입력해 주세요."
										value={this.state.passWord || ''}
										onChange={this.handleChange}
										onKeyDown={(e) => {
											if (e.key === 'Enter') this.handleSubmit();
										}}
									/>
								</div>
								<div className="btnLogin" onClick={this.handleSubmit}>
									<a href="javascript:;">결재페이지 이동</a>
								</div>
								<div className="link" style={{ marginBottom: '60px' }}>
									{/* <a href="javascript:;" onClick={() => this.handleClickOpen(0)}>아이디 찾기</a>
                                    <a href="javascript:;" onClick={() => this.handleClickOpen(1)}>패스워드 찾기</a> */}
									{/* <a href="javascript:;" onClick={this.handleClickOpenJoin}>회원가입</a> */}
								</div>
							</div>
						</div>
						<div className="info">
							<a href="tel:15880053">컨텍센터 1588 - 0053</a>
							<a href="http://www.ajnetworks.co.kr/" target="_blank">
								AJ렌탈 홈페이지 바로가기
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default observer(RentalOrderApprLink);
