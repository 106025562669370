import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

const MAX = 10; // autoComplete 라인수

const styles = (theme) => ({
	root: {
		flexGrow: 1,
	},
	container: {
		position: 'relative',
	},
	suggestionsContainerOpen: {
		position: 'absolute',
		zIndex: 1,
		marginTop: theme.spacing.unit,
		left: 0,
		right: 0,
	},
	suggestion: {
		display: 'block',
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none',
	},
	divider: {
		height: theme.spacing.unit * 2,
	},
});

class AutoComplete extends React.Component {
	constructor(props) {
		super(props);
	}
	state = {
		single: '',
		suggestions: [],
	};

	renderInputComponent = (inputProps) => {
		const { classes, inputRef = () => {}, ref, ...other } = inputProps;

		return (
			<TextField
				fullWidth
				InputProps={{
					inputRef: (node) => {
						ref(node);
						inputRef(node);
					},
					classes: {
						input: classes.input,
					},
				}}
				{...other}
			/>
		);
	};

	renderSuggestion = (suggestion, { query, isHighlighted }) => {
		const matches = match(suggestion.label, query);
		const parts = parse(suggestion.label, matches);

		return (
			<MenuItem selected={isHighlighted} component="div">
				<div>
					{parts.map((part, index) => {
						return part.highlight ? (
							<span key={String(index)} style={{ fontWeight: 500 }}>
								{part.text}
							</span>
						) : (
							<strong key={String(index)} style={{ fontWeight: 300 }}>
								{part.text}
							</strong>
						);
					})}
				</div>
			</MenuItem>
		);
	};

	getSuggestions = (value) => {
		const inputValue = deburr(value.trim()).toLowerCase();
		const inputLength = inputValue.length;
		let count = 0;

		return inputLength === 0
			? []
			: this.props.suggestions.filter((suggestion) => {
					const keep =
						//   count < MAX && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue; // 시작글자버전
						count < MAX && suggestion.label.toLowerCase().indexOf(inputValue) > -1; // 포합버전

					if (keep) {
						count += 1;
					}

					return keep;
			  });
	};

	getSuggestionValue = (suggestion) => {
		return suggestion.label;
	};

	handleSuggestionsFetchRequested = ({ value }) => {
		this.setState({
			suggestions: this.getSuggestions(value),
		});
	};

	handleSuggestionsClearRequested = () => {
		this.setState({
			suggestions: [],
		});
	};

	handleChange =
		(name) =>
		(event, { newValue }) => {
			// console.log(this.props.suggestions);
			this.setState(
				{
					[name]: newValue,
				},
				() => {
					this.props.handleSearchChange(newValue);
				},
			);
		};

	render() {
		const { classes } = this.props;

		const autosuggestProps = {
			renderInputComponent: this.renderInputComponent,
			suggestions: this.state.suggestions,
			onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
			onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
			getSuggestionValue: this.getSuggestionValue,
			renderSuggestion: this.renderSuggestion,
		};

		return (
			<div className={classes.root}>
				<Autosuggest
					{...autosuggestProps}
					inputProps={{
						classes,
						placeholder: this.props.placeholder,
						value: this.state.single,
						onChange: this.handleChange('single'),
					}}
					theme={{
						container: classes.container,
						suggestionsContainerOpen: classes.suggestionsContainerOpen,
						suggestionsList: classes.suggestionsList,
						suggestion: classes.suggestion,
					}}
					renderSuggestionsContainer={(options) => (
						<Paper {...options.containerProps} square>
							{options.children}
						</Paper>
					)}
				/>
			</div>
		);
	}
}

AutoComplete.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AutoComplete);
