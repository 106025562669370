import React, { Component } from 'react';
import IBSheet from '../IBSheet';
import { errorMsgCheck, resultToIbSheet } from '../../common/ComFunction';
import { getOrderDetail, getOrderItemList } from '../../service/rental/RentalOrderService';
import Progress from '../include/Progress';
import Dialog from '@material-ui/core/Dialog';
import { Transition } from '../../common/ComFunction';
import { CUST_NO_HKFIRE } from '../../common/ComValues';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ProgressStore, RentalOrderPopStore } = CommonStore;

class RentalOrderPop extends Component {
	constructor(props) {
		super(props);

		this.state = {
			itemList: [], //신청제품 리스트

			ordeNo: '', //요청번호
			addrSeq: '', //지점시퀀스
			spotId: '', //지점코드
			spotNm: '', //지점명
			userDeptId: '', //사용자지점코드 (흥국:대리점코드)
			delUserNm: '', //수령인명
			delDeptNm: '', //수령인부서명
			delTelNo: '', //수령인전화번호
			delMobileNo: '', //수령인휴대폰번호
			delPostCode: '', //우편번호
			delAddr: '', //주소1
			delAddrDtl: '', //상세주소
			delRemark: '', //배송요청사항

			deptShow: 'none', // 대리점코드 보이는 여부
			ct006Flag: false,
		};

		this.orderItemSheetPop = {
			id: 'orderItemSheetPop', // sheet id
			initData: 'rental/Init-RentalOrderDtl_item', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '180px', // sheet height
		};
	}

	componentDidUpdate = () => {
		if (RentalOrderPopStore.open && !RentalOrderPopStore.searched) {
			ProgressStore.activeProgress();
			Promise.all([getOrderDetail(RentalOrderPopStore.ordeNo), getOrderItemList(RentalOrderPopStore.ordeNo)])
				.then((r) => {
					let [data1, data2] = r;

					//신청정보셋팅
					this.setUserData(data1[0]);
					//신청 제품 리스트 셋팅

					const sheetIntvl = setInterval(() => {
						if (window[this.orderItemSheetPop.id] !== undefined) {
							window[this.orderItemSheetPop.id].LoadSearchData(resultToIbSheet(data2));
							clearInterval(sheetIntvl);
						}
					}, 100);
					RentalOrderPopStore.setSearched(true);
				})
				.catch((err) => {
					errorMsgCheck(err);
				})
				.finally(() => {
					ProgressStore.deactiveProgress();
				});

			var customData = JSON.parse(sessionStorage.getItem('customData'));

			if (customData != null && customData.hasOwnProperty('CT006') && this.state.deptShow == 'none' && !this.state.ct006Flag) {
				this.setState({
					deptShow: customData['CT006'],
					ct006Flag: true,
				});
			}
		}
	};

	componentWillUnmount = () => {
		if (window[this.orderItemSheetPop.id]) {
			window[this.orderItemSheetPop.id].DisposeSheet();
		}
	};

	setUserData = (data) => {
		if (data) {
			this.setEmptyInput();
			this.setState({
				ordeNo: data.ordeNo,
				userDeptId: data.deptId, //부서(대리점)코드
				spotId: data.spotId, //지점코드
				spotNm: data.spotNm, //지점명
				delUserNm: data.delUser, //수령인명
				delDeptNm: data.delDept, //수령인부서명
				delTelNo: data.delTelno, //수령인전화번호
				delMobileNo: data.delHpno, //수령인휴대폰번호
				delPostCode: data.delPostCode, //우편번호
				delAddr: data.delAddr, //주소1
				delAddrDtl: data.delAddrDtl, //상세주소
				delRemark: data.ordeRmk, //요청비고
			});
		}
	};

	setEmptyInput = () => {
		this.setState({
			userDeptId: '', //부서(대리점)코드
			spotId: '', //지점코드
			spotNm: '', //지점명
			delUserNm: '', //수령인명
			delDeptNm: '', //수령인부서명
			delTelNo: '', //수령인전화번호
			delMobileNo: '', //수령인휴대폰번호
			delPostCode: '', //우편번호
			delAddr: '', //주소1
			delAddrDtl: '', //상세주소
			delRemark: '', //요청사항
		});
	};

	popClose = () => {
		if (window[this.orderItemSheetPop.id]) {
			window[this.orderItemSheetPop.id].RemoveAll();
			window[this.orderItemSheetPop.id].DisposeSheet();
		}
		//this.setEmptyInput();
		RentalOrderPopStore.closePopup();
	};

	render() {
		return (
			<div>
				<Dialog open={RentalOrderPopStore.open} TransitionComponent={Transition} onClose={this.popClose}>
					<div className="popSmArea searchPop" style={{ transform: 'translate(-9%, 0)' }}>
						<div className="popCont" style={{ height: 670, width: 940, overflow: 'hidden' }}>
							<div className="h2Wrap">
								<h2>상세정보</h2>
							</div>
							<p className="headline">제품정보</p>
							<div className="writeType03">
								<IBSheet {...this.orderItemSheetPop} />
							</div>
							<p className="headline">배송정보</p>
							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: 100 }} />
										<col />
									</colgroup>
									<tbody>
										<tr>
											<th>{sessionStorage.spotTitle}정보</th>
											<td colSpan="3">
												<span style={{ fontSize: 14 }}>{sessionStorage.spotTitle}명</span>
												<input type="text" value={this.state.spotNm} style={{ width: 200, marginLeft: 5 }} readOnly={true} />
												<span style={{ fontSize: 14, marginLeft: 15 }}>{sessionStorage.spotTitle}코드</span>
												<input type="text" value={this.state.spotId} style={{ width: 140, marginLeft: 5, marginRight: 5 }} readOnly={true} />
												<div style={{ display: this.state.deptShow }}>
													<span style={{ fontSize: 14, marginLeft: 15 }}>대리점코드</span>
													<input type="text" value={this.state.userDeptId} style={{ width: 120, marginLeft: 5, marginRight: 5 }} readOnly={true} />
												</div>
											</td>
										</tr>
										<tr>
											<th>수령인</th>
											<td>
												<span style={{ fontSize: 14 }}>이름</span>
												<input type="text" value={this.state.delUserNm} style={{ width: 100, marginLeft: 5 }} readOnly={true} />
												<span style={{ fontSize: 14, marginLeft: 15 }}>{sessionStorage.deptTitle}명</span>
												<input type="text" value={this.state.delDeptNm} style={{ width: 200, marginLeft: 5 }} readOnly={true} />
											</td>
										</tr>
										<tr>
											<th>연락처</th>
											<td>
												<span style={{ fontSize: 14 }}>전화번호</span>
												<input
													type="text"
													value={this.state.delTelNo}
													onChange={(e) => this.setState({ delTelNo: e.target.value })}
													onBlur={this.checkNumber}
													style={{ width: 130, marginLeft: 5 }}
													readOnly={true}
												/>
												<span style={{ fontSize: 14, marginLeft: 15 }}>휴대폰번호</span>
												<input
													type="text"
													value={this.state.delMobileNo}
													onChange={(e) => this.setState({ delMobileNo: e.target.value })}
													onBlur={this.checkNumber}
													style={{ width: 130, marginLeft: 5 }}
													readOnly={true}
												/>
											</td>
										</tr>
										<tr>
											<th>주소</th>
											<td>
												<span style={{ fontSize: 14 }}>우편번호</span>
												<input type="text" value={this.state.delPostCode} style={{ width: 70, marginLeft: 5, marginRight: 5 }} readOnly={true} />
												<span style={{ fontSize: 14, marginLeft: 15 }}>주소</span>
												<input type="text" value={this.state.delAddr} style={{ width: 578, marginLeft: 5 }} readOnly={true} />
											</td>
										</tr>
										<tr>
											<th>상세주소</th>
											<td>
												<input type="text" value={this.state.delAddrDtl} style={{ width: 756 }} readOnly={true} />
											</td>
										</tr>
										<tr>
											<th>요청사항</th>
											<td>
												<textarea value={this.state.delRemark} rows="3" style={{ height: 60 }} readOnly={true}></textarea>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="btnClose" onClick={this.popClose}>
								<a>팝업닫기</a>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}
}
export default observer(RentalOrderPop);
