import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CheckIcon from '@material-ui/icons/CreateNewFolder';
import { Transition, ArrayTextComma } from '../../common/ComFunction';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { LargeInputNoPopupStore } = CommonStore;
class LargeInputNo extends Component {
	constructor(props) {
		super(props);

		this.state = {
			text: '',
		};
	}

	handleClose = (text) => {
		LargeInputNoPopupStore.closePopup(text ? ArrayTextComma(text) : '');
		this.setState({
			text: '',
		});
	};

	render() {
		return (
			<div>
				<Dialog open={LargeInputNoPopupStore.open} onClose={this.handleClose} TransitionComponent={Transition}>
					<div style={{ padding: '5px 5px 5px 5px' }}>
						<span style={{ marginLeft: 10 }}>대량입력</span>
						<DialogContent>
							<textarea
								style={{ minHeight: 250, width: 300, marginTop: 10 }}
								value={this.state.text}
								onChange={(e) => this.setState({ text: e.target.value })}
							></textarea>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => this.handleClose(this.state.text)} color="primary" autoFocus>
								입력
							</Button>
							<Button onClick={() => this.handleClose()} color="secondary">
								닫기
							</Button>
						</DialogActions>
					</div>
				</Dialog>
			</div>
		);
	}
}
export default observer(LargeInputNo);
