import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import { getApiHandList, putApiProc } from '../../service/admin/ApiHandManagerSerivce';
import { errorMsgCheck, resultToIbSheet, SheetParamData5 } from '../../common/ComFunction';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore } = CommonStore;

class ApiHandManager extends Component {
	constructor(props) {
		super(props);

		this.sheetData = {
			id: 'apiHandManagerSheet', //sheet id
			initData: 'admin/Init-ApiHandManager',
			width: '100%',
			height: '600px',
		};
	}

	state = {
		apiHandList: '', // 수기요청리스트

		disabledBtn: true, // 처리완료 버튼

		// 검색조건
		apiInputSeq: '', //API_INPUT_SEQ
		custReqNo: '', // 고객요청번호
		custNm: '', //고객명
		custNo: '', // 고객번호
		saleChg: '', // 영업담당자
	};

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
	};

	customerSearch = () => {
		CustSearchPopupStore.openPopup('1', null, null, this.handleCustClose);
	};

	handleCustClose = (item) => {
		if (item === undefined) return;

		this.setState({
			custNo: item.custNo,
			custNm: item.custNm,
		});
	};

	apiHandList = () => {
		ProgressStore.activeProgress();

		let param = {
			apiInputSeq: this.state.apiInputSeq,
			custReqNo: this.state.custReqNo,
			custNo: this.state.custNo,
			saleChg: this.state.saleChg,
		};

		getApiHandList(param)
			.then((r) => {
				this.setState({ apiHandList: r }, () => {
					window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				});
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	apiCheck = (check) => {
		let sheet = window[this.sheetData.id];
		let FindCheckedRow = sheet.FindCheckedRow('chk');
		let checkRows = FindCheckedRow.split('|');

		if (!FindCheckedRow) {
			ModalStore.openModal('선택된 건이 없습니다.');
			return;
		}

		for (var i = 0; i < checkRows.length; i++) {
			var statusCd = sheet.GetCellValue(checkRows[i], 'statusCd');
			if (check == 'Y') {
				// 33번 까지 가능
				if (statusCd == '34' || statusCd == '35' || statusCd == '36' || statusCd == '37' || statusCd == '38') {
					ModalStore.openModal('배송완료(34), 입고완료(35), 검사중(36), 협상중(37), 청구완료(38) 상태가 아닐때 "처리완료"가 가능합니다.');
					return;
				}
			}
			if (check == 'N') {
				// 33 번부터 가능
				if (statusCd != '33' && statusCd != '34' && statusCd != '35' && statusCd != '36' && statusCd != '37'
					&& statusCd != '38') {
					ModalStore.openModal('배송할당(33), 배송완료(34), 입고완료(35), 검사중(36), 협상중(37), 청구완료(38) 상태일 경우에 "처리불가"가 가능합니다.');
					return;
				}
			}
			sheet.SetCellValue(checkRows[i], 'procYn', check);
		}

		ConfirmModalStore.openModal('선택된 주문번호를 처리하시겠습니까?', () => {
			let sheet = window[this.sheetData.id];

			ProgressStore.activeProgress();
			const sheetParam = SheetParamData5(sheet, null);

			console.log(sheetParam);

			putApiProc(sheetParam.data)
				.then((r) => {
					ModalStore.openModal('저장되었습니다.', this.apiHandList);
				})
				.catch((err) => {
					errorMsgCheck(err);
				})
				.finally(() => {
					ProgressStore.deactiveProgress();
				});
		});
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mm30">
								<h2>API 수기관리</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.apiHandList}>
										<span>조회</span>
									</a>
									<Button
										color="secondary"
										variant="contained"
										style={{ lineHeight: '18px', marginLeft: '10px' }}
										onClick={() => {
											this.apiCheck('Y');
										}}
									>
										<label style={{ fontSize: '13px' }}>처리완료</label>
									</Button>
									<Button
										color="primary"
										variant="contained"
										style={{ lineHeight: '18px', marginLeft: '10px' }}
										onClick={() => {
											this.apiCheck('N');
										}}
									>
										<label style={{ fontSize: '13px' }}>처리불가</label>
									</Button>
								</div>
							</div>

							<div className="writeType03">
								<table>
									<tbody>
										<tr>
											<th style={{ width: '8%' }}>SEQ</th>
											<td>
												<input
													type="text"
													style={{ width: 80, marginRight: 10 }}
													value={this.state.apiInputSeq}
													onChange={(e) => this.setState({ apiInputSeq: e.target.value })}
												/>
											</td>
											<th style={{ width: '10%' }}>고객요청번호</th>
											<td>
												<input type="text" value={this.state.custReqNo} onChange={(e) => this.setState({ custReqNo: e.target.value })} />
											</td>
											<th style={{ width: '7%' }}>고객명</th>
											<td style={{ width: '20%' }}>
												<input type="text" readOnly={true} style={{ marginRight: 10, width: 130 }} value={this.state.custNm} />
												<IconButton onClick={this.customerSearch} color="secondary" className="iconButton" style={{ display: sessionStorage.btnControl }}>
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
											<th style={{ width: '10%' }}>영업사원 사번</th>
											<td>
												<input type="text" value={this.state.saleChg} onChange={(e) => this.setState({ saleChg: e.target.value })} />
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="writeType03">
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(ApiHandManager);
