import React, { Component } from 'react';
import LeftMenu from '../include/LeftMenu';
import Header from '../include/Header';
import IBSheet from '../IBSheet';
import { Button } from '@material-ui/core';
import { Search, Clear } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import AutoComplete from '../include/AutoComplete';
import { errorMsgCheck, resultToIbSheet, buildSelectItems, managerCheck } from '../../common/ComFunction';
import { getAllCompList, getCisCommCode } from '../../service/CommonService';
import { getCompList, saveComp } from '../../service/admin/CompService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore, UserPopupStore, FilePopupStore } = CommonStore;

class CustManager extends Component {
	constructor(props) {
		super(props);

		this.sheetData = {
			id: 'sheetCustManager', // sheet id
			initData: 'admin/Init-CustManager', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '600px', // sheet height
		};
	}

	state = {
		//검색조건
		custNmSearch: '', //고객사명
		alncCustNoSearch: '', // 제휴사 고객번호
		alncCustNmSearch: '', // 제휴사명

		//조회결과
		compList: [], //고객사리스트

		//입력항목
		custNo: '', //고객번호
		custNm: '', //고객명
		contTerm: '', //계약기간
		contYn: '', //계약여부
		asstHead: '', //자산관리머리번호
		saleChg: '', //영업담당자
		userChg: '', //지원담당자
		saleChgNm: '', //영업담당자
		userChgNm: '', //지원담당자
		appUse: 'Y', //결재사용여부
		ordeUse: 'Y', //발주사용여부
		repCustNo: '', //대표고객번호
		mntnYn: '', //유지보수위탁업체여부
		rmk: '', //비고
		delYn: 'N', //삭제여부
		postCode: '', //우편번호
		postAddr: '', //주소
		postSddr: '', //상세주소
		custImg: '', //고객사이미지
		custMainTxt: '', //고객사메인페이지문구
		deptTitle: '0', //부서호칭구분
		spotTitle: '0', //지점호칭구분
		delAskRmk: '', //출고비고
		expAssetSearchYn: 'Y', //만기자산관리사용여부
		closedMallYn: 'N', //폐쇄몰여부
		fileName: '', //법인인감증명서 첨부파일명
		filePath: '', //법인인감증명서 첨부파일 URL
		brFileName: '', //사업자등록증 첨부파일명
		brFilePath: '', //사업자등록증 첨부파일 URL
		commission: 0, //수수료
		alncCustNo: '', //제휴사 고객번호
		fcCode: '', //FC번호

		custNoRead: true, //userId Readonly 여부
		chgYn: false, //입력변경여부
		tranType: 'I', //transaction타입(U:수정 / I:추가 / D:삭제)

		custMode: '1', //고객검색타입(1:고객번호 / 2:대표고객번호 / 3:제휴사고객번호)
		//사용자검색팝업
		userType: '1', //사용자검색타입(1:영업담당자 / 2:지원담당자)

		fileType: '', //파일팝업타입(1: 법인인감증명서 첨부 / 2: 법인인감증명서 첨부 / 3:일반 파일첨부)
		apiYn: '', // 인터페이스 사용 여부
		apiKey: '', // 고객의 고유 키

		spotTitleCodeList: [], // 공통코드 선언
		deptTitleCodeList: [], // 공통코드 선언

		custSearchList: [], // autoComplete를 위한 고객사 리스트 선언
		suggestions: [], // autoComplete props 전달용
	};

	componentDidMount() {
		managerCheck(); // 관리자체크
		ProgressStore.activeProgress();

		//autoComplete를 위한 고객사 리스트 조회
		Promise.all([getAllCompList(), getCisCommCode('16'), getCisCommCode('17')])
			.then((r) => {
				let [data, code16Data, code17Data] = r;

				let suggestions = [];

				for (let i = 0; i < data.length; i++) {
					suggestions.push({ label: data[i].custNm });
				}
				// console.log(suggestions);
				this.setState({
					custSearchList: data,
					suggestions: suggestions,
					spotTitleCodeList: code16Data, // 지점호칭 공통코드
					deptTitleCodeList: code17Data, // 부서호칭 공통코드
				});
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	}

	resetState = (type) => {
		if (type === 'A') {
			// 전체 초기화
			this.setState({
				custList: [], //고객리스트
				tranType: 'U', //transaction타입(U:수정 / I:추가 / D:삭제)
				custNoRead: false, //userId Readonly 여부
			});
			window[this.sheetData.id].RemoveAll(); //ibsheet 데이터 리셋
		} else if (type === 'I') {
			// 신규입력상태
			this.setState({
				tranType: 'I', //transaction타입(U:수정 / I:추가 / D:삭제)
				custNoRead: true, //userId Readonly 여부
			});
		} else if (type === 'U') {
			// 수정가능상태
			this.setState({
				tranType: 'U', //transaction타입(U:수정 / I:추가 / D:삭제)
				custNoRead: false, //userId Readonly 여부
			});
		}
		this.setState({
			//입력항목
			custNo: '', //고객번호
			custNm: '', //고객명
			contTerm: '', //계약기간
			contYn: '', //계약여부
			asstHead: '', //자산관리머리번호
			saleChg: '', //영업담당자
			userChg: '', //지원담당자
			saleChgNm: '', //영업담당자
			userChgNm: '', //지원담당자
			appUse: 'Y', //결재사용여부
			ordeUse: 'Y', //발주사용여부
			repCustNo: '', //대표고객번호
			mntnYn: '', //유지보수위탁업체여부
			rmk: '', //비고
			delYn: 'N', //삭제여부
			postCode: '', //우편번호
			postAddr: '', //주소
			postSddr: '', //상세주소
			custImg: '', //고객사이미지
			custMainTxt: '', //고객사메인페이지문구
			deptTitle: '0', //부서호칭구분
			spotTitle: '0', //지점호칭구분
			delAskRmk: '', //출고비고
			expAssetSearchYn: 'Y', //만기자산관리사용여부
			closedMallYn: 'N', //폐쇄몰여부
			fileIdx: '', // 법인인감증명서 파일아이디
			fileName: '', // 법인인감증명서 파일명
			filePath: '', //법인인감증명서 파일 URL
			brFileIdx: '', // 사업자등록증 파일아이디
			brFileName: '', // 사업자등록증 파일명
			brFilePath: '', //사업자등록증 파일 URL
			chgYn: false, //입력변경여부
			commission: 0, //수수료
			alncCustNo: '', //제휴사고객번호
			fcCode: '', //FC번호
			apiYn: '', // 인터페이스 사용 여부
			apiKey: '', // 고객의 고유번호
			stipulationYn: 'Y', // 약정여부
		});
	};
	handleSearchChange = (value) => {
		this.setState({
			custNmSearch: value,
		});
	};
	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
			chgYn: true, // 변경일어남
		});
	};
	handleChangeNum = (e) => {
		// 숫자만 입력받도록
		const value = e.target.value.replace(/\D/, '');
		this.setState({
			[e.target.name]: value,
			chgYn: true, // 변경일어남
		});
	};

	handleChangePointNumber = (e) => {
		const value = e.target.value;
		this.setState({
			[e.target.name]: value,
			chgYn: true, // 변경일어남
		});
	};
	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
	};
	// 고객사팝업
	handleClickOpen = (popupType) => {
		if (popupType === '1' || popupType === '3') {
			CustSearchPopupStore.openPopup(popupType, null, null, this.handleClose);
		} else if (popupType === '5') {
			//5:제휴사 팝업
			CustSearchPopupStore.openPopup(popupType, null, null, this.handleClose);
		} else if (popupType === '2') {
			//지점 팝업
			//지점은 고객사 팝업 검색 후 가능
			if (this.state.custNo === '') {
				ModalStore.openModal('지점검색은 회사명 검색 이후 가능합니다.');
				return;
			}
			CustSearchPopupStore.openPopup(popupType, null, null, this.handleClose);
		}
	};
	handleClose = (item) => {
		if (item !== undefined) {
			if (CustSearchPopupStore.popupType === '1' || CustSearchPopupStore.popupType === '3') {
				// 고객사 검색 결과
				if (item.custNo !== undefined) {
					if (this.state.custMode === '1') {
						// Customer에서 가져온 custNo가 Comp에 존재하면 alert 뿌리고 추가생성 방지
						let isOk = true;
						this.state.custSearchList.map((comp, idx2) => {
							if (comp.custNo === item.custNo) {
								ModalStore.openModal('해당 고객사는 이미 고객 리스트에 존재합니다.');
								isOk = false;
							}
						});
						if (isOk) {
							this.setState({
								custNo: item.custNo,
								custNm: item.custNm,
								postCode: item.zipCd1,
								postAddr: item.addrM,
								postSddr: item.addrS,
								chgYn: true,
							});
						}
						// this.resetState('A');
					} else if (this.state.custMode === '2') {
						// 고객사(대표고객번호) 검색 결과
						this.setState({
							repCustNo: item.custNo,
							chgYn: true,
						});
						// this.resetState('A');
					} else if (this.state.custMode === '3') {
						//제휴사 고객번호 검색
						this.setState({
							alncCustNo: item.custNo,
							chgYn: true,
						});
					}
				}
			} else if (CustSearchPopupStore.popupType === '2') {
				// 지점 검색 결과
				if (item.addrSeq !== undefined) {
					this.setState({
						addrSeq: item.addrSeq, //지점시퀀스
						spotNm: item.spotNm, //지점명
						spotId: item.spotId, //지점아이디
						postAddr: item.postAddr, //지점주소1
						postSddr: item.postSddr, //지점주소2
						postCode: item.postCode, //지점우편번호
						chgYn: true, // 변경일어남
					});
				}
			} else if (CustSearchPopupStore.popupType === '5') {
				// 제휴사 검색 결과
				if (item.custNo !== undefined) {
					this.setState({
						alncCustNoSearch: item.custNo,
						alncCustNmSearch: item.custNm,
					});
					this.resetState();
				}
			}
		}
	};
	// 고객사팝업 end
	// 사용자팝업
	searchUser = () => {
		UserPopupStore.openPopup('user', this.state.custNo, this.handleUserSearch);
	};
	handleUserSearch = (item) => {
		if (item !== undefined) {
			if (this.state.userType === '1') {
				this.setState({
					saleChg: item.userCode,
					saleChgNm: item.userName,
					chgYn: true,
				});
			} else {
				this.setState({
					userChg: item.userCode,
					userChgNm: item.userName,
					chgYn: true,
				});
			}
		}
	};
	// 사용자팝업 end
	// 파일팝업
	handleClickFileOpen = (fileType) => {
		this.setState({
			fileType: fileType,
		});
		FilePopupStore.openPopup('파일', this.handleFileClose);
	};

	handleFileClose = (result) => {
		if (result !== undefined) {
			if (this.state.fileType == '1') {
				this.setState({
					fileName: result.fileSaveName,
					filePath: result.fileSavePath,
					chgYn: true, //입력변경여부
				});
			} else if (this.state.fileType == '2') {
				this.setState({
					brFileName: result.fileSaveName,
					brFilePath: result.fileSavePath,
					chgYn: true, //입력변경여부
				});
			} else {
				this.setState({
					custImg: result.fileSavePath,
					chgYn: true, //입력변경여부
				});
			}
		}
	};
	// 파일팝업 end
	// 조회된 결과 중 선택한 user input에 셋팅
	setInput = () => {
		let key = window.getSelectedKey();
		if (key !== undefined) {
			this.state.compList.map((item, idx) => {
				if (item.custNo === key) {
					this.setState({
						custNo: item.custNo, //고객번호
						custNm: item.custNm, //고객명
						contTerm: item.contTerm, //계약기간
						contYn: item.contYn, //계약여부
						asstHead: item.asstHead, //자산관리머리번호
						saleChg: item.saleChg, //영업담당자
						userChg: item.userChg, //지원담당자
						saleChgNm: item.saleChgNm, //영업담당자
						userChgNm: item.userChgNm, //지원담당자
						appUse: item.appUse, //결재사용여부
						ordeUse: item.ordeUse, //발주사용여부
						repCustNo: item.repCustNo, //대표고객번호
						mntnYn: item.mntnYn, //유지보수위탁업체여부
						rmk: item.rmk, //비고
						postCode: item.postCode, //우편번호
						postAddr: item.postAddr, //주소
						postSddr: item.postSddr, //상세주소
						custImg: item.custImg, //고객사이미지
						custMainTxt: item.custMainTxt, //고객사메인페이지문구
						delYn: item.delYn, //삭제여부
						deptTitle: item.deptTitle, //부서호칭구분
						spotTitle: item.spotTitle, //지점호칭구분
						delAskRmk: item.delAskRmk, //출고비고
						expAssetSearchYn: item.expAssetSearchYn, //만기자산관리사용여부
						closedMallYn: item.closedMallYn, //폐쇄몰여부
						fileIdx: item.fileIdx, //법인인감증명서 파일아이디
						fileName: item.fileName, //법인인감증명서 파일이름
						filePath: item.filePath, //법인인감증명서 파일 URL
						brFileIdx: item.brFileIdx, //사업자등록증 파일아이디
						brFileName: item.brFileName, //사업자등록증 파일이름
						brFilePath: item.brFilePath, //사업자등록증 파일 URL
						chgYn: false, //입력변경여부
						tranType: 'U', //transaction타입 수정
						custNoRead: false, //userid입력
						commission: item.commission, //제휴사 수수료
						alncCustNo: item.alncCustNo, //제휴사 고객번호
						fcCode: item.fcCode, //FC 번호
						apiYn: item.apiYn, // 인터페이스 사용 여부
						apiKey: item.apiKey, // 고객의 고유번호
						stipulationYn: item.stipulationYn, // 약정여부
					});
				}
			});
		}
	};
	// 사용자 추가할 수 있도록 readonly 풀고 state 리셋 후 추가상태로 셋팅
	setForAdd = () => {
		this.resetState('I'); //입력가능상태
		this.custNo.focus();
	};
	// 삭제
	callDel = () => {
		if (this.state.custNo === '') {
			ModalStore.openModal('고객사를 먼저 선택하세요.');
			return;
		}
		ConfirmModalStore.openModal('고객사를 삭제하시겠습니까?', this.deleteComp);
	};

	//조회
	getCompList = () => {
		ProgressStore.activeProgress();
		let param = {
			custNm: this.state.custNmSearch,
			alncCustNoSearch: this.state.alncCustNoSearch,
		};

		getCompList(param)
			.then((r) => {
				this.setState({ compList: r }, () => {
					// JSON객체형태로 서버에서 받으므로 IBsheet에서 출력 가능한 배열형태로 변환
					window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				});

				this.resetState('I');
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};
	// 저장
	saveComp = () => {
		//validation chk
		if (this.state.tranType === 'U' && !this.state.chgYn) {
			// 수정상태에서 변경 없을 시
			ModalStore.openModal('변경사항이 없습니다.');
			return;
		}
		if (this.state.custNo === '') {
			ModalStore.openModal('고객사를 먼저 검색하세요.');
			this.custNo.focus();
			return;
		}
		if (this.state.ordeUse === 'Y' && this.state.saleChg === '') {
			ModalStore.openModal('영업담당자를 먼저 검색하세요.');
			this.saleChgNm.focus();
			return;
		}
		if (this.state.ordeUse === 'Y' && this.state.userChg === '') {
			ModalStore.openModal('지원담당자를 먼저 검색하세요.');
			this.userChgNm.focus();
			return;
		}
		if (this.state.contTerm === '') {
			ModalStore.openModal('계약기간을 입력하세요');
			this.contTerm.focus();
			return;
		}
		// validation chk end
		ProgressStore.activeProgress();

		let param = {
			custNo: this.state.custNo, //고객번호
			custNm: this.state.custNm, //고객명
			contTerm: this.state.contTerm, //계약기간
			contYn: this.state.contYn === '' ? 'N' : this.state.contYn, //계약여부
			asstHead: this.state.asstHead, //자산관리머리번호
			saleChg: this.state.saleChg, //영업담당자
			userChg: this.state.userChg, //지원담당자
			appUse: this.state.appUse === '' ? 'N' : this.state.appUse, //결재사용여부
			ordeUse: this.state.ordeUse === '' ? 'N' : this.state.ordeUse, //발주사용여부
			repCustNo: this.state.repCustNo, //대표고객번호
			mntnYn: this.state.mntnYn === '' ? 'N' : this.state.mntnYn, //유지보수위탁업체여부
			rmk: this.state.rmk, //비고
			custImg: this.state.custImg, //고객사이미지
			custMainTxt: this.state.custMainTxt, //고객사메인페이지문구
			delYn: 'N', //삭제여부
			deptTitle: this.state.deptTitle === '' ? '0' : this.state.deptTitle, //부서호칭구분
			spotTitle: this.state.spotTitle === '' ? '0' : this.state.spotTitle, //지점호칭구분
			delAskRmk: this.state.delAskRmk, //출고비고
			expAssetSearchYn: this.state.expAssetSearchYn === '' ? 'N' : this.state.expAssetSearchYn, //만기자산관리사용여부
			closedMallYn: this.state.closedMallYn === '' ? 'N' : this.state.closedMallYn, //폐쇄몰여부
			tranType: this.state.tranType, //transaction 타입
			commission: this.state.commission, //수수료
			alncCustNo: this.state.alncCustNo, //제휴사 고객번호
			fileIdx: this.state.fileIdx, // 법인인감증명서 파일 아이디
			fileName: this.state.fileName, // 법인인감증명서 이름
			filePath: this.state.filePath, // 법인인감증명서 경로
			brFileIdx: this.state.brFileIdx, // 사업자등록증 파일 아이디
			brFileName: this.state.brFileName, // 사업자등록증 이름
			brFilePath: this.state.brFilePath, // 사업자등록증 경로
			fcCode: this.state.fcCode, //FC번호
			apiYn: this.state.apiYn, // 인터페이스 사용 여부
			apiKey: this.state.apiKey, // 고유 고객번호
			stipulationYn: this.state.stipulationYn === '' ? 'Y' : this.state.stipulationYn, // 약정여부
		};

		saveComp(param)
			.then((r) => {
				// console.log(r);console.log(r.length);console.log(r[0]);
				ModalStore.openModal('저장되었습니다.');

				this.getCompList(); //저장 성공 후 재조회
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};
	// 삭제
	deleteComp = () => {
		ProgressStore.activeProgress();

		let param = {
			custNo: this.state.custNo, //고객번호
			delYn: 'Y', //삭제여부
			tranType: 'D', //transaction 타입
		};

		saveComp(param)
			.then((r) => {
				// console.log(r);console.log(r.length);console.log(r[0]);
				ModalStore.openModal('삭제되었습니다.');

				this.getCompList(); //저장 성공 후 재조회
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	handleFocus = (e) => {
		e.target.select();
		let url = e.target.value;
		if (url !== undefined && url !== '') {
			window.open(url, '_blank');
		}
	};
	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mb30">
								<h2>고객관리</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.getCompList}>
										<span>조회</span>
									</a>
									<a className="btn01 add" onClick={this.setForAdd}>
										<span>추가</span>
									</a>
									{/* <a className="btn01 save"><span>저장</span></a> */}
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '10%' }} />
										<col style={{ width: '30%' }} />
										<col style={{ width: '10%' }} />
										<col style={{ width: '28%' }} />
										<col style={{ width: '10%' }} />
										<col style={{ width: '12%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>고객사명</th>
											<td>
												<div
													onKeyDown={(e) => {
														if (e.key === 'Enter') this.getCompList();
													}}
												>
													<AutoComplete placeholder="고객사명을 입력하세요" handleSearchChange={this.handleSearchChange} suggestions={this.state.suggestions} />
												</div>
												{/* 고객사검색버튼 */}
												{/* <IconButton aria-label="Search" className="iconButton" onClick={() => this.handleClickOpen('1')} style={{display:sessionStorage.btnControl}}><Search fontSize="small"/></IconButton> */}
											</td>
											<th>제휴사</th>
											<td>
												<input
													type="text"
													className="size01"
													ref={(ref) => (this.alncCustNmSearch = ref)}
													name="alncCustNmSearch"
													value={this.state.alncCustNmSearch || ''}
													onChange={this.handleChange}
													readOnly
												/>
												{/* 제휴사검색버튼 */}
												<IconButton
													aria-label="Search"
													className="iconButton"
													onClick={() => this.handleClickOpen('5')}
													style={{ display: sessionStorage.btnControl }}
												>
													<Search fontSize="small" />
												</IconButton>
												<IconButton
													aria-label="Clear"
													className="iconButton"
													onClick={() => this.setState({ alncCustNoSearch: '', alncCustNmSearch: '' })}
													style={{ display: sessionStorage.btnControl }}
												>
													<Clear fontSize="small" />
												</IconButton>
											</td>
											<td></td>
											<td></td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="writeType05 long3">
								<div className="left" onClick={this.setInput}>
									<div
										className="scroll"
										onMouseOver={() => {
											document.body.style.overflow = 'hidden';
										}}
										onMouseOut={() => {
											document.body.style.overflow = 'auto';
										}}
									>
										<p className="headline">고객 리스트</p>
										<IBSheet {...this.sheetData} />
									</div>
								</div>
								<div className="right">
									<p className="headline">고객 정보</p>
									<table>
										<colgroup>
											<col style={{ width: '20%' }} />
											<col style={{ width: '30%' }} />
											<col style={{ width: '20%' }} />
											<col style={{ width: '30%' }} />
										</colgroup>
										<tbody>
											<tr>
												<th>
													고객번호 <span className="star">*</span>
												</th>
												<td>
													<input
														type="text"
														className="size01"
														name="custNo"
														maxLength="7"
														value={this.state.custNo || ''}
														readOnly
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.custNo = ref)}
													/>
													<IconButton
														aria-label="Search"
														className="iconButton"
														onClick={() => {
															this.handleClickOpen('3');
															this.setState({ custMode: '1' });
														}}
														style={!this.state.custNoRead ? { display: 'none' } : { display: 'inline-block' }}
													>
														<Search fontSize="small" />
													</IconButton>
												</td>
												<th>대표고객번호 </th>
												<td>
													<input
														type="text"
														className="size01"
														name="repCustNo"
														maxLength="7"
														value={this.state.repCustNo || ''}
														onChange={(e) => {
															this.handleChange(e);
															this.setState({ repCustNo: '' });
														}}
														ref={(ref) => (this.repCustNo = ref)}
													/>
													<IconButton
														aria-label="Search"
														className="iconButton"
														onClick={() => {
															this.handleClickOpen('3');
															this.setState({ custMode: '2' });
														}}
													>
														<Search fontSize="small" />
													</IconButton>
												</td>
											</tr>
											<tr>
												<th>
													고객명 <span className="star">*</span>
												</th>
												<td>
													<input
														type="text"
														name="custNm"
														maxLength="20"
														value={this.state.custNm || ''}
														onChange={this.handleChange}
														ref={(ref) => (this.custNm = ref)}
														readOnly
													/>
												</td>
												<th>
													계약기간 <span className="star">*</span>
												</th>
												<td>
													<input
														type="text"
														className="size02"
														name="contTerm"
														maxLength="2"
														value={this.state.contTerm || ''}
														onChange={this.handleChangeNum}
														ref={(ref) => (this.contTerm = ref)}
													/>
												</td>
											</tr>
											<tr>
												<th>주소 </th>
												<td colSpan="3">
													<input
														type="text"
														name="postCode"
														className="size04"
														maxLength="6"
														value={this.state.postCode || ''}
														onChange={(e) => {
															this.handleChangeNum(e);
														}}
														ref={(ref) => (this.postCode = ref)}
														readOnly
													/>
													<input
														type="text"
														name="postAddr"
														className="size03"
														maxLength="50"
														value={this.state.postAddr || ''}
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.postAddr = ref)}
														readOnly
													/>
													<input
														type="text"
														name="postSddr"
														className="size03"
														maxLength="50"
														value={this.state.postSddr || ''}
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.postSddr = ref)}
														readOnly
													/>
												</td>
											</tr>
											<tr>
												<th>
													영업담당자 <span className="star">*</span>
												</th>
												<td>
													<input
														type="text"
														className="size01"
														name="saleChgNm"
														maxLength="25"
														value={this.state.saleChgNm || ''}
														onChange={this.handleChange}
														ref={(ref) => (this.saleChgNm = ref)}
														readOnly
													/>
													<IconButton
														aria-label="Search"
														className="iconButton"
														onClick={() => {
															this.searchUser();
															this.setState({ userType: '1' });
														}}
													>
														<Search fontSize="small" />
													</IconButton>
												</td>
												<th>
													지원담당자 <span className="star">*</span>
												</th>
												<td>
													<input
														type="text"
														className="size01"
														name="userChgNm"
														maxLength="25"
														value={this.state.userChgNm || ''}
														onChange={this.handleChange}
														ref={(ref) => (this.userChgNm = ref)}
														readOnly
													/>
													<IconButton
														aria-label="Search"
														className="iconButton"
														onClick={() => {
															this.searchUser();
															this.setState({ userType: '2' });
														}}
													>
														<Search fontSize="small" />
													</IconButton>
												</td>
											</tr>
											<tr>
												<th>
													결재사용여부 <span className="star">*</span>
												</th>
												<td>
													<select name="appUse" value={this.state.appUse} onChange={this.handleChange}>
														<option value="N">N</option>
														<option value="Y">Y</option>
													</select>
												</td>
												<th>
													발주사용여부 <span className="star">*</span>
												</th>
												<td>
													<select name="ordeUse" value={this.state.ordeUse} onChange={this.handleChange}>
														<option value="N">N</option>
														<option value="Y">Y</option>
													</select>
												</td>
											</tr>
											<tr>
												<th>
													유지보수
													<br />
													위탁업체여부 <span className="star">*</span>
												</th>
												<td>
													<select name="mntnYn" value={this.state.mntnYn} onChange={this.handleChange}>
														<option value="N">N</option>
														<option value="Y">Y</option>
													</select>
												</td>
												<th>지점호칭구분 </th>
												<td>
													<select name="spotTitle" value={this.state.spotTitle} onChange={this.handleChange}>
														{this.state.spotTitleCodeList ? buildSelectItems(this.state.spotTitleCodeList) : null}
													</select>
												</td>
											</tr>
											<tr>
												<th>약정여부 </th>
												<td>
													<select name="stipulationYn" value={this.state.stipulationYn} onChange={this.handleChange}>
														<option value="Y">약정</option>
														<option value="N">무약정</option>
													</select>
												</td>
												<td></td><td></td>
											</tr>
											<tr>
												<th>비고 </th>
												<td colSpan="3">
													<input
														type="text"
														name="rmk"
														maxLength="25"
														value={this.state.rmk || ''}
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.rmk = ref)}
													/>
												</td>
											</tr>
										</tbody>
									</table>
									<p className="headline">기타 고객 정보</p>
									<table>
										<colgroup>
											<col style={{ width: '20%' }} />
											<col style={{ width: '30%' }} />
											<col style={{ width: '20%' }} />
											<col style={{ width: '30%' }} />
										</colgroup>
										<tbody>
											<tr>
												<th>
													부서호칭구분 <span className="star">*</span>
												</th>
												<td>
													<select name="deptTitle" value={this.state.deptTitle} onChange={this.handleChange}>
														{this.state.deptTitleCodeList ? buildSelectItems(this.state.deptTitleCodeList) : null}
													</select>
												</td>
												<th>
													만기자산관리
													<br />
													사용여부 <span className="star">*</span>
												</th>
												<td>
													<select name="expAssetSearchYn" value={this.state.expAssetSearchYn} onChange={this.handleChange}>
														<option value="N">N</option>
														<option value="Y">Y</option>
													</select>
												</td>
											</tr>
											<tr>
												<th>출고비고 </th>
												<td colSpan="3">
													<textarea
														className="size01"
														name="delAskRmk"
														maxLength="500"
														value={this.state.delAskRmk || ''}
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.delAskRmk = ref)}
													/>
												</td>
											</tr>
										</tbody>
									</table>
									<p className="headline">Mall 정보</p>
									<table>
										<colgroup>
											<col style={{ width: '20%' }} />
											<col style={{ width: '30%' }} />
											<col style={{ width: '20%' }} />
											<col style={{ width: '30%' }} />
										</colgroup>
										<tbody>
											<tr>
												<th>제휴사 수수료</th>
												<td>
													총 금액의{' '}
													<input
														type="text"
														name="commission"
														className="size03"
														style={{ width: '40%', textAlign: 'right', paddingRight: 5 }}
														value={this.state.commission}
														onChange={(e) => {
															this.handleChangePointNumber(e);
														}}
														ref={(ref) => (this.commission = ref)}
													/>
													%
												</td>
												<th>제휴사고객번호</th>
												<td>
													<input
														type="text"
														className="size01"
														name="alncCustNo"
														maxLength="7"
														value={this.state.alncCustNo || ''}
														onChange={(e) => {
															this.handleChange(e);
															this.setState({ alncCustNo: '' });
														}}
														ref={(ref) => (this.alncCustNo = ref)}
													/>
													<IconButton
														aria-label="Search"
														className="iconButton"
														onClick={() => {
															this.handleClickOpen('3');
															this.setState({ custMode: '3' });
														}}
													>
														<Search fontSize="small" />
													</IconButton>
												</td>
											</tr>
											<tr>
												<th>
													폐쇄몰 여부 <span className="star">*</span>
												</th>
												<td>
													<select name="closedMallYn" value={this.state.closedMallYn} onChange={this.handleChange}>
														<option value="N">N</option>
														<option value="Y">Y</option>
													</select>
												</td>
												<th>법인인감증명서</th>
												<td>
													{/*{this.state.filePath ? 
                                                    <a href={this.state.filePath} target="_blank" name="file" style={{fontSize:10}}>{this.state.fileName}</a>
                                                    : <a name="file">첨부파일없음</a>
                                                    }*/}
													<a href={this.state.filePath} target="_blank">
														{' '}
														<input
															type="text"
															className="size01"
															name="file"
															style={{ fontSize: 10 }}
															value={this.state.fileName || ''}
															onChange={(e) => {
																this.handleChange(e);
															}}
															ref={(ref) => (this.fileName = ref)}
															readOnly
														/>{' '}
													</a>
													<IconButton
														aria-label="Search"
														className="iconButton"
														onClick={() => {
															this.handleClickFileOpen('1');
														}}
													>
														<Search fontSize="small" />
													</IconButton>
												</td>
												<td></td>
											</tr>
											<tr>
												<th>고객 식별번호</th>
												<td>
													<input
														type="text"
														name="fcCode"
														maxLength="20"
														value={this.state.fcCode || ''}
														onChange={this.handleChange}
														ref={(ref) => (this.fcCode = ref)}
													/>
												</td>
												<th>사업자등록증</th>
												<td>
													{/*{this.state.filePath ? 
                                                    <a href={this.state.filePath} target="_blank" name="file" style={{fontSize:10}}>{this.state.fileName}</a>
                                                    : <a name="file">첨부파일없음</a>
                                                    }*/}
													<a href={this.state.brFilePath} target="_blank">
														{' '}
														<input
															type="text"
															className="size01"
															name="file"
															style={{ fontSize: 10 }}
															value={this.state.brFileName || ''}
															onChange={(e) => {
																this.handleChange(e);
															}}
															ref={(ref) => (this.brFileName = ref)}
															readOnly
														/>{' '}
													</a>
													<IconButton
														aria-label="Search"
														className="iconButton"
														onClick={() => {
															this.handleClickFileOpen('2');
														}}
													>
														<Search fontSize="small" />
													</IconButton>
												</td>
												<td></td>
											</tr>
										</tbody>
									</table>
									<p className="headline">API 관리</p>
									<table>
										<colgroup>
											<col style={{ width: '20%' }} />
											<col style={{ width: '30%' }} />
											<col style={{ width: '20%' }} />
											<col style={{ width: '30%' }} />
										</colgroup>
										<tbody>
											<tr>
												<th>
													인터페이스
													<br />
													사용여부
												</th>
												<td>
													<select name="apiYn" value={this.state.apiYn} onChange={this.handleChange}>
														<option value="N">N</option>
														<option value="Y">Y</option>
													</select>
												</td>

												<th>고유 고객번호</th>
												<td>
													<input type="text" name="apiKey" readOnly value={this.state.apiKey || ''}></input>
												</td>
											</tr>
										</tbody>
									</table>
									<p className="headline">로그인화면 관리</p>
									<table>
										<colgroup>
											<col style={{ width: '20%' }} />
											<col style={{ width: '30%' }} />
											<col style={{ width: '20%' }} />
											<col style={{ width: '30%' }} />
										</colgroup>
										<tbody>
											<tr>
												<th>고객사로고 </th>
												<td colSpan="3">
													<input
														type="text"
														className="size01"
														name="custImg"
														value={this.state.custImg || ''}
														onClick={this.handleFocus}
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.custImg = ref)}
														readOnly
													/>
													<IconButton
														aria-label="Search"
														className="iconButton"
														onClick={() => {
															this.handleClickFileOpen('3');
														}}
													>
														<Search fontSize="small" />
													</IconButton>
												</td>
											</tr>
											<tr>
												<th>로그인화면문구 </th>
												<td colSpan="3">
													<input
														type="text"
														name="custMainTxt"
														maxLength="25"
														value={this.state.custMainTxt || ''}
														onChange={(e) => {
															this.handleChange(e);
														}}
														ref={(ref) => (this.custMainTxt = ref)}
													/>
												</td>
											</tr>
										</tbody>
									</table>
									<div className="btnRight2">
										<Button className="button" onClick={this.saveComp} color="primary" variant="contained" size="small">
											<SaveIcon className="saveIcon" />
											<span className="text">저장</span>
										</Button>
										<Button className="button" onClick={this.callDel} color="secondary" variant="contained" size="small">
											<DeleteIcon className="saveIcon" />
											<span className="text">삭제</span>
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(CustManager);
