import { httpGet, httpPost, httpPut, toQueryString } from './Service';

//로그인, spring 로그인 처리 방식의 제약 때문에 Content-Type이  'application/x-www-form-urlencoded'이고 body 에는 json이 아닌 querystring 사용
export function userLogin(data) {
	data = data || {};
	let localHeader = {};
	localHeader['Content-Type'] = 'application/x-www-form-urlencoded';
	return httpPost('/api/v1.0/cis/login', { headers: localHeader, body: toQueryString(data).replace('?', '') });
}

// id찾기
export function getUserId(data) {
	return httpGet('/api/v1.0/cis/user/find-id/' + toQueryString(data));
}

// pw찾기
export function getUserPw(data) {
	return httpGet('/api/v1.0/cis/user/find-password/' + toQueryString(data));
}

// 인증번호전송
export function sendAuthNum(data) {
	return httpGet('/api/v1.0/cis/user/send-authNum/' + toQueryString(data));
}

// id중복체크
export function getUserCnt(userId) {
	return httpGet('/api/v1.0/cis/user/check-id/' + userId);
}

// id중복체크2
export function getUserCnt2(data) {
	return httpGet('/api/v1.0/cis/user/check-id2/' + toQueryString(data));
}

// 회원가입_고객사조회
export function getCustList(data) {
	return httpGet('/api/v1.0/cis/user/get-cust/' + toQueryString(data));
}

// 회원가입_지점조회
export function getSpotList(data) {
	return httpGet('/api/v1.0/cis/user/get-spot/' + toQueryString(data));
}

// 회원가입
export function insertJoinRequest(data) {
	data = data || {};
	return httpPost('/api/v1.0/cis/user/', { body: data });
}

//공통코드 조회
export function getCisCommCode(codeNo) {
	return httpGet('/api/v1.0/cis/comm/' + codeNo);
}

//메뉴리스트조회
export function getLoginMenuList(data) {
	data.mobileOnly = 'N'; //웹에서는 mobile 메뉴는 생략
	return httpGet('/api/v1.0/cis/user/get-menu/' + toQueryString(data));
}

//고객사리스트 조회
export function getAllCompList() {
	return httpGet('/api/v1.0/cis/get-comp/');
}

// 고객사(Customer테이블기준)조회
export function getCustomerList(data) {
	return httpGet('/api/v1.0/cis/user/get-customer/' + toQueryString(data));
}

//홈배지 카운트 조회
export function getHeaderBadgeCount(data) {
	data = data || {};
	return httpGet('/api/v1.0/cis/header-badge-cnt' + toQueryString(data));
}

//렌탈 제품 대분류 조회
export function getRentalItemGroup(data) {
	return httpGet('/api/v1.0/rental/item/item-group' + toQueryString(data));
}

//부서별 타입 조회
export function getDeprItemTypeList(deptCode) {
	return httpGet('/api/v1.0/rental/ifrs-type/' + deptCode);
}

//ifrs 코드 조회
export function getIfrsBoardList(data) {
	return httpGet('/api/v1.0/rental/ifrs-list' + toQueryString(data));
}

//로그인페이지 이미지, 문구 조회
export function getCustImgTxt(data) {
	return httpGet('/api/v1.0/cis/user/get-imgTxt/' + toQueryString(data));
}

//ERP공통코드 조회
export function getRentalCommCode(codeNo) {
	return httpGet('/api/v1.0/rental/comm/' + codeNo);
}

//ERP 고객별 계약 유형
export function getRentalCustContType(custNo) {
	return httpGet('/api/v1.0/rental/cust/contType/' + custNo);
}

// 교원 인증키 가져오기
export function getApiKywonKey(data) {
	return httpPost('/api/out/kywon/key' + toQueryString(data));
}

// 권한 존재여부 체크. 미사용임...
export function getAuthCompCheck(data) {
	return httpPost('/api/v1.0/cis/commAuth/authCompCheck' + toQueryString(data));
}

// 고객 커스터마이징 데이터
export function getCustomData(data) {
	return httpGet('/api/v1.0/cis/customize/customData' + toQueryString(data));
}
