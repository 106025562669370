import React, { Component } from 'react';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';
import { errorMsgCheck, resultToIbSheet, managerCheck, SheetParamData3 } from '../../common/ComFunction';
import { getList, save, getDtlList, saveDtl } from '../../service/admin/CommCodeService';
import Header from '../include/Header';

var result = {
	//sheet데이터
	data: [],
};
import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ProgressStore } = CommonStore;
class CommCodeManager extends Component {
	constructor(props) {
		super(props);

		this.sheetData = {
			id: 'commCodeSheet', // sheet id
			initData: 'admin/Init-CommCodeManager', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '250px', // sheet height
		};
		this.sheetData2 = {
			id: 'commCodeDtlSheet', // sheet id
			initData: 'admin/Init-CommCodeDtl', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '250px', // sheet height
		};
	}

	state = {
		//조회결과
		commCodeList: [], //대분류리스트
		commCodeDtlList: [], //중분류리스트

		//검색조건
		groupCode: '', //그룹코드
		selectedGrpCd: '', //선택된 대분류 코드

		chgYn: false, //입력변경여부
	};
	handleChangeSearch = (e) => {
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
		});
	};
	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
			chgYn: true, // 변경일어남
		});
	};
	handleChangeNum = (e) => {
		// 숫자만 입력받도록
		const value = e.target.value.replace(/\D/, '');
		this.setState({
			[e.target.name]: value,
			chgYn: true, // 변경일어남
		});
	};
	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
		window[this.sheetData2.id].DisposeSheet();
	};
	componentDidMount = () => {
		managerCheck(); // 관리자체크
		this.getList();
	};

	//대분류 코드 리스트 조회
	getList = () => {
		ProgressStore.activeProgress();
		let param = {
			groupCode: this.state.groupCode,
		};

		getList(param)
			.then((r) => {
				this.resetResult(); //ibsheet 데이터 리셋
				this.resetDtlResult(); //ibsheet 데이터 리셋

				this.setState({ commCodeList: r, selectedGrpCd: '' }, () => {
					// JSON객체형태로 서버에서 받으므로 IBsheet에서 출력 가능한 배열형태로 변환
					const timer = setInterval(() => {
						// 마운트 되자마자 바로 LoadSearchData 시도하면 sheet 로딩 전에 시도할 수 있으므로 interval로 sheet 로딩 후 처리하는 방식
						if (window[this.sheetData.id]) {
							window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
							clearInterval(timer);
						}
					}, 100);
				});
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};
	//중분류 코드 리스트 조회
	getDtlList = () => {
		ProgressStore.activeProgress();
		let param = {
			groupCode: this.state.selectedGrpCd,
		};
		getDtlList(param)
			.then((r) => {
				this.resetDtlResult(); //ibsheet 데이터 리셋
				this.setState({ commCodeDtlList: r }, () => {
					// JSON객체형태로 서버에서 받으므로 IBsheet에서 출력 가능한 배열형태로 변환
					window[this.sheetData2.id].LoadSearchData(resultToIbSheet(r));
				});

				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			});
	};
	// sheet데이터 리셋
	resetResult = () => {
		result = {
			data: [],
		};
		window[this.sheetData.id].LoadSearchData(result, {
			Sync: 1,
		});
	};

	resetDtlResult = () => {
		result = {
			data: [],
		};
		window[this.sheetData2.id].LoadSearchData(result, {
			Sync: 1,
		});
	};

	addCommCode = () => {
		const sheet = window[this.sheetData.id];
		let newRow = sheet.DataInsert(-1);
		this.resetDtlResult();
		this.setState({ selectedGrpCd: '' });
	};
	addCommCodeDtl = () => {
		if (!this.state.selectedGrpCd) return;
		const sheet = window[this.sheetData2.id];
		let newRow = sheet.DataInsert(-1);
		sheet.SetCellValue(newRow, 'commCodeGrp', this.state.selectedGrpCd);
	};

	// 저장
	save = () => {
		// validation chk end
		ProgressStore.activeProgress();
		const sheet = window[this.sheetData.id];

		let sheetParam = SheetParamData3(sheet, null, true);
		if (sheetParam && sheetParam.data.length > 0 && sheetParam.Message != 'KeyFieldError') {
			save(sheetParam)
				.then((r) => {
					ModalStore.openModal('저장되었습니다.');

					this.resetResult(); //ibsheet 데이터 리셋
					this.resetDtlResult(); //ibsheet 데이터 리셋
					this.getList(); //저장 성공 후 재조회
					ProgressStore.deactiveProgress();
				})
				.catch((err) => {
					errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
				});
		} else {
			ProgressStore.deactiveProgress();
		}
	};

	// 상세저장
	saveDtl = () => {
		// validation chk end
		ProgressStore.activeProgress();
		const sheet = window[this.sheetData2.id];

		let sheetParam = SheetParamData3(sheet, null, true);
		if (sheetParam && sheetParam.data.length > 0 && sheetParam.Message != 'KeyFieldError') {
			saveDtl(sheetParam)
				.then((r) => {
					ModalStore.openModal('저장되었습니다.');

					this.resetDtlResult(); //ibsheet 데이터 리셋
					this.getDtlList(); //저장 성공 후 재조회
					ProgressStore.deactiveProgress();
				})
				.catch((err) => {
					errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
				});
		} else {
			ProgressStore.deactiveProgress();
		}
	};

	setGrpCd = () => {
		let data = window.getSelCommCodeData();

		if (data.col != 3) return;
		this.setState(
			{
				selectedGrpCd: data.commCode,
			},
			() => {
				this.getDtlList();
			},
		);
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mb30">
								<h2>공통코드관리</h2>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '15%' }} />
										<col style={{ width: '20%' }} />
										<col style={{ width: '15%' }} />
										<col style={{ width: '50%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>공통코드</th>
											<td>
												<input
													type="text"
													ref={(ref) => (this.groupCode = ref)}
													name="groupCode"
													value={this.state.groupCode || ''}
													onKeyDown={(e) => {
														if (e.key === 'Enter') this.getList();
													}}
													onChange={this.handleChange}
													style={{ marginRight: 10, width: 160 }}
												/>
											</td>
											<td></td>
											<td></td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="btnRight3">
								<a className="btn05 search" onClick={this.getList}>
									<span>조회</span>
								</a>
								<a className="btn04 add" onClick={this.addCommCode}>
									<span>추가</span>
								</a>
								<a className="btn05 save" onClick={this.save}>
									<span>저장</span>
								</a>
							</div>

							<div className="writeType05 short">
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
									onClick={this.setGrpCd}
								>
									<p className="headline">대분류</p>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
							<div className="btnRight3">
								<a className="btn04 add" onClick={this.addCommCodeDtl}>
									<span>추가</span>
								</a>
								<a className="btn05 save" onClick={this.saveDtl}>
									<span>저장</span>
								</a>
							</div>
							<div className="writeType05 short">
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">중분류</p>
									<div>
										<IBSheet {...this.sheetData2} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(CommCodeManager);
