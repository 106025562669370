import React, { Component } from 'react';
import IBSheet from '../IBSheet';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import {
	SheetParamData,
	numberCheck,
	createItemIcon,
	errorMsgCheck,
	resultToIbSheet,
	excelFormDownload,
	getCustomDataList,
	mailCheck,
	isEmpty,
} from '../../common/ComFunction';
import {
	insertRentOrder,
	getCustAppUserList,
	getCustOrderItemList,
	getOrderDetail,
	getOrderItemList,
	updateRentOrder,
	getOrderItemCombList,
} from '../../service/rental/RentalOrderService';
import RentalOrderExcel from './RentalOrderExcel';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { getCisCommCode, getCustomData } from '../../service/CommonService';
import { FormControlLabel } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { RadioGroup } from '@material-ui/core';
import { Radio } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore, PostPopupStore, RentalOrderItemCombPopStore } = CommonStore;

let ordePrpsCodeList; // 공통코드 선언

class RentalOrder extends Component {
	constructor(props) {
		super(props);

		this.state = {
			excelUploadModal: false, //엑셀업로드모달

			appList: [], //결재자리스트
			itemList: [], //신청제품 리스트

			orderSameChk: true, //배송정보 신청자 동일
			selfInputChk: false, //배송정보 직접입력
			spotSearchChk: false, //지점정보 가져오기
			readOnly: true, //읽기전용
			appInfoDisplay: 'none', //결재정보 보여주기
			updateTitle: '', //수정시 보여질 문구

			orderCustNo: '', //신청고객코드
			orderCustNm: '', //신청고객명
			orderSpotNm: '', //신청지점명
			orderUserId: '', //신청자ID
			orderUser: '', //신청자
			orderStatus: '', //신청상태]

			ordeNo: '', //요청번호
			addrSeq: '', //지점시퀀스
			spotId: '', //지점코드
			spotNm: '', //지점명
			userDeptId: '', //사용자지점코드 (흥국:대리점코드)
			delUserNm: '', //수령인명
			delDeptNm: '', //수령인부서명
			delTelNo: '', //수령인전화번호
			delMobileNo: '', //수령인휴대폰번호
			rqstMail: '', //신청자 메일주소
			delPostCode: '', //우편번호
			delAddr: '', //주소1
			delAddrDtl: '', //상세주소
			delRemark: '', //배송요청사항
			ordePrps: '', // 신청목적

			custPordeNo: '', //고객사 관리번호 (고객사에서 관리하는 관리번호)

			inProgress: false, // 중복저장방지용

			// render 시 보여지는 화면 설정
			regOrdeMsg: '',
			userTitle: '',
			inputSelectDp: '',
			daerijumCodeDp: '',
			deptTitleDp: '',
			custPordeNoDp: '',
			delInfoMarginTop: '',
			excelBigOrdeDp: 'none',
			rqstMailDp: 'none',
			realUserInfoMsg: '',
			ordePrpsDp: '',
			customData: [],
		};

		this.orderItemSheet = {
			id: 'rentalOrderSheet', // sheet id
			initData: 'rental/Init-RentalOrder_item', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '272px', // sheet height
		};
	}

	componentDidMount = () => {
		ProgressStore.activeProgress();

		getCustomDataList();

		Promise.all([getCisCommCode('25')])
			.then((r) => {
				let [code25Data] = r;
				ordePrpsCodeList = code25Data; // 권한 공통코드
			})
			.catch((err) => {
				errorMsgCheck(err);
			});

		let ordeNo = this.props.match.params.ordeNo;

		//요청번호가 있으면 수정모드
		if (ordeNo) {
			Promise.all([getOrderDetail(ordeNo), getOrderItemList(ordeNo)])
				.then((r) => {
					let [data1, data2] = r;

					this.setState({
						ordeNo: ordeNo,
						updateTitle: '수정', //타이틀을 수정으로 변경한다.
					});

					//신청정보셋팅
					this.setUserData(data1[0]);
					//신청 제품 리스트 셋팅

					var sheetIntvl = setInterval(() => {
						if (window[this.orderItemSheet.id] !== undefined) {
							window[this.orderItemSheet.id].LoadSearchData(resultToIbSheet(data2));
							ProgressStore.deactiveProgress();
							clearInterval(sheetIntvl);
						}
					}, 100);

					//결재 리스트와 제품리스트를 가져온다.
					let param = {
						custNo: data1[0].custNo,
						ordeNo: data1[0].ordeNo,
					};

					Promise.all([getCustAppUserList(param), getCustOrderItemList(param)])
						.then((r) => {
							let [appList, itemList] = r;

							this.setState({
								appInfoDisplay: appList.length === 0 ? 'none' : 'block',
								appList: appList,
								itemList: itemList,
							});
						})
						.catch((err) => {
							errorMsgCheck(err);
						});
				})
				.catch((err) => {
					errorMsgCheck(err);
				});
		} else {
			let param = {
				custNo: sessionStorage.custNo,
				addrSeq: sessionStorage.addrSeq !== '' ? sessionStorage.addrSeq : null,
			};

			Promise.all([getCustAppUserList(param), getCustOrderItemList(param)])
				.then((r) => {
					let [appList, itemList] = r;

					this.setState({
						appInfoDisplay: appList.length === 0 || sessionStorage.authCode === '2' ? 'none' : 'block',
						appList: appList,
						itemList: itemList,
					});
					//신청자 정보 설정
					this.setUserData(null);
					// 20210531 현대엔지니어링은 직접입력모드 고정
					// if (sessionStorage.custNo === CUST_NO_HDENG) {
					// 	this.selectShipMethod(2);
					// }
				})
				.catch((err) => {
					errorMsgCheck(err);
				});
		}

		Promise.all([getCustomData({ custNo: sessionStorage.custNo })]).then((r) => {
			let [data] = r;
			let arr = new Map();

			if (data.length > 0) {
				for (var i = 0; i < data.length; i++) {
					if (data[i].url != null && data[i].url != '') {
						arr.set(data[i].customCode, data[i].url);
					} else {
						arr.set(data[i].customCode, data[i].customWord);
					}
				}
			}

			this.setState(
				{
					customData: Object.fromEntries(arr),
				},
				() => {
					if (this.state.customData.hasOwnProperty('CT001')) window.open(this.state.customData['CT001'], '_blank');
					var timer = setInterval(() => {
						if (window[this.orderItemSheet.id]) {
							if (this.state.customData.hasOwnProperty('CT002')) window[this.orderItemSheet.id].SetColEditable('contTerm', 1);
							if (this.state.customData.hasOwnProperty('CT003')) window[this.orderItemSheet.id].SetColEditable('itemQty', 0);
							if (this.state.customData.hasOwnProperty('CT004')) window[this.orderItemSheet.id].SetColHidden('monthPrc', 1);
						}
						clearInterval(timer);
					}, 100);

					this.setState({
						regOrdeMsg: this.state.customData.hasOwnProperty('CT005') ? this.state.customData['CT005'] : '신청',
						userTitle: this.state.customData.hasOwnProperty('CT017') ? this.state.customData['CT017'] : '수령인',
						inputSelectDp: 'table-row',
						daerijumCodeDp: this.state.customData.hasOwnProperty('CT006') ? this.state.customData['CT006'] : 'none',
						deptTitleDp: this.state.customData.hasOwnProperty('CT007') ? this.state.customData['CT007'] : 'inline-block',
						custPordeNoDp: this.state.customData.hasOwnProperty('CT008') ? this.state.customData['CT008'] : 'none',
						delInfoMarginTop: this.state.customData.hasOwnProperty('CT009') ? this.state.customData['CT009'] : 300,
						realUserInfoMsg: this.state.customData.hasOwnProperty('CT018') ? this.state.customData['CT018'] : '(좌측 제품을 선택하여 우측에 추가 하세요.)',
						ordePrpsDp: this.state.customData.hasOwnProperty('CT010') ? this.state.customData['CT010'] : 'none',
						excelBigOrdeDp: this.state.customData.hasOwnProperty('CT032') ? this.state.customData['CT032'] : 'none',
						rqstMailDp: this.state.customData.hasOwnProperty('CT037') ? this.state.customData['CT037'] : 'none',
					});
				},
			);
		});

		ProgressStore.deactiveProgress();
	};

	componentWillUnmount = () => {
		if (window[this.orderItemSheet.id]) {
			window[this.orderItemSheet.id].DisposeSheet();
		}
	};

	selectItem = (item) => {
		let sheet = window[this.orderItemSheet.id];
		//현대엔지니어링(2711687)은 요청당 제품 하나만 가능 20210907 kkj
		// 커스터마이징 권한으로 변경
		if (this.state.customData != null && this.state.customData.hasOwnProperty('CT022')) {
			if (sheet.LastRow() == 1) {
				alert(this.state.customData['CT022']);
				return;
			}
		}
		let row = sheet.DataInsert(-1);

		sheet.SetCellValue(row, 'grpCd', item.grpCd);
		sheet.SetCellValue(row, 'grpNm', item.grpNm);
		sheet.SetCellValue(row, 'itemSeq', item.itemSeq);
		sheet.SetCellValue(row, 'monthPrc', item.monthPrc);
		sheet.SetCellValue(row, 'contTerm', item.contTerm);
		sheet.SetCellValue(row, 'itemQty', 1);

		// 홈앤쇼핑은 부서명/사명/사용자명 로그인 사용자가 들어가도록 20210126 kkj
		if (this.state.customData != null && this.state.customData.hasOwnProperty('CT023')) {
			sheet.SetCellValue(row, 'userDeptName', sessionStorage.deptName);
			sheet.SetCellValue(row, 'userEmpno', sessionStorage.empno);
			sheet.SetCellValue(row, 'userName', sessionStorage.userName);
		}

		//한국생산성본부이면 NT,DT 추가시 SPLA를 자동으로 추가한다.
		if (this.state.customData != null && this.state.customData.hasOwnProperty('CT024') && (item.grpCd == 'DT' || item.grpCd == 'NT')) {
			let sheetRow = sheet.LastRow();
			let swRow = 0;
			for (var i = 1; i <= sheetRow; i++) {
				if (sheet.GetCellValue(i, 'grpCd') === 'SW') {
					swRow = i;
					break;
				}
			}

			if (swRow > 0) {
				sheet.SetCellValue(swRow, 'itemQty', Number(sheet.GetCellValue(swRow, 'itemQty')) + 1);
			} else {
				ModalStore.openModal('소프트웨어를 자동으로 추가 합니다.');

				let param = {
					custNo: sessionStorage.custNo,
					isSw: 'Y',
				};

				getCustOrderItemList(param)
					.then((r) => {
						let data = r[0];
						if (data) {
							let addRow = sheet.DataInsert(-1);
							sheet.SetCellValue(addRow, 'grpCd', data.grpCd);
							sheet.SetCellValue(addRow, 'grpNm', data.grpNm);
							sheet.SetCellValue(addRow, 'itemSeq', data.itemSeq);
							sheet.SetCellValue(addRow, 'monthPrc', data.monthPrc);
							sheet.SetCellValue(addRow, 'contTerm', data.contTerm);
							sheet.SetCellValue(addRow, 'itemQty', 1);
						}
					})
					.catch((err) => {
						errorMsgCheck(err);
					});
			}
		}

		// 제품 조합 가능여부 조회하여 조합 가능 제품 존재시 선택 팝업 호출
		ProgressStore.activeProgress();
		getOrderItemCombList(item.itemSeq)
			.then((r) => {
				if (r.length > 0) {
					RentalOrderItemCombPopStore.openPopup(r, this.addCustItemComb);
				}
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};
	/** 제품 조합 선택건 추가 */
	addCustItemComb = (item) => {
		let sheet = window[this.orderItemSheet.id];
		if (item) {
			let addRow = sheet.DataInsert(-1);
			sheet.SetCellValue(addRow, 'grpCd', item.grpCd);
			sheet.SetCellValue(addRow, 'grpNm', item.grpNm);
			sheet.SetCellValue(addRow, 'itemSeq', item.itemSeq);
			sheet.SetCellValue(addRow, 'monthPrc', item.monthPrc);
			sheet.SetCellValue(addRow, 'contTerm', item.rentMonth);
			sheet.SetCellValue(addRow, 'itemQty', item.qty);
		}
	};
	regOrderCheck = () => {
		let mySheet = window[this.orderItemSheet.id];
		let sheetCnt = mySheet.LastRow();

		if (sheetCnt === 0) {
			ModalStore.openModal('제품은 하나 이상 선택해 주세요.');
			return;
		} else {
			for (var i = 1; i <= sheetCnt; i++) {
				let itemNm = mySheet.GetCellValue(i, 'grpNm');
				if (mySheet.GetCellValue(i, 'qty') === 0) {
					ModalStore.openModal(itemNm + '의 수량을 0보다 크게 입력해 주세요.');
					return;
				}

				if (this.state.customData != null && this.state.customData.hasOwnProperty('CT016')) {
					let userEmpno = mySheet.GetCellValue(i, 'userEmpno');
					let check = /^[0-9]+$/;

					if (userEmpno.length != this.state.customData['CT016']) {
						alert('사번은 숫자' + this.state.customData['CT016'] + '자리로 입력해야 합니다.(ex:2011111)');
						return;
					}

					if (!check.test(userEmpno)) {
						alert('사번입력은 숫자만 입력 가능합니다');
						return;
					}
				}

				if (this.state.customData != null && this.state.customData.hasOwnProperty('CT035')) {
					let userName = mySheet.GetCellValue(i, 'userName');
					let check = /[0-9]/;

					if (check.test(userName)) {
						alert('사용자명은 텍스트 입력만 가능합니다.(ex:홍길동)');
						return;
					}
					if (userName === '') {
						alert('사용자명을 입력해주세요.');
						return;
					}
				}

				if (this.state.customData != null && this.state.customData.hasOwnProperty('CT025') && mySheet.GetCellValue(i, 'userDeptName') === '') {
					ModalStore.openModal('제품 정보에 대리점명을 반드시 입력해 주세요.');
					return;
				}
				if (this.state.customData != null && this.state.customData.hasOwnProperty('CT026')) {
					if (mySheet.GetCellValue(i, 'userDeptName') === '') {
						ModalStore.openModal('부서명을 입력해주세요.');
						mySheet.SelectCell(i, 'userDeptName');
						return;
					}
					if (mySheet.GetCellValue(i, 'userName') === '') {
						ModalStore.openModal('사용자명을 입력해주세요.');
						mySheet.SelectCell(i, 'userName');
						return;
					}
				}
				if (itemNm === '노트북' && this.state.customData != null && this.state.customData.hasOwnProperty('CT027')) {
					if (mySheet.GetCellValue(i, 'userRemark') === '') {
						ModalStore.openModal('AD비밀번호를 입력해주세요');
						return;
					} else {
						ConfirmModalStore.openModal(this.state.customData['CT027'], this.adCheck);
						return;
					}
				}
			}
		}
		/*
        if(sessionStorage.spotIdYn === 'Y' && this.state.spotId === ''){
            ModalStore.openModal(sessionStorage.spotTitle+'코드를 반드시 입력해 주세요.');
            return;
        }
        */
		this.adCheck();
	};
	adCheck = () => {
		if (this.state.customData != null && this.state.customData.hasOwnProperty('CT025') && !this.state.userDeptId) {
			ModalStore.openModal('대리점 코드를 반드시 입력해 주세요.');
			return;
		}
		if (!this.state.delUserNm) {
			ModalStore.openModal('수령인 이름을 입력해 주세요');
			return;
		}
		if (!this.state.delTelNo && !this.state.delMobileNo) {
			ModalStore.openModal('전화번호, 휴대폰번호중 하나는 반드시 입력해 주세요.');
			return;
		}
		if (!this.state.delPostCode) {
			ModalStore.openModal('우편번호가 없습니다. 조회 버튼을 클릭하여 우편번호를 조회 하세요.');
			return;
		}
		if (!this.state.delAddr) {
			ModalStore.openModal('우편번호 조회 버튼을 클릭하여 주소를 선택해 주세요.');
			return;
		}
		if (!this.state.delAddrDtl) {
			ModalStore.openModal('상세 주소를 입력해 주세요.');
			return;
		}
		if (this.state.customData != null && this.state.customData.hasOwnProperty('CT028')) {
			// 현대엔지니어링 전용 validation
			if (!this.state.ordePrps) {
				ModalStore.openModal('신청목적을 선택해 주세요.');
				return;
			}
		}

		if (!this.state.ordeNo) {
			ConfirmModalStore.openModal('렌탈 신청을 하시겠습니까?', this.regOrder);
		} else {
			ConfirmModalStore.openModal('수정 하시겠습니까?', this.updateOrder);
		}
	};
	regOrder = () => {
		if (!this.state.inProgress) {
			//중복저장방지 20211015 kkj
			let maxContTerm = window.getMaxRentMonth();
			//console.log('maxContTerm = '+maxContTerm);

			if (maxContTerm === 0) {
				ModalStore.openModal('렌탈기간이 입력되지 않았습니다.');
				return;
			}
			this.setState(
				{
					inProgress: true,
				},
				() => {
					ProgressStore.activeProgress();
					/*
					orde_status = 0 : 결재사용 , 1 : 결재미사용)
					app_status =  0 : 접수대기중 , 1 : 결재중 , 2 : 반려, 3 : 결재완료, 9 : 신청취소
				*/

					var ordeStatus = '';
					if (sessionStorage.appUse === 'Y' && sessionStorage.authCode > 2) {
						ordeStatus = 0;
					} else if (sessionStorage.appUse === 'Y' && sessionStorage.authCode === '2' && sessionStorage.repCustNo !== '') {
						//결제사용하는데 고객관리자고 대표고객은 있는경우
						ordeStatus = 0;
					} else {
						ordeStatus = 1;
					}
					let param = {
						custNo: sessionStorage.custNo, //고객번호
						addrSeq: this.state.addrSeq === '' ? sessionStorage.addrSeq : this.state.addrSeq, //지점이 입력되지 않았다면 신청자 지점시퀀스를 입력한다.
						userId: sessionStorage.userId, //신청자ID
						rqstMail: this.state.rqstMail, //신청자메일
						ordeStatus: ordeStatus /* 고객관리자는 결재없이 바로 처리된다. 20190405 */ /* 0 : 결재사용 , 1 : 결재미사용)  */,
						rentMonth: maxContTerm, //고객 계약기간
						spotId: this.state.spotId,
						spotNm: this.state.spotNm,
						userDeptId: this.state.userDeptId, //사용자지점코드 (흥국: 대리점코드)
						delUserNm: this.state.delUserNm, //수령인이름
						delDeptNm: this.state.delDeptNm, //수령인부서명
						delTelNo: this.state.delTelNo, //수령인 전화번호
						delMobileNo: this.state.delMobileNo, //수령인 휴대폰 번호
						delPostCode: this.state.delPostCode, //배송지 우편번호
						delAddr: this.state.delAddr, //배송지 주소
						delAddrDtl: this.state.delAddrDtl, //배송지 상세주소
						delRemark: this.state.delRemark, //배송요청사항
						ordePrps: isEmpty(this.state.ordePrps) || this.state.ordePrps === 'null' ? '' : this.state.ordePrps, //신청목적
						custPordeNo: this.state.custPordeNo, //고객사 관리번호
					};

					let mySheet = window[this.orderItemSheet.id];
					let paramData = SheetParamData(mySheet, param, true);
					if (paramData) {
						insertRentOrder(SheetParamData(mySheet, param, true))
							.then((r) => {
								// let ordeNo = r[0].ordeNo;

								ConfirmModalStore.openModal('렌탈 신청이 완료 되었습니다.\n신청조회 화면으로 이동 하시겠습니까?', this.goOrderSearch);
								this.setEmptyInput(true);
								ProgressStore.deactiveProgress();

								this.setState({
									inProgress: false,
								});
							})
							.catch((err) => {
								// 20201103 kkj 렌탈신청시 이제 controller에서 에러코드 던지므로 반드시 트렌젝션 걸리며 에러처리를 위한 catch필수!
								errorMsgCheck(err);

								ProgressStore.deactiveProgress();
								this.setState({
									inProgress: false,
								});
							});
						// .finally(() => {
						// 	ProgressStore.deactiveProgress();
						// 	this.setState({
						// 		inProgress: false,
						// 	});
						// });
					} else {
						ProgressStore.deactiveProgress();
					}
				},
			);
		}
	};

	updateOrder = () => {
		if (!this.state.inProgress) {
			this.setState(
				{
					inProgress: true,
				},
				() => {
					//중복저장방지 20211015 kkj
					ProgressStore.activeProgress();

					/*
					orde_status = 0 : 결재사용 , 1 : 결재미사용)
					app_status =  0 : 접수대기중 , 1 : 결재중 , 2 : 반려, 3 : 결재완료, 9 : 신청취소
				*/

					let param = {
						ordeNo: this.state.ordeNo,
						custNo: this.state.orderCustNo, //고객번호
						addrSeq: this.state.addrSeq, //지점시퀀스
						rqstMail: this.state.rqstMail, //신청자메일
						spotId: this.state.spotId,
						spotNm: this.state.spotNm,
						userDeptId: this.state.userDeptId, //사용자지점코드 (흥국: 대리점코드)
						delUserNm: this.state.delUserNm, //수령인이름
						delDeptNm: this.state.delDeptNm, //수령인부서명
						delTelNo: this.state.delTelNo, //수령인 전화번호
						delMobileNo: this.state.delMobileNo, //수령인 휴대폰 번호
						delPostCode: this.state.delPostCode, //배송지 우편번호
						delAddr: this.state.delAddr, //배송지 주소
						delAddrDtl: this.state.delAddrDtl, //배송지 상세주소
						delRemark: this.state.delRemark, //배송요청사항
						ordePrps: isEmpty(this.state.ordePrps) || this.state.ordePrps === 'null' ? '' : this.state.ordePrps, //신청목적
						custPordeNo: this.state.custPordeNo, //고객사 관리번호
					};

					let mySheet = window[this.orderItemSheet.id];

					updateRentOrder(SheetParamData(mySheet, param, false))
						.then(() => {
							ConfirmModalStore.openModal('수정이 완료 되었습니다.\n신청조회 화면으로 이동 하시겠습니까?', this.goOrderSearch);
							this.goOrder();
							this.setEmptyInput(true);
							ProgressStore.deactiveProgress();

							this.setState({
								inProgress: false,
							});
						})
						.catch((err) => {
							errorMsgCheck(err);

							ProgressStore.deactiveProgress();
							this.setState({
								inProgress: false,
							});
						});
					// .finally(() => {
					// 	ProgressStore.deactiveProgress();
					// 	this.setState({
					// 		inProgress: false,
					// 	});
					// });
				},
			);
		}
	};

	goOrder = () => {
		this.props.history.push('/RentalOrder', null);
		sessionStorage.activeSubPageId = '0000003';
	};

	goOrderSearch = () => {
		this.props.history.push('/RentalOrderSearch', null);
		sessionStorage.activeSubPageId = '0000004';
	};

	selectShipMethod = (selMethod) => {
		/*
            1: 신청자정보와동일, 2: 직접입력
        */

		if (selMethod === 1) {
			this.setState({
				orderSameChk: true,
				selfInputChk: false,
				spotSearchChk: false,
				readOnly: true,
			});
			//유저 데이터 입력
			this.setUserData();
		} else if (selMethod === 2) {
			this.setEmptyInput(false);
			this.setState({
				orderSameChk: false,
				selfInputChk: true,
				spotSearchChk: false,
				readOnly: false,
			});
		} else if (selMethod === 3) {
			this.setState({
				orderSameChk: false,
				selfInputChk: false,
				spotSearchChk: true,
				readOnly: true, //지점정보 readonly
			});
			CustSearchPopupStore.openPopup('2', null, sessionStorage.custNo, this.handleSpotSearch);
		}
	};

	setEmptyInput = (isRem) => {
		this.setState({
			orderSameChk: false, //배송정보 신청자 동일
			selfInputChk: false, //배송정보 직접입력
			spotSearchChk: false, //지점정보 가져오기
			rqstMail: '', //신청자메일
			updateTitle: '', //수정문구
			orderStatus: '', //요청상태
			ordeNo: '', //요청번호
			addrSeq: '', //지점시퀀스
			spotId: '', //지점코드
			spotNm: '', //지점명
			delUserNm: '', //수령인명
			delDeptNm: '', //수령인부서명
			delTelNo: '', //수령인전화번호
			delMobileNo: '', //수령인휴대폰번호
			delPostCode: '', //우편번호
			delAddr: '', //주소1
			delAddrDtl: '', //상세주소
			delRemark: '', //요청사항
			custPordeNo: '', //고객사 관리번호
		});

		if (isRem) {
			window[this.orderItemSheet.id].RemoveAll();
		}
	};

	setUserData = async (data) => {
		if (data) {
			// 수정모드 시 사용자 정보 셋팅
			this.setState({
				orderSameChk: false,
				orderCustNo: data.custNo, //신청고객코드
				orderCustNm: data.custNm, //신청고객명
				orderSpotNm: data.userSpotNm, //신청지점명
				orderUserId: data.rqstId, //신청자ID
				orderUser: data.userNm, //신청자
				orderStatus: data.ordeStatus, //신청상태
				addrSeq: data.addrSeq, //지점시퀀스
				userDeptId: data.deptId, //부서(대리점)코드
				rqstMail: data.rqstMail, //신청자메일
				spotId: data.spotId, //지점코드
				spotNm: data.spotNm, //지점명
				delUserNm: data.delUser, //수령인명
				delDeptNm: data.delDept, //수령인부서명
				delTelNo: data.delTelno, //수령인전화번호
				delMobileNo: data.delHpno, //수령인휴대폰번호
				delPostCode: data.delPostCode, //우편번호
				delAddr: data.delAddr, //주소1
				delAddrDtl: data.delAddrDtl, //상세주소
				delRemark: data.ordeRmk, //요청비고
				ordePrps: data.ordePrps, //신청목적
				custPordeNo: data.custPordeNo, //고객사관리번호
			});
		} else {
			// 최초 사용자정보 셋팅 or 신청자 정보와 동일 버튼 클릭 시
			if (this.state.customData != null && this.state.customData.hasOwnProperty('CT029')) {
				// 현대엔지니어링은 신청자 명과 주소는 자동세팅 제외한다. 20210823 kkj
				this.setState({
					orderCustNo: sessionStorage.custNo, //신청고객코드
					orderCustNm: sessionStorage.custNm, //신청고객명
					orderSpotNm: sessionStorage.spotNm, //신청지점명
					orderUser: sessionStorage.userName, //신청자
					rqstMail: sessionStorage.emailAddr, //신청자메일
					addrSeq: sessionStorage.addrSeq, //지점시퀀스
					spotId: sessionStorage.spotId, //지점코드
					spotNm: sessionStorage.spotNm, //지점명
					delUserNm: '', //수령인명
					delDeptNm: sessionStorage.deptName, //수령인부서명
					delTelNo: sessionStorage.otelNo, //수령인전화번호
					delMobileNo: sessionStorage.celNo, //수령인휴대폰번호
					delPostCode: '', //우편번호
					delAddr: '', //주소1
					delAddrDtl: '', //상세주소
				});
			} else {
				this.setState({
					orderCustNo: sessionStorage.custNo, //신청고객코드
					orderCustNm: sessionStorage.custNm, //신청고객명
					orderSpotNm: sessionStorage.spotNm, //신청지점명
					orderUser: sessionStorage.userName, //신청자
					rqstMail: sessionStorage.emailAddr, //신청자메일
					addrSeq: sessionStorage.addrSeq, //지점시퀀스
					spotId: sessionStorage.spotId, //지점코드
					spotNm: sessionStorage.spotNm, //지점명
					delUserNm: sessionStorage.userName, //수령인명
					delDeptNm: sessionStorage.deptName, //수령인부서명
					delTelNo: sessionStorage.otelNo, //수령인전화번호
					delMobileNo: sessionStorage.celNo, //수령인휴대폰번호
					delPostCode: sessionStorage.postCode, //우편번호
					delAddr: sessionStorage.postAddr, //주소1
					delAddrDtl: sessionStorage.postSddr, //상세주소
				});
			}
		}
	};

	checkNumber = () => {
		if (this.state.delTelNo !== '') {
			if (!numberCheck(this.state.delTelNo)) {
				this.setState({
					delTelNo: '',
				});
			}
		}
		if (this.state.delMobileNo !== '') {
			if (!numberCheck(this.state.delMobileNo)) {
				this.setState({
					delMobileNo: '',
				});
			}
		}
	};

	checkMail = (e) => {
		if (!mailCheck(e.target.value)) {
			alert('메일주소 형식이 올바르지 않습니다.');
			this.setState({
				[[e.target.name]]: '',
			});
		}
	};

	setAddress = (data) => {
		if (data.postCode !== undefined) {
			this.setState(
				{
					delPostCode: data.postCode,
					delAddr: data.address,
					delAddrDtl: '',
				},
				() => this.delAddrDtl.focus(),
			);
		}
	};

	spotInsertChange = (e) => {
		this.setState({
			spotInputCheck: !this.state.spotInputCheck,
		});
	};

	handleSpotSearch = (item) => {
		if (item !== undefined) {
			this.setState({
				addrSeq: item.addrSeq, //지점시퀀스
				spotNm: item.spotNm, //지점명
				spotId: item.spotId, //지점아이디
				delUserNm: item.addrUser, //수령인
				delTelNo: item.telNo, //전화번호
				delMobileNo: item.mobileNo, //휴대폰번호
				delAddr: item.postAddr, //지점주소1
				delAddrDtl: item.postSddr, //지점주소2
				delPostCode: item.postCode, //지점우편번호
			});
		}
	};

	closeModal = () => {
		if (this.state.excelUploadModal) {
			this.setState({
				excelUploadModal: false,
			});
		}
	};

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
		});
	};

	render() {
		let inputNoBorder = {
			border: 0,
		};

		let appInfoStyle = {
			textAlign: 'center',
			border: 0,
		};

		let redPointText = {
			color: '#d41826',
			marginLeft: 10,
			fontWeight: 'normal',
		};

		const HtmlTooltip = withStyles((theme) => ({
			tooltip: {
				backgroundColor: '#ffffff',
				color: 'rgba(0, 0, 0, 0.87)',
				maxWidth: 500,
				fontSize: theme.typography.pxToRem(12),
				border: '1px solid #dadde9',
			},
		}))(Tooltip);

		// let regOrdeMsg = sessionStorage.custNo === CUST_NO_HDENG ? '결재 요청' : '신청'; //20210531 현대엔지니어링은 결재요청
		// let userTitle = sessionStorage.custNo === CUST_NO_HDENG ? '실사용자' : '수령인'; //2021.06.07 현대엔지니어링 수령자 -> 실사용자
		// // let inputSelectDp = sessionStorage.custNo === CUST_NO_HDENG ? 'none' : 'table-row'; // 입력선택
		// let inputSelectDp = 'table-row'; // 입력선택
		// let daerijumCodeDp = sessionStorage.custNo === CUST_NO_HKFIRE ? 'inline-block' : 'none'; // 대리점코드
		// let deptTitleDp = sessionStorage.custNo === CUST_NO_HDENG ? 'none' : 'inline-block'; //부서명
		// let custPordeNoDp = sessionStorage.custNo === CUST_NO_KT || sessionStorage.custNo === CUST_NO_AJ ? 'block' : 'none'; // 고객사관리번호
		// let delInfoMarginTop = sessionStorage.custNo === CUST_NO_KT || sessionStorage.custNo === CUST_NO_AJ ? 0 : 300; //배송정보
		// //let excelBigOrdeDp = sessionStorage.custNo === CUST_NO_KT || sessionStorage.custNo === CUST_NO_AJ ? 'inline-block' : 'none'; //엑셀대량발주
		// let excelBigOrdeDp = this.state.bigOrderYn === 'Y' ? 'inline-block' : 'none'; // 대량발주사용여부 따라 버튼 보이기
		// let realUserInfoMsg = sessionStorage.custNo === CUST_NO_HDENG ? '(제품은 요청당 하나만 등록할 수 있습니다.)' : '(좌측 제품을 선택하여 우측에 추가 하세요.)'; // 현대엔지니어링 실사용자 정보 안내문구 20210907
		// let ordePrpsDp = sessionStorage.custNo === CUST_NO_HDENG ? 'block' : 'none'; //20211103 kkj 현대엔지니어링만 신청목적 노출
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mb30">
								<h2>렌탈신청{this.state.updateTitle}</h2>
								<div className="btnRight" style={{ display: this.state.excelBigOrdeDp }}>
									<a className="btn01" onClick={() => this.setState({ excelUploadModal: true })}>
										<span>엑셀대량발주</span>
									</a>
								</div>
							</div>
							<div className="writeType03">
								<h2>-신청자 정보</h2>
								<table>
									<colgroup>
										<col style={{ width: '70px' }} />
										<col style={{ width: '180px' }} />
										<col style={{ width: '70px' }} />
										<col style={{ width: '200px' }} />
										<col style={{ width: '70px' }} />
										<col style={{ width: '120px' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>회사명</th>
											<td>
												<input type="text" value={this.state.orderCustNm} readOnly={true} style={inputNoBorder} />
											</td>
											<th>{sessionStorage.spotTitle}명</th>
											<td>
												<input type="text" value={this.state.orderSpotNm} readOnly={true} style={inputNoBorder} />
											</td>
											<th>신청자</th>
											<td>
												<input type="text" value={this.state.orderUser} readOnly={true} style={inputNoBorder} />
											</td>
										</tr>
										<tr style={{ display: this.state.rqstMailDp }}>
											<th>메일주소</th>
											<td colSpan={5}>
												<input
													type="text"
													name="rqstMail"
													value={this.state.rqstMail}
													onChange={(e) => this.setState({ rqstMail: e.target.value })}
													onBlur={this.checkMail}
													style={{ width: 300, marginLeft: 5 }}
													maxLength={50}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="writeType03" style={{ marginTop: 30, display: this.state.appInfoDisplay }}>
								<h2>-결재자정보</h2>
								<table>
									<colgroup>
										<col style={{ width: '80px' }} />
										<col style={{ width: '150px' }} />
										<col style={{ width: '150px' }} />
										<col style={{ width: '150px' }} />
									</colgroup>
									<tbody>
										<tr>
											<th style={{ textAlign: 'center' }}>결재순서</th>
											<th style={{ textAlign: 'center' }}>{sessionStorage.spotTitle}명</th>
											<th style={{ textAlign: 'center' }}>{sessionStorage.deptTitle}명</th>
											<th style={{ textAlign: 'center' }}>결재자</th>
										</tr>
										{this.state.appList.map((item, i) => (
											<tr key={i} style={{ textAlign: 'center' }}>
												<td>
													<input type="text" className="txtCenter" value={i + 1} readOnly={true} style={appInfoStyle} />
												</td>
												<td>
													<input type="text" className="txtCenter" value={item.spotNm} readOnly={true} style={appInfoStyle} />
												</td>
												<td>
													<input type="text" className="txtCenter" value={item.deptName} readOnly={true} style={appInfoStyle} />
												</td>
												<td>
													<input type="text" className="txtCenter" value={item.userName} readOnly={true} style={appInfoStyle} />
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>

							<div className="writeType03" style={{ marginTop: 30, display: this.state.ordePrpsDp }}>
								<h2>-신청목적</h2>
								<table>
									<colgroup>
										<col style={{ width: 100 }} />
										<col />
									</colgroup>
									<tbody>
										<tr style={{ display: this.state.inputSelectDp }}>
											{/* <th>입력선택</th> */}
											<td>
												<FormControl component="fieldset" style={{ margin: '-11px 0px' }}>
													<RadioGroup
														aria-label="ordePrps"
														name="ordePrps"
														value={this.state.ordePrps}
														onChange={(e) => {
															this.handleChange(e);
														}}
														row
													>
														{/* <FormControlLabel value="1" control={<Radio />} label="신규입사자" />
														<FormControlLabel value="2" control={<Radio />} label="기존사용자 교체" /> */}
														{ordePrpsCodeList
															? ordePrpsCodeList.map((item, i) => <FormControlLabel key={i} value={item.value} control={<Radio />} label={item.title} />)
															: null}
													</RadioGroup>
												</FormControl>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div
								className="writeType03"
								style={{
									float: 'left',
									height: 270,
									marginTop: 30,
									width: 280,
								}}
							>
								<h2>-제품선택</h2>
								<div style={{ border: '1px solid #dfe2e4' }}>
									<List style={{ overflow: 'auto', height: 270 }}>
										{this.state.itemList.map((item, i) => (
											<HtmlTooltip
												key={i}
												title={
													<React.Fragment>
														{item.itemImg ? (
															<span>
																<center>
																	<img src={item.itemImg} width="300" />
																</center>
																<br></br>
															</span>
														) : null}
														{item.itemDesc &&
															(`${item.itemDesc}/렌탈기간: ${item.contTerm}개월`).split('/').map((line, idx) => {
																return (
																	<span
																		key={idx}
																		style={{
																			font: '맑은 고딕',
																			fontSize: '15px',
																			lineHeight: 1.6,
																		}}
																	>
																		- {line}
																		<br />
																	</span>
																);
															})}
													</React.Fragment>
												}
												placement="right"
											>
												<ListItem button onClick={() => this.selectItem(item)}>
													<ListItemIcon>{createItemIcon(item.grpCd)}</ListItemIcon>
													<ListItemText primary={item.grpNm} secondary={item.model} />
												</ListItem>
											</HtmlTooltip>
										))}
									</List>
								</div>
							</div>
							<div
								className="writeType03"
								style={{
									float: 'right',
									height: 270,
									marginTop: 30,
									width: 650,
								}}
							>
								<h2>
									-실사용자 정보
									<span style={redPointText}>{this.state.realUserInfoMsg}</span>
								</h2>
								<div>
									<div
										className="scroll"
										onMouseOver={() => {
											document.body.style.overflow = 'hidden';
										}}
										onMouseOut={() => {
											document.body.style.overflow = 'auto';
										}}
									>
										<IBSheet {...this.orderItemSheet} />
									</div>
								</div>
							</div>
							<br />
							<br />
							{
								/* 케이티만 노출한다. */
								<div className="writeType03" style={{ marginTop: 300, display: this.state.custPordeNoDp }}>
									<table>
										<colgroup>
											<col style={{ width: 130 }} />
											<col />
										</colgroup>
										<tbody>
											<tr>
												<th>고객사 관리번호</th>
												<td>
													<input
														type="text"
														value={this.state.custPordeNo}
														style={{ width: 200 }}
														onChange={(e) => this.setState({ custPordeNo: e.target.value })}
													/>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							}
							<div className="writeType03" style={{ marginTop: this.state.delInfoMarginTop }}>
								<h2>
									-배송정보
									<span style={redPointText}>*표시는 필수 입니다.</span>
								</h2>
								<table>
									<colgroup>
										<col style={{ width: 100 }} />
										<col />
									</colgroup>
									<tbody>
										<tr style={{ display: this.state.inputSelectDp }}>
											<th>입력선택</th>
											<td>
												<Checkbox checked={this.state.orderSameChk} onClick={() => this.selectShipMethod(1)} style={{ color: '#3f51b5' }} />
												신청자 정보와 동일
												<Checkbox checked={this.state.selfInputChk} onClick={() => this.selectShipMethod(2)} style={{ color: '#f50057' }} />
												직접입력
												<Checkbox checked={this.state.spotSearchChk} onClick={() => this.selectShipMethod(3)} style={{ color: '#01DF3A' }} />
												{sessionStorage.spotTitle}정보 가져오기
											</td>
										</tr>
										<tr style={{ display: this.state.spotInfoDisplay }}>
											<th>{sessionStorage.spotTitle}정보</th>
											<td colSpan="3">
												<span style={{ fontSize: 14 }}>{sessionStorage.spotTitle}명</span>
												<input
													type="text"
													value={this.state.spotNm}
													style={{ width: 200, marginLeft: 5 }}
													readOnly={this.state.readOnly}
													onChange={(e) => this.setState({ spotNm: e.target.value })}
												/>
												<span style={{ fontSize: 14, marginLeft: 15 }}>{sessionStorage.spotTitle}코드</span>
												<input
													type="text"
													value={this.state.spotId}
													style={{ width: 140, marginLeft: 5, marginRight: 5 }}
													readOnly={this.state.readOnly}
													onChange={(e) => this.setState({ spotId: e.target.value })}
												/>
												<div style={{ display: this.state.daerijumCodeDp }}>
													<span style={{ fontSize: 14, marginLeft: 15 }}>
														<span style={redPointText}>*</span>대리점코드
													</span>
													<input
														type="text"
														value={this.state.userDeptId}
														style={{
															width: 120,
															marginLeft: 5,
															marginRight: 5,
														}}
														onChange={(e) => this.setState({ userDeptId: e.target.value })}
													/>
												</div>
											</td>
										</tr>
										<tr>
											<th>
												{this.state.userTitle}
												<span style={redPointText}>*</span>
											</th>
											<td>
												<span style={{ fontSize: 14 }}>이름</span>
												<input
													type="text"
													value={this.state.delUserNm}
													maxLength="20"
													onChange={(e) => this.setState({ delUserNm: e.target.value })}
													style={{ width: 100, marginLeft: 5 }}
												/>
												{/* 20210531 현대엔지니어링 부서명 제거 */}
												<span style={{ display: this.state.deptTitleDp }}>
													<span style={{ fontSize: 14, marginLeft: 15 }}>{sessionStorage.deptTitle}명</span>
													<input
														type="text"
														value={this.state.delDeptNm}
														maxLength="80"
														onChange={(e) => this.setState({ delDeptNm: e.target.value })}
														style={{ width: 170, marginLeft: 5 }}
													/>
												</span>
											</td>
										</tr>
										<tr>
											<th>
												연락처<span style={redPointText}>*</span>
											</th>
											<td>
												<span style={{ fontSize: 14 }}>전화번호</span>
												<input
													type="text"
													value={this.state.delTelNo}
													onChange={(e) => this.setState({ delTelNo: e.target.value })}
													onBlur={this.checkNumber}
													style={{ width: 130, marginLeft: 5 }}
												/>
												<span style={{ fontSize: 14, marginLeft: 15 }}>휴대폰번호</span>
												<input
													type="text"
													value={this.state.delMobileNo}
													onChange={(e) => this.setState({ delMobileNo: e.target.value })}
													onBlur={this.checkNumber}
													style={{ width: 130, marginLeft: 5 }}
												/>
												<span style={redPointText}>*둘중 하나는 반드시 입력해 주세요.</span>
											</td>
										</tr>
										<tr>
											<th>
												주소<span style={redPointText}>*</span>
											</th>
											<td>
												<span style={{ fontSize: 14 }}>우편번호</span>
												<input
													type="text"
													value={this.state.delPostCode}
													onChange={(e) => this.setState({ delPostCode: e.target.value })}
													style={{ width: 70, marginLeft: 5, marginRight: 5 }}
													readOnly={true}
												/>
												<Button color="primary" size="small" variant="contained" onClick={() => PostPopupStore.openPopup(this.setAddress)}>
													조회
												</Button>
												<span style={{ fontSize: 14, marginLeft: 15 }}>주소</span>
												<input
													type="text"
													value={this.state.delAddr}
													onChange={(e) => this.setState({ delAddr: e.target.value })}
													style={{ width: 270, marginLeft: 5 }}
													readOnly={true}
												/>
												<input
													type="text"
													ref={(ref) => (this.delAddrDtl = ref)}
													name="delAddrDtl"
													value={this.state.delAddrDtl}
													onChange={(e) => this.setState({ delAddrDtl: e.target.value })}
													placeholder="상세 주소를 입력해 주세요"
													style={{ width: 298, marginLeft: 5 }}
												/>
											</td>
										</tr>
										<tr>
											<th>요청사항</th>
											<td>
												<textarea
													value={this.state.delRemark}
													onChange={(e) => this.setState({ delRemark: e.target.value })}
													placeholder="배송시 요청사항을 입력해 주세요."
													rows="3"
													style={{ height: 60 }}
												/>
											</td>
										</tr>
									</tbody>
								</table>
								<div className="btnCenter" style={{ marginTop: 20 }}>
									{!this.state.ordeNo ? (
										<a className="btn02 white" style={{ opacity: 1 }} onClick={this.regOrderCheck}>
											{!this.state.ordeNo ? this.state.regOrdeMsg : this.state.updateTitle}
										</a>
									) : this.state.orderStatus < 4 && this.state.orderUserId === sessionStorage.userId ? (
										<a className="btn02 white" style={{ opacity: 1 }} onClick={this.regOrderCheck}>
											{!this.state.ordeNo ? this.state.regOrdeMsg : this.state.updateTitle}
										</a>
									) : (
										''
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<RentalOrderExcel open={this.state.excelUploadModal} closeFnc={this.closeModal} custItemList={this.state.itemList} />
			</div>
		);
	}
}
export default observer(RentalOrder);
