import { httpGet, httpPost, httpPut, toQueryString } from '../Service';

// api 상태전송 리스트
export function getApiStatusSend(data) {
	return httpGet('/api/v1.0/cis/status/apiStatusSend' + toQueryString(data));
}
// api 상태 재전송
export function putApiRetryStatusSend(data) {
	return httpPut('/api/v1.0/cis/status/apiRetryStatusSend' + toQueryString(data));
}
// api 상태 존재여부 체크
export function getApiStatusCheck(data) {
	return httpGet('/api/v1.0/cis/status/apiStatusCheck' + toQueryString(data));
}
// api 상태 전송상태 리셋
export function apiSendStatusReset(data) {
	data = data || {};
	return httpPut('/api/v1.0/cis/status/apiSendStatusReset/', { body: data });
}
