import React, { Component } from 'react';
import { errorMsgCheck } from '../../common/ComFunction';
import { getItemList } from '../../service/admin/CompService';
import { TableBody } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { Table } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { Dialog } from '@material-ui/core';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ProgressStore, ItemPopupStore } = CommonStore;

// 검색조건 비 state. 검색결과 변경시에는 render하지만 검색조건 변경시에는 render하지 않으므로. shouldComponentUpdate로 전체 state화 가능
var modelSearch = ''; //모델명_검색조건
var itemNmSearch = ''; //제품명_검색조건
var itemCdSearch = ''; //제품코드_검색조건
var useYnSearch = 'Y'; //사용여부_검색조건

class ItemPopup extends Component {
	state = {
		// modelSearch     : '',//모델명_검색조건
		// itemNmSearch    : '',//제품명_검색조건
		// itemCdSearch    : '',//제품코드_검색조건
		// useYnSearch     : 'Y',//사용여부_검색조건

		itemList: [], //검색결과
	};

	//제품 조회
	findItemList = () => {
		if (modelSearch === '' && itemNmSearch === '' && itemCdSearch === '') {
			ModalStore.openModal('검색조건 중 하나를 입력해야합니다.');
			return;
		}
		let param = {
			model: modelSearch,
			itemNm: itemNmSearch,
			itemCd: itemCdSearch,
			useYn: useYnSearch,
		};
		ProgressStore.activeProgress();
		getItemList(param)
			.then((r) => {
				if (r.length > 0) {
					this.setState({ itemList: r });
				}
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	resetState = () => {
		//state초기화
		modelSearch = ''; //모델명_검색조건
		itemNmSearch = ''; //제품명_검색조건
		itemCdSearch = ''; //제품코드_검색조건
		useYnSearch = 'Y'; //사용여부_검색조건
		this.setState({
			itemList: [], //검색결과
		});
	};

	render() {
		const { open, title } = ItemPopupStore;

		let popContHeight = {
			height: 600,
		};
		let popTableHeight = {
			height: 330,
			overflowY: 'scroll',
		};

		return (
			<div>
				<Dialog open={open} onClose={ItemPopupStore.closePopup}>
					<div className="popArea searchPop">
						<div className="popCont" style={popContHeight}>
							<div className="h2Wrap">
								<h2>
									{title} 검색 <span>*는 필수항목입니다.</span>
								</h2>
								{/* <div className="btnRight">
                                    <a href="#;" className="btn01 search" onClick={this.findCustList}><span>검색</span></a>
                                </div> */}
							</div>
							<div className="writeType02 type2">
								<table>
									<colgroup>
										<col style={{ width: '10%' }} />
										<col style={{ width: '15%' }} />
										<col style={{ width: '10%' }} />
										<col style={{ width: '15%' }} />
										<col style={{ width: '10%' }} />
										<col style={{ width: '15%' }} />
										<col style={{ width: '10%' }} />
										<col style={{ width: '15%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>모델명 </th>
											<td>
												<input
													type="text"
													ref={(ref) => (this.modelSearch = ref)}
													name="modelSearch"
													className="size01"
													onChange={(e) => {
														modelSearch = e.target.value;
													}}
													onKeyDown={(e) => {
														if (e.key == 'Enter') this.findItemList();
													}}
												/>
											</td>
											<th>제품명 </th>
											<td>
												<input
													type="text"
													ref={(ref) => (this.itemNmSearch = ref)}
													name="itemNmSearch"
													className="size01"
													onChange={(e) => {
														itemNmSearch = e.target.value;
													}}
													onKeyDown={(e) => {
														if (e.key == 'Enter') this.findItemList();
													}}
												/>
											</td>
											<th>제품코드 </th>
											<td>
												<input
													type="text"
													ref={(ref) => (this.itemCdSearch = ref)}
													name="itemCdSearch"
													className="size01"
													onChange={(e) => {
														itemCdSearch = e.target.value;
													}}
													onKeyDown={(e) => {
														if (e.key == 'Enter') this.findItemList();
													}}
												/>
											</td>
											<th>사용여부 </th>
											<td>
												<select
													ref={(ref) => (this.useYnSearch = ref)}
													name="useYnSearch"
													defaultValue="Y"
													onChange={(e) => {
														useYnSearch = e.target.value;
													}}
												>
													<option value="">전체</option>
													<option value="N">N</option>
													<option value="Y">Y</option>
												</select>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="writeType05">
								{/* <div onClick={this.setInput}>                                    
                                    <IBSheet {...this.sheetData}/>
                                </div> */}
								<div style={popTableHeight}>
									<Paper>
										<Table>
											<colgroup>
												<col style={{ width: '15%' }} />
												<col style={{ width: '15%' }} />
												<col style={{ width: '10%' }} />
												<col style={{ width: '10%' }} />
												<col style={{ width: '10%' }} />
												<col style={{ width: '10%' }} />
												<col style={{ width: '30%' }} />
											</colgroup>
											<TableHead>
												<TableRow>
													<TableCell>모델명</TableCell>
													<TableCell>제품명</TableCell>
													<TableCell>제조사</TableCell>
													<TableCell>제품코드</TableCell>
													<TableCell numeric>기준렌탈료</TableCell>
													<TableCell>대분류명</TableCell>
													<TableCell>제품스펙</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{this.state.itemList.map((item) => {
													return (
														<TableRow
															key={item.itemId}
															hover
															onClick={() => {
																ItemPopupStore.closePopup(item);
																this.resetState();
															}}
														>
															{/* <TableCell component="th" scope="row">
                                                    {row.name}
                                                    </TableCell> */}
															<TableCell>{item.model}</TableCell>
															<TableCell>{item.itemNm}</TableCell>
															<TableCell>{item.makeNm}</TableCell>
															<TableCell>{item.itemCd}</TableCell>
															<TableCell numeric>{item.stdPrc}</TableCell>
															<TableCell>{item.lGrpNm}</TableCell>
															<TableCell>{item.itemSpec}</TableCell>
														</TableRow>
													);
												})}
											</TableBody>
										</Table>
									</Paper>
								</div>
							</div>
							{/* <div className="btnCenter">
                                <a className="btn02 blue">등록</a>
                                <a className="btn02 white" onClick={handleClose}>취소</a>
                            </div> */}
							<div className="btnRight2">
								<a href="#;" className="btn01 search" onClick={this.findItemList}>
									<span>검색</span>
								</a>
							</div>
							<div
								className="btnClose"
								onClick={() => {
									ItemPopupStore.closePopup(undefined);
									this.resetState();
								}}
							>
								<a>팝업닫기</a>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}
}

export default observer(ItemPopup);
