import React, { Component } from 'react';
import { getMemberList } from '../../service/members/MembersService';
import { errorMsgCheck } from '../../common/ComFunction';
import { ArrowRight } from '@material-ui/icons';
import { Dialog } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ProgressStore, UserPopupStore } = CommonStore;

class UserPopup extends Component {
	componentDidUpdate = () => {
		if (UserPopupStore.open && !UserPopupStore.searched && UserPopupStore.type === 'rqst') {
			UserPopupStore.setSearched(true);
			console.log('componentDidUpdate');
			this.getUserInfo();
		}
	};

	//고객사 조회
	getUserInfo = () => {
		// if (UserPopupStore.userNm === '') {
		// 	this.setState({
		// 		modalOpen: true,
		// 		modalText: '검색어를 입력해 주세요.',
		// 	});
		// 	return;
		// }

		ProgressStore.activeProgress();

		let param = {
			custNo: UserPopupStore.custNo,
			userNm: UserPopupStore.userNm,
			type: UserPopupStore.type,
		};
		getMemberList(param)
			.then((r) => {
				if (r.length > 0) {
					UserPopupStore.setUserList(r);
					UserPopupStore.setCountText('총 ' + r.length + '건이 검색 되었습니다.');
				}
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	primaryTitle = (item) => {
		if (UserPopupStore.type === 'user') {
			// user_info_new 검색시
			var userNm = item.userName;
			var dutyType = item.clasFlagNm && item.dutyType ? ' / ' + item.dutyType : item.dutyType;
			var deptNm = item.clasFlagNm;
			deptNm = !deptNm && !dutyType ? '' : '  [' + deptNm + dutyType + ']';

			return userNm + deptNm;
		} else if (UserPopupStore.type === 'rqst') {
			// 요청자id 변경 시 조회 폼으로 청구지순번을 보여줘야 한다
			// user_info_new 검색시
			var userNm = item.userName;
			var dutyType = item.clasFlagNm && item.dutyType ? ' / ' + item.dutyType : item.dutyType;
			var deptNm = item.clasFlagNm;
			deptNm = !deptNm && !dutyType ? '' : '  [' + deptNm + dutyType + ']';

			return userNm + deptNm + `(청구지 순번 : ${item.custSeq})`;
		} else {
			// cis_user 기준 검색시
			var userNm = item.userTitle ? ' ' + item.userName : item.userName;
			var userTitle = item.deptName && item.userTitle ? ' / ' + item.userTitle : item.userTitle;
			var deptNm = item.deptName;
			var userId = item.userId;
			deptNm = !deptNm && !userTitle ? '' : '  [' + deptNm + userTitle + ']';

			return userNm + '(' + userId + ')' + deptNm;
		}
	};

	secondaryTitle = (item) => {
		if (UserPopupStore.type === 'user') {
			// user_info_new 검색시
			var title = item.title ? item.title + ' - ' + item.userCode + '[' + item.userId + ']' : item.userCode + '[' + item.userId + ']';
			return title;
		} else {
			// cis_user 기준 검색시
			var spotNm = item.spotNm ? ' - ' + item.spotNm : item.spotNm;
			return item.custNm + spotNm;
		}
	};

	render() {
		const { open, countText, userNm, userList } = UserPopupStore;

		let popContHeight = {
			height: 600,
		};

		return (
			<div>
				<Dialog open={open} onClose={UserPopupStore.closePopup}>
					<div className="popSmArea searchPop">
						<div className="popCont" style={popContHeight}>
							<div className="h2Wrap">
								<h2>
									사용자 검색 <span>*는 필수항목입니다.</span>
								</h2>
							</div>
							<div className="writeType02 type2">
								<table>
									<colgroup>
										<col style={{ width: '100px' }} />
										<col />
									</colgroup>
									<tbody>
										<tr>
											<th>
												사용자명<span className="star">*</span>
											</th>
											<td>
												<input
													type="text"
													ref={(ref) => (this.userNm = ref)}
													name="userNm"
													className="size01"
													value={userNm}
													onChange={(e) => UserPopupStore.setUserNm(e.target.value)}
													onKeyDown={(e) => {
														if (e.key == 'Enter') this.getUserInfo();
													}}
												/>
												<a href="#;" className="btn01 search" onClick={this.getUserInfo}>
													<span>검색</span>
												</a>
												<span style={{ marginLeft: 20 }}>{countText}</span>
											</td>
										</tr>
										<tr>
											<td colSpan="2">
												{/* list출력부분 */}
												<div
													style={{
														height: '380px',
														overflowX: 'hidden',
														overflowY: 'auto',
													}}
												>
													<div>
														<List component="nav">
															{UserPopupStore.userList.map((item, idx) => (
																<ListItem button key={idx}>
																	<ListItemIcon>
																		<ArrowRight />
																	</ListItemIcon>
																	<ListItemText
																		inset
																		primary={this.primaryTitle(item)}
																		secondary={this.secondaryTitle(item)}
																		onClick={() => {
																			UserPopupStore.closePopup(item);
																		}}
																	/>
																</ListItem>
															))}
														</List>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div
								className="btnClose"
								onClick={() => {
									UserPopupStore.closePopup(undefined);
								}}
							>
								<a>팝업닫기</a>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}
}
export default observer(UserPopup);
