import { httpGet, httpPost, httpPut, toQueryString } from '../Service';

// 조회
export function getDelInsuInfo(delAskNo) {
	return httpGet('/api/v1.0/erp/delInsuSms/' + delAskNo);
}

// 등록 카운트 조회
export function getDelInsuInfoCnt(delAskNo) {
	return httpGet('/api/v1.0/erp/delInsuSms/' + delAskNo + '/cnt');
}

// 인수인정보/서명 저장
export function postDelInsuInfo(data) {
	return httpPost('/api/v1.0/erp/delInsuSms/' + data.P_DEL_ASK_NO, { body: data });
}
