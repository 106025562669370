import React, { Component } from 'react';
import { makeObservable, observable, action } from 'mobx';
import { observer } from 'mobx-react';

const KYUseageDaysPopStore = observable({
	open: false,
	custNo: '',

	openPopup(custNo) {
		this.open = true;
		this.custNo = custNo;
	},
	closePopup() {
		this.open = false;
	},
});

export default KYUseageDaysPopStore;
