import React from 'react'
import Script from 'react-load-script'

class IBSheet extends React.Component {
	constructor(props) {
		super(props)

		this.sheetinfo = "_" + this.props.id;
		if (!window[this.sheetinfo]) {
		  window[this.sheetinfo] = {}
		  
		}
		this.container = this.props.id + "-container";
	}

	leadersLoaded() {
		this.leaders_loaded = true;
		if (this.allLoaded()) this.createSheet()
	}

	initDataLoaded() {
		this.initData_loaded = true;
		if (!window[this.sheetinfo].initData) {
		  window[this.sheetinfo].initData = window.initData
		}
		if (this.allLoaded()) this.createSheet()
	}

	dataLoaded() {
		this.data_loaded = true;
		if (!window[this.sheetinfo].data) {
		  window[this.sheetinfo].data = window.data
		}
		if (this.allLoaded()) this.createSheet()
	}

	scriptLoaded() {
		this.script_loaded = true;
		if (this.allLoaded()) this.createSheet()
	}

	infoLoaded() {
		this.info_loaded = true;
		if (!this.createIBSheet2) this.createIBSheet2 = window.createIBSheet2
		if (!this.IBS_InitSheet) this.IBS_InitSheet = window.IBS_InitSheet
		if (this.allLoaded()) this.createSheet()
	}  

	allLoaded() {
		return this.leaders_loaded && this.script_loaded && this.info_loaded && this.data_loaded && this.initData_loaded;
	}

	createSheet() {
		var container = document.getElementById(this.container);
		// row 클릭이벤트가 있는 경우
		if (this.props.onClick) {
			window.createSheet(container, this.props.id, this.props.width, this.props.height, window[this.sheetinfo].initData, this.props.onClick);
		} else {
			window.createSheet(container, this.props.id, this.props.width, this.props.height, window[this.sheetinfo].initData);
		}
		if(typeof this.props.sheetRendered === 'function') {
			this.props.sheetRendered(window[this.props.id]);
		}
	}

	render() {
		return <div>
			<div id={this.container} style={{width: this.props.width, height: this.props.height}} />
			<Script url={"/ibsheet/ibleaders.js"} onLoad={this.leadersLoaded.bind(this)} />
			<Script url={"/ibsheet/ibsheet.js"} onLoad={this.scriptLoaded.bind(this)} />
			<Script url={"/ibsheet/ibsheetinfo.js"} onLoad={this.infoLoaded.bind(this)} />
			<Script url={"/gridInitData/" + this.props.initData + ".js"} onLoad={this.initDataLoaded.bind(this)} />
			<Script url={"/gridInitData/" + this.props.initData + ".js"} onLoad={this.dataLoaded.bind(this)} />
		</div>;
	}
}

export default IBSheet

