import React, { Component } from 'react';
import { errorMsgCheck } from '../../common/ComFunction';
import { getAppBuildInfo } from '../../service/admin/AppManageService';

export default class Fms extends Component {
	constructor(props) {
		super(props);
		this.state = {
			url: '',
		};
	}

	componentDidMount = () => {
		let param = {
			platform: 'ios',
			appName: 'FMS',
		};
		getAppBuildInfo(param)
			.then((r) => {
				let obj = null;
				if (r.length > 0) {
					obj = r[0];
					this.setState({
						url: 'itms-services://?action=download-manifest&url=' + obj.manifestUrl,
					});
				}
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {});
	};

	render() {
		return (
			<div className="fms">
				<div className="vertical">
					<div className="in">
						<div>
							<div>
								<h1 style={{ fontSize: 25 }}>AJ렌탈 FMS 앱 다운로드</h1>
								<br />
							</div>
							{/* <p>
							AJ네트웍스㈜(이하 '회사'라 한다)가 취급하는 모든 개인정보는 개인정보보호법 등 관련 법령상의 개인정보보호 규정을 준수하여 이용자의 개인정보 보호 및
							권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.
						</p> */}
							<a className="button" title="AJ렌탈 FMS(아이폰용) - 설치하기" href={this.state.url} onClick={() => console.log(this.state.url)}>
								AJ렌탈 FMS(아이폰용) - 설치하기
							</a>
						</div>
						<br />
						<div>
							<img className="img" src={'https://mgw.ajway.kr/ekp/docs/iosApp/img/aj_iphone01.png'} />
							<img className="img" src={'https://mgw.ajway.kr/ekp/docs/iosApp/img/aj_iphone02.png'} />
							<img className="img" src={'https://mgw.ajway.kr/ekp/docs/iosApp/img/aj_iphone03.png'} />
							<img className="img" src={'https://mgw.ajway.kr/ekp/docs/iosApp/img/aj_iphone04.png'} />
							<img className="img" src={'https://mgw.ajway.kr/ekp/docs/iosApp/img/aj_iphone05.png'} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
