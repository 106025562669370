import { httpGet, httpPost, httpPut, toQueryString } from '../Service';

// 고객 api 할당 키 조회
export function getCustApiAllocList(data) {
	return httpGet('/api/v1.0/cis/apiAlloc/list' + toQueryString(data));
}

// 고객 api 할당 키 저장
export function saveCustApiAlloc(data) {
	data = data || {};
	return httpPut('/api/v1.0/cis/apiAlloc/save', { body: data });
}

// api 할당 정보 조회
export function getAllocList(data) {
	return httpGet('api/v1.0/cis/apiAlloc/allocList' + toQueryString(data));
}

// api 미할당 정보 조회
export function getNoAllocList(data) {
	return httpGet('api/v1.0/cis/apiAlloc/noAllocList' + toQueryString(data));
}
