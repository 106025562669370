import React, { Component, Fragment } from 'react';
import { apiMisreturnExchangeList } from '../../service/admin/ApiMisreturnManagerService';
import { errorMsgCheck } from '../../common/ComFunction';
import { ArrowRight } from '@material-ui/icons';
import { Dialog } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ProgressStore, ApiMisreturnSearchPopupStore } = CommonStore;

class ApiMisreturnSearchPopup extends Component {
	//고객사 조회
	search = () => {
		if (ApiMisreturnSearchPopupStore.contNo === '') {
			//alert('검색어를 입력하셔야합니다.');
			ModalStore.openModal('검색어를 입력하셔야합니다.');
			this.contNo.focus();
			return;
		}
		let param = {
			contNo: ApiMisreturnSearchPopupStore.contNo
		};
		ProgressStore.activeProgress();
		apiMisreturnExchangeList(param)
			.then((r) => {
				ApiMisreturnSearchPopupStore.setList(r);
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};
	primaryTitle = (item) => {
		return item.custReqNo;
	};

	secondaryTitle = (item) => {

		return '계약번호 : ' + item.contNo;
	};

	render() {
		const { open } = ApiMisreturnSearchPopupStore;

		let popContHeight = {
			height: 600,
		};

			// 고객사, 유지보수사, 제휴사
		return (
			<div>
				<Dialog open={open} onClose={ApiMisreturnSearchPopupStore.closePopup}>
					<div className="popSmArea searchPop">
						<div className="popCont" style={popContHeight}>
							<div className="h2Wrap">
								<h2>
									교체대상 검색 <span>*는 필수항목입니다.</span>
								</h2>
							</div>
							<div style={{float: 'right', fontSize: '12px'}}>
								(반납요청한 계약기준 목록)
							</div>
							<div className="writeType02 type2">
								<table>
									<colgroup>
										<col style={{ width: '160px' }} />
										<col />
									</colgroup>
									<tbody>
										<tr>
											<th>
												교체대상(계약번호)
												<span className="star">*</span>
											</th>
											<td>
												<input
													type="text"
													ref={(ref) => (this.contNo = ref)}
													name="contNo"
													className="size01"
													value={ApiMisreturnSearchPopupStore.contNo}
													onChange={(e) => ApiMisreturnSearchPopupStore.setContNo(e.target.value)}
													onKeyDown={(e) => {
														if (e.key == 'Enter') this.search();
													}}
												/>
												<a href="#;" className="btn01 search" onClick={this.search}>
													<span>검색</span>
												</a>
											</td>
										</tr>
										<tr>
											<td colSpan="2">
												{/* list출력부분 */}
												<div style={{ height: '380px', overflowX: 'hidden', overflowY: 'auto' }}>
													<div>
														<List component="nav">
															{ApiMisreturnSearchPopupStore.list.map((item, idx) => (
																<ListItem button key={idx}>
																	<ListItemIcon>
																		<ArrowRight />
																	</ListItemIcon>
																	<ListItemText
																		inset
																		primary={this.primaryTitle(item)}
																		secondary={
																			<Fragment>
																				{this.secondaryTitle(item)
																					.split('\n')
																					.map((line, i) => {
																						return (
																							<Fragment key={i}>
																								{line}
																								<br />
																							</Fragment>
																						);
																					})}
																			</Fragment>
																		}
																		onClick={() => {
																			ApiMisreturnSearchPopupStore.closePopup(item);
																		}}
																	/>
																</ListItem>
															))}
														</List>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							{/* <div className="btnCenter">
								<a className="btn02 blue">등록</a>
								<a className="btn02 white" onClick={ApiMisreturnSearchPopupStore.closePopup}>취소</a>
							</div> */}
							<div
								className="btnClose"
								onClick={() => {
									ApiMisreturnSearchPopupStore.closePopup(undefined);
								}}
							>
								<a>팝업닫기</a>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}
}

export default observer(ApiMisreturnSearchPopup);
