import React, { Component } from 'react';
import { makeObservable, observable, action } from 'mobx';
import { observer } from 'mobx-react';

const UserPopupStore = observable({
	open: false,
	custNo: '',
	handleClose: null,
	type: '', //타입 user : user_info_new 기준 조회. rep : cis_user 기준 대표고객사 사용자 포함 조회. 값 없을 시 cis_user 기준 조회
	userNm: '', //유저명
	countText: '', //카운터
	userList: [], //유저리스트
	searched: false, // 팝업 오픈시 리스트 조회됨 여부

	openPopup(type, custNo, handleClose) {
		this.open = true;
		if (type) this.type = type;
		if (custNo) this.custNo = custNo;
		if (handleClose) this.handleClose = handleClose;
	},
	closePopup(item) {
		this.open = false;
		if (this.handleClose) {
			this.handleClose(item);
		}

		this.type = '';
		this.custNo = '';
		this.userNm = '';
		this.countText = '';
		this.userList = [];
		this.searched = false;
	},
	setUserNm(userNm) {
		this.userNm = userNm;
	},
	setCountText(countText) {
		this.countText = countText;
	},
	setUserList(list) {
		this.userList = list;
	},
	setSearched(v) {
		this.searched = v;
	},
});

export default UserPopupStore;
