import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
// import { isWindows, isMacOs } from 'react-device-detect';
import commCss from './css/common.css?v=01';

import Header from './containers/include/Header';

/* 홈 */
import Home from './containers/Home';
import HDEC from './containers/HDEC';
import HDENG from './containers/HDENG';
import DDI from './containers/DDI';
import HC from './containers/HCO';
import HNS from './containers/HNS';
import KMILogin from './containers/KMILogin';
import Login from './containers/Login';
/* 렌탈 */
import RentalOrder from './containers/rental/RentalOrder';
import RentalOrderSearch from './containers/rental/RentalOrderSearch';
import RentalOrderAppr from './containers/rental/RentalOrderAppr';
import RentalOrderApprLink from './containers/rental/RentalOrderApprLink';
import ExpAssetSearch from './containers/rental/ExpAssetSearch';
import MonthEnd from './containers/rental/MonthEnd';
import RentalStatusSearch from './containers/rental/RentalStatusSearch';
import ReturnAsk from './containers/rental/ReturnAsk';
import ReturnAskManual from './containers/rental/ReturnAskManual';
/* 고객전용 */
import MgPrntOrder from './containers/customer_private/MG/MgPrntOrder';
import MgPrntOrderSearch from './containers/customer_private/MG/MgPrntOrderSearch';
import MpcAssetManager from './containers/customer_private/MPC/MpcAssetManager';
import BonifAssetManager from './containers/customer_private/BONIF/BonifAssetManager';
import NonOrdeAssetManager from './containers/customer_private/NonOrdeAssetManager';
import PrntMntnCounter from './containers/customer_private/PrntMntnCounter';
/* 관리자 */
import CustManager from './containers/admin/CustManager';
import MemberAppr from './containers/admin/MemberAppr';
import CustItemManager from './containers/admin/CustItemManager';
import ContractProc from './containers/admin/ContractProc';
import ContractTrans from './containers/admin/ContractTrans';
import ErpDataSync from './containers/admin/ErpDataSync';
import MenuManager from './containers/admin/MenuManager';
import AuthManager from './containers/admin/AuthManager';
import BannerManager from './containers/admin/BannerManager';
import MainCategoryManager from './containers/admin/MainCategoryManager';
import CustIFManager from './containers/admin/CustIFManager';
import CustIFCompManager from './containers/admin/CustIFCompManager';
import AssetManager from './containers/admin/AssetManager';
import MallOrder from './containers/admin/MallOrder';
import MallRegularPay from './containers/admin/MallRegularPay';
import MallMoCpManager from './containers/admin/MallMoCpManager';
import MallMoCpProd from './containers/admin/MallMoCpProd';
import MallMoCpCategory from './containers/admin/MallMoCpCategory';
import CommCodeManager from './containers/admin/CommCodeManager';
import AppVerManager from './containers/admin/AppVerManager';
import AppBuildManager from './containers/admin/AppBuildManager';
import ApiVerifyKeyManager from './containers/admin/ApiVerifyKeyManager';
import ApiUrlManager from './containers/admin/ApiUrlManager';
import ApiAllocManager from './containers/admin/ApiAllocManager';
import ApiInputManager from './containers/admin/ApiInputManager';
import ApiStatusSendManager from './containers/admin/ApiStatusSendManager';
import ApiHandManager from './containers/admin/ApiHandManager';
import ApiEncManager from './containers/admin/ApiEncManager';
import ApiReprRequestSendManager from './containers/admin/ApiReprRequestSendManager';
import ApiContractProc from './containers/admin/ApiContractProc';
import CustomizeManager from './containers/admin/CustomizeManager';
import ApiMisreturnManager from './containers/admin/ApiMisreturnManager';
import ApiBillingStatementManager from './containers/admin/ApiBillingStatementManager';


/* 고객관리 */
import MemberManager from './containers/members/MemberManager';
import SpotManager from './containers/members/SpotManager';
import FixApprManager from './containers/members/FixApprManager';
/* 포인트 */
import PointSearch from './containers/point/PointSearch';
/* 유지보수 */
import MntncAlloc from './containers/mntnc/MntncAlloc';
import MntncAssetAlloc from './containers/mntnc/MntncAssetAlloc';

/* 개인정보활용지침 */
import Privacy from './containers/include/Privacy';
/* FMS 엔터프라이즈 앱 다운 페이지 */
import Fms from './containers/include/Fms';
/* ERP */
/* 인수인도서 확인 서명 페이지 */
import DelInsuSms from './containers/erp/DelInsuSms';

import 'moment/locale/ko';

// import { version } from '../package.json';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Common from './containers/include/Common';

export default class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoggedIn: null,
		};
	}
	/*
	componentWillMount = async () => {
		// if (navigator.platform.indexOf('Mac') > -1) {
		// 	// 20200703 kkj os별 css 별도적용
		// 	require('./css/commonMac.css?v=01');
		// } else {
		// 	require('./css/common.css?v=01');
		// }
		if (navigator.platform.indexOf('Mac') > -1) {
			const { common } = await import('./css/commonMac.css?v=01');
		} else {
			const { common } = await import('./css/common.css?v=01');
		}
	};
*/
	render() {
		const isProduction = process.env.NODE_ENV === 'production';
		// console.log(version + '/' + isProduction);
		return (
			<BrowserRouter>
				<div>
					<div id="skipnavigation">
						<a href="#container">본문내용 바로가기</a>
					</div>
					<div className="wrap">
						{/* <Header/> */}
						{/* <div className="container" id="container">
                            <div className="inner">                         */}
						<Route exact path="/" render={() => <Redirect to="/Login" />} />
						<Route path="/Home" component={Home} />
						<Route path="/HDEC" component={HDEC} />
						<Route path="/HDENG" component={HDENG} />
						<Route path="/DDI" component={DDI} />
						<Route path="/HC" component={HC} />
						<Route path="/HNS" component={HNS} />
						<Route path="/KMI" component={KMILogin} />
						<Route path="/Login" component={Login} />
						<Switch>
							<Route path="/RentalOrder/:ordeNo" component={RentalOrder} />
							<Route path="/RentalOrder" component={RentalOrder} />
						</Switch>
						<Route path="/RentalOrderSearch" component={RentalOrderSearch} />
						<Route path="/RentalOrderAppr" component={RentalOrderAppr} />
						<Route path="/RentalOrderApprLink" component={RentalOrderApprLink} />
						<Route path="/RentalStatusSearch" component={RentalStatusSearch} />
						<Route path="/ReturnAsk" component={ReturnAsk} />
						<Route path="/ExpAssetSearch" component={ExpAssetSearch} />
						<Route path="/MonthEnd" component={MonthEnd} />
						{/*
                                <Route path="/MgPrntOrder" component={MgPrntOrder}/>
                                <Route path="/MgPrntOrderSearch" component={MgPrntOrderSearch}/>
                                <Route path="/PrntMntnCounter" component={PrntMntnCounter}/>
                            */}
						<Route path="/MpcAssetManager" component={MpcAssetManager} />
						<Route path="/BonifAssetManager" component={BonifAssetManager} />
						<Route path="/NonOrdeAssetManager" component={NonOrdeAssetManager} />

						<Route path="/CustManager" component={CustManager} />
						<Route path="/MemberAppr" component={MemberAppr} />
						<Route path="/CustItemManager" component={CustItemManager} />
						<Route path="/ContractProc" component={ContractProc} />
						<Route path="/ContractTrans" component={ContractTrans} />
						<Route path="/ErpDataSync" component={ErpDataSync} />
						<Route path="/MenuManager" component={MenuManager} />
						<Route path="/AuthManager" component={AuthManager} />
						<Route path="/BannerManager" component={BannerManager} />
						<Route path="/MainCategoryManager" component={MainCategoryManager} />
						<Route path="/CustIFManager" component={CustIFManager} />
						<Route path="/CustIFCompManager" component={CustIFCompManager} />
						<Route path="/AssetManager" component={AssetManager} />
						<Route path="/MallOrder" component={MallOrder} />
						<Route path="/MallRegularPay" component={MallRegularPay} />
						<Route path="/MallMoCpManager" component={MallMoCpManager} />
						<Route path="/MallMoCpProd" component={MallMoCpProd} />
						<Route path="/MallMoCpCategory" component={MallMoCpCategory} />
						<Route path="/CommCodeManager" component={CommCodeManager} />
						<Route path="/AppVerManager" component={AppVerManager} />
						<Route path="/AppBuildManager" component={AppBuildManager} />
						<Route path="/ApiVerifyKeyManager" component={ApiVerifyKeyManager} />
						<Route path="/ApiUrlManager" component={ApiUrlManager} />
						<Route path="/ApiAllocManager" component={ApiAllocManager} />
						<Route path="/ApiInputManager" component={ApiInputManager} />
						<Route path="/ApiStatusSendManager" component={ApiStatusSendManager} />
						<Route path="/ApiHandManager" component={ApiHandManager} />
						<Route path="/ApiEncManager" component={ApiEncManager} />
						<Route path="/ApiReprRequestSendManager" component={ApiReprRequestSendManager} />
						<Route path="/ApiContractProc" component={ApiContractProc} />
						<Route path="/MemberManager" component={MemberManager} />
						<Route path="/SpotManager" component={SpotManager} />
						<Route path="/FixApprManager" component={FixApprManager} />
						<Route path="/PointSearch" component={PointSearch} />
						<Route path="/MntncAlloc" component={MntncAlloc} />
						<Route path="/MntncAssetAlloc" component={MntncAssetAlloc} />
						<Route path="/Privacy" component={Privacy} />
						<Route path="/Fms" component={Fms} />
						<Route path="/CustomizeManager" component={CustomizeManager} />
						<Route path="/ReturnAskManual" component={ReturnAskManual} />
						<Route path="/ApiMisreturnManager" component={ApiMisreturnManager} />
						<Route path="/ApiBillingStatementManager" component={ApiBillingStatementManager} />

						<Route path="/DelInsuSms/:delAskNo" component={DelInsuSms} />
						{/* </div>
                        </div> */}
					</div>
					<Common />
				</div>
			</BrowserRouter>
		);
	}
}
