import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { observer } from 'mobx-react';
import ProgressStore from '../../common/ProgressStore';

const Progress = (props) => {
	return (
		<div>
			<div
				style={{
					top: '50%',
					left: '53%',
					position: 'absolute',
					zIndex: 10000,
					transform: 'translateX(-50%)translate(-50%)',
					display: ProgressStore.loadingData ? 'block' : 'none',
				}}
			>
				<CircularProgress size={80} thickness={6} color="secondary" />
			</div>
			<div
				style={{
					position: 'absolute',
					width: '100%',
					height: '100%',
					backgroundColor: '#fefefe',
					zIndex: 9999,
					top: 0,
					opacity: 0,
					display: ProgressStore.loadingData ? 'block' : 'none',
				}}
			></div>
		</div>
	);
};

export default observer(Progress);
