import {httpGet,httpPost,httpPut,toQueryString} from '../Service';

// 인터페이스 리스트 조회
export function geOrdeInterfaceList(data){
    return httpGet('/api/v1.0/cis/interface/list'+toQueryString(data));
}

// 인터페이스 발주 고객 정보
export function getCustInterfaceCustInfo(data){
    return httpGet('/api/v1.0/cis/interface/cust/info'+toQueryString(data));
}

// 인터페이스 제품 리스트
export function getReqInterfaceItemList(data){
    return httpGet('/api/v1.0/cis/interface/list/item'+toQueryString(data));
}

// 인터페이스 재전송
export function sendCustInterfaceData(data){
    data = data || {};
    return httpPost('/api/v1.0/cis/insteface/cust/',{body:data});
}