import React, { useState } from 'react';
import Slide from '@material-ui/core/Slide';

import DeskTopIcon from '@material-ui/icons/Devices';
import NotebookIcon from '@material-ui/icons/Computer';
import MonitorIcon from '@material-ui/icons/Dvr';
import ServerIcon from '@material-ui/icons/Dock';
import PrintIcon from '@material-ui/icons/LocalPrintshop';
import SwIcon from '@material-ui/icons/PictureAsPdf';
import MemIcon from '@material-ui/icons/Memory';
import SsdIcon from '@material-ui/icons/SdCard';

import * as XLSX from 'xlsx';
import { CUST_NO_AJ } from './ComValues';
import { getCustomData } from '../service/CommonService';

export function dateToFormat(paramDate) {
	let retrunDate = '';

	if (paramDate !== '' && paramDate !== undefined && paramDate !== null) {
		let date = new Date(paramDate);

		var yyyy = date.getFullYear().toString();
		var mm = (date.getMonth() + 1).toString();
		var dd = date.getDate().toString();
		if (mm.length === 1) mm = '0' + mm;
		if (dd.length === 1) dd = '0' + dd;

		retrunDate = yyyy + '-' + mm + '-' + dd;
	}
	return retrunDate;
}

export function timeToFormat(time) {
	if (time === null) {
		return;
	}
	//console.log(time);
	var hh = time.getHours();
	var mi = time.getMinutes();

	if (hh.length === 1) hh = '0' + hh;
	if (mi.length === 1) mi = '0' + mi;

	return hh + ':' + mi;
}

export function numberCheck(num) {
	let checker = /\d/;
	return !checker.test(num) ? alert('숫자만 입력해 주세요.') : true;
}

export function mailCheck(addr) {
	let checker = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
	return !checker.test(addr) ? false : true;
}

export function getTodayWeek(v) {
	/*
	 * 해당일자의 당월 주차를 구한다.
	 * 일요일 ~ 토요일 (0 ~ 6)
	 */
	let year = dateToFormat(v).substring(0, 4);
	let month = dateToFormat(v).substring(5, 7);

	let thisMonthFirstDate = new Date(year, month - 1, 1);
	let nextMonthFirstDate = new Date(year, month, 1);
	let thisMonthLastDate = new Date(nextMonthFirstDate - 1);

	let monthFirstDayOfWeek = null;

	//이번달 1일을 구하여 무슨요일인지 확인
	let firstAddDayOfWeek = getAddDayOfWeek(thisMonthFirstDate);

	//달의 1일이 수요일 이후이면 당월 첫주의 첫날을 변경한다.
	if (firstAddDayOfWeek < 4) {
		monthFirstDayOfWeek = new Date(thisMonthFirstDate.getFullYear(), thisMonthFirstDate.getMonth(), thisMonthFirstDate.getDate() + firstAddDayOfWeek);
	} else {
		// 1일이 수요일 이면 전달 일,월,화는 당월 1주차가 된다
		monthFirstDayOfWeek = thisMonthFirstDate;
	}

	let thisWeek = 1;
	if (v < monthFirstDayOfWeek) {
		//이번달 1주차 시작일보다 작으면 무조건 전달 5주차 이다.
		thisWeek = 5;
	} else {
		while (monthFirstDayOfWeek <= thisMonthLastDate) {
			let thisDay = monthFirstDayOfWeek;
			let d = new Date(thisDay.getFullYear(), thisDay.getMonth(), thisDay.getDate() + getAddDayOfWeek(thisDay));

			if (v >= monthFirstDayOfWeek && v <= d) {
				break;
			} else {
				monthFirstDayOfWeek = d;
				thisWeek++;
			}
		}
	}
	return thisWeek;
}

export function getAddDayOfWeek(date) {
	let dayOfWeek = date.getDay();

	let addDay = 0;
	if (dayOfWeek === 0) {
		addDay = 7;
	} else if (dayOfWeek === 1) {
		addDay = 6;
	} else if (dayOfWeek === 2) {
		addDay = 5;
	} else if (dayOfWeek === 3) {
		addDay = 4;
	} else if (dayOfWeek === 4) {
		addDay = 3;
	} else if (dayOfWeek === 5) {
		addDay = 2;
	} else if (dayOfWeek === 6) {
		addDay = 1;
	}

	return addDay;
}

export function getDateFriday(date) {
	let d = new Date(date);

	let dayOfWeek = d.getDay(); // 일요일~토요일(0~6)
	let addDate = 0;

	// 일요일은 2일을 빼서 금요일을 구한다.
	if (dayOfWeek === 0) {
		addDate = -2;
	} else {
		addDate = 5 - dayOfWeek;
	}

	// 구한날짜만큼 더한다.
	return new Date(d.getFullYear(), d.getMonth(), d.getDate() + addDate);
}

// 접속환경 체크
export function getHostType() {
	if (window.location.hostname.indexOf('localhost') > -1 || window.location.hostname.indexOf('127.0.0.1') > -1) {
		//로컬테스트환경
		return 'L';
	} else if (window.location.hostname.indexOf('dev') > -1) {
		//테스트서버
		return 'D';
	} else {
		//운영서버
		return 'P';
	}
}

//에러를 체크하여 메인화면(로그인 페이지로 이동한다)
export function errorMsgCheck(err, props, func) {
	let errMsg = err.message;
	errMsg = errMsg.toLowerCase();
	console.log('errMsg = ' + errMsg);

	// 20180919 kkj session token 안맞을 시에도 세션 종료로 인식 추가
	if (
		errMsg.indexOf('already read') > -1 ||
		errMsg.indexOf('unauthorized') > -1
		// || errMsg.indexOf('failed to execute') > -1
	) {
		alert('세션이 종료 되었습니다. 다시 로그인 해주세요.');
		sessionStorage.accessToken = '';
		//20180906 kkj 건설장비 전용 sfa로 redirect
		//window.location.assign('http://sfa.ajr.co.kr');
		//20180919 kkj 서버 환경별로 redirect 분기처리
		if (window.location.hostname.indexOf('localhost') > -1 || window.location.hostname.indexOf('127.0.0.1') > -1) {
			//로컬테스트환경
			window.location.assign('http://localhost:3000');
		} else if (window.location.hostname.indexOf('dev') > -1) {
			//테스트서버
			window.location.assign('http://dev-cis.networks.ajway.kr');
		} else {
			//운영서버
			window.location.assign('http://cis.networks.ajway.kr');
		}
		return;
	} else {
		if (props !== undefined) {
			// props로 전달한 경우
			// props.handleProgress(false);
		} else if (func !== undefined) {
			// 익명함수로 전달한 겨우
			func();
		}
		alert(errMsg);
	}
}

//관리자 체크
export function managerCheck() {
	if (
		!(
			JSON.parse(sessionStorage.getItem('customData')).hasOwnProperty('CT011') &&
			(sessionStorage.authCode === '0' || sessionStorage.authCode === '1' || sessionStorage.authCode === '10')
		)
	) {
		// aj네트웍스이면서 관리자 또는 현업 권한 체크
		alert('해당사용자는 관리자가 아닙니다.');
		if (window.location.hostname.indexOf('localhost') > -1 || window.location.hostname.indexOf('127.0.0.1') > -1) {
			//로컬테스트환경
			window.location.assign('http://localhost:3000');
		} else if (window.location.hostname.indexOf('dev') > -1) {
			//테스트서버
			window.location.assign('http://dev-cis.networks.ajway.kr');
		} else {
			//운영서버
			window.location.assign('http://cis.networks.ajway.kr');
		}
		return;
	}
}

export function getThisWeekMonday(v) {
	// 일요일 ~ 토요일 (0 ~ 6)
	let d = new Date(v);

	let dayOfWeek = d.getDay();
	let addDay = 0;

	switch (dayOfWeek) {
		case 0: //일요일은 전주 월요일로
			addDay = -5;
			break;
		case 1: //월
			addDay = 0;
			break;
		case 2: //화
			addDay = -1;
			break;
		case 3: //수
			addDay = -2;
			break;
		case 4: //목
			addDay = -3;
			break;
		case 5: //금
			addDay = -4;
			break;
		case 6: //토
			addDay = -5;
			break;
	}

	return new Date(d.getFullYear(), d.getMonth(), d.getDate() + addDay);
}

export function checkValidThisDay(v) {
	let d = new Date(v);
	//토요일,일요일이면 금요일 날짜를 리턴한다.
	if (d.getDay() === 0) {
		d = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 2);
	} else if (d.getDay() === 6) {
		d = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1);
	}

	return d;
}

export function createCookie(cookieName, cookieValue, expireDate) {
	var today = new Date();
	//today.setDate( today.getDate() + parseInt(expireDate));
	today.setTime(today.getTime() + expireDate * 60 * 60 * 1000);
	document.cookie = cookieName + '=' + escape(cookieValue) + '; path=/; expires=' + today.toGMTString() + ';';
}

export function createCookieDay(name, value, days) {
	createCookie(name, value, days * 24);
}

export function readCookie(name) {
	var result = null;
	var nameEQ = name + '=';
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1, c.length);
		}
		if (c.indexOf(nameEQ) === 0) {
			result = c.substring(nameEQ.length, c.length);
		}
	}
	return result;
}

export function inputFieldStyle() {
	return { color: 'rgb(0, 188, 212)', fontWeight: 'bold' };
}

export function userAuth() {
	return sessionStorage.authCode;
}

export function userDeptFilterList(deptCode, auth, data) {
	let retData = [];

	for (var i in data) {
		if (auth === '1') {
			if (deptCode === '04' || deptCode === '07') {
				retData[i] = data[i];
			}
		} else if (auth !== '1') {
			if (deptCode === '04') {
				retData[i] = data[i];
			}
		}
		//이곳에다가 다른부서는 추가한다.
	}

	return retData;
}

export function getAddDay(n, strDate) {
	var d = new Date(strDate);
	var addDate = d.getTime() + parseInt(n) * 24 * 60 * 60 * 1000;
	d.setTime(addDate);

	return new Date(leadingZeros(d.getFullYear(), 4) + '-' + leadingZeros(d.getMonth() + 1, 2) + '-' + leadingZeros(d.getDate(), 2));
}

export function leadingZeros(date, digits) {
	var zero = '';
	date = date.toString();
	if (date.length < digits) {
		for (var i = 0; i < digits - date.length; i++) zero += '0';
	}
	return zero + date;
}

export function textLength(text) {
	return text.toString().length;
}

export function comClickSubMenu(upprMenuCode, menuCode) {
	console.log('shouldComponentUpdate 처리 하세요!');
	if (upprMenuCode != null) {
		//대분류 메뉴 active class적용을 위한 pageId값 설정
		sessionStorage.activePageId = upprMenuCode;
	}
	//현재 페이지의 pageId값 설정
	sessionStorage.activeSubPageId = menuCode;
}
/*
export function LoadingProgress(props){
    console.log(props );
    return (
        <div style={{top:'50%', left:'53%',position: 'absolute', transform:'translateX(-50%)translate(-50%)', display: props.status ? 'block' : 'none'}}>
            <CircularProgress size={80}  thickness={6} color="secondary"/>	
        </div>
    )
}
*/

export function Transition(props) {
	return <Slide direction="up" {...props} />;
}

/*
    텍스트 뉴라인 적용
*/
export function textNewLine(paramTxt) {
	return paramTxt.split('\n').map((text) => (
		<span>
			{text}
			<br />
		</span>
	));
}

export function toQueryString(data) {
	let buf = [];
	Object.keys(data).forEach((k) => buf.push(k + '=' + encodeURIComponent(data[k])));
	return buf.join('&');
}

/*
    IbSheet 파라메터 데이터 생성
 */
export function SheetParamData(sheetObj, paramData, sheetCheck) {
	var sheetParamData = sheetObj.GetSaveString(0);

	/** 필수 조건이 걸리면 리턴 */
	if (sheetParamData == 'KeyFieldError') return;

	var sRow = null;
	if (sheetCheck == undefined) {
		sheetCheck = true;
	}

	/** 저장할 내용이 있는지 체크 */
	if (sheetCheck) {
		sRow = sheetObj.FindStatusRow('I|U');
		if (sRow == '') {
			alert('저장할 내역이 없습니다.');
			return;
		}
	}
	/**
	 * 사용자가 삭제를 클릭 하고 저장을 클릭 했을경우 삭제 체크를 풀어주고 DATA 가지고 있다가 저장이 완료 후에 삭제 체크를
	 * 자동으로 해준다.
	 */
	var dRow = sheetObj.FindStatusRow('D');
	var deleteRow = null;
	if (sRow != null && sRow != '') {
		deleteRow = dRow.split(';');
		for (var i = 0; i < deleteRow.length; i++) {
			sheetObj.SetCellValue(deleteRow[i], 'status', 'R');
		}
	}

	/** 시트의 파라메터 + Form의 파라미터 까지 넘겨준다 **/
	if (paramData != undefined && paramData != null) {
		sheetParamData = '?' + sheetParamData + '&' + toQueryString(paramData);
	} else {
		sheetParamData = '?' + sheetParamData;
	}

	return sheetParamData;
}

/*
    IbSheet 파라메터 데이터 생성
 */
export function SheetParamData2(sheetObj, paramData, sheetCheck) {
	var sheetParamData = sheetObj.GetSaveString(0);

	/** 필수 조건이 걸리면 리턴 */
	if (sheetParamData == 'KeyFieldError') return;

	var sRow = null;
	if (sheetCheck == undefined) {
		sheetCheck = true;
	}

	/** 저장할 내용이 있는지 체크 */
	if (sheetCheck) {
		sRow = sheetObj.FindStatusRow('I|U|D');
		if (sRow == '') {
			alert('저장할 내역이 없습니다.');
			return;
		}
	}

	/** 시트의 파라메터 + Form의 파라미터 까지 넘겨준다 **/
	if (paramData != undefined && paramData != null) {
		sheetParamData = '?' + sheetParamData + '&' + toQueryString(paramData);
	} else {
		sheetParamData = '?' + sheetParamData;
	}

	return sheetParamData;
}

/*
    IbSheet 파라메터 데이터 생성
 */
export function SheetParamData3(sheetObj, paramData, sheetCheck) {
	var sheetParamData = sheetObj.GetSaveJson(0);

	/** 필수 조건이 걸리면 리턴 */
	if (sheetParamData == 'KeyFieldError') return;

	var sRow = null;
	if (sheetCheck == undefined) {
		sheetCheck = true;
	}

	/** 저장할 내용이 있는지 체크 */
	if (sheetCheck) {
		sRow = sheetObj.FindStatusRow('I|U|D');
		if (sRow == '') {
			alert('저장할 내역이 없습니다.');
			return;
		}
	}
	/**
	 * 사용자가 삭제를 클릭 하고 저장을 클릭 했을경우 삭제 체크를 풀어주고 DATA 가지고 있다가 저장이 완료 후에 삭제 체크를
	 * 자동으로 해준다.
	 */
	var dRow = sheetObj.FindStatusRow('D');
	var deleteRow = null;
	if (sRow != null && sRow != '') {
		deleteRow = dRow.split(';');
		for (var i = 0; i < deleteRow.length; i++) {
			sheetObj.SetCellValue(deleteRow[i], 'status', 'R');
		}
	}

	/** 시트의 파라메터 + Form의 파라미터 까지 넘겨준다 **/
	if (paramData != undefined && paramData != null) {
		let rows = sheetParamData.data.length;
		for (let i = 0; i < rows; i++) {
			sheetParamData.data[i] = Object.assign(sheetParamData.data[i], paramData);
		}
	}

	return sheetParamData;
}

/*
    IbSheet 파라메터 데이터 생성

	전체 저장
	validation 없음
 */
export function SheetParamData4(sheetObj, paramData) {
	var sheetParamData = sheetObj.GetSaveJson({ AllSave: 1, ValidFullInput: 0, ValidKeyField: 0 });

	/** 필수 조건이 걸리면 리턴 */
	if (sheetParamData == 'KeyFieldError') return;

	var sRow = null;

	/**
	 * 사용자가 삭제를 클릭 하고 저장을 클릭 했을경우 삭제 체크를 풀어주고 DATA 가지고 있다가 저장이 완료 후에 삭제 체크를
	 * 자동으로 해준다.
	 */
	var dRow = sheetObj.FindStatusRow('D');
	var deleteRow = null;
	if (sRow != null && sRow != '') {
		deleteRow = dRow.split(';');
		for (var i = 0; i < deleteRow.length; i++) {
			sheetObj.SetCellValue(deleteRow[i], 'status', 'R');
		}
	}

	/** 시트의 파라메터 + Form의 파라미터 까지 넘겨준다 **/
	if (paramData != undefined && paramData != null) {
		let rows = sheetParamData.data.length;
		for (let i = 0; i < rows; i++) {
			sheetParamData.data[i] = Object.assign(sheetParamData.data[i], paramData);
		}
	}

	return sheetParamData;
}

/*
    IbSheet 파라메터 데이터 생성
	
	체크된 건만 리스트 생성
	sheetCheck는 true시에만 체크
	sheetObj에는 반드시 상태(Status) 컬럼이 필요하다. 이걸로 chk여부를 걸러냄
 */
export function SheetParamData5(sheetObj, paramData, keyFieldCheck, sheetCheck) {
	var sheetParamData;
	if (keyFieldCheck) {
		sheetParamData = sheetObj.GetSaveJson({ AllSave: 0, StdCol: 'chk' });
	} else {
		sheetParamData = sheetObj.GetSaveJson({ AllSave: 0, StdCol: 'chk', ValidFullInput: 0, ValidKeyField: 0 });
	}
	/** 필수 조건이 걸리면 리턴 */
	if (sheetParamData == 'KeyFieldError') return;

	var sRow = null;
	if (sheetCheck == undefined) {
		sheetCheck = false;
	}

	/** 저장할 내용이 있는지 체크 */
	if (sheetCheck) {
		sRow = sheetObj.FindStatusRow('I|U|D');
		if (sRow == '') {
			alert('저장할 내역이 없습니다.');
			return;
		}
	}
	/**
	 * 사용자가 삭제를 클릭 하고 저장을 클릭 했을경우 삭제 체크를 풀어주고 DATA 가지고 있다가 저장이 완료 후에 삭제 체크를
	 * 자동으로 해준다.
	 */
	var dRow = sheetObj.FindStatusRow('D');
	var deleteRow = null;
	if (sRow != null && sRow != '') {
		deleteRow = dRow.split(';');
		for (var i = 0; i < deleteRow.length; i++) {
			sheetObj.SetCellValue(deleteRow[i], 'status', 'R');
		}
	}

	/** 시트의 파라메터 + Form의 파라미터 까지 넘겨준다 **/
	if (paramData != undefined && paramData != null) {
		let rows = sheetParamData.data.length;
		for (let i = 0; i < rows; i++) {
			sheetParamData.data[i] = Object.assign(sheetParamData.data[i], paramData);
		}
	}

	return sheetParamData;
}

export function buildSelectItems(dataItems) {
	return dataItems.map((item, i) => (
		<option key={i} value={item.value}>
			{item.title}
		</option>
	));
}

export function resultToIbSheet(result) {
	// console.log(result);
	var resultData = { data: [] };
	result.map((item, i) => (resultData.data[i] = item));

	//console.log(JSON.stringify(resultData));

	return resultData;
}

export function genRandomNum(digit) {
	// min (포함) 과 max (불포함) 사이의 임의 정수를 반환
	// Math.round() 를 사용하면 고르지 않은 분포를 얻게된다!
	var num = '';
	const min = 0;
	const max = 10;

	for (var i = 0; i < digit; i++) {
		num = num + (Math.floor(Math.random() * (max - min)) + min).toString();
	}

	return num;
}

export function createItemIcon(grpCd) {
	if (grpCd === 'DT') {
		return <DeskTopIcon />;
	} else if (grpCd === 'NT') {
		return <NotebookIcon />;
	} else if (grpCd === 'MT') {
		return <MonitorIcon />;
	} else if (grpCd === 'SV') {
		return <ServerIcon />;
	} else if (grpCd === 'PT') {
		return <PrintIcon />;
	} else if (grpCd === 'SW') {
		return <SwIcon />;
	} else if (grpCd === 'SSD') {
		return <SsdIcon />;
	} else if (grpCd === 'MEM') {
		return <MemIcon />;
	} else {
		return <DeskTopIcon />;
	}
}

/*
    숫자에 콤마 찍기
*/
export function InsertComma(n) {
	if (n === 0) return n;
	if (null === n || '' === n) return;

	var reg = /(^[+-]?\d+)(\d{3})/; // 정규식
	n += ''; // 숫자를 문자열로 변환
	while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');
	return n;
}

/*
    SHEET TO EXCEL
*/
export function getSheetToExcel(fileName, sheet, design, pId, viewName, hiddenCol, downCol) {
	try {
		if (!fileName) {
			alert('파일명 필수입니다.');
			return;
		}
		if (!sheet) {
			alert('시트 필수입니다.');
			return;
		}
		if (sheet.GetTotalRows() == 0) return;
		if (!downCol) downCol = '';
		fileName = fileName.replace('xlsx', 'xls').replace('xls', 'xlsx');

		sheet.Down2Excel({
			FileName: fileName, // + excelTimeStamp() + ".xlsx"
			SheetName: sheet.id,
			SheetDesign: isNaN(design) ? 1 : design,
			HiddenColumn: hiddenCol ? hiddenCol : 1,
			DownCols: downCol,
		});
	} catch (e) {
		alert(e.message);
	}
}

export function isIE() {
	var usagFlag = false;
	var browName = 'Unknown';
	var browVers = '';
	var browMain = '';

	var browserInfo = navigator.userAgent;
	var trident = navigator.userAgent.match(/Trident\/(\d.\d)/i);
	var versionNo = 0;

	try {
		if (/MSIE/.test(navigator.userAgent)) {
			browName = 'Internet Explorer';
			browVers = /MSIE ([\d\.]+)\;/.exec(navigator.appVersion)[1];

			//alert(/MSIE ([\d\.]+)\;/.exec(navigator.appVersion)[1]);
			browMain = /(\d+)\./.exec(browVers)[1];
			switch (browMain) {
				case '7':
				case '8':
				case '9':
				case '10':
				case '11':
					usagFlag = true;
			}
		} else if (/Chrome/.test(navigator.userAgent)) {
			usagFlag = false;
			browName = 'Chrome';
			browVers = /Chrome\/([\d\.]+) Safari/.exec(navigator.appVersion)[1];
			browMain = /(\d+)\./.exec(browVers)[1];
		} else if (/Firefox/.test(navigator.userAgent)) {
			usagFlag = false;
			browName = 'Firefox';
			browVers = /Firefox\/([\d\.]+)/.exec(navigator.userAgent)[1];
			browMain = /(\d+)\./.exec(browVers)[1];
		} else if (/Safari/.test(navigator.userAgent)) {
			usagFlag = false;
			browName = 'Safari';
			browVers = /Version\/([\d\.]+) Safari/.exec(navigator.appVersion)[1];
			browMain = /(\d+)\./.exec(browVers)[1];
		} else {
			usagFlag = false;
			browName = 'Unknown';
			browVers = '';
		}

		if (trident != null && trident[1] == '4.0') {
			versionNo = 8;
			usagFlag = true;
		} else if (trident != null && trident[1] == '5.0') {
			versionNo = 9;
			usagFlag = true;
		} else if (trident != null && trident[1] == '6.0') {
			versionNo = 10;
			usagFlag = true;
		} else if (trident != null && trident[1] == '7.0') {
			versionNo = 10;
			usagFlag = true;
		} else {
			var startIndexOfVersionNo = browserInfo.indexOf('MSIE') + 5;
			var endIndexOfVersionNo = browserInfo.indexOf('; Windows');
			versionNo = browserInfo.substring(startIndexOfVersionNo, endIndexOfVersionNo);
			usagFlag = false;
		}
	} catch (err) {}
	return usagFlag;
}

export function ozWinOpen(title, fileName, odiName, param, sizeFlag) {
	var width = '';
	var height = '';

	switch (sizeFlag) {
		case '1':
			width = '800';
			height = '1100';
			break;
		case '2':
			width = '1150';
			height = '880';
			break;
		default:
			alert('잘못된 요청입니다');
	}

	ozWinOpenSize(title, fileName, odiName, param, width, height);
}

export function ozWinOpenSize(title, fileName, odiName, param, width, height) {
	var WinStatus = 'width=' + width + ', height=' + height + ', left=0, top=0, menubar=no, statusbar=no, toolbar=no';

	window.open('http://report.ajr.co.kr:8080/POP_OZ_REPORT.jsp?fileName=' + fileName + '&odiName=' + odiName + '&' + param, title, WinStatus);
}

export function ArrayTextComma(text) {
	var retText = '';
	text.split('\n').map((text, i) => {
		if (i === 0) {
			retText += text;
		} else {
			retText += ',' + text;
		}
	});

	return retText;
}

export function handleSessionStorage(userData, saveIdYn, activePageId, activeSubPageId) {
	// 20181017 kkj sessionStorage에 로그인 정보 셋팅
	sessionStorage.userId = userData.userId;
	sessionStorage.userName = userData.userName;
	sessionStorage.empno = userData.empno;
	sessionStorage.emailAddr = userData.emailAddr;
	sessionStorage.custNo = userData.custNo;
	sessionStorage.custNm = userData.custNm;
	sessionStorage.appAuthYn = userData.appAuthYn;
	sessionStorage.ordeUse = userData.ordeUse;
	sessionStorage.repCustNo = userData.repCustNo;
	sessionStorage.deptCode = userData.deptCode;
	sessionStorage.contTerm = userData.contTerm;
	sessionStorage.mntnYn = userData.mntnYn;
	sessionStorage.deptTitle = userData.deptTitle;
	sessionStorage.spotTitle = userData.spotTitle;
	sessionStorage.addrSeq = userData.addrSeq;
	sessionStorage.spotId = userData.spotId;
	sessionStorage.spotNm = userData.spotNm;
	sessionStorage.postAddr = userData.postAddr;
	sessionStorage.postSddr = userData.postSddr;
	sessionStorage.postCode = userData.postCode;
	sessionStorage.appUse = userData.appUse;
	sessionStorage.deptName = userData.deptName;
	sessionStorage.celNo = userData.celNo;
	sessionStorage.otelNo = userData.otelNo;
	sessionStorage.authCode = userData.authCode;
	sessionStorage.activePageId = activePageId === undefined ? '0000001' : activePageId;
	sessionStorage.activeSubPageId = activeSubPageId === undefined ? '0000001' : activeSubPageId;
	sessionStorage.systCode = 'CIS';
	sessionStorage.custSeq = userData.custSeq; //KT로 인해 청구지 추가. 20201019

	// 권한코드에 따른 버튼컨트롤 셋팅
	if (userData.authCode <= '1' || userData.authCode === '10' || userData.authCode === 'CC') {
		// '0' : 관리자, '1' : 현업, '10' :  mall 관리자, 'CC': 컨택센터
		sessionStorage.btnControl = 'inline-block';
	} else if (userData.authCode > '1') {
		// '2' : 고객관리자, '3' : 고객사용자, '4' : 유지보수처...
		sessionStorage.btnControl = 'none';
	}
	if (userData.authCode === '2' && userData.repCustNo === userData.custNo) {
		// 고객관리자이면서 대표고객인경우
		sessionStorage.btnControl = 'inline-block';
	}

	//토큰설정
	if (userData.accessToken) {
		sessionStorage.accessToken = userData.accessToken;
	}

	//아이디저장 쿠키설정
	if (saveIdYn) {
		createCookie('ajCisSaveUserID', userData.userId, 30);
	}

	getCustomDataList(); // 커스텀데이터 저장
}

export function csvToJson(csv) {
	const headerMatch = (header) => {
		let title = '';
		header = header.replace(' ', '');
		//console.log('header = '+header);
		switch (header) {
			case '순번':
				title = 'no';
				break;
			case '순서':
				title = 'noSeq';
				break;
			case '발주자ID':
				title = 'userId';
				break;
			case '신청품목':
				title = 'item';
				break;
			case '수량':
				title = 'itemQty';
				break;
			case '사용자부서명':
				title = 'userDeptName';
				break;
			case '사용자사번':
				title = 'userEmpno';
				break;
			case '사용자명':
				title = 'userName';
				break;
			case '비고':
				title = 'userRemark';
				break;
			case '우편번호':
				title = 'delPostCode';
				break;
			case '주소1':
				title = 'delAddr';
				break;
			case '주소2':
				title = 'delAddrDtl';
				break;
			case '수령인명':
				title = 'delUserNm';
				break;
			case '수령인부서':
				title = 'delDeptNm';
				break;
			case '휴대폰번호':
				title = 'delMobileNo';
				break;
			case '전화번호':
				title = 'delTelNo';
				break;
			case '요청사항':
				title = 'delRemark';
				break;
			case '고객사관리번호':
				title = 'custPordeNo';
				break;

			/* 사용자대량등록 추가 */
			case '고객코드':
				title = 'custNo';
				break;
			case '제휴사코드':
				title = 'alncCustNo';
				break;
			case '아이디':
				title = 'userId';
				break;
			case '비밀번호':
				title = 'passWord';
				break;
			case '사번':
				title = 'empno';
				break;
			case '이름':
				title = 'userName';
				break;
			case '부서명':
				title = 'deptName';
				break;
			case '사용자상태':
				title = 'userStatus';
				break;
			case '지점시퀀스':
				title = 'addrSeq';
				break;
			case '청구지순번':
				title = 'custSeq';
				break;
			case '이메일':
				title = 'emailAddr';
				break;
			case '직급':
				title = 'userTitle';
				break;
			case '대표전화':
				title = 'otelNo';
				break;
			case '휴대전화':
				title = 'celNo';
				break;
			case '권한':
				title = 'authCode';
				break;
			case 'AOS사용여부':
				title = 'aosYn';
				break;
		}
		//console.log('title = '+title);
		return title;
	};

	var lines = csv.split('\n');
	var result = [];
	var headers = lines[0].split(',');

	for (var j in headers) {
		headers[j] = headerMatch(headers[j]);
	}

	for (var i = 1; i < lines.length; i++) {
		var obj = {};
		var currentline = lines[i].split(',');
		//console.log(currentline);
		if (currentline != '') {
			for (var j = 0; j < headers.length; j++) {
				obj[headers[j]] = currentline[j];

				if (j + 1 == headers.length) {
					obj['itemSeq'] = '';
				}
			}
			result.push(obj);
		}
	}

	return result;
}

/**
 * IBSheet Combo 셋팅
 *
 * @param sheetObj
 * @param saveName
 * @param jsonResult
 * @param optionTextKey
 * @param optionValueKey
 */
export function ComIBSheetCombo(sheetObj, saveName, jsonResult, optionTextKey, optionValueKey) {
	var comboCode = '';
	var comboText = '';
	jsonResult.map((v, index) => {
		if (v != null && v != undefined) {
			comboCode += eval('v.' + optionValueKey) + '|';
			comboText += eval('v.' + optionTextKey) + '|';
		}
	});
	var info = {
		ComboText: comboText.substring(0, comboText.length - 1),
		ComboCode: comboCode.substring(0, comboCode.length - 1),
	};
	sheetObj.SetColProperty(saveName, info);
}

/**
 * IBSheet 특정 셀에 Combo 셋팅
 *
 * @param sheetObj
 * @param targetRow
 * @param saveName
 * @param jsonResult
 * @param optionTextKey
 * @param optionValueKey
 */
export function ComIBSheetCellCombo(sheetObj, targetRow, saveName, jsonResult, optionTextKey, optionValueKey) {
	var comboCode = '';
	var comboText = '';

	jsonResult.map((v, index) => {
		if (v != null && v != undefined) {
			comboCode += eval('v.' + optionValueKey) + '|';
			comboText += eval('v.' + optionTextKey) + '|';
		}
	});

	var info = {
		ComboText: comboText.substring(0, comboText.length - 1),
		ComboCode: comboCode.substring(0, comboCode.length - 1),
	};
	sheetObj.CellComboItem(targetRow, saveName, info);
}

/**
 * IBSheet Combo 셋팅
 *
 * @param sheetObj
 * @param saveName
 * @param jsonResult
 * @param optionTextKey
 * @param optionValueKey
 */
export function ComIBSheetComboNull(comboOption, sheetObj, saveName, jsonResult, optionTextKey, optionValueKey) {
	var comboCode = '';
	var comboText = '';
	jsonResult.map((v, index) => {
		if (index == 0 && comboOption == '0') {
			comboCode += '|';
			comboText += '|';
		}
		if (v != null && v != undefined) {
			comboCode += eval('v.' + optionValueKey) + '|';
			comboText += eval('v.' + optionTextKey) + '|';
		}
	});
	var info = {
		ComboText: comboText.substring(0, comboText.length - 1),
		ComboCode: comboCode.substring(0, comboCode.length - 1),
	};
	sheetObj.SetColProperty(saveName, info);
}

export function isEmptyObject(param) {
	return Object.keys(param).length === 0 && param.constructor === Object;
}

export function sheetCheckAll(sheet, checkValue, saveName) {
	// 필터링 된 rows
	// console.log(sheet.FilteredRowIndex());
	let rows = sheet.FilteredRowIndex();
	let chkSaveName = !saveName ? 'chk' : saveName;
	rows.map((v, i) => {
		// console.log(v);
		// editable 에 따른 체크
		let editable = sheet.GetCellEditable(v, chkSaveName);
		if (editable) sheet.SetCellValue(v, chkSaveName, !checkValue);
	});
}

// 엑셀 다운로드. json array를 csv blob으로 전환하여 다운로드
export function excelDown(data, filename) {
	var row = data;

	if (row.length === 0) {
		// this.props.modalOpen('검색된 내용이 없습니다.');
		return false; // You are stopping the handling of component
	}

	var csvRows = [];
	//헤더생성
	for (var cell = 0; cell < 1; ++cell) {
		var str = '';
		for (var key in row[cell]) {
			str += key + ',';
		}
		csvRows.push(str);
	}
	//데이터생성
	for (var cell = 0; cell < row.length; ++cell) {
		var str = '';
		for (var key in row[cell]) {
			var tmp = row[cell][key] + '';
			tmp = tmp === '' || tmp === 'null' ? '' : tmp.replace(/,/g, ''); //csv파일로 변경하므로 컬럼 스트링의 콤마는 제거
			str += tmp + ',';
		}
		str = str.replace(/['"]+/g, '');
		csvRows.push(str);
	}

	var csvString = csvRows.join('\n');

	// var csvString = csvRows.join("\r\n");

	if (window.navigator.msSaveOrOpenBlob) {
		//ie는 msSaveOrOpenBlob으로 처리. \ufeff 없으면 utf-8 적용 안됨
		var blob = new Blob(['\ufeff', csvString], { type: 'text/csv;charset=utf-8;' });
		window.navigator.msSaveOrOpenBlob(blob, filename);
	} else {
		let csvFile = new Blob(['\ufeff', csvString], { type: 'text/csv;charset=utf-8' });
		let downloadLink = document.createElement('a');
		downloadLink.download = filename;
		downloadLink.href = window.URL.createObjectURL(csvFile);
		downloadLink.style.display = 'none';
		document.body.appendChild(downloadLink);
		downloadLink.click();
	}
}

export function excelFormDownload(url, filename) {
	fetch(url).then((res) =>
		res.blob().then((blob) => {
			var downloadLink = document.createElement('a');
			var url = window.URL.createObjectURL(blob);
			var fileName = filename;
			downloadLink.href = url;
			downloadLink.download = filename;
			downloadLink.click();
			window.URL.revokeObjectURL(url);
		}),
	);
}

export function isEmpty(v) {
	if (v === undefined || v === null || v === '') {
		return true;
	} else {
		return false;
	}
}

export function telValidator(args) {
	const msg = '유효하지 않는 전화번호입니다.';
	// IE 브라우저에서는 당연히 var msg로 변경

	if (/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(args) || /^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/.test(args)) {
		return true;
	}
	// alert(msg);
	return false;
}

export function getCustomDataList() {
	// 고객 커스텀 데이터 리스트 가져오기 및 세션 저장
	getCustomData({ custNo: sessionStorage.custNo }).then((r) => {
		let dataMap = new Map();

		if (r.length > 0) {
			for (var i = 0; i < r.length; i++) {
				if (r[i].url != null && r[i].url != '') {
					dataMap.set(r[i].customCode, r[i].url);
				} else {
					dataMap.set(r[i].customCode, r[i].customWord);
				}
			}
		} else {
			dataMap.set('null', 'null');
		}

		sessionStorage.setItem('customData', JSON.stringify(Object.fromEntries(dataMap)));
	});
}
