import { httpGet, httpPost, httpPut, toQueryString } from '../Service';

// 인터페이스 업체 리스트 조회
export function getCustIFCompList(data) {
	return httpGet('/api/v1.0/cis/interface/company/list' + toQueryString(data));
}

// 인터페이스 업체 리스트 저장
export function saveCustIFComp(data) {
	data = data || {};
	return httpPut('/api/v1.0/cis/interface/company/save', { body: data });
}
