import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';
import { getApiStatusSend, putApiRetryStatusSend, getApiStatusCheck, apiSendStatusReset } from '../../service/admin/ApiStatusSendManagerService';
import { errorMsgCheck, resultToIbSheet, dateToFormat, buildSelectItems, ComIBSheetComboNull, SheetParamData5 } from '../../common/ComFunction';
import SendIcon from '@material-ui/icons/Input';
import Button from '@material-ui/core/Button';
import { getCisCommCode } from '../../service/CommonService';
import ExcelDown from '../include/ExcelDown';

//날짜
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore } = CommonStore;

class ApiStatusSendManager extends Component {
	constructor(props) {
		super(props);

		this.sheetData = {
			id: 'apiStatusSendManagerSheet', // sheet id
			initData: 'admin/Init-ApiStatusSendManager', // sheet data javascript filename
			width: '100%', // sheet width
			height: '600px', // sheet height
		};
	}
	state = {
		apiStatusList: '', // api 상태전송 리스트

		/* 검색조건 */
		reqOrdeNo: '',
		custReqNo: '',
		statusCd: '',
		procFlag: '',

		/* api 상태전송 재전송 */
		selSendIfType: '',
		/* api 재전송 상태 체크 */
		apiStatusCheck: '',

		sDate: moment(),
		eDate: moment(),

		apistCodeList: [], // 공통코드 선언
		apipfCodeList: [], // 공통코드 선언

		excelList: [],
	};

	componentDidMount = () => {
		ProgressStore.activeProgress();
		Promise.all([getCisCommCode('APIST'), getCisCommCode('APIPF')])
			.then((r) => {
				let [codeApistData, codeApipfData] = r;
				for (let i = 0; i < codeApistData.length; i++) {
					if (codeApistData[i].value === '38' || codeApistData[i].value === '41' || codeApistData[i].value === '42') {
						codeApistData.splice(i, 1);
						i--;
					}
				}
				this.setState({
					apistCodeList: codeApistData,
					apipfCodeList: codeApipfData,
				});

				const timer = setInterval(() => {
					if (window[this.sheetData.id]) {
						ComIBSheetComboNull('0', window[this.sheetData.id], 'procFlag', codeApipfData, 'title', 'value', '', '');
						ProgressStore.deactiveProgress();
						clearInterval(timer);
					}
				}, 100);
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
	};

	dateHandler = (date, type) => {
		if (type === 's') {
			this.setState({ sDate: date });
		} else if (type === 'e') {
			this.setState({ eDate: date });
		}
	};

	apiStatusList = () => {
		ProgressStore.activeProgress();

		let param = {
			ordeNo: this.state.reqOrdeNo,
			custReqNo: this.state.custReqNo,
			statusCd: this.state.statusCd,
			procFlag: this.state.procFlag,
			sDate: dateToFormat(this.state.sDate),
			eDate: dateToFormat(this.state.eDate),
		};

		getApiStatusSend(param)
			.then((r) => {
				this.setState({ apiStatusList: r }, () => {
					window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				});
				this.makeExcelData(r);
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	apiRetryStatusSend = () => {
		ProgressStore.activeProgress();

		let data = this.getSheetData();
		let status = this.state.selSendIfType;
		let param = {
			ordeNo: data.ordeNo,
			statusCd: status,
			selSendIfType: this.state.selSendIfType,
		};

		getApiStatusCheck(param)
			.then((r) => {
				this.setState({
					apiStatusCheck: r[0].statusCheck,
				});

				if (this.state.apiStatusCheck == 'Y') {
					// 재전송 할 수 있는 경우
					putApiRetryStatusSend(param)
						.then((r) => {
							ModalStore.openModal('전송이 완료되었습니다.');
						})
						.catch((err) => {
							errorMsgCheck(err);
						})
						.finally(() => {
							ProgressStore.deactiveProgress();
						});
				} else {
					// 재전송 할 수 없는 경우
					ModalStore.openModal('선택하신 상태로 재전송이 불가능합니다');
					return;
				}
			})
			.catch((err) => {
				errorMsgCheck(err);
				ProgressStore.deactiveProgress();
			});

		// if (data.statusCd === '11') {
		// 	// 현재 상태가 접수상태인데 반납신청접수 or 회수요청일 경우 전송안되도록 함
		// 	if (status === '31' || status === '32') {
		// 		ModalStore.openModal('반납신청 및 회수요청이 불가능합니다.');
		// 		return;
		// 	}
		// } else if (data.statusCd === '13' || data.statusCd === '14' || data.statusCd === '15') {
		// 	if (status === '21') {
		// 		ModalStore.openModal('렌탈신청취소가 불가능합니다.');
		// 		return;
		// 	}
		// } else if (data.statusCd === '21') {
		// 	if (status === '12' || status === '13' || status === '14' || status === '15') {
		// 		ModalStore.openModal('해당 상태로 재전송이 불가능합니다.');
		// 		return;
		// 	}
		// } else if (data.statusCd === '31' || data.statusCd === '32') {
		// 	if (status === '12' || status === '13' || status === '14' || status === '15' || status === '21') {
		// 		ModalStore.openModal('해당 상태로 재전송이 불가능합니다.');
		// 		return;
		// 	}
		// } else if (data.statusCd === '32') {
		// 	if (status === '31') {
		// 		ModalStore.openModal('이미 반납신청된 자산입니다.');
		// 		return;
		// 	}
		// }
	};

	getSheetData = () => {
		return window.getApiSendStatus();
	};

	checkSendIf = () => {
		if (this.state.selSendIfType === '') {
			ModalStore.openModal('전송상태를 선택해 주세요.');
			return;
		}
		ConfirmModalStore.openModal('전송 하시겠습니까?', this.apiRetryStatusSend);
	};
	callResetFunc = () => {
		const sheet = window[this.sheetData.id];
		const FindCheckedRow = sheet.FindCheckedRow('chk');
		const checkRows = FindCheckedRow.split('|');

		if (!FindCheckedRow) {
			ModalStore.openModal('선택된 건이 없습니다.');
			return;
		}

		ConfirmModalStore.openModal('해당건의 전송 처리여부를 초기화 하시겠습니까?', () => {
			// validation chk end
			ProgressStore.activeProgress();
			const sheetParam = SheetParamData5(sheet, null);

			apiSendStatusReset(sheetParam.data)
				.then((r) => {
					ModalStore.openModal('처리상태가 리셋되었습니다.');

					// this.resetResult(); //ibsheet 데이터 리셋
					// this.resetDtlResult(); //ibsheet 데이터 리셋
					this.apiStatusList(); // 재조회
				})
				.catch((err) => {
					errorMsgCheck(err);
				})
				.finally(() => {
					ProgressStore.deactiveProgress();
				});
		});
	};

	makeExcelData = (data) => {
		let tmpList = [];
		let tmp = {};

		data.map((item, idx) => {
			tmp = {};
			tmp.Seq = item.seq;
			tmp.고객사 = item.custNm;
			tmp.요청번호 = item.ordeNo;
			tmp.계약번호 = item.contNo;
			tmp.고객요청번호 = item.custReqNo;
			tmp.상태 = item.commKrnm;
			tmp.시도횟수 = item.retryCnt;
			tmp.처리여부 = item.procFlag;
			tmp.처리메세지 = item.procMsg;
			tmp.입력날짜 = dateToFormat(item.instDate);
			tmpList.push(tmp);
		});
		this.setState({ excelList: tmpList });
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mb30">
								<h2>API 상태전송 관리</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.apiStatusList}>
										<span>조회</span>
									</a>
									<ExcelDown data={this.state.excelList} text="엑셀" filename={'API상태전송리스트.csv'} />
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '10%' }} />
										<col style={{ width: '23%' }} />
										<col style={{ width: '10%' }} />
										<col style={{ width: '23%' }} />
										<col style={{ width: '10%' }} />
										<col style={{ width: '23%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>주문번호</th>
											<td>
												<input type="text" value={this.state.reqOrdeNo} onChange={(e) => this.setState({ reqOrdeNo: e.target.value })} />
											</td>

											<th>요청일자</th>
											<td colSpan={3}>
												<DatePicker
													customInput={<input type="text" style={{ width: 115 }} />}
													dateFormat="YYYY-MM-DD"
													selectsStart
													selected={this.state.sDate}
													onChange={(date) => this.dateHandler(date, 's')}
													startDate={this.state.sDate}
													endDate={this.state.eDate}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
												<span style={{ marginLeft: 5, marginRight: 5 }}>~</span>
												<DatePicker
													customInput={<input type="text" style={{ width: 115 }} />}
													dateFormat="YYYY-MM-DD"
													selectsEnd
													selected={this.state.eDate}
													onChange={(date) => this.dateHandler(date, 'e')}
													startDate={this.state.sDate}
													endDate={this.state.eDate}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
											</td>
										</tr>
										<tr>
											<th>고객요청번호</th>
											<td>
												<input type="text" value={this.state.custReqNo} onChange={(e) => this.setState({ custReqNo: e.target.value })} />
											</td>
											<th>상태</th>
											<td>
												<select value={this.state.statusCd} onChange={(e) => this.setState({ statusCd: e.target.value })}>
													{this.state.apistCodeList ? buildSelectItems([{ title: '선택', value: '' }].concat(this.state.apistCodeList)) : null}
												</select>
											</td>
											<th>처리여부</th>
											<td>
												<select value={this.state.procFlag} onChange={(e) => this.setState({ procFlag: e.target.value })}>
													<option value={''}>전체</option>
													<option value={'N'}>미처리</option>
													<option value={'Y'}>처리완료</option>
													<option value={'X'}>처리불가</option>
												</select>
											</td>
										</tr>
										<tr>
											{/* <th>재전송</th>
											<td colSpan={3}>
												<select value={this.state.selSendIfType} onChange={(e) => this.setState({ selSendIfType: e.target.value })} style={{ width: 150 }}>
													{apistCodeList ? buildSelectItems([{ title: '선택', value: '' }].concat(apistCodeList)) : null}
												</select>
												<Button className="button" onClick={this.checkSendIf} color="primary" variant="contained" size="small" style={{ marginLeft: 10 }}>
													<SendIcon className="saveIcon" />
													<span className="text">전송</span>
												</Button>
											</td> */}
											<th>
												처리불가건
												<br />
												리셋
											</th>
											<td colSpan={5}>
												<Button className="button" onClick={this.callResetFunc} color="primary" variant="contained" size="small" style={{ marginLeft: 10 }}>
													<SendIcon className="saveIcon" />
													<span className="text">리셋</span>
												</Button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="writeType03">
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
									onClick={() => {
										this.getSheetData();
									}}
								>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(ApiStatusSendManager);
