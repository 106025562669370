import {httpGet,httpPost,httpPut,toQueryString} from '../Service'

//결재 리스트
export function getAppList(data){
    return httpGet('/api/v1.0/cis/rental/app/list'+toQueryString(data));
}

//결재
export function updateOrderApp(data){
    data = data || {};
    return httpPut('/api/v1.0/cis/rental/app/'+data.ordeNo,{body:data});
}