import React, { Component } from 'react';

import SaveIcon from '@material-ui/icons/Save';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { Transition } from '../../common/ComFunction';

import { updateAssetInfo } from '../../service/rental/RentalAssetService';
import { errorMsgCheck } from '../../common/ComFunction';
import { CUST_NO_HKFIRE } from '../../common/ComValues';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore, AssetInfoChangePopStore } = CommonStore;

class AssetInfoChangePop extends Component {
	constructor(props) {
		super(props);

		this.state = {
			addrSeq: '',
			spotId: '',
			spotNm: '',
			userDeptId: '',
			userDeptNm: '',
			userNm: '',
			realUseSpot: '',
			remark: '',
			userEmpno: '',
			userTelno: '',
			userHpno: '',
			updateComplete: false,
		};
	}

	changeSave = () => {
		if (
			this.state.spotId === '' &&
			this.state.spotNm === '' &&
			this.state.userDeptNm === '' &&
			this.state.userNm === '' &&
			this.state.realUseSpot === '' &&
			this.state.userEmpno === '' &&
			this.state.userTelno === '' &&
			this.state.userHpno === ''
		) {
			ModalStore.openModal('변경할 정보가 없습니다.');
			return;
		}

		if (sessionStorage.custNo === CUST_NO_HKFIRE && !this.state.userDeptId) {
			ModalStore.openModal('대리점 코드를 반드시 입력해 주세요.');
			return;
		}

		ConfirmModalStore.openModal('변경 하시겠습니까?', this.updateAssetInfo);
	};

	updateAssetInfo = () => {
		ProgressStore.activeProgress();

		let param = {
			asstSeq: AssetInfoChangePopStore.asstInfo.asstSeq,
			asstNo: AssetInfoChangePopStore.asstInfo.asstNo,
			addrSeq: this.state.addrSeq,
			spotId: this.state.spotId,
			spotNm: this.state.spotNm,
			userDeptId: this.state.userDeptId,
			userDeptNm: this.state.userDeptNm,
			userNm: this.state.userNm,
			realUseSpot: this.state.realUseSpot,
			remark: this.state.remark,
			userId: sessionStorage.userId,
			userEmpno: this.state.userEmpno,
			userTelno: this.state.userTelno,
			userHpno: this.state.userHpno,
		};

		updateAssetInfo(param)
			.then((r) => {
				ModalStore.openModal('저장 되었습니다.');
				this.setState({ updateComplete: true });
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	setEmptyField = () => {
		this.setState({
			addrSeq: '',
			spotId: '',
			spotNm: '',
			userDeptId: '',
			userDeptNm: '',
			userNm: '',
			realUseSpot: '',
			remark: '',
			userEmpno: '',
		});
	};

	popClose = () => {
		AssetInfoChangePopStore.closePopup(this.state.updateComplete);
	};

	modalClearCallback = () => {
		this.setEmptyField(); //초기화
		this.popClose(this.state.updateComplete);
	};

	searchSpot = () => {
		CustSearchPopupStore.openPopup('2', null, AssetInfoChangePopStore.asstInfo.custNo, this.handleCustClose);
	};

	handleCustClose = (item) => {
		if (item === undefined) return;
		this.setState({
			addrSeq: item.addrSeq,
			spotId: item.spotId,
			spotNm: item.spotNm,
		});
	};

	render() {
		return (
			<div>
				<Dialog open={AssetInfoChangePopStore.open} TransitionComponent={Transition} onClose={this.popClose}>
					<div className="popSmArea searchPop">
						<div className="popCont" style={{ height: 750, overflow: 'hidden' }}>
							<div className="h2Wrap">
								<h2>자산정보변경</h2>
							</div>
							<p className="headline">현재정보</p>
							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: 90 }} />
										<col style={{ width: 120 }} />
										<col style={{ width: 90 }} />
										<col style={{ width: 240 }} />
									</colgroup>
									<tbody>
										<tr>
											<th>{sessionStorage.spotTitle}코드</th>
											<td>
												<input type="text" value={AssetInfoChangePopStore.asstInfo.spotId} readOnly={true} style={{ border: 0 }} />
											</td>
											<th>{sessionStorage.spotTitle}명</th>
											<td>
												<input type="text" value={AssetInfoChangePopStore.asstInfo.spotNm} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										<tr>
											<th>{sessionStorage.deptTitle}코드</th>
											<td>
												<input type="text" value={AssetInfoChangePopStore.asstInfo.userDeptId} readOnly={true} style={{ border: 0 }} />
											</td>
											<th>{sessionStorage.deptTitle}명</th>
											<td>
												<input type="text" value={AssetInfoChangePopStore.asstInfo.userDeptNm} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										<tr>
											<th>사용자명</th>
											<td>
												<input type="text" value={AssetInfoChangePopStore.asstInfo.userNm} readOnly={true} style={{ width: 120, border: 0 }} />
											</td>
											<th>사용자사번</th>
											<td>
												<input type="text" value={AssetInfoChangePopStore.asstInfo.userEmpno} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										<tr>
											<th>전화번호</th>
											<td>
												<input type="text" value={AssetInfoChangePopStore.asstInfo.userTelno} readOnly={true} style={{ width: 120, border: 0 }} />
											</td>
											<th>핸드폰번호</th>
											<td>
												<input type="text" value={AssetInfoChangePopStore.asstInfo.userHpno} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										<tr>
											<th>실사용장소</th>
											<td colSpan="3">
												<input type="text" value={AssetInfoChangePopStore.asstInfo.realUseSpot} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<p className="headline">변경정보 - 변경되는 정보만 입력해 주세요</p>
							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: 90 }} />
										<col style={{ width: 120 }} />
										<col style={{ width: 90 }} />
										<col style={{ width: 240 }} />
									</colgroup>
									<tbody>
										<tr>
											<th>{sessionStorage.spotTitle}</th>
											<td colSpan="3">
												<input type="text" value={this.state.spotId} placeholder={sessionStorage.spotTitle + '코드'} readOnly={true} style={{ width: 120 }} />
												<Button color="primary" variant="contained" size="small" style={{ marginLeft: 5, marginRight: 5 }} onClick={this.searchSpot}>
													{sessionStorage.spotTitle}조회
												</Button>
												<input type="text" value={this.state.spotNm} placeholder={sessionStorage.spotTitle + '명'} readOnly={true} style={{ width: 220 }} />
											</td>
										</tr>
										<tr>
											<th>{sessionStorage.deptTitle}코드</th>
											<td>
												<input
													type="text"
													value={this.state.userDeptId}
													placeholder={sessionStorage.deptTitle + '코드'}
													onChange={(e) => this.setState({ userDeptId: e.target.value })}
												/>
											</td>
											<th>{sessionStorage.deptTitle}명</th>
											<td>
												<input
													type="text"
													value={this.state.userDeptNm}
													placeholder={sessionStorage.deptTitle + '명'}
													onChange={(e) => this.setState({ userDeptNm: e.target.value })}
												/>
											</td>
										</tr>
										<tr>
											<th>사용자명</th>
											<td>
												<input type="text" value={this.state.userNm} onChange={(e) => this.setState({ userNm: e.target.value })} style={{ width: 120 }} />
											</td>
											<th>사용자사번</th>
											<td>
												<input type="text" value={this.state.userEmpno} onChange={(e) => this.setState({ userEmpno: e.target.value })} />
											</td>
										</tr>
										<tr>
											<th>전화번호</th>
											<td>
												<input type="text" value={this.state.userTelno} onChange={(e) => this.setState({ userTelno: e.target.value })} />
											</td>
											<th>핸드폰번호</th>
											<td>
												<input type="text" value={this.state.userHpno} onChange={(e) => this.setState({ userHpno: e.target.value })} style={{ width: '60%' }} />
											</td>
										</tr>
										<tr>
											<th>실사용장소</th>
											<td colSpan="3">
												<input type="text" value={this.state.realUseSpot} onChange={(e) => this.setState({ realUseSpot: e.target.value })} />
											</td>
										</tr>
										<tr>
											<th>변경사유</th>
											<td colSpan="3">
												<textarea
													type="text"
													value={this.state.remark}
													rows="2"
													placeholder="변경 사유를 입력해 주세요"
													onChange={(e) => this.setState({ remark: e.target.value })}
													style={{ height: 60 }}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div style={{ marginTop: 15, textAlign: 'right' }}>
								<Button color="primary" variant="contained" size="small" style={{ alignItems: 'initial' }} onClick={this.changeSave}>
									<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
									저장
								</Button>
							</div>
							<div className="btnClose" onClick={this.popClose}>
								<a>팝업닫기</a>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}
}
export default observer(AssetInfoChangePop);
