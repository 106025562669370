import React, { Component } from 'react';
import IBSheet from '../IBSheet';

import Dialog from '@material-ui/core/Dialog';
import { Transition, SheetParamData } from '../../common/ComFunction';

import { resultToIbSheet, errorMsgCheck } from '../../common/ComFunction';
import { getContOrderItemList, divideOrde } from '../../service/admin/ContractService';

import { Button } from '@material-ui/core';
import SwapVertIcon from '@material-ui/icons/SwapVert';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ProgressStore, RentalOrderDividePopupStore } = CommonStore;

class RentalOrderDividePop extends Component {
	constructor(props) {
		super(props);

		this.sheetData = {
			id: 'orderDivideSheet', // sheet id
			initData: 'rental/Init-RentalOrderDivide', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '400px', // sheet height
		};
	}

	componentWillUnmount = () => {
		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}
	};

	componentDidUpdate = () => {
		if (RentalOrderDividePopupStore.open && !RentalOrderDividePopupStore.searched) {
			const ordeNo = RentalOrderDividePopupStore.ordeNo;
			ProgressStore.activeProgress();
			getContOrderItemList(ordeNo)
				.then((r) => {
					var sheetIntvl2 = setInterval(() => {
						if (window[this.sheetData.id] !== undefined) {
							window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
							RentalOrderDividePopupStore.setSearched(true);
							//초기화
							clearInterval(sheetIntvl2);
						}
					}, 100);
				})
				.catch((err) => {
					errorMsgCheck(err);
				})
				.finally(() => {
					ProgressStore.deactiveProgress();
				});
		}
	};

	popClose = () => {
		RentalOrderDividePopupStore.closePopup();
		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}
	};

	callDivideOrde = () => {
		let paramList = [];
		const sheet = window[this.sheetData.id];
		let length = sheet.GetTotalRows();
		let item = {};

		for (let i = 0; i < length; i++) {
			// 체크된 행만 fetch
			if (sheet.GetCellValue(i + 1, 'chk') === '1') {
				item.ordeNo = sheet.GetCellValue(i + 1, 'ordeNo');
				item.seq = sheet.GetCellValue(i + 1, 'seq');
				item.chk = sheet.GetCellValue(i + 1, 'chk');
				paramList.push(item);
				item = {};
			}
		}

		if (paramList.length === 0) {
			ModalStore.openModal('선택된 자산이 없습니다.');
			return;
		}
		if (paramList.length === length) {
			ModalStore.openModal('요청분리는 전체선택할 수 없습니다.');
			return;
		}
		ProgressStore.activeProgress();

		divideOrde(paramList)
			.then((r) => {
				ModalStore.openModal('처리되었습니다.');
				this.popClose();
			})
			.catch((err) => {
				// 20201103 kkj 렌탈신청시 이제 controller에서 에러코드 던지므로 반드시 트렌젝션 걸리며 에러처리를 위한 catch필수!
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	render() {
		return (
			<div>
				<Dialog open={RentalOrderDividePopupStore.open} TransitionComponent={Transition} onClose={this.popClose}>
					<div className="popSmArea searchPop" style={{ transform: 'translate(-18%, 0)' }}>
						<div className="popCont" style={{ height: 620, width: 1300, overflow: 'hidden' }}>
							<div className="h2Wrap">
								<h2>요청분리</h2>
							</div>
							<p className="headline">장기/단기 제품이 함께 존재하거나 기타 사유로 계약을 분리하여 생성해야하는 경우 요청을 분리할 수 있습니다.</p>
							<div className="writeType03">
								<IBSheet {...this.sheetData} />
							</div>
							<div style={{ marginTop: 15, textAlign: 'right' }}>
								<Button color="primary" variant="contained" size="small" style={{ alignItems: 'initial' }} onClick={this.callDivideOrde}>
									<SwapVertIcon style={{ fontSize: 20, marginRight: 5 }} />
									요청분리
								</Button>
							</div>
							<div className="btnClose" onClick={this.popClose}>
								<a>팝업닫기</a>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}
}
export default observer(RentalOrderDividePop);
