import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';

import SendIcon from '@material-ui/icons/Input';
import OtherIcon from '@material-ui/icons/SwapVerticalCircle';
import SaveIcon from '@material-ui/icons/Save';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import BuildIcon from '@material-ui/icons/Build';
import ContIcon from '@material-ui/icons/AssignmentTurnedIn';
import BlockIcon from '@material-ui/icons/Block';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import ChangeIcon from '@material-ui/icons/Autorenew';
//날짜
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import ExcelDown from '../include/ExcelDown';

import { getCisCommCode, getRentalCustContType, getApiKywonKey, getCustomData } from '../../service/CommonService';
import {
	buildSelectItems,
	resultToIbSheet,
	errorMsgCheck,
	dateToFormat,
	InsertComma,
	managerCheck,
	getHostType,
	ComIBSheetComboNull,
	SheetParamData5,
} from '../../common/ComFunction';
import {
	getContOrderList,
	getContOrderItemList,
	updateContOrderDelInfo,
	updateContOrderItemInfo,
	postChangeOrderQty,
	deleteContOrder,
	getContInfoKY,
	updateContOrderDelInfoList,
	contractBatchProc,
	contractBatchStatusReset,
	updateContOrderRqstId,
} from '../../service/admin/ContractService';
import { getCustItemList } from '../../service/admin/CompService';
import { CUST_NO_KY, CUST_NO_KY_DEV } from '../../common/ComValues';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const {
	ModalStore,
	ConfirmModalStore,
	ProgressStore,
	CustSearchPopupStore,
	UserPopupStore,
	ApiOrdeItemAllocPopupStore,
	StockPopupStore,
	IfrsPopupStore,
	PostPopupStore,
	LargeInputNoPopupStore,
	RentalOrderDividePopupStore,
	KYContInfoCheckPopupStore,
} = CommonStore;

class ApiContractProc extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selOrderStatus: '3', //결재완료를 가져오도록 디폴트 설정
			arrOrderStatus: [],
			arrPreAft: [],
			arrDelMothd: [],
			arrContType: [],
			apipfCodeList: [], // 공통코드 선언
			sDate: null,
			eDate: null,
			sOrdeNo: '',
			sCustReqNo: '',
			sContNo: '',
			sCustNo: '',
			sCustNm: '',
			procFlag: '', // 처리상태
			sUserChgId: sessionStorage.userId,
			sUserChgNm: sessionStorage.userName,

			//요청상세
			proceMonth: '',
			ordeNo: '',
			custNo: '',
			contNo: '',
			spotId: '',
			spotNm: '',
			delTelno: '',
			delHpno: '',
			delPostCode: '',
			delAddr: '',
			delAddrDtl: '',
			ordeRmk: '',
			delMethod: '',
			delAskDate: null,
			delDate: '',
			delRemark: '', //출고비고
			delOtherRemark: '', //배송비고
			updtUser: '',
			updtDate: '',
			contType: '',
			custReqNo: '',
			centerCd: '', // 센터코드
			refurCd: '', // 리퍼코드
			ordeStipulationYn: '', // 약정여부

			//요청제품상세
			seq: '',
			itemSeq: '',
			itemQty: '',
			itemCode: '',
			makeNm: '',
			itemNm: '',
			model: '',
			spec: '',
			userDeptName: '',
			userName: '',
			userRemark: '',
			monthPrc: '',
			stoNo: '',
			stoSeq: '',
			asstNo: '',
			ifrsCode: '',
			duration: '',
			lastBalRate: '',
			changeQty: '',
			excelList: [], //엑셀다운로드용 리스트

			deleteBtn: true,
			disabledBtn: true, // 더블클릭한 요청 건 관련 버튼
			disabledForChkBtn: true, // 체크한 요청 건 관련 버튼
			disabledForChkRstBtn: true, // 체크한 요청 건 리셋 버튼
			inputReadOnly: true,

			// 계약정보 확인 팝업
			contInfoDisabledBtn: true, // 계약정보 확인 버튼 계약완료일 경우에도 보이게 하기
			contInfoBtnShow: 'none',

			//수량나누기
			anchorEl: null,
			//제품변경팝업
			anchorEl2: null,
			//대량입력
			textArrayPopMode: 'C', // O:요청번호 C:계약번호 CR:고객요청번호
			// 체크된 요청정보
			orderSelectedData: null,

			arrCustItemList: [], // 고객제품정보 리스트
			centerCodeList: [], // 센터코드목록
			refurCodeList: [], // 리퍼코드목록
			isOpenCenter: false, // 센터모달열기
			isOpenRefur: false, // 리퍼모달열기
			isOpenStipulationYn: false, // 약정여부모달열기
			bulkChangeDialogValue: '', // 센터/리퍼 변경값
			searchedCustNo: '',
		};

		this.sheetData = {
			id: 'apiOrderListSheet', // sheet id
			initData: 'admin/Init-ApiOrderList', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '250px', // sheet height
		};

		this.sheetData2 = {
			id: 'apiOrderItemsSheet', // sheet id
			initData: 'admin/Init-ApiOrderItemsList', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '267px', // sheet height
		};
	}

	componentDidMount = () => {
		managerCheck(); // 관리자체크
		Promise.all([getCisCommCode('04'), getCisCommCode('11'), getCisCommCode('28'), getCisCommCode('APIPF'), getCisCommCode('29'), getCisCommCode('30')]).then((r) => {
			let [code04, code11, code28, codeApipfData, code29, code30] = r;

			for (let i = 0; i < codeApipfData.length; i++) {
				if (codeApipfData[i].value === 'P') {
					// 담당자 처리대상 제외
					codeApipfData.splice(i, 1);
					i--;
				}
			}

			this.setState({
				arrDelMothd: code04, // 배송방법
				arrOrderStatus: code11, // 신청상태
				arrPreAft: code28, // 계약처리월
				apipfCodeList: codeApipfData, // 배치 처리상태코드
				refurCodeList: code29, // 교원 리퍼 코드
				centerCodeList: code30, // 센터 코드
			});

			const timer = setInterval(() => {
				if (window[this.sheetData.id]) {
					ComIBSheetComboNull('0', window[this.sheetData.id], 'delMethod', code04, 'title', 'value', '', '');
					ComIBSheetComboNull('0', window[this.sheetData.id], 'ordeStatusCd', code11, 'title', 'value', '', '');
					ComIBSheetComboNull('0', window[this.sheetData.id], 'procFlag', codeApipfData, 'title', 'value', '', '');
					ComIBSheetComboNull('0', window[this.sheetData.id], 'proceMonth', code28, 'title', 'value', '', '');
					ComIBSheetComboNull('0', window[this.sheetData.id], 'refurCd', code29, 'title', 'value', '', '');
					ComIBSheetComboNull('0', window[this.sheetData.id], 'centerCd', code30, 'title', 'value', '', '');
					clearInterval(timer);
					window[this.sheetData.id].ShowFilterRow();
				}
			}, 100);
		});
	};

	componentWillUnmount = () => {
		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}
		if (window[this.sheetData2.id]) {
			window[this.sheetData2.id].DisposeSheet();
		}
	};

	getContOrderList = () => {
		if (!this.state.sCustNo) {
			ModalStore.openModal('고객명은 필수입니다.');
			return;
		}
		ProgressStore.activeProgress();

		getRentalCustContType(this.state.sCustNo)
			.then((custContTypeData) => {
				this.setOrderInfo(); //초기화 먼저
				this.setState({ arrContType: custContTypeData });

				ComIBSheetComboNull('0', window[this.sheetData.id], 'contType', custContTypeData, 'contTypeNm', 'contType', '', '');

				if (custContTypeData.length === 0) {
					ModalStore.openModal('계약 유형이 저장되지 않았습니다.\nERP 고객별계약정보에서 계약 유형을 등록해 주시기 바랍니다.');
				}
			})
			.then(() => {
				let param = {
					orderStatus: this.state.selOrderStatus,
					sDate: dateToFormat(this.state.sDate),
					eDate: dateToFormat(!this.state.eDate ? new Date() : this.state.eDate),
					ordeNo: this.state.sOrdeNo,
					custReqNo: this.state.sCustReqNo,
					contNo: this.state.sContNo,
					custNo: this.state.sCustNo,
					procFlag: this.state.procFlag,
					userChgId: this.state.sUserChgId,
					apiYn: 'Y', // api에 의한 요청만 조회
				};

				getContOrderList(param)
					.then((r) => {
						window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
						//초기화
						window[this.sheetData2.id].RemoveAll();
						this.setOrderInfo();
						this.setOrderItemInfo();
						this.setState({ orderSelectedData: null,
							searchedCustNo: this.state.sCustNo
						 });

						if(this.state.searchedCustNo == '3148330') {
							window[this.sheetData.id].SetColHidden('centerCd', false);
							window[this.sheetData.id].SetColHidden('refurCd', false);
							
						} else {
							window[this.sheetData.id].SetColHidden('centerCd', true);
							window[this.sheetData.id].SetColHidden('refurCd', true);
						}

						//엑셀용
						this.makeExcelData(r);
					})
					.catch((err) => {
						errorMsgCheck(err);
						this.setState({ searchedCustNo: '' });
						window[this.sheetData.id].SetColHidden('centerCd', true);
						window[this.sheetData.id].SetColHidden('refurCd', true);
					})
					.finally(() => {
						ProgressStore.deactiveProgress();
					});
			})
			.finally(() => {
				this.orderListEditableControl(); // 조회 후 그리드 입력 필드 제어 호출
			});
	};

	makeExcelData = (data) => {
		let tmpList = [];
		let tmp = {};
		data.map((item, idx) => {
			tmp = {};
			tmp.No = idx + 1;
			tmp.요청번호 = item.ordeNo;
			tmp.고객요청번호 = item.custReqNo;
			tmp.요청일자 = dateToFormat(item.ordeDate);
			tmp.계약번호 = item.contNo;
			tmp.고객번호 = item.custNo;
			tmp.고객명 = item.custNm;
			tmp.진행상태 = item.ordeStatus;
			tmp.신청자 = item.userName;
			tmp.수령부서 = item.delDept;
			tmp.수령인 = item.delUser;
			tmp.지점코드 = item.spotId;
			tmp.지점명 = item.spotNm;
			tmp.전화번호 = item.delTelno;
			tmp.휴대폰번호 = item.delHpno;
			tmp.우편번호 = item.delPostCode;
			tmp.주소 = item.delAddr;
			tmp.상세주소 = item.delAddrDtl;
			tmp.요청비고 = item.ordeRmk;
			tmp.계약처리월 = item.proceMonth === '1' ? '당월' : '익월';
			tmp.배송방법 = item.delMethod;
			tmp.배송요청일 = dateToFormat(item.delAskDate);
			tmp.출고비고 = item.delAskRecdesc;
			tmp.배송비고 = item.delOtherRecdesc;
			tmp.약정여부 = item.ordeStipulationYn;
			tmp.센터 = item.centerCd;
			tmp.리퍼 = item.refurCd;
			tmp.처리자 = item.updtUser;
			tmp.처리일자 = dateToFormat(item.updtDate);
			tmpList.push(tmp);
		});
		this.setState({ excelList: tmpList });
	};

	getContOrderInfoData = (ordeNo) => {
		this.setOrderInfo(); //초기화 먼저
		let param = {
			ordeNo: ordeNo,
		};
		ProgressStore.activeProgress();

		getContOrderList(param)
			.then((custOrdeData) => {
				this.setOrderInfo(custOrdeData[0]);
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	getContOrderItemList = (ordeNo, custNo) => {
		ProgressStore.activeProgress();
		if (custNo) {
			let param = {
				custNoSearch: custNo,
				useYnSearch: 'Y', //[대응개발] 2024-05-21 사용여부 추가 by choye
				delYnSearch: 'N',
				stoQty: 'N', // 입고수량은 가져오지않는다. 너무 오래걸려서 계약생성 화면에서는 제외하고 가져옴. 20220124 kkj
				//useYnSearch: 'Y'
			};

			Promise.all([getContOrderItemList(ordeNo), getCustItemList(param)])
				.then((r) => {
					let [data1, data2] = r;
					window[this.sheetData2.id].LoadSearchData(resultToIbSheet(data1));
					this.setState({
						arrCustItemList: data2,
					});
					//초기화
					this.setOrderItemInfo();
					ProgressStore.deactiveProgress();
				})
				.catch((err) => {
					errorMsgCheck(err, ProgressStore.deactiveProgress());
				});
		} else {
			getContOrderItemList(ordeNo)
				.then((r) => {
					window[this.sheetData2.id].LoadSearchData(resultToIbSheet(r));
					//초기화
					this.setOrderItemInfo();
					ProgressStore.deactiveProgress();
				})
				.catch((err) => {
					errorMsgCheck(err, ProgressStore.deactiveProgress());
				});
		}
	};

	checkContOrder = (type) => {
		if (this.state.ordeNo === '') {
			ModalStore.openModal('요청번호를 선택해 주세요.');
			return;
		}

		if (type === 0) {
			ConfirmModalStore.openModal('요청번호[' + this.state.ordeNo + ']를 분리 하시겠습니까?', this.devideContOrder);
		} else if (type === 1) {
			ConfirmModalStore.openModal('요청번호[' + this.state.ordeNo + ']를 삭제 하시겠습니까?', this.deleteContOrder);
		}
	};

	deleteContOrder = () => {
		let param = {
			ordeNo: this.state.ordeNo,
		};

		deleteContOrder(param)
			.then((r) => {
				ProgressStore.deactiveProgress();
				//재조회
				this.getContOrderList();
				//버튼 디저블
				ModalStore.openModal('삭제 되었습니다.');
				this.setState({
					deleteBtn: true,
				});
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	devideContOrder = () => {
		RentalOrderDividePopupStore.openPopup(this.state.ordeNo, this.orderDivideCloseFnc);
	};

	dateHandler = (date, type) => {
		if (type === 's') {
			this.setState({ sDate: date });
		} else if (type === 'e') {
			this.setState({ eDate: date });
		} else if (type === 'd') {
			this.setState({ delAskDate: date });
		}
	};

	/** 일괄 요청제품 할당 */
	callCheckedOrdeItemAlloc = () => {
		const sheet = window[this.sheetData.id];
		const FindCheckedRow = sheet.FindCheckedRow('chk');
		const checkRows = FindCheckedRow.split('|');

		if (!FindCheckedRow) {
			ModalStore.openModal('선택된 건이 없습니다.');
			return;
		}

		// 요청제품 row수가 같은 건들만 일괄 제품 할당 가능하게 한다
		let itemCnt = sheet.GetCellValue(checkRows[0], 'itemRowCnt');
		if (FindCheckedRow) {
			for (var idx = 0; idx < checkRows.length; idx++) {
				if (itemCnt !== sheet.GetCellValue(checkRows[idx], 'itemRowCnt')) {
					ModalStore.openModal('요청제품 행(row)수가 같은 건들만 제품 일괄할당이 가능합니다.');
					return;
				}
				itemCnt = sheet.GetCellValue(checkRows[idx], 'itemRowCnt');
			}
		}

		const sheetParam = SheetParamData5(sheet, null);

		// 요청제품 일괄할당 팝업 호출
		ApiOrdeItemAllocPopupStore.openPopup(this.state.sCustNo, sheetParam, this.getContOrderList);
	};

	/** 일괄 요청자ID(청구지 순번) 일괄변경 */
	callCheckedRqstIdAlloc = () => {
		const sheet = window[this.sheetData.id];
		const FindCheckedRow = sheet.FindCheckedRow('chk');
		const checkRows = FindCheckedRow.split('|');

		if (!FindCheckedRow) {
			ModalStore.openModal('선택된 건이 없습니다.');
			return;
		}

		// 요청자ID(청구지 순번) 일괄할당 팝업 호출
		// ApiOrdeItemAllocPopupStore.openPopup(this.state.sCustNo, sheetParam, this.getContOrderList);
		UserPopupStore.openPopup('rqst', this.state.sCustNo, (item) => {
			if (item !== undefined) {
				ConfirmModalStore.openModal(`선택된 건들의 신청자ID를 ${item.userId}(으)로 일괄 변경하시겠습니까?`, () => {
					const sheetParam = SheetParamData5(sheet, { rqstId: item.userId });

					updateContOrderRqstId(sheetParam)
						.then(() => {
							ModalStore.openModal('저장 되었습니다.');
							//재조회
							this.getContOrderList();
						})
						.catch((err) => {
							errorMsgCheck(err);
						})
						.finally(() => {
							ProgressStore.deactiveProgress();
						});
				});
			}
		});
	};

	/** 일괄 배송/출고정보 저장 */
	saveCheckedDelInfo = () => {
		const sheet = window[this.sheetData.id];
		const FindCheckedRow = sheet.FindCheckedRow('chk');
		const checkRows = FindCheckedRow.split('|');

		if (!FindCheckedRow) {
			ModalStore.openModal('선택된 건이 없습니다.');
			return;
		}

		ConfirmModalStore.openModal('체크된 건들의 배송/출고 정보를 일괄저장 하시겠습니까?', () => {
			let sheet = window[this.sheetData.id];
			let sheetParam = SheetParamData5(sheet, null, true);

			if (sheetParam.data.length === 0) return;

			updateContOrderDelInfoList(sheetParam)
				.then(() => {
					ModalStore.openModal('저장 되었습니다.');
					//재조회
					this.getContOrderList();
				})
				.catch((err) => {
					errorMsgCheck(err);
				})
				.finally(() => {
					ProgressStore.deactiveProgress();
				});
		});
	};

	saveDelInfo1 = () => {
		if (!this.state.delTelno) {
			ModalStore.openModal('전화번호를 입력해 주세요');
			return;
		}
		if (!this.state.delHpno) {
			ModalStore.openModal('휴대폰번호를 입력해 주세요');
			return;
		}
		if (!this.state.delPostCode) {
			ModalStore.openModal('우편번호를 입력해 주세요');
			return;
		}
		if (!this.state.delAddr) {
			ModalStore.openModal('주소를 입력해 주세요');
			return;
		}
		if (!this.state.delAddrDtl) {
			ModalStore.openModal('상세주소를 입력해 주세요');
			return;
		}
		if (!this.state.ordeStipulationYn) {
			ModalStore.openModal('약정여부를 선택해 주세요');
			return;
		}

		ConfirmModalStore.openModal('배송 정보를 저장 하시겠습니까?', this.updateContOrderDelInfo1);
	};

	updateContOrderDelInfo1 = () => {
		let param = {
			ordeNo: this.state.ordeNo,
			delTelno: this.state.delTelno,
			delHpno: this.state.delHpno,
			delPostCode: this.state.delPostCode,
			delAddr: this.state.delAddr,
			delAddrDtl: this.state.delAddrDtl,
			stipulationYn: this.state.ordeStipulationYn,
			type: '0', // 배송정보
		};

		updateContOrderDelInfo(param)
			.then(() => {
				ModalStore.openModal('저장 되었습니다.');
				ProgressStore.deactiveProgress();
				//재조회
				this.getContOrderInfoData(param.ordeNo);
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	saveDelInfo2 = () => {
		if (!this.state.proceMonth) {
			ModalStore.openModal('계약처리월을 선택해 주세요');
			return;
		}
		if (!this.state.contType) {
			ModalStore.openModal('계약 유형을 선택해 주세요.');
			return;
		}
		if (!this.state.delMethod) {
			ModalStore.openModal('배송방법을 선택해 주세요');
			return;
		}
		if (!this.state.delAskDate) {
			ModalStore.openModal('배송 요청일을 선택해 주세요');
			return;
		}

		ConfirmModalStore.openModal('출고 정보를 저장 하시겠습니까?', this.updateContOrderDelInfo2);
	};

	updateContOrderDelInfo2 = () => {
		let param = {
			ordeNo: this.state.ordeNo,
			proceMonth: this.state.proceMonth,
			contType: this.state.contType,
			delMethod: this.state.delMethod,
			delAskDate: this.state.delAskDate.format('YYYY-MM-DD'),
			delAskRecdesc: this.state.delRemark,
			delOtherRecdesc: this.state.delOtherRemark,
			centerCd: this.state.centerCd,
			refurCd: this.state.refurCd,
			type: '1', // 출고정보
		};

		updateContOrderDelInfo(param)
			.then(() => {
				ModalStore.openModal('저장 되었습니다.');
				ProgressStore.deactiveProgress();
				//재조회
				this.getContOrderInfoData(param.ordeNo);
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	saveItemInfo = () => {
		if (!this.state.monthPrc) {
			ModalStore.openModal('렌탈료가 입력되지 않았습니다.');
			return;
		}
		// if(!this.state.asstNo && !this.state.stoNo && !this.state.stoSeq){
		//     ModalStore.openModal('입고번호 또는 자산번호중 하나는 반드시 입력해 주세요.');
		//     return;
		// }
		if (this.state.asstNo && this.state.stoNo && this.state.stoSeq) {
			ModalStore.openModal('입고번호와 자산번호를 둘다 입력할 수 없습니다.');
			return;
		}
		if (this.state.stoNo && !this.state.stoSeq) {
			ModalStore.openModal('입고순번을 반드시 입력해 주세요.');
			return;
		}
		if (!this.state.stoNo && this.state.stoSeq) {
			ModalStore.openModal('입고번호를 반드시 입력해 주세요.');
			return;
		}
		if (this.state.stoNo && this.state.stoSeq && !this.state.ifrsCode) {
			ModalStore.openModal('신품인 경우 IFRS코드를 반드시 입력해 주세요.');
			return;
		}

		this.setState({
			itemSeq: '', // 제품변경 시도시 itemSeq값 변경 일어날 수 있으므로 제품정보저장 이벤트시에는 제거
		});

		ConfirmModalStore.openModal('제품 출고 정보를 저장 하시겠습니까?', this.updateContOrderItemInfo);
	};

	updateContOrderItemInfo = (item) => {
		let param = {
			ordeNo: this.state.ordeNo,
			seq: this.state.seq,
			itemSeq: this.state.itemSeq,
			monthPrc: this.state.monthPrc,
			stoNo: this.state.stoNo,
			stoSeq: this.state.stoSeq,
			asstNo: this.state.asstNo,
			ifrsCode: this.state.ifrsCode,
			duration: this.state.duration,
			lastBalRate: this.state.lastBalRate,
		};

		if (item) {
			this.setState({
				anchorEl2: null,
			});
		}

		ProgressStore.activeProgress();
		updateContOrderItemInfo(param)
			.then(() => {
				ModalStore.openModal('저장 되었습니다.');
				//재조회
				this.getContOrderItemList(param.ordeNo);
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	checkItemQty = () => {
		if (!this.state.changeQty) {
			ModalStore.openModal('수량을 입력해 주세요');
			return;
		}
		if (this.state.changeQty <= 0) {
			ModalStore.openModal('한 개 이상 입력해 주세요');
			return;
		}
		if (this.state.changeQty > this.state.itemQty || this.state.changeQty == this.state.itemQty) {
			ModalStore.openModal('원 수량보다 같거나 클 수 없습니다.');
			return;
		}

		ConfirmModalStore.openModal('수량을 나누시겠습니까?', this.postChangeOrderQty);
	};

	postChangeOrderQty = () => {
		let param = {
			ordeNo: this.state.ordeNo,
			seq: this.state.seq,
			changeQty: this.state.changeQty,
		};

		ProgressStore.activeProgress();
		postChangeOrderQty(param)
			.then(() => {
				ModalStore.openModal('수정 되었습니다.');
				//팝업닫기
				this.changeQtyPopClose();
				//재조회
				this.getContOrderItemList(param.ordeNo);
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	changeQtyPop = (e) => {
		if (!this.state.seq) {
			ModalStore.openModal('제품을 선택해 주세요.');
			return;
		}
		if (this.state.itemCode === '추가옵션') {
			return;
		}
		if (this.state.seq && this.state.itemQty === 1) {
			ModalStore.openModal('수량이 하나면 나눌수 없습니다.');
			return;
		}

		this.setState({ anchorEl: e.currentTarget });
	};

	changeItemPop = (e) => {
		if (!this.state.seq) {
			ModalStore.openModal('제품을 선택해 주세요.');
			return;
		}
		if (this.state.itemCode === '추가옵션') {
			return;
		}

		this.setState({
			anchorEl2: e.currentTarget,
		});
	};

	custItemCloseFnc = (item) => {
		this.setState({
			itemSeq: item.itemSeq,
			monthPrc: item.monthPrc,
		});

		ConfirmModalStore.openModal(item.model + '로 변경 하시겠습니까?', () => this.updateContOrderItemInfo(item));
	};

	changeQtyPopClose = () => {
		this.setState({
			anchorEl: null,
			changeQty: '',
		});
	};

	getOrderInfo = () => {
		if (window[this.sheetData.id].LastRow() === 0) return;

		// let data = window.getOrderSelectData();
		this.setState(
			{
				orderSelectedData: window.getOrderSelectData(),
			},
			() => {
				if (!this.state.orderSelectedData.ordeNo) return;

				this.setState({ contInfoDisabledBtn: false, custNo: this.state.orderSelectedData.custNo }, () => {
					this.kyContInfoCheckBtnShowControl(this.state.orderSelectedData.custNo);
				});

				this.getContOrderInfoData(this.state.orderSelectedData.ordeNo, this.state.orderSelectedData.custNo);
				this.getContOrderItemList(this.state.orderSelectedData.ordeNo, this.state.orderSelectedData.custNo);
			},
		);
	};

	getOrderItemInfo = () => {
		if (window[this.sheetData2.id].LastRow() === 0) return;

		this.setOrderItemInfo(window.getOrderItemSelectData());
	};

	setOrderInfo = (data) => {
		if (data) {
			this.setState({
				ordeNo: data.ordeNo,
				custNo: data.custNo,
				contNo: data.contNo,
				spotId: data.spotId,
				spotNm: data.spotNm,
				delTelno: data.delTelno,
				delHpno: data.delHpno,
				delPostCode: data.delPostCode,
				delAddr: data.delAddr,
				delAddrDtl: data.delAddrDtl,
				ordeRmk: data.ordeRmk,
				proceMonth: data.proceMonth,
				contType: data.contType,
				delMethod: data.delMethod,
				delAskDate: data.delAskDate ? moment(new Date(data.delAskDate)) : null,
				delDate: dateToFormat(data.delAskDate),
				delRemark: data.delAskRecdesc,
				delOtherRemark: data.delOtherRecdesc,
				updtUser: data.updtUser,
				updtDate: data.updtDate ? dateToFormat(data.updtDate) : '',
				deleteBtn: data.ordeStatusCd === '4' ? true : false,
				disabledBtn: data.ordeStatusCd !== '3' ? true : false,
				inputReadOnly: data.ordeStatusCd !== '3' ? true : false,
				custReqNo: data.custReqNo,
				centerCd: data.centerCd,
				refurCd: data.refurCd,
				ordeStipulationYn: data.ordeStipulationYn,
			});
		} else {
			this.setState({
				ordeNo: '',
				custNo: '',
				contNo: '',
				spotId: '',
				spotNm: '',
				delTelno: '',
				delHpno: '',
				delPostCode: '',
				delAddr: '',
				delAddrDtl: '',
				ordeRmk: '',
				proceMonth: '',
				contType: '',
				delMethod: '',
				delAskDate: null,
				delDate: '',
				delRemark: '',
				delOtherRemark: '',
				updtUser: '',
				updtDate: '',
				disabledBtn: true,
				inputReadOnly: true,
				centerCd: '',
				refurCd: '',
				ordeStipulationYn: '',
			});
		}
	};

	setOrderItemInfo = (data) => {
		if (data) {
			this.setState({
				seq: data.seq,
				itemQty: data.itemQty,
				itemCode: data.itemCode,
				itemNm: data.itemNm,
				model: data.model,
				spec: data.spec,
				makeNm: data.makeNm,
				userDeptName: data.userDeptName,
				userName: data.userName,
				userRemark: data.userRemark,
				monthPrc: data.monthPrc ? InsertComma(data.monthPrc) : data.monthPrc,
				stoNo: data.stoNo,
				stoSeq: data.stoSeq,
				asstNo: data.asstNo,
				ifrsCode: data.ifrsCode,
				itemSeq: '',
			});
		} else {
			this.setState({
				seq: '',
				itemQty: '',
				itemCode: '',
				changeQty: '',
				itemNm: '',
				model: '',
				spec: '',
				makeNm: '',
				userDeptName: '',
				userName: '',
				userReamrk: '',
				monthPrc: '',
				stoNo: '',
				stoSeq: '',
				asstNo: '',
				ifrsCode: '',
				itemSeq: '',
			});
		}
	};

	stockPopOpen = (type) => {
		if (type === 'B' && this.state.itemQty > 1) {
			ModalStore.openModal('재고자산을 출고할 경우 요청 제품의 수량은 하나여야 합니다.\n수량 나누기로 수량을 조정 하세요.');
			return;
		}
		if (type === 'A' && this.state.asstNo) {
			ModalStore.openModal('이미 자산번호가 입력되어 있습니다.');
			return;
		}
		if (type === 'B' && this.state.stoNo) {
			ModalStore.openModal('이미 입고번호가 입력되어 있습니다.');
			return;
		}
		StockPopupStore.openPopup(type, this.state.model, this.stockCloseFnc);
	};

	stockCloseFnc = (item) => {
		if (!item) return;

		//20210319 kkj 대분류 체크
		if (item.itemCd !== undefined) {
			let itemCd1 = item.itemCd;
			let itemCd2 = this.state.itemCode;
			if (itemCd1.substr(0, 3) !== itemCd2.substr(0, 3)) {
				ModalStore.openModal('제품의 대분류가 일치하지 않습니다.');
				this.setState({
					stoNo: '',
					stoSeq: '',
					asstNo: '',
				});
				return;
			}
		}

		if (item.ableQty && this.state.itemQty > item.ableQty) {
			ModalStore.openModal('상품 수량보다 발주 수량이 큽니다.');
			this.setState({
				stoNo: '',
				stoSeq: '',
			});
			return;
		}

		this.setState({
			stoNo: item.stoNo,
			stoSeq: item.stoSeq,
			asstNo: item.asstNo,
		});
	};
	orderDivideCloseFnc = () => {
		this.getContOrderList();
	};

	ifrsCloseFnc = (item) => {
		if (!item) return;

		this.setState({
			ifrsCode: item.ifrsCode,
			duration: item.duration,
			lastBalRate: item.lastBalRate,
		});
	};

	regContBatch = () => {
		const sheet = window[this.sheetData.id];
		const FindCheckedRow = sheet.FindCheckedRow('chk'); // 체크건 추출
		const checkRows = FindCheckedRow.split('|');

		if (!FindCheckedRow) {
			ModalStore.openModal('선택된 건이 없습니다.');
			return;
		}

		//[대응개발] 2024-04-08 약생성 버튼명 계약생성요청으로 변경 by choye
		ConfirmModalStore.openModal('해당 건을 계약생성요청 배치에 등록하시겠습니까?', () => {
			const sheetParam = SheetParamData5(sheet, null, true);

			if (sheetParam.data.length === 0) return;

			ProgressStore.activeProgress();
			contractBatchProc(sheetParam.data)
				.then((r) => {
					//[대응개발] 2024-04-08 약생성 버튼명 계약생성요청으로 변경 by choye
					ModalStore.openModal('계약생성요청 배치 등록이 완료 되었습니다.');

					//요청 리스트 재조회
					this.getContOrderList();
				})
				.catch((err) => {
					errorMsgCheck(err);
				})
				.finally(() => {
					ProgressStore.deactiveProgress();
				});
		});
	};

	customerSearch = () => {
		CustSearchPopupStore.openPopup('1', null, null, this.handleCustSearch);
	};

	handleCustSearch = (item) => {
		if (item !== undefined) {
			this.setState({
				sCustNo: item.custNo,
				sCustNm: item.custNm,
			});
			this.setOrderInfo(); // 진행상태 검색조건 변경시 초기화
			window[this.sheetData.id].RemoveAll();
			window[this.sheetData2.id].RemoveAll();
		}
	};

	userSearch = () => {
		UserPopupStore.openPopup(null, sessionStorage.custNo, this.handleUserSearch);
	};

	handleUserSearch = (item) => {
		if (item !== undefined) {
			this.setState({
				sUserChgId: item.userId,
				sUserChgNm: item.userName,
			});
		}
	};

	contInfoCheck = () => {
		KYContInfoCheckPopupStore.openPopup(this.state.custNo, this.state.custReqNo, this.handleContCheck);
	};

	handleContCheck = (contInfoList) => {
		if (contInfoList != undefined) {
			this.setState({
				delTelno: contInfoList.orderTelNo,
				delHpno: contInfoList.orderCellPhonNo,
				delPostCode: contInfoList.orderZip1 + contInfoList.orderZip2,
				delAddr: contInfoList.orderAddress1,
				delAddrDtl: contInfoList.orderAddress2,
				delDate: contInfoList.orderSubscriptionStartDate,
			});
		}
	};

	// 다음 주소검색
	handleAddress = (data) => {
		let fullAddress = data.address;
		let postCode = data.postCode;

		this.setState(
			{
				delPostCode: fullAddress === undefined ? this.state.delPostCode : postCode,
				delAddr: fullAddress === undefined ? this.state.delAddr : fullAddress,
				delAddrDtl: fullAddress === undefined ? this.state.delAddrDtl : '',
			},
			() => {
				this.delAddrDtl.focus();
			},
		);
	};

	kyContInfoCheckBtnShowControl = (custNo) => {
		getCustomData({ custNo: custNo, customCode: 'CT033' }).then((r) => {
			if (r.length > 0) {
				this.setState({
					contInfoBtnShow: r[0].customWord,
				});
			} else {
				this.setState({
					contInfoBtnShow: 'none',
				});
			}
		});
	};

	// 교원 전용 계약정보 확인
	getKYContInfo = async (param) => {
		let data = '';
		let result = '';
		let paraMap = param;

		// 교원에서 부여하는 인증키 가져오기
		result = getApiKywonKey(paraMap)
			.then((r) => {
				let apiKywonKey = r[0].key;

				let param = {
					key: apiKywonKey,
					orderNo: paraMap.custReqNo,
					custNo: paraMap.custNo,
				};

				// 인증키 + 고객요청번호를 통해 계약정보 가져오기
				data = getContInfoKY(param).then((r) => {
					return r[0];
				});
				return data;
			})
			.catch((err) => {
				errorMsgCheck(err);
			});

		return result;
	};

	handleTextArray = (text) => {
		if (!text) return;
		if (this.state.textArrayPopMode === 'C') {
			this.setState({
				sContNo: text,
			});
		}
		if (this.state.textArrayPopMode === 'O') {
			this.setState({
				sOrdeNo: text,
			});
		}
		if (this.state.textArrayPopMode === 'CR') {
			this.setState({
				sCustReqNo: text,
			});
		}
	};
	handleChange = (e, length) => {
		if (length != undefined && length != null && length > 0) {
			if (length < e.target.value.length) {
				ModalStore.openModal(`해당값은 ${length}자를 넘을 수 없습니다.`);
				return;
			}
		}
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
		});
	};
	selOrderStatusChange = (e) => {
		this.handleChange(e);
		this.setState({ orderSelectedData: null });
		this.setOrderInfo(); // 진행상태 검색조건 변경시 초기화
		window[this.sheetData.id].RemoveAll();
		window[this.sheetData2.id].RemoveAll();
	};

	orderListEditableControl = () => {
		const sheet = window[this.sheetData.id];
		if (this.state.selOrderStatus === '3' && !this.state.procFlag) {
			// 접수완료건에만 그리드 입력필드 활성화
			sheet.SetColEditable('delTelno', 1);
			sheet.SetColEditable('delHpno', 1);
			sheet.SetColEditable('delPostCode', 1);
			sheet.SetColEditable('delAddr', 1);
			sheet.SetColEditable('delAddrDtl', 1);
			sheet.SetColEditable('proceMonth', 1);
			sheet.SetColEditable('contType', 1);
			sheet.SetColEditable('delMethod', 1);
			sheet.SetColEditable('delAskDate', 1);
			sheet.SetColEditable('delAskRecdesc', 1);
			sheet.SetColEditable('delOtherRecdesc', 1);
			sheet.SetColEditable('centerCd', 1);
			sheet.SetColEditable('refurCd', 1);

			this.setState({ disabledForChkBtn: false });
		} else {
			sheet.SetColEditable('delTelno', 0);
			sheet.SetColEditable('delHpno', 0);
			sheet.SetColEditable('delPostCode', 0);
			sheet.SetColEditable('delAddr', 0);
			sheet.SetColEditable('delAddrDtl', 0);
			sheet.SetColEditable('proceMonth', 0);
			sheet.SetColEditable('contType', 0);
			sheet.SetColEditable('delMethod', 0);
			sheet.SetColEditable('delAskDate', 0);
			sheet.SetColEditable('delAskRecdesc', 0);
			sheet.SetColEditable('delOtherRecdesc', 0);
			sheet.SetColEditable('centerCd', 0);
			sheet.SetColEditable('refurCd', 0);

			this.setState({ disabledForChkBtn: true });
		}

		if (this.state.selOrderStatus === '3' && this.state.procFlag === 'X') {
			// 접수완료/처리불가 대상은 리셋가능
			this.setState({ disabledForChkRstBtn: false });
		} else {
			this.setState({ disabledForChkRstBtn: true });
		}
	};

	callResetFunc = () => {
		const sheet = window[this.sheetData.id];
		const FindCheckedRow = sheet.FindCheckedRow('chk');
		const checkRows = FindCheckedRow.split('|');

		if (!FindCheckedRow) {
			ModalStore.openModal('선택된 건이 없습니다.');
			return;
		}

		ConfirmModalStore.openModal('해당건의 처리상태를 초기화 하시겠습니까?', () => {
			// validation chk end
			ProgressStore.activeProgress();
			const sheetParam = SheetParamData5(sheet, null);

			contractBatchStatusReset(sheetParam.data)
				.then((r) => {
					ModalStore.openModal('처리상태가 리셋되었습니다.');

					this.getContOrderList(); // 재조회
				})
				.catch((err) => {
					errorMsgCheck(err);
				})
				.finally(() => {
					ProgressStore.deactiveProgress();
				});
		});
	};

	handleBulkChangeDialogOpen = (target) => {
		const sheet = window[this.sheetData.id];
		const FindCheckedRow = sheet.FindCheckedRow('chk');

		if (!FindCheckedRow) {
			ModalStore.openModal('선택된 건이 없습니다.');
			return;
		}
		if(target == "center") {
			this.setState({ isOpenCenter: true });
		} else if(target == "refur") {
			this.setState({ isOpenRefur: true });
		} else {
			this.setState({ isOpenStipulationYn: true });
		}
	};

	handleBulkChangeDialogClose = (target) => {
		this.setState({ isOpenCenter: false })
		this.setState({ isOpenRefur: false });
		this.setState({ isOpenStipulationYn: false });
		this.setState({ bulkChangeDialogValue: '' });
	};

	handleBulkChangeDialogProc = (target) => {
		/* if(this.state.bulkChangeDialogValue == '') {
			ModalStore.openModal("변경하실 값을 선택해 주세요.");
			return;
		} */
		const sheet = window[this.sheetData.id];
		const FindCheckedRow = sheet.FindCheckedRow('chk');
		const checkRows = FindCheckedRow.split('|');

		if (!FindCheckedRow) {
			ModalStore.openModal('선택된 건이 없습니다.');
			return;
		}

		let colName = null;
		if(target == "center") {
			colName = 'centerCd';
		} else if(target == "refur") {
			colName = 'refurCd';
		} else {
			colName = 'ordeStipulationYn';
		}
		
		for (var idx = 0; idx < checkRows.length; idx++) {
			sheet.SetCellValue(checkRows[idx], colName, this.state.bulkChangeDialogValue);
		}

		ModalStore.openModal(`선택된 ${checkRows.length}건이 성공적으로 변경되었습니다. "배송/출고정보 일괄 저장"버튼으로 저장해주세요.`);
		this.setState({ isOpenCenter: false })
		this.setState({ isOpenRefur: false });
		this.setState({ isOpenStipulationYn: false });
	};

	

	render() {
		// const { ModalStore } = CommonStore;
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont" style={{ height: 1500 }}>
							<div className="h2Wrap mb30">
								{/* 
								[대응개발] 2024-01-30 API 일괄 계약 생성을 API 일괄 계약 생성 요청으로 변경 by choye 
								*/}
								<h2>API 일괄 계약 생성 요청</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.getContOrderList}>
										<span>조회</span>
									</a>
									<ExcelDown modalOpen={ModalStore.openModal} data={this.state.excelList} text="엑셀" filename={this.state.sCustNm + '_요청리스트.csv'} />
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '10%' }} />
										<col style={{ width: '40%' }} />
										<col style={{ width: '10%' }} />
										<col style={{ width: '40%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>진행상태</th>
											<td>
												{/* 
												[대응개발] 2024-01-30 정렬순서가 COMM_CODE로 되어 있어서 진행상태는 하드코딩으로 순서 표기 by choye START
												*/}
												<select name="selOrderStatus" onChange={this.selOrderStatusChange} value={this.state.selOrderStatus}>
													<option value="">전체</option>
													<option value="1">접수대기중(결재중)</option>
													<option value="2">반려</option>
													<option value="3">접수완료(결재완료)</option>
													<option value="5">계약생성요청</option>
													<option value="4">계약완료</option>
													<option value="9">신청취소</option>
												</select>
												{/* 
												[대응개발] 2024-01-30 정렬순서가 COMM_CODE로 되어 있어서 진행상태는 하드코딩으로 순서 표기 by choye END
												*/}
											</td>

											<th>요청일자</th>
											<td>
												<DatePicker
													customInput={<input type="text" style={{ width: 115 }} />}
													dateFormat="YYYY-MM-DD"
													selectsStart
													selected={this.state.sDate}
													onChange={(date) => this.dateHandler(date, 's')}
													startDate={this.state.sDate}
													endDate={this.state.eDate}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
												<span style={{ marginLeft: 5, marginRight: 5 }}>~</span>
												<DatePicker
													customInput={<input type="text" style={{ width: 115 }} />}
													dateFormat="YYYY-MM-DD"
													selectsEnd
													selected={this.state.eDate}
													onChange={(date) => this.dateHandler(date, 'e')}
													startDate={this.state.sDate}
													endDate={this.state.eDate}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
											</td>
										</tr>
										<tr>
											<th>처리상태</th>
											<td>
												<select name="procFlag" value={this.state.procFlag} onChange={this.selOrderStatusChange}>
													{this.state.apipfCodeList
														? buildSelectItems(
																[
																	{ title: '처리미등록', value: '' },
																	{ title: '전체', value: 'A' },
																].concat(this.state.apipfCodeList),
														  )
														: null}
												</select>
											</td>
											<th>고객요청번호</th>
											<td>
												<input
													type="text"
													style={{ marginRight: 10, width: '80%' }}
													value={this.state.sCustReqNo}
													name="sCustReqNo"
													onChange={this.handleChange}
												/>
												<IconButton
													onClick={() => {
														this.setState({ textArrayPopMode: 'CR' });
														LargeInputNoPopupStore.openPopup(this.handleTextArray);
													}}
													color="secondary"
													className="iconButton"
												>
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
										</tr>
										<tr>
											<th>요청번호</th>
											<td>
												<input type="text" style={{ marginRight: 10, width: '80%' }} value={this.state.sOrdeNo} name="sOrdeNo" onChange={this.handleChange} />
												<IconButton
													onClick={() => {
														this.setState({ textArrayPopMode: 'O' });
														LargeInputNoPopupStore.openPopup(this.handleTextArray);
													}}
													color="secondary"
													className="iconButton"
												>
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
											<th>계약번호</th>
											<td>
												<input type="text" style={{ marginRight: 10, width: '80%' }} value={this.state.sContNo} name="sContNo" onChange={this.handleChange} />
												<IconButton
													onClick={() => {
														this.setState({ textArrayPopMode: 'C' });
														LargeInputNoPopupStore.openPopup(this.handleTextArray);
													}}
													color="secondary"
													className="iconButton"
												>
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
										</tr>
										<tr>
											<th>
												고객명 <span className="star">*</span>
											</th>
											<td>
												<input type="text" readOnly={true} style={{ marginRight: 10, width: 220 }} value={this.state.sCustNm} />
												<IconButton onClick={this.customerSearch} color="secondary" className="iconButton">
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
											<th>담당자</th>
											<td>
												<input
													type="text"
													style={{ width: 100, marginRight: 10 }}
													value={this.state.sUserChgId}
													placeholder="ID"
													name="sUserChgId"
													onChange={this.handleChange}
												/>
												<IconButton onClick={this.userSearch} color="secondary" className="iconButton">
													<SearchIcon fontSize="small" />
												</IconButton>
												<input
													type="text"
													style={{ width: 80, marginLeft: 10 }}
													value={this.state.sUserChgNm}
													placeholder="이름"
													name="sUserChgNm"
													onChange={this.handleChange}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="writeType03">
								<div style={{ marginTop: 15, marginBottom: -15, textAlign: 'right' }}>
									{/* 
									[대응개발] 2024-01-30 marginLeft:10을 marginLeft:5로 변경, 계약생성 등록 버튼명 계약생성요청 등록으로 변경 by choye START
									*/}
									<Button
										className="button"
										onClick={this.callResetFunc}
										color="primary"
										variant="contained"
										size="small"
										style={{ marginLeft: 5 }}
										disabled={this.state.disabledForChkRstBtn}
									>
										<SendIcon className="saveIcon" />
										<span className="text">처리상태 리셋</span>
									</Button>
									<Button
										color="primary"
										variant="outlined"
										size="small"
										style={{ marginLeft: 5, alignItems: 'initial' }}
										onClick={this.saveCheckedDelInfo}
										disabled={this.state.disabledForChkBtn}
									>
										<CheckBoxIcon style={{ fontSize: 20, marginRight: 5 }} />
										배송/출고정보 일괄 저장
									</Button>
									<Button
										color="default"
										variant="outlined"
										size="small"
										style={{ marginLeft: 5, alignItems: 'initial' }}
										onClick={this.callCheckedOrdeItemAlloc}
										disabled={this.state.disabledForChkBtn}
									>
										<CheckBoxIcon style={{ fontSize: 20, marginRight: 5 }} />
										요청제품 일괄 제품할당
									</Button>
									<Button
										color="default"
										variant="outlined"
										size="small"
										style={{ marginLeft: 5, alignItems: 'initial' }}
										onClick={this.callCheckedRqstIdAlloc}
										disabled={this.state.disabledForChkBtn}
									>
										<CheckBoxIcon style={{ fontSize: 20, marginRight: 5 }} />
										신청자ID(청구지 순번) 변경
									</Button>
									{/* 
									[대응개발] 2024-01-30 계약생성 등록 버튼명 계약생성요청 등록으로 변경 by choye 
									*/}
									<Button
										color="secondary"
										variant="outlined"
										size="small"
										style={{ marginLeft: 5, alignItems: 'initial' }}
										onClick={this.regContBatch}
										disabled={this.state.disabledForChkBtn}
									>
										<CheckBoxIcon style={{ fontSize: 20, marginRight: 5 }} />
										계약생성요청 등록
									</Button>
									{/* 
									[대응개발] 2024-01-30 marginLeft:10을 marginLeft:5로 변경, 계약생성 등록 버튼명 계약생성요청 등록으로 변경 by choye 뚱
									*/}
									{this.state.searchedCustNo == '3148330' ? (
										<React.Fragment>
											<div style={{marginTop: '5px'}}>
												<Button
													color="primary"
													variant="outlined"
													size="small"
													style={{ marginLeft: 5, alignItems: 'initial' }}
													onClick={() => this.handleBulkChangeDialogOpen('stipulationYn')}
													disabled={this.state.disabledForChkBtn}
												>
													<CheckBoxIcon style={{ fontSize: 20, marginRight: 5 }} />
													약정여부일괄변경
												</Button>
												<Button
													color="primary"
													variant="outlined"
													size="small"
													style={{ marginLeft: 5, alignItems: 'initial' }}
													onClick={() => this.handleBulkChangeDialogOpen('center')}
													disabled={this.state.disabledForChkBtn}
												>
													<CheckBoxIcon style={{ fontSize: 20, marginRight: 5 }} />
													센터일괄변경
												</Button>
												<Button
													color="primary"
													variant="outlined"
													size="small"
													style={{ marginLeft: 5, alignItems: 'initial' }}
													onClick={() => this.handleBulkChangeDialogOpen('refur')}
													disabled={this.state.disabledForChkBtn}
												>
													<CheckBoxIcon style={{ fontSize: 20, marginRight: 5 }} />
													리퍼일괄변경
												</Button>
											</div>
										</React.Fragment>
									) : null}
								</div>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
									onDoubleClick={this.getOrderInfo}
								>
									<p className="headline">요청 리스트</p>
									<IBSheet {...this.sheetData} />
								</div>
								<div style={{ fontSize: 14, color: '#d41826' }}>
									<span>* 선택 시 더블클릭</span>
								</div>
							</div>
							<div className="writeType03_acc" style={{ float: 'left', width: '100%', height: 50, marginBottom: '60px' }}>
								<div style={{ marginBottom: -15, textAlign: 'right' }}>
									<Button
										color="primary"
										variant="outlined"
										size="small"
										style={{ marginLeft: 10, alignItems: 'initial' }}
										onClick={() => this.checkContOrder(0)}
										disabled={this.state.disabledBtn}
									>
										<SwapVertIcon style={{ fontSize: 20, marginRight: 5 }} />
										요청분리
									</Button>
									<Button
										color="secondary"
										variant="outlined"
										size="small"
										style={{ marginLeft: 10, alignItems: 'initial' }}
										onClick={() => this.checkContOrder(1)}
										disabled={this.state.deleteBtn}
									>
										<BlockIcon style={{ fontSize: 20, marginRight: 5 }} />
										요청삭제
									</Button>
								</div>
								<p className="headline">선택정보</p>
								<table>
									<colgroup>
										<col style={{ width: '6%' }} />
										<col style={{ width: '14%' }} />
										<col style={{ width: '6%' }} />
										<col style={{ width: '14%' }} />
										<col style={{ width: '6%' }} />
										<col style={{ width: '14%' }} />
										<col style={{ width: '6%' }} />
										<col style={{ width: '14%' }} />
										<col style={{ width: '6%' }} />
										<col style={{ width: '14%' }} />
									</colgroup>
									<tbody>
										<tr style={{ height: 41 }}>
											<th>요청일자</th>
											<td>{this.state.orderSelectedData ? this.state.orderSelectedData.ordeDate : ''}</td>
											<th>요청번호</th>
											<td>{this.state.orderSelectedData ? this.state.orderSelectedData.ordeNo : ''}</td>
											<th>고객명</th>
											<td>{this.state.orderSelectedData ? this.state.orderSelectedData.custNm : ''}</td>
											<th>계약기간</th>
											<td>{this.state.orderSelectedData ? this.state.orderSelectedData.contTerm : ''}</td>
											<th>계약번호</th>
											<td>{this.state.orderSelectedData ? this.state.orderSelectedData.contNo : ''}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="writeType03_acc" style={{ float: 'left', width: '48%', height: 340, marginBottom: '25px' }}>
								<p className="headline">배송정보</p>
								<table>
									<colgroup>
										<col style={{ width: 80 }} />
										<col style={{ width: 140 }} />
										<col style={{ width: 80 }} />
										<col />
									</colgroup>
									<tbody>
										<tr>
											<th>{sessionStorage.spotTitle}명</th>
											<td colSpan="3">
												<input
													type="text"
													value={
														this.state.spotId === '' || this.state.spotId === undefined || this.state.spotId === null
															? this.state.spotNm
															: '(' + this.state.spotId + ')' + this.state.spotNm
													}
													readOnly={true}
													style={{ border: 0 }}
												/>
											</td>
											{/* <th>{sessionStorage.spotTitle}코드</th>
                                            <td>
                                                <input type="text" value={this.state.spotId} readOnly={true} style={{border:0}}/>                                                                           
                                            </td> */}
										</tr>
										<tr>
											<th>전화번호</th>
											<td>
												<input type="text" value={this.state.delTelno} name="delTelno" onChange={this.handleChange} readOnly={this.state.inputReadOnly} />
											</td>
											<th>휴대폰번호</th>
											<td>
												<input type="text" value={this.state.delHpno} name="delHpno" onChange={this.handleChange} readOnly={this.state.inputReadOnly} />
											</td>
										</tr>
										<tr>
											<th>주소</th>
											<td colSpan="3">
												<input
													type="text"
													value={this.state.delPostCode}
													name="delPostCode"
													onChange={this.handleChange}
													readOnly={true}
													style={{ width: '20%', marginRight: 5 }}
												/>
												<input
													type="text"
													value={this.state.delAddr}
													name="delAddr"
													onChange={this.handleChange}
													readOnly={true}
													style={{ width: '70%', marginRight: 5 }}
												/>
												<IconButton
													onClick={() => PostPopupStore.openPopup(this.handleAddress)}
													color="secondary"
													className="iconButton"
													disabled={this.state.disabledBtn}
												>
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
										</tr>
										<tr>
											<th>상세주소</th>
											<td colSpan="3">
												<input
													type="text"
													name="delAddrDtl"
													ref={(ref) => (this.delAddrDtl = ref)}
													value={this.state.delAddrDtl}
													onChange={this.handleChange}
													readOnly={this.state.inputReadOnly}
												/>
											</td>
										</tr>
										<tr>
											<th>요청비고</th>
											<td colSpan="3">
												<textarea value={this.state.ordeRmk} rows="2" style={{ border: 0, height: 60 }} readOnly={true}></textarea>
											</td>
										</tr>
										<tr>
											<th>약정여부</th>
											<td>
												<select name="ordeStipulationYn" value={this.state.ordeStipulationYn} onChange={this.handleChange} disabled={this.state.inputReadOnly}>
													<option value="">선택</option>
													<option value="Y">약정</option>
													<option value="N">무약정</option>
												</select>
											</td>
											<td></td><td></td>
										</tr>
									</tbody>
								</table>
								<div style={{ marginTop: 15, textAlign: 'right' }}>
									<span style={{ display: this.state.contInfoBtnShow }}>
										<Button
											color="primary"
											variant="outlined"
											size="small"
											style={{ marginLeft: 10, alignItems: 'initial' }}
											onClick={this.contInfoCheck}
											disabled={this.state.contInfoDisabledBtn}
										>
											<CheckCircleOutlineOutlinedIcon style={{ fontSize: 20, marginRight: 5 }} />
											계약정보 확인
										</Button>
									</span>
									<Button
										color="primary"
										variant="outlined"
										size="small"
										style={{ marginLeft: 10, alignItems: 'initial' }}
										onClick={this.saveDelInfo1}
										disabled={this.state.disabledBtn}
									>
										<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
										배송정보수정
									</Button>
								</div>
							</div>
							<div className="writeType03_acc" style={{ float: 'right', width: '48%', height: 340, marginBottom: '25px' }}>
								<p className="headline">출고정보</p>
								<table>
									<colgroup>
										<col style={{ width: 80 }} />
										<col style={{ width: 130 }} />
										<col style={{ width: 80 }} />
										<col />
									</colgroup>
									<tbody>
										<tr>
											<th>계약처리월</th>
											<td>
												<select value={this.state.proceMonth} name="proceMonth" onChange={this.handleChange} disabled={this.state.inputReadOnly}>
													{/* <option value="">선택</option>
													<option value="1">당월</option>
													<option value="2">익월</option> */}
													{this.state.arrPreAft ? buildSelectItems([{ title: '선택', value: '' }].concat(this.state.arrPreAft)) : null}
												</select>
											</td>
											<th>계약유형</th>
											<td>
												<select value={this.state.contType} name="contType" onChange={this.handleChange} disabled={this.state.inputReadOnly}>
													<option value="">선택</option>
													{this.state.arrContType
														? this.state.arrContType.map((item, i) => (
																<option key={i} value={item.contType} defaultChecked={item.contType === this.state.contType ? true : false}>
																	{item.contTypeNm}
																</option>
														  ))
														: null}
												</select>
											</td>
										</tr>
										<tr>
											<th>배송방법</th>
											<td>
												<select value={this.state.delMethod} name="delMethod" onChange={this.handleChange} disabled={this.state.inputReadOnly}>
													{this.state.arrDelMothd ? buildSelectItems([{ title: '선택', value: '' }].concat(this.state.arrDelMothd)) : null}
												</select>
											</td>
											<th>배송요청일</th>
											<td>
												{this.state.contNo ? (
													<input type="text" value={this.state.delDate} readOnly={true} style={{ width: 100, border: 0 }} />
												) : (
													<DatePicker
														customInput={<input type="text" style={{ width: 100 }} />}
														dateFormat="YYYY-MM-DD"
														selected={this.state.delAskDate}
														onChange={(date) => this.dateHandler(date, 'd')}
														isClearable={false}
													/>
												)}
											</td>
										</tr>
										<tr>
											<th>출고비고</th>
											<td colSpan="3">
												<textarea
													value={this.state.delRemark}
													readOnly={this.state.inputReadOnly}
													name="delRemark"
													onChange={(e) => this.handleChange(e, 400)}
													style={{ height: 60 }}
												></textarea>
											</td>
										</tr>
										<tr>
											<th>배송비고</th>
											<td colSpan="3">
												<input
													type="text"
													value={this.state.delOtherRemark}
													readOnly={this.state.inputReadOnly}
													name="delOtherRemark"
													onChange={(e) => this.handleChange(e, 500)}
												/>
											</td>
										</tr>
										<tr>
											<th>변경자</th>
											<td>
												<input type="text" value={this.state.updtUser} readOnly={true} style={{ border: 0 }} />
											</td>
											<th>변경일자</th>
											<td>
												<input type="text" value={this.state.updtDate} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										{this.state.searchedCustNo == '3148330' ? (
										<React.Fragment>
											<tr>
												<th>센터정보</th>
												<td>
													<select value={this.state.centerCd} name="centerCd" onChange={this.handleChange} disabled={this.state.inputReadOnly}>
														{this.state.centerCodeList ? buildSelectItems([{ title: '선택', value: '' }].concat(this.state.centerCodeList)) : null}
													</select>
												</td>
												<th>리퍼정보</th>
												<td>
													<select value={this.state.refurCd} name="refurCd" onChange={this.handleChange} disabled={this.state.inputReadOnly}>
														{this.state.refurCodeList ? buildSelectItems([{ title: '선택', value: '' }].concat(this.state.refurCodeList)) : null}
													</select>	
												</td>
											</tr>
											</React.Fragment>
										) : null}
									</tbody>
								</table>
								<div style={{ marginTop: 15, textAlign: 'right' }}>
									<Button
										color="primary"
										variant="outlined"
										size="small"
										style={{ marginLeft: 10, alignItems: 'initial' }}
										onClick={this.saveDelInfo2}
										disabled={this.state.disabledBtn}
									>
										<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
										출고정보저장
									</Button>
								</div>
							</div>
							<div className="writeType03" style={{ float: 'left', width: '40%', marginTop: 20 }} onClick={this.getOrderItemInfo}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">요청제품 리스트</p>
									<IBSheet {...this.sheetData2} />
								</div>
								<div style={{ marginTop: 15, textAlign: 'right' }}>
									<Button
										color="primary"
										variant="outlined"
										size="small"
										style={{ marginLeft: 10, alignItems: 'initial' }}
										onClick={this.changeQtyPop}
										disabled={this.state.disabledBtn}
									>
										<OtherIcon style={{ fontSize: 20, marginRight: 5 }} />
										수량나누기
									</Button>
									<Button
										color="primary"
										variant="outlined"
										size="small"
										style={{ marginLeft: 10, alignItems: 'initial' }}
										onClick={this.changeItemPop}
										disabled={this.state.disabledBtn}
									>
										<BuildIcon style={{ fontSize: 20, marginRight: 5 }} />
										제품변경
									</Button>
								</div>
							</div>
							<div className="writeType03_acc" style={{ float: 'right', width: '55%', marginTop: 20 }}>
								<p className="headline">제품정보</p>
								<table>
									<colgroup>
										<col style={{ width: 80 }} />
										<col style={{ width: 220 }} />
										<col style={{ width: 70 }} />
										<col />
									</colgroup>
									<tbody>
										<tr>
											<th>모델명</th>
											<td>
												<input type="text" value={this.state.model} readOnly={true} style={{ border: 0 }} />
											</td>
											<th>제조사</th>
											<td>
												<input type="text" value={this.state.makeNm} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										<tr>
											<th>스펙</th>
											<td colSpan="3">
												<input type="text" value={this.state.spec} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										<tr>
											<th>부서(대리점)</th>
											<td>
												<input type="text" value={this.state.userDeptName} readOnly={true} style={{ border: 0 }} />
											</td>
											<th>사용자명</th>
											<td>
												<input type="text" value={this.state.userName} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										<tr>
											<th>사용자비고</th>
											<td colSpan="3">
												<input type="text" value={this.state.userRemark} readOnly={true} style={{ border: 0 }} />
											</td>
										</tr>
										{/*
										[대응개발] 2024-05-14 삭제 by choye 
										<tr>
											<th>신품</th>
											<td>
												<input
													type="text"
													value={this.state.stoNo}
													name="stoNo"
													onChange={this.handleChange}
													placeholder="입고번호"
													readOnly
													// readOnly={this.state.inputReadOnly}
													style={{ width: 100, marginLeft: 5, marginRight: 5 }}
												/>
												<input
													type="text"
													value={this.state.stoSeq}
													name="stoSeq"
													onChange={this.handleChange}
													placeholder="순번"
													readOnly
													// readOnly={this.state.inputReadOnly}
													style={{ width: 50, marginRight: 5 }}
												/>
												<IconButton
													onClick={() => {
														this.stockPopOpen('A');
														this.setState({ stoNo: '', stoSeq: '' });
													}}
													color="secondary"
													className="iconButton"
													disabled={this.state.disabledBtn}
												>
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
											<th>IFRS코드</th>
											<td colSpan="3">
												<input
													type="text"
													value={this.state.ifrsCode}
													style={{ width: 90, marginRight: 5 }}
													name="ifrsCode"
													onChange={this.handleChange}
													readOnly={this.state.inputReadOnly}
												/>
												<IconButton
													onClick={() => IfrsPopupStore.openPopup(this.ifrsCloseFnc)}
													color="secondary"
													className="iconButton"
													disabled={this.state.disabledBtn}
												>
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
										</tr>
										*/}
										<tr>
											{/*
											[대응개발] 2024-05-14 삭제 by choye 
											<th>재고</th>
											<td>
												<input
													type="text"
													value={this.state.asstNo}
													name="asstNo"
													onChange={this.handleChange}
													placeholder="자산번호"
													readOnly
													// readOnly={this.state.inputReadOnly}
													style={{ width: 90, marginRight: 5 }}
												/>
												<IconButton
													onClick={() => {
														this.stockPopOpen('B');
														this.setState({ asstNo: '' });
													}}
													color="secondary"
													className="iconButton"
													disabled={this.state.disabledBtn}
												>
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
											*/}
											<th>월렌탈료</th>
											{/*
											[대응개발] 2024-05-14 colSpan 추가, readOnly = true by choye 
											*/}
											<td colSpan="3">
												<input type="text" value={this.state.monthPrc} readOnly={true} name="monthPrc" style={{ border: 0 }} />
											</td>
										</tr>
									</tbody>
								</table>
								<div style={{ marginTop: 15, textAlign: 'right' }}>
									{/*
									[대응개발] 2024-05-14 제품정보저장 버튼 삭제 by choye 
									<Button
										color="primary"
										variant="outlined"
										size="small"
										style={{ marginLeft: 10, alignItems: 'initial' }}
										onClick={this.saveItemInfo}
										disabled={this.state.disabledBtn}
									>
										<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
										제품정보저장
									</Button>
									*/}
								</div>
							</div>
						</div>
					</div>
				</div>
				<Menu id="simple-menu" anchorEl={this.state.anchorEl} open={Boolean(this.state.anchorEl)} onClose={this.changeQtyPopClose}>
					<TextField
						label="나눌 수량 입력"
						type="number"
						variant="filled"
						margin="normal"
						autofocus={true}
						value={this.state.changeQty}
						name="changeQty"
						onChange={this.handleChange}
						style={{ margin: 10, width: 120 }}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<Button color="primary" variant="contained" size="small" style={{ margin: '24px 18px 10px 10px', alignItems: 'initial' }} onClick={this.checkItemQty}>
						<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
						저장
					</Button>
				</Menu>
				<Menu id="long-menu" anchorEl={this.state.anchorEl2} open={Boolean(this.state.anchorEl2)} onClose={() => this.setState({ anchorEl2: null })}>
					{/* 
					[대응개발] 2024-05-17 포괄계약번호, 변경차수 표시 by choye 
					*/}
					{this.state.arrCustItemList.map((item, i) => (
						<MenuItem key={i} onClick={() => this.custItemCloseFnc(item)}>
							{item.itemSeq} - {item.model} ({InsertComma(item.monthPrc)}원) - {item.grupContNo}({item.chgSeq})
						</MenuItem>
					))}
				</Menu>
				<Dialog onClose={() => this.handleBulkChangeDialogClose('center')} open={this.state.isOpenCenter} >
				<DialogTitle>센터 일괄 변경</DialogTitle>
					<div style={{ margin: '10px', textAlign: 'center'}}>
						<select name='bulkChangeDialogValue' value={this.state.bulkChangeDialogValue} onChange={this.handleChange} style={{border: '1px solid #dddddd', height: '25px'}}>
							{this.state.centerCodeList ? buildSelectItems([{ title: '빈값', value: '' }].concat(this.state.centerCodeList)) : null}
						</select><br></br>
						<Button color="primary" variant="contained" size="small" onClick={()=>this.handleBulkChangeDialogProc('center')} style={{ margin: '10px'}}>
							<ChangeIcon style={{ fontSize: 20, marginRight: 5 }} />
							일괄변경
						</Button>
					</div>
				</Dialog>
				<Dialog onClose={() => this.handleBulkChangeDialogClose('refur')} open={this.state.isOpenRefur} >
				<DialogTitle>리퍼 일괄 변경</DialogTitle>
					<div style={{ margin: '10px', textAlign: 'center'}}>
						<select name='bulkChangeDialogValue' value={this.state.bulkChangeDialogValue} onChange={this.handleChange} style={{border: '1px solid #dddddd', height: '25px'}}>
						{this.state.refurCodeList ? buildSelectItems([{ title: '빈값', value: '' }].concat(this.state.refurCodeList)) : null}
						</select><br></br>
						<Button color="primary" variant="contained" size="small" onClick={()=>this.handleBulkChangeDialogProc('refur')} style={{ margin: '10px'}}>
							<ChangeIcon style={{ fontSize: 20, marginRight: 5 }} />
							일괄변경
						</Button>
					</div>
				</Dialog>
				<Dialog onClose={() => this.handleBulkChangeDialogClose('stipulationYn')} open={this.state.isOpenStipulationYn} >
				<DialogTitle>약정여부 일괄 변경</DialogTitle>
					<div style={{ margin: '10px', textAlign: 'center'}}>
						<select name='bulkChangeDialogValue' value={this.state.bulkChangeDialogValue} onChange={this.handleChange} style={{border: '1px solid #dddddd', height: '25px'}}>
							<option value=''>빈값</option>
							<option value='Y'>Y</option>
							<option value='N'>N</option>
						</select><br></br>
						<Button color="primary" variant="contained" size="small" onClick={()=>this.handleBulkChangeDialogProc('stipulationYn')} style={{ margin: '10px'}}>
							<ChangeIcon style={{ fontSize: 20, marginRight: 5 }} />
							일괄변경
						</Button>
					</div>
				</Dialog>
			</div>
		);
	}
}
export default observer(ApiContractProc);
