import { httpGet, httpPost, httpPut, toQueryString } from '../Service';

// api url 관리 리스트 조회
export function getApiUrlList(data) {
	return httpGet('/api/v1.0/cis/apiUrl/list' + toQueryString(data));
}
// api url 관리 리스트 저장
export function saveApiUrl(data) {
	data = data || {};
	return httpPut('/api/v1.0/cis/apiUrl/save', { body: data });
}
