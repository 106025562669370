import { httpGet, httpPost, httpPut, toQueryString } from '../Service';

export function getList(data) {
	return httpGet('/api/v1.0/cis/comm' + toQueryString(data));
}
export function getDtlList(data) {
	return httpGet('/api/v1.0/cis/comm/dtl' + toQueryString(data));
}

// 저장(C/U/D)
export function save(data) {
	data = data || {};
	console.log(data);
	return httpPut('/api/v1.0/cis/comm/', { body: data });
}
// 저장(C/U/D)
export function saveDtl(data) {
	data = data || {};
	console.log(data);
	return httpPut('/api/v1.0/cis/comm/dtl/', { body: data });
}
