import { httpGet, httpPost, httpPut, toQueryString } from '../Service';

// api 암복호화키 리스트 조회
export function getApiEncList(data) {
	return httpGet('/api/v1.0/cis/apiEnc/list' + toQueryString(data));
}
// api 암복호화키 저장
export function saveApiEnc(data) {
	data = data || {};
	return httpPut('/api/v1.0/cis/apiEnc/save', { body: data });
}
