import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { IconButton } from '@material-ui/core';
import { Search, ThumbUpSharp } from '@material-ui/icons';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ApprIcon from '@material-ui/icons/Face';

import { errorMsgCheck, resultToIbSheet, SheetParamData, buildSelectItems, ComIBSheetComboNull, isEmptyObject, sheetCheckAll } from '../../common/ComFunction';
import {
	getMntncCustList,
	getMntncCustAsstList,
	getMntncProcUserList,
	allocProcUser,
	getMntncProcAsstList,
	deallocProcUser,
} from '../../service/mntnc/MntncAllocService';
import { getCisCommCode } from '../../service/CommonService';
import { MergeType } from '@material-ui/icons';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore, UserPopupStore } = CommonStore;

class MntncAlloc extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sMntCustNo: '', // 유지보수사
			sMntCustNm: '',
			sCustNo: '', // 고객사
			sCustNm: '',
			sAsstNo: '', // 자산번호
			sContNo: '', // 고객번호
			sProcUser: '', // 엔지니어 id
			sProcUserNm: '', // 엔지니어명
			sItemType: '', // 제품타입
			sYear: '', // 년
			sMonth: '', // 월

			arrItemType: [],
			arrYear: [],
			mapArrYear: [],

			sheet2CheckAllValue: false, // sheet2 checkAll value
			sheet4CheckAllValue: false, // sheet4 checkAll value
		};

		this.sheetData = {
			id: 'mntncCustSheet', // sheet id
			initData: 'mntnc/Init-MntncCustList', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '250px', // sheet height
		};
		this.sheetData2 = {
			id: 'mntncCustAsstSheet', // sheet id
			initData: 'mntnc/Init-MntncCustAsstList', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '250px', // sheet height
		};
		this.sheetData3 = {
			id: 'mntncProcUserSheet', // sheet id
			initData: 'mntnc/Init-MntncProcUserList', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '250px', // sheet height
		};
		this.sheetData4 = {
			id: 'mntncProcAsstSheet', // sheet id
			initData: 'mntnc/Init-MntncProcAsstList', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '250px', // sheet height
		};
	}
	componentDidMount = () => {
		Promise.all([getCisCommCode('19')]).then((r) => {
			let [code19] = r;
			this.setState(
				{
					arrItemType: code19,
				},
				() => {
					setInterval(() => {
						if (window[this.sheetData2.id]) {
							ComIBSheetComboNull('0', window[this.sheetData2.id], 'itemType', code19, 'title', 'value', '', '');
						}
					}, 100);
				},
			);
		});

		// 년도 셋팅
		var date = new Date();
		let nowYear = date.getFullYear();
		let startYear = nowYear - 10;

		for (var i = startYear; i <= nowYear; i++) {
			this.setState({
				arrYear: this.state.arrYear.push(i),
			});
		}

		this.state.mapArrYear = this.state.arrYear.map((year) => year);

		setInterval(() => {
			if (window[this.sheetData.id]) {
				let sheet = window[this.sheetData.id];
				sheet.ShowFilterRow();
				sheet.SetSumText(1, '합계');
			}
		}, 100);
		setInterval(() => {
			if (window[this.sheetData2.id]) {
				let sheet = window[this.sheetData2.id];
				sheet.ShowFilterRow();
			}
		}, 100);
		setInterval(() => {
			if (window[this.sheetData3.id]) {
				let sheet = window[this.sheetData3.id];
				sheet.ShowFilterRow();
				sheet.SetSumText(1, '합계');
			}
		}, 100);
		setInterval(() => {
			if (window[this.sheetData4.id]) {
				let sheet = window[this.sheetData4.id];
				sheet.ShowFilterRow();
				sheet.SetSumText(1, '합계');
			}
		}, 100);
	};

	componentWillUnmount = () => {
		// window[this.sheetData.id].DisposeSheet();
		// window[this.sheetData2.id].DisposeSheet();
		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}
		if (window[this.sheetData2.id]) {
			window[this.sheetData2.id].DisposeSheet();
		}
		if (window[this.sheetData3.id]) {
			window[this.sheetData3.id].DisposeSheet();
		}
		if (window[this.sheetData4.id]) {
			window[this.sheetData4.id].DisposeSheet();
		}
	};

	// 고객사팝업
	handleClickOpen = (popupType) => {
		if (popupType === '1' || popupType === '3') {
			//고객사 팝업
			CustSearchPopupStore.openPopup(popupType, null, null, this.handleClose);
		} else if (popupType === '2') {
			//지점 팝업
			//지점은 고객사 팝업 검색 후 가능
			if (this.state.sCustNo === '') {
				ModalStore.openModal(sessionStorage.spotTitle + '검색은 회사명 검색 이후 가능합니다.');
				return;
			}
			CustSearchPopupStore.openPopup(popupType, sessionStorage.spotTitle + '명', this.state.sCustNo, this.handleClose);
		} else if (popupType === '4') {
			//유지보수사 팝업
			CustSearchPopupStore.openPopup(popupType, null, null, this.handleClose);
		}
	};

	handleClose = (item) => {
		if (item !== undefined) {
			if (CustSearchPopupStore.popupType === '1') {
				// 고객사 검색 결과
				if (item.custNo !== undefined) {
					this.setState({
						sCustNo: item.custNo,
						sCustNm: item.custNm,
					});
				}
			} else if (CustSearchPopupStore.popupType === '2') {
				// 지점 검색 결과
				if (item.addrSeq !== undefined) {
					this.setState({
						addrSeq: item.addrSeq, //지점시퀀스
						spotNm: item.spotNm, //지점명
					});
				}
			} else if (CustSearchPopupStore.popupType === '3') {
				// 고객사 검색 결과
				if (item.custNo !== undefined) {
					this.setState({
						sCustNo: item.custNo,
						sCustNm: item.custNm,
					});
				}
			} else if (CustSearchPopupStore.popupType === '4') {
				// 유지보수사 검색 결과
				if (item.custNo !== undefined) {
					this.setState({
						sMntCustNo: item.custNo,
						sMntCustNm: item.custNm,
					});
				}
			}
		}
	};

	handleUserSearch = (item) => {
		if (item !== undefined) {
			this.setState({
				sProcUser: item.userId,
				sProcUserNm: item.userName,
			});
		}
	};

	searchUser = () => {
		if (!this.state.sMntCustNo) {
			ModalStore.openModal('검색은 유지보수사명 검색 이후 가능합니다.');
			return;
		}
		UserPopupStore.openPopup('rep', this.state.sMntCustNo, this.handleUserSearch);
	};

	getMntncCustList = () => {
		if (!this.state.sMntCustNo) {
			ModalStore.openModal('유지보수사를 먼저 입력해주세요.');
			return;
		}

		if (!this.state.sYear) {
			ModalStore.openModal('년도를 선택해주세요.');
			return;
		}

		let param = {
			mntCustNo: this.state.sMntCustNo,
			custNo: this.state.sCustNo,
			contNo: this.state.sContNo,
			asstNo: this.state.sAsstNo,
			itemType: this.state.sItemType,
			year: this.state.sYear,
			month: this.state.sMonth,
		};

		ProgressStore.activeProgress();
		getMntncCustList(param)
			.then((r) => {
				window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				window[this.sheetData2.id].RemoveAll();
				// window[this.sheetData3.id].RemoveAll();
				// window[this.sheetData4.id].RemoveAll();
				this.setState({ sheet2CheckAllValue: false });
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};
	getMntncCustAsstList = () => {
		let data = window.getData();

		if (isEmptyObject(data)) return;

		let param = {
			year: data.year,
			month: data.month,
			mntCustNo: this.state.sMntCustNo,
			custNo: data.custNo,
		};

		ProgressStore.activeProgress();
		getMntncCustAsstList(param)
			.then((r) => {
				window[this.sheetData2.id].LoadSearchData(resultToIbSheet(r));
				this.setState({ sheet2CheckAllValue: false });
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};
	getMntncProcAsstList = () => {
		let data = window.getData();

		if (isEmptyObject(data)) return;

		let param = {
			procUser: data.allocProcUser,
		};

		ProgressStore.activeProgress();
		getMntncProcAsstList(param)
			.then((r) => {
				window[this.sheetData4.id].LoadSearchData(resultToIbSheet(r));
				this.setState({ sheet4CheckAllValue: false });
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};
	getMntncProcUserList = () => {
		if (!this.state.sMntCustNo) {
			ModalStore.openModal('유지보수사를 먼저 입력해주세요.');
			return;
		}

		let param = {
			mntCustNo: this.state.sMntCustNo,
			procUser: this.state.sProcUser,
			procUserNm: this.state.sProcUserNm,
		};

		ProgressStore.activeProgress();
		getMntncProcUserList(param)
			.then((r) => {
				window[this.sheetData3.id].LoadSearchData(resultToIbSheet(r));
				window[this.sheetData4.id].RemoveAll();
				this.setState({ sheet4CheckAllValue: false });
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	updateCheck = (type) => {
		if (type === 0) {
			let sheet2 = window[this.sheetData2.id];
			if (sheet2.FindCheckedRow('chk')) {
				let sheet3 = window[this.sheetData3.id];
				let selectedRow = sheet3.GetSelectRow();
				if (selectedRow < 0) {
					ModalStore.openModal('선택된 엔지니어가 없습니다.');
				} else {
					ConfirmModalStore.openModal(
						`선택된 자산을 엔지니어(${sheet3.GetCellValue(selectedRow, 'allocProcUser')}) 에게 할당하시겠습니까?`,
						this.allocProcUser,
					);
				}
			} else {
				ModalStore.openModal('선택된 자산이 없습니다.');
			}
		} else if (type === 1) {
			let sheet4 = window[this.sheetData4.id];
			if (sheet4.FindCheckedRow('chk')) {
				ConfirmModalStore.openModal(`선택된 자산을 할당해제 하시겠습니까?`, this.deallocProcUser);
			} else {
				ModalStore.openModal('선택된 자산이 없습니다.');
			}
		}
	};

	allocProcUser = () => {
		let sheet = window[this.sheetData2.id];
		let sheet3 = window[this.sheetData3.id];

		let param = {
			procUser: sheet3.GetCellValue(sheet3.GetSelectRow(), 'allocProcUser'),
		};

		ProgressStore.activeProgress();
		allocProcUser(SheetParamData(sheet, param, false))
			.then(() => {
				ModalStore.openModal('저장 되었습니다.');
				this.getMntncCustList();
				this.getMntncProcUserList();
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};
	deallocProcUser = () => {
		let sheet4 = window[this.sheetData4.id];

		ProgressStore.activeProgress();
		deallocProcUser(SheetParamData(sheet4, null, false))
			.then(() => {
				ModalStore.openModal('저장 되었습니다.');
				this.getMntncCustList();
				this.getMntncProcUserList();
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	selApprData = () => {
		let data = window.getData();

		if (!data) return;

		console.log(data);
	};
	clearField = (type) => {
		if (type === 0) {
			this.setState({
				sMntCustNo: '',
				sMntCustNm: '',
				sProcUser: '',
				sProcUserNm: '',
			});
			window[this.sheetData.id].RemoveAll();
			window[this.sheetData2.id].RemoveAll();
			window[this.sheetData3.id].RemoveAll();
			window[this.sheetData4.id].RemoveAll();
		} else if (type === 1) {
			this.setState({
				sCustNo: '',
				sCustNm: '',
			});
		} else if (type === 2) {
			this.setState({
				sProcUser: '',
			});
		}
	};

	sheet2CheckAll = () => {
		let sheet2 = window[this.sheetData2.id];
		sheetCheckAll(sheet2, this.state.sheet2CheckAllValue);
		this.setState({ sheet2CheckAllValue: !this.state.sheet2CheckAllValue });
	};
	sheet4CheckAll = () => {
		let sheet4 = window[this.sheetData4.id];
		sheetCheckAll(sheet4, this.state.sheet4CheckAllValue);
		this.setState({ sheet4CheckAllValue: !this.state.sheet4CheckAllValue });
	};

	render() {
		let date = new Date();
		//let yearMonth = date.getFullYear().toString() + '.' + (date.getMonth() + 1).toString();
		let yearMonth = this.state.sYear == '' && this.state.sMonth == '' ? '' : this.state.sYear + '.' + this.state.sMonth;

		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						{/* <div className="leftMenu">
							<LeftMenu />
						</div> */}
						<div className="wideRightCont" style={{ height: 800 }}>
							<div className="h2Wrap mb30">
								<h2>유지보수 엔지니어 자산할당</h2>
							</div>
							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '80px' }} />
										<col style={{ width: '80px' }} />
										<col style={{ width: '60px' }} />
										<col style={{ width: '80px' }} />
										<col style={{ width: '60px' }} />
										<col style={{ width: '120px' }} />
										<col style={{ width: '60px' }} />
										<col style={{ width: '120px' }} />
										<col style={{ width: '60px' }} />
										<col style={{ width: '90px' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>
												유지보수사 <span className="star">*</span>
											</th>
											<td>
												<input type="text" value={this.state.sMntCustNm} style={{ width: 150, marginRight: 10 }} onClick={() => this.clearField(0)} />
												{/* 고객사검색버튼 */}
												<IconButton onClick={() => this.handleClickOpen('4')} color="secondary" className="iconButton">
													<Search fontSize="small" />
												</IconButton>
											</td>
											<th>고객사</th>
											<td>
												<input type="text" value={this.state.sCustNm} style={{ width: 150, marginRight: 10 }} onClick={() => this.clearField(1)} />
												{/* 고객사검색버튼 */}
												<IconButton onClick={() => this.handleClickOpen('3')} color="secondary" className="iconButton">
													<Search fontSize="small" />
												</IconButton>
											</td>
											<th>계약번호</th>
											<td>
												<input type="text" value={this.state.sContNo} onChange={(e) => this.setState({ sContNo: e.target.value })} />
											</td>
											<th>자산번호</th>
											<td>
												<input type="text" value={this.state.sAsstNo} onChange={(e) => this.setState({ sAsstNo: e.target.value })} />
											</td>
											<th>제품타입</th>
											<td>
												<select value={this.state.sItemType} onChange={(e) => this.setState({ sItemType: e.target.value })}>
													{this.state.arrItemType ? buildSelectItems([{ title: '전체', value: '' }].concat(this.state.arrItemType)) : null}
												</select>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div style={{ marginTop: 15, textAlign: 'right' }}>
								<Button color="primary" variant="contained" size="small" style={{ alignItems: 'initial', marginRight: 5 }} onClick={this.getMntncCustList}>
									<Search style={{ fontSize: 20, marginRight: 5 }} />
									조회
								</Button>
								<Button color="secondary" variant="contained" size="small" style={{ alignItems: 'initial' }} onClick={() => this.updateCheck(0)}>
									<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
									선택건 할당
								</Button>
								<IconButton color="secondary" onClick={() => this.sheet2CheckAll()}>
									{this.state.sheet2CheckAllValue ? <CheckBoxIcon style={{ fontSize: 20 }} /> : <CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
								</IconButton>
							</div>
							<div className="writeType03" style={{ float: 'left', width: '48%' }}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">
										{yearMonth} 고객 리스트
										<span style={{ float: 'right' }}>
											<select value={this.state.sYear} onChange={(e) => this.setState({ sYear: e.target.value })}>
												<option value="">선택</option>
												{this.state.mapArrYear.map((year) => (
													<option value={year}>{year}</option>
												))}
											</select>
											년
											<select value={this.state.sMonth} style={{ marginLeft: 10 }} onChange={(e) => this.setState({ sMonth: e.target.value })}>
												<option value="">전체</option>
												<option value="01">01</option>
												<option value="02">02</option>
												<option value="03">03</option>
												<option value="04">04</option>
												<option value="05">05</option>
												<option value="06">06</option>
												<option value="07">07</option>
												<option value="08">08</option>
												<option value="09">09</option>
												<option value="10">10</option>
												<option value="11">11</option>
												<option value="12">12</option>
											</select>
											월
										</span>
									</p>

									<span onClick={this.getMntncCustAsstList}>
										<IBSheet {...this.sheetData} />
									</span>
								</div>
							</div>
							<div className="writeType03" style={{ float: 'right', width: '48%' }}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">자산 리스트</p>
									<IBSheet {...this.sheetData2} />
								</div>
							</div>
							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '80px' }} />
										<col style={{ width: '80px' }} />
										<col style={{ width: '60px' }} />
										<col style={{ width: '80px' }} />
										{/* <col style={{ width: '60px' }} />
										<col style={{ width: '120px' }} />
										<col style={{ width: '60px' }} />
										<col style={{ width: '120px' }} />
										<col style={{ width: '60px' }} />
										<col style={{ width: '90px' }} /> */}
									</colgroup>
									<tbody>
										<tr>
											<th>엔지니어ID</th>
											<td>
												<input type="text" value={this.state.sProcUser} style={{ width: 150, marginRight: 10 }} onClick={() => this.clearField(2)} />
												<IconButton onClick={this.searchUser} color="secondary" className="iconButton">
													<Search fontSize="small" />
												</IconButton>
											</td>
											<th>엔지니어명</th>
											<td>
												<input type="text" value={this.state.sProcUserNm} onChange={(e) => this.setState({ sProcUserNm: e.target.value })} />
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div style={{ marginTop: 15, textAlign: 'right' }}>
								<Button color="primary" variant="contained" size="small" style={{ alignItems: 'initial', marginRight: 5 }} onClick={this.getMntncProcUserList}>
									<Search style={{ fontSize: 20, marginRight: 5 }} />
									조회
								</Button>
								<Button
									color="secondary"
									variant="contained"
									size="small"
									style={{ alignItems: 'initial', marginRight: 5 }}
									onClick={() => this.updateCheck(1)}
								>
									<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
									선택건 할당해제
								</Button>
								<IconButton color="secondary" onClick={() => this.sheet4CheckAll()}>
									{this.state.sheet4CheckAllValue ? <CheckBoxIcon style={{ fontSize: 20 }} /> : <CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
								</IconButton>
							</div>
							<div className="writeType03" style={{ float: 'left', width: '38%' }} onClick={this.getMntncProcAsstList}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">엔지니어 리스트</p>
									<IBSheet {...this.sheetData3} />
								</div>
							</div>
							<div className="writeType03" style={{ float: 'right', width: '58%' }}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">엔지니어 할당 자산 리스트</p>
									<IBSheet {...this.sheetData4} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(MntncAlloc);
