import React, { Component } from 'react';

import IBSheet from '../IBSheet';
import SaveIcon from '@material-ui/icons/Save';
import ReturnIcon from '@material-ui/icons/LocalShipping';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { Transition, errorMsgCheck, resultToIbSheet } from '../../common/ComFunction';

import { getSpotList } from '../../service/members/MembersService';
import { updateAssetListInfo } from '../../service/rental/RentalAssetService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, AssetListChangePopStore } = CommonStore;

class AssetListChangePop extends Component {
	constructor(props) {
		super(props);

		this.sheetData = {
			id: 'assetListChangePop', // sheet id
			initData: 'rental/Init-AssetListChangePop', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '400px', // sheet height
		};
	}

	componentDidUpdate = () => {
		const interval = setInterval(() => {
			if (window[this.sheetData.id]) {
				window[this.sheetData.id].SetClipPasteMode(1);
				window[this.sheetData.id].SetClipCopyMode(0);
				window[this.sheetData.id].LoadSearchData(resultToIbSheet(AssetListChangePopStore.selectData));
				this.getSpotList();
				clearInterval(interval);
			}
		}, 100);
	};

	componentWillUnmount = () => {
		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}
	};

	getSpotList = () => {
		let param = {
			custNo: AssetListChangePopStore.custNo,
		};

		getSpotList(param)
			.then((r) => {
				window.assetListChangePopSheetCombo(r);
			})
			.catch((err) => {
				errorMsgCheck(err);
			});
	};

	changeSave = () => {
		ConfirmModalStore.openModal('변경 하시겠습니까?', this.updateAssetInfo);
	};

	updateAssetInfo = () => {
		ProgressStore.activeProgress();

		let arrData = window.getAssetInfoChangeList();

		if (arrData.length === 0) {
			ModalStore.openModal('변경된 데이터가 없습니다.');
			return;
		}

		console.log(arrData);
		updateAssetListInfo(arrData)
			.then(() => {
				ModalStore.openModal('저장 되었습니다.', () => this.popClose(true));
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	popClose = (research) => {
		console.log(research);
		AssetListChangePopStore.closePopup(research);

		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}
	};

	render() {
		// getSpotList();
		return (
			<div>
				<Dialog open={AssetListChangePopStore.open} TransitionComponent={Transition} onClose={() => this.popClose(false)}>
					<div className="popSmArea searchPop" style={{ transform: 'translate(-15%, 0)' }}>
						<div className="popCont" style={{ height: 570, width: 1170, overflow: 'hidden' }}>
							<div className="h2Wrap">
								<h2>자산선택변경</h2>
							</div>
							<div className="writeType04">
								<IBSheet {...this.sheetData} />
								<div style={{ marginTop: 20, float: 'right' }}>
									<Button color="primary" variant="contained" size="small" style={{ alignItems: 'initial' }} onClick={this.changeSave}>
										<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
										변경저장
									</Button>
								</div>
							</div>
							<div className="btnClose" onClick={() => this.popClose(false)}>
								<a>팝업닫기</a>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}
}
export default observer(AssetListChangePop);
