import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import { getApiEncList, saveApiEnc } from '../../service/admin/ApiEncManagerService';
import { errorMsgCheck, resultToIbSheet, SheetParamData3, ComIBSheetComboNull } from '../../common/ComFunction';
import { getCisCommCode } from '../../service/CommonService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ProgressStore, CustSearchPopupStore } = CommonStore;

class ApiEncManager extends Component {
	constructor(props) {
		super(props);

		this.sheetData = {
			id: 'apiEncManagerSheet',
			initData: 'admin/Init-ApiEncManager',
			width: '100%',
			height: '600px',
		};

		this.state = {
			// 검색조건
			custNm: '', // 고객명
			custNo: '', //고객번호
		};
	}

	componentDidMount = () => {
		ProgressStore.activeProgress();

		//공통코드 조회
		Promise.all([getCisCommCode('APIEA')]).then((r) => {
			let [codeApiEncAlgoData] = r;
			const timer = setInterval(() => {
				if (window[this.sheetData.id]) {
					ComIBSheetComboNull('0', window[this.sheetData.id], 'apiEncAlgo', codeApiEncAlgoData, 'title', 'value', '', '');
					ProgressStore.deactiveProgress();
					clearInterval(timer);
				}
			}, 100);
		});
	};

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
	};

	customerSearch = () => {
		CustSearchPopupStore.openPopup('1', null, null, this.handleCustClose);
	};

	handleCustClose = (item) => {
		if (item === undefined) return;

		this.setState({
			custNo: item.custNo,
			custNm: item.custNm,
		});
	};

	// api 암복호화 키추가
	addApiEnc = () => {
		const sheet = window[this.sheetData.id];
		sheet.DataInsert(-1);
	};

	// api 암복호화키 리스트 조회
	getApiEncList = () => {
		ProgressStore.activeProgress();

		let param = {
			custNo: this.state.custNo,
		};

		getApiEncList(param)
			.then((r) => {
				window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	// api 암복화키 저장
	saveApiEnc = () => {
		ProgressStore.activeProgress();
		const sheet = window[this.sheetData.id];

		let sheetParam = SheetParamData3(sheet, null, true);

		if (sheetParam && sheetParam.data.length > 0 && sheetParam.Message != 'KeyFieldError') {
			saveApiEnc(sheetParam)
				.then((r) => {
					ModalStore.openModal('저장되었습니다.');
					this.getApiEncList(); // 저장 성공 후 재조회
				})
				.catch((err) => {
					errorMsgCheck(err);
				})
				.finally(() => {
					ProgressStore.deactiveProgress();
				});
		}

		ProgressStore.deactiveProgress();
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mm30">
								<h2>API 암복호화키 관리</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.getApiEncList}>
										<span>조회</span>
									</a>
									<a className="btn01 add" onClick={this.addApiEnc}>
										<span>추가</span>
									</a>
									<a className="btn01 save" onClick={this.saveApiEnc}>
										<span>저장</span>
									</a>
								</div>
							</div>
							<div className="writeType03">
								<table>
									<tbody>
										<tr>
											<th>고객명</th>
											<td>
												<input type="text" readOnly={true} style={{ marginRight: 10, width: 130 }} value={this.state.custNm} />
												<IconButton onClick={this.customerSearch} color="secondary" className="iconButton" style={{ display: sessionStorage.btnControl }}>
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="writeType03">
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(ApiEncManager);
