import { httpGet, httpPost, httpPut, toQueryString } from '../Service';

// 고객 검증키 리스트 조회
export function getCustVerifyList(data) {
	return httpGet('/api/v1.0/cis/verifyKey/list' + toQueryString(data));
}

// 고객 검증키 저장
export function saveCustVerify(data) {
	data = data || {};
	return httpPut('/api/v1.0/cis/verifyKey/save', { body: data });
}
