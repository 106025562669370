import React, { Component } from 'react';
import LeftMenu from '../include/LeftMenu';

export default class ContractTrans extends Component {
	render() {
		return (
			<div>
				<div className="leftMenu">
					<LeftMenu />
				</div>
				<div className="rightCont">
					<div className="h2Wrap mb30">
						<h2>회원 정보 입력</h2>
						<div className="btnRight">
							<a href="#;" className="btn01 search">
								<span>조회</span>
							</a>
							<a href="#;" className="btn01 add">
								<span>추가</span>
							</a>
							<a href="#;" className="btn01 save">
								<span>저장</span>
							</a>
						</div>
					</div>

					<div className="writeType03">
						<table>
							<colgroup>
								<col style={{ width: '90px' }} />
								<col />
								<col style={{ width: '90px' }} />
								<col style={{ width: '135px' }} />
								<col style={{ width: '90px' }} />
								<col style={{ width: '135px' }} />
								<col style={{ width: '90px' }} />
								<col style={{ width: '135px' }} />
							</colgroup>
							<tbody>
								<tr>
									<th>회사</th>
									<td>
										<select>
											<option value="전체">전체</option>
											<option value="전체">전체</option>
										</select>
									</td>
									<th>사용 여부</th>
									<td>
										<select>
											<option value="전체">전체</option>
											<option value="전체">전체</option>
										</select>
									</td>
									<th>관리자 여부</th>
									<td>
										<select>
											<option value="전체">전체</option>
											<option value="전체">전체</option>
										</select>
									</td>
									<th>삭제 여부</th>
									<td>
										<select>
											<option value="전체">전체</option>
											<option value="전체">전체</option>
										</select>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div className="writeType03">
						<table>
							<colgroup>
								<col style={{ width: '90px' }} />
								<col />
								<col style={{ width: '90px' }} />
								<col style={{ width: '135px' }} />
								<col style={{ width: '90px' }} />
								<col style={{ width: '135px' }} />
								<col style={{ width: '90px' }} />
								<col style={{ width: '135px' }} />
							</colgroup>
							<tbody>
								<tr>
									<th>회사</th>
									<td>
										<select>
											<option value="전체">전체</option>
											<option value="전체">전체</option>
										</select>
									</td>
									<th>사용 여부</th>
									<td>
										<select>
											<option value="전체">전체</option>
											<option value="전체">전체</option>
										</select>
									</td>
									<th>관리자 여부</th>
									<td>
										<select>
											<option value="전체">전체</option>
											<option value="전체">전체</option>
										</select>
									</td>
									<th>삭제 여부</th>
									<td>
										<select>
											<option value="전체">전체</option>
											<option value="전체">전체</option>
										</select>
									</td>
								</tr>
								<tr>
									<th>회사</th>
									<td colSpan="3">
										<input type="text" />
									</td>
									<th>텍스트</th>
									<td>
										<input type="text" />
									</td>
									<th>텍스트</th>
									<td>
										<input type="text" />
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div className="writeType04">
						<div className="scrollWrap hori">
							<div className="tableSc" style={{ width: '1200px' }}>
								<table>
									<colgroup>
										<col style={{ width: '50px' }} />
										<col />
										<col style={{ width: '100px' }} />
										<col style={{ width: '100px' }} />
										<col style={{ width: '150px' }} />
										<col style={{ width: '150px' }} />
										<col style={{ width: '150px' }} />
										<col style={{ width: '150px' }} />
										<col style={{ width: '150px' }} />
										<col style={{ width: '150px' }} />
										<col style={{ width: '150px' }} />
										<col style={{ width: '150px' }} />
									</colgroup>
									<thead>
										<tr>
											<th>NO</th>
											<th>상태</th>
											<th>
												<input type="checkbox" id="del" />
												<label for="del">삭제</label>
											</th>
											<th>회사코드</th>
											<th>렌탈코드</th>
											<th>회사명</th>
											<th>제품순번</th>
											<th>제품명</th>
											<th>제품상세</th>
											<th>사용시작일</th>
											<th>사용종료일</th>
											<th>월렌탈료</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>1</td>
											<td>텍스트</td>
											<td>텍스트</td>
											<td>텍스트</td>
											<td>텍스트</td>
											<td>텍스트</td>
											<td>텍스트</td>
											<td>텍스트</td>
											<td>텍스트</td>
											<td>텍스트</td>
											<td>텍스트</td>
											<td>텍스트</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
