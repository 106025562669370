import React, { Component } from 'react';
import { errorMsgCheck, resultToIbSheet, SheetParamData3 } from '../../common/ComFunction';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';
import Header from '../include/Header';
import { IconButton } from '@material-ui/core';
import { Search, ThumbUpSharp } from '@material-ui/icons';
import { getCustVerifyList, saveCustVerify } from '../../service/admin/CustVerifyService';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ProgressStore, CustSearchPopupStore } = CommonStore;

class ApiVerifyKeyManager extends Component {
	constructor(props) {
		super(props);
		this.sheetData = {
			id: 'custVerifyManagerSheet', //sheet id
			initData: 'admin/Init-CustVerifyManager', // sheet data javascript filename
			width: '100%', // sheet width
			height: '600px', // sheet height
		};
	}

	state = {
		// 검색조건
		custNoSearch: '', //고객사코드 검색
		custNmSearch: '', // 고객사명
		cnSrchInpt: 'S', //고객사검색검색조건/입력항목 여부('S':검색조건/'I':입력항목)

		// 조회결과
		custVerifyList: [], // 검증키 할당리스트
	};

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
	};

	// 고객 토큰 리스트 조회
	getCustVerifyList = () => {
		let param = {
			custNo: this.state.custNoSearch,
		};

		ProgressStore.activeProgress();

		getCustVerifyList(param)
			.then((r) => {
				this.setState({ custVerifyList: r }, () => {
					window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				});
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				ProgressStore.deactiveProgress();
				errorMsgCheck(err);
			});
	};

	// 고객 토큰 리스트 저장
	saveCustVerify = () => {
		ProgressStore.activeProgress();
		const sheet = window[this.sheetData.id];

		let sheetParam = SheetParamData3(sheet, null, true);

		if (sheetParam && sheetParam.data.length > 0 && sheetParam.Message != 'KeyFieldError') {
			saveCustVerify(sheetParam)
				.then((r) => {
					ModalStore.openModal('저장되었습니다.');

					this.getCustVerifyList(); // 저장 성공 후 재조회
					ProgressStore.deactiveProgress();
				})
				.catch((err) => {
					errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
				});
		} else {
			ProgressStore.deactiveProgress();
		}
	};

	// 검증키 컬럼 추가
	addVerifyKey = () => {
		const sheet = window[this.sheetData.id];
		sheet.DataInsert(-1);
	};

	// 고객사팝업
	handleClickOpen = (popupType) => {
		CustSearchPopupStore.openPopup('1', null, null, this.handleClose);
	};

	handleClose = (item) => {
		if (item !== undefined) {
			this.setState({
				custNoSearch: item.custNo,
				custNmSearch: item.custNm,
			});
		}
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mb30">
								<h2>API검증키관리</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.getCustVerifyList}>
										<span>조회</span>
									</a>
									<a className="btn01 add" onClick={this.addVerifyKey}>
										<span>추가</span>
									</a>
									<a className="btn01 save" onClick={this.saveCustVerify}>
										<span>저장</span>
									</a>
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '10%' }} />
										<col style={{ width: '30%' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>고객</th>
											<td>
												<input
													type="text"
													className="size01"
													ref={(ref) => (this.custNmSearch = ref)}
													name="custNmSearch"
													value={this.state.custNmSearch || ''}
													onChange={() => {
														this.setState({ custNoSearch: '', custNmSearch: '' });
													}}
													style={{ width: 170 }}
												/>
												{/* 고객사검색버튼 */}
												<IconButton
													aria-label="Search"
													className="iconButton"
													onClick={() => {
														this.handleClickOpen('1');
														this.setState({ cnSrchInpt: 'S' });
													}}
												>
													<Search fontSize="small"></Search>
												</IconButton>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="writeType05 short">
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
									// onClick={this.setGrpCd}
								>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(ApiVerifyKeyManager);
